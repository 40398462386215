<shared-my-header>
</shared-my-header>

<ion-content>
  <ion-grid style="max-width: 1200px; display: block; margin: auto;">
    <ion-row>
      <ion-col size="4" *ngFor="let page of adminLinks()">
        <button mat-button class="section-div" style="height: 200px;" (click)="navigate(page)">
          <span [matBadge]="this.getChip(page)" class="material-icons" style="font-size: 50px;">
            {{page.icon}}
          </span>
          <br>
          {{page.title}}
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>


</ion-content>

<app-my-footer #footerElement>
</app-my-footer>