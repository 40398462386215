import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generic-progress-modal',
  templateUrl: './generic-progress-modal.component.html',
  styleUrls: ['./generic-progress-modal.component.scss'],
})
export class GenericProgressModalComponent implements OnInit {

  public maxValue: number;
  public currentValue: number;
  
  constructor(public dialogRef: MatDialogRef<GenericProgressModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.maxValue = this.data.maxValue ? this.data.maxValue : 0;
    this.currentValue = this.data.startValue ? this.data.startValue : 0;
  }

  setMax(newMax: number) {
    this.maxValue = newMax;
  }

  setCurrentValue(newValue: number) {
    // console.log("NEW VALUE: " + newValue)
    this.currentValue = newValue;
    // console.log("percentage" + this.getProgressPercentage())
  }

  getProgressPercentage() {
    return (this.currentValue / this.maxValue) * 100;
  }

}
