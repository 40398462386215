import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DEFAULT_NOTE, Note } from 'src/app/leads/leads.model';
import { UserPickerModalComponent } from 'src/app/shared/components/user-picker-modal/user-picker-modal.component';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { DEFAULT_TICKET, TicketModel, TicketsService } from '../tickets.service';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
})
export class TicketFormComponent implements OnInit {

  @Output() onSaveTicket = new EventEmitter<any>();
  @Output() onTicketCancel = new EventEmitter<any>();

  

  @Input() ticket: TicketModel = DEFAULT_TICKET;
  newNoteText: string;

  constructor(
    public ticketsService: TicketsService,
    public employeeStoreService: EmployeeStoreService,
    public userDetailsService: UserDetailsService,
    public dialog: MatDialog,

  ) { }

  ngOnInit() { }

  getFields() {
    return Object.keys(this.ticket);
  }

  getFieldTitle(field: string) {
    return camelCaseToSentenceCase(field);
  }

  getTicketStatusOptions(): string[] {
    return this.ticketsService.ticketOptionsConfig.ticketStatus;
  }

  async newNoteFromInput() {
    if (!(this.newNoteText?.length > 0)) return;
    this.ticket.notes.push({
      ...DEFAULT_NOTE,
      authorId: +(await this.userDetailsService.getUserId()),
      note: this.newNoteText
    });
    this.newNoteText = "";
  }


  selectTicketStatus(option: string) {
    if (option) {
      this.ticket.ticketStatus = option;
    }
  }

  getReportedByEmployee() {
    const output = this.employeeStoreService.getUser(this.ticket.reportingUser);
    // console.log(output);
    return output;
  }

  getAssignedEmployee() {
    const output = this.employeeStoreService.getUser(this.ticket.assignedUser);
    // console.log(output);
    return output;
  }

  selectAssignedEmployee() {
    let dialogRef: MatDialogRef<UserPickerModalComponent> = this.dialog.open(UserPickerModalComponent, {
      data: { selectSingleUser: true },
      minWidth: "300px",
      maxHeight: "100vh"

    })

    dialogRef.afterClosed().subscribe((res: { userIds: number[] }) => {
      if (!res) { return }
      if (res?.userIds && res.userIds.length > 0) {
        this.ticket.assignedUser = res.userIds[0];
      }
    })
  }

  save() {
    this.newNoteFromInput();
    this.onSaveTicket.emit();
  }

  cancel() {
    this.onTicketCancel.emit()
  }


}
