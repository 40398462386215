<h1 
  style="font-size: 20px; font-weight: 500; padding-left: 20px; margin-bottom: 0;">
  {{ title }}
</h1>
<div style="height: 10px"></div>
<div class="report-carousel" style="overflow-y: hidden; padding: 0px;">

  <div *ngFor="let report of reports" style=" margin-left: 20px;display: block;min-width: 300px; height: 220px" class="section-div">
    
    <div *ngIf='report && report.reportCellList' style="min-width: 200px;display: block; height: 100%">
      <button mat-button [disableRipple]="true" style="padding: 0px; margin: 0; width: 100%; height: 100%;" >
          <app-record-card [report]='report'>
          </app-record-card>
      </button>
    </div>
    
  </div>

  <div style="width: 10px"></div>

</div>