<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<div style="max-width: 600px; display: block; margin: auto; min-height: 100vh;">


  <div style="height: 10px;"></div>

  <p>Customer Info</p>
  <div class="section-div">
    <!-- ================ Address ================ -->
    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Address</ion-label>
      <input ngx-google-places-autocomplete matInput [(ngModel)]='this.lead.fullAddress' autocomplete="off"
        style="width: 100%; text-align: right;" [options]='options' #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event, lead)" />
    </ion-item>


    <!-- ================ First Name ================ -->

    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">First Name</ion-label>
      <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.lead.customerFirstName'
        autocomplete="off" type="text" />
    </ion-item>



    <!-- ================ Last Name ================ -->

    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Last Name</ion-label>
      <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.lead.customerLastName'
        autocomplete="off" type="text" />
    </ion-item>



    <!-- ================ Email ================ -->

    <ion-item *ngIf="this.mode === 'new'" class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Email</ion-label>
      <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.lead.email' autocomplete="off"
        type="text" />
    </ion-item>


    <!-- ================ Phone ================ -->

    <ion-item *ngIf="this.mode === 'new'" class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Phone</ion-label>
      <input appPhoneMask type="email" style="text-align: right;" matInput [(ngModel)]='this.lead.phoneNumber'
        autocomplete="off" type="text">
    </ion-item>


    <!-- ================ Setter Information ================ -->

    <ion-item class="item-background-color" style="width: 100%;" *ngIf="getEmployee('setterId')">
      <ion-label class="opaque-text" position="fixed">Setter</ion-label>

      <ion-toolbar class="transparent-bg">
        <div slot="end">
          <app-user-icon matListAvatar [employee]='getEmployee("setterId")'>
          </app-user-icon>
        </div>
        <div slot="end" style="margin-top: -10px;">
          <span>
            &nbsp; {{getEmployee("setterId")?.userDto?.firstName}} {{getEmployee("setterId")?.userDto?.lastName}}
          </span>
        </div>
      </ion-toolbar>


      <!-- <ion-toolbar class="transparent-bg" *ngIf='!getEmployee("setterId")'>
        <div slot="end">
          <button mat-button class="primary-color" style="float: right;"> Assign </button>
        </div>
      </ion-toolbar> -->


    </ion-item>



    <!-- ================ Market ================ -->
    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Team</ion-label>
      <mat-select style="text-align: right;" [(ngModel)]='this.lead.sourceMarket'>
        <mat-option *ngFor="let option of getDropDownOptions('sourceMarket')" [value]="option"
          [(ngModel)]='this.lead.sourceMarket' [name]="getFieldTitle('sourceMarket')" ngDefaultControl>
          {{option}} &nbsp;
        </mat-option>
      </mat-select>
    </ion-item>



    <!-- ================ Prequalification ================ -->
    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Prequal</ion-label>
      <mat-select multiple style="text-align: right;" [(ngModel)]='this.lead.preQualification'>
        <mat-option *ngFor="let option of getDropDownOptions('preQualification')" [value]="option"
          [(ngModel)]='this.lead.preQualification' [name]="getFieldTitle('preQualification')" ngDefaultControl>
          {{option}} &nbsp;
        </mat-option>
      </mat-select>
    </ion-item>

  </div>



  <div *ngIf="this.mode === 'new'">
    <!-- <p>Customer Consent</p>

    <div class="section-div">
      <span class="material-icons" style="font-size: 100px;" (click)="openCustomerConsent()">
        qr_code_2
      </span>
    </div> -->




    <p>Attachments</p>
    <div class="section-div" style="padding: 10px;">
      <app-multi-file-upload [fileUrls]='this.lead.customerUtilityBillUrls'></app-multi-file-upload>
    </div>


    <p>Appointment</p>
    <div class="section-div">
      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Title</ion-label>
        <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.appointment.title' autocomplete="off"
          type="text" />
      </ion-item>
      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Time</ion-label>
        <mat-form-field appearance="none">
          <input matInput style="width: 100%; text-align: right;" step="900"
            [(ngModel)]='this.appointment.startDateTime' type="datetime-local">
        </mat-form-field>
      </ion-item>
    </div>


    <p>Notes</p>
    <div class="section-div" style="padding: 10px;">
      <ion-row *ngFor='let note of this.lead.notes' style="margin-bottom: 10px;">
        <app-note-card [note]="note"></app-note-card>
      </ion-row>
      <mat-divider></mat-divider>
      <ion-toolbar class="transparent-bg" style="width: 100%;">
        <div slot="start" style="width: 100%; text-align: left;">
          <textarea matInput [(ngModel)]='this.newNote' autocomplete="off" type="text" placeholder="Type Here..."
            class="opaque-div" style="padding: 10px;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
        </div>
        <div slot="end">
          <button mat-button class="primary-color" (click)='newNoteFromInput()'
            style="height: 55px; border-radius: 0px;">
            Add Note
          </button>
        </div>
      </ion-toolbar>
      <p style="text-align: left; margin: 0;" class="opaque-text">{{this.newNote.length}} <span
          style="color: red; font-weight: 100;" *ngIf="this.newNote.length > 1000">Only 1,000 characters
          permitted</span></p>
    </div>
  </div>

  <div style="height: 20px;"></div>
  <button mat-button (click)='submitLeadOutput()' class="primary-color"
    style="display: block; margin: auto; width: 100%; max-width: 600px;">
    Save
  </button>
</div>