import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MatDialog, MatDialogRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { AdvancedAnalyticsModalComponent } from 'src/app/reports/advanced-analytics/advanced-analytics-modal/advanced-analytics-modal.component';
import { ConversationModel, MessageModel, MessagesResponse, MessagingService, ParticipantModel, ReportLine } from 'src/app/shared/services/messaging.service';
import { Storage } from '@ionic/storage';
import { UserData } from 'src/app/shared/models/dashboard.model';
import { Router } from '@angular/router';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { APP_IS_DEV_MODE, REST_BASE_URL } from 'src/app/shared/models/constants';
import { NgZone } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Incentive } from 'src/app/shared/models/incentives.model';
import { EditMessageModalComponent } from '../edit-message-modal/edit-message-modal.component';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { GeneralInputModalComponent } from 'src/app/shared/components/general-input-modal/general-input-modal.component';
import { number } from '@amcharts/amcharts4/core';
import { MessageSelectRecipientComponent } from '../message-select-recipient/message-select-recipient.component';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Media } from '@capacitor-community/media';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Employee } from 'src/app/shared/models/employees.model';
import { DEFAULT_TICKET, TicketModel, TicketsService } from 'src/app/tickets/tickets.service';
import { adamLog } from 'src/app/shared/generic-functions';
import { TicketFormModalComponent } from 'src/app/tickets/ticket-form-modal/ticket-form-modal.component';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-message-actions-drawer',
  templateUrl: './message-actions-drawer.component.html',
  styleUrls: ['./message-actions-drawer.component.scss'],
})
export class MessageActionsDrawerComponent {
  sessionKey: string = "";
  mode = "normal";

  fromUserImg: string = "";
  fromUserName: string = "";
  fromUserId: string = "";

  messageId: string = ""
  thisMessageIsFromCurrentUser: boolean = false;
  isReply: boolean = false;

  messageContent: string = "";
  frontendId: string = "";

  userName: string = "";
  userId: string = "";
  userImageUrl: string = "";
  userData: UserData;

  message: MessageModel;
  conversation: ConversationModel;

  messageIndex: number;

  imageUrl: string = "";

  constructor(private ref: ChangeDetectorRef,
    public ticketService: TicketsService,
    private employeeStoreService: EmployeeStoreService,
    private zone: NgZone,
    public dashboardService: DashboardService,
    private conversationService: ConversationService,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private dialog: MatDialog,
    public platform: Platform,
    private localStorage: Storage,
    public http: HttpClient,
    public userDetailsService: UserDetailsService,
    public messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    public snackbarService: SnackbarService,
    private _bottomSheetRef: MatBottomSheetRef<MessageActionsDrawerComponent>) {
    if (this.data) {
      if (this.data.mode === 'profile') {
        this.mode = this.data.mode;
        this.fromUserName = this.data.fromUserName;
        this.fromUserImg = this.data.fromUserImg;
        this.fromUserId = this.data.fromUserId;
        this.userId = this.data.userId;
      }
      else if (this.data.mode === 'options') {
        this.mode = this.data.mode;
        this.messageId = this.data.messageId;
        this.thisMessageIsFromCurrentUser = this.data.thisMessageIsFromCurrentUser
        this.isReply = this.data.isReply
        this.messageContent = this.data.messageContent
        this.frontendId = this.data.frontendId
        this.messageIndex = this.data.messageIndex;
        this.message = this.data.message;
      } 
      
      if (this.data.mode === 'conversation') {
        this.conversation = this.data.conversation;
        
      } 

    }
    if (!this.conversation) {
      this.conversation = this.messagingStoreService.getStoredConversationBasedOnId(+this.message?.convoId);
    }
    this.initIsMuted();
    this.getGroupImage();
    this.currentOptions = this.conversation?.participants;

    this.initAccessLevel()

    this.initUserDataValues();
    this.initRedrawListener();
  }


  async initUserDataValues() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.userData = await this.userDetailsService.getUserData();
    this.userName = await this.userDetailsService.getUserName();
    this.userImageUrl = await this.userDetailsService.getUserImage();
  }

  viewProfile() {
    let employee = this.employeeStoreService.getUser(+this.fromUserId);

    this._bottomSheetRef.dismiss();
    this.router.navigateByUrl('/home-drill-down/employee', { state: { employee: employee } });
  }

  async initRedrawListener() {
    this.messagingService.triggerConvoReload$.subscribe(()=> {
      this.searchSend();
      this.ref.detectChanges();
      this.zone.run(()=> {
        console.log("redraw")
      });
    })
  }

  getUsersToShow(): ParticipantModel[] {
    const numberOfUsersToShow: number = 50;
    const topSpliceValue: number = this.currentOptions.length <= numberOfUsersToShow ? this.currentOptions.length : numberOfUsersToShow;
    return this.currentOptions.slice(0, topSpliceValue);
  }

  showUsersThatHaveReadMessage() {
    // TODO: Create a new mode that shows the participants that have read it so far
    this.mode = 'unread';
  }

  backToOptions() {
    this.mode = 'options'
  }

  category = 'read';

  segmentChanged(option: string) {
    this.category = option;
  }

  getUsersReadSectionTitle(): string {
    if (this.messageIndex == null) return '';
    const messageIndex: number = this.messageIndex;
    
    const participants: ParticipantModel[] = this.conversation?.participants;
    const numberOfParticipants: number = participants?.length;
    const numberThatHaveRead: number = this.getNumberOfRead();

    return "Read by " + numberThatHaveRead + " of " + numberOfParticipants;
  }

  getNumberOfUnread(): number {
    return this.getParticipantsThatHaveNotReadCurrentMessage()?.length;
  }

  getNumberOfRead(): number {
    return this.getParticipantsThatHaveReadCurrentMessage()?.length;
  }

  getParticipantsThatHaveNotReadCurrentMessage(): ParticipantModel[] {
    const participants: ParticipantModel[] = this.conversation?.participants;
    return  participants?.filter(p=>p.unreadMessageCount>this.messageIndex);
  }

  getParticipantsThatHaveReadCurrentMessage(): ParticipantModel[] {
    const participants: ParticipantModel[] = this.conversation?.participants;
    return  participants?.filter(p=>p.unreadMessageCount<=this.messageIndex);
  }


  accessLevel: number;
  async initAccessLevel(): Promise<void> {
    this.accessLevel = await this.userDetailsService.getAccessLevel();
  }

  openRenameConversationDialog(): void {
    const dialogRef = this.dialog.open(AdvancedAnalyticsModalComponent, {
      width: '500px',
      data: { mode: "editName", reportName: this.conversation?.title }
    });

    dialogRef.afterClosed().subscribe((newTitle: string) => {
      if (newTitle === undefined || newTitle.length === 0) {
        alert("Unable to rename this conversation");
        return;
      } else {

      }
      if (newTitle !== undefined) {
        this.renameConversation(newTitle);
      }
    });

  }

  openEditModal(): void {
    this._bottomSheetRef.dismiss("edit");
  }

  messageIsTemp(): boolean {
    return this.messageId === "tempId" || this.messageId === "Failed";
  }

  isPollMessage(): boolean {
    return this.message.messageType === 'POLL';
  }  

  isEnzyAssistantMessage(): boolean {
    return this.message.messageType === 'REPORT';
  }  

  messageHasReport(): boolean {
    return this.message.reportId?.length > 0;
  }

  messageHasReportLines(): boolean {
    return this.message.reportLines?.length > 0;
  }

  openReport(messageUsersMode: boolean = false) {
    this._bottomSheetRef.dismiss();
    this.dashboardService.showAdvancedAnalyticsByReportId(this.message.reportId, messageUsersMode, this.message.sendDate);
  }


  async messageAllUsers() {
    this._bottomSheetRef.dismiss();

    const allLines: ReportLine[] = this.message.reportLines;

    // Use the incomplete lines to open the select recipients page
    const employeesToMessage: Employee[] = allLines.map(line => this.employeeStoreService.getUser(+line.lineUserId));

    let ref = this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        employeesToMessage: employeesToMessage,
        messageReportLines: allLines,
        isBCC: true,

      },
      panelClass: 'transparent',
    })

    const res = await ref.afterClosed().toPromise();
    if (!res) { return }
    console.log(res)
    if (!res.isBCC) {      
      this.router.navigateByUrl('/messages/'+res.conversationId, { state: { messages: res.messages, reportLines: allLines } });

    } else if (res.isBCC) {
        this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend, reportLines: allLines } });
    }

  }

  async messageIncompleteUsers() {
    this._bottomSheetRef.dismiss();

    const incompleteLines: ReportLine[] = this.message.reportLines.filter(line=>!line.isCompleted);

    // Use the incomplete lines to open the select recipients page
    const employeesToMessage: Employee[] = incompleteLines.map(line => this.employeeStoreService.getUser(+line.lineUserId));

    let ref = this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        employeesToMessage: employeesToMessage,
        messageReportLines: incompleteLines,
        isBCC: true,

      },
      panelClass: 'transparent',
    })

    const res = await ref.afterClosed().toPromise();
    if (!res) { return }
    console.log(res)
    if (!res.isBCC) {      
      this.router.navigateByUrl('/messages/'+res.conversationId, { state: { messages: res.messages, reportLines: incompleteLines } });

    } else if (res.isBCC) {
        this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend, reportLines: incompleteLines } });
    }


  }

  

  async deleteTempMessage() {
    await this.messagingStoreService.removeMessageFromTempByFrontendId(this.frontendId, this.message.convoId)
    this._bottomSheetRef.dismiss();

  }

  deleteMessage(): void {

    // if message is temp, remove it from the temp message
    if (this.messageIsTemp()) {
      this.deleteTempMessage();
      return;
    }

    this.messagingService.deleteMessage(this.sessionKey, this.messageId).subscribe(result => {
      this._bottomSheetRef.dismiss();
    }, error => {

    });
  }


  renameConversation(newTitle: string): void {
    this.messagingService.renameConversation(this.sessionKey, +this.conversation?.conversationId, newTitle).then((result) => {
      if (result.message.toLowerCase().includes("success")) {
        this.conversation.title = newTitle;
        this.messagingService.triggerConvoReload$.next("Rename Convo");
        this.ref.detectChanges()

      }
    });
  }



  openParticipants() {
    this.mode = "participants"
  }

  back() {
    this.mode = "normal"
  }

  openPhotoGallery() {
    this.fetchImages();
    this.mode = "gallery"

  }

  imageMessages: any[];


  async fetchImages() {
    const convoId = this.conversation?.conversationId;
    if (!convoId) return;
    const res: MessagesResponse = await this.messagingService.getMessages(convoId, 0, 25, true);
    this.imageMessages = res.messages;

    console.log(this.imageMessages)
  }

  react(reaction: string) {
    this._bottomSheetRef.dismiss(reaction)
  }

  reply() {
    this._bottomSheetRef.dismiss("reply")
  }

  copy() {
    this._bottomSheetRef.dismiss("copy");
  }

  getGroupImage() {
    let image = this.conversation?.imageUrl ? this.conversation?.imageUrl : "/assets/placeholder-image2.png"
    this.imageUrl = image;
  }

  viewPerson() {
    this.mode = "profile"
  }


  async directMessage() {
    this.userId = await this.userDetailsService.getUserId();
    const participantsIncludingMe: string[] = [this.fromUserId, this.userId];

    // const participantsFromEmployee: ParticipantModel[] = participants.map(employee => this.getParticipantFromEmployee(employee));
    const participants: ParticipantModel[] = [
      {
        id: this.fromUserId,
        preferredFirstName: this.fromUserName,
        lastName: this.fromUserName,
        imageUrl: this.fromUserImg,
        isMuted: false,
        isPinned: false,
        isAdmin: false,
        unreadMessageCount: null,
      },
      {
        id: this.userId,
        preferredFirstName: this.userName,
        lastName: this.userName,
        imageUrl: this.userImageUrl,
        isMuted: false,
        isPinned: false,
        isAdmin: false,
        unreadMessageCount: null,
      }
    ]

    this.messagingService.getOrCreateConversationByUserList(this.sessionKey, participantsIncludingMe).subscribe((result) => {
      if (result.conversationId) {
        let newConversation: ConversationModel = {
          conversationId: result.conversationId,
          participants: participants,
          latestMessage: "",
          latestMessageTime: "",
          latestMessageId: null,
          title: this.fromUserName,
          imageUrl: "",
          unreadMessageCount: 0,
          ownerId: null,
          definingTeam: null,
          isAdminSendOnly: false
        }
        this.messagingStoreService.addConvoIfItIsNew(newConversation);
        // TODO: may need to navigate t
        this.router.navigateByUrl('/messages/' + newConversation?.conversationId);

        this._bottomSheetRef.dismiss("directMessage");

      } else {
      }
    });
  }


  addPeople() {
    this._bottomSheetRef.dismiss();
    // this.router.navigateByUrl('/selectRecipient', { state: { existingConversationId: this.conversation?.conversationId } })



    let ref = this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        existingConversationId: this.conversation?.conversationId
      },
      panelClass: 'transparent',
    })

    // ref.afterClosed().subscribe(res => {
    //   if (!res) {return}
    //   if (!res.isBCC) {
    //     this.router.navigateByUrl('/conversation', { state: { messages: res.messages } });
    //   } else if (res.isBCC) {
    //     this.router.navigateByUrl('/conversation', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend } });
    //   }
    // })
    
    
  }

  leaveChat() {
    this.conversationService.deleteParticipant(+this.conversation?.conversationId, this.userData.id).then(res => {
      this.snackbarService.displaySnackBarMessage("You have left the chat.", true);
      this._bottomSheetRef.dismiss("leftChat");
    });
  }

  toggleMuted(event: any) {
    if (this.isMuted) {
      this.unMuteChat();
    } else {
      this.muteChat();
    }
  }

  isMuted: boolean = false;

  async muteChat() {
    const result = await this.conversationService.setConversationMuted(+this.conversation?.conversationId, true);
    this.isMuted = true;
    this.messagingService.setConversationMutedForMe(this.conversation, this.isMuted)
  }

  async unMuteChat() {
    const result = await this.conversationService.setConversationMuted(+this.conversation?.conversationId, false);
    this.isMuted = false;
    this.messagingService.setConversationMutedForMe(this.conversation, this.isMuted)
  }

  async initIsMuted() {
    this.isMuted = await this.messagingService.conversationIsMutedForMe(this.conversation);
    this.userId = await this.userDetailsService.getUserId();
  }

  togglePinned(event: any) {
    if (this.isPinned()) {
      this.unPinChat();
    } else {
      this.pinChat();
    }
  }

  async pinChat() {
    const result = await this.conversationService.setConversationPinned(+this.conversation?.conversationId, true);
    this.messagingService.setConversationPinnedForMe(this.conversation, this.userId, true)
  }

  async unPinChat() {
    const result = await this.conversationService.setConversationPinned(+this.conversation?.conversationId, false);
    this.messagingService.setConversationPinnedForMe(this.conversation, this.userId, false)
  }

  isPinned(): boolean {
    return this.messagingService.conversationIsPinnedForMe(this.conversation, this.userId);
  }

  async reportMessage() {
    let dialogRef = this.dialog.open(GeneralInputModalComponent, {
      maxWidth: '100vw !important',

      data: {
        title: 'Reporting Reason',
        prompt: "Why would you like to report this message?",
      }
    });

    dialogRef.afterClosed().subscribe(async (data: string) => {
      if (data !== undefined) {
        const reason: string = data;

        const result = await this.messagingService.reportMessage(this.messageId, reason);
        if (result.message == "Success") {
          this.snackbarService.displaySnackBarMessage("Message has been successfully reported.",true);
          this._bottomSheetRef.dismiss();
        } else {
          this.snackbarService.displaySnackBarMessage("There was trouble reporting your message", false);
        }
      }
    });
  }

  conversationIsAutomaticallyManaged(): boolean {
    return !!this.conversation?.definingTeam;
  }

  canRemoveParticipants(): boolean {
    return this.conversation?.participants.length > 2 && !this.conversationIsAutomaticallyManaged();
  }

  async removeParticipant(person: ParticipantModel) {

    const myUserId: string = await this.userDetailsService.getUserId();

    if (myUserId == person.id) {
      const shouldDelete: boolean = confirm("Are you sure you would like to remove yourself from the conversation?");

      if (!shouldDelete) return;
    }
    this.sessionKey = await this.userDetailsService.getSessionKey();

    this.conversationService.deleteParticipant(+this.conversation?.conversationId, person.id).then(res => {

      if (res.message === "Success") {
        this.snackbarService.displaySnackBarMessage("Deleted " + person.preferredFirstName + " " + person.lastName + " Group Chat", true);

        this.conversation?.participants.forEach((personArr, index) => {
          if (personArr.id === person.id) {
            this.conversation?.participants.splice(index, 1);
          }
        })


        this.ref.detectChanges();
      } else {
        this.snackbarService.displaySnackBarMessage(res.errorMessage, false);
      }

    });
  }






  imageSrc: string;
  selectedFile = null;


  onFileSelected(event) {
    this.selectedFile = event.target.files[0];

    this.onSubmit();
  }




  onSubmit() {
    const payload = new FormData();
    payload.append('imageFile', this.selectedFile, this.selectedFile.name);
    this.saveProfilePicture(this.sessionKey, payload);
  }


  public saveProfilePicture(sessionKey: string, image: any) {
    let spinner = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })


    this.conversationService.setConvoImage(+this.conversation?.conversationId, this.selectedFile).then(res => {
      if (this.conversation) {
        this.conversation.imageUrl = res.message
      }
      this.imageUrl = res.message;

      this.ref.detectChanges();

      spinner.close()
    });

  }

  keyword: string = "test";
  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  currentOptions: ParticipantModel[] = [];

  searchSend() {
    this.currentOptions = this.conversation?.participants.filter(p => (p.preferredFirstName + " " + p.lastName).toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  userCanDeleteChat(): boolean {
    return this.accessLevel > 7;
  }

  deleteChat(): void {
    this.messagingService.deleteConversation(+this.conversation?.conversationId).then((res) => {
      this._bottomSheetRef.dismiss("leftChat");
    });
  }

  isAdmin(person: ParticipantModel): boolean {
    return person.isAdmin || this.conversation?.ownerId + "" === this.userId;
  }

  numberOfUnread(person: ParticipantModel): number {
    return person.unreadMessageCount;
  }

  getInitials(person: ParticipantModel): string {
    const preferredFirstName: string = person?.preferredFirstName;

    const firstInitial: string = person?.preferredFirstName ? person.preferredFirstName[0] : "";
    const lastInitial: string = person?.lastName ? person.lastName[0] : "";
    let output: string = firstInitial + lastInitial;
    return output;
  }

  async saveImage() {
    let spinner = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    const path: string = this.message.messageImage;
    try {
      const res = await this.messagingService.savePhotoByPath(path);
      spinner.close();
      alert ("Image Saved");
    } catch(e) {
      spinner.close();
      alert (e);
    }
  }

  isIOS() {
    return this.platform.is("ios")
  }

  downloadAndSaveFile(url: string, name: string) {

    // Start spinner
    let spinner = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    console.log("@@@@@@ start download");

    this.http.get(url, {
      responseType: 'blob',
    }).toPromise().then(async event=> {
      console.log("@@@@@@ response happened");
      const base64: string = await this.convertBlobToBase64(event) as string;
      const path: string = name;
      
      
      console.log("@@@@@@ blob converted. base64 size: " + base64.length);
      const foo = await Filesystem.writeFile({
        path: path,
        data: base64,
        directory: Directory.Documents
      });
      console.log("@@@@@@ File Saved: " + JSON.stringify(foo));
      
      try {
        const res = await this.messagingService.saveVideoByPath(foo.uri);
        spinner.close();
        alert ("Video Saved");
      } catch(e) {
        spinner.close();
        alert ("Video failed to save");
      } 

      // }
    }).catch(e => { spinner.close();})
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  async convertBlobToBase64 (blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = this.getFileReader();
      reader.onerror = reject;
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  userCanDeleteMessage(): boolean {
    return this.thisMessageIsFromCurrentUser || this.accessLevel >= 10;
  }

  async saveVideo() {
    
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let videoS3Path: string = this.message.messageImage;

    let url: string = REST_BASE_URL + "/downloadVideo/" + sessionKey;
    url += "?videoUrl=" + videoS3Path;
    url += "&messageId=" + this.message.messageId;

    const videoName: string = "enzyVideo_" + this.message.messageId + ".MOV";

    this.downloadAndSaveFile(url, videoName);

  }

  canOpenTicket(): boolean {
    return this.ticketService?.ticketOptionsConfig?.userHasTicketAdminRights;
  }

  async openNewTicket() {
    if (!this.canOpenTicket()) return;
    const ticket: TicketModel = {...DEFAULT_TICKET};
    ticket.title = "New Ticket";
    ticket.notes = [{
      creationDate: null, 
      authorId: +(await this.userDetailsService.getUserId()),
      note: "Original message contents: \n" + this.message.contents
    }];
    ticket.conversationId = this.conversation.conversationId;
    ticket.messageId = +this.message.messageId;
    ticket.reportingUser = +this.message.fromUser;
    ticket.assignedUser = +(await this.userDetailsService.getUserId());
    if (this.message.messageImage) {
      ticket.fileUrls.push(this.message.messageImage);
    }
    let dialogRef: MatDialogRef<TicketFormModalComponent> = this.dialog.open(TicketFormModalComponent, {
      data: { ticket },
      minWidth: "100vw",
      maxHeight: "100vh"

    })

    dialogRef.afterClosed().subscribe(async (res: {ticket: TicketModel}) => {
      if (!res) { return }
      if (res.ticket) {
        const result: any = await this.ticketService.createNewTicket(res.ticket);
        this._bottomSheetRef.dismiss();
        this.router.navigateByUrl('/ticket-management');
      }
    })
  }

  canChangeConversationImage(): boolean {
    return !this.conversationIsAutomaticallyManaged() || this.accessLevel >= 10;
  }

}
