import { filter } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { camelCaseToSentenceCase } from '../../generic-functions';
import { DateFormatService } from '../../services/date-format.service';
import { getStartOfCurrentWeek, getEndOfCurrentWeek } from '../../services/dates.service';
import { EmployeeStoreService } from '../../services/employee-store.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'enzy-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,

})
export class TableFilterComponent implements OnInit {

  filters: TableFilterButton[] = [];

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public snackbarService: SnackbarService,
    public dateFormatService: DateFormatService,
    public zone: NgZone,
    public employeeStoreService: EmployeeStoreService,
    public changeDetectionRef: ChangeDetectorRef,
    private _bottomSheetRef: MatBottomSheetRef<TableFilterComponent>) {

    if (data?.filters) {
      this.filters = this.data.filters;
    }

  }

  ngOnInit() { }

  startEndTime: { startTime: string, endTime: string } = {
    startTime: getStartOfCurrentWeek() + "",
    endTime: getEndOfCurrentWeek() + "",
  }

  async processFilterAction(filter: TableFilterButton) {
    if (filter.type === "employeeLookup") {
      const userIds: number[] = await this.employeeStoreService.selectUsersModal();
      filter.selectedValue = userIds;
      this.redraw();

    } else if (filter.type === "teamLookup") {

      const selectedOptions: string[] = await this.employeeStoreService.selectPicker(filter.options, false);

      if (selectedOptions) {
        filter.selectedValue = selectedOptions;
        this.redraw();
      }
    }
    else if (filter.type === "date") {

      const selectedOptions: string[] = await this.employeeStoreService.selectPicker(
        ['Today', 'Tomorrow', 'This Week', 'This Month', 'This Year', 'Past']
      );

      if (selectedOptions) {
        filter.selectedValue = selectedOptions;
        this.redraw();
      }
    }

    else if (filter.type === "boolean") {

      const selectedOptions: string[] = await this.employeeStoreService.selectPicker(
        ['true', 'false']
      );

      if (selectedOptions) {
        filter.selectedValue = selectedOptions;
        this.redraw();
      }
    }

    else if (filter.type === "emptyObjectKey") {
      const selectedOptions: string[] = await this.employeeStoreService.selectPicker(
        ['true']
      );

      if (selectedOptions) {
        filter.selectedValue = selectedOptions;
        this.redraw();
      }      

    }

    else if (filter.type === "other") {

      const selectedOptions: string[] = await this.employeeStoreService.selectPicker(
        filter.options, false
      );

      if (selectedOptions) {
        filter.selectedValue = selectedOptions;
        this.redraw();
      }
    }

  }

  save() {
    this._bottomSheetRef.dismiss(this.data.filters)
  }

  clearAll() {
    this.filters.forEach(filter => {
      filter.selectedValue = null;
    })

    this._bottomSheetRef.dismiss(this.filters)

  }

  redraw() {
    this.zone.run(() => {
      this.changeDetectionRef.detectChanges();
    });
  }

  shouldShowClear(filterButton: TableFilterButton): boolean {
    return filterButton.selectedValue;
  }

  getSelectedValue(filterButton: TableFilterButton): string {
    if (filterButton.type === "employeeLookup") {
      let employees = filterButton.selectedValue;
      let displayValue: string = "";
      employees?.forEach((e, index) => {
        let employeeName = this.employeeStoreService.getUserName(e);
        if (employeeName) {
          displayValue += " " + employeeName;
          if (index !== employees.length - 1) { displayValue += "," }
        }
      })

      return (displayValue !== "") ? displayValue : "All";

    } else {
      return filterButton.selectedValue ? filterButton.selectedValue : "All"
    }
  }

  // generalFormat(filter: TableFilterButton) {
  //   let displayValue = "";
  //   filter.selectedValue.forEach((val, index) => {
  //     displayValue += this.getSentenceCase(val) 
  //     if (index !== filter.selectedValue.length - 1) { displayValue += ", " }
     
  //   })

  //   return displayValue
  // }

  // getSentenceCase(option: string) {
  //   return camelCaseToSentenceCase(option)
  // }



  removeFilter(filterButton: TableFilterButton) {
    filterButton.selectedValue = null;
  }


}

export class TableFilterButton {
  objectKey: string;
  subObjectKey?: string;
  label: string;
  selectedValue: any;
  type: "employeeLookup" | "teamLookup" | "date" | "boolean" | "emptyObjectKey" | "other"; //Other will depend on "options" to be populated
  options?: string[];
}