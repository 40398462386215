
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralInputModalComponent } from 'src/app/shared/components/general-input-modal/general-input-modal.component';
import { DEFAULT_TOURNAMENT_ROUND, TwoTeamMatchup } from 'src/app/shared/models/incentives.model';
import { FreshReportService } from 'src/app/shared/services/fresh-report.service';
import { IncentivesService, TournamentModel, TournamentRound } from 'src/app/shared/services/incentives.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { saveAs } from 'file-saver'; 
import { Platform } from "@ionic/angular";
import { MatchParticipant } from '../edit-tournament.component';
import { DatesService } from 'src/app/shared/services/dates.service';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';

@Component({
  selector: 'app-edit-tournament-rounds',
  templateUrl: './edit-tournament-rounds.component.html',
  styleUrls: ['./edit-tournament-rounds.component.scss'],
})
export class EditTournamentRoundsComponent implements OnInit {
  currentIndex: number = 0;


  @Input() set _rounds(_rounds: TournamentRound[]) {
    this.rounds = _rounds;
    this.updateLeftRightTeams();
  }

  rounds: TournamentRound[];

  leftTeams: MatchParticipant[] = [];
  rightTeams: MatchParticipant[] = [];

  constructor(
    public platform: Platform,
    public freshReportService: FreshReportService,
    public datesService: DatesService,
    public dialog: MatDialog,
    public spinnerService: BusySpinnerService,
    public incentivesService: IncentivesService,
  ) { }

  ngOnInit() {}

  switchRound(index: number) {
    this.updateRoundMatchups(this.currentIndex);
    this.currentIndex = index;
    this.updateLeftRightTeams(index)
  }


  roundDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rounds, event.previousIndex, event.currentIndex);
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    this.updateRoundMatchups(this.currentIndex);
  }

  private updateLeftRightTeams(index: number = this.currentIndex) {

    const round = this.rounds[index];
    const firstTeams: MatchParticipant[] = this.getFirstTeams(round);
    this.leftTeams = firstTeams;
    const secondTeams: MatchParticipant[] = this.getSecondTeams(round);
    this.rightTeams = secondTeams;
  }


  getFirstTeams(round: any): MatchParticipant[] {
    return round.matchups.map((m: TwoTeamMatchup) => {return {teamName: m.team1Name, teamScore: m.team1Score, teamMultiplier: m.team1Multiplier, teamUseOverrideScore: m.team1UseOverrideScore, teamDeals: m.team1Deals, teamSelfGen: m.team1SelfGen, teamCalculatedRealizationRate: m.team1CalculatedRealizationRate}});
  }

  getSecondTeams(round: any): MatchParticipant[] {
    return round.matchups.map((m: TwoTeamMatchup) => {return {teamName: m.team2Name, teamScore: m.team2Score, teamMultiplier: m.team2Multiplier, teamUseOverrideScore: m.team2UseOverrideScore, teamDeals: m.team2Deals, teamSelfGen: m.team2SelfGen, teamCalculatedRealizationRate: m.team2CalculatedRealizationRate}});
  }



  addNewRound() {
    const nextIndex: number = this.rounds.length;
    const newRound: TournamentRound = DEFAULT_TOURNAMENT_ROUND;
    newRound.roundOrder = nextIndex;
    this.rounds.push(newRound)
    //   {
    // isActive: false,
    // roundId: null,
    // startDate: null,
    // endDate: null,
    // roundName: "New Round",
    // roundType: "Team",
    // matchups: [],
    // roundPrizeImage: "",
    // roundOrder: nextIndex,
    // reportRequestId: null,
    // selfGenReportRequestId: null,
    // stagePerTeamReportId: null,
    // }
    // )
  }

  duplicateSelectedRound() {
    const nextIndex: number = this.rounds.length;
    const newRound: TournamentRound = {...this.rounds[this.currentIndex]};
    newRound.roundOrder = nextIndex;
    this.rounds.push(newRound);
  }

  addStartDate(event: MatDatepickerInputEvent<Date>) {
    const date = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.rounds[this.currentIndex].startDate = date;
    console.log(date);

  }

  addEndDate(event: MatDatepickerInputEvent<Date>) {
    const date = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.rounds[this.currentIndex].endDate = date;
    console.log(date);

  }


  async downloadCurrentRoundProjectsCSV() {
    const reportId: string = this.rounds[this.currentIndex].reportRequestId;
    this.downloadProjectsCSV(reportId);
  }


  async downloadProjectsCSV(reportId: string) {
    // TODO: This only works for web right now. iOS creates some weird experience
    if (!this.platform.is('mobileweb') && !this.platform.is("desktop")) return;
    const listener: Observable<any> = await this.freshReportService.getDataListReportCsv(reportId, false);
    listener.subscribe((res)=> {
      // console.log(res);
      const blob = new Blob([res], { type: 'text/csv' });

      saveAs(blob, "report_results.csv");
    })
  }

  async onRoundImageFileSelected(event: Event): Promise<void> {
    this.saveFile(event, this.saveRoundImageFile);
  }

  async saveRoundImageFile(selectedFile: File, incentivesService: IncentivesService, round: TournamentRound): Promise<void> {
    const newRoundImageUrl: string = await incentivesService.saveRoundIncentiveImage(selectedFile);
    round.roundPrizeImage = newRoundImageUrl;
  }


  async saveFile(event: Event, saveFileToServerCallback: (file:File, incentivesService: IncentivesService, round: TournamentRound)=>Promise<void>) {
  const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const selectedFile: File = eventTarget.files[0];
    
    // this.setupLocalUrlsIfNeeded(selectedFile);

    this.spinnerService.start();

    //upload file
    try {
      await saveFileToServerCallback(selectedFile, this.incentivesService, this.rounds[this.currentIndex]);
    } catch(e) {
      console.error(e);
    }
    this.spinnerService.stop();
  }


  /**
   * THIS FUNCTION MUST BE CALLED BY THE PARENT BEFORE SAVING OR BEFORE BEING RELIED ON BECAUSE IT SETS THE ROUNDS BASED ON THE MATCHUP PARTICIPANTS
   * @param index 
   * @returns 
   */
  updateRoundMatchups(index: number = this.currentIndex) {
    if (this.leftTeams.length !== this.rightTeams.length) {
      // TODO: Add visual indicator that not all teams have a matchup
      return;
    }

    const round = this.rounds[index];
    const matchups: any[] = [];
    for (let i in this.leftTeams) {
      const nextMatch: TwoTeamMatchup = {
        team1Name: this.leftTeams[i].teamName,
        team1Score: this.leftTeams[i].teamScore,
        team1Multiplier: this.leftTeams[i].teamMultiplier,
        team1Deals: this.leftTeams[i].teamDeals,
        team1SelfGen: this.leftTeams[i].teamSelfGen,
        team1CalculatedRealizationRate: this.leftTeams[i].teamCalculatedRealizationRate,
        team1UseOverrideScore: this.leftTeams[i].teamUseOverrideScore,
        team2Name: this.rightTeams[i].teamName,
        team2Score: this.rightTeams[i].teamScore,
        team2Multiplier: this.rightTeams[i].teamMultiplier,
        team2Deals: this.rightTeams[i].teamDeals,
        team2SelfGen: this.rightTeams[i].teamSelfGen,
        team2CalculatedRealizationRate: this.rightTeams[i].teamCalculatedRealizationRate,
        team2UseOverrideScore: this.rightTeams[i].teamUseOverrideScore,
      }
      matchups.push(nextMatch);
    }
    round.matchups = matchups;
    // round.roundPrizeImage =
  
  }

  removeTeam(index: number, side: string = 'left') {
    if (side === 'left') {
      this.leftTeams.splice(index, 1);
    } else if (side === 'right') {
      this.rightTeams.splice(index, 1);
    }
    this.updateRoundMatchups(this.currentIndex);
  }


  checkAll(): void {
    this.leftTeams.forEach(m=>m.teamUseOverrideScore = true);
    this.rightTeams.forEach(m=>m.teamUseOverrideScore = true);
    this.updateRoundMatchups(this.currentIndex);

  }

  uncheckAll(): void {
    this.leftTeams.forEach(m=>m.teamUseOverrideScore = false);
    this.rightTeams.forEach(m=>m.teamUseOverrideScore = false);
    this.updateRoundMatchups(this.currentIndex);

  }


/**
 * side variable can only be the string 'left' or 'right'
 */
 async addTeams(side: string = 'left') {
  let dialogRef = this.dialog.open(GeneralInputModalComponent, {
    maxWidth: '100vw !important',

    data: {
      title: 'New Teams',
      prompt: "Seperate the teams with commas to enter multiple teams",
    }
  });

  dialogRef.afterClosed().subscribe(async (data: string) => {
    if (data !== undefined) {
      const dataString: string = data;
      const dataArray: MatchParticipant[] = dataString.split(",").map(m => {return {teamName: m, teamScore: null, teamImageUrl: null, teamUseOverrideScore: null, teamMultiplier: null, teamDeals: null, teamSelfGen: null, teamCalculatedRealizationRate: null}})
      if (side === 'right') {
        this.rightTeams.push(...dataArray);
      } else {
        this.leftTeams.push(...dataArray);
      }
      // TODO: PUSH THIS TO BACKEND
    }
  });
  this.updateRoundMatchups(this.currentIndex);

}

}

