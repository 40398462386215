import { MouseEvent } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnzyAddress, KnockedDoor } from 'src/app/knocking/knocking.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'enzy-geo-map',
  templateUrl: './geo-map.component.html',
  styleUrls: ['./geo-map.component.scss'],
})
export class GeoMapComponent implements OnInit {

  @Output()
  public addPin: EventEmitter<MapMarker> = new EventEmitter<MapMarker>();
  @Output()
  public clickedPin: EventEmitter<MapMarker> = new EventEmitter<MapMarker>();


  @Input() public mapDetails: any = DEFAULT_MAP_DETAILS;
  @Input() public markers: MapMarker[] = [];
  @Input() public showToggle: boolean = true;
  public markersOn: boolean = true;

  private map: google.maps.Map;
  private heatmap: google.maps.visualization.HeatmapLayer;

  constructor(
  ) { }

  ngOnInit() {}

  mapClicked(event: MouseEvent) {
    console.log(`placeId: ${event.placeId}`)
    this.addPin.emit({
      serverId: null,
      frontendId: uuidv4(),
      lat: event.coords.lat,
      lng: event.coords.lng,
      weightNumber: 1,
      draggable: false,
      pinType: 1,
      pinImageUrl: "assets/map_pins/pin1.png",
    })
  }

  getMarkersOn(): boolean { return this.markersOn }
  getMarkers(): MapMarker[] { return this.markers }

  clickedMarker(marker: MapMarker, index: number) {
    console.log(`clicked the marker: ${index}, lat: ${marker.lat}, long: ${marker.lng}`)
    this.clickedPin.emit(marker);
  }

  private getWeightedLatLngFromMarker(m: MapMarker): { location: google.maps.LatLng, weight: Number } {
    let weightFactor: number = 40
    return { location: new google.maps.LatLng(m.lat, m.lng), weight: Number(m?.weightNumber ? m.weightNumber : 1) * weightFactor }
  }

  onMapLoad(mapInstance: google.maps.Map) {
    this.map = mapInstance;
    const coords: any[] = this.markers.map((m: MapMarker) => this.getWeightedLatLngFromMarker(m))
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      map: this.map,
      data: coords
    });
  }

  removeHeatMap() {
    this.heatmap?.setMap(null)
  }

  addHeatMap() {
    this.heatmap?.setMap(this.map)
  }

  onToggleGroupChange(value: String) {
    if (value == 'heat_map') {
      this.markersOn = false;
      this.addHeatMap()
    }
    if (value == 'markers') {
      this.markersOn = true;
      this.removeHeatMap()
    }
  }

  getColoredPinUrl(marker: MapMarker): String {
    return marker.pinImageUrl || "assets/map_pins/pin1.png";
  }


}

const DEFAULT_MAP_DETAILS = {
  latitude: 40.455770605784544, 
  longitude: -111.79644705397973,
  zoom: 17,
};

export interface MapMarker {
  serverId: string;
  frontendId: string;
  lat: number;
  lng: number;
  weightNumber: number;
	label?: string;
	draggable?: boolean;
  pinType: number; // 1 | 2 | 3 | 4 | 5;
  pinImageUrl: string;
  
  address?: EnzyAddress;
  knockedDoor?: KnockedDoor,
}

type GoogleMarker = google.maps.Marker;