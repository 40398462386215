<ion-content style="display: block; ">
  <ion-grid class='incentive-component-body' >

    <!-- Fields that are the same for all incentives-->
    <div >
      <mat-form-field [floatLabel]="'never'">
        <input matInput required
        [(ngModel)]='this.campaignDetails.campaignName' 
        autocomplete="off" 
        type="text" 
        placeholder="Campaign Title" />
      </mat-form-field>
      <button mat-button (click)='saveIncentive()' class="primary-color">
        Save
      </button>
    </div>

    <div>
      <mat-form-field [floatLabel]="'never'">
        <mat-label>Start Date</mat-label>
        <input matInput required
        autocomplete="off" 
        [(ngModel)]='this.campaignDetails.startDate'
        [matDatepicker]="pickerStart" 
        (dateChange)="addStartDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerStart" [disableRipple]="true">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
    
      </mat-form-field>

      <span>
        to 
      </span>
      
      <mat-form-field [floatLabel]="'never'">
        <mat-label>End Date</mat-label>
        <input matInput required
        autocomplete="off" 
        [(ngModel)]='this.campaignDetails.endDate'
        [matDatepicker]="pickerEnd" 
        (dateChange)="addEndDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd" [disableRipple]="true">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
    
      </mat-form-field>
    </div>

  <div style="margin-bottom: 15px" >
    <button title="This will be the background image on the page showing the incentive." 
              type="button"
              mat-button style="text-align:center;" 
              (click)="addBackgroundFile.click()">
            Add Background Image
            </button>
            <input hidden (change)="onBackgroundFileSelected($event)" #addBackgroundFile type="file" value="" name="Attach">
            <img *ngIf='this.campaignDetails.backgroundURL' [src]='this.campaignDetails.backgroundURL' style="max-width: 100px;"/>

  </div>

    <div >
      <button type="button"
                title="The incentive's image when it's shown in a list of other incentives."
                mat-button style="text-align:center;" 
                (click)="addThumbnailFile.click()">
              Add Thumbnail Image
              </button>
              <input hidden (change)="onThumbnailFileSelected($event)" #addThumbnailFile type="file" value="" name="Attach">
              <img *ngIf='this.campaignDetails.thumbnailUrl' [src]='this.campaignDetails.thumbnailUrl' style="max-width: 100px;"/>

    </div>

    <div>
      <mat-checkbox [(ngModel)]="campaignDetails.isActive" labelPosition="after">
        Campaign Is Active
     </mat-checkbox>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="campaignDetails.isLocked" labelPosition="after">
        Campaign Is Locked
     </mat-checkbox>
    </div>

    <!-- MILESTONE VS COMPETITION -->
    <div>
      <mat-tab-group *ngIf='this.getIncentiveTypes().length>0'
        (selectedTabChange)="onTabChanged($event)"
        [selectedIndex]="selectedTabIndex"
        style="display: inline-flex; margin: auto; width: fit-content;">
          <mat-tab *ngFor="let type of this.getIncentiveTypes()">
            <ng-template mat-tab-label style="height: 100px;">
                <span class="my-custom-label"> {{type}} </span>
            </ng-template>
      
          </mat-tab>
        </mat-tab-group>
      
        <div *ngIf='showCompetitionEditor()' class="editing-frame">
          <!-- <app-new-competition-incentive></app-new-competition-incentive> -->
          <app-edit-tournament-rounds #roundsComponent [_rounds]='this.campaignDetails?.rounds'></app-edit-tournament-rounds>
        </div>
      
      
        <div *ngIf='showMilestoneEditor()' class="editing-frame" style="display: block; padding-right: 20px;">
          <app-new-milestone-incentive [campaignDetails]='this.campaignDetails' style="max-width: 40%;"></app-new-milestone-incentive>
          <div *ngFor='let milestone of this.campaignDetails.milestones' style="max-width: 40%;">
            <app-prize [prize]='milestone'></app-prize>
          </div>
        </div>
    
    </div>

  </ion-grid>

</ion-content>
