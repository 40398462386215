import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { DataReportMenu, GroupByOption, ShowOption, TextFilter } from '../../models/dashboard-report-list.model';
import { DashboardData, UserData } from '../../models/dashboard.model';
import { ReportCell, ReportCellList } from '../../models/report-cell-list.model';
import { ReportDataPoint, ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-drill-down-card',
  templateUrl: './drill-down-table-card.component.html',
  styleUrls: ['./drill-down-table-card.component.scss'],
})
export class DrillDownTableCardComponent implements OnInit {

  savedReportMenu: DataReportMenu;
  savedReportCellList: ReportCellList;

  @Input() set reportCellList(newReportCellList: ReportCellList) {
    this.savedReportCellList = newReportCellList;
    this.updateDataSource(this.getTableData(newReportCellList))
  }

  @Input() set reportMenu(newReportMenu: DataReportMenu) {
    this.savedReportMenu = newReportMenu;
    this.xHeader = this.updateXHeader(newReportMenu)
    this.yHeader = this.updateYHeader(this.savedReportCellList)

    if (this.xHeader === 'Sales Rep' && this.savedReportCellList !== undefined) {
      this.updateDataSource(this.getTableData(this.savedReportCellList))

    }
  }

  data: ReportDataPoint[];

  @Input()
  xHeader: string = "";

  @Input()
  yHeader: string = "";

  displayedColumns: string[] = ['date', 'dataPointValue'];
  dataSource: MatTableDataSource<ReportDataPoint>;



  constructor(public dialog: MatDialog,
    public router: Router) {

  }


  getTableData(reportCellList: ReportCellList): ReportDataPoint[] {
    let output: ReportDataPoint[] = [];
    if (reportCellList.list) {
      if (this.xHeader === 'Sales Rep') {
        output = reportCellList.list.map((cell: ReportCell) => {
          return {
            x: (cell.cellList[0].employee) ? cell.cellList[0].employee.fullName : cell.cellList[0].value,
            y: (cell.cellList[1]) ? cell.cellList[1].value : null
          }
        })

      }
      else {
        output = reportCellList.list.map((cell: ReportCell) => {
          return {
            x: cell.cellList[0] ? this.truncateTextIfNecessary(cell.cellList[0].value) : "-",
            y: cell.cellList[1] ? this.truncateTextIfNecessary(cell.cellList[1].value) : "-",

          }
        })
      }
    }
    output = output.slice(0, 4);
    return output;
  }

  truncateTextIfNecessary(value: string) {
    if (!value){return ""}
    return value.length > 31 ? value.substring(0, 30) : value
  }

  ngOnInit() {
    // this.setUpDataSource(this.data);
  }

  setUpDataSource(data: ReportDataPoint[]) {
    this.dataSource = new MatTableDataSource(data);
  }

  updateDataSource(data: ReportDataPoint[]) {
    if (this.dataSource) {
      this.dataSource.data = data
    } else {
      this.setUpDataSource(data)
    }
  }

  updateXHeader(reportMenu: DataReportMenu): string {
    if (!reportMenu || !reportMenu.stratList) {
      return ''
    }
    let groupByOption: GroupByOption = reportMenu.stratList.find(x => x.field === reportMenu.selectedStratField)
    if (groupByOption !== undefined) {
      return groupByOption.text;
    }
    else {
      return '';
    }
  }

  updateYHeader(reportCellList: ReportCellList): string {
    if (reportCellList?.columnHeaders?.length < 2) { return "" }
    if (!reportCellList?.columnHeaders) { return ""}
    return reportCellList?.columnHeaders[1] ? reportCellList?.columnHeaders[1] : "";
  }

}
