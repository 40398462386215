import { Component, OnInit } from '@angular/core';
import { getEndOfCurrentWeek, getStartOfCurrentWeek } from 'src/app/shared/services/dates.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadsStoreService } from '../../leads-store.service';
import { LeadModel } from '../../leads.model';
import { Tallys, TallyStatsMap } from '../tallys';

@Component({
  selector: 'enzy-tally-stats',
  templateUrl: './tally-stats.component.html',
  styleUrls: ['./tally-stats.component.scss'],
})
export class TallyStatsComponent implements OnInit {
  // TODO: we should make a start/end date component
  startEndTime: {startTime: string, endTime: string} = {
    startTime: getStartOfCurrentWeek() + "",
    endTime: getEndOfCurrentWeek() + "",
  }
  userId: number;

  constructor(
    public tallys: Tallys,
    public leadsStore: LeadsStoreService,
    public userDetailsService: UserDetailsService,

  ) { 
    this.initUserId();
  }

  ngOnInit() {}

  private async initUserId() {
    this.userId = +(await this.userDetailsService.getUserId())
  }

    // *************** TALLIED STATS ***************

  private getStats(): TallyStatsMap {
    return this.tallys.getTallyStatsForDates(new Date(this.startEndTime.startTime), new Date(this.startEndTime.endTime));
  }

  public getStatKeys(): string[] {
    return Object.keys(this.getStats());
  }

  public getStatValue(key: string): number {
    return this.getStats()[key];
  }

  public getStatPercentageValue(key: string, index: number): number {
    if (index === 0) return null;
    const previousKey: string = this.getStatKeys()[index - 1];
    return this.getStatValue(key) / this.getStatValue(previousKey);
  }

  public getStatRatio(key: string, index: number): number {
    if (index === 0) return null;
    const previousKey: string = this.getStatKeys()[index - 1];
    return this.getStatValue(previousKey) / this.getStatValue(key)
  }

  // *************** CALCULATED STATS ***************

  private getLeadsSubmitted(): LeadModel[] {
    return this.leadsStore.getMyLeadsInRange(new Date(this.startEndTime.startTime), new Date(this.startEndTime.endTime), this.userId);
  }

  public getLeadsSubmittedTotal(): number {
    return this.getLeadsSubmitted()?.length;
  }

  public getLeadsThatSatTotal(): number {
    return this.getLeadsSubmitted()?.filter(l=>l.isCompleted).length;
  }

  public getLeadsThatSatRatio(): number {
    return this.getLeadsSubmittedTotal() / this.getLeadsThatSatTotal();
  }

  public getLeadsRatio(): number {
    const lastKey: string = this.getStatKeys()[this.getStatKeys().length - 1];
    return this.getStatValue(lastKey) / this.getLeadsSubmittedTotal();
  }

  public loadExtraStats() {
    // TODO: Get projects from backend based on start end date
  }

  showLawOfAverages(): boolean {
    return this.leadsStore.leadsService.leadOptionsConfig.shouldShowLawOfAverages;
  }


  private getStatsDebug(): string {
    return JSON.stringify(this.getStats());
  }

  ///-----------------------------------

  


}
