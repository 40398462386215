<ion-header class="ion-no-border app-background-color">
  <ion-toolbar class="transparent-bg" style="max-width: 1200px; display: block; margin: auto;">
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button (click)="goBack()">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>


    <div slot="end">
      <button mat-button class="primary-color">
        Done
      </button>
    </div>


  </ion-toolbar>
</ion-header>

<ion-content class="app-background-color">
  <div style="padding: 20px;">

    <input hidden (change)="onThumbnailSelected($event)" #fileInputThumbnail type="file" name="image">

    <ion-toolbar style=" width: 100%; height: 60px; position: sticky;top: 0;   z-index: 999; ">
      <div style="  padding: 10px;">
        <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
          <div style="width: 100%;">
            <button class="filter-button" mat-button style="width: 100%;">
              <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
                search
              </i>
              <mat-form-field floatLabel='never' appearance="none"
                style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
              </mat-form-field>
            </button>
          </div>
          <div>
            <button (click)="openAddNewModal()" class=" primary-color" style=" border-radius: 22px;
            font-size: 14px;
            width: 100%;
            height: 40px;" mat-button>
              <i class="material-icons">
                add
              </i>
            </button>
          </div>
        </div>
      </div>
    </ion-toolbar>


    <ion-grid>
      <ion-row>
        <ion-col size="auto" style="width: 100px">
          <ion-row>

            <button mat-raised-button [matMenuTriggerFor]="menu">
              <span class="zeroLineHeight">{{this.libraryService.selectedResourceType}}</span>
              <i class="material-icons">
                arrow_drop_down
              </i>
            </button>
          </ion-row>
          <mat-menu #menu="matMenu">
            <ng-template matMenuContent>

              <button mat-menu-item (click)="updatedSelectedTab('Podcasts')">
                <mat-icon>podcasts</mat-icon>
                <span>Podcasts</span>
              </button>


              <button mat-menu-item (click)="updatedSelectedTab('Videos')">
                <mat-icon>ondemand_video</mat-icon>
                <span>Videos</span>
              </button>


              <button mat-menu-item (click)="updatedSelectedTab('Documents')">
                <mat-icon>description</mat-icon>
                <span>Documents</span>
              </button>


            </ng-template>
          </mat-menu>

          <div style="height: 10px;"></div>

          <mat-divider></mat-divider>

          <div style="height: 10px;"></div>

          <ion-row>
            <button mat-button
              [ngClass]="{'primary-color': (this.selectedTab === 'Resources'), 'non-highlighted-theme': (this.selectedTab !== 'Resources') }"
              (click)="setSelectedTab('Resources')">Resources</button>
          </ion-row>

          <ion-row>
            <button mat-button
              [ngClass]="{'primary-color': (this.selectedTab === 'Series'), 'non-highlighted-theme': (this.selectedTab !== 'Series') }"
              (click)="setSelectedTab('Series')">Series</button>
          </ion-row>

          <ion-row>
            <button mat-button
              [ngClass]="{'primary-color': (this.selectedTab === 'Sections'), 'non-highlighted-theme': (this.selectedTab !== 'Sections') }"
              (click)="setSelectedTab('Sections')">Sections</button>
          </ion-row>

        </ion-col>

        <ion-col style="width:100%">

          <!-- ============================================ RESOURCES ============================================ -->

          <div class="mat-elevation-z8" [hidden]="selectedTab !== 'Resources'">
            <table mat-table [dataSource]="dataSource_resources" matSort #sortResources>

              <ng-container matColumnDef="thumbnailUrl">
                <th mat-header-cell *matHeaderCellDef> Thumbnail </th>
                <td mat-cell *matCellDef="let row">
                  <img *ngIf="row.thumbnailUrl" [src]="row.thumbnailUrl"
                    style="width: 50px; height: 30px; object-fit: cover;" />
                  <button class="primary-color" *ngIf="!row.thumbnailUrl" mat-button
                    (click)="fileInputThumbnail.click()">
                    <span class="material-icons iconAlign">
                      add
                    </span>
                  </button>

                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let row">
                  <input matInput type="text" [(ngModel)]="row.title" (ngModelChange)="setEditMode()">
                </td>
              </ng-container>

              <ng-container matColumnDef="trainerName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
                <td mat-cell *matCellDef="let row">
                  <input matInput type="text" [(ngModel)]="row.trainerName" (ngModelChange)="setEditMode()">
                </td>
              </ng-container>

              <ng-container matColumnDef="section">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
                <td mat-cell *matCellDef="let row">
                  <button
                    [ngClass]="{'primary-color': row.section === '' || !row.section, 'opaque-div' : row.section !== '' && row.section }"
                    mat-button (click)="selectRow(row)" [matMenuTriggerFor]="sectionMenu" mat-button>{{row.section}}
                    <span *ngIf="row.section !== '' && row.section" class="material-icons iconAlign">
                      arrow_drop_down
                    </span>
                    <span *ngIf="row.section === '' || !row.section" class="material-icons iconAlign">
                      add
                    </span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Series </th>
                <td mat-cell *matCellDef="let row">
                  <button
                    [ngClass]="{'primary-color': row.category === '' || !row.category, 'opaque-div' : row.category !== '' }"
                    [matMenuTriggerFor]="seriesMenu" mat-button>{{row.category}}
                    <span *ngIf="row.category !== ''" class="material-icons iconAlign">
                      arrow_drop_down
                    </span>
                    <span *ngIf="row.category === ''" class="material-icons iconAlign">
                      add
                    </span>
                  </button>
                </td>
              </ng-container>



              <ng-container matColumnDef="resourceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                <td mat-cell *matCellDef="let row">
                  <button class='opaque-div' [matMenuTriggerFor]="typeMenu" mat-button>{{row.resourceType}}
                    <span class="material-icons iconAlign">
                      arrow_drop_down
                    </span>
                  </button>
                </td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row">

                  <mat-spinner [diameter]="20" *ngIf="row.status === 'uploading'"></mat-spinner>

                  <span *ngIf="row.status === 'uploaded' && !row.inEditMode" class="material-icons"
                    style="color: green;">
                    check_circle
                  </span>
                  <button mat-button (click)="upload(row)" *ngIf="row.status === 'failed' && !row.inEditMode">
                    Failed. Try Again.
                  </button>

                  <div [hidden]="row.status === 'uploading'">
                    <button mat-button (click)="upload(row)" *ngIf="!row.status || row.inEditMode">
                      Upload
                    </button>
                  </div>
                </td>
              </ng-container>


              <mat-menu #sectionMenu="matMenu">
                <ng-template matMenuContent>
                  <div *ngFor="let s of this.libraryService.allSections">
                    <button mat-menu-item style="text-align: center;" (click)="updateSection(s.sectionTitle)">
                      {{s.sectionTitle}}
                    </button>
                  </div>

                  <mat-divider></mat-divider>
                  <button mat-menu-item style="text-align: center;" (click)="addNewSection()">
                    <span class="material-icons iconAlign">
                      add
                    </span>
                    Add New
                  </button>
                </ng-template>

              </mat-menu>

              <mat-menu #seriesMenu="matMenu">
                <ng-template matMenuContent>
                  <div *ngFor="let s of getSeriesToDisplay()">
                    <button mat-menu-item style="text-align: center;" (click)="updateSeries(s)">
                      {{s}}
                    </button>
                  </div>

                  <mat-divider></mat-divider>
                  <button mat-menu-item style="text-align: center;" (click)="addNewSeries()">
                    <span class="material-icons iconAlign">
                      add
                    </span>
                    Add New
                  </button>

                </ng-template>
              </mat-menu>

              <mat-menu #typeMenu="matMenu">
                <ng-template matMenuContent>
                  <button mat-menu-item style="text-align: center;" (click)="updateType('video')">
                    video
                  </button>
                  <button mat-menu-item style="text-align: center;" (click)="updateType('podcast')">
                    podcast
                  </button>
                  <button mat-menu-item style="text-align: center;" (click)="updateType('document')">
                    document
                  </button>
                </ng-template>
              </mat-menu>

              <tr mat-header-row *matHeaderRowDef="displayedColumns_resources"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns_resources;" (click)="selectRow(row)"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator #paginatorResources [pageSizeOptions]="[25, 100]" aria-label="Select page of users">
            </mat-paginator>
          </div>

          <!-- ============================================ SERIES ============================================ -->

          <div class="mat-elevation-z8" [hidden]="selectedTab !== 'Series'">
            <table mat-table [dataSource]="dataSource_categories" matSort #sortCategories>


              <ng-container matColumnDef="backgroundImage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Image </th>
                <td mat-cell *matCellDef="let row">
                  <img style="height: 150px; width: 100px; object-fit:cover" [src]="row.backgroundImage" />
                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let row">
                  {{row.title}}
                </td>
              </ng-container>

              <ng-container matColumnDef="section">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
                <td mat-cell *matCellDef="let row">
                  {{row.section}}
                </td>
              </ng-container>


              <ng-container matColumnDef="videos">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Resources </th>
                <td mat-cell *matCellDef="let row">
                  {{row.resources.length}} Resources
                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns_categories"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns_categories;"
                (click)="selectRow_categories(row)"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator #paginatorCategories [pageSizeOptions]="[25, 100]" aria-label="Select page of users">
            </mat-paginator>
          </div>

          <!-- ============================================ SECTIONS ============================================ -->

          <div class="mat-elevation-z8" [hidden]="selectedTab !== 'Sections'">
            <table mat-table [dataSource]="dataSource_sections" matSort #sortSections>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let row">
                  {{row.sectionTitle}}
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns_sections"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns_sections;" (click)="selectRow_sections(row)">
              </tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>
            </table>

            <mat-paginator #paginatorSections [pageSizeOptions]="[25, 100]" aria-label="Select page of users">
            </mat-paginator>
          </div>


        </ion-col>
      </ion-row>
    </ion-grid>



  </div>
</ion-content>