import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { Task } from '../leads.model';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'enzy-task-row',
  templateUrl: './task-row.component.html',
  styleUrls: ['./task-row.component.scss'],
})
export class TaskRowComponent implements OnInit {

  @Input() task: Task;

  @Output() saveTaskChanges = new EventEmitter<Task>();

  constructor(
    public dateFormatService: DateFormatService,
    public leadsService: LeadsService,

  ) { }

  ngOnInit() { }


  check(event) {
    let checkStatus = event.checked;
    this.task.isCompleted = checkStatus;

    this.saveTaskChanges.next(this.task);
    //TODO: save lead

  }

  getDate() {
    return this.dateFormatService.formatDateFromString(this.task.dueDate)
  }





}
