import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { BackendUser, Employee } from 'src/app/shared/models/employees.model';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { DatesService } from 'src/app/shared/services/dates.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, MessagingService, ParticipantModel } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-conversation-cell',
  templateUrl: './conversation-cell.component.html',
  styleUrls: ['./conversation-cell.component.scss'],
})
export class ConversationCellComponent implements OnInit {

  sliceVal: number = 24
  userId: string;
  conversationWasClicked: boolean = false;

  @Input() conversation: ConversationModel;
  @Input() isMobile: boolean;
  @Input() isPinnedIcon: boolean = false;


  constructor(public conversationService: ConversationService, public dateService: DatesService, public messagingService: MessagingService, public messagingStoreService: MessagingStoreService, public _bottomSheet: MatBottomSheet, public userDetailsService: UserDetailsService) { }

  conversationIsMuted: boolean = false;
  ngOnInit() {
    this.initUserId()
  }

  async initUserId() {
    this.userId = await this.userDetailsService.getUserId();
    // const result = this.conversationService.setConversationPinned(this.messagingStoreService.getStoredConvoId(), true);
    const result = await this.messagingService.conversationIsMutedForMe(this.conversation);
    this.conversationIsMuted = result;
  }

  getConversationTitle(): string {
    if (this.conversation.title) {
      return this.conversation.title;
    } else {

      let title = this.generateTitleFromParticipants();
      if (title === "") {
        return "Me"
      } else {
        return title;
      }
    }
  }

  getLatestMessage(): string {
    return this.conversation.latestMessage ? this.conversation.latestMessage : "";
  }

  conversationClicked() {


    this.getUnreadMessageCount();
  }

  generateTitleFromParticipants(): string {
    if (this.conversation.participants.length === 2) {
      return this.conversation.participants.filter(usr => usr.id != this.userId).map(user => this.getFullName(user)).join(', ');
    } else {
      return this.conversation.participants.filter(usr => usr.id != this.userId).map(user => this.getpreferredFirstNameLastInitial(user)).join(', ');
    }
  }

  getFullName(user: ParticipantModel): string {
    const first: string = user.preferredFirstName ? user.preferredFirstName : '';
    const last: string = user.lastName ? user.lastName : '';
    return first + " " + last;
  }

  getpreferredFirstNameLastInitial(user: ParticipantModel): string {
    const lastName: string = user?.lastName;
    const lastInitial: string = lastName?.length > 0 ? lastName[0] : "";
    return user.preferredFirstName ? user.preferredFirstName : '' + " " + lastInitial;
  }


  getImageUrl(): string {
    // If there's a url use that with user-icon


    // Otherwise, if there's one participant, return the employee object with user-icon

    // otherwise if there's many, then use a multiple-icon component


    return this.conversation.thumbnailImageUrl;
  }

  shouldUseUserIconComponent(): boolean {
    return (!!this.conversation.imageUrl && this.conversation.imageUrl.length > 0)
      || this.conversation.participants.length <= 2;
  }

  getEmployee(): Employee {
    //If the conversation has a image url then we don't want the app-user-icon component to default to the first employee
    if (this.getImageUrl()) {
      return null;
    }
    else {
      const output = new Employee();
      let user = new BackendUser();

      let otherUser: ParticipantModel;

      if (this.conversation.participants.length === 1) {
        otherUser = this.conversation.participants[0];
      } else {
        otherUser = this.conversation.participants.find(user => user.id !== this.userId)
      }

      user.preferredFirstName = otherUser.preferredFirstName;
      user.lastName = otherUser.lastName;
      user.imageUrl = otherUser.imageUrl; // TODO: Make this a real url
      output.userDto = { ...user, };

      return output;
    }

  }

  isGif(): boolean {

    let msg = this.getLatestMessage();

    let isGiphy = msg.includes("https://") && msg.includes("giphy.com");

    return isGiphy;
  }



  isReply(): boolean {

    let msg = this.getLatestMessage();


    if (msg[0] === "R" && msg[1] === "$" && msg[2] === "P" && msg[3] === "L" && msg[4] === "Y") {
      return true
    } else {
      return false
    }
  }

  public humanFriendlyDate(inputDate: string): string {
    return this.dateService.humanFriendlyDate(inputDate);
  }

  public getUnreadMessageCount(): number {
    if (this.conversation.unreadMessageCount >= 99) {
      return 99
    } else {
      return this.conversation.unreadMessageCount;
    }
  }

  public shouldShowUnreadMessageCount(): boolean {
    return this.conversation.unreadMessageCount > 0;
  }



  public isPinned(): boolean {
    return this.messagingService.conversationIsPinnedForMe(this.conversation, this.userId);
  }

  pressed() {
  }

  released() {
  }
}
