<ion-grid>
 

  {{this.messages?.length}} Photos
  <ion-row>

    <ion-col size="4" *ngFor="let message of this.messages" style="height: 100px; width: 100px; padding:2px;  background-color: black;">

      <img (click)="expandImage(message.messageImage)" style="height: 100%; width: 100%; object-fit: cover;" [src]="getImageUrlForThumbnail(message)" />

    </ion-col>

  </ion-row>

  <!-- <ion-row>
    <button mat-button style="width: 100%;" (click)="fetchMore()"> Fetch More </button>
  </ion-row> -->
</ion-grid>

