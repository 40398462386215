import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { BackendUser } from '../../models/employees.model';
import { Poll, PollOption, PollResponse } from '../../models/poll.model';
import { EmployeeStoreService } from '../../services/employee-store.service';
import { MessagingSocketService } from '../../services/messaging-socket.service';
import { PollService } from '../../services/poll.service';
import { UserDetailsService } from '../../services/user-details.service';
import { VoterDetailsComponent } from '../poll/voter-details/voter-details.component';

@Component({
  selector: 'app-poll-card',
  templateUrl: './poll-card.component.html',
  styleUrls: ['./poll-card.component.scss'],
})
export class PollCardComponent implements OnInit {

  poll: Poll;
  barChartValue: number = 0;
  isActive: boolean = true;
  isExpanded: boolean = false;
  userId: string = "";

  @Input() set pollInput(newPoll: Poll) {
    this.poll = newPoll;
    this.options = this.poll.options;
  }

  @Input() conversationId: number;
  @Input() messageId: number;

  options: PollOption[] = [];


  constructor(
    private _bottomSheet: MatBottomSheet,
    public pollService: PollService,
    public userDetailsService: UserDetailsService,
    public employeeStoreService: EmployeeStoreService,
    public messagingSocketService: MessagingSocketService,
  ) {

   }

  ngOnInit() { 
    this.pollIsStillOpen();

    this.initUserId();
  }

  async initUserId() {
    this.userId = await this.userDetailsService.getUserId();
  }

  pollIsStillOpen() {
    if (this.poll.endTime === null) {
      this.isActive = true;
      return;
    }

    let now = new Date();
    let endDate = new Date(this.poll.endTime);

    if (now > endDate) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  userHasVotedForOption(option: PollOption): boolean {
    return option.responses.filter(r=> +r.userId === +this.userId).length > 0;
  }

  castOrRemoveVote(option: PollOption) {
    if (this.userHasVotedForOption(option)) {
      this.removeVote(option);
    } else {
      this.castVote(option);
    }
  }

  async removeVote(option: PollOption) {
    this.pollIsStillOpen();
    if (!this.isActive) {return}

    const res = await this.pollService.removeVote(option, this.poll, this.conversationId, this.messageId);
    const removalWorked: boolean = res;
    if (removalWorked) {
      const userId: number = +(await this.userDetailsService.getUserId());
      this.pollService.removeUserVotesFromPollOption(this.poll, option, userId);
    }
  }

  async castVote(option: PollOption) {
    this.pollIsStillOpen();
    if (!this.isActive) {return}

    const res = await this.pollService.castVote(option, this.poll, this.conversationId, this.messageId);
    const voteWorked: boolean = res;
    if (voteWorked && !this.poll.multipleAnswers) {
      const userId: number = +(await this.userDetailsService.getUserId());
      this.pollService.removeUserVotesFromPollObject(this.poll, userId);
      const tempResponse: PollResponse = {
        id: null,
        userId: userId,
        optionId: option.id
      }
      this.pollService.addVoteToPollObject(this.poll, tempResponse);
    }
  }

  displayWhoVoted(option: PollOption) {
    let responses = option.responses;

    responses.forEach(response => {
      // console.log(response)
    })
    
  }

  getUserFromResponse(response: PollResponse): BackendUser {
    return this.employeeStoreService.getUser(response.userId).userDto;
  }

  getVoterString(option: PollOption) {
    if (option.responses.length === 0) {return ""}
    else if (option.responses.length === 1){
      let output = "";
      let user = this.getUserFromResponse(option.responses[0]);
      output = user.preferredFirstName;
      return output;
    } else {
      let output = "";
      let user = this.getUserFromResponse(option.responses[0]);
      output = user.preferredFirstName;
      output += " and " + (option.responses.length - 1) + " more";

      return output;
    }

  }

  getVoterFullName(response: PollResponse) {
    if (response.userId) {
      let user = this.getUserFromResponse(response);
      return user.fullName
    } else {
      return "User"
    }
  }


  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  openVoterDetails(option: PollOption){
    let ref = this._bottomSheet.open(VoterDetailsComponent, {
      data: {
        option: option.description,
        responses: option.responses.map(r => this.getUserFromResponse(r))
      }
    });
  }
}
