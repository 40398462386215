<ion-header class="ion-no-border app-background-color">
  <ion-toolbar class="transparent-bg" style="max-width: 600px; display: block; margin: auto;">
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button (click)="goBack()">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>

    <!-- <div slot="end">
      <button mat-button (click)="delete()">
        Delete
      </button>
    </div> -->

    <div slot="end">
      <button mat-button (click)="save()" class="primary-color">
        Save
      </button>
    </div>
    <!-- <div slot="end">
      <div style="width: 10px;"></div>
    </div>

    <div slot="end">
      <button mat-button class="primary-color">
        Publish
      </button>
    </div> -->
  </ion-toolbar>
</ion-header>

<ion-content class="app-background-color">
  <div style='max-width: 600px; display: block; margin: auto;'>
    <ion-grid>
      <ion-row>
        <ion-col size="12" style="padding: 0; margin: 0;">
          <ion-row style="padding: 0; margin: 0;">
            <mat-form-field style="width: 100%;">
              <input [(ngModel)]="section.sectionTitle" class="non-bolded-placeholder" matInput
                placeholder="Section Title" style="width: 100%;">
            </mat-form-field>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="canChangeType">
        <ion-col size="6">
          <button mat-button class="bigButton" (click)="setType('series')"
            [ngClass]="{'selected': this.type === 'series', 'not-selected': this.type !== 'series' }">
            <span class="material-icons" style="font-size: 40px;">
              movie
            </span>
            <br>
            Add Series
          </button>

        </ion-col>

        <ion-col size="6">
          <button mat-button class="bigButton" (click)="setType('videos')"
            [ngClass]="{'selected': this.type === 'videos', 'not-selected': this.type !== 'videos' }">
            <span class="material-icons" style="font-size: 40px;">
              auto_awesome_motion
            </span>
            <br>
            Add Resources
          </button>

        </ion-col>
      </ion-row>


      <div *ngIf="this.type === 'series'">
     

        
        <ion-toolbar class="transparent-bg">
          <div slot="start">
            <p>{{this.series.length}} Series</p>
          </div>

          <div slot="end">
            <button mat-raised-button class="primary-color" (click)="addNewSeries()"
              style="display: block; margin: auto;">
              <span class="material-icons">
                add
              </span>

            </button>
          </div>
        </ion-toolbar>

        <ion-row>
          <ion-col size="6" (click)="openSeries(series)" *ngFor="let series of this.series">
            <app-series-card style="display: block; margin: auto;" [backgroundImage]="series.backgroundImage"
              [isXL]="true"></app-series-card>
            <p style="text-align: center;">{{series.title}} <br> <span style="font-weight: 100;"
                class="opaque-text">{{series.resources.length}} Video<span
                  *ngIf="series.resources.length !== 1">s</span></span></p>
          </ion-col>
        </ion-row>

    
      </div>


      <div *ngIf="this.type === 'videos'">

        <ion-toolbar class="transparent-bg">
          <div slot="start">
            <p>{{this.videos.length}} Resource<span *ngIf="this.videos.length !== 1">s</span></p>
          </div>

          <div slot="end">
            <button mat-raised-button class="primary-color" (click)="addNewVideo()"
              style="display: block; margin: auto;">
              <span class="material-icons">
                add
              </span>

            </button>
          </div>
        </ion-toolbar>

        <ion-toolbar class="transparent-bg" *ngFor="let video of this.videos">
          <div slot="start">
            <app-video-card [displayMode]="'small'" style="display: block; margin: auto;" [video]="video">
            </app-video-card>
          </div>
          <div slot="start">
            <div style="width: 10px;"> </div>
          </div>
          <div slot="start" style='font-size: 12px;'>
            <ion-row>
              <input style="min-width: 300px;" matInput type="text" [(ngModel)]="video.title">
            </ion-row>

            <ion-row style="margin-top: 10px;">
              <input style="min-width: 300px;" class="opaque-text" matInput type="text" [(ngModel)]="video.trainerName">
            </ion-row>

          </div>


          <div slot="end">
            <button mat-icon-button (click)="editVideo(video)">
              <span class="material-icons">
                edit
              </span>
            </button>
          </div>

          <div slot="end">
            <button mat-icon-button (click)="deleteVideo(video)">
              <span class="material-icons">
                delete
              </span>
            </button>
          </div>

        </ion-toolbar>

      </div>



    </ion-grid>
  </div>
</ion-content>