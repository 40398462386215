<div>
    <ion-toolbar class="ion-align-items-start toolbar-wrapper transparent-bg">
        <div slot="start">
            <div style="width: 5px"></div>
        </div>
        <div slot="start" style="min-width: 45px; align-self: flex-start;">
            <img [hidden]="!showUserIcon" (click)="clickProfile()" class="messageImage" [src]="getImageUrl()" />
        </div>


        <div slot="start">
            <div style="width: 10px"></div>
        </div>

        <div slot="start" style="align-items: flex-start;  width: 100%;">
            <ion-grid class="spacing" long-press (press)="openMessageOptions()" style="--min-height: 30px;">

                <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="message.messageId === 'Failed'">
                        <span>{{this.message.fromUserName}} </span> &nbsp;
                    </ng-container>


                    <ng-container *ngSwitchCase="message.messageId !== 'Failed' && showUserIcon">
                        <span>{{this.message.fromUserName}} </span> &nbsp; <span *ngIf="showUserIcon"
                            style="text-align: right;" class="spacing timeLabel">
                            {{humanFriendlyDate(this.message.sendDate)}}</span>
                    </ng-container>

                    <ng-container *ngSwitchCase="isNewMessageType(message)">
                        <ion-row style="padding: 0px 0 5px 0" class="spacing contentLabel">
                            <ion-label text-wrap>
                                <span style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                                <!-- <span>{{this.message.reportId}}</span> -->
                                <div style='color: red' class='opaque-text'> {{getNewMessageTypeMessage(message)}}</div>
                            </ion-label>
                        </ion-row>
                    </ng-container>

                    <ng-container *ngSwitchCase="isTextMessage(message)">
                        <ion-row style="padding: 0px 0 5px 0" class="spacing contentLabel">
                            <ion-label text-wrap>
                                <span style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}

                                </span>
                                <button *ngIf='messageHasReport()' mat-raised-button (click)='openReport()'>Message
                                    Users</button>
                            </ion-label>
                        </ion-row>
                    </ng-container>
                    <ng-container *ngSwitchCase="isLeadMessage(message)">

                        <div class="poll-div contentLabel"
                            style="padding:0px; text-align: left; min-width: 300px; min-height: 40px;">
                            <enzy-lead-changes-card [messageInput]="message"></enzy-lead-changes-card>

                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="isReportMessage(message)">

                        <div class="poll-div contentLabel"
                            style="padding:0px; text-align: left; min-width: 300px; min-height: 40px;">
                            <!-- <span>{{message.reportLines[0]}}</span> -->
                            <app-enzy-assistant-card [messageInput]="message"
                                (activeReportLines)='this.setActiveReportLines($event)'></app-enzy-assistant-card>

                        </div>

                    </ng-container>


                    <ng-container *ngSwitchCase="isSurveyResponseDescriptionMessage(message)">
                        <div style="min-width: 300px; max-width: 400px;">
                            <enzy-dashboard-alert-card [alert]="message.surveyResponseDescription" (onSurveyWasDispositioned)="surveyDispositioned($event)">
                            </enzy-dashboard-alert-card>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="isReplyMessage(message)">

                        <ion-row>
                            <app-reply-card [message]="message"></app-reply-card>
                        </ion-row>
                        <ion-row>
                            <ion-label text-wrap style="font-size: 17px; font-weight: 100;">
                                {{this.message.contents.split("π")[3]}}
                            </ion-label>
                        </ion-row>

                    </ng-container>
                    <ng-container *ngSwitchCase="isImageMessage(message)">
                        <div *ngIf="message.selectedFile">

                            <div style="display: block; margin: auto;" (click)="expandImage(this.message)"
                                style="max-width: 500px;">
                                <img [src]="imgURL" [ngStyle]="{ 'width': this.width, 'height':this.height }"
                                    style="z-index: 1; object-fit: cover;" *ngIf="imgURL">
                            </div>
                        </div>
                        <div *ngIf="!message.selectedFile" style="z-index: 1; " (click)="expandImage(this.message)">

                            <div [ngStyle]="styleObject()" class="skeleton"
                                style="max-height: 400px; max-width: 700px;">
                                <div style="width: 100%; height: 100%; max-height: 400px; max-width: 700px; background-position: center; background-size: cover;object-fit: cover;"
                                    [ngStyle]="{'background-image': 'url(' + this.getImage() + ')'}">
                                    <!-- <img style="width: 100%; height: 100%; max-height: 400px; max-width: 700px; object-fit: cover;"
                                        [src]="this.message.messageImage" /> -->

                                    <!-- <div [hidden]="imageLoaded"> -->
                                    <ion-img [src]="this.message.messageImage"
                                        style="width: 100%; height: 100%; max-height: 400px; max-width: 700px; object-fit: cover;">
                                    </ion-img>
                                    <!-- </div> -->

                                </div>
                            </div>


                        </div>
                        <ion-row *ngIf='message?.contents?.length > 0' style="padding: 0px 0 5px 0"
                            class="spacing contentLabel">
                            <ion-label text-wrap>
                                <span style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                            </ion-label>
                        </ion-row>


                    </ng-container>

                    <!-- 
                    <div *ngIf="message.contents === 'Calendar' ">
                        <mat-card style="max-width: 400px;">
                            <h4 style="text-align: center;">
                                <span class="material-icons iconAlign">
                                    event
                                </span>
                            </h4>

                            <div style="text-align: center;">
                                <span>Adam's Birthday</span> <br>
                                <span style="font-weight: 100;">Today</span> <br>
                                <span style="font-weight: 100;">4:00 PM to 5:00 PM</span> <br> <br>
                                <span style="font-weight: 100; color: blue;">1234 Washing Ln, Salt Lake City</span> <br>
                                <br>

                            </div>


                            <mat-divider></mat-divider>
                            <ion-toolbar class="transparent-bg">
                                <div slot="start">
                                    Going?
                                </div>

                                <div slot="end">
                                    <button class="primary-color" mat-raised-button>Yes</button>
                                </div>
                                <div slot="end" style="width: 5px;"></div>

                                <div slot="end">
                                    <button mat-raised-button>No</button>
                                </div>
                                <div slot="end" style="width: 5px;"></div>

                                <div slot="end">
                                    <button mat-raised-button>Maybe</button>
                                </div>
                            </ion-toolbar>


                        </mat-card>

                    </div> -->


                    <ng-container *ngSwitchCase="isPollMessage(message)">
                        <div style="height: 5px;"></div>
                        <div style="min-height: 160px;">
                            <app-poll-card [pollInput]="message.poll" [conversationId]='message.convoId'
                                [messageId]='message.messageId'></app-poll-card>
                            <ion-row *ngIf='message?.contents?.length > 0' style="padding: 0px 0 5px 0"
                                class="spacing contentLabel" (isReportMessageclick)="getMoreReactionInfo(null)">
                                <ion-label text-wrap>
                                    <span
                                        style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                                </ion-label>
                            </ion-row>
                        </div>
                    </ng-container>



                    <ng-container *ngSwitchCase="isVideoMessage(message)">

                        <div
                            *ngIf="this.message.messageImage.includes('placeholder-image') && this.message.messageId !== 'Failed' ">
                            <!-- <mat-spinner class="custom-spinner" [diameter]="20"
                            style="display: block; margin: auto; padding-top: 5px"></mat-spinner> -->

                            <div style="width: 100%; height: 200px; background-color: black;">
                                <img src="../../../assets/loading.gif"
                                    style="width: 100%; height: 100%; object-fit: cover" />
                            </div>

                            <ion-row *ngIf='message?.contents?.length > 0' style="padding: 0px 0 5px 0"
                                class="spacing contentLabel">
                                <ion-label text-wrap>
                                    <span
                                        style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                                </ion-label>
                            </ion-row>
                        </div>

                        <div
                            *ngIf="!this.message.messageImage.includes('placeholder-image') && this.message.messageId !== 'Failed' ">
                            <button
                                style="height: 175px; width: 300px; background-color: black; background-size: contain;   background-repeat: no-repeat; background-position: center;"
                                mat-button (click)="openVideo(this.message.messageImage)"
                                [ngStyle]="{'background-image': 'url(' + this.message.messageImage +'.png'+ ')'}">

                                <span class="material-icons specialText" style="font-size: 40px;">
                                    play_circle_filled
                                </span>

                            </button>
                            <!-- <app-video-card style='margin: 0 auto;'  [url]="this.message.messageImage" [backgroundImage]="this.message.messageImage + '.png'"></app-video-card> -->



                            <!-- <video controls playsinline preload="metadata"
                                style="height: 200px; width: 350px; background-color: black;"
                                [poster]="this.message.messageImage + '.png'">
                                <source [src]="this.message.messageImage" type="video/mp4">
                            </video> -->

                            <ion-row *ngIf='message?.contents?.length > 0' style="padding: 0px 0 5px 0"
                                class="spacing contentLabel">
                                <ion-label text-wrap>
                                    <span
                                        style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                                </ion-label>
                            </ion-row>

                        </div>

                        <div *ngIf="this.message.messageId === 'Failed' ">

                            <div style="width: 100%; height: 200px; background-color: black;">
                                <span class="material-icons"
                                    style="line-height: 200px; width: 100%; text-align: center; color: white">
                                    error_outline
                                </span>
                            </div>


                        </div>

                    </ng-container>
                    <ng-container *ngSwitchCase="isGifMessage(message)">
                        <ion-row class="spacing contentLabel">
                            <div style="max-width: 300px; min-height: 100px;" class="gif">

                                <img [src]="this.message.messageImage" (click)="expandImage(this.message)" />
                            </div>
                        </ion-row>
                        <ion-row *ngIf='message?.contents?.length > 0' style="padding: 0px 0 5px 0"
                            class="spacing contentLabel">
                            <ion-label text-wrap>
                                <span style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
                            </ion-label>
                        </ion-row>
                    </ng-container>

                    <ng-container *ngSwitchCase="isUrlMessage(message)">
                        <div style="width: 100%;">
                            <app-link-preview-card [messageContent]="this.message.contents"></app-link-preview-card>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="message.messageId === 'Failed'">
                    <button mat-button class="failed-message" style="padding: 0;" (click)="attemptResend()">
                        <span class="material-icons iconAlign" style="font-size: 16px;">
                            error
                        </span> <span style="margin-bottom: -5px;"> Couldn't send. Tap to try again.</span>
                    </button>
                </ng-container>
            </ion-grid>
            <ion-grid>
                <ion-row class="spacing">
                    <div class="flex">
                        <div *ngFor="let emoji of getReactionEmojis()">

                            <button long-press (press)="getMoreReactionInfo(emoji)" (click)="toggleReaction(emoji)"
                                mat-button class="reactionsLabel"
                                [ngClass]="{'reactions-highlight': reactionIncludesAuthUser(emoji)}">
                                {{emoji}} <span>{{reactionCount(emoji)}}</span>
                            </button>
                        </div>
                    </div>
                </ion-row>
            </ion-grid>
        </div>

        <div slot="end">
            <div style="width: 5px"></div>
        </div>

    </ion-toolbar>
</div>