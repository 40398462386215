
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, from, Observable } from "rxjs";
import { LeadsService } from "./leads.service";
import { LeadModel } from "./leads.model";
import { UserDetailsService } from "../shared/services/user-details.service";
import { dateIsInRange, genericDateFromString, genericDateOnlyFromString } from "../shared/services/dates.service";

@Injectable({
    providedIn: 'root'
})
export class LeadsStoreService {

    private _leads: BehaviorSubject<LeadModel[]> = new BehaviorSubject([]);

    public readonly leads: Observable<LeadModel[]> = this._leads.asObservable();


    constructor(
        public userDetailsService: UserDetailsService,
        public httpClient: HttpClient,
        public localStorage: Storage,
        public leadsService: LeadsService) {
        this.initializeLeads();
    }

    async initializeLeads() {
        // await this.localStorage.create();
        // await this.localStorage.defineDriver(CordovaSQLiteDriver);
        await this.initFromCachedLeads();
        this.fetchLatestLeads();
    }

    async fetchLatestLeads() {
        const result: any = await this.leadsService.getLeads();
        this._leads.next(result);
        this.cacheLeadsList();
    }

    async removeLeadById(leadId: number) {
        const currentLeads: LeadModel[] = this.getLeads();
        this._leads.next(currentLeads.filter(l => l.leadId !== leadId));
        this.cacheLeadsList();

    }


    async initFromCachedLeads() {
        await this.loadCachedLeads();
    }

    public async cacheLeadsList(): Promise<void> {
        setTimeout(() => {
            this.localStorage.set("LEADS_LIST", JSON.stringify(this._leads.getValue()));
        }, 1000);
    }



    public async loadCachedLeads(): Promise<LeadModel[]> {
        const currentListOfLeads: LeadModel[] = this._leads.getValue();

        if (currentListOfLeads?.length > 0) return;
        const res = await this.localStorage.get("LEADS_LIST");
        if (!res) return;
        const cachedListOfLeads: LeadModel[] = JSON.parse(res);

        if (cachedListOfLeads?.length > 0) {
            this._leads.next(cachedListOfLeads);
        }

    }

    public getLeads(): LeadModel[] {
        return this._leads.getValue();
    }

    public updateLeadsLocally(leadIds: number[], newValue: any, field: string) {
        const updatedLeads: LeadModel[] = this.getLeads();
        leadIds.forEach(leadId => {
            let lead = updatedLeads.find(l => l.leadId === leadId);
            if (lead)
                lead[field] = newValue;
        });
        this._leads.next(updatedLeads);
        this.cacheLeadsList();
    }

    public async addNewlyCreatedLead(lead: LeadModel) {
        const updatedLeads: LeadModel[] = this.getLeads();
        lead.setterId = +(await this.userDetailsService.getUserId());
        updatedLeads.push(lead);
        this._leads.next(updatedLeads);
        this.cacheLeadsList();

    }

    public getMyLeadsInRange(start: Date, end: Date, userId: number): LeadModel[] {
        return this.getLeads().filter(l => l.setterId === userId && this.leadIsInDateRange(l, start, end));
    }

    leadIsInDateRange(lead: LeadModel, start: Date, end: Date): boolean {
        // return true;
        if (!lead?.creationDate) return false;
        return dateIsInRange(genericDateOnlyFromString(lead.creationDate), start, end);
    }


    getUnassignedLeads(): LeadModel[] {
        return this.getLeads()?.filter(lead => !lead.assignedRep || lead.assignedRep === 0)
    }

}
