import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-bcc-guard-rail-modal',
  templateUrl: './bcc-guard-rail-modal.component.html',
  styleUrls: ['./bcc-guard-rail-modal.component.scss'],
})
export class BccGuardRailModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BccGuardRailModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  okay() {
    this.dialogRef.close("ok");

  }

  cancel() {
    this.dialogRef.close();
  }

}
