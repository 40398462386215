import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DashboardAlertModel } from 'src/app/cr-dashboard/cr-dashboard.service';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';
import { AccuracyModalComponent } from './accuracy-modal.component';

@Component({
  selector: 'enzy-survey-modal',
  templateUrl: './accuracy-modal.component.html',
  styleUrls: ['./accuracy-modal.component.scss'],
})
export class SurveyModalComponent extends AccuracyModalComponent implements OnInit  {
    getTitle() {
        return "Survey Response Details";
    }
}
