<ion-grid class="opaque-div" style="border-radius: 5px; min-width: 200px; width: 100%;">
  <ion-row>
    <ion-col size="auto" style="width: 40px">
      <div class="vl"></div>
    </ion-col>

    <ion-col  style="width: 100%; text-align: left; font-size:14px; font-weight: 100;">
      
      <div> {{displaySender}} </div>


      <ion-label *ngIf="!isGif() && !isImage() && !isImageResponse() && message.contents !=='' " text-wrap style="text-align: left; font-size:14px; font-weight: 100;">
        <!-- {{displayContent}} -->

        {{ (displayContent.length>80)? (displayContent | slice:0:80)+'..':(displayContent) }}


      </ion-label>

      <!-- <ion-item *ngIf="!isGif() && !isImage() && !isImageResponse() && message.contents !=='' " lines="none" class="item-background-color">
        <ion-label lines="none" class="ion-text-wrap " style="background-color: transparent; color: white; text-align: left;">{{displayContent}}</ion-label>
      </ion-item> -->

      <div *ngIf="isGif()">
        <img src="{{displayContent}}" />
      </div>


      <div *ngIf="isImage()">
        <img style="max-height: 150px" src="{{this.message.messageImage}}" />
      </div>

      <div *ngIf="isImageResponse()">
        <img style="max-height: 150px" src="{{this.displayContent}}" />

      </div>

    </ion-col>
  </ion-row>
</ion-grid>