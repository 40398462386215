import { Injectable } from "@angular/core";
import { DEFAULT_USER_DATA, UserData } from "../models/dashboard.model";
import { Storage } from "@ionic/storage";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { Storage as capStorage } from '@capacitor/storage';
import { SnackbarService } from "./snackbar.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserDetailsService {
  userData: UserData;
  sessionKey: string;


  constructor(
    private localStorage: Storage,
    public snackbarService: SnackbarService,
    public router: Router
  ) {
    this.initService();
  }

  async initService() {
    await this.localStorage.create();
    await this.localStorage.defineDriver(CordovaSQLiteDriver);
    this.localStorage.get("userData").then((result: UserData) => {
      this.userData = result;
      // console.log("FINISHED LOADING USER DETAILS FROM LOCAL STORAGE")
    })
  }

  async ensureUserDataIsLoaded(): Promise<UserData> {
    if (!this.userData) {
      const val = await this.localStorage.get("userData")
      this.userData = val;
      return val;
    }
    return this.userData
  }

async clearSessionKey(): Promise<any> {
  this.sessionKey = null;
  this.userData = null;
  return await capStorage.remove({ key: 'sessionKey' });
}

async setSessionKey(sessionKey): Promise<any> {
  return await capStorage.set({
    key: 'sessionKey',
    value: sessionKey,
  });
}

  async getSessionKey(): Promise<string> {
    if (this.sessionKey) { return this.sessionKey }
    this.sessionKey = (await capStorage.get({ key: "sessionKey" }))?.value

    //This if block should be removed after a few builds. This is a transition from cordova to capacitor storage for sessionKeys
    if (!this.sessionKey && this.router.url === "/login") {
      this.userData = await this.ensureUserDataIsLoaded();
      let sessionKeyFromLocalStorage = this.userData?.sessionKey;
      if (sessionKeyFromLocalStorage) {
        this.setSessionKey(this.sessionKey);
      }
    }    

    //I'm not a huge fan of this. This is a fix for the external leads. Something about that page's URL isn't initialized in time so we're getting a race condition. 
    // If we don't have this, the app kicks out the user trying to submit an external lead
    if (this.router.url === "/"){
      await this.delay(2000)
    }

    //This is to make sure there is still a session key. This will also keep users out if they should be.
    if (!this.sessionKey && this.router.url !== "/login" && this.router.url !== "/join-sunder" && this.router.url !== "/reset-password" && !this.router.url.includes("/lead-submission")) {
      this.snackbarService.displaySnackBarMessage("Invalid session key. Logging out.");
      await this.clearSessionKey();
      this.router.navigate(["/login"]);
      return
    }

    return this.sessionKey;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getUserId(): Promise<string> {
    this.userData = await this.ensureUserDataIsLoaded();
    return this.userData?.id;
  }

  async getCompanyId(): Promise<number> {
    this.userData = await this.ensureUserDataIsLoaded();
    return this.userData?.companyId;
  }

  getUserIdNumber(): number {
    return +this.userData?.id;
  }

  async getUserImage(): Promise<string> {
    this.userData = await this.ensureUserDataIsLoaded();
    return this.userData?.imageUrl;
  }

  async getUserName(): Promise<string> {
    this.userData = await this.ensureUserDataIsLoaded();
    const firstName: string = this.userData?.preferredFirstName ? this.userData?.preferredFirstName : this.userData?.firstName
    return firstName + " " + this.userData?.lastName;
  }

  async getAccessLevel(): Promise<number> {
    this.userData = await this.ensureUserDataIsLoaded();
    return this.userData?.accessLevel;
  }

  async getUserData(): Promise<UserData> {
    this.userData = await this.ensureUserDataIsLoaded();
    return this.userData;
  }
  public async setUserData(data: UserData) {
    this.userData = data;
    await this.localStorage.set('userData', this.userData);
  }

  public async clearUserData() {
    this.userData = { ...DEFAULT_USER_DATA };
    await this.localStorage.set('userData', this.userData);
  }
}