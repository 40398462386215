<ion-header translucent>
  <ion-toolbar>
    <ion-title>Box Score</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content style="height: 100%; width: 100%">
  <ion-list>
    <ion-item *ngFor="let employee of employees">
      <div slot="start">
        <div style="margin-top: 10px; margin-left: -5px;">
          <app-user-icon matListAvatar [employee]='employee'>
          </app-user-icon>
        </div>

      </div>
      <div slot="start">
        <div class="mat-bod-string sender-name" style="font-size: 16px; white-space: nowrap;">
          {{employee?.fullName}}
        </div>
      </div>
      <div slot="end" style="width: 40px;">
        {{getScore(employee)}}
      </div>
      </ion-item>
  </ion-list>
</ion-content>
