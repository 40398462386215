<div style="width: 100%; padding: 0; margin: 0; text-align: left; padding-top: 10px; padding-bottom: 10px">


  <ion-toolbar *ngIf="!isPinnedIcon" class="transparent-bg" style="width: 100%; margin: 0; padding: 0; min-height: 80px;">
    
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <div *ngIf='shouldUseUserIconComponent()'>
        <div style="height: 18px"></div>
        <app-user-icon [isLarge]="true" [imageUrl]='getImageUrl()' [employee]='getEmployee()'></app-user-icon>
      </div>


      <div *ngIf="!shouldUseUserIconComponent()">
        <div style="height: 8px"></div>

        <div>
          <span style="height: 55px; width: 55px; font-size: 30px; line-height: 55px; display: block; margin: auto;"
            class="material-icons circle-icon app-text-color opaque-div">
            groups
          </span>
        </div>

      </div>
    </div>

    <div slot="start">
      <div style="width: 10px;"></div>
    </div>

    <div slot="start">
      <span *ngIf='this.userId' class="truncated" style="padding: 0; margin: 0; font-size: 17px; ">{{getConversationTitle()}} <span class="material-icons iconAlign opaque-text" *ngIf="conversationIsMuted" style="font-size: 16px; margin-top: -2px;"> notifications_off </span></span>
      <div style="height: 5px;"></div>
      <span style="font-weight: 100; font-size: 17px; text-align: left;"> {{ (getLatestMessage().length>60)? (getLatestMessage() | slice:0:60)+'..':(getLatestMessage()) }} </span>
    </div>

    <div slot="end"  style="min-width: 65px;">
      <span style="font-size: 12px; font-weight: 100; text-align: right; width: 100%;">{{humanFriendlyDate(conversation.latestMessageTime)}}</span>
      
      
      <div *ngIf="shouldShowUnreadMessageCount()">
        <div style="height: 5px;"></div>

        <div *ngIf="!conversationIsMuted" style="float: right" class="circle-amount"> {{getUnreadMessageCount()}}</div>
        <div *ngIf="conversationIsMuted" style="float: right" class="circle-amount-gray">{{getUnreadMessageCount()}} </div>
      </div>
      
    </div>

  </ion-toolbar>


  <ion-grid *ngIf=" isPinnedIcon" (click)="conversationClicked()"
    style="width: 100%; margin: 0; padding: 0; min-height: 90px;">

    <ion-row style="margin: 0; padding: 0">

      <ion-col size="12" style="margin: 0; padding: 0">


        <div *ngIf='shouldUseUserIconComponent()' style="width: 100%;">
          <app-user-icon [isPinned]="true" [imageUrl]='getImageUrl()' [employee]='getEmployee()'></app-user-icon>
        </div>

        <div *ngIf="!shouldUseUserIconComponent()">

          <div style="margin-right: 5px; display: block; margin: auto; width: 100%;">
            <span
              style="height: 75px; width: 75px; font-size: 30px; line-height: 75px; display: block; margin: auto; border-radius: 50%; "
              class="material-icons circle-icon app-text-color opaque-div">
              groups
            </span>
          </div>

        </div>
      </ion-col>
      <ion-col size="12">
        <span *ngIf='this.userId' class="truncated" style=" font-size: 12px; font-weight: 500; text-align: center;">
          <span *ngIf="shouldShowUnreadMessageCount()" class="circle-amount-pinned"> {{getUnreadMessageCount()}} </span>

          <span style="margin-top: 5px;"> {{getConversationTitle()}} </span>

        </span>

      </ion-col>
    </ion-row>
  </ion-grid>

</div>