
<div style="background-color: transparent; padding: 10px;">


  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Name
    </div>
    <div slot="end">
      Status
    </div>

    <div slot="end">
      <div style="width: 40px;"></div>
    </div>
  </ion-toolbar>

  <div *ngFor="let invite of this.data">
    <ion-toolbar class="transparent-bg">
      <div slot="start" style="font-weight: 100;">
        {{invite.firstName + " " + invite.lastName}}
      </div>

      <div slot="end" style="font-weight: 100;">
        {{invite.status}}
      </div>

      <div slot="end" style="font-weight: 100; width: 40px;">
        <button mat-icon-button (click)="openDetails(invite)">
          <span class="material-icons">
            more_vert
            </span>
        </button>
      </div>

    </ion-toolbar>
  </div>


</div>