<div style="padding: 20px;">
<h1 style="margin: 0;">{{title}}</h1>
<div style="height: 5px;"> </div>

<mat-divider> </mat-divider>
<div style="height: 5px;"> </div>

  <div *ngFor='let key of getColumnOneList(); let i = index;' style="font-size: 16px;">
    <b>{{key}}: </b> {{this.getColumnTwoValueByKey(key)}}
    <div style="height: 5px;"> </div>
  </div>
</div>
