
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, from, Observable } from "rxjs";
import { ApplicantData } from "src/app/onboarding/onboarding-wizard/onboarding-store.service";
import { AdminDirectoryService } from "./admin-directory.service";
import { SnackbarService } from "./snackbar.service";
import { UserDetailsService } from "./user-details.service";


@Injectable({
    providedIn: 'root'
})
export class ApplicantsStoreService {

    private _applicants: BehaviorSubject<ApplicantData[]> = new BehaviorSubject([]);
    public readonly applicants: Observable<ApplicantData[]> = this._applicants.asObservable();

  
    constructor(
        public userDetailsService: UserDetailsService, 
        public httpClient: HttpClient, 
        public localStorage: Storage, 
        public snackbarService: SnackbarService,
        public adminDirectoryService: AdminDirectoryService
     ) {
        this.initializeApplicants();
    }

    async initializeApplicants() {

        await this.initFromCachedApplicants();
        this.fetchLatestApplicants();
    }

    async fetchLatestApplicants() {
        this.snackbarService.displaySnackBarMessage("Fetching latest...", true, 100000000000)
        const result: any = await this.adminDirectoryService.getAllApplicants();
        this._applicants.next(result);
        this.snackbarService.displaySnackBarMessage("Applicants Updated", true)
        this.cacheApplicantsList();
    }


    async initFromCachedApplicants() {
        await this.loadCachedApplicants();
    }

    public async cacheApplicantsList(): Promise<void> {
        setTimeout(() => {
            this.localStorage.set("APPLICANTS_LIST", JSON.stringify(this._applicants.getValue()));

            this.loadCachedApplicants();

        }, 1000);
    }


    private async loadCachedApplicants(): Promise<ApplicantData[]> {
        const currentListOfApplicants: ApplicantData[] = this._applicants.getValue();

        if (currentListOfApplicants?.length > 0) return;
        const res = await this.localStorage.get("APPLICANTS_LIST");
        if (!res) return;
        const cachedListOfApplicants: ApplicantData[] = JSON.parse(res);

        if (cachedListOfApplicants?.length > 0) {
            this._applicants.next(cachedListOfApplicants);
        }

    }

    public getApplicants(): ApplicantData[]{
        const result = Array.from(this._applicants.getValue().reduce((m, t) => m.set(t.id, t), new Map()).values());
        result.forEach(a => {a.fullName = a.firstName + " " + a.lastName })
        return result;
    }

}

