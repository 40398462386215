import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartGenerationService {

  constructor() { }

  generatePieChartFromData(data: any[]): any {
    return {
      chartType: 'PieChart',
      dataTable: data,
      options: {
        legend: { textStyle: {fontSize: 12, color: '#575757'}, position: 'bottom' },
        colors: ['#00BCE1', '#858585', 'red', '#f3b49f', '#f6c7b6']
      }
    };
  }

  generateLineChartFromData(data: any[], inputColor: string): any {
    return {
      chartType: 'LineChart',
      dataTable: data,
      options: {
        chartArea: { left: 5, top: 0, width: '95%', height: '100%' },
        backgroundColor: { fill: 'transparent' },
        height: 100,
        width: '100%',
        curveType: 'function',
        legend: { position: 'none' },
        series: {
          0: {
            // lineDashStyle: [7, 7],
            color: inputColor
          }
        },
        pointSize: 1,
        lineWidth: 3,
        hAxis: {
          gridlines: {
            color: 'transparent'
          },

          format: 'MMM yy',
          textPosition: 'none'
        },

        vAxis: {
          gridlines: {
            color: 'transparent'
          },

          baselineColor: 'transparent',
          format: '#\'%\'',
          viewWindow: {
            max: 20,
            min: 0
          },
          textPosition: 'none'

        }
      }
    };
  }
}
