<div *ngIf="this.video" >

  <mat-card [style.background-image]='getImageForVideo()' (click)="openVideo()"
  [ngClass]="{'normal': (displayMode === 'normal'), 'small': (displayMode === 'small') }"
    style="background-color: #383838; background-size:cover; background-position: center;  padding: 0; display: block; margin: auto; ">
    <ion-grid class="specialText">
      <div [ngStyle]="(displayMode === 'normal') ? {'height': '35px'} : {'height' : '10px'}"></div>
      <ion-row class="specialText">
        <span class="material-icons" style="text-align: center; width: 100%;" *ngIf="this.video.resourceType === 'video' || this.video.resourceType === 'podcast'">
          play_circle_filled
        </span>
      </ion-row>
    </ion-grid>
  </mat-card>
  <div *ngIf="displayMode !== 'small'" style="font-size: 12px; width: 150px;" >
    <div style="height: 5px;"></div>
    <span>{{this.video.title}}</span>
    <br>
    <div style="height: 4px;"></div>

    <span style="font-size: 12px; font-weight: 100;">{{this.video.trainerName}}</span>
    <div style="height: 10px;"></div>

  </div>
</div>