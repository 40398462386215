<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div *ngIf="nav.visible">

  <div>
    <ion-header no-border class="no-padding-toolbar">
      <ion-toolbar no-border class="no-padding-toolbar "  *ngIf="nav.textVisible">
            <div class="no-padding-toolbar elementToFadeInAndOut"   
              style="max-width: 1200px; display: block; margin: auto; padding: 0;  padding-top: 0px;"
              [ngStyle]="outOfDateApp ? {'height' : '90px'} : {'height' : '45px'}"
              >
              <ion-grid style="width: 100%; padding: 5px 10px 5px 10px;">
                <ion-row *ngIf="outOfDateApp" style="background-color: #d8db6b; width: 100%; text-align: center; height: 40px;" (click)="goToAppStore()">
                  <span style="text-align: center; width: 100%; color: black; font-weight: 100; line-height: 40px;" >App Update Available 
                    <span class="material-icons iconAlign">
                      chevron_right
                      </span>
                  </span>
                </ion-row>
                <ion-row>
                  <!-- <ion-col size="2">
                    <div style="width: 30px;"></div>
                  </ion-col> -->
                  <ion-col size="12" style="text-align: center;">
                    <h1 (click)="refreshPage()" style="font-size: 24px;  margin: 0;   font-family: 'Rubik', sans-serif; line-height: 30px;">
                      <span>{{getTitle()}}</span>
                    </h1>
                  </ion-col>

                  <!-- <ion-col size="2" style="padding: 0; margin: 0">
                    <img *ngIf="this.router.url !== '/profile'" style="width: 40px; height: 40px; border-radius: 50%; float: right; background-position: center; background-size: cover;object-fit: cover;" [src]="getWorkspaceImage()" />
                  </ion-col> -->
                </ion-row>
              </ion-grid>
              <div style="width: 10px;"> </div>
            </div>
          </ion-toolbar>
          <ion-toolbar class="my-animation" *ngIf="!nav.textVisible" style="height: 5px">

            
          </ion-toolbar>
    </ion-header>
  </div>



</div>