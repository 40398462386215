import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { adamLog } from "../generic-functions";
import { REST_BASE_URL } from "../models/constants";
import { UserDetailsService } from "../services/user-details.service";

const DEFAULT_SERVER_FAIL: string = "Unable to get result from server";
const DEFAULT_SERVER_POST_FAIL: string = "Unable to send request to server";

function handleServerException(e) {
    adamLog(`Server Error: ${JSON.stringify(e)}`);
}

function httpParamsString(params: any): string {
    if(Object.keys(params).length===0) return "";
    return "?" + Object.keys(params)
            .map(key => `${key}=${params[key] && params[key]!="null" ? params[key] : ""}`)
            .join("&");
}

export async function serverGetRequestV1<T>(
    restPath: string, 
    params: any, 
    http: HttpClient, 
    userDetails: UserDetailsService,
    alertString: string = DEFAULT_SERVER_FAIL): Promise<T> {

    const sessionKey: string = await userDetails.getSessionKey();
    const url: string =  `${REST_BASE_URL}${restPath}/${sessionKey}${httpParamsString(params)}`;
    const header: HttpHeaders = new HttpHeaders();
    header.append("x-auth-token", `${sessionKey}`);
    try {
        const res = await http.get<T>(url).toPromise();
        // if (!res) alert(alertString);
        return res;
    } catch(e) {
        handleServerException(alertString || e);
        return null;
    }
}

export async function serverPostRequest(
    restPath: string, 
    queryParams: any, 
    body: any, 
    http: HttpClient, 
    userDetails: UserDetailsService,
    alertString: string = DEFAULT_SERVER_POST_FAIL): Promise<any> {
        const sessionKey: string = await userDetails.getSessionKey();
        const url: string =  `${REST_BASE_URL}${restPath}/${sessionKey}${httpParamsString(queryParams)}`;
        try {
        const res = await http.post(url, body).toPromise();
        // if (!res) alert(alertString);
        return res;
    } catch(e) {
        handleServerException(alertString || e);
        return null;
    }
}


// function paramsFormData(params: any): FormData {
//     let body: any = new FormData();
//     for (let key of Object.keys(params)) {
//         body.append(key, JSON.stringify(params[key]));
//     }
//     return body
// }