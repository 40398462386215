
<div class="modal-container app-background-color" style="max-width: 500px; display: block; margin: auto; padding: 20px">
  <div>
    Responses
  </div>
  <div *ngFor="let response of getResponses()">
    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <span style="font-weight: 100;">{{getResponseLeft(response)}}:</span>
      </div>

      <div slot="start">
        <div style="width: 10px;"></div>
      </div>

      <div slot="start">
        {{getResponseRight(response)}}

      </div>
    </ion-toolbar>


  </div>
</div>