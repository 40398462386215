import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { nl } from "date-fns/locale";
import { BackendUser } from "src/app/shared/models/employees.model";
import { OnboardingRestService } from "../onboarding-rest.service";
import { GetResult, Storage as capStorage } from '@capacitor/storage';
import { CompanyInfo, DEFAULT_COMPANY_INFO } from "./components/applicant-authentication/applicant-authentication.component";


@Injectable({
    providedIn: 'root'
})
export class OnboardingStoreService {

    emailHasBeenVerified: boolean;
    phoneNumberHasBeenVerified: boolean;

    // public companyInfo: CompanyInfo = {...DEFAULT_COMPANY_INFO}
    // public companyId = ""
    // public companyName = ""
    public officeLocation = ""

    constructor(public localStorage: Storage, public onboardingRestService: OnboardingRestService) {

    }

    APPLICANT_KEY = "APPLICANT_KEY"

    public async loadApplicantFromStorage(): Promise<ApplicantData> {
        let getResult: GetResult = (await capStorage.get({ key: this.APPLICANT_KEY }));
        if (getResult?.value) return JSON.parse(getResult.value);
        return null;
    }

    public async cacheApplicant(applicant: ApplicantData): Promise<void> {
        return await capStorage.set({
            key: this.APPLICANT_KEY,
            value: JSON.stringify(applicant),
        });
    }

    public async clearApplicant(): Promise<void> {
        return await capStorage.set({
            key: this.APPLICANT_KEY,
            value: null,
        });
    }


    APPLICANT_SESSION_KEY = "APPLICANT_SESSION_KEY"

    public async loadApplicantSessionKeyFromStorage(): Promise<string> {
        let key = (await capStorage.get({ key: this.APPLICANT_SESSION_KEY }))?.value;
        return key
    }

    public async cacheApplicantSessionKey(): Promise<any> {
        return await capStorage.set({
            key: this.APPLICANT_SESSION_KEY,
            value: await this.onboardingRestService.getOnboardingSessionKey(),
        });
    }

    public async clearApplicantSessionKey(): Promise<void> {
        return await capStorage.remove({ key: this.APPLICANT_SESSION_KEY });

    }



    APPLICANT_OFFICE_LOCATION = "APPLICANT_OFFICE_LOCATION"

    public async loadApplicantOfficeLocationStorage(): Promise<string> {
        let key = (await capStorage.get({ key: this.APPLICANT_OFFICE_LOCATION }))?.value;
        return key
    }

    public async cacheApplicantOfficeLocation(officeLocation: string): Promise<any> {
        return await capStorage.set({
            key: this.APPLICANT_OFFICE_LOCATION,
            value: officeLocation,
        });
    }

    public async clearApplicantOfficeLocation(): Promise<void> {
        return await capStorage.remove({ key: this.APPLICANT_OFFICE_LOCATION });

    }


    emailHasBeenSuccessfullySubmitted(): boolean {
        return this.onboardingRestService.onboardingSessionKey?.length > 0;
    }

    applicantHasCompletedEverything(applicant: ApplicantData) {
        return (
            this.personalInfoDone(applicant)
            && this.w9Done(applicant)
            && this.w9Sign(applicant)
            && this.repAgreementDone(applicant)
            && this.idDone(applicant)
            && this.bankingDone(applicant)
            && this.directDepositDone(applicant)
            && this.clothingDone(applicant)

        )
    }

    personalInfoDone(applicant: ApplicantData) {
        return this.getMissingPersonalInfo(applicant).length === 0
    }

    getMissingPersonalInfo(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!applicant.firstName) missingFields.push("First Name");
        if (!applicant.lastName) missingFields.push("Last Name")
        if (!applicant.address) missingFields.push("Address")
        if (!applicant.city) missingFields.push("City")
        if (!applicant.state) missingFields.push("State")
        if (!applicant.zip) missingFields.push("Zip")
        if (!applicant.dateOfBirth) missingFields.push("Date Of Birth")
        if (!applicant.userImage && !applicant.userImageUrl) missingFields.push("User Image")
        return missingFields;
    }



    //TODO: what else do we need to verify?
    w9Done(applicant: ApplicantData) {
        return this.getMissingW9(applicant).length === 0
    }

    getMissingW9(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!applicant.w9.address) missingFields.push("Address");
        if (!applicant.w9.city) missingFields.push("City")
        if (!applicant.w9.state) missingFields.push("State")
        if (!applicant.w9.zip) missingFields.push("Zip")
        
        if (!applicant.w9.name && !applicant.w9.businessName) missingFields.push("Name (Personal or Business)")
        if (!applicant.w9.fedTaxClass) missingFields.push("Federal Tax Classification")
        if (!applicant.w9.ssn && !applicant.w9.employmentId) missingFields.push("Tax Pay ID Number (SSN or EID)")
        if (!applicant.w9.exemptions) missingFields.push("Exemptions")

        return missingFields;
    }



    userHasSignedW9: boolean = false;
    w9Sign(applicant: ApplicantData) {
        return this.userHasSignedW9 || applicant.w9.w9FileUrl;
    }

    getMissingW9Sign(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!this.userHasSignedW9) missingFields.push("W9 Signature");
        return missingFields;
    }




    userHasSignedRepAgreement: boolean = false;

    //TODO: How should we verify this?
    repAgreementDone(applicant: ApplicantData) {
        return this.userHasSignedRepAgreement || applicant.repAgreement.repAgreementFileUrl
    }

    getMissingRepAgreementSign(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!this.userHasSignedRepAgreement) missingFields.push("Rep Agreement Signature");
        return missingFields;
    }



    idDone(applicant: ApplicantData) {
        let count: number = 0;
        if (applicant.identification.driversLicense) count++;
        if (applicant.identification.birthCertificate) count++;
        if (applicant.identification.socialSecurityCard) count++;
        if (applicant.identification.passport) count++;
        return count >= 2;
    }

    getMissingIdentification(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!this.idDone(applicant)) missingFields.push("TWO valid forms of ID")
        return missingFields;
    }


    bankingDone(applicant: ApplicantData) {
        return this.getMissingBanking(applicant).length === 0
    }

    getMissingBanking(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!applicant.bank.type) missingFields.push("Account Name");
        if (!applicant.bank.accountHolderName) missingFields.push("Name on Account");
        if (!applicant.bank.bankName) missingFields.push("Bank Name");
        if (!applicant.bank.accountType) missingFields.push("Checking / Savings Type");
        if (!applicant.bank.accountHolderName) missingFields.push("Account Holder Name");
        if (!applicant.bank.achRoutingNumber) missingFields.push("ACH Routing Number");
        if (!applicant.bank.accountNumber) missingFields.push("Account Number");
        // if (!applicant.bank.wireRoutingNumber) missingFields.push("Wire Routing Number");
        return missingFields;
    }

    directDepositDone(applicant: ApplicantData) {
        return this.getMissingDirectDeposit(applicant).length === 0
    }

    getMissingDirectDeposit(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (applicant.bank.hasAgreedToDirectDeposit?.toString() !== 'true') missingFields.push("Check the box to agree");
        return missingFields;
    }



    clothingDone(applicant: ApplicantData) {
        return this.getMissingClothing(applicant).length === 0
    }

    getMissingClothing(applicant: ApplicantData) {
        let missingFields: string[] = [];
        if (!applicant.apparel.shirtSize) missingFields.push("Shirt Size");
        if (!applicant.apparel.pantShortSize) missingFields.push("Pant/Short Size");
        if (!applicant.apparel.hatSize) missingFields.push("Hat Size");
        if (!applicant.apparel.jacketSize) missingFields.push("Jacket Size");
        if (!applicant.apparel.waistSize) missingFields.push("Waist Size");
        if (!applicant.apparel.poloShirtSize) missingFields.push("Polo Shirt Size");
        if (!applicant.apparel.shoeSize) missingFields.push("Shoe Size");
        return missingFields;
    }

    getFullName(applicant: ApplicantData) {
        let firstName = applicant.firstName ? applicant.firstName : "";
        let lastName = applicant.lastName ? applicant.lastName : "";
        return firstName + " " + lastName;

    }



}

export function applicantHasDocuments(applicant: ApplicantData): boolean {
    const numberOfIdentificationFieldsCompleted: number =
        +(applicant?.identification?.birthCertificate !== null) +
        +(applicant?.identification?.driversLicense !== null) +
        +(applicant?.identification?.passport !== null) +
        +(applicant?.identification?.socialSecurityCard !== null);

    return numberOfIdentificationFieldsCompleted >= 2;
}



export function applicantHasSunderEmail(applicant: ApplicantData): boolean {
    return applicant?.emailAddress.includes("@sunderenergy.com");
}

export function applicantHasRepAgreement(applicant: ApplicantData): boolean {
    return applicant?.repAgreement.repAgreementFileUrl !== null;
}

export function applicantHasBankInfo(applicant: ApplicantData): boolean {
    return applicant?.bank?.accountType !== null;
}

export function applicantHasw9(applicant: ApplicantData): boolean {
    return applicant?.w9?.address !== null &&
        applicant?.w9?.w9FileUrl !== null;
}

export function applicantHasBackgroundCheck(applicant: ApplicantData): boolean {
    return applicant?.backgroundCheck?.backgroundCheckFileUrl !== null;
}

export function applicantIsScar(applicant: ApplicantData): boolean {
    return applicant?.scar;
}

export interface w9 {
    address: string;
    businessName: string;
    city: string;
    employmentId: string;
    fedTaxClass: string;
    name: string;
    ssn: string;
    zip: string;
    state: string;
    w9FileUrl: string;
    type: string;
    exemptions: string; //persist
    exemption1?: string; //persist
    exemption2?: string; //persist
    otherClassification?: string; //persist

}

export const DEFAULT_W9: w9 = {
    address: null,
    businessName: null,
    city: null,
    employmentId: null,
    fedTaxClass: null,
    name: null,
    ssn: null,
    zip: null,
    w9FileUrl: null,
    type: null,
    state: null,
    exemptions: null,
    exemption1: null,
    exemption2: null,
    otherClassification: null


}


export interface Identification {
    birthCertificate: string;
    driversLicense: string;
    passport: string;
    socialSecurityCard: string;
}

export const DEFAULT_ID: Identification = {
    birthCertificate: null,
    driversLicense: null,
    passport: null,
    socialSecurityCard: null,
}

export function applicantIdBadgePrinted(applicant: ApplicantData): boolean {
    return applicant?.idBadgePrinted;
}

export interface RepAgreement {
    repAgreementFileUrl: string;
}

export const DEFAULT_REP_AGREEMENT: RepAgreement = {
    repAgreementFileUrl: null
}

export interface BackgroundCheck {
    backgroundCheckFileUrl: string;
}

export const DEFAULT_BACKGROUND_CHECK: BackgroundCheck = {
    backgroundCheckFileUrl: null
}


export interface ApplicantData {
    id: string;
    emailAddress: string;
    emailAddressSecondary?:string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    dateOfBirth: string;
    hasCompletedBackgroundCheck: boolean;
    status: string;
    officeLocation: string;
    userId: string;
    scar: boolean;
    idBadgePrinted: boolean;

    insertDateTime: string;

    userImage?: File;
    userImageUrl?: string;
    repAgreement?: RepAgreement;
    bank?: BankInfo;
    apparel?: ClothingInfo;
    identification?: Identification;
    notes?: string;
    invitation?: AppInvitation;
    w9?: w9;
    backgroundCheck?: BackgroundCheck;
    listOfNotes?: ListOfNotes;
    listOfFiles?: ListOfFiles;

    fullname?: string;

}
export interface BankInfo {
    accountHolderName: string;
    bankName: string;
    accountType: string;
    achRoutingNumber: number;
    accountNumber: number;
    wireRoutingNumber?: number;
    imageFile?: any;
    type: string;
    hasAgreedToDirectDeposit: string;
}

export const DEFAULT_BANK_INFO: BankInfo = {
    accountHolderName: null,
    bankName: null,
    accountType: null,
    achRoutingNumber: null,
    accountNumber: null,
    wireRoutingNumber: null,
    imageFile: null,
    type: null,
    hasAgreedToDirectDeposit: null,
}


export interface ClothingInfo {
    hatSize: string;
    jacketSize: string;
    pantShortSize: string; // Weird naming on backend = this should be pant size
    shirtSize: string;
    waistSize: string;
    poloShirtSize: string;
    shoeSize: string;

}

export const DEFAULT_APPAREL: ClothingInfo = {
    hatSize: null,
    jacketSize: null,
    pantShortSize: null,
    shirtSize: null,
    waistSize: null,
    poloShirtSize: null,
    shoeSize: null,
}

export const DEFAULT_APPLICANT: ApplicantData = {
    id: null,
    emailAddress: null,
    emailAddressSecondary: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    dateOfBirth: null,
    hasCompletedBackgroundCheck: false,
    status: null,
    officeLocation: null,
    userId: null,
    scar: false,
    idBadgePrinted: false,

    insertDateTime: null,

    userImage: null,
    userImageUrl: null,
    repAgreement: DEFAULT_REP_AGREEMENT,
    bank: DEFAULT_BANK_INFO,
    apparel: DEFAULT_APPAREL,
    identification: DEFAULT_ID,
    notes: null,
    invitation: null,
    w9: DEFAULT_W9,
    backgroundCheck: DEFAULT_BACKGROUND_CHECK,
    listOfNotes: null,
    listOfFiles: null,

    fullname: null,
}



export interface ListOfNotes {
    notes: ApplicantNote[];
}

export interface ApplicantNote {
    applicantId: string;
    noteId: string;
    note: string;
    insertTime: string;
    updateTime: string;
    authorId: number;
    // authorUser: BackendUser;
}


export interface ListOfFiles {
    files: ApplicantFile[];
}
export interface ApplicantFile {
    applicantId: string;
    fileId: string;
    file?: any;
    insertTime: string;
    title: string;

}

export const DEFAULT_APPLICANT_FILE: ApplicantFile = {
    applicantId: null,
    fileId: null,
    title: null,
    insertTime: null,
    file: null,
}



export interface W9Info {
    name: string;
    businessName: string;
    federalTaxClassification: string; // TODO: Make this enumerated what the actual options are
    soc: string;
    employmentId: string;
    form: any;

    address: string;
    city: string;
    state: string;
    zip: string;

    type: string;

    exemptions: string;
    exemption1?: string;
    exemption2?: string;
    otherClassification?: string;
}







export interface AppInvitation {
   invitingUserId: number;
   note: string;
}   






export interface Invitation {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    team: string;
    office?: string;
    scarProgram: boolean;
    note?: string;
    recruiterId: number;
}


