<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<agm-map 

  styles="cursor: pointer"
  [latitude]='mapDetails.latitude' 
  [longitude]='mapDetails.longitude' 
  [zoom]='mapDetails.zoom' 
  (mapReady)="onMapLoad($event)" 
  [zoomControl]='false'
  [disableDefaultUI]="false"
  (mapClick)="mapClicked($event)">
  <div *ngIf="getMarkersOn()">
    <agm-marker 
      *ngFor="let m of markers; let i = index" 
      [latitude]="m.lat" 
      [longitude]="m.lng" 
      (markerClick)="clickedMarker(m, i)"
      [iconUrl]='getColoredPinUrl(m)'>
      <!-- <agm-info-window style="width: 250; margin: 0px;" [maxWidth]="300">
       
      </agm-info-window> -->
    </agm-marker>
  </div>
</agm-map>

<div *ngIf="showToggle">
<mat-button-toggle-group #group="matButtonToggleGroup" [vertical]="true">
  <mat-button-toggle value="heat_map" (change)="onToggleGroupChange($event.value)" [checked]='true'>
    <mat-icon>whatshot</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle value="markers" (change)="onToggleGroupChange($event.value)">
    <mat-icon>place</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
</div>
