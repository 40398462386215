import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Platform } from '@ionic/angular';
import { AreYouSureModalComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure-modal.component';
import { sendEmail } from 'src/app/shared/generic-functions';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadModel } from '../leads.model';
import { LeadsService } from '../leads.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'enzy-lead-info-row',
  templateUrl: './lead-info-row.component.html',
  styleUrls: ['./lead-info-row.component.scss'],
})
export class LeadInfoRowComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() type: string;
  @Input() lead: LeadModel;

  userId: number;
  constructor(public employeeStoreService: EmployeeStoreService, 
    public userDetailsService: UserDetailsService, 
    public dialog: MatDialog, 
    public leadsService: LeadsService, 
    public platform: Platform,
    public sanitizer: DomSanitizer) {
  }

  async ngOnInit() {
    this.userId = +(await this.userDetailsService.getUserId());
  }


  shouldDisableSatCheckbox() {
    return this.lead.isCompleted || (this.userId !== this.lead.assignedRep)
  }

  checkSat() {

    let ref = this.dialog.open(AreYouSureModalComponent);
    ref.afterClosed().subscribe(result => {
      if (result) {
        let success = this.leadsService.safeUpdateSat(this.lead?.leadId, true);
        if (success) {
          this.lead.sitDate = new Date().toDateString();
          console.log(this.lead.sitDate)
        }
        else {
          this.lead.isCompleted = false;
        }
      }
      if (!result) {
        this.lead.isCompleted = false;
      }

    })
  }

  public openMapsApp(location: any) {
    if (this.platform.is('android')) {
      window.location.href = 'geo:' + location;
    } else {
      window.location.href = 'maps://maps.apple.com/?q=' + location;
    }
  }

  getLeadStatusOptions(): string[] {
    return this.leadsService.leadOptionsConfig.leadStatus;
  }


  selectLeadStatus(option: string) {
    if (!option) { return }
    const oldLead: LeadModel = { ...this.lead };
    this.lead.leadStatus = option;
    this.leadsService.safeUpdateLead(oldLead, this.lead);
  }

  leadIsUnassigned() {
    return !this.lead.assignedRep
  }

  async openSelectSingleUserPickerModal() {

    const userId: number = await this.employeeStoreService.selectSingleUserModal();
    if (userId) {
      const oldLead: LeadModel = { ...this.lead };
      this.lead.assignedRep = userId;
      this.leadsService.safeUpdateLead(oldLead, this.lead);
      // this.setTableFilter(this.selectedTab)

    }
  }

  getEmployeeName(id: number) {
    if (id === -1) { return "Assigned" }
    if (id === null) { return "Unassigned" }

    return this.employeeStoreService.getUserName(id) ? this.employeeStoreService.getUserName(id) : "-";
  }

  userCanChangeAssignedRep() {
    return this.leadsService.userCanChangeAssignedRep(this.lead);
  }


  sendEmail(phone: string) {
    sendEmail(phone);
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
