
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BusySpinnerComponent } from '../components/busy-spinner/busy-spinner.component';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { DashboardData } from '../models/dashboard.model';
import { FreshReportResponse } from '../models/fresh-report.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { DashboardService } from './dashboard.service';
import { FreshReportService } from './fresh-report.service';
import { ReportsService } from './reports.service';



@Injectable({
  providedIn: 'root'
})
export class ReportStoreService {


  private _reports: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly reports: Observable<DashboardData[]> = this._reports.asObservable();


  constructor(public freshReportService: FreshReportService,
    public dialog: MatDialog,
    public localStorage: Storage) {

    // this.loadCachedReports();


    this.initializeReports();

  }


  async initializeReports() {
    await this.initFromCachedReports();
    this.fetchLatestReports();
  }

  async initFromCachedReports() {
    await this.loadCachedReports();
  }

  private async loadCachedReports(): Promise<DashboardData[]> {
    const currentListOfReports: DashboardData[] = this._reports.getValue();

    if (currentListOfReports?.length > 0) return;
    const res = await this.localStorage.get("REPORTS");
    if (!res) return;
    let cachedListOfReports: DashboardData[];
    try {
       cachedListOfReports = JSON.parse(res);

    } catch (e) {
      console.warn(e);
    }

    if (cachedListOfReports?.length > 0) {
      // this._reports.next(cachedListOfReports); //TODO: HJ
    }
  }





  async fetchLatestReports() {
    const result: any = await this.freshReportService.getFreshReports()
    this.extractReportsFromResponse(result);
    this.cacheReports();
  }

  private extractReportsFromResponse(res: any) {
    const tempReports: DashboardData[] = [];
    res.list.forEach((element, index) => {
      let report: DashboardData = {
        freshReport: element,
        reportMenu: new DataReportMenu(),
        reportCellList: new ReportCellList(),
        hasBeenSaved: true,
        viewFilter: 'Table',
        reportOrder: index
      }

      tempReports.push(report);
    });
    this._reports.next(tempReports);
  }

  public async loadReportData(report: DashboardData) {
    const cellList: ReportCellList = await this.freshReportService.getReportCellListByReportId(report.freshReport.id);
    report.reportCellList = cellList
    report.reportMenu = cellList.menu;
    if (report.reportMenu) {
      report.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(report.freshReport.viewType)
    }
    this.cacheReports();

  }

  // ****** GETTERS ******

  getCurrentReports(): DashboardData[] {
    return this._reports.value;
  }

  getFavoritedReports(): DashboardData[] {
    return this._reports.value?.filter((report: DashboardData) => report.freshReport.isFavorite);
  }

  public getSelectedReport() {

  }

  // ****** CACHING ******

  // async loadCachedReports(): Promise<DashboardData[]> {
  //   if (this.currentReports?.length > 0) return;
  //   const currentReports: DashboardData[] = await this.localStorage.get("REPORTS");
  //   // TODO: Do I need some checks here for safety?
  //   this.currentReports = currentReports;

  //   return currentReports;
  // }

  private async cacheReports(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("REPORTS", JSON.stringify(this._reports.getValue()));
    }, 1000);
  }


}