import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { startOfWeek, format, endOfWeek } from 'date-fns'

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor() { }

  public timeString(inputDate: string): string {
    var date: Date = this.dateFromString(inputDate);
    if (date === null) { return "-"}
    return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }

  private dateFromString(inputDate:string): Date {
    return genericDateFromString(inputDate);
  }

  public humanFriendlyDate(inputDate: string): string {
    return genericHumanFriendlyTime(inputDate)
  }

  serverFriendlyDate(dateString: string): string {
    return new DatePipe('en-US').transform(dateString, 'yyyy-MM-dd')

  }
}

export function genericDateFromString(inputDate:string): Date {
  if (inputDate === null) { return null}

  let t: number[] = inputDate.split(/[- :]/).map(r => parseInt(r))
  var date: Date = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
  return date;
}

export function genericDateOnlyFromString(inputDate:string): Date {
  if (inputDate === null) { return null}

  let t: number[] = inputDate.split(/[- :]/).map(r => parseInt(r))
  var date: Date = new Date(Date.UTC(t[0], t[1] - 1, t[2]));
  return date;
}

export function genericHumanFriendlyTime(inputDate: string): string {
  if (!inputDate) return null;
  var date: Date = genericDateFromString(inputDate);
  if (date === null) { return "-"}

  const unixTimestamp: number = date.valueOf();

  const now: number = Date.now();

  const diffNumber = now - unixTimestamp;


  let days = Math.floor(diffNumber / (60 * 60 * 24 * 1000));
  let hours = Math.floor(diffNumber / (60 * 60 * 1000));
  let minutes = Math.floor(diffNumber / (60 * 1000));

  if (days > 1) {
    return date.toLocaleDateString();
  } else if (days === 1) {
    return "Yesterday";
  } else if (hours > 0) {
    return date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  } else if (minutes > 1) {
    return minutes + " min ago";
  } else if (minutes === 1) {
    return minutes + " min ago";
  } else {
    return "Just now";
  }
}

export function getStartOfCurrentWeek(): Date {
  return startOfWeek(Date.now());
}

export function getEndOfCurrentWeek(): Date {
  return endOfWeek(Date.now());
}

export function getDateStringForToday(): string {
  return format(new Date(), 'MM/dd/yyyy')
}

export function getCurrentMysqlDateTimeString(): string {
  return format(new Date(), 'yyyy-MM-dd hh:mm:ss')
}

export function dateIsInRange(date: Date, startDate: Date, endDate: Date): boolean {
  return date >= startDate && date <= endDate;
}