
// DON'T CHANGE THE FIELD NAMES OF ANY OF THESE MODELS UNLESS THE BACKEND CHANGES
export class DataReportMenu {
    errorMessage?:string;
    filterCalcList: NumberFilter[];
    filterDateList: DateFilter[];
    filterDescription: string;
    filterId: any;
    filterNumberList: NumberFilter[];
    filterTextList: TextFilter[];
    message: string;
    objectDefinitionDto: ObjectDefinitonDto;
    objectIndex: number;
    selectedStratField: string;
    showOptionList: ShowOption[];
    stratList: GroupByOption[];
    viewType: string;
    timePeriodMap?: any;
    title?: string;
  }


  export const DEFUALT_DATA_REPORT_MENU: DataReportMenu = { 
    errorMessage: null,
    filterCalcList: [],
    filterDateList: [],
    filterDescription: null,
    filterId: null,
    filterNumberList: [],
    filterTextList: [],
    message: null,
    objectDefinitionDto: null, //Will this cause issues?
    objectIndex: null,
    selectedStratField: null,
    showOptionList: [],
    stratList: [],
    viewType: null,
    timePeriodMap: null,
    title: null,
  }


  export interface MinMaxFilter {
    attributeIndex: number;
    description: string;
    maxName: string;
    maxText: string;
    maxValue: string;
    minName: string;
    minText: string;
    minValue: string;
  }


  export class DateFilter implements MinMaxFilter {
    attributeIndex: number;
    description: string;
    maxName: string;
    maxText: string;
    maxValue: string;
    minName: string;
    minText: string;
    minValue: string;
    timePeriodValue: string;

    maxValueCalendar: number;
    minValueCalendar: number;
  }

  // FilterCalcList and filterNumberList
  export class NumberFilter implements MinMaxFilter {
    attributeIndex: number;
    description: string;
    maxName: string;
    maxText: string;
    maxValue: string;
    minName: string;
    minText: string;
    minValue: string;

    maxValueCalendar: number;
    minValueCalendar: number;
  }

  // filterTextList
  export class TextFilter {
    attributeText: string;
    attributeIndex: number;
    description: string;
    excludeName: string;
    excludeValue: string;
    includeName: string;
    includeValue: string;
    text: string;

  }

  export class FilterId {
    attributeIndex: string;
    description: string;
    excludeName: string;
    excludeValue: string;
    includeName: string;
    includeValue: string;
    text: string;
  }

  export class ObjectDefinitonDto {
    objectIndex: number;
    objectName: string;
    objectPlural: string;
  }

  export class ShowOption {
    desc: boolean;
    field: string;
    format: string;
    iconClass: string;
    index: number;
    isEmployeeEmailAddress: boolean;
    isSelected: boolean;
    isSortColumn: boolean;
    name: string;
    orderBy: string;
    tdClass: string;
    text: string;
  }

  export class GroupByOption {
    field: string;
    text: string;
  }
