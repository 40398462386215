<mat-card class="poll-div" style="max-width: 400px; min-width: 300px;" *ngIf="poll">
  <div style="height: 1px;"></div>
  <h4 style="text-align: center;">
    <span class="material-icons iconAlign">
      poll
    </span>
  </h4>

  <div style="text-align: center;">
    <span style="font-weight: 400; font-size: 14px">{{poll.title}}</span> <br>
    <span style="font-weight: 100; font-size: 14px">Expires: {{poll.endTime ? poll.endTime : "never"}}</span> <br>
    <span *ngIf="poll.isPublic" style="font-weight: 100; font-size: 14px">Public Poll</span> 
    <span *ngIf="!poll.isPublic" style="font-weight: 100; font-size: 14px">Private Poll</span>     
    <span class="opaque-text"> | </span>
    <span *ngIf="poll.multipleAnswers" style="font-weight: 100; font-size: 14px">Multiple Votes</span> 
    <span *ngIf="!poll.multipleAnswers" style="font-weight: 100; font-size: 14px">Single Vote</span> 
    <br>
    <span *ngIf="!this.isActive" style="font-weight: 900; font-size: 14px">This poll is closed</span>

  </div>
  <div *ngIf="isExpanded">


    <div *ngFor="let option of options">
      <button mat-button style="width: 100%;" (click)='castOrRemoveVote(option)'>
        <ion-toolbar class="transparent-bg">

          <div slot="start">
            <div
              [ngClass]="{'app-radio-selected': userHasVotedForOption(option), 'app-radio-non-selected': !userHasVotedForOption(option) }">
            </div>
          </div>
          <div slot="start">
            <div style="width: 5px;"></div>
          </div>

          <div slot="start" style="text-align: left; line-height: 10px;">
            <!-- &nbsp; <span style="font-weight: 400; max-width: 200px;"> {{option.description}}</span> -->

            <ion-label text-wrap style="text-align: left;">
              <span style="font-weight: 400;  white-space: pre-line; text-align: left;"> {{option.description}}</span>
          </ion-label>
          </div>

          <div slot="end">
            {{option.responses.length}}
          </div>
        </ion-toolbar>

        <ion-row style="padding: 0; margin: 0; margin-top: -5px">
          <ion-col size="12" style="padding: 0; margin: 0;">
            <mat-progress-bar mode="determinate" value=" {{ option.ratio * 100 }}"></mat-progress-bar>
          </ion-col>
        </ion-row>


        <div *ngIf="this.poll.isPublic" style="height: 5px;"> </div>
        <div *ngIf="!this.poll.isPublic" style="height: 10px;"> </div>

      </button>
      <ion-row *ngIf="poll.isPublic" style="padding: 0 0px 0 10px; margin: 0; margin-top: -5px"  style="height: 20px;">
        <ion-col size="12" style="padding: 0; margin: 0; text-align: left;">
          <span style="padding-left: 15px; font-size: 12px"  class="opaque-text" (click)="openVoterDetails(option)">{{getVoterString(option)}}</span>
          
          <!-- <span *ngFor="let response of this.option.responses; let i = index" class="opaque-text" style="padding-left:15px; line-height: 30px;">
            <span *ngIf="i < 2" style="font-size: 12px;">
              {{getVoterFullName(response)}}<span *ngIf="i < 2 "></span>
            </span>
            
          </span>

          <button *ngIf="option.responses.length > 2" mat-button style="float: right;" (click)="openVoterDetails(option)">+{{option.responses.length-2}} More</button> -->
        </ion-col>
      </ion-row>
      <!-- <button *ngIf="poll.isPublic" mat-button (click)="displayWhoVoted(option)">who</button> -->
    </div>

  </div>

  <div *ngIf="this.poll.isPublic" style="height: 5px;"> </div>
  <div *ngIf="!this.poll.isPublic" style="height: 15px;"> </div>
  <mat-divider></mat-divider>
  <button mat-button (click)="toggleExpand()" class="opaque-text" style="width: 100%;">
    <span *ngIf="!this.isExpanded && this.isActive">
      VOTE
    </span>

    <span *ngIf="!this.isExpanded && !this.isActive">
      SEE RESULTS
    </span>
    <span *ngIf="this.isExpanded">HIDE</span>

  </button>

</mat-card>