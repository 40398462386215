<button mat-button style="width: 100%; margin: 0; padding: 0; border-radius: 10px;">
  <ion-grid style="height: 150px;" class="example-ripple-container section-div mat-elevation-z2" (click)="increment()">
    <ion-row>
      <ion-col>
        <span class="opaque-text">{{getTitle() | titlecase}}</span>
      </ion-col>
    </ion-row>

    <ion-row class="big-text">

      <ion-col>
        <h1 style="margin: 0; margin-top: 5px; font-size: 50px; line-height: 50px;">{{getValue()}}</h1>
      </ion-col>

    </ion-row>
    <ion-row>
      <button class="opaque-text" mat-icon-button (click)="$event.stopPropagation()" (click)="decrement()">
        <span class="material-icons">
          replay
        </span>
      </button>
    </ion-row>
  </ion-grid>

</button>