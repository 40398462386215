import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { ResourceFile } from '../models/help.model';
import { Poll, PollOption, PollResponse } from '../models/poll.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { UserDetailsService } from './user-details.service';


@Injectable({
  providedIn: 'root'
})
export class PollService {



    constructor(
        private http: HttpClient, 
        public userDetailsService: UserDetailsService,
        ) { }  

    public async castVote(option: PollOption, poll: Poll, conversationId: number, messageId: number) {
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        // console.log("IMPLEMENT CASTING A VOTE", option, poll);
        let url = REST_BASE_URL + '/polls/castVote/' + sessionKey;
        
        let body = new FormData();
        body.append('optionId', option.id + '');
        body.append('pollId', poll.id + '');
        body.append('conversationId', conversationId + '');
        body.append('messageId', messageId + '');

        return this.http.post<any>(url, body).toPromise();

    }

    public async removeVote(option: PollOption, poll: Poll, conversationId: number, messageId: number) {
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url = REST_BASE_URL + '/polls/removeVote/' + sessionKey;
        
        let body = new FormData();
        body.append('optionId', option.id + '');
        body.append('pollId', poll.id + '');
        body.append('conversationId', conversationId + '');
        body.append('messageId', messageId + '');

        return this.http.post<any>(url, body).toPromise();

    }

    addVoteToPollObject(poll: Poll, response: PollResponse): void {
        const option: PollOption = poll.options.find(o=>o.id === response.optionId);
        if (!option) return;
        const responseIndex: number = option.responses.findIndex(r => r.userId === response.userId);
        if (responseIndex === -1) {
            option.responses.push(response);
        }
        this.calculatePollRatios(poll);
    }

    removeUserVotesFromPollObject(poll: Poll, userId: number): void {
        
        for (let option of poll.options) {
            const responseIndex: number = option.responses.findIndex(r => +r.userId === +userId);
            if (responseIndex >= 0) {
                option.responses.splice(responseIndex, 1);
            }

        }
        this.calculatePollRatios(poll);
    }

    removeUserVotesFromPollOption(poll: Poll, option: PollOption, userId: number): void {
        const responseIndex: number = option.responses.findIndex(r => +r.userId === +userId);
        if (responseIndex >= 0) {
            option.responses.splice(responseIndex, 1);
        }

        this.calculatePollRatios(poll);
    }

    private calculatePollRatios(poll: Poll) {
        let totalVotes: number = 0;
        for (let option of poll.options) {
            totalVotes += option.responses.length 
        }
        for (let option of poll.options) {
            option.ratio = option.responses.length / totalVotes;
        }
    }

}

