import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { LeadsDrawerComponent } from '../leads-drawer/leads-drawer.component';
import { Appointment } from '../leads.model';

@Component({
  selector: 'enzy-appointment-row',
  templateUrl: './appointment-row.component.html',
  styleUrls: ['./appointment-row.component.scss'],
})
export class AppointmentRowComponent implements OnInit {
  @Input() appointment: Appointment;

  @Output() updateAppointment = new EventEmitter<Appointment>();
  @Output() deleteAppointment = new EventEmitter<void>();


  constructor(public dateFormatService: DateFormatService,  public bottomSheet: MatBottomSheet) { }

  ngOnInit() {}

  openAppointmentInfo() {
    //In order for this to work we'd need an appointmentID
    
    let ref = this.bottomSheet.open(LeadsDrawerComponent, 
      {
        data: {title: "Appointments", 
        appointment: this.appointment
      }});
    ref.afterDismissed().subscribe((res) => {

      if (!res) {return}

      this.updateAppointment.emit(res)
  
    });
  }

  delete() {
    this.deleteAppointment.next();
  }


}
