import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { flatMap } from 'rxjs/operators';
import { GeneralInputModalComponent } from 'src/app/shared/components/general-input-modal/general-input-modal.component';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { UserData } from 'src/app/shared/models/dashboard.model';
import { Employee } from 'src/app/shared/models/employees.model';
import { ConversationService } from 'src/app/shared/services/conversation.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, MessagingService, ParticipantModel, ReportLine } from 'src/app/shared/services/messaging.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';


@Component({
  selector: 'app-message-select-recipient',
  templateUrl: './message-select-recipient.component.html',
  styleUrls: ['./message-select-recipient.component.scss'],
})
export class MessageSelectRecipientComponent implements OnInit {

  sessionKey: string;
  userId: string;

  selectable = true;
  removable = true;
  onBlur = true;

  filteredUsers: Employee[] = [];

  inputVal: string = "";

  selectedUsers_toSend: Employee[] = [];

  reportLines: ReportLine[];

  isBcc: boolean = false;

  showEntireList: boolean = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  isAddingToExistingConversation: boolean = false;
  existingConvoId: number;

  constructor(
    public employeeStoreService: EmployeeStoreService,
    private messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    public userDetailsService: UserDetailsService,
    public localStorage: Storage,
    public router: Router,
    public dialog: MatDialog,
    private nav: HeaderService,
    private conversationService: ConversationService,
    private snackbarService: SnackbarService,
    private navController: NavController,
    public dialogRef: MatDialogRef<MessageSelectRecipientComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (this.data?.employeesToMessage) {
      this.selectedUsers_toSend = this.data.employeesToMessage;
      this.cleanUpBadData();
    }

    if (this.data?.reportLines) {
      this.reportLines = this.data?.reportLines;
    }

    if (this.data?.isBCC) {
      this.isBcc = true
    }

    if (this.data?.existingConversationId) {
      this.isAddingToExistingConversation = true;
      this.existingConvoId = +this.data.existingConversationId;
    }
    else {
      this.isAddingToExistingConversation = false;
    }
  }

  ngOnInit() {
    this.initializeComponent();

  }

  async initializeComponent() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.userId = await this.userDetailsService.getUserId();



    this.loadListOfUsers();

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 300);
  }

  updateIsBcc(status: boolean) {
    this.isBcc = status;
  }

  private async loadListOfUsers() {
    await this.employeeStoreService.loadAllUsers();

    // this.employeeService.getAllEmployees(this.sessionKey).then(
    //   result => {
    //     this.users = result['list'];
    //     this.filteredUsers = this.users;
    //   });
  }


  /*
setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  */
  @ViewChild('searchInput') searchElement: ElementRef;


  selectUser(selectedUser: Employee) {
    this.selectedUsers_toSend.push(selectedUser);


    this.filteredUsers.forEach((item, index) => {
      if (item.fullName === selectedUser.fullName) {
        this.filteredUsers.splice(index, 1);
      }
    })


    this.inputVal = "";
    this.filteredUsers = this.employeeStoreService.users.filter(r => (r.fullName)?.toLowerCase().includes(""));

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  removeUser(selectedUserToRemove: Employee) {
    if (selectedUserToRemove === undefined) { return }
    this.filteredUsers.unshift(selectedUserToRemove);

    this.selectedUsers_toSend.forEach((item, index) => {
      if (item.fullName === selectedUserToRemove.fullName) {
        this.selectedUsers_toSend.splice(index, 1);
      }
    })

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  showFullList() {
    this.showEntireList = true;
  }

  showLess() {
    this.showEntireList = false;
  }

  ionViewWillLeave() {
    this.selectedUsers_toSend = [];
  }

  ionViewWillEnter() {
  }

  sendMessage() {

    // this.router.navigate(['analytics'])
    this.openMessageView(this.selectedUsers_toSend, this.isBcc)

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filteredUsers = this.employeeStoreService.users.filter(r => (r.fullName)?.toLowerCase().includes(filterValue.toLowerCase()));
  }


  openMessageView(participants: Employee[], isBcc: boolean) {

    if (this.isAddingToExistingConversation) {
      const participantsId: string[] = participants.map(employee => employee.userDto.id);


      this.conversationService.addParticipants(this.existingConvoId, participantsId).then(res => {
        this.snackbarService.displaySnackBarMessage("Added to Group Chat", true);

        const convo: ConversationModel = this.messagingStoreService.getStoredConversationBasedOnId(this.existingConvoId);

        this.messagingStoreService.updateCurrentConversationParticipants(res.object, convo);
        this.router.navigateByUrl('/messages/' + this.existingConvoId);
        this.dialogRef.close();
      });

    }
    else {

      if (!isBcc) {
        const participantsIncludingMe: string[] = participants.map(employee => employee.userDto.id);
        participantsIncludingMe.push(this.userId);

        const participantsFromEmployee: ParticipantModel[] = participants.map(employee => this.getParticipantFromEmployee(employee));

        this.messagingService.getOrCreateConversationByUserList(this.sessionKey, participantsIncludingMe).subscribe((result) => {
          if (result.conversationId) {
            let newConversation: ConversationModel = {
              conversationId: result.conversationId,
              participants: participantsFromEmployee,
              latestMessage: "",
              latestMessageTime: "",
              latestMessageId: null,
              title: this.getTitle(participants),
              imageUrl: "",
              unreadMessageCount: 0,
              ownerId: null,
              definingTeam: null,
              isAdminSendOnly: false
            }
            this.messagingStoreService.addConvoIfItIsNew(newConversation);

            let data = {
              isBCC: false,
              reportLines: this.reportLines,
              conversationId: newConversation.conversationId
            }

            this.dialogRef.close(data);

          }
        });
      }
      else {

        let data = {
          isBCC: true,
          selectedUsers_toSend: this.selectedUsers_toSend,
          reportLines: this.reportLines
        }

        this.dialogRef.close(data);

      }
    }
  }


  getParticipantFromEmployee(employee: Employee): ParticipantModel {
    return {
      id: employee.userDto.id,
      preferredFirstName: employee.userDto.preferredFirstName,
      lastName: employee.userDto.lastName,
      imageUrl: employee.userDto.imageUrl,
      isMuted: false,
      isPinned: false,
      isAdmin: false,
      unreadMessageCount: null,
    }
  }

  getTitle(participants: Employee[]): string {
    let title = participants.map((employee) => employee.userDto.preferredFirstName).join(', ');
    return title;
  }

  goBack() {
    this.selectedUsers_toSend = [];
    // this.navController.pop();
    this.dialogRef.close();
    // this.router.navigate(['messages']);
  }

  getUsersToShowInFilter(): Employee[] {
    this.filteredUsers.forEach((item, index) => {
      if (item === undefined) {
        this.filteredUsers.splice(index, 1);

        return
      }
      this.selectedUsers_toSend.forEach((item2, index2) => {
        if (item.fullName === item2.fullName) {
          this.filteredUsers.splice(index, 1);
        }

      })
    })

    if (this.inputVal === "" || this.inputVal === " ") { return [] }

    return this.filteredUsers.slice(0, 25)
  }

  cleanUpBadData() {
    this.selectedUsers_toSend.forEach((user, index) => {
      if (user === undefined) {
        this.selectedUsers_toSend.splice(index, 1);
      }
    })
  }
}
