import { AgmCoreModule } from '@agm/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    MatButtonToggleModule,
    MatInputModule
} from '@angular/material';
import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AvailableTimeRowComponent } from './calendar/available-time-row/available-time-row.component';
import { AvailableTimesComponent } from './calendar/available-times/available-times.component';
import { DashboardAlertCardComponent } from './cr-dashboard/components/dashboard-alert-card/dashboard-alert-card.component';
import { CRDashboardService, ICRDashboardService } from './cr-dashboard/cr-dashboard.service';
import { ResponsesBySessionId } from './cr-dashboard/directives/responses-by-session-id.directive';
import { KnockModalComponent } from './knocking/knock-modal/knock-modal.component';
import { AppointmentRowComponent } from './leads/appointment-row/appointment-row.component';
import { ExternalLeadFormComponent } from './leads/external-lead-form/external-lead-form.component';
import { LeadCalendarComponent } from './leads/lead-calendar/lead-calendar.component';
import { LeadInfoRowComponent } from './leads/lead-info-row/lead-info-row.component';
import { TestExtendLeadInfoComponent } from './leads/lead-info/better-lead-info.component';
import { LeadInfoComponent } from './leads/lead-info/lead-info.component';
import { LeadManagerAdminComponent } from './leads/lead-manager-admin/lead-manager-admin.component';
import { LeadModalComponent } from './leads/lead-modal/lead-modal.component';
import { LeadsFormComponent } from './leads/leads-form/leads-form.component';
import { NoteCardComponent } from './leads/note-card/note-card.component';
import { MultiTallyTrackerComponent } from './leads/prospects/multi-tally-tracker/multi-tally-tracker.component';
import { ProspectCardComponent } from './leads/prospects/prospect-card/prospect-card.component';
import { TallyStatsComponent } from './leads/prospects/tally-stats/tally-stats.component';
import { TallyTrackerComponent } from './leads/prospects/tally-tracker/tally-tracker.component';
import { TaskRowComponent } from './leads/task-row/task-row.component';
import { MaterialModule } from './material.module';
import { LeadChangesCardComponent } from './messaging/lead-changes-card/lead-changes-card.component';
import { LinkPreviewCardComponent } from './messaging/link-preview-card/link-preview-card.component';
import { MessageCellComponent } from './messaging/message-cell/message-cell.component';
import { ReplyCardComponent } from './messaging/reply-card/reply-card.component';
import { LeaderboardDrillDownService } from './rankings/services/leaderboard-drill-down.service';
import { AreYouSureModalComponent } from './shared/components/are-you-sure-modal/are-you-sure-modal.component';
import { LineChartComponent } from './shared/components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './shared/components/charts/pie-chart/pie-chart.component';
import { EnzyAssistantCardComponent } from './shared/components/enzy-assistant-card/enzy-assistant-card.component';
import { GenericMapModalComponent } from './shared/components/generic-map-modal/generic-map-modal.component';
import { GeoMapComponent } from './shared/components/geo-map/geo-map.component';
import { GiphyComponent } from './shared/components/giphy/giphy.component';
import { MultiFileUploadComponent } from './shared/components/multi-file-upload/multi-file-upload.component';
import { MyFooterComponent } from './shared/components/my-footer/my-footer.component';
import { HeaderService } from './shared/components/my-header/header.service';
import { MyHeaderComponent } from './shared/components/my-header/my-header.component';
import { PickerModalComponent } from './shared/components/picker-modal/picker-modal.component';
import { PollCardComponent } from './shared/components/poll-card/poll-card.component';
import { PollComponent } from './shared/components/poll/poll.component';
import { VoterDetailsComponent } from './shared/components/poll/voter-details/voter-details.component';
import { SearchHeaderComponent } from './shared/components/search-header/search-header.component';
import { StartEndDatePickerComponent } from './shared/components/start-end-date-picker/start-end-date-picker.component';
import { UserIconComponent } from './shared/components/user-icon/user-icon.component';
import { UserPickerModalComponent } from './shared/components/user-picker-modal/user-picker-modal.component';
import { UserPickerComponent } from './shared/components/user-picker/user-picker.component';
import { LongPressDirective } from './shared/long-press.directive';
import { PhoneMaskDirective } from './shared/phone-mask.directive';
import { ICacheService, CacheService } from './shared/services/cache.service';
import { ISurveyApi, SurveyApi } from './shared/services/survey.api';
import { ThemeService } from './shared/services/theme-service.service';
import { SurveyResponsesModalComponent } from './surveys/survey-responses-modal/survey-responses-modal.component';
import { ISurveyService, SurveyService } from './surveys/survey.service';
import { TicketCardComponent } from './tickets/ticket-card/ticket-card.component';
import { TicketFormModalComponent } from './tickets/ticket-form-modal/ticket-form-modal.component';
import { TicketFormComponent } from './tickets/ticket-form/ticket-form.component';

@NgModule({
    declarations: [
        MyFooterComponent,
        MyHeaderComponent,
        UserIconComponent,
        UserPickerComponent,
        UserPickerModalComponent,
        TicketCardComponent,
        TicketFormModalComponent,
        TicketFormComponent,
        PhoneMaskDirective,
        MultiFileUploadComponent,
        NoteCardComponent,
        LeadManagerAdminComponent,
        LeadModalComponent,
        LeadInfoComponent,
        TestExtendLeadInfoComponent,
        LeadsFormComponent,
        ExternalLeadFormComponent,
        LeadInfoRowComponent,
        TaskRowComponent,
        AppointmentRowComponent,
        TallyTrackerComponent,
        MultiTallyTrackerComponent,
        TallyStatsComponent,
        LeadCalendarComponent,
        StartEndDatePickerComponent,
        PickerModalComponent,
        SearchHeaderComponent,
        AreYouSureModalComponent,
        ProspectCardComponent,
        AvailableTimesComponent,
        AvailableTimeRowComponent,
        MessageCellComponent,
        EnzyAssistantCardComponent,
        PollCardComponent,
        GiphyComponent,
        PollComponent,
        LinkPreviewCardComponent,
        ReplyCardComponent,
        LeadChangesCardComponent,
        ResponsesBySessionId,
        DashboardAlertCardComponent,
        LongPressDirective,
        VoterDetailsComponent,
        GeoMapComponent,
        KnockModalComponent,
        SurveyResponsesModalComponent,
        LineChartComponent,
        PieChartComponent,
        GenericMapModalComponent,
        
    ],
    imports: [
        CommonModule,
        IonicModule.forRoot({ animated: true}),
        MaterialModule,
        ScrollingModule,
        MatInputModule,
        FormsModule,
        GooglePlaceModule,
        AgmCoreModule.forRoot({ apiKey: 'AIzaSyCP1dLkrSkX5_3ZKOceUqb-sT90ogui4k8' + '&libraries=visualization' }),
        MatButtonToggleModule,

        
        IonicStorageModule.forRoot({
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
        }),
    ],
    exports: [
        MyFooterComponent,
        MyHeaderComponent,
        SearchHeaderComponent,
        UserIconComponent,
        UserPickerComponent,
        UserPickerModalComponent,
        TicketCardComponent,
        TicketFormModalComponent,
        TicketFormComponent,
        PhoneMaskDirective,
        MultiFileUploadComponent,
        NoteCardComponent,
        LeadManagerAdminComponent,
        LeadModalComponent,
        LeadInfoComponent,
        TestExtendLeadInfoComponent,
        LeadsFormComponent,
        ExternalLeadFormComponent,
        LeadInfoRowComponent,
        TaskRowComponent,
        AppointmentRowComponent,
        GooglePlaceModule,
        TallyTrackerComponent,
        MultiTallyTrackerComponent,
        TallyStatsComponent,
        LeadCalendarComponent,
        StartEndDatePickerComponent,
        AreYouSureModalComponent,
        ProspectCardComponent,
        AvailableTimesComponent,
        AvailableTimeRowComponent,
        MessageCellComponent,
        EnzyAssistantCardComponent,
        PollCardComponent,
        GiphyComponent,
        PollComponent,
        LinkPreviewCardComponent,
        ReplyCardComponent,
        LeadChangesCardComponent,
        ResponsesBySessionId,
        DashboardAlertCardComponent,
        LongPressDirective,
        VoterDetailsComponent,
        
        GeoMapComponent,
        AgmCoreModule,
        MatButtonToggleModule,
        KnockModalComponent,
        SurveyResponsesModalComponent,

        LineChartComponent,
        PieChartComponent,
        GenericMapModalComponent,


    ],
    providers: [
        HeaderService,
        ThemeService,
        { provide: ISurveyApi, useClass: SurveyApi },
        { provide: ICRDashboardService, useClass: CRDashboardService },
        { provide: ICacheService, useClass: CacheService },
        { provide: ISurveyService, useClass: SurveyService },
        { provide: LeaderboardDrillDownService, useClass: LeaderboardDrillDownService },
        
    ]
})
export class SharedModule {}
