<div class='background' style="width: 100vw; ">
  <ion-header style="position: sticky; top: 0; z-index: 9999;">
    <ion-toolbar>
      <div slot="start" style="width: 10px;">
      </div>
      <div slot="start">
        <button mat-icon-button (click)="close()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <ion-title style="text-align: center;">Manage</ion-title>
      <!-- <div slot="end">
        <button mat-button class="primary-color">
          Save
        </button>
      </div> -->

      <div slot="end" style="width: 10px;">
      </div>
    </ion-toolbar>
  </ion-header>
  <div style="max-width: 600px; display: block; margin: auto; min-height: 100vh;">

    <div style="height: 10px;"></div>
    <ion-toolbar class="transparent-bg"
      style=" width: 100%; height: 60px; position: sticky;top: 0;   z-index: 999; max-width: 1200px; display: block; margin: auto; ">
      <div>
        <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
          <div style="width: 100%;">
            <button class="filter-button" mat-button style="width: 100%;">
              <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
                search
              </i>
              <mat-form-field floatLabel='never' appearance="none" 
                style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input matInput [(ngModel)]="searchVal" placeholder="Search" #input>
              </mat-form-field>
            </button>
          </div>
        </div>
      </div>
    </ion-toolbar>



    <div class="app-background-color" style="min-height: 100vh;">

      <div *ngFor="let assignment of getSortedAssignments()">
        <div style="height: 10px;"></div>
        <div class="section-div" style="padding: 10px;">
          <ion-grid>
            <ion-row>
              <h1 style="margin: 0;">{{assignment.team}}</h1>
            </ion-row>
            <div style="height: 5px;"></div>

            <mat-divider></mat-divider>
            <div style="height: 5px;"></div>

            <ion-row (click)='selectNewEmployees(assignment)'>
              Lead Managers
            </ion-row>
            <ion-row (click)='selectNewEmployees(assignment)'>
              <div *ngFor="let userId of getLeadAdminUserIds(assignment)"
                style="max-width: 180px; padding: 5px; margin: 5px; border-radius: 10px;" class="opaque-div">

                <ion-toolbar class="transparent-bg" *ngIf='getEmployee(userId)'>
                  <div slot="start">
                    <div style="height: 10px;"></div>
                    <app-user-icon matListAvatar [employee]='getEmployee(userId)' style="max-width: 50px;">
                    </app-user-icon>
                  </div>
                  <div slot="start">
                    <div style="width: 5px;"></div>
                  </div>

                  <div slot="start" style="text-align: left; font-weight: 100;">
                    {{getEmployee(userId)?.userDto?.firstName}} {{getEmployee(userId)?.userDto?.lastName}}
                  </div>
                </ion-toolbar>
              </div>

              <div *ngIf="getLeadAdminUserIds(assignment).length === 0">
                <p class="opaque-text" style="font-weight: 100;">None Assigned</p>
              </div>

            </ion-row>

            <div style="height: 10px;"></div>

            <ion-row (click)='selectNewTeamManagers(assignment?.team)'>
              Chat Managers
            </ion-row>

            <ion-row (click)='selectNewTeamManagers(assignment?.team)'>
              <div *ngFor="let userId of getTeamManagerUserIds(assignment?.team)"
                style="max-width: 180px; padding: 5px; margin: 5px; border-radius: 10px;" class="opaque-div">

                <ion-toolbar class="transparent-bg" *ngIf='getEmployee(userId)'>
                  <div slot="start">
                    <div style="height: 10px;"></div>
                    <app-user-icon matListAvatar [employee]='getEmployee(userId)' style="max-width: 50px;">
                    </app-user-icon>
                  </div>
                  <div slot="start">
                    <div style="width: 5px;"></div>
                  </div>

                  <div slot="start" style="text-align: left; font-weight: 100;">
                    {{getEmployee(userId)?.userDto?.firstName}} {{getEmployee(userId)?.userDto?.lastName}}
                  </div>
                </ion-toolbar>
              </div>

              <div *ngIf="getTeamManagerUserIds(assignment?.team)?.length === 0">
                <p class="opaque-text" style="font-weight: 100;">None Assigned</p>
              </div>

            </ion-row>


          </ion-grid>
        </div>
      </div>

    </div>

  </div>

</div>