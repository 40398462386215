import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { DatesService } from 'src/app/shared/services/dates.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { MessageModel, MessageTypeEnum, MessagingService, Reaction, ReportLine } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { EditMessageModalComponent } from '../edit-message-modal/edit-message-modal.component';
import { EmojiModalComponent } from '../emoji-modal/emoji-modal.component';
import { ExpandedImageModalComponent } from '../expanded-image-modal/expanded-image-modal.component';
import { MessageActionsDrawerComponent } from '../message-actions-drawer/message-actions-drawer.component';
import { ReactionsModalComponent } from '../reactions-modal/reactions-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@capacitor/clipboard';
import { CustomFileService } from 'src/app/shared/services/custom-file-service.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { BackendUser, Employee } from 'src/app/shared/models/employees.model';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';



@Component({
  selector: 'app-message-cell',
  templateUrl: './message-cell.component.html',
  styleUrls: ['./message-cell.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class MessageCellComponent implements OnInit, AfterViewInit {
  isCompletelyLoaded: boolean = false;

  message: MessageModel;

  @Input() set messageInput(newMessage: MessageModel) {
    this.message = newMessage;
    

    // let dummySurvey: SurveyResponseDescription = {
    //   header: "Men's Bathroom needs to be cleaned!",
    //   iconString: "🗑️",
    //   timeStamp: "2 Min ago",
    //   dispositionable: true,
    //   dispositioned: false
    // }

    // this.message.surveyResponseDescription = dummySurvey;

    this.setupMessage();
  }

  @Input() showUserIcon: boolean;

  @Input() thumbsUpCount: number;
  @Input() thumbsDownCount: number;
  @Input() thisMessageIsFromCurrentUser: boolean;
  @Input() sessionKey: string;
  @Input() userId: number;
  @Input() isPending: boolean = true;
  @Input() isScrolling: boolean;

  @Input() messageIndex: number;

  drawerIsOpen: boolean = false;
  clickListenerIsLocked: boolean = false;

  // userData: UserData;

  public clickText: String;

  @Output() onReplyMessage = new EventEmitter<any>();
  @Output() onDirectMessage = new EventEmitter<any>();
  @Output() onEditMessage = new EventEmitter<any>();
  @Output() onAttemptResend = new EventEmitter<any>();
  @Output() onImageExpanded = new EventEmitter<any>();
  @Output() activeReportLines = new EventEmitter<ReportLine[]>();


  @ViewChild('special') special: ElementRef

  @HostListener('click', ['$event'])
  clickout(event) { }

  onRightClick(event) {
    event.preventDefault();
  }

  active() {
    this.openMessageOptions();
  }


  height: string;
  width: string;

  openMessageOptions() {
    if (this.isScrolling) { return }


    let ref = this._bottomSheet.open(MessageActionsDrawerComponent, {
      data: {
        mode: "options",
        messageId: this.message.messageId,
        thisMessageIsFromCurrentUser: this.thisMessageIsFromCurrentUser,
        isReply: this.message.messageType === "REPLY",
        // conversationId: this.messagingStoreService.getStoredConvoId(),
        messageContent: this.message.contents,
        frontendId: this.message.frontendId,
        messageIndex: this.messageIndex,
        message: this.message
      }
    });

    ref.afterDismissed().subscribe(res => {
      if (this.isEmoji(res)) {
        this.addReactionToMessage(res);
      }
      else if (res === 'reply') {
        this.reply(this.message);
      }
      else if (res === 'copy') {
        this.copyMessage(this.message)
      }
      else if (res === 'edit') {
        this.onEditMessage.emit(this.message);
      }

    })

    this.messagingService.selectedMessageCell = this.message.messageId;


  }

  private isEmoji(str: string) {
    if (str === '👍' || str === '👎' || str === '❓' || str === '🔥' || str === '💥' || str === '🤣' || str === '❤️' || str === '✅') {
      return true
    }
  }


  onImgLoad(event: any) {

  }

  onThumbLoad(event: any) {

  }

  openVideo(url: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        video: url,
      }
    });
  }

  constructor(private ref: ChangeDetectorRef, private _bottomSheet: MatBottomSheet, private zone: NgZone, private eRef: ElementRef,
    public messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private dateService: DatesService,
    private employeeStoreService: EmployeeStoreService,
    public customFileService: CustomFileService,
    public userDetailsService: UserDetailsService,
    public dashboardService: DashboardService,
    public router: Router,
    public snackbarService: SnackbarService,
    public dialog: MatDialog) {
    this.clickText = 'normal';


   


  }


  ngAfterViewInit() {
    // console.log("rendered")

  }

  async setupMessage() {



    const fromUserObject: Employee = this.employeeStoreService.getUser(+this.message.fromUser);
    if (fromUserObject?.userDto != null) {
      const newFirstName: string = fromUserObject?.userDto?.preferredFirstName;
      const lastName: string = fromUserObject?.userDto?.lastName;
      if (newFirstName !== null && newFirstName.length > 0 && lastName !== null && lastName.length > 0) {
        this.message.fromUserName = newFirstName + " " + lastName;
      }
      const newImageThumbnail: string = fromUserObject?.userDto?.imageThumbnailUrl;
      if (newFirstName !== null && newFirstName.length > 0) {
        this.message.fromUserImgThumbnail = newImageThumbnail;
      }
    }

    if (this.message.messageType === "IMAGE") {
      this.setUpImageHeightAndWidth()
    }

    if (this.message.messageId === "Failed" && (this.message.messageType === "IMAGE" || this.message.messageType === "VIDEO")) {
      this.customFileService.getFileLocally(this.message.frontendId).then(res => {
        this.message.selectedFile = res;
        this.getUrlFromFile(this.message.selectedFile);

      })
    }

    else if (this.message && this.message.selectedFile !== null && this.message.selectedFile !== undefined) {
      this.getUrlFromFile(this.message.selectedFile);
    }
    this.message.reactions = this.message.reactions ? this.message.reactions : [];
    this.clickText = 'normal';
  }

  ngOnInit() {
    if (this.message.messageType === 'POLL') {
      // console.log(this.message)
    }
  }

  imageType: string = "";

  setUpImageHeightAndWidth() {
    let height = this.message.imageHeight;
    let width = this.message.imageWidth;

    if (height === 0 || width === 0) { return }

    if (height === width) {
      this.height = "400px";
      this.width = "400px";
    }
    else if (height > width) {
      this.height = "400px";
      this.width = "300px";
    }
    else if (height < width) {
      this.height = "200px";
      this.width = "300px";
    }
  }

  attemptResend() {
    this.onAttemptResend.emit(this.message);
  }


  toggleReaction(emoji: string): void {
    const shouldRemove: boolean = this.reactionIncludesAuthUser(emoji);
    if (shouldRemove) {
      this.removeReaction(emoji);
    } else {
      this.addReactionToMessage(emoji);
    }
  }

  removeReaction(emoji: string) {
    this.removeReactionLocally({ reaction: emoji, userId: this.userId + "" })
    this.messagingService.removeReaction(this.sessionKey, +this.message.messageId, emoji).subscribe(res => {
    }, (error) => {
      this.snackbarService.displaySnackBarMessage("Cannot remove reactions when offline", false);
      this.addReactionLocally(emoji);
    })
  }

  reply(message: MessageModel) {
    this.onReplyMessage.emit(message);
  }


  copyMessage(message: MessageModel) {
    this.snackbarService.displaySnackBarMessage("Message Copied", true);

    Clipboard.write({
      string: message.contents
    });

  }



  replyContent: string = "";
  replySender: string = "";

  replyResponse: string = "";




  openEmojiModal() {
    this.clickListenerIsLocked = true;
    const dialogRef = this.dialog.open(EmojiModalComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addReactionToMessage(result);
        this.clickListenerIsLocked = false;
      }

    });
  }


  private addReactionToMessage(newReactionString: string): void {
    this.addReactionLocally(newReactionString);

    this.messagingService.addReaction(this.sessionKey, +this.message.messageId, newReactionString).subscribe(res => {
    }, (error) => {
      this.snackbarService.displaySnackBarMessage("Cannot add reactions when offline", false);
      this.removeReactionLocally({ reaction: newReactionString, userId: this.userId + "" });
    })
  }

  addReactionLocally(newReactionString: string): void {
    const newReaction: Reaction = {
      reaction: newReactionString, userId: this.userId + ""
    }
    if (!this.message.reactions.find(r => r.reaction === newReaction.reaction && r.userId === newReaction.userId)) {
      this.message.reactions.push(newReaction);
    }
  }

  removeReactionLocally(reaction: Reaction): void {
    const idx: number = this.message.reactions.findIndex(r => r.reaction === reaction.reaction && r.userId === reaction.userId);
    if (idx >= 0) this.message.reactions.splice(idx, 1);
  }





  urlToDisplay: string = "";
  contentToDisplay: string = "";



  showFailureMessage() {
    this.snackbarService.displaySnackBarMessage("Failed to delete message", false);
  }


  public humanFriendlyDate(inputDate: string): string {
    let date = this.dateService.timeString(inputDate);
    if (date === "Invalid Date") { return "" }
    return date;
  }

  getImage() {
    if (this.message.messageImageThumbnailMedium) { return this.message.messageImageThumbnailMedium }
    if (this.message.messageImage) { return this.message.messageImage }
    return "../../../assets/placeholder-image.png"
  }

  getImageUrl(): string {
    if (this.message.fromUserImgThumbnail && this.message.fromUserImgThumbnail.length > 0) {
      return this.message.fromUserImgThumbnail;
    }
    return this.message.fromUserImg ? this.message.fromUserImg : './assets/headshots/default-avatar-user.jpg';
  }

  getBigImageUrl(): string {
    return this.message.fromUserImg ? this.message.fromUserImg : './assets/headshots/default-avatar-user.jpg';
  }

  hasImage(): boolean {
    return !!this.message.messageImage && this.message.messageImage.length > 0;
  }

  // isImageMessage(): boolean {
  //   return this.hasImage() && !this.message.messageImage?.toLowerCase().endsWith('.mov');
  // }

  // isVideoMessage(): boolean {
  //   return this.hasImage() && this.message.messageImage?.toLowerCase().endsWith('.mov');
  // }

  styleObject() {
    return { height: this.height, width: this.width }
  }


  clickProfile() {
    let ref = this._bottomSheet.open(MessageActionsDrawerComponent, {
      data: {
        mode: "profile",
        fromUserName: this.message.fromUserName,
        fromUserImg: this.getBigImageUrl(),
        fromUserId: this.message.fromUser,
        userId: this.userDetailsService.getUserId()
      }
    });


    ref.afterDismissed().subscribe(res => {
      if (res === 'directMessage') {
        this.onDirectMessage.emit();
      }
    })
  }


  async expandImage(message: MessageModel) {
    let img = "";
    let imgFile: any;

    if (message.messageImage) {
      img = message.messageImage
    } else if (message.selectedFile) {


      var reader = new FileReader();
      await this.getBlobFromFile(message.selectedFile).then(res => {

        let objectURL = URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);

        this.zone.run(() => {
          console.log("redrawing component ");
        });

        img = this.imgURL;

      })

    }

    this.onImageExpanded.emit("open");
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        image: img,
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.onImageExpanded.emit("closed");
    })
  }



  /**
   * Return a map where the keys are the reactions and the values are
   * an array of users that have reacted that way.
   */
  getMappedReactions(): any {
    const map: any = {};

    for (const reaction of this.message.reactions) {
      if (map[reaction.reaction]) {
        map[reaction.reaction].push(reaction.userId);
      } else {
        map[reaction.reaction] = [reaction.userId];
      }
    }
    return map;
  }

  reactionIncludesAuthUser(emoji: string): boolean {
    return this.getMappedReactions()[emoji]?.findIndex(r => +r === this.userId) >= 0;
  }

  reactionCount(emoji: string): number {
    return this.getMappedReactions()[emoji]?.length;
  }

  getReactionEmojis(): string[] {
    return Object.keys(this.getMappedReactions()).sort();
  }

  messageHasReactions(): boolean {
    return this.getReactionEmojis().length > 0;
  }

  getMoreReactionInfo(reaction: any): void {
    if (this.isScrolling) { return }

    if (!this.messageHasReactions()) { return }

    if (reaction === null) {
      reaction = this.getReactionEmojis()[0]
    }

    this.messagingService.getFullReactions(this.message.messageId).then((reactionList) => {
      let ref = this._bottomSheet.open(ReactionsModalComponent, {
        data: {
          selectedReaction: reaction,
          reactions: reactionList.reactions
        }
      });

      ref.afterDismissed().subscribe(res => {


      })

    })
  }

  isTemporary(): boolean {
    return this.message.messageId === "tempId";
  }



  imgURL: any;



  getUrlFromFile(file: File) {


    const Img = new Image();

    Img.src = URL.createObjectURL(file);

    Img.onload = (e: any) => {
      const height = e.path[0].height;
      const width = e.path[0].width;

      if (height === width) {
        this.height = "400px";
        this.width = "400px";
      }
      else if (height > width) {
        this.height = "400px";
        this.width = "100%";
      }
      else if (height < width) {
        this.height = "200px";
        this.width = "100%";
      }

    }





    var reader = new FileReader();
    this.getBlobFromFile(file).then(res => {

      let objectURL = URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);

      this.zone.run(() => {
        console.log("redrawing component ");
      });

      // console.log(this.imgURL);

      // return this.imgURL;

    })
  }


  getBlobFromFile(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();

      // Is this a "real" file? In other words, is this an instance of the original `File` class (not the one overriden by cordova-plugin-file).
      // If so, then we need to use the "real" FileReader (not the one overriden by cordova-plugin-file).
      if (file instanceof Blob) {
        const realFileReader = (fileReader as any)._realReader;
        if (realFileReader) {
          fileReader = realFileReader;
        }
      }

      fileReader.onloadend = function () {
        resolve(new Blob([this.result], { type: file.type }));
      };

      fileReader.onerror = function (err) {
        console.error("Inside fileReader.onerror", err);
        reject(err);
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  messageHasReport(): boolean {
    return this.message.reportId?.length > 0;
  }

  openReport() {
    const messageUsersMode: boolean = true;
    this.dashboardService.showAdvancedAnalyticsByReportId(this.message.reportId, messageUsersMode, this.message.sendDate);
  }

  // openInternalUrl() {
  //   this.router.navigateByUrl(this.message.messageImage)
  //   // alert(this.message.messageImage);
  // }

  isTextMessage(message: MessageModel): boolean {
    return message.messageType === 'TEXT' && !this.isSurveyResponseDescriptionMessage(message);
  }

  isReportMessage(message: MessageModel): boolean {
    return message.messageType === 'REPORT';
  }

  isSurveyResponseDescriptionMessage(message: MessageModel): boolean {
    return message.surveyResponseDescription !== undefined && message.surveyResponseDescription !== null;
  }

  isInternalUrlMessage(message: MessageModel): boolean {
    return message.messageType === 'INTERNAL_URL';
  }

  isReplyMessage(message: MessageModel): boolean {
    return message.messageType === 'REPLY';
  }

  isImageMessage(message: MessageModel): boolean {
    return message.messageType === 'IMAGE';
  }

  isPollMessage(message: MessageModel): boolean {
    return message.messageType === 'POLL';
  }

  isLeadMessage(message: MessageModel): boolean {
    return message.messageType === 'LEAD';
  }

  isVideoMessage(message: MessageModel): boolean {
    return message.messageType === 'VIDEO';
  }

  isGifMessage(message: MessageModel): boolean {
    return message.messageType === 'GIF';
  }

  isUrlMessage(message: MessageModel): boolean {
    return message.messageType === 'URL';
  }

  /**
   * If a new type is created it should be registered here
   * @param message 
   * @returns 
   */
  isNewMessageType(message: MessageModel): boolean {
    return !(this.isTextMessage(message) ||
      this.isReportMessage(message) ||
      this.isReplyMessage(message) ||
      this.isImageMessage(message) ||
      this.isPollMessage(message) ||
      this.isVideoMessage(message) ||
      this.isGifMessage(message) ||
      // this.isInternalUrlMessage(message) ||
      this.isLeadMessage(message) ||
      this.isUrlMessage(message) || 
      this.isSurveyResponseDescriptionMessage(message))
  }

  getNewMessageTypeMessage(message: MessageModel): string {
    return "You must have the latest version to see all details of this message."
      + " Go to the Google or Apple app store to download.";
  }

  setActiveReportLines(reportLines: ReportLine[]) {
    this.activeReportLines.emit(reportLines);
  }

  surveyDispositioned(event: Event) {
    if (!this.message?.surveyResponseDescription) return;
    this.messagingStoreService.markMessageSurveysDispositionedLocally(+this.message?.convoId, this.message?.surveyResponseDescription)
  }
}