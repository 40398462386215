<div class="opaque-div" style="text-align: left; padding: 12px; border-radius: 10px;">
      

  <ion-toolbar class="transparent-bg" >
    <div slot="start">
      <app-user-icon matListAvatar [employee]='getNoteEmployee(note)' >
      </app-user-icon>
    </div>
    <div slot="start" style="margin-top: -10px;">
      <span >
      &nbsp; {{getNoteEmployee(note)?.fullName}} 
      </span>

    </div>

  </ion-toolbar>



  <span class="opaque-text" style="font-size: 12px;" *ngIf="note.creationDate">{{humanFriendlyDate(note.creationDate)}}</span>
  <br />
  <i>{{note.note}}</i>
</div>