import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Storage } from '@ionic/storage';
import { REST_BASE_URL } from '../../models/constants';
import { UserData } from '../../models/dashboard.model';
import { ReportsService } from '../../services/reports.service';
import { UserDetailsService } from '../../services/user-details.service';
import { BusySpinnerComponent } from '../busy-spinner/busy-spinner.component';
import { UploadProfilePictureComponent } from '../upload-profile-picture/upload-profile-picture.component';

@Component({
  selector: 'app-welcome-wizard',
  templateUrl: './welcome-wizard.component.html',
  styleUrls: ['./welcome-wizard.component.scss'],
})
export class WelcomeWizardComponent implements OnInit {

  sessionKey: string; 

  constructor(
    public dialogRef: MatDialogRef<WelcomeWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private http: HttpClient, 
    public reportsService: ReportsService, 
    public dialog: MatDialog, 
    public userDetailsService: UserDetailsService,
    public localStorage: Storage) {

    this.initUserDataValues();

  }
  
  async initUserDataValues() {
    this.sessionKey = await this.userDetailsService.getSessionKey()
    
  }

  ngOnInit() {}

  selectedFile = null;


  onFileSelected(event) {
    this.selectedFile = event.target.files[0];

    this.onSubmit();

  }

  userData: UserData;


  onSubmit() {
    const payload = new FormData();
    payload.append('imageFile', this.selectedFile, this.selectedFile.name);
    this.saveProfilePicture(this.sessionKey, payload);
  }


  public saveProfilePicture(sessionKey: string, image: any) {
    this.dialog.open(BusySpinnerComponent, {
      panelClass: "transparent",
      disableClose: false,
    });

    let nextUrl = REST_BASE_URL + "/uploadUserProfileImage/" + sessionKey;

    return this.http.post<any>(nextUrl, image).subscribe(
      (res) => {
        this.dialog.closeAll();

        const url: string = res.message;
        if (res.message === "Action Failed") {
          alert("Unable to upload profile picture");
          return;
        }
        if (res.message === "Invalid Key") {
          alert("It's possible your session has expired. Try logging back in.");
          return;
        }

        this.localStorage.get("userData").then((data: UserData) => {
          data.imageUrl = url;

          this.localStorage.set("userData", data).then((val) => {
            this.userData = val;
          });
        });
      },
      (error) => {
        this.dialog.closeAll();
        alert("Unable to upload profile picture");

      }
    );
  }
  dismiss() {
    this.dialogRef.close()
  }
  


  openUpdateProfilePhoto() {
    let dialogRef = this.dialog.open(UploadProfilePictureComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(res=> {
      if (res) {
        fetch(res)
        .then(res => {
          res.blob().then(blob => {

            const payload = new FormData();
            payload.append("imageFile", blob, "name");
            this.saveProfilePicture(this.sessionKey, payload);

          })
        })

      }
    })

  }
}
