<div class="app-background-color" style="min-height: 100vh;">
  <ion-toolbar class="transparent-bg">

    <div slot="start">
      <button mat-icon-button (click)="cancel()">
        <span class="material-icons">
          close
        </span>


      </button>
    </div>

    <div slot="end">
      <button mat-button class="primary-color" (click)="save()">Save</button>
    </div>
  </ion-toolbar>
  <h4 style="text-align: center;">{{title}}</h4>

  <div *ngIf="title === 'Appointments'">
    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Title</ion-label>
      <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.appointment.title' autocomplete="off"
        type="text" />
    </ion-item>



    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Time</ion-label>

      <mat-form-field appearance="none" style="width: 100%;">
        <input matInput style="width: 100%; text-align: right;" [(ngModel)]='this.appointment.startDateTime'
          type="datetime-local">
      </mat-form-field>

    </ion-item>

  </div>



  <div *ngIf="title === 'Tasks'">

    <ion-item>
      <ion-label position="stacked">Description</ion-label>

      <ion-textarea [(ngModel)]='this.task.description'></ion-textarea>
    </ion-item>

    <ion-item class="item-background-color">
      <ion-label class="opaque-text" position="fixed">Due Date</ion-label>
      <mat-form-field appearance="none" style="width: 100%;">
        <input matInput style="width: 100%; text-align: right;" [(ngModel)]='this.task.dueDate' type="datetime-local">
      </mat-form-field>
    </ion-item>



  </div>


  <div *ngIf="title === 'Notes'">
    <ion-item>
      <ion-label position="stacked">Note</ion-label>
      <ion-textarea [(ngModel)]='this.note.note'></ion-textarea>
    </ion-item>

    <p style="text-align: left; margin: 0;" class="opaque-text">{{this.note?.note?.length}} <span
        style="color: red; font-weight: 100;" *ngIf="this.note?.note?.length > 1000">Only 1,000 characters
        permitted</span></p>

  </div>

</div>