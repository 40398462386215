<div class="gridBox section-div" style=" width: 100%; padding: 0; margin: 0" style="border-radius: 50px;" (click)="drillDown()">

  <div matRipple class="example-ripple-container" [matRippleUnbounded]="false" style="border-radius: 50px;">

    <ion-row>
      <ion-col size="4" style="padding: 0; margin: 0; height: 100px;">
        <app-user-icon class="gridImage" [isGrid]="true" [imageUrl]="leaderboardCell.imageThumbnail" [initials]='getCellInitials()'>
        </app-user-icon>
      </ion-col>

      <ion-col size="4">
        <div style="height: 10px"></div>
        <ion-row style="height: 20px;">
          <span class="truncated"
            style="text-align: left;  font-size: 14px;  margin-left: -10px; width: 100%">
            {{ leaderboardCell.title }}
          </span>
        </ion-row>


        <ion-row style="height: 30px; font-size: 22px">
          <span style=" font-weight: 100; text-align: left; white-space: nowrap;  margin-left: -10px;">
            {{ leaderboardCell.value }}
          </span>
        </ion-row>


      </ion-col>
      <ion-col size="4">

        <div class="ribbon-1"
          [ngClass]="{'ribbon-first': (leaderboardCell.rank === 1), 'ribbon-second' : (leaderboardCell.rank === 2), 'ribbon-third' : (leaderboardCell.rank === 3)  }">
          <p style="padding: 0; margin: 0; font-size: 16px; line-height: 50px; text-align: center;">
            #{{leaderboardCell.rank}}
          </p>
        </div>
      </ion-col>
    </ion-row>
  </div>
</div>