<div class="section-div" style="max-width: 600px; display: block; margin: auto; text-align: left; padding: 10px"
  (click)="openLead()">

  <ion-toolbar class="transparent-bg">
    <div slot="start">
      <h1 style="padding: 0; margin: 0; font-size: 18px;">
        {{getCustomerName()}}
      </h1>
    </div>

    <div slot="end" class="opaque-div"
      style="padding: 5px 10px 5px 10px; border-radius: 20px; font-weight: 100; font-size: 14px;"
      *ngIf="userIsSetter()">
      My Set
    </div>
    <div slot="end" *ngIf="userIsSetter() && userIsAssignedCloser()">
      <div style="width: 5px;"></div>
    </div>
    <div slot="end" class="opaque-div"
      style="padding: 5px 10px 5px 10px; border-radius: 20px; font-weight: 100; font-size: 14px;"
      *ngIf="userIsAssignedCloser()">
      My Close
    </div>
    <div slot="end" class="opaque-div"
      style="padding: 5px 10px 5px 10px; border-radius: 20px; font-weight: 100; font-size: 14px; cursor: pointer;"
      (click)="deleteLead($event)" >
      
        <span class="material-icons" >delete</span>
    </div>

  </ion-toolbar>

  <mat-menu #leadStatusMenu="matMenu">
    <ng-template matMenuContent>
      <div *ngFor="let option of getLeadStatusOptions()">
        <button mat-menu-item style="text-align: center;" (click)="selectLeadStatus(option)">
          {{option}}
        </button>
      </div>
    </ng-template>

  </mat-menu>

  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Next Appt </span>
    </ion-col>

    <ion-col size="8">
      <span style="font-weight:100">{{getAppointment()}} </span>
    </ion-col>
  </ion-row>


  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100; ">Address </span>
    </ion-col>

    <ion-col size="8">
      <span class="hyper-text-color" style="font-weight:100;" (click)="openMapsApp(); $event.stopPropagation()">{{getAddress()}} </span>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Did this Sit? </span>
    </ion-col>

    <ion-col size="8">
      &nbsp;<mat-checkbox [disabled]="true" [checked]="leadIsCompleted()"></mat-checkbox>
    </ion-col>
  </ion-row>


  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Team </span>
    </ion-col>

    <ion-col size="8">
      <span style="font-weight:100">{{getTeam()}} </span>
    </ion-col>
  </ion-row>


  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Setter </span>
    </ion-col>

    <ion-col size="8">
      <span style="font-weight:100;">{{getSetterName()}} </span>
    </ion-col>
  </ion-row>




  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Status</span>
    </ion-col>

    <ion-col size="8">

      <button style="font-weight: 100; font-size: 16px;" mat-button class="opaque-div"
        (click)="$event.stopPropagation()" [matMenuTriggerFor]="leadStatusMenu">
        {{getLeadStatus()}}

        <span class="material-icons iconAlign">
          arrow_drop_down
        </span>
      </button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="getLeadProjectStatus()">
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Project Status<sup style="color: red;">beta</sup></span>
    </ion-col>

    <ion-col size="8">
      <span style="font-weight:100;">{{getLeadProjectStatus()}} </span>
    </ion-col>
  </ion-row>




  <ion-row>
    <ion-col size="4">
      <span class="opaque-text" style="font-weight:100">Closer</span>
    </ion-col>

    <ion-col size="8">

      <button style="font-weight: 100; font-size: 16px;" *ngIf="this.canReassignLead()"
        [ngClass]="{'red-button': leadIsUnassigned() , 'opaque-div': !leadIsUnassigned() }" class="opaque-div"
        mat-button (click)='openSelectSingleUserPickerModal(); $event.stopPropagation()'>
        {{getAssignedRepName()}}

        <span class="material-icons">
          arrow_drop_down
        </span>

      </button>

      <span *ngIf="!this.canReassignLead()" style="font-weight: 100;"
        [ngClass]="{'red-text': (getAssignedRepName() === 'Unassigned') }">
        <span>
          {{getAssignedRepName()}}
        </span>
      </span>
    </ion-col>
  </ion-row>



</div>