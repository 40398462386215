import { number } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { getEndOfCurrentWeek, getStartOfCurrentWeek } from 'src/app/shared/services/dates.service';
import {  TallyDataPoint, Tallys } from '../tallys';

@Component({
  selector: 'enzy-multi-tally-tracker',
  templateUrl: './multi-tally-tracker.component.html',
  styleUrls: ['./multi-tally-tracker.component.scss'],
})
export class MultiTallyTrackerComponent implements OnInit {

  constructor(
    public tallys: Tallys,
  ) { }

  ngOnInit() {
  }

  handleValueChanged(key: string, value: TallyDataPoint[]) {
    this.tallys.setKeyForTodaysTally(key, value);
  }


}


// NOTES FROM ADAM WEBB
/**
 * Law of averages is
 * 50 contacts a week/ 10 a day 
 * 10homeowners= 1 appointment 
 * 5 appointments a week = 3 sits
 * 3 sits a week = 1 close
 * 4 closes a month= 3 installs 
 * 
 *  Doors knocked 
 *  Homeowners talked too
 *  Appointments set
 *  Appointments sit
 *  Appointments close
 * 
 */




