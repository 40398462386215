<ion-header no-border class="no-padding-header ion-no-border">
  <div style="width: 100%;">
    <ion-toolbar class="no-padding-header  ">
      <div slot="start">
        <div style="width: 10px;"></div>
      </div>
      <div slot="start">
        <button  mat-icon-button class="app-text-color"
          style="text-align: center; box-shadow: none; background-color: transparent !important;"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">close</mat-icon>
        </button>
      </div>



      <div slot="end">

        <button [disabled]='this.pollHasBeenSubmitted' mat-button mat-icon-button (click)="sendPollMessage()" style="text-align:center;" class="primary-color">
          <span class="material-icons">
            send
          </span>
        </button>

      </div>

      <div slot="end">
        <div style="width: 10px;"></div>
      </div>
    </ion-toolbar>
  </div>
</ion-header>
<ion-content>


  <div class="no-context-menu app-background-color app-text-color"
    style="height: 80vh; padding: 0px; max-width: 600px; display: block; margin: auto;">
    <div style="height: 10px;"></div>
    <h1 style="text-align: center; margin: 0; font-size: 18px;"> New Poll</h1>
    <ion-grid>

      <div style="padding: 10px">
        <!-- <ion-row>
        <span style="float:left">Poll Details</span>
      </ion-row> -->

        <ion-row>

          <ion-col size="12" style="padding: 0; margin: 0;">

            <mat-form-field style="width: 100%;" floatLabel='never'>
              <input name="fieldName" ngDefaultControl matInput [(ngModel)]="poll.title" placeholder="Type poll question here"
                style="width: 100%; font-weight: 100;">
            </mat-form-field>

          </ion-col>

        </ion-row>

        <ion-row>
          <ion-col size="4">
            <span style='line-height: 45px; float: left;'>End Time</span>
          </ion-col>


          <ion-col size="8">


            <div *ngIf="endTime">
              <ion-item>
                <ion-label>Date</ion-label>
                <ion-datetime name="fieldName" ngDefaultControl style="text-align: right;" [(ngModel)]="poll.endTime"  (ngModelChange)="testMethod($event)"  placeholder="Select Date"
                  pickerFormat='MMM DD, YYYY hh:mm, A' displayFormat='MMM DD, YYYY hh:mm, A'></ion-datetime>
              </ion-item>
              <!-- <ion-item>
                <ion-label>Time</ion-label>
                <ion-datetime style="text-align: right;" display-format="h:mm A" picker-format="h:mm A"
                  placeholder="Select Time" [(ngModel)]="time"></ion-datetime>
              </ion-item> -->
            </div>
            <!-- <div>

              <button (click)="toggleEndTime()" style="float: right; height: 40px; background: transparent;"
                class="opaque-text">
                <span *ngIf="!endTime">Add</span> <span *ngIf="endTime">Remove</span>
              </button>
            </div> -->



          </ion-col>

        </ion-row>


        <!-- <mat-divider></mat-divider> -->

        <ion-row>
          <ion-col size="5">
            <span style='line-height: 45px; float: left;'>Multiple Answers</span>

          </ion-col>

          <ion-col size="7">
            <mat-slide-toggle name="fieldName" ngDefaultControl [(ngModel)]="poll.multipleAnswers" style="float: right;"></mat-slide-toggle>
          </ion-col>

        </ion-row>

        <!-- <mat-divider></mat-divider> -->

        <ion-row>
          <ion-col size="4">
            <span style='line-height: 45px; float: left;'>Public Poll</span>


          </ion-col>

          <ion-col size="8">
            <mat-slide-toggle name="fieldName" ngDefaultControl [(ngModel)]="poll.isPublic" style="float: right;"></mat-slide-toggle>
          </ion-col>

        </ion-row>
        <!-- <ion-row>
        <span style="font: 12px; padding-left: 5px; text-align: left; font-weight: 100;" class="opaque-text">In public
          polls everyone in the group will be
          able
          to view who has voted and for which options</span>
      </ion-row> -->



      </div>

      <div style="height: 20px;"></div>


      <div style="padding: 10px">
        <ion-row>
          <span style="float:left" class="opaque-text">VOTE OPTIONS</span>

        </ion-row>
        <ion-row>

          <ion-col size="12" style="padding: 0; margin: 0;">

            <ion-row *ngFor="let option of this.poll.options; let i = index" style="padding: 0; margin: 0;">
              <mat-form-field style="width: 100%;" floatLabel='never'>
                <input name="fieldName" ngDefaultControl [(ngModel)]="option.description" class="non-bolded-placeholder" matInput
                  placeholder="Option {{i+1}}" style="width: 100%;">


                <button *ngIf=" this.poll.options.length > 2" matSuffix mat-icon-button aria-label="Clear"
                  (click)="removeOption(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

            </ion-row>

            <ion-row *ngIf="this.poll.options.length < 10">
              <span class="special-app-text-color" (click)="addOption()">
                Add More
              </span>
            </ion-row>


          </ion-col>

        </ion-row>
      </div>

      <div style="height: 20px;"></div>

    </ion-grid>

  </div>

</ion-content>