import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REST_BASE_URL } from '../models/constants';
import { Incentive } from '../models/incentives.model';
import { Filter, FiltersForBackend, SummaryResult } from '../models/leaders.model';
import { ChartGenerationService } from './chart-generation.service';
import { ReportsService } from './reports.service';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class LeadersService {

  constructor(public userDetailsService: UserDetailsService, private http: HttpClient) { }

  // public async getFilters(selectedObjectIndex: number): Promise<Filter[]> {
  //   const sessionKey: string = await this.userDetailsService.getSessionKey();
  //   let url = REST_BASE_URL + '/leaderboard/filters/' + sessionKey;

  //   let body = new FormData();
  //   body.append('selectedObjectIndex', selectedObjectIndex + '');

  //   return (await this.http.get<FilterResult>(url).toPromise()).filterList;

  // }

  public async getFilterOutput(): Promise<LeaderboardFilterOutput> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/leaderboard/filters/' + sessionKey;

    let body = new FormData();

    return (await this.http.get<LeaderboardFilterOutput>(url).toPromise());

  }


  public async getDrillDownFilterOutput(currentFilterOutput: LeaderboardFilterOutput, drillDown: string, rollback: boolean = false): Promise<LeaderboardFilterOutput> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/leaderboard/updatedFilters/' + sessionKey;
    currentFilterOutput.drillDown = drillDown;
    url += "?drillDown=" + drillDown;
    url += "&rollback=" + rollback;
    let body = JSON.stringify(currentFilterOutput)
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    
    return (await this.http.post<LeaderboardFilterOutput>(url, body, {headers}).toPromise());

  }
  


  public async getSummaryUpdatedResults(filterOutput: LeaderboardFilterOutput, showZeros: boolean = null, limit: number = 50): Promise<LeaderboardResult> {
    if (!filterOutput) return null;

    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/leaderboard/updatedResults/' + sessionKey;

    let firstChar: string = "?";
    if (showZeros !== null) {
      url += firstChar + "includeZeros=" + showZeros;
      firstChar = "&";
    }

    if (limit !== null) {
      url += firstChar + "limit=" + limit;
      firstChar = "&";
    }

    let body = JSON.stringify(filterOutput)

    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    const output: LeaderboardResult = await this.http.post<LeaderboardResult>(url, body, {headers}).toPromise();

    return output;
  }

  public async getProjects(filterOutput: LeaderboardFilterOutput, dateFilterAttributeIndex: number): Promise<any[]> {
    if (!filterOutput) return null;

    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/leaderboardList/' + sessionKey;

    if (dateFilterAttributeIndex !== null) {
      url += "?dateFilterAttributeIndex=" + dateFilterAttributeIndex;
    }

    let body = JSON.stringify(filterOutput)

    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    return (await this.http.post<any>(url, body, {headers}).toPromise());

  }

}

export interface LeaderboardResult {
  summaryResults: SummaryResult[];
  filterOutput: LeaderboardFilterOutput;
}

export interface FilterResult {
  filterList: Filter[];
}

export interface LeaderboardFilterOutput {
  timePeriodFilter: Filter;
  groupByFilter: Filter;
  showFilter: Filter;

  drillDown: string;
  startDate: string;
  endDate: string;
}
