import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'enzy-are-you-sure-modal',
  templateUrl: './are-you-sure-modal.component.html',
  styleUrls: ['./are-you-sure-modal.component.scss'],
})
export class AreYouSureModalComponent implements OnInit {
  actionCanBeUndone: boolean = false;
  displayText = "Are You Sure?"

  constructor(
    public dialogRef: MatDialogRef<AreYouSureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.actionCanBeUndone) {
      this.actionCanBeUndone = data.actionCanBeUndone
    }

    if (data?.displayText) {
      this.displayText = data?.displayText;
    }

  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false)
  }

  ngOnInit() { }

}
