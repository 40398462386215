import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Platform } from '@ionic/angular';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadsStoreService } from '../../leads-store.service';
import { LeadModel } from '../../leads.model';
import { LeadsService } from '../../leads.service';

@Component({
  selector: 'app-prospect-card',
  templateUrl: './prospect-card.component.html',
  styleUrls: ['./prospect-card.component.scss'],
})
export class ProspectCardComponent implements OnInit {

  @Input() private lead: LeadModel;

  userId: string;

  constructor(public dialog: MatDialog,
    public dateFormatService: DateFormatService,
    public platform: Platform,
    public userDetailsService: UserDetailsService,
    public employeeStoreService: EmployeeStoreService,
    public leadsStoreService: LeadsStoreService, 
    public leadsService: LeadsService) {
    

  }

  async ngOnInit() {
    this.userId =  await this.userDetailsService.getUserId();
  }

  leadIsCompleted(): boolean {
    return this.lead?.isCompleted;
  }

  userIsAssignedCloser() {
    return this.lead?.assignedRep === +this.userId
  }

  userIsSetter() {
    return this.lead?.setterId === +this.userId
  }

  getCustomerName(): string {
    return this.leadsService.getCustomerName(this.lead);
  }

  getAssignedRepName(): string {
    return this.getEmployeeName(this.lead?.assignedRep);
  }

  getAddress() {
    return this.lead?.fullAddress ? this.lead.fullAddress : "-"
  }

  getPhone() {
    return this.lead?.phoneNumber ? this.lead.phoneNumber : "-"
  }

  getTeam() {
    return this.lead?.sourceMarket ? this.lead?.sourceMarket : "-";
  }

  getLeadStatus(): string {
    return this.lead?.leadStatus;
  }

  getLeadProjectStatus(): string {
    return this.lead?.projectStatus;
  }

  getSetterName(): string {
    return this.getEmployeeName(this.lead.setterId);
  }

  private getEmployeeName(id: number) {
    if (id === -1) { return "Assigned" }
    if (id === null) { return "Unassigned" }

    return this.employeeStoreService.getUserName(id) ? this.employeeStoreService.getUserName(id) : "-";
  }

  getAppointment() {
    return this.dateFormatService.formatDateFromString(this.lead?.appointments[this.lead.appointments.length - 1]?.startDateTime)
  }

  leadIsUnassigned() {
    return !this.lead.assignedRep
  }



  async openSelectSingleUserPickerModal() {

    const userId: number = await this.employeeStoreService.selectSingleUserModal();
    if (userId) {
      const oldLead: LeadModel = { ...this.lead };
      this.lead.assignedRep = userId;
      this.leadsService.safeUpdateLead(oldLead, this.lead);
      // this.setTableFilter(this.selectedTab)

    }
  }

  canReassignLead(): boolean {
    return this.leadsService.userCanChangeAssignedRep(this.lead);
  }


  async openLead() {
    const editedLead: LeadModel = await this.leadsService.openLeadUsingModal(this.lead, "view");

      if (editedLead) {
      this.lead = editedLead;
    }

  }

  getLeadStatusOptions(): string[] {
    return this.leadsService.leadOptionsConfig.leadStatus;
  }

  selectLeadStatus(option: string) {
    this.lead.leadStatus = option;
    const oldLead: LeadModel = { ...this.lead };
    this.lead.leadStatus = option;
    this.leadsService.safeUpdateLead(oldLead, this.lead);
  }



  public openMapsApp() {
    const location: any = this.lead?.fullAddress;
    if (this.platform.is('android')) {
      window.location.href = 'geo:' + location;
    } else {
      window.location.href = 'maps://maps.apple.com/?q=' + location;
    }
  }

  async deleteLead(event: Event) {
    if (event) {
      event.stopPropagation();
    }
    const confirmed: boolean = confirm("Are you sure you want to delete the lead? You won't be able to undo this.")
    if (!confirmed) return;
    const successfullyDeleted: boolean = await this.leadsService.deleteLead(this.lead.leadId);
    if (successfullyDeleted) {
      this.leadsStoreService.removeLeadById(this.lead.leadId);
    }
  }
}
