import { options } from '@amcharts/amcharts4/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Employee } from 'src/app/shared/models/employees.model';
import { Incentive } from 'src/app/shared/models/incentives.model';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { HelpItem, HelpService } from 'src/app/shared/services/help.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, MessagingService } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TicketFormModalComponent } from 'src/app/tickets/ticket-form-modal/ticket-form-modal.component';
import { DEFAULT_TICKET, TicketModel, TicketsService } from 'src/app/tickets/tickets.service';

@Component({
  selector: 'app-key-contacts',
  templateUrl: './key-contacts.component.html',
  styleUrls: ['./key-contacts.component.scss'],
})
export class KeyContactsComponent implements OnInit {

  myUserId: number;
  searchOn: boolean = false;

  customerSupportUserId: number;

  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  currentOptions: HelpItem[] = [];
  allOptions: HelpItem[] = [];

  currentInstallCount: number = 0;
  progressBarValue: number = 0;
  campaignId: string = '2';

  searchSend() {
    if (this.searchValue?.length === 0) {
      this.currentOptions = this.allOptions;
      return;
    };
    this.currentOptions = this.allOptions.filter(r => (r.helpDescription + " " + r.helpEmailAddress).toLowerCase().includes(this.searchValue.toLowerCase()));

  }
  constructor(
    public ticketService: TicketsService,
    public helpService: HelpService,
    public userDetailsService: UserDetailsService,
    public messagingStoreService: MessagingStoreService,
    public messagingService: MessagingService,
    public router: Router,
    private platform: Platform,
    public dialog: MatDialog,
    public ticketsService: TicketsService,
    public employeeStoreService: EmployeeStoreService
  ) {
    this.initAsync();
  }

  async initAsync() {
    this.myUserId = +(await this.userDetailsService.getUserId());

   this.assignCustomerSupportUserId();
  }

  async assignCustomerSupportUserId() {
    let res = await this.helpService.getCustomerSupportInfo();
    if (!res) { return }

    this.customerSupportUserId = res.userId

  }

  ngOnInit() {
    this.loadContacts();
  }

  getMyTickets(): TicketModel[] {
    return this.ticketService.tickets?.filter(t => (JSON.stringify(t).includes(this.searchValue)))
      .filter(t => (t.reportingUser === this.myUserId && (t.ticketStatus === 'Submitted' || t.ticketStatus === 'In Progress' || t.ticketStatus === 'Completed')));
  }

  getTicketsAssignedToMe(): TicketModel[] {
    return this.ticketService.tickets?.filter(t => (JSON.stringify(t).includes(this.searchValue)))
      .filter(t => t.assignedUser === this.myUserId);
  }

  async openNewTicketForm() {
    const ticket: TicketModel = { ...DEFAULT_TICKET };
    ticket.reportingUser = +(await this.userDetailsService.getUserId());
    let dialogRef: MatDialogRef<TicketFormModalComponent> = this.dialog.open(TicketFormModalComponent, {
      data: { ticket },
      minWidth: "100vw",
      maxHeight: "100vh"

    })

    dialogRef.afterClosed().subscribe((res: { ticket: TicketModel }) => {
      if (!res) { return }
      if (res.ticket) this.ticketsService.createNewTicket(res.ticket);
    })
  }

  async loadContacts() {
    let res = await this.helpService.getHelpContacts();
    if (res?.object) {
      this.allOptions = res.object;
      this.currentOptions = this.allOptions;
    }
  }


  mailto(helpContactObject: any) {
    const email: string = helpContactObject?.helpEmailAddress
    if (!email) {
      this.messageUsers(helpContactObject);
      return;
    };

    this.platform.ready().then(() => {
      window.open('mailto:' + email);
    });
  }

  messageUsers(helpContactObject: any) {
    const usersToMessage: number[] = helpContactObject?.ids;
    if (!usersToMessage) {
      return;
    }
    // TODO: Make it message the users
    this.initiateConversation(usersToMessage);
  }

  async initiateConversation(usersToMessage: number[]) {
    let usersToSend: string[] = usersToMessage.map(u => u + "");
    const userId: string = await this.userDetailsService.getUserId();
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const participantsIncludingMe: string[] = usersToSend;
    if (!participantsIncludingMe.find(c => c === userId + "")) {
      participantsIncludingMe.push(userId + "");
    }

    this.messagingService.getOrCreateConversationByUserList(sessionKey, participantsIncludingMe).subscribe(async (result) => {
      if (result.conversationId) {

        const newConvoId: number = result.conversationId;
        this.messagingStoreService.loadAllConversations(newConvoId);

        let newConversations: ConversationModel[] = await this.messagingStoreService.getStoredConversations();
        const newConversation: ConversationModel = newConversations.find(c => c.conversationId + "" === result.conversationId + "");
        if (!newConversation) return;

        //TODO
        this.router.navigateByUrl('/messages/' + newConvoId);
      }

    });
  }



  getCustomerSupportUser() {
    // if (!this.ticket?.assignedUser) return null;
    const output: Employee = this.employeeStoreService.getUser(this.customerSupportUserId);
    // console.log(output);
    return output;
  }

  getCustomerSupportUserName() {
    let employee = this.getCustomerSupportUser();
    let firstName = employee?.userDto.firstName ? employee?.userDto.firstName : "";
    let lastName = employee?.userDto.lastName ? employee?.userDto.lastName : "";

    return firstName + " " + lastName;
  }


  chatWithCustomerSupport() {
    let userIds = [this.customerSupportUserId]
    this.initiateConversation(userIds);
  }


}
