import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AnyObjectDescriptionComponent } from 'src/app/shared/components/any-object-description/any-object-description.component';
import { Prize } from 'src/app/shared/models/incentives.model';

@Component({
  selector: 'app-prize',
  templateUrl: './prize.component.html',
  styleUrls: ['./prize.component.scss'],
})
export class PrizeComponent implements OnInit {

  @Input()
  prize: Prize

  @Input()
  currentInstallCount: number = 0;

  progressBarValue: number = 0;

  constructor() { 
  }

  getNumberOfInstallsLeft(){
    let val = this.prize.minValue - this.currentInstallCount;

    if (val > 0) {
      return val + " remaining!"
    }
    else {
      return "Complete!"
    }

  }

  ngOnInit() { 
    if (this.prize && this.currentInstallCount) {
      this.progressBarValue = (this.currentInstallCount / this.prize.minValue) * 100;
    }
  }






}
