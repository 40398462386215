<div style="padding: 20px;">
  <h1 style="margin: 0;">{{title}}</h1>
  <div style="height: 5px;"> </div>

  <mat-divider> </mat-divider>

<div *ngFor="let install of installations; let i = index">
  <div>
    {{getName(install, i)}}
  </div>
  <div>
    {{getAddress(install)}}
  </div>
  <div>
    {{getInstallDate(install)}}
  </div>
  <div style="height: 5px;"> </div>

</div>
