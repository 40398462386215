import { Component, Input, OnInit } from '@angular/core';
import { LeadsService } from 'src/app/leads/leads.service';
import { LeadChanges, MessageModel } from 'src/app/shared/services/messaging.service';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { Router } from '@angular/router';

@Component({
  selector: 'enzy-lead-changes-card',
  templateUrl: './lead-changes-card.component.html',
  styleUrls: ['./lead-changes-card.component.scss'],
})
export class LeadChangesCardComponent implements OnInit {

  message: MessageModel; 
  @Input() set messageInput(newMessage: MessageModel) {
    this.message = newMessage;
  }

  constructor(public leadsService: LeadsService, public router: Router) { }

  ngOnInit() {}

  getColumnName(change: LeadChanges) {
    return camelCaseToSentenceCase(change.fieldName)
  }

  navigateToLead() {
    if (this.message.internalNavUrl)
      this.router.navigateByUrl(this.message.internalNavUrl)
  }


}
