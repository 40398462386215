import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-general-input-modal',
  templateUrl: './general-input-modal.component.html',
  styleUrls: ['./general-input-modal.component.scss'],
})
export class GeneralInputModalComponent implements OnInit {

  title:string;
  prompt: string;

  textToSubmit: string = '';
  submitButtonString: string = 'Done';

  constructor(public dialogRef: MatDialogRef<GeneralInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.title = this.data?.title;
      this.prompt = this.data?.prompt;

    }

  ngOnInit() {}


  submit() {
    this.dialogRef.close(this.textToSubmit);
  }

}
