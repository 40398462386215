<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<!-- <app-my-header>
</app-my-header> -->
<ion-content>
  <div style="max-width: 600px; display: block; margin: auto; padding: 10px;">


    <p>Email Verification Lookup</p>


    <mat-form-field class="example-form-field" appearance="fill" style="width: 100%;">
      <mat-label>Email</mat-label>
      <input matInput type="text" [(ngModel)]="email">
      <button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-button class="primary-color" 
      style="display: block; margin: auto;"
      (click)="lookupCode()">Lookup</button>


    <div *ngIf="this.emailVerificationResponse">
      <div *ngIf="this.emailVerificationResponse.success">
        <h1>Success: {{this.emailVerificationResponse.emailVerificationCode}}</h1>
      </div>

      <div *ngIf="!this.emailVerificationResponse.success">
        <span style="color: red;">{{this.emailVerificationResponse.message}}</span>
      </div>

    </div>
  
    <div style="height: 20px;"></div>
    <mat-divider></mat-divider>
    <div style="height: 20px;"></div>

    <button (click)="selectUser()" mat-button class="primary-color">
      Lookup User Information
    </button>

    <div *ngFor="let field of getUserFieldsToDisplay()">
      {{field}}: {{getUserFieldValue(field)}}
    </div>
    <div *ngIf="employeeSessionKey">
      session key: {{employeeSessionKey}}
    </div>
    <div *ngFor="let field of getUserExtraFieldsToDisplay()">
      {{field}}: {{getUserExtraFieldValue(field)}}
    </div>

    <div *ngFor="let device of deviceDetails" style="width: 100%;">
      <mat-card style="width: 100%; margin: 10px;">
        <ion-grid style="width: 100%;">
            <ion-row>
              <ion-col size="4">DeviceInfo</ion-col>
              <ion-col size="8">
                Model: {{device?.deviceInfo?.model}}<br />
                Operating System: {{device?.deviceInfo?.operatingSystem}}<br />
                Name: {{device?.deviceInfo?.name}}<br />
                Manufacturer: {{device?.deviceInfo?.manufacturer}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">AppVersion</ion-col>
              <ion-col size="8">{{device.appVersion}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">DeviceId</ion-col>
              <ion-col size="8">{{device.deviceId}}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="4">InsertDateTime</ion-col>
              <ion-col size="8">{{device.insertDateTime}}</ion-col>
            </ion-row>

        </ion-grid>
      </mat-card>
    </div>
    <!-- <div>
      {{getEmployeeDescription()}}
    </div> -->

    <div style="height: 20px;"></div>
    <mat-divider></mat-divider>
    <div style="height: 20px;"></div>

    <h5>
      Update Team Image
    </h5>
    <div>
      
      <mat-form-field class="example-form-field" appearance="fill" style="width: 100%;">
        <mat-label>Team to update</mat-label>
        <input 
        matInput
        type="text" 
        [(ngModel)]="nameOfTeamForImageUpdate">
      </mat-form-field>
    </div>
    <app-multi-file-upload [fileUrls]='this.fileUrls'></app-multi-file-upload>
    <button (click)="saveTeamImage()" mat-button class="primary-color">
      Save Team Image
    </button>


    <div style="height: 20px;"></div>
    <mat-divider></mat-divider>
    <div style="height: 20px;"></div>

    <h2>
      Tools that need to be built
    </h2>
    <ul>
      <li *ngFor="let toolIdea of getListOfToolsThatNeedToBeBuilt()">
        {{toolIdea}}
      </li>
    </ul>
    
  </div>

</ion-content>

<app-my-footer>
</app-my-footer>