<ion-header class="ion-no-border app-background-color">
    <ion-toolbar class="transparent-bg" style="max-width: 1200px; display: block; margin: auto;">  
      <div slot="start">
        <div style="width: 5px;"></div>
      </div>
      <div slot="start">
        <button mat-icon-button (click)="goBack()">
          <span class="material-icons">
            close
            </span>
        </button>
      </div>

      <div slot="end" *ngIf="this.selectedUsers_toSend.length >= 2 && !isAddingToExistingConversation"> 
        <button class="filter-button" style="max-width: 200px; float: right;" mat-button [matMenuTriggerFor]="timePeriodMenu">

          <span class="zeroLineHeight"> <span *ngIf="!isBcc"> Group Message</span> <span *ngIf="isBcc"> Private Message</span></span>

          <i class="material-icons">
            arrow_drop_down
          </i>
        </button>
      </div>


      <div slot="end">
        <div style="width: 5px;"></div>
      </div>
    
      <div slot="end">
        <div>
          <button *ngIf="this.selectedUsers_toSend.length !== 0" (click)="sendMessage()" class=" primary-color" style=" border-radius: 22px; font-size: 14px; width: 50%; height: 40px;" mat-button>
            Next
          </button>
        </div>
      </div>

       <div slot="end">
        <div style="width: 5px;"></div>
      </div>
    </ion-toolbar>
    <mat-menu #timePeriodMenu="matMenu">
      <ng-template matMenuContent>

        <div>
       
          <button mat-menu-item (click)="updateIsBcc(false)" style="text-align: center;  ">
            <span [ngStyle]="isBcc ? {'font-weight': '100'} : {'font-weight' : '800'}">
              Group Message
            </span>
          </button>

          <button mat-menu-item (click)="updateIsBcc(true)" style="text-align: center;  ">
            <span [ngStyle]="isBcc ? {'font-weight': '800'} : {'font-weight' : '100'}">
              Private Message
            </span>
          </button>
        </div>
      </ng-template>
    </mat-menu>
</ion-header>

<ion-content scrollY="false">
  <div class="custom-scroll" style="padding: 20px; max-width: 1200px; display: block; margin: auto;">
    <mat-form-field class="example-chip-list">
      <mat-label>Recipients</mat-label>

      <mat-chip-list #chipList aria-label="Fruit selection">

        <div *ngIf="this.showEntireList === false">
          <div *ngFor="let selectedUser of selectedUsers_toSend; let i = index" style="width: 100%;">
            <mat-chip *ngIf="i < 5" [selectable]="selectable" [removable]="removable"
              (removed)="removeUser(selectedUser)" style="padding: 0; ">


              <ion-toolbar class="transparent-bg">
                <div slot="start">
                  <div style="margin-top: 10px; margin-left: -8px; ">
                    <app-user-icon matListAvatar [matChip]="true" [employee]='selectedUser'>
                    </app-user-icon>
                  </div>

                </div>
                <div slot="start" style="width: 10px;">

                </div>

                <div slot="start">
                  <div class="mat-bod-string sender-name" style="padding: 10px 0 10px 0px; font-size: 20px; white-space: nowrap; font-weight: 100;">
                    {{selectedUser?.fullName}}</div>
                </div>
              </ion-toolbar>


              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              <br>

            </mat-chip>
          </div>

          <mat-chip *ngIf="this.selectedUsers_toSend.length > 5" [selectable]="selectable" (click)="showFullList()">

            <ion-toolbar class="transparent-bg">
              <div slot="start">
                {{selectedUsers_toSend.length - 5}} More

              </div>

            </ion-toolbar>
          </mat-chip>

        </div>



        <div *ngIf="this.showEntireList === true" style="width: 100%;">
          <mat-chip *ngFor="let selectedUser of selectedUsers_toSend" [selectable]="selectable" [removable]="removable"
            (removed)="removeUser(selectedUser)">


            <ion-toolbar class="transparent-bg">
              <div slot="start">
                <div style="margin-top: 10px; margin-left: -5px;">
                  <app-user-icon matListAvatar [employee]='selectedUser'>
                  </app-user-icon>
                </div>

              </div>
              <div slot="start" style="width: 10px;">

              </div>

              <div slot="start">
                <div class="mat-bod-string sender-name" style="font-size: 16px; white-space: nowrap;">
                  {{selectedUser?.fullName}}</div>
              </div>
            </ion-toolbar>


            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>

          <mat-chip *ngIf="this.selectedUsers_toSend.length > 5" [selectable]="selectable" (click)="showLess()">
            <ion-toolbar class="transparent-bg">
              <div slot="start">
                Show Less

              </div>

            </ion-toolbar>

          </mat-chip>

        </div>


        <ion-toolbar class="transparent-bg">
          <input #searchInput  placeholder="Add..." (keyup)="applyFilter($event)" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [(ngModel)]="inputVal">
        </ion-toolbar>

      </mat-chip-list>
    </mat-form-field>

    <!-- <div *ngIf="filteredUsers.length === 0">
      <div>
        <mat-spinner class="custom-spinner " style="display: block; margin: auto;"></mat-spinner>
      </div>
    </div> -->





    <div *ngFor="let user of getUsersToShowInFilter(); let i = index">
      <button mat-button style="width: 100%; height: 35px; margin: 0; padding: 0;" (click)="selectUser(user)">
        <ion-toolbar style="height: 50px;" class="transparent-bg">
          <div slot="start">
            <app-user-icon matListAvatar [employee]='user'>
            </app-user-icon>
          </div>
          <div slot="start" style="width: 10px;">

          </div>
          <div slot="start">
            <div class="mat-bod-string sender-name" style="font-size: 16px; margin-top: -12px;">
              {{user?.fullName}}</div>
          </div>
        </ion-toolbar>

      </button>

    </div>


  </div>
</ion-content>