import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { VideoSectionCardComponent } from '../add-section/video-section-card/video-section-card.component';
import { EditDrawerComponent } from '../edit-drawer/edit-drawer.component';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent implements OnInit {
  @Input() video: ResourceFile;
  @Input() displayMode: string = "normal";


  @Output() onEditVideo = new EventEmitter<any>();
  @Output() onRemoveVideo = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer, private dialog: MatDialog, private _bottomSheet: MatBottomSheet) {

  }

  ngOnInit() {}


  getImageForVideo() {
    return this.sanitizer.bypassSecurityTrustStyle(`url(` + this.video.thumbnailUrl +`)`);
  }

  openVideo() {
    
    if (this.video.resourceType === 'video') {

      if (this.video.isInApp) {
        let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
          maxWidth: '100vw !important',
          width: '100%',
          height: '100%',
    
          data: {
            video: this.video.videoUrl
          }
        });
      }
      else {
        this.goToLink(this.video.videoUrl)
      }
      
    }
    else if(this.video.resourceType === 'podcast'){
      if (this.video.isInApp) {
        let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
          maxWidth: '100vw !important',
          width: '100%',
          height: '100%',
    
          data: {
            video: this.video.videoUrl
          }
        });
      }
      else {
        this.goToLink(this.video.videoUrl)
      }
    }
    else {
      this.goToLink(this.video.videoUrl)
    }

  }


  goToLink(url: string){
    window.open(url, "_blank");
  }


  edit() {
    let ref = this._bottomSheet.open(EditDrawerComponent);

    ref.afterDismissed().subscribe(res => {
      if (!res) { return }
    
      if (res === "edit") {
        let ref = this.dialog.open(VideoSectionCardComponent, {
          data: {
            video: this.video
          }
        });

        ref.afterClosed().subscribe(res2 => {
          if (res2) {
            this.onEditVideo.emit(res2);
            this.video = res2;
          }
        })
      }
      else if (res === "remove") {
        this.onRemoveVideo.emit(this.video)
      }

    })

  }

  
  
}
