<div style="padding: 20px">
  <form style="width: 100%" class="example-form">
    <mat-form-field style="width: 100%">
      <mat-label>{{this.data.type}}</mat-label>
      <input matInput [(ngModel)]="this.userData_copy[this.data.formatType]" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </form>

  <button (click)="save()" mat-button style="width: 100%; background-color: #00BCE1; color: white;">Update</button>
</div>


