<div class="app-background-color app-text-color" style="padding: 20px;">
  
    <h1>Request More Information </h1>
  <p>{{data.applicant.firstName}} {{data.applicant.lastName}}</p>
 

    <mat-form-field style="width: 100%;">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput [(ngModel)]="reason"></textarea>
    </mat-form-field>

    <button mat-button style="width: 100%;" (click)="submit()">Submit</button>


</div>
