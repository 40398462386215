import { Component, Input, OnInit } from '@angular/core';
import { Incentive } from 'src/app/shared/models/incentives.model';

@Component({
  selector: 'enzy-incentive-card',
  templateUrl: './incentive-card.component.html',
  styleUrls: ['./incentive-card.component.scss'],
})
export class IncentiveCardComponent implements OnInit {
  @Input() incentive: Incentive;

  constructor() { 
  }

  ngOnInit() {
  }

  isMilestone(){
    return this.incentive.campaignType.includes("milestone")
  }


}
