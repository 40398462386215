import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatChipInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DashboardData } from 'src/app/shared/models/dashboard.model';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.scss'],
})
export class AdvancedFiltersComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl();
  inputCtrlExclude = new FormControl();


  inputCtrlDate = new FormControl();


  filteredOptions: Observable<string[]>;
  filteredOptionsExclude: Observable<string[]>;


  filteredOptionsDate: Observable<string[]>;
  filteredOptionsDate2: Observable<string[]>;

  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoInclude') input: ElementRef
  @ViewChild('autoExclude') inputExclude: ElementRef



  frontEndFilters: FrontEndFilter[] = [];

  selectedFilterCount: number = 0;
  selectedReport: DashboardData;

  autocomplete1: string[] = [];
  autocomplete2: string[] = [];


  autocompleteDate: string[] = [];


  testVal: string = "";

  drillDown: boolean = false;
  selectedDrillDownFilter: any;

  drillDownIndex = -1;

  filterType: string = "";


  dateOptions: any[] = [
    "Today", "Yesterday", "Week To Date", "Last Week", "Month To Date", "Last Month", "Quarter To Date", "Last Quarter", "Year To Date", "Last Year"
  ]

  dateOptions2: any[] = [
    "Today", "Yesterday", "Week To Date", "Last Week", "Month To Date", "Last Month", "Quarter To Date", "Last Quarter", "Year To Date", "Last Year"
  ]

  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<AdvancedFiltersComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {


    this.selectedReport = this.data.selectedReport;
    this.grabAutoCompleteOptions();

    this.data.filterTextList.forEach((element, index) => {

      let includeString = "";
      if(element.includeValue !== null) {
        includeString = element.includeValue.substring(0, element.includeValue.length - 2);
      }

      let excludeString = "";
      if (element.excludeValue !== null) {
        excludeString = element.excludeValue.substring(0, element.excludeValue.length - 2);
      }

      this.frontEndFilters.push({
        title: element.text,
        selectedInclude: (element.includeValue === null) ? [] : includeString.split(','),
        selectedExclude: (element.excludeValue === null) ? [] : excludeString.split(','),
      })



    });

    this.data.filterDateList.forEach(element => {
      if (element.timePeriodValue === "yesterday") {
        element.timePeriodValue = "Yesterday"
      }
      else if (element.timePeriodValue === "today") {
        element.timePeriodValue = "Today"
      }
      else if (element.timePeriodValue === "weekToDate") {
        element.timePeriodValue = "Week To Date"
      }
      else if (element.timePeriodValue === "lastWeek") {
        element.timePeriodValue = "Last Week"
      }
      else if (element.timePeriodValue === "monthToDate") {
        element.timePeriodValue = "Month To Date"
      }
      else if (element.timePeriodValue === "lastMonth") {
        element.timePeriodValue = "Last Month"
      }
      else if (element.timePeriodValue === "quarterToDate") {
        element.timePeriodValue = "Quarter To Date"
      }
      else if (element.timePeriodValue === "lastQuarter") {
        element.timePeriodValue = "Last Quarter"
      }
      else if (element.timePeriodValue === "yearToDate") {
        element.timePeriodValue = "Year To Date"
      }
      else if (element.timePeriodValue === "lastYear") {
        element.timePeriodValue = "Last Year"
      }

    });


    this.filteredOptions = this.inputCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.filteredOptionsExclude = this.inputCtrlExclude.valueChanges.pipe(
      startWith(''),
      map(value => this._filterExclude(value))
    );


    this.filteredOptionsDate = this.inputCtrlDate.valueChanges.pipe(
      startWith(''),
      map(value => this._filterDate(value))
    );

    this.filteredOptionsDate2 = this.inputCtrlDate.valueChanges.pipe(
      startWith(''),
      map(value => this._filterDate(value))
    );

    
  }




  grabAutoCompleteOptions() {
    this.selectedReport.reportCellList.list.forEach(cell => {
      this.autocomplete1.push(cell.cellList[0].value);
      this.autocomplete2.push(cell.cellList[0].value);
    })
  }

  addInclude(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;

    if (value === null) {return}

    if ((value || '').trim()) {
      this.fruits.push(value.trim());
        this.frontEndFilters[index].selectedInclude.push(value);

  
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.inputCtrl.setValue('');
  }

  removeInclude(item: string, indexInput: number): void {
    const index = this.frontEndFilters[indexInput].selectedInclude.indexOf(item);

    if (index >= 0) {
      this.frontEndFilters[indexInput].selectedInclude.splice(index, 1);

    }

  }

  addExclude(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    if (value === null) {return}

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());

      this.frontEndFilters[index].selectedExclude.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.inputCtrlExclude.setValue('');
  }

  removeExclude(fruit: string, indexInput: number): void {
    const index = this.frontEndFilters[indexInput].selectedExclude.indexOf(fruit);

    if (index >= 0) {
      this.frontEndFilters[indexInput].selectedExclude.splice(index, 1);
    }
  }

  

  selectedInclude(event: any, index: number): void {
    this.frontEndFilters[index].selectedInclude.push(event.option.value);
    this.input.nativeElement.value = ''
    this.inputCtrl.setValue('');      
  }


  selectedExclude(event: any, index: number): void {
    this.frontEndFilters[index].selectedExclude.push(event);
    this.inputExclude.nativeElement.value = ''
    this.inputCtrlExclude.setValue('');
  }



  ngOnInit() {
    //Call ALL the filters this report can see.
    //Cross reference which filters I have on this report versus the possible filters
  }

  clearSingle(filter: any) {
    if (filter.includeValue !== undefined) {
      filter.includeValue = null;
      filter.excludeValue = null;
    }



    if (filter.timePeriodValue !== undefined ) {
      filter.timePeriodValue = null;
    }

    if (filter.minValue !== undefined) {
      filter.minValue = '';
      filter.maxValue = '';
    }

    this.apply();
  }

  updateTextFilter(filter: any) {
    this.drillDown = true;
    this.selectedDrillDownFilter = filter;
    this.cdr.detectChanges();

    this.inputCtrl.setValue('');
    this.inputCtrlExclude.setValue('');

    this.filterType = "text";

  }

  clearTextFilter(filter: any) {
    this.drillDown = false;

    filter.includeValue = null;
    filter.excludeValue = null;

  }

  updateDateFilter(filter: any) {
    this.drillDown = true;
    this.selectedDrillDownFilter = filter;

  }

  clearDateFilter(filter: any) {
    filter.timePeriodValue = null;
  }


  backDrillDown() {
    this.apply();
    this.drillDown = false;
  }

  updateNumberFilter(filter: any) {
    this.selectedDrillDownFilter = filter;
    this.drillDown = true;
  }

  clearNumberFilter(filter: any) {
    filter.maxValue = "";
    filter.minValue = "";
  }

  clear(filter: any) {
    filter.timePeriodValue = null;
  }


  clearAll() {

    this.frontEndFilters.forEach((element, index) => {
      this.frontEndFilters[index].selectedInclude = [];
      this.frontEndFilters[index].selectedExclude = [];


      this.data.filterTextList[index].includeValue = null;
      this.data.filterTextList[index].excludeValue = null;
    });


    this.data.filterDateList.forEach((element, index) => {
      this.data.filterDateList[index].minValue = null;
      this.data.filterDateList[index].maxValue = null;
      this.data.filterDateList[index].timePeriodValue = null;
    });

    this.data.filterNumberList.forEach((element, index) => {
      this.data.filterNumberList[index].minValue = '';
      this.data.filterNumberList[index].maxValue = '';
    });

  }



  back() {
    this.save();
    // this.dialogRef.close(undefined);
  }

  private _filterDate(value: string): string[] {
    if (value !== null) {
      const filterValue = value.toLowerCase();

      let o: string[] = [];

      this.dateOptions.forEach(element => {
        o.push(element);
      });

      return o.filter(option => option.toLowerCase().includes(filterValue));
    }


  }


  private _filter(value: string): string[] {
    if (value === null) {return}
    const filterValue = value.toLowerCase();

    let o: string[] = [];

    this.autocomplete1.forEach(element => {
      o.push(element);
    });

    o.sort(function(a, b) {
      var textA = a.toUpperCase();
      var textB = b.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    return o.filter(option => option.toLowerCase().includes(filterValue));
  }


  private _filterExclude(value: string): string[] {
    if (value === null) {return}
    const filterValue = value.toLowerCase();

    let o: string[] = [];

    this.autocomplete2.forEach(element => {
      o.push(element);
    });

    o.sort(function(a, b) {
      var textA = a.toUpperCase();
      var textB = b.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    return o.filter(option => option.toLowerCase().includes(filterValue));
  }



  add() {
  
    this.frontEndFilters.forEach((element, index) => {

      if(this.inputCtrl.value !== '') {
        if (this.selectedDrillDownFilter.attributeText === element.title) {
          this.frontEndFilters[index].selectedInclude.push(this.inputCtrl.value);
          this.inputCtrl.setValue('');
          if (this.input) {
            this.input.nativeElement.value = ''
          }


        }
      }


      if( this.inputCtrlExclude.value !== '') {
        if (this.selectedDrillDownFilter.attributeText === element.title) {
          this.frontEndFilters[index].selectedExclude.push(this.inputCtrlExclude.value);
          this.inputCtrlExclude.setValue('');
          if(this.inputExclude) {
            this.inputExclude.nativeElement.value = ''
          }
        }
      }
    });
  }


  apply() {


    this.frontEndFilters.forEach((element, index) => {
      if (element.selectedInclude.length !== 0) {
        let combinedString = "";

        element.selectedInclude.forEach(item => {
          combinedString = combinedString + item + ", ";
        })

        if (combinedString !== "") {
          this.data.filterTextList[index].includeValue = combinedString;
        }
      }
      else {
        this.data.filterTextList[index].includeValue = null;
      }



      if (element.selectedExclude.length !== 0) {
        let combinedString = "";

        element.selectedExclude.forEach(item => {
          combinedString = combinedString + item + ", ";
        })

        if (combinedString !== "") {

          this.data.filterTextList[index].excludeValue = combinedString;
        }
      }
      else {
        this.data.filterTextList[index].excludeValue = null;
      }



    });

    this.data.filterDateList.forEach(element => {


      if (element.minValue !== "" && element.minValue !== null && element.minValue !== " ") {
        const dateSendingToServer = new DatePipe('en-US').transform(element.minValue, 'yyyy-MM-dd')
        element.minValue = "" + dateSendingToServer;
      }

      if (element.maxValue !== "" && element.maxValue !== null && element.maxValue !== " ") {
        const dateSendingToServer = new DatePipe('en-US').transform(element.maxValue, 'yyyy-MM-dd')
        element.maxValue = "" + dateSendingToServer;
      }

    });

    this.drillDown = false;
  }

  save() {

    this.frontEndFilters.forEach((element, index) => {
      if (element.selectedInclude != []) {
        let combinedString = "";

        element.selectedInclude.forEach(item => {
          combinedString = combinedString + item + ", ";
        })

        if (combinedString !== "") {
          this.data.filterTextList[index].includeValue = combinedString;
        }
      }



      if (element.selectedExclude != []) {
        let combinedString = "";

        element.selectedExclude.forEach(item => {
          combinedString = combinedString + item + ", ";
        })

        if (combinedString !== "") {

          this.data.filterTextList[index].excludeValue = combinedString;
        }
      }



    });

    this.data.filterDateList.forEach(element => {

      if (element.timePeriodValue === "Yesterday") {
        element.timePeriodValue = "yesterday"
      }
      else if (element.timePeriodValue === "Today") {
        element.timePeriodValue = "today"
      }
      else if (element.timePeriodValue === "Week To Date") {
        element.timePeriodValue = "weekToDate"
      }
      else if (element.timePeriodValue === "Last Week") {
        element.timePeriodValue = "lastWeek"
      }
      else if (element.timePeriodValue === "Month To Date") {
        element.timePeriodValue = "monthToDate"
      }
      else if (element.timePeriodValue === "Last Month") {
        element.timePeriodValue = "lastMonth"
      }
      else if (element.timePeriodValue === "Quarter To Date") {
        element.timePeriodValue = "quarterToDate"
      }
      else if (element.timePeriodValue === "Last Quarter") {
        element.timePeriodValue = "lastQuarter"
      }
      else if (element.timePeriodValue === "Year To Date") {
        element.timePeriodValue = "yearToDate"
      }
      else if (element.timePeriodValue === "Last Year") {
        element.timePeriodValue = "lastYear"
      }


      if (element.minValue !== "" && element.minValue !== null && element.minValue !== " ") {
        const dateSendingToServer = new DatePipe('en-US').transform(element.minValue, 'yyyy-MM-dd')
        element.minValue = "" + dateSendingToServer;
      }

      if (element.maxValue !== "" && element.maxValue !== null && element.maxValue !== " ") {
        const dateSendingToServer = new DatePipe('en-US').transform(element.maxValue, 'yyyy-MM-dd')
        element.maxValue = "" + dateSendingToServer;
      }

    });


    this.dialogRef.close(this.data);
  }

}

export class FrontEndFilter {
  title: string;
  selectedInclude: string[];
  selectedExclude: string[];
}

export class SelectedDrillDownFilter {
  filter: any;
  index: number;
}
