import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SafeUrl } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { ResourceFile } from 'src/app/shared/models/help.model';

@Component({
  selector: 'app-library-section-detail',
  templateUrl: './library-section-detail.component.html',
  styleUrls: ['./library-section-detail.component.scss'],
})
export class LibrarySectionDetailComponent implements OnInit {
  videos: ResourceFile[];
  sectionImage: string;
  currentOptions: ResourceFile[] = [];

  constructor(private navCtrl: NavController, private dialog: MatDialog) {
    this.videos = history.state.videos;
    this.sectionImage = history.state.sectionImage;
    this.currentOptions = this.videos;
  }

  searchOn: boolean = false;

  ngOnInit() { }


  goBack() {
    this.navCtrl.back();
  }


  getThumbnailUrl(video: ResourceFile) {
    if (video.thumbnailUrl) { return video.thumbnailUrl }
    else { return "../../../assets/placeholder-image.png" }

  }



  openVideo(video: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        video: video,
      }
    });
  }


  setSearchOn() {
    // this.content.scrollToTop();
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  searchSend() {
    this.currentOptions = this.videos.filter(r => (r.title + "_" + r.trainerName + "_" + r.category).toLowerCase().includes(this.searchValue.toLowerCase()));

  }


}
