import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { UserDetailsService } from './user-details.service';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, 
    public userDetailsService: UserDetailsService   ) { }  


  /**
   * Change the reportMenu so that it will sort ONLY by a specific option
   *
   * @param reportMenu - the original reportMenu
   * @param optionName - the name of the option that should be set as the sorting option
   * @param descending - true to sort descending, false to sort ascending
   */
  public changeMenuToSortByOption(reportMenu: DataReportMenu, optionName: String, descending: boolean = true): DataReportMenu {
    reportMenu.showOptionList = reportMenu.showOptionList.map((option, index) => {

      let newOption = option
      newOption.isSortColumn = false;
      if (newOption.text === optionName) {
        newOption.isSortColumn = true;
        newOption.desc = descending;
      }
      return newOption;

    })
    return reportMenu;
  }


  public async setFavoriteReport(reportId: number): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/insertFavoriteReport/' + sessionKey + "?reportId=" + reportId;
    return this.http.post<ReportCellList>(url, reportId).toPromise();
  }

  public async updateReportOrder(reportIds: string[]): Promise<any> {
    if (!reportIds || reportIds.length === 0) return;
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/insertFavoriteReport/updateOrder/' + sessionKey + "?reportIds=" + reportIds.join(",");
    return this.http.post<ReportCellList>(url, reportIds).toPromise();
  }


  public async deleteFavoriteReport(reportId: number): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/deleteFavoriteReport/' + sessionKey + "?reportId=" + reportId;
    return this.http.post<ReportCellList>(url, reportId).toPromise();
  }

  public getDefaultDataReportMenu(sessionKey: string): Observable<DataReportMenu> {
    let url = REST_BASE_URL + '/dataReportMenu/' + sessionKey;

    return this.http.get<DataReportMenu>(url);
  }

  // public downloadReport(sessionKey: string, email: boolean = false): Observable<any> {
  //   let url = REST_BASE_URL + '/dataReportListCsv/' + sessionKey  ;
  //   if (email) {
  //      url += "?email=" + "true"
  //   }
  //   return this.http.get(url, { responseType: "arraybuffer" } );
  // }


  public async downloadReportFromId(reportId: string, email: boolean = false ): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/dataReportListCsv/' + sessionKey + "/" + reportId ;
    if (email) {
       url += "?email=" + "true"
    }
    return this.http.get(url, { responseType: "arraybuffer" } ).toPromise();
  }


  /*

   public downloadIncentives(sessionKey: string, campaignId: number): Observable<any> {
    let url = REST_BASE_URL + '/milestoneEmployeesCsv/' + sessionKey + "?campaignId=" + campaignId + "&minValue=" + "0";

    return this.http.get(url, { responseType: "arraybuffer" });
  }
  */



  public async getDataReportList(
                          dataReportMenu: DataReportMenu, 
                          offset: number = 0, 
                          includeTrend: boolean = false, 
                          offsetBySessionId: boolean = false, 
                          stratFilter: string = "", 
                          limit: number = 10000): Promise<ReportCellList> { //TODO: REMOVE THE HARDCODED LIMIT OF 800

    const sessionKey: string = await this.userDetailsService.getSessionKey();
    console.log(sessionKey)

    const dataReportListUrl: string = this.getDataReportListUrl(sessionKey, offset, limit, includeTrend, offsetBySessionId, stratFilter);

    const body: any = dataReportMenu
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
                        
    console.log(dataReportListUrl)

    return this.http.post<ReportCellList>(dataReportListUrl, body, config).toPromise();
  }

  private getDataReportListUrl(sessionKey: string, 
    offset: number = 0, 
    limit: number = 0, 
    includeTrend: boolean = false, 
                              offsetBySessionId: boolean = false, 
                              stratFilter: string = ""): string {

    let dataReportListFullUrl = REST_BASE_URL + '/dataReportList/' + sessionKey;

    let firstCharacter: string = '?';
    if (offset !== null && offset >= 0) {
      dataReportListFullUrl += firstCharacter + "offset=" + offset;
      firstCharacter = '&'
    }

    if (includeTrend){
      dataReportListFullUrl += firstCharacter + "includeTrend=" + "true";
      firstCharacter = '&'
    }

    if (stratFilter !== ""){
      dataReportListFullUrl += firstCharacter + "stratFilter=" + stratFilter
      firstCharacter = '&'
    }

    if (offsetBySessionId){
      dataReportListFullUrl += firstCharacter + "offsetBySessionId=false"
      firstCharacter = '&'
    }

    
    if (limit !== null && limit >= 0) {
      dataReportListFullUrl += firstCharacter + "limit=" + limit;
      firstCharacter = '&'
    }

    return dataReportListFullUrl;
  }

  async getLatestReportProjectDetails(reportMenu: DataReportMenu): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/dataList/' + sessionKey;
    const body: any = reportMenu;

    return this.http.post<ReportCellList>(url, body).toPromise();  
  }

}



export interface ReportDataPoint {
  x: string;
  y: string;
  trend?: number;
  rank?: number;
}

export interface Project {
  "Project Id": string;
  "Customer Name": string;
  "Customer Email": string;
  "Customer Phone": string;
  "Customer Address": string;
  "Customer City": string;
  "Customer State": string;
  "Customer Zip": string;
  "Lead Source": string;
  "Branch": string;
  "Sales Rep Name": string,
  "Sales Rep": string;
  "Stage": string;
  "Sales Rep Team": string;
  "Sales Rep Region": string;
}