<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<ion-content scrollY="false">
  <div style="padding: 10px"
    style="background-color: black; background-image: url({{incentive?.backgroundUrl}}); 
    background-size:cover; height: 100vh; background-position: top center;">

    <div style="height: 20px;"></div>
    <ion-toolbar class="no-padding-header transparent-bg" style="position: sticky; top: 0; max-width: 1200px; display: block; margin: auto;">
      <div slot="start">
        <button mat-fab class="app-text-color"
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important; color: white"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>

      <div slot="end">
        <button mat-mini-fab style="text-align: center; background-color: transparent;"
          (click)='editTournament(this.incentive?.id)' *ngIf='userCanEditTournament(this.incentive?.id)'>
          <mat-icon style="text-align: center; ">edit</mat-icon>
        </button>
      </div>

      <div slot="end">
        <button mat-mini-fab style="text-align: center; background-color: transparent;" (click)='switchToRules()'>
          <mat-icon style="text-align: center; ">info</mat-icon>
        </button>
      </div>
    </ion-toolbar>

    <div style="height: 100px"></div>

    <ion-toolbar class="no-padding-header transparent-bg" style="max-width: 1200px; display: block; margin: auto;">
      <div>
        <ion-grid style="padding: 0; margin: 0;  padding: 0; margin: 0;  ">


          <ion-row>
            <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;">


              <span *ngFor='let round of this.rounds; let i = index' style="padding-right: 10px;">
                <div>
                  <button

                    [ngClass]="{'filter-button' : this.selectedRound !== i || !this.showRegularSeason(), 'selected-filter-button' : this.selectedRound === i && this.showRegularSeason()}"
                    [ngStyle]="!round.isActive ? {'color' : 'gray'} : {'color' : 'white'}"
                    mat-button (click)="switchToRegularSeason(i)">
                    <span *ngIf="!round.isActive" class="material-icons iconAlign" style="font-size: 18px;">
                      lock
                      </span> 
                      &nbsp;
                    <span class="zeroLineHeight">{{round.roundName}} &nbsp; 
                      <span style="font-weight: 100;" *ngIf="round.startDate" >

                        ({{round.startDate | date: 'M/d'}} - {{round.endDate | date: 'M/d'}})

                    </span>
                    </span>


                  </button>
                </div>
                <div style="padding: 5px;"></div>
              </span>

              <!-- <div>
                <button
                  [ngClass]="{'filter-button' : !this.showPlayoffs(), 'selected-filter-button' : this.showPlayoffs()}"
                  mat-button (click)="switchToPlayoffs()">

                  <span class="zeroLineHeight">Playoffs &nbsp;</span>

                </button>
              </div>
              <div style="padding: 5px;"></div> -->

              <!-- <div>
                <button
                  [ngClass]="{'filter-button' : !this.showChampionship(), 'selected-filter-button' : this.showChampionship()}"
                  [ngStyle]="!this.currentRound.includes('Championship') ? {'color' : 'gray'} : {'color' : 'white'}"

                  mat-button (click)="switchToChampionship()">
                  <span *ngIf="!this.currentRound.includes('Championship') " class="material-icons iconAlign" style="font-size: 18px;">
                    lock
                    </span> 
                    &nbsp;
                  <span class="zeroLineHeight">Championship &nbsp;</span>

                </button>
              </div>

              <div style="padding: 5px;"></div> -->




              <div style="height: 10px;"></div>
            </div>
          </ion-row>
          <ion-row>
            <div style="height: 10px;"></div>

          </ion-row>

        </ion-grid>
      </div>
    </ion-toolbar>


    <!-- RULES -->
    <div *ngIf="this.showRules()">

      <p style="color: white; font-weight: 100; text-align: center;">
        {{this.rules}}
      </p>

      <div *ngIf='this.rulesImage'>
        <img [src]='this.rulesImage' style="display: block; margin: auto;" />
      </div>

    </div>

    <!-- REGULAR SEASON ROUNDS -->
    <div *ngIf="this.showRegularSeason()" style="padding: 0; max-width: 600px; display: block; margin: auto; overflow-y: scroll; height: 100%;">
      <!-- <p style="text-align: center; color: white; width: 100%; margin: 0;">This Week's Incentive</p> -->

      <div
      [ngStyle]="{'background-image': 'url(' + this.getSelectedRoundPrizeImage() + ')'}"
        style="height: 100px; background-color: #383838;  background-size:cover; background-position: center; border: none;"
        class="section-div-dark">
        <!-- <div style="height: 70px"></div> -->
        <!-- <span style="text-shadow: 0px 1px 6px rgba(0,0,0,0.74);">Prize</span>  -->
      </div>

      <div style="height: 20px;"></div>

      <div *ngFor="let matchupId of this.getCurrentMatchIds()" >

        <!-- <button mat-raised-button (click)='alertUsers(team)' *ngFor='let team of getTeamsForMatchup(matchupId)'>
          {{team}}
        </button> -->

        <app-game-card [game]='gameFromMatchupId(matchupId)' 
        [team1Participants]='getTeam1Participants(matchupId)' 
        [team2Participants]='getTeam2Participants(matchupId)'></app-game-card>


        <div style="height: 10px;"></div>
        <!-- <mat-divider class="material-divider"></mat-divider> -->
        <div style="height: 10px;"></div>

      </div>

      <ion-grid class="custom-grid" *ngIf='this.getCurrentMatchIds()?.length === 1'>
        <mat-divider class="material-divider" style="font-weight: 100;"></mat-divider>
        <ion-row   style="padding: 0; margin: 0">
          <ion-col size="6" style="padding: 0; margin: 0">
            <div *ngFor="let p of getTeam1Participants(this.getCurrentMatchIds()[0])" style="color: white; margin-top: 20px;">
              <ion-grid 
              (click)="alertParticipant(p)"
              style="padding: 0; margin: 0; " 
              class="section-div-dark" 
              [ngClass]="{'winner': (true)}">
                <ion-row style="padding: 0; margin: 0">
                  <ion-col size="4"
                    style="padding: 0; margin: 0; border-top-left-radius: 10px;   border-bottom-left-radius: 10px;background-image: url('../../../assets/temecula.jpg'); background-size:cover;"
                    [ngStyle]="{'background-image': 'url(' + getParticipantImage(p) + ')'}">

                  </ion-col>
        
                  <ion-col size="9" style="padding: 5px 5px 5px 10px">
                    <ion-row style="font-weight: 100; font-size: 14px;">
                      {{getParticipantName(p)}}
                    </ion-row>
        
                    <ion-row>
                      <span style="font-size: 30px; white-space: nowrap; text-align: right;"> {{p.score | number : '1.0-1' }} </span>
        
                    </ion-row>
                  </ion-col>
        
        
                </ion-row>
              </ion-grid>
  
            </div>
            <div style="height: 20px;"></div>

          </ion-col>
          <ion-col size="1">
            <!-- <p style="font-size: 12px; text-align: center; color:rgba(255,255,255,0.4); width: 100%; line-height: 60px; margin: 0;"> vs</p> -->
          </ion-col>
          <ion-col size="6" style="padding: 0; margin: 0">
            <div *ngFor="let p of getTeam2Participants(this.getCurrentMatchIds()[0])" style="color: white; margin-top: 20px;">
                
              <ion-grid style="padding: 0; margin: 0; " 
              (click)="alertParticipant(p)"
              class="section-div-dark" 
              [ngClass]="{'winner': (true)}">
                <ion-row style="padding: 0; margin: 0">
                  
    
                  <ion-col size="9" style="padding: 5px 10px 5px 5px">
                    <ion-row style="font-weight: 100; font-size: 14px;">
                      <span style=" text-align: right; width: 100%;"> {{getParticipantName(p)}} </span>
                    </ion-row>
    
                    <ion-row>
                      <span style="font-size: 30px; white-space: nowrap; text-align: right; width: 100%;"> {{p.score | number : '1.0-1' }} </span>
    
                    </ion-row>
                  </ion-col>
    
                  <ion-col size="4"
                    style="padding: 0; margin: 0; border-top-right-radius: 10px;   border-bottom-right-radius: 10px;background-image: url('../../../assets/temecula.jpg'); background-size:cover;"
                    [ngStyle]="{'background-image': 'url(' + getParticipantImage(p) + ')'}">
    
                  </ion-col>
    
    
                </ion-row>
              </ion-grid>
  
              
            </div>
            <div style="height: 20px;"></div>

          </ion-col>
        </ion-row>

      </ion-grid>



      <div *ngFor="let matchup of this.getSelectedGames()" >
        <app-game-card [game]='matchup'></app-game-card>


        <div style="height: 10px;"></div>
        <div style="height: 10px;"></div>

      </div>

      <div style="height: 250px;"></div>
    </div>

    <!-- PLAYOFFS -->
    <!-- <div *ngIf="this.showPlayoffs()">

      <app-bracket></app-bracket>
    </div> -->

    <!-- CHAMPIONSHIP -->
    <div *ngIf="this.showChampionship()" style="padding: 0px 10px 10px 10px; max-width: 600px; display: block; margin: auto;">
      <div
        style="height: 100px; background-color: #383838; background-image: url('../../../assets/Tesla.jpg'); background-size:cover; background-position: center; border: none;"
        class="section-div-dark">
        <!-- <div style="height: 70px"></div> -->
        <!-- <span style="text-shadow: 0px 1px 6px rgba(0,0,0,0.74);">Prize</span>  -->
      </div>

      <div style="height: 20px"></div>
      <app-championship-card [matchParticipants]='this.championship.heatParticipants'></app-championship-card>


    </div>



  </div>
</ion-content>
