import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Filter } from 'src/app/shared/models/leaders.model';
import { LeadersStoreService } from 'src/app/shared/services/leaders-store.service';
import { LeadersService } from 'src/app/shared/services/leaders.service';

@Component({
  selector: 'app-leader-filter-button',
  templateUrl: './leader-filter-button.component.html',
  styleUrls: ['./leader-filter-button.component.scss'],
})
export class LeaderFilterButtonComponent implements OnInit {
  routeId: string;
  filter: Filter;

  @Input() set filterInput(newFilter: Filter) {
    this.filter = newFilter;
    this.selectedFilter = this.filter?.currentSelectedKey ? this.filter?.currentSelectedKey : this.filter?.defaultSelectedKey;    
  }

  selectedFilter: string = ""
  constructor(
    public leadersStoreService: LeadersStoreService, 
    private _route: ActivatedRoute,) { 


  }

  ngOnInit() {
    this.routeId = this._route.snapshot.paramMap.get('id');

  }

  getKeys(): string[] {
    return this.filter ? Object.keys(this.filter.map) : []
  }

  getValues(): string[] {
    return this.filter ?  Object.values(this.filter?.map) : []
  }

  updateSelection(item: string) {
    let tempId = +this.routeId;

    this.selectedFilter = item;
    if (this.filter?.currentSelectedKey) {
      this.filter.currentSelectedKey = item;
    }
    this.leadersStoreService.setFilter(this.filter, tempId);
    
  }

  getSelectedFilter() {
    return this.filter?.map[this.selectedFilter] ?  this.filter?.map[this.selectedFilter] : "Custom Date"
  }

}
