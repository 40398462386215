import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StratFieldEnum } from 'src/app/shared/enums/stratFieldEnum';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { CompanyRecordsStoreService } from 'src/app/shared/services/company-records-store.service';
@Component({
  selector: 'app-section-carousel',
  templateUrl: './section-carousel.component.html',
  styleUrls: ['./section-carousel.component.scss'],
})
export class SectionCarouselComponent implements OnInit {
  @Input() reports: DashboardData[];
  @Input() title: string;
  constructor() {

  }
  ngOnInit() {

  }


}
