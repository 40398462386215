import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { BusySpinnerComponent } from "../components/busy-spinner/busy-spinner.component";

@Injectable({
    providedIn: 'root'
})
export class BusySpinnerService {
    ref: any;
    constructor(public dialog: MatDialog) {
    }

    start() {
        this.ref = this.dialog.open(BusySpinnerComponent, {
            panelClass: 'transparent',
            disableClose: false
        })
    }


    stop() {
        this.ref.close();
    }

}
