import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Device, DeviceInfo } from '@capacitor/device';

import { Camera, CameraPhoto, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

import { IonRouterOutlet } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { BusySpinnerComponent } from "../shared/components/busy-spinner/busy-spinner.component";
import { WelcomeWizardComponent } from "../shared/components/welcome-wizard/welcome-wizard.component";
import { APP_VERSION, REST_BASE_URL } from "../shared/models/constants";
import { UserData } from "../shared/models/dashboard.model";
import { MessagingSocketService } from "../shared/services/messaging-socket.service";
import { ReportsService } from "../shared/services/reports.service";
import { ThemeService } from "../shared/services/theme-service.service";
import { EditInfoModalComponent } from "./edit-info-modal/edit-info-modal.component";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { Capacitor } from "@capacitor/core";
import { CustomFileService } from "../shared/services/custom-file-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { AppVersion } from '@ionic-native/app-version/ngx';
import { UserDetailsService } from "../shared/services/user-details.service";
import { GeneralInputModalComponent } from "../shared/components/general-input-modal/general-input-modal.component";
import { FcmService } from "../shared/services/fcm.service";
import { Observable } from "rxjs";
import { ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { UploadProfilePictureComponent } from "../shared/components/upload-profile-picture/upload-profile-picture.component";
import { ProfileService } from "../shared/services/profile.service";
import { Workspace } from "../shared/models/api-response.model";
import { EmployeeStoreService } from "../shared/services/employee-store.service";
import { MessagingStoreService } from "../shared/services/messaging-store.service";
import { SnackbarService } from "../shared/services/snackbar.service";
import { Geolocation } from '@capacitor/geolocation';
import { FooterStoreService } from "../shared/components/my-footer/footer-store.service";
import { FooterService } from "../shared/components/my-footer/footer.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  userData: UserData;
  sessionKey: string;

  editMode: boolean = false;

  info: DeviceInfo;
  appVersionDisplay: string;

  selectedWorkspace: Workspace;
  workspaces: Workspace[] = [];

  constructor(
    public themeService: ThemeService,
    private http: HttpClient,
    private platform: Platform,
    public router: Router,
    public snackbarService: SnackbarService,
    public reportsService: ReportsService,
    public footerService: FooterService,
    public fcmService: FcmService,
    public dialog: MatDialog,
    public socketService: MessagingSocketService,
    public localStorage: Storage,
    public customFileService: CustomFileService,
    private sanitizer: DomSanitizer,
    public userDetailsService: UserDetailsService,
    public routerOutlet: IonRouterOutlet,
    public profileService: ProfileService,
    public employeeStoreService: EmployeeStoreService,
    public messagingStoreService: MessagingStoreService
  ) {
    this.initComponentData();
  }

  async initComponentData() {
    this.userData = await this.userDetailsService.getUserData();
    this.sessionKey = await this.userDetailsService.getSessionKey();

    this.appVersionDisplay = APP_VERSION;

    let res = await this.profileService.getWorkspaces();
    if (!res.workspaces) {return}
    this.workspaces = res.workspaces;

    this.workspaces.forEach(workspace => {
      if (workspace.isSelected) { this.selectedWorkspace = workspace }
    })

  }

  getImage(workspace: Workspace) {
    if (workspace.imageUrl) {
      return workspace.imageUrl
    } else {
      return "../../assets/placeholder-image.png"
    }
  }

  async switchWorkspace(workspace: Workspace) {
    let spinner = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    let res = await this.profileService.setWorkspace(workspace.id);
    if (res.success === true) {
      this.selectedWorkspace = workspace
      await this.employeeStoreService.loadAllUsers();
      await this.footerService.setFooterTabs();

      this.profileService.cacheSelectedWorkspace(this.selectedWorkspace);


      setTimeout(() => {
        window.location.reload();
        spinner.close();
      }, 1000);

    

    } else {
      spinner.close();
    }
  }

  items = []

  async ngOnInit() {
    // await this.photoService.loadSaved();
    // console.log("photos...")
    // // console.log(this.photoService.photos)

  

    this.info = await Device.getInfo();


    this.localStorage.get("themeSelection").then((selection: string) => {
      if (selection) {
        this.themeService.setTheme(selection);
      } else {
        this.themeService.setTheme("light");
      }
    });
  }

  openOnboardData() {
    this.router.navigateByUrl('/join-sunder', { state: { authenticatedAppUser: true } })

  }

  async getDeviceInfoDescription(): Promise<string> {
    let output = JSON.stringify(this.info);
    output += "\n" + await this.fcmService.angularFireMessaging.getToken.toPromise();
    return output;
  }

  async displayDevicePopup() {
    const dialogRef = this.dialog.open(GeneralInputModalComponent, {
      maxWidth: "100vw !important",
      width: "80%",

      data: {
        title: 'Device Data',
        prompt: await this.getDeviceInfoDescription(),
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userData = result;
      }
    });
  }

  specialUser() {
    return this.userData?.emailAddress === "hunterbjenkins@gmail.com" ||  this.userData?.emailAddress === "adam.dellapiana@gmail.com"
  }

  ionViewDidEnter() {
    this.routerOutlet.swipeGesture = false;
  }

  async takeSavePhoto() {
    const photo = await Camera.getPhoto({
      quality: 100, // 0-100
      source: CameraSource.Camera, // Prompt | Camera | Photos
      saveToGallery: true,
      resultType: CameraResultType.Base64,
    });
  }

  async openEditPreferredNameModal() {

    const dialogRef = this.dialog.open(GeneralInputModalComponent, {
      maxWidth: "100vw !important",
      width: "80%",

      data: { title: "Preferred Name", prompt: "Enter your new preferred name" },
    });

    const result = await dialogRef.afterClosed().toPromise();
    
    if (result) {

      const newPreferredName: string = result;
      const res = await this.profileService.updateMyPreferredName(newPreferredName)

      if (res?.success) {
        this.userData.preferredFirstName = newPreferredName;
        // TODO: update preferred name in userdetails service and cache it
  
      }
    }
    

  }

  editInfo(type: string, formatType: string) {
    const dialogRef = this.dialog.open(EditInfoModalComponent, {
      maxWidth: "100vw !important",
      width: "80%",

      data: { type: type, formatType: formatType, userData: this.userData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userData = result;
      }
    });
  }

  editPhoto() {
    // this.takeSavePhoto();
    // this.addPhoto();
  }

  imageSrc: string;
  selectedFile = null;

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    // console.log("selected fil: ")

    // console.log(this.selectedFile)

    this.onSubmit();
  }





  getBlobFromFile(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      if (file instanceof Blob) {
        const realFileReader = (fileReader as any)._realReader;
        if (realFileReader) {
          fileReader = realFileReader;
        }
      }

      fileReader.onloadend = function () {
        resolve(new Blob([this.result], { type: file.type }));
      };

      fileReader.onerror = function (err) {
        console.error("Inside fileReader.onerror", err);
        reject(err);
      };

      fileReader.readAsArrayBuffer(file);
    });
  }






  onSubmit() {
    const payload = new FormData();
    payload.append("imageFile", this.selectedFile, this.selectedFile.name);
    this.saveProfilePicture(this.sessionKey, payload);
  }

  public saveProfilePicture(sessionKey: string, image: any) {
    this.dialog.open(BusySpinnerComponent, {
      panelClass: "transparent",
      disableClose: false,
    });

    let nextUrl = REST_BASE_URL + "/uploadUserProfileImage/" + sessionKey;

    return this.http.post<any>(nextUrl, image).subscribe(
      async (res) => {
        this.dialog.closeAll();

        const url: string = res.message;
        if (res.message === "Action Failed") {
          alert("Unable to upload profile picture");
          return;
        }
        if (res.message === "Invalid Key") {
          alert("It's possible your session has expired. Try logging back in.");
          return;
        }

        this.userData = await this.userDetailsService.getUserData();
        this.userData.imageUrl = url;
        this.userDetailsService.setUserData(this.userData);

      },
      (error) => {
        this.dialog.closeAll();
        alert("Unable to upload profile picture");
        console.error(error);
      }
    );
  }

  async addPhoto() {
    const photo: CameraPhoto = await Camera.getPhoto({
      source: CameraSource.Prompt,
      saveToGallery: false,
      resultType: CameraResultType.DataUrl,
    });

    this.selectedFile = photo;

    this.imageSrc = photo.dataUrl;

    this.userData.imageUrl = this.imageSrc;

    // this.reportsService.saveProfilePicture(this.sessionKey, photo);
  }

  initials: string;
  getInitials(): string {
    if (this.userData && this.userData.preferredFirstName !== null) {
      return (
        this.userData.preferredFirstName[0] + this.userData.lastName[0]
      ).toUpperCase();
    }
    if (this.initials) {
      return this.initials;
    }
    return "";
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(WelcomeWizardComponent, {
      maxWidth: "100vw !important",
      width: "100%",
      height: "100%",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  get dark() {
    return this.themeService.theme === "dark";
  }

  set dark(enabled: boolean) {
    this.themeService.theme = enabled ? "dark" : null;
  }

  setTheme(selectedTheme: string) {
    this.themeService.setTheme(selectedTheme);
  }


  openUpdateProfilePhoto() {
    let dialogRef = this.dialog.open(UploadProfilePictureComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        fetch(res)
          .then(res => {
            res.blob().then(blob => {
              const payload = new FormData();
              payload.append("imageFile", blob, "name");
              this.saveProfilePicture(this.sessionKey, payload);
            })
          })
      }
    })

  }

}


