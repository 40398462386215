<!-- <button (click)="newMessagePopup()"
  style="width: 60px; height: 60px; border-radius: 30px; position: fixed; bottom: 100px; right: 15px; z-index: 1000; background-color: #00BCE1; color: white;"
  mat-raised-button>
  <i class="material-icons">
    add
  </i>
</button> -->


<div class="darkTheme">
  <div style="max-width: 1200px; display: block; margin: auto; padding: 0px 10px 0 10px">
    <div style="height: 10px"></div>
    <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
      <div style="padding: 5px;"></div>

      <div style="width: 100%">
        <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
          <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
            search
          </i>
          <span *ngIf="!searchOn" style="float:left; " class="zeroLineHeight opaque-text">Search
          </span>

          <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
            style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
            <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue" (keyup)="searchSend()">
          </mat-form-field>
        </button>

      </div>
      <div style="padding: 5px"></div>
      <div>
        <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
          style="margin-left: -50px; z-index: 999;">
          <i class="material-icons" style="font-size: 14px;">
            clear
          </i>
        </button>
      </div>
      <div style="padding: 2px;"></div>

      
      <div>
        <button class="primary-color" 
        
        [ngClass]="{'primary-color': allFilterSelected(), 'non-primary-color': !allFilterSelected() }"
        style=" border-radius: 22px;
        font-size: 14px;
        width: 40px;
        height: 40px;"  [matMenuTriggerFor]="filterConvosMenu" mat-icon-button>
          <i class="material-icons" style="font-size: 20px">
            filter_alt
          </i>
        </button>

      </div>
      <div style="padding: 5px;"></div>


      <div>
        <button (click)="newConversation()" class="primary-color" style=" border-radius: 22px;
        font-size: 14px;
        width: 40px;
        height: 40px;" mat-icon-button>
          <i class="material-icons" style="font-size: 20px">
            add
          </i>
        </button>
      </div>


    </div>


    <mat-menu #filterConvosMenu="matMenu">
      <ng-template matMenuContent>

        <button mat-menu-item (click)="selectAllFilter()">
          <!-- <mat-icon *ngIf='allFilterSelected()'>check</mat-icon> -->
          <span [ngStyle]="allFilterSelected() ? {'font-weight' : '900'} : {'font-weight' : '400'}">All</span>
        </button>

        <button mat-menu-item (click)="selectUnreadFilter()">
          <!-- <mat-icon *ngIf='unreadFilterSelected()'>check</mat-icon> -->
          <span [ngStyle]="unreadFilterSelected() ? {'font-weight' : '900'} : {'font-weight' : '400'}">Unread</span>
        </button>

        <button mat-menu-item (click)="selectDirectMessageFilter()">
          <!-- <mat-icon *ngIf='directMessageFilterSelected()'>check</mat-icon> -->
          <span [ngStyle]="directMessageFilterSelected() ? {'font-weight' : '900'} : {'font-weight' : '400'}">Direct
            Messages</span>
        </button>

        <button mat-menu-item (click)="selectExcludeAutoChats()">
          <!-- <mat-icon *ngIf='excludeAutoChatfilterSelected()'>check</mat-icon> -->
          <span [ngStyle]="excludeAutoChatfilterSelected() ? {'font-weight' : '900'} : {'font-weight' : '400'}">Exclude
            Region/Team Chats</span>
        </button>

      </ng-template>
    </mat-menu>

    <div>
      <ng-container>

        <ion-grid style="padding: 0; margin: 0;">
          <ion-row style="padding: 0; margin: 0;">
            <ion-col size="{{customColumnSize}}" *ngFor="let conversation of this.getPinnedConversations(); let i = index">

              <div style='display: block; margin: auto; '>
                <app-conversation-cell
                  (click)="openMessageView(conversation)" [isMobile]="this.isMobile" [isPinnedIcon]="true"
                  [conversation]='conversation'>
                </app-conversation-cell>

              </div>
            </ion-col>

          </ion-row>

        </ion-grid>

        <mat-divider></mat-divider>

        <div (scroll)="triggerLoadNewConversations($event)" *ngFor="let conversation of this.getConversationsToShow(); let i = index" style='width: 100%; '>

          <div style='width: 100%; padding: 0px 0 0px 0;'
            [ngClass]="{'opaque-div': (this.conversationWasClicked === i)}">
            <app-conversation-cell
              (click)="openMessageView(conversation)" [isMobile]="this.isMobile" [conversation]='conversation'>
            </app-conversation-cell>
            <div style="padding: 0 5px 0 5px;">
              <mat-divider></mat-divider>

            </div>

          </div>
        </div>

        <div id="end-" style="color: transparent; ">
          end
        </div>
        <button style="width: 100%" 
        *ngIf="this.currentOptions.length > 15 && notScrolling()" 
        mat-button [disableRipple]="true"
          (click)="increaseNumberOfConversationsToRender()">More</button>
      </ng-container>
    </div>



  </div>
</div>