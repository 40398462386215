<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<shared-my-header>
</shared-my-header>



<ion-content>

  <ion-toolbar no-border class="no-padding-header">
    <div style="height: 20px;"></div>
    <div *ngIf="this.userData" style=" padding-bottom: 5px; margin: 0; height: 100px; ">
      <!-- <img *ngIf="this.userData.imageUrl !== null" src="{{this.userData.imageUrl}}"
        style="object-fit: cover; height: 120px; width: 120px; border-radius: 10px; display: block; margin: auto;" /> -->
      <!-- <img  *ngIf="this.userData.imageUrl === null" src="/assets/headshots/default_avatar.png" style="height: 70px; width: 70px; border-radius: 50%; display: block; margin: auto;" /> -->

      <div style="display: block; margin: auto;">


        <button *ngIf="this.userData.imageUrl"
          style="border-radius: 50%; width: 100px; height: 100px; display: block; margin: auto; background-image: url({{this.userData.imageUrl}}); background-size:100% 100%; background-repeat: no-repeat;"
          (click)="openUpdateProfilePhoto()" mat-raised-button>
          <span class="material-icons" style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
            photo_camera
          </span>
        </button>

        <button *ngIf="!this.userData.imageUrl"
          style="width: 100px; height: 100px; display: block; margin: auto; background-image: url('../../assets/headshots/default-avatar-user.jpg'); background-size:100% 100%; background-repeat: no-repeat;"
          (click)="openUpdateProfilePhoto()" mat-raised-button>

          <span style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
            Add Photo
          </span>

        </button>


      </div>

    </div>
  </ion-toolbar>

  <div style="max-width: 600px; display: block; margin: auto;">
    <div style="padding: 20px;" *ngIf="this.userData">
      <p style="padding: 0; margin: 0;"> Personal Info </p>
      <div style="height: 10px;"> </div>

      <button mat-button class="section-div" (click)='openEditPreferredNameModal()'>
        <ion-grid>
          <ion-row>

            <ion-col size="4" style="text-align: left;">
              <p style="padding: 0; margin: 0;">Preferred First Name</p>
            </ion-col>

            <ion-col size="8" style="text-align: right;">
              <span class="secondaryLabel" style="padding: 0; margin: 0;">{{this.userData.preferredFirstName}}</span>
              <mat-icon class="iconAlign" style="font-size: 18px; margin-bottom: -3px;">edit</mat-icon>
            </ion-col>

          </ion-row>
        </ion-grid>
      </button>

      <div style="height: 10px;"> </div>

      <button mat-button class="section-div">
        <ion-grid>
          <ion-row>
            <ion-col size="4" style="text-align: left;">
              <p style="padding: 0; margin: 0;">First Name</p>
            </ion-col>

            <ion-col size="8" style="text-align: right;">
              <p class="secondaryLabel" style="padding: 0; margin: 0;">{{this.userData.firstName}}</p>
            </ion-col>

          </ion-row>
        </ion-grid>
      </button>

      <div style="height: 10px;"> </div>

      <button mat-button class="section-div">
        <ion-grid>
          <ion-row>
            <ion-col size="4" style="text-align: left;">
              <p style="padding: 0; margin: 0;">Last Name</p>
            </ion-col>

            <ion-col size="8" style="text-align: right;">
              <p class="secondaryLabel" style="padding: 0; margin: 0;">{{this.userData.lastName}}</p>
            </ion-col>


          </ion-row>
        </ion-grid>
      </button>

      <div style="height: 10px"></div>

      <button mat-button class="section-div">
        <ion-grid>
          <ion-row>
            <ion-col size="4" style="text-align: left;">
              <p style="padding: 0; margin: 0;">Phone Number</p>
            </ion-col>

            <ion-col size="8" style="text-align: right;">
              <p class="secondaryLabel" style="padding: 0; margin: 0;"> {{this.userData.phoneNumber}}</p>
            </ion-col>


          </ion-row>
        </ion-grid>
      </button>

      <div style="height: 10px;"> </div>

      <button mat-button class="section-div">
        <ion-grid>
          <ion-row>
            <ion-col size="4" style="text-align: left;">
              <p style="padding: 0; margin: 0;">Email Address</p>
            </ion-col>

            <ion-col size="8" style="text-align: right;">
              <p class="secondaryLabel" style="padding: 0; margin: 0;">{{this.userData.emailAddress}}</p>
            </ion-col>


          </ion-row>
        </ion-grid>
      </button>

      <!-- <div style="height: 10px;"> </div>
      <div style="height: 10px;"> </div>

      <button mat-button class="primary-color" (click)="openOnboardData()" style="width: 100%; border-radius: 40px; height: 50px;">Edit Personal Information</button>
      <div style="height: 10px;"> </div> -->



    </div>





    <div style="padding: 20px;">
      <p style="padding: 0; margin: 0; "> App Version: <span class="opaque-text">{{appVersionDisplay}}</span> </p>
      <div style="height: 10px;"> </div>
    </div>


    <div style="padding: 20px;" *ngIf="workspaces?.length > 1">
      <p style="padding: 0; margin: 0; "> Workspaces:</p>
      <div style="height: 10px;"> </div>
      <ion-grid>
        <ion-row>
          <ion-col size="3" *ngFor="let workspace of workspaces" (click)="switchWorkspace(workspace)">

            <div [ngStyle]="{'background-image': 'url(' + workspace.imageUrl + ')'}"
              style="height: 100px; width: 100px; border-radius: 50%; background-position: center; background-size: cover;object-fit: cover; display: block; margin: auto;">

              <div
                style="background-color: green; height: 35px; width: 35px; border-radius: 50%; float: right; border-style: solid; border-width: 2px; border-color: white;"
                *ngIf="this.selectedWorkspace.name === workspace.name">
                <span class="material-icons" style="color: white;  font-size: 32px; ">
                  done
                </span>
              </div>


            </div>




            <span style="text-align: center; width: 100%; display: block; margin: auto;">{{workspace.name}}</span>
          </ion-col>
        </ion-row>
      </ion-grid>

    </div>

    <div style="padding: 20px;">
      <p style="padding: 0; margin: 0; "> App Theme </p>
      <div style="height: 10px;"> </div>



      <ion-grid style="border-radius: 5px;">
        <ion-row>
          <ion-col size="6">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'light') }">
              <ion-row style="background-color: white; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row style="background-color: #166886; height: 25px">
                &nbsp;
                <button (click)="setTheme('light')"
                  style="width: 100%; margin-top: -97px;color: black; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Light</button>

              </ion-row>
            </ion-grid>
          </ion-col>


          <ion-col size="6">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'dark') }">
              <ion-row style="background-color: #44515e; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row style="background-color: rgba(28,35,39,1); height: 25px">
                &nbsp;
                <button (click)="setTheme('dark')"
                  style="width: 100%; margin-top: -97px; color: white; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Dark</button>

              </ion-row>
            </ion-grid>
          </ion-col>


          <ion-col size="6">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'enzy-light') }">
              <ion-row style="background-color: white; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row
                style="background: linear-gradient(90deg, rgba(255,79,110,1) 0%, rgba(228,0,174,1) 48%, rgba(188,0,188,1) 100%); height: 25px">
                &nbsp;
                <button (click)="setTheme('enzy-light')"
                  style="width: 100%; margin-top: -97px;color: black; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Enzy Light</button>

              </ion-row>
            </ion-grid>
          </ion-col>

          <ion-col size="6">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'enzy-dark') }">
              <ion-row style="background-color: #0D0D28; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row
                style="background: linear-gradient(90deg, rgba(255,79,110,1) 0%, rgba(228,0,174,1) 48%, rgba(188,0,188,1) 100%); height: 25px">
                &nbsp;
                <button (click)="setTheme('enzy-dark')"
                  style="width: 100%; margin-top: -97px; color: white; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Enzy Dark</button>

              </ion-row>
            </ion-grid>
          </ion-col>
          <ion-col size="6" *ngIf="this.selectedWorkspace?.id === 7">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'sb-light') }">
              <ion-row style="background-color: white; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row style="background: #ccff00; height: 25px">
                &nbsp;
                <button (click)="setTheme('sb-light')"
                  style="width: 100%; margin-top: -97px;color: black; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Solar Bros Light</button>

              </ion-row>
            </ion-grid>
          </ion-col>


          <ion-col size="6" *ngIf="this.selectedWorkspace?.id === 7">

            <ion-grid style="padding: 0; margin: 0;"
              [ngClass]="{'highlighted-theme': (this.themeService.theme === 'sb-dark') }">
              <ion-row style="background-color: #0D0D28; height: 75px">
                &nbsp;
              </ion-row>
              <ion-row style="background: #ccff00; height: 25px">
                &nbsp;
                <button (click)="setTheme('sb-dark')"
                  style="width: 100%; margin-top: -97px; color: white; background-color: rgba(255,255,255, 0.1);"
                  mat-button>Solar Bros Dark</button>

              </ion-row>
            </ion-grid>
          </ion-col>

        </ion-row>
      </ion-grid>
    </div>


  </div>
</ion-content>

<app-my-footer>
</app-my-footer>