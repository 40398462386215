import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BusySpinnerService } from '../shared/services/busy-spinner.service';
import { DateFormatService } from '../shared/services/date-format.service';
import { genericHumanFriendlyTime } from '../shared/services/dates.service';
import { MessagingSocketService } from '../shared/services/messaging-socket.service';
import { ISurveyApi } from '../shared/services/survey.api';
import { AccuracyModalComponent } from './components/dashboard-alert-card/accuracy-modal/accuracy-modal.component';
import { SurveyModalComponent } from './components/dashboard-alert-card/accuracy-modal/survey-modal.component';
import { SurveyResponsePopUpComponent } from './components/survey-response-pop-up/survey-response-pop-up.component';

export abstract class ICRDashboardService {
    abstract readonly dashboardAlertsModel: Observable<DashboardAlertModel[]>; // From leadership only
    abstract readonly dashboardThumbsModel: Observable<DashboardThumbsModel[]>;
    abstract readonly dashboardRatingsModel: Observable<DashboardRatingsModel[]>;

    abstract readonly location: string;


    abstract changeStoreLocation(location: string);
    abstract changeStoreDate(date: string);

    abstract openSurveyResponsePopUp(response: DashboardAlertModel);
    abstract openAccuracyDetails(alert: DashboardAlertModel): void;
    abstract openAlertDetails(alert: DashboardAlertModel): void;
    abstract dispositionSurvey(surveyResponse: DashboardAlertModel): Promise<boolean>;
    abstract refresh();

}

@Injectable()
export class CRDashboardService implements ICRDashboardService {

    surveyResponses: DashboardAlertModel[] = [];

    private _dashboardAlertsModel: BehaviorSubject<DashboardAlertModel[]> = new BehaviorSubject(null);
    private _dashboardThumbsModel: BehaviorSubject<DashboardThumbsModel[]> = new BehaviorSubject(null);
    private _dashboardRatingsModel: BehaviorSubject<DashboardRatingsModel[]> = new BehaviorSubject(null);
    public readonly dashboardAlertsModel: Observable<DashboardAlertModel[]> = this._dashboardAlertsModel.asObservable();
    public readonly dashboardThumbsModel: Observable<DashboardThumbsModel[]> = this._dashboardThumbsModel.asObservable();
    public readonly dashboardRatingsModel: Observable<DashboardRatingsModel[]> = this._dashboardRatingsModel.asObservable();

    public location: string = "Taylorsville";
    public date: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    constructor(
        private dateFormatService: DateFormatService,
        private messageSocket: MessagingSocketService,
        private surveyApi: ISurveyApi,
        private busySpinnerService: BusySpinnerService,
        private dialog: MatDialog,
        public router: Router
    ) {
        this.init()
    }

    async init() {
        await this.initSurveyResponsesFromServer();
        this.startSurveyResponses();

        this.initDashboardAlertsModelFromServer();
        this.initDashboardThumbsModelFromServer();
        this.initDashboardRatingsModelFromServer();
    }

    changeStoreLocation(location: string) {
        this.location = location;
        this.refresh();
    }

    changeStoreDate(date: string) {
        this.date = date;
        this.refresh();
    }


    async refresh() {
        this.busySpinnerService.start();
        await this.initSurveyResponsesFromServer();

        await this.initDashboardAlertsModelFromServer();
        await this.initDashboardRatingsModelFromServer();
        await this.initDashboardThumbsModelFromServer();
        this.busySpinnerService.stop();

    }


    async dispositionSurvey(surveyResponse: DashboardAlertModel): Promise<boolean> {
        try {
            const res = await this.surveyApi.disposition(surveyResponse, this.location);
            surveyResponse.dispositioned = true

            this.playDispositionAudio();

            this.initSurveyResponsesFromServer();

        } catch (e) {
            alert("Unable to disposition survey.")
            return false;
        }
        return true;
    }


    private playIncomingAlert() {
        this.playAudio("../../assets/sounds/notification.mp3")
    }

    private playIncomingApplaud() {
        this.playAudio("../../assets/sounds/ES_Heavenly Harp 3 - SFX Producer.mp3")
    }

    private playDispositionAudio() {
        this.playAudio("../../assets/sounds/harp.mp3")
    }


    private playAudio(sound: string) {
        let audio = new Audio();
        audio.src = sound;
        audio.load();
        audio.play();
    }


    currentDateIsToday() {
        return this.date === this.dateFormatService.convertDateToFormat(new Date())
    }

    async initSurveyResponsesFromServer() {
        this.surveyResponses = (await this.surveyApi.getSurveyResponses(this.location, this.date));
    }

    startSurveyResponses() {
        this.messageSocket.socketDashboardAlertModel$.subscribe(
            response => {
                console.log(response, this.location)
                if (this.router.url !== "/cr-dashboard") { return }
                if (response.location !== this.location) return;
                this.surveyResponses.unshift(response)
                this.surveyResponse_REDUCER(response);
            }
        );
    }


    private async initDashboardAlertsModelFromServer() {
        this._dashboardAlertsModel.next(this.initDashboardAlertModels());
    }

    private surveyResponse_REDUCER(response: DashboardAlertModel) {
        if (this.surveyResponseIsAlert(response)) {
            this.dashboardAlert_REDUCER(response);
        } else if (this.surveyResponseIsRatings(response)) {
            this.dashboardRatings_REDUCER(response);
        } else if (this.surveyResponseIsThumbsUp(response) || this.surveyResponseIsThumbsDown(response)) {
            this.dashboardThumbs_REDUCER(response);
        }
    }



    //***************** ALERTS ******************/
    private surveyResponseIsAlert(survey: DashboardAlertModel) {
        return this.surveyResponseIsTrash(survey) || this.surveyResponseIsRestroom(survey) || this.surveyResponseIsAccuracy(survey)
    }

    private surveyResponseIsTrash(survey: DashboardAlertModel) {
        return survey.surveyPageKey?.includes("CR_TRASH")
    }

    private surveyResponseIsRestroom(survey: DashboardAlertModel) {
        return survey.surveyPageKey?.includes("CR_RESTROOM")
    }

    private surveyResponseIsAccuracy(survey: DashboardAlertModel) {
        return (survey.surveyId === 11 || survey.surveyId === 12 || survey.surveyId === 13)
            && survey.response?.includes("Accuracy")
    }

    private dashboardAlert_REDUCER(response: DashboardAlertModel) {
        let headerOfCardToUpdateIncludes: string;
        if (this.surveyResponseIsAccuracy(response)) {
            response.dispositioned = false;
            headerOfCardToUpdateIncludes = "Accuracy";
            response = this.accuracyCard(response);

        }
        if (this.surveyResponseIsRestroom(response)) {
            response = this.restroomCard(response);
            headerOfCardToUpdateIncludes = "Restroom";
        }
        if (this.surveyResponseIsTrash(response)) {
            response = this.trashCard(response);
            headerOfCardToUpdateIncludes = "Trash";
        }

        if (!response.dispositioned) {
            this.playIncomingAlert();
        }
        this.updateAlertModels(response, headerOfCardToUpdateIncludes);
    }

    private updateAlertModels(response: DashboardAlertModel, headerIncludes: string) {
        if (!headerIncludes) return;
        const alertModels: DashboardAlertModel[] = this._dashboardAlertsModel.getValue();
        const indexToUpdate = alertModels.findIndex(m => m.header.includes(headerIncludes));
        if (indexToUpdate < 0) return;
        alertModels[indexToUpdate] = response;

        this._dashboardAlertsModel.next(alertModels);
    }

    private initDashboardAlertModels(): DashboardAlertModel[] {
        let dashboardAlerts = this.surveyResponses.filter(s => this.surveyResponseIsAlert(s));

        const dashboardAlertsModel: DashboardAlertModel[] = [
            this.restroomCard(this.getLatestRestroomSurvey(dashboardAlerts)),
            this.trashCard(this.getLatestTrashSurvey(dashboardAlerts)),
            this.accuracyCard(this.getLatestAccuracySurvey(dashboardAlerts)),
        ];

        return dashboardAlertsModel.sort((a, b) => dashboardAlertsSortSurveyPageKeyComparator(a, b));
    }

    private accuracyCard(surveyResponse: DashboardAlertModel): DashboardAlertModel {
        let dashboardAlerts = this.surveyResponses.filter(s => this.surveyResponseIsAlert(s));

        return {
            ...surveyResponse,
            dispositioned: (this.getLatestAccuracySurveys(dashboardAlerts).length === 0 || !this.currentDateIsToday()),
            dispositionable: true,
            header: "Accuracy (" + this.getLatestAccuracySurveys(dashboardAlerts).length + ")",
            iconString: "🌯",
        }
    }

    private restroomCard(surveyResponse: DashboardAlertModel): DashboardAlertModel {
        return {
            ...surveyResponse,
            header: "Restroom",
            iconString: "🧼",
            dispositionable: true //TODO: we shouldn't have to set this here... should come from backend
        }
    }

    private trashCard(surveyResponse: DashboardAlertModel): DashboardAlertModel {
        return {
            ...surveyResponse,
            header: "Trash",
            iconString: "🗑️",
            dispositionable: true //TODO: we shouldn't have to set this here... should come from backend
        }
    }

    private getLatestTrashSurvey(surveyResponse: DashboardAlertModel[]) {
        let trashResponses = surveyResponse.filter(s => this.surveyResponseIsTrash(s));
        let def: DashboardAlertModel = { ...DEFAULT_DASHBOARD_ALERT }
        def.dispositioned = true
        return trashResponses[0] ? trashResponses[0] : def
    }

    private getLatestRestroomSurvey(surveyResponse: DashboardAlertModel[]) {
        let restroomSurvey = surveyResponse.filter(s => this.surveyResponseIsRestroom(s));
        let def: DashboardAlertModel = { ...DEFAULT_DASHBOARD_ALERT }
        def.dispositioned = true
        return restroomSurvey[0] ? restroomSurvey[0] : def
    }

    private getLatestAccuracySurvey(surveyResponse: DashboardAlertModel[]) {
        let survey = surveyResponse.filter(s => this.surveyResponseIsAccuracy(s));
        return survey[0] || { ...DEFAULT_DASHBOARD_ALERT, dispositioned: true }
    }


    private getLatestAccuracySurveys(surveyResponse: DashboardAlertModel[]) {
        let surveys: DashboardAlertModel[] = [];
        //TODO we'll want to filter on which accuracy alerts have been dispositioned
        surveys = surveyResponse.filter(s => this.surveyResponseIsAccuracy(s)
            //  && this.dateFormatService.isTodayUTC(s.timeStamp) 
        );
        return surveys
    }

    openAccuracyDetails(alert: DashboardAlertModel) {
        if (!alert.dispositioned) {
            alert.dispositioned = true;
            this.playDispositionAudio();
        }

        let dashboardAlerts = this.surveyResponses.filter(s => this.surveyResponseIsAlert(s));
        let res: any = [];
        this.getLatestAccuracySurveys(dashboardAlerts).forEach(survey => {
            res.push(this.getAssociatedAccuracySurveys(survey))
        })

        this.dialog.open(AccuracyModalComponent, {
            data: {
                res: res
            },
            minWidth: "300px",
            maxHeight: "80vh"
        })
    }

    openAlertDetails(alert: DashboardAlertModel) {

        this.dialog.open(SurveyModalComponent, {
            data: {
                res: [this.getAssociatedSurveys(alert)]
            },
            minWidth: "500px",
            height: "80vh"
        })
    }

    private getAssociatedSurveys(survey: DashboardAlertModel): DashboardAlertModel[] {
        return this.surveyResponses.filter(s => s.sessionId === survey.sessionId);
    }

    private getAssociatedAccuracySurveys(survey: DashboardAlertModel): DashboardAlertModel[] {
        return this.getAssociatedSurveys(survey).filter(s => this.surveyResponseIsAccuracy(survey));
    }


    //***********************************/


    //***************** RATINGS ******************/
    private surveyResponseIsRatings(survey: DashboardAlertModel) {
        return survey.surveyId === 1 || survey.surveyId === 2 || survey.surveyId === 3
    }

    private async dashboardRatings_REDUCER(response: DashboardAlertModel) {
        if (response.numericResponse <= 3) {
            this.openSurveyResponsePopUp(response)
            this.playIncomingAlert();
        } else {
            this.openSurveyResponsePopUp(response)
            this.playIncomingApplaud();
        }
        this._dashboardRatingsModel.next(this.getDashboardRatingsModels());
    }


    openSurveyResponsePopUp(response: DashboardAlertModel) {
        this.dialog.open(SurveyResponsePopUpComponent, {
            data: { response: response },
            minWidth: "100vw",
            minHeight: "100vh"
        })
    }

    private async initDashboardRatingsModelFromServer() {
        this._dashboardRatingsModel.next(this.getDashboardRatingsModels());
    }

    private getDashboardRatingsModels() {
        let dashboardRatingsSurveys = this.surveyResponses.filter(s => this.surveyResponseIsRatings(s))

        const dashboardRatingsModel: DashboardRatingsModel[] = [
            {
                category: "Today's Ratings",
                score: this.calcuateTodaysRatingsScore(dashboardRatingsSurveys),
                description: this.calcuateTodaysCount(dashboardRatingsSurveys) + " Ratings"
            },
            
        ];


        if (this.currentDateIsToday()) {
            dashboardRatingsModel.push({
                category: "Last 10 Ratings",
                score: this.calcuateLast10RatingsScore(dashboardRatingsSurveys),
                description: genericHumanFriendlyTime(this.calcuateOldest10RatingsDate(dashboardRatingsSurveys))
            })
        }


        return dashboardRatingsModel
    }

    private calcuateOldest10RatingsDate(surveyResponse: DashboardAlertModel[]) {
        let recent10 = surveyResponse.slice(0, 10);
        return recent10[recent10.length - 1]?.timeStamp
    }

    private calcuateLast10RatingsScore(surveyResponse: DashboardAlertModel[]) {
        let total = 0;
        let count = 0;
        surveyResponse.slice(0, 10).forEach(s => {
            total += s.numericResponse;
            count++;
        })
        return Math.round(total / count * 100) / 100;
    }

    private calcuateTodaysCount(surveyResponse: DashboardAlertModel[]) {
        let count = 0;
        surveyResponse.forEach(s => {
            // if (this.dateFormatService.isTodayUTC(s.timeStamp)) {
            count++;
            // }
        })
        return count
    }

    private calcuateTodaysRatingsScore(surveyResponse: DashboardAlertModel[]) {
        let total = 0;
        let count = 0;
        surveyResponse.forEach(s => {
            // if (this.dateFormatService.isTodayUTC(s.timeStamp)) {
            total += s.numericResponse;
            count++;
            // }
        })
        return Math.round(total / count * 100) / 100;
    }

    //************************************/


    //***************** THUMBS  ******************/

    private surveyResponseIsThumbsUp(survey: DashboardAlertModel) {
        return survey.response?.includes("THUMBS_UP")
    }

    private surveyResponseIsThumbsDown(survey: DashboardAlertModel) {
        return survey.response?.includes("THUMBS_DOWN")
    }

    private async dashboardThumbs_REDUCER(response: DashboardAlertModel) {
        this._dashboardThumbsModel.next(this.getDashboardThumbsModels());
    }

    private getDashboardThumbsModels() {
        let dashboardThumbsSurveys = this.surveyResponses.filter(s => this.surveyResponseIsThumbsUp(s) || this.surveyResponseIsThumbsDown(s))
        let categories: ShiftTimePeriod[] = ["Lunch", "Mid-Day", "Dinner"];

        const dashboardThumbsModel: DashboardThumbsModel[] = []
        categories.forEach(c => {
            let thumb: DashboardThumbsModel =
            {
                up: {
                    count: this.getThumbs(dashboardThumbsSurveys, "THUMBS_UP", c).length,
                    percentage: ""
                },
                down: {
                    count: this.getThumbs(dashboardThumbsSurveys, "THUMBS_DOWN", c).length,
                    percentage: ""
                },
                category: c
            }
            dashboardThumbsModel.push(thumb)
        })

        return dashboardThumbsModel
    }


    private async initDashboardThumbsModelFromServer() {
        this._dashboardThumbsModel.next(this.getDashboardThumbsModels());
    }


    private getThumbs(surveyResponses: DashboardAlertModel[], type: "THUMBS_UP" | "THUMBS_DOWN", timePeriod: "Lunch" | "Mid-Day" | "Dinner") {
        return surveyResponses
            .filter(response => this.thumbResponseIncluded(response, type, timePeriod));

    }

    private thumbResponseIncluded(response: DashboardAlertModel, type: "THUMBS_UP" | "THUMBS_DOWN", timePeriod: ShiftTimePeriod) {
        const { start, end } = timePeriodStartTimeMap[timePeriod];

        return response.response?.includes(type)
            // && this.dateFormatService.isTodayUTC(response.timeStamp)
            && responseHour(response) >= start && responseHour(response) < end
    }
    //***********************************/



}

type ShiftTimePeriod = "Lunch" | "Mid-Day" | "Dinner"

function responseHour(response: DashboardAlertModel) {
    return (new Date(response.timeStamp.replace(/-/g, "/") + " UTC")).getHours();
}

const timePeriodStartTimeMap = {
    "Lunch": { start: 0, end: 15 },
    "Mid-Day": { start: 15, end: 18 },
    "Dinner": { start: 18, end: 25 },
}

export const DEFAULT_DASHBOARD_ALERT: DashboardAlertModel = {
    surveyResponseId: null,
    surveyId: null,

    pageVisitId: null,
    surveyPageKey: null,

    header: null,
    iconString: null,
    timeStamp: null, // TODO: date type?
    dispositionable: null,
    dispositioned: null,
}

export interface DashboardAlertModel {
    surveyResponseId?: number;
    surveyId?: number;
    response?: string;
    pageVisitId?: number;
    surveyPageKey?: string;
    sessionId?: string;

    surveyTitle?: string;
    numericResponse?: number;
    location?: string;

    header: string;
    iconString: string;
    timeStamp: string; // TODO: date type?
    dispositionable: boolean;
    dispositioned: boolean;

}

export interface SurveyResponse {
    surveyResponseId: number;

    surveyId: number;
    frontendIdentifier: string;
    sessionId: string;
    requestIp: string;
    deviceName: string;

    location: string;
    response: string;
    details: string;

    creationDate: string;
    dispositionDate: string;

    surveyTitle: string;
}



export const DEFAULT_DASHBOARD_THUMB: DashboardThumb = {
    count: 0,
    percentage: null,
}
export interface DashboardThumb {
    count: number;
    percentage: string;
}


export const DEFAULT_DASHBOARD_THUMBS_MODEL: DashboardThumbsModel = {
    up: { ...DEFAULT_DASHBOARD_THUMB },
    down: { ...DEFAULT_DASHBOARD_THUMB },
    category: null
}
export interface DashboardThumbsModel {
    up: DashboardThumb;
    down: DashboardThumb;
    category: string;
}


export const DEFAULT_DASHBOARD_RATINGS: DashboardRatingsModel = {
    score: null,
    category: null,
    description: null
}

export interface DashboardRatingsModel {
    score: number;
    category: string;
    description: string;
}



export function dashboardAlertsSortComparator(a: DashboardAlertModel, b: DashboardAlertModel): number {
    if ((a.dispositionable && !a.dispositioned) === (b.dispositionable && !b.dispositioned)) return 0;
    return (a.dispositionable && !a.dispositioned) > (b.dispositionable && !b.dispositioned) ? -1 : 1;
}



export function dashboardAlertsSortSurveyPageKeyComparator(a: DashboardAlertModel, b: DashboardAlertModel): number {
    if ((a.surveyPageKey) === (b.surveyPageKey)) return 0;
    return (a.surveyPageKey) > (b.surveyPageKey) ? -1 : 1;
}
