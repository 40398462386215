import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LibraryComponent } from './library/library.component';
import { GameDetailComponent } from './incentives/game-detail/game-detail.component';
import { IncentivesComponent } from './incentives/incentives.component';
import { LoginComponent } from './login/login.component';
import { MessageSelectRecipientComponent } from './messaging/message-select-recipient/message-select-recipient.component';
import { MessagesComponent } from './messaging/messages.component';
import { NotificationsPageComponent } from './notifications/notifications-page/notifications-page.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './reports/dashboard/dashboard.component';
import { AdvancedAnalyticsComponent } from './reports/advanced-analytics/advanced-analytics.component';
import { TeamDetailComponent } from './incentives/team-detail/team-detail.component';
import { CreateNewCompetitionComponent } from './incentives/create-new-competition/create-new-competition.component';
import { MilestoneComponent } from './incentives/milestones/milestone/milestone.component';
import { OnboardingListComponent } from './onboarding/onboarding-list/onboarding-list.component';
import { GenerateNewRepComponent } from './onboarding/onboarding-list/generate-new-rep/generate-new-rep.component';
import { MilestoneDetailComponent } from './incentives/milestones/milestone-detail/milestone-detail.component';
import { FaqComponent } from './shared/faq/faq.component';
import { CompanyRecordsComponent } from './company-records/company-records/company-records.component';
import { RecordDetailComponent } from './company-records/record-detail/record-detail.component';
import { HomeEmployeeDrillDownComponent } from './rankings/home-employee-drill-down/home-employee-drill-down.component';
import { AdminDirectoryComponent } from './admin/admin-directory/admin-directory.component';
import { OnboardingWizardComponent } from './onboarding/onboarding-wizard/onboarding-wizard.component';
import { EditTournamentComponent } from './incentives/edit-tournament/edit-tournament.component';
import { KeyContactsComponent } from './key-contacts/key-contacts/key-contacts.component';
import { PollComponent } from './shared/components/poll/poll.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { LibrarySectionDetailComponent } from './library/library-section-detail/library-section-detail.component';
import { ConversationComponent } from './messaging/conversation/conversation.component';
import { LinkedVideosPageComponent } from './linked-videos/linked-videos-page/linked-videos-page.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { NewIncentiveComponent } from './incentives/new-incentive/new-incentive.component';
import { LeadersComponent } from './rankings/leaders.component';
import { UserPickerComponent } from './shared/components/user-picker/user-picker.component';
import { AdminToolsComponent } from './tools/tools/admin-tools.component';
import { AdminPageComponent } from './admin/admin-page/admin-page.component';


const routes: Routes = [
  {path: '404', redirectTo: 'login'},
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home/:id',
    component: LeadersComponent
  },
  {
    path: 'home-drill-down/employee',
    component: HomeEmployeeDrillDownComponent
  },

  {
    path: 'notifications',
    component: NotificationsPageComponent
  },
  {
    path: 'key-contacts',
    component: KeyContactsComponent
  },
  {
    path: 'analytics',
    component: DashboardComponent
  },
  
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'onboarding-list',
    component: OnboardingListComponent
  },
  {
    path: 'new-rep',
    component: GenerateNewRepComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'game-detail',
    component: GameDetailComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },

  {
    path: 'incentives',
    component: IncentivesComponent,
  },
  {
    path: 'incentives/edit-tournament',
    component: EditTournamentComponent,
  },
  {
    path: 'incentives/new-incentive',
    component: NewIncentiveComponent,
  },{
    path: 'incentives/user-picker',
    component: UserPickerComponent,
  },
  {
    path: 'milestones',
    component: MilestoneComponent,
  },
  {
    path: 'admin-directory',
    component: AdminDirectoryComponent,
  },
  
  {
    path: 'company-records',
    component: CompanyRecordsComponent,
  },
  {
    path: 'record-detail',
    component: RecordDetailComponent,
  },
  {
    path: 'analytics/:Id',
    component: AdvancedAnalyticsComponent,
  },
  {
    path: 'messages',
    component: MessagesComponent
  },
  {
    path: 'messages/:conversationId',
    component: ConversationComponent
  },

  // {
  //   path: 'messages/:convoId',
  //   component: MessagesComponent
  // },
  {
    path: 'selectRecipient',
    component: MessageSelectRecipientComponent,
  },

  {
    path: 'milestone-detail',
    component: MilestoneDetailComponent,
  },

  {
    path: 'library',
    component: LibraryComponent
  },
  {
    path: 'library-section-detail',
    component: LibrarySectionDetailComponent
  },
  {
    path: 'team-detail',
    component: TeamDetailComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'new-competition',
    component: CreateNewCompetitionComponent
  },
  // {
  //   path: 'join-sunder',
  //   component: OnboardingWizardComponent
  // },

  {
    path: 'new-poll',
    component: PollComponent
  },
  {
    path: 'new-calendar',
    component: CalendarComponent
  },
  {
    path: 'videos',
    component: LinkedVideosPageComponent
  },
  {
    path: 'admin-tools',
    component: AdminToolsComponent
  },
  {
    path: 'admin-page',
    component: AdminPageComponent
  },
  {
    path: 'lead-submission',
    loadChildren: () => import('./leads/external-lead-submission/external-lead-submission.module').then( m => m.LeadSubmissionPageModule)
  },
  {
    path: 'lead-management',
    loadChildren: () => import('./leads/leads-page/leads-page.module').then( m => m.LeadManagementPageModule)
  },
  {
    path: 'ticket-management',
    loadChildren: () => import('./tickets/ticket-management/ticket-management.module').then( m => m.TicketManagementPageModule)
  },

  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'customer-consent',
    loadChildren: () => import('./customer-consent/customer-consent.module').then( m => m.CustomerConsentPageModule)
  },
  {
    path: 'tally-tracker',
    loadChildren: () => import('./leads/tally-tracker/tally-tracker.module').then( m => m.TallyTrackerPageModule)
  },
  {
    path: 'cr-dashboard',
    loadChildren: () => import('./cr-dashboard/cr-dashboard.module').then( m => m.CrDashboardPageModule)
  },

  {
    path: 'join-sunder',
    loadChildren: () => import('./onboarding/onboarding-wizard/onboarding-wizard.module').then( m => m.OnboardingWizardModule)
  },
  {
    path: 'knocking',
    loadChildren: () => import('./knocking/knocking.module').then( m => m.KnockingPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
