<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<shared-my-header>
</shared-my-header>

<enzy-search-header 
  [filtersObjects]="filtersObjects" 
  [iconsInput]="getHeaderIcons()"
  (searchInputEmitter)="handleSearchInputAction($event)"  
  (filterEmitter)="openFilters()" 
  (filterClearedEmitter)="handleClearedFilter($event)" 
  (iconEmitter)="handleIconAction($event)"></enzy-search-header>

<ion-content>


  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content style="color: white;" pullingIcon="" pullingText="Pull Down to Refresh"
      refreshingSpinner="none" refreshingText="">
    </ion-refresher-content>
  </ion-refresher>

  <div>
    <div style="padding: 0px;">
      <main>
        <div *ngFor="let incentive of this.dataSource?.filteredData; let i = index">
          <article>
            <enzy-incentive-card (click)="processIncentiveAction(incentive)" [incentive]="incentive">
            </enzy-incentive-card>
          </article>
        </div>
      </main>

    </div>

    <div style="padding: 20px; max-width: 600px; display: block; margin: auto;" *ngIf='userCanCreateTournament()'>
      <button mat-button class="primary-color" style="width: 100%;" (click)='createTournament()'>
        Create New Tournament
      </button>
    </div>
  </div>


</ion-content>

<app-my-footer>
</app-my-footer>