import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { UserData } from 'src/app/shared/models/dashboard.model';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-basic-filter',
  templateUrl: './basic-filter.component.html',
  styleUrls: ['./basic-filter.component.scss'],
})
export class BasicFilterComponent implements OnInit {


  selectedOptions: string[] = [];

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  formattedOptions: string[] = [];
  originalOptions: string[] = [];

  multipleOptions: boolean = false;


  showClear = false;
  constructor(private employeeService: EmployeeService,
    private messagingService: MessagingService,
    private localStorage: Storage,
    public router: Router,
    public userDetailsService: UserDetailsService,
    private snackBarService: SnackbarService,
    private nav: HeaderService, public dialogRef: MatDialogRef<BasicFilterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {


    if (data.multipleOptions) {
      this.multipleOptions = data.multipleOptions;
      this.selectedOptions = data.currentOption;
    }
    else {
      this.selectedOptions = [data.currentOption];
    }


    this.data.options.forEach(element => {
      //  if(element !== "Sales Rep Name") {
      this.formattedOptions.push("" + element);
      this.originalOptions.push("" + element);
      // }

    });




  }


  ngOnInit() {
    this.initUserDataValues();

  }

  async initUserDataValues() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.userId = await this.userDetailsService.getUserId();

  }




  back() {
    if (this.multipleOptions) {
      if (this.selectedOptions.length !== 0) {
        this.dialogRef.close(this.selectedOptions);
      }
      else {
        this.snackBarService.displaySnackBarMessage("You must select an item.", false);
      }
    }
    else {
      if (this.selectedOptions.length !== 0) {
        this.dialogRef.close(this.selectedOptions[0]);
      }
      else {
        this.snackBarService.displaySnackBarMessage("You must select an item.", false);
      }
    }

  }

  onNgModelChange($event) {
  }


  sessionKey: string;
  userId: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];



  add(event: MatChipInputEvent): void {
    const input = event.input;

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(option: string): void {
    if (this.multipleOptions) {
      const index: number = this.selectedOptions.indexOf(option);
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
        this.formattedOptions = this.originalOptions;

      }
    } else {
      this.selectedOptions = [];
      this.formattedOptions = this.originalOptions;
    }

  }





  ionViewWillLeave() {

  }

  ionViewWillEnter() {
    ;
  }




  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.formattedOptions = this.originalOptions.filter(r => (r).toLowerCase().includes(filterValue.toLowerCase()));
  }


  selectOption(option: string) {
    if (this.multipleOptions) {
      if (!this.selectedOptions.includes(option)) {
        this.selectedOptions.push(option);
        this.formattedOptions = this.originalOptions;
      }
      else {
        this.remove(option);
      }
    } else {
      this.selectedOptions = [option];
      this.formattedOptions = this.originalOptions;
    }

  }


}
