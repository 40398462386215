import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HomeEmployeeDrillDownComponent } from 'src/app/rankings/home-employee-drill-down/home-employee-drill-down.component';
import { DataReportMenu, GroupByOption, ShowOption, TextFilter } from '../../models/dashboard-report-list.model';
import { DashboardData, UserData } from '../../models/dashboard.model';
import { Employee } from '../../models/employees.model';
import { ReportCell, ReportCellList } from '../../models/report-cell-list.model';
import { ReportDataPoint, ReportsService } from '../../services/reports.service';
import { BusySpinnerComponent } from '../busy-spinner/busy-spinner.component';



@Component({
  selector: 'app-drill-down-table',
  templateUrl: './drill-down-table.component.html',
  styleUrls: ['./drill-down-table.component.scss'],
})
export class DrillDownTableComponent implements OnInit {
  savedReportMenu: DataReportMenu;
  savedReportCellList: ReportCellList;

  @Input() set reportCellList(newReportCellList: ReportCellList) {
    this.savedReportCellList = newReportCellList;
    this.updateDataSource(newReportCellList.list);
    this.setSelectAllIfAllAreSelected();
  }

  @Input() set reportMenu(newReportMenu: DataReportMenu) {
    this.savedReportMenu = newReportMenu;
    if (this.getXHeader() === 'Sales Rep' && this.savedReportCellList !== undefined) {
      this.updateDataSource(this.savedReportCellList.list)

    }
    this.setSelectAllIfAllAreSelected();
  }


  @Input()
  selectUserMode: boolean = false;


  @Input()
  report: DashboardData;

  displayedColumns: string[] = ['date'];
  dataSource: MatTableDataSource<ReportCell>;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(public dialog: MatDialog,
    private reportsService: ReportsService,
    public router: Router) {
  }

  ngOnInit() {
    // this.setUpDataSource(this.data);
  }


  setUpDataSource(data: ReportCell[]) {
    this.dataSource = new MatTableDataSource(data);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updateDataSource(data: ReportCell[]) {
    if (this.dataSource) {
      this.dataSource.data = data
    } else {
      this.setUpDataSource(data)
    }

  }

  select(row: any) {
    row.isSelected = !row.isSelected;
  }

  getDisplayColumns(): string[] {
    let output: string[] = ['date'];
    let headers: string[] = this.getSelectedHeaders();
    return output.concat(headers);
  }

  getSelectedHeaders(): string[] {
    if (!this.savedReportCellList?.menu) return [];

    return this.savedReportCellList?.columnHeaders?.slice(1);
  }

  getXFromCell(cell: ReportCell): string {
    if (cell.cellList.length <= 0) { return '' }
    const employee: Employee = cell.cellList[0].employee
    const value: string = cell.cellList[0].value;
    return employee ? employee.fullName : value
  }

  getValueForRowCol(row: ReportCell, columnIndex: number): string {
    if (row.cellList.length <= 0) { return '' }
    if (row.cellList.length <= columnIndex) { return '' }
    return row.cellList[columnIndex].value;
  }

  xValueIsEmployee(row: ReportCell): boolean {
    return row.cellList[0].employee?.userDto?.id !== undefined;
  }

  canMessage(): boolean {
    if (this.savedReportCellList.list.length <= 0) { return false }
    const cell: ReportCell = this.savedReportCellList.list[0]
    return this.xValueIsEmployee(cell);

  }

  selectAll: boolean = false;

  selectAllClicked(): void {
    this.savedReportCellList.list.forEach(cell => cell.isSelected = this.selectAll)
  }

  setSelectAllIfAllAreSelected(): void {
    for (let cell of this.savedReportCellList?.list) {
      if (cell.isSelected === false) return;
    }
    this.selectAll = true;
  }

  showDetail(name: string): void {

    this.setNewFilterOnReportMenu(this.savedReportMenu, name);

  }

  getXHeader(): string {
    if (!this.savedReportMenu || !this.savedReportMenu.stratList) {
      return ''
    }
    let groupByOption: GroupByOption = this.savedReportMenu.stratList.find(x => x.field === this.savedReportMenu.selectedStratField)
    if (groupByOption !== undefined) {
      return groupByOption.text;
    }
    else {
      return '';
    }
  }

  private setNewFilterOnReportMenu(reportMenu: DataReportMenu, filterValue: string): void {

    // Find the filter
    const index: number = reportMenu.filterTextList.findIndex((value: TextFilter, index: number) => { return value.text === this.getXHeader() })

    if (index >= 0) {
      reportMenu.filterTextList[index].includeValue = filterValue;

      reportMenu.selectedStratField = reportMenu.stratList[1].field;

      this.loadReportCellListByReportMenu(reportMenu);
    }

  }

  selectRow(cell: ReportCell): void {
  }

  private loadReportCellListByReportMenu(reportMenu: DataReportMenu) {

    this.reportsService.getDataReportList(reportMenu).then((reportCellList: ReportCellList) => {
      this.reportCellList = reportCellList

    })
  }



  spinnerRef: any;

  viewEmployeeDetails(cell: ReportCell): void {
    if (!cell.cellList[0].employee) { return }

    this.spinnerRef = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })


    if (cell.cellList[0].employee) {
      cell.cellList[0].employee.userDto.emailAddress = cell.cellList[0].employeeEmailAddress;

      let ref = this.dialog.open(HomeEmployeeDrillDownComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',
        data: { employee: cell.cellList[0].employee, selectedTimePeriodOptions: null, selectedDataSetOption: null },
        panelClass: 'transparent',
      })

      ref.afterClosed().subscribe(res => {
        this.spinnerRef.close();
      })

      return;
    }
  }

  getWidth() {
    if (this.getSelectedHeaders().length > 4) {
      return 2000;
    }
    else if (this.getSelectedHeaders().length > 2 && this.getSelectedHeaders().length <= 4) {
      return 1200;
    }

    else {
      return 600
    }
  }

}
