<div class="app-background-color app-text-color" style="padding: 20px;">

  <h1>Create Enzy Account </h1>
  <p>{{data.applicant.firstName}} {{data.applicant.lastName}}</p>

  <p class="opaque-text">In order to create an Enzy account please input the email <br>associated with this user.</p>


  <mat-form-field style="width: 100%;" floatLabel="never">
    <!-- <mat-label>Company Email Address</mat-label>
    <textarea matInput [(ngModel)]="emailAddress"></textarea> -->

    <input matInput placeholder="first.last@companyemail.com" [(ngModel)]="emailAddress">

  </mat-form-field>

  <button mat-button style="width: 100%;" (click)="submit()">Send</button>


</div>