import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ENZY_BASE_URL, REST_BASE_URL } from "../models/constants";
import { UserDetailsService } from "./user-details.service";
import { Storage } from '@ionic/storage';


// const { Network } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, public userDetailsService: UserDetailsService, private localStorage: Storage) {
    this.initService();
  }



  async initService() {
    // HAS_ONBOARDING_RIGHTS
    const res = await this.localStorage.get("HAS_ONBOARDING_RIGHTS");
    this.hasOnboardingRights = res;

  }

  userHasLoggedIn: boolean = false;
  appOpenedFromNotification: boolean = false;
  notificationConvoId: number;
  hasOnboardingRights: boolean = false;


  public async resetPassword(emailAddress: string): Promise<any> {
    let url: string = REST_BASE_URL + '/passwordResetEmail/enzyEndpointKey' + "?emailAddress=" + emailAddress;
    return this.http.get<any[]>(url).toPromise();
  }


  public async getOnboardingRights(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/hasOnboardingRights/' + sessionKey;
    return this.http.get<any[]>(url).toPromise();
  }



  public async checkOnboardingRights() {
    let result = await this.getOnboardingRights();
    if (result.hasOnboardingRights) {
      this.hasOnboardingRights = true;
    }

    setTimeout(() => {
      this.localStorage.set("HAS_ONBOARDING_RIGHTS", result.hasOnboardingRights);
    }, 1000);

  }




  public async attemptLogin(email: string, password: string): Promise<any> {
    let loginUrl: string = ENZY_BASE_URL + '/authentication';
    let params: any = {
      emailAddress: email,
      password: password
    };

    return this.http.get(loginUrl, { params }).toPromise();
  }


  public async checkIfCurrentSessionKeyIsValid(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let checkLoginUrl: string = REST_BASE_URL + '/authentication/checkLogin/' + sessionKey;
    return this.http.get(checkLoginUrl).toPromise();
  }

}