import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { GiphyService } from './giphy.service';

@Component({
  selector: 'app-giphy',
  templateUrl: './giphy.component.html',
  styleUrls: ['./giphy.component.scss'],
})
export class GiphyComponent {

 
  searchTerm: string = '';
  searchWasPressed: boolean = false;

  @HostListener('window:scroll')
  onScroll() {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      this.giphyService.next();
    }
  }

  constructor(public giphyService: GiphyService, private _bottomSheetRef: MatBottomSheetRef<GiphyComponent>) {

    this.giphyService.search("gif");
  }

  search() {
    this.giphyService.search(this.searchTerm);
    this.searchWasPressed = true;

  }

  grabGif(src: string){
    this._bottomSheetRef.dismiss(src);
  }

  loadMore(){
    this.giphyService.next()
  }


  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  searchSend() {

    this.search();

  }

}
