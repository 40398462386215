<div class="app-background-color" style="width: 450px; display: block; margin: auto;">
  <div style="height: 10px;"></div>
  <button (click)="fileInput.click()" *ngIf="this.video.thumbnailUrl"
    style="width: 200px; height: 100px; display: block; margin: auto; background-image: url({{this.video.thumbnailUrl}}); background-size: cover; object-fit: cover; background-repeat: no-repeat;"
    mat-raised-button>
    <span class="material-icons" style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
      photo_camera
    </span>
  </button>



  <button (click)="fileInput.click()" *ngIf="!this.video.thumbnailUrl && !this.video.thumbnailFile"
    style="width: 200px; height: 100px; display: block; margin: auto; background-image: url('../../../assets/placeholder-image.png'); object-fit: cover; background-position: center;  background-size: cover; background-repeat: no-repeat;"
    mat-raised-button>
    <span style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
      Add Thumbnail
    </span>
  </button>



  <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="image">

  <div style="padding: 10px;">


    <ion-col size="12" style="padding: 0; margin: 0;">
      <ion-row style="padding: 0; margin: 0;">
        <mat-form-field style="width: 100%;">
          <input [(ngModel)]="this.video.title" class="non-bolded-placeholder" matInput placeholder="Resource Title"
            style="width: 100%;">
        </mat-form-field>
      </ion-row>


      <ion-row style="padding: 0; margin: 0;">
        <mat-form-field style="width: 100%;">
          <input [(ngModel)]="this.video.trainerName" class="non-bolded-placeholder" matInput placeholder="Author"
            style="width: 100%;">
        </mat-form-field>
      </ion-row>


      <ion-row>
        <mat-form-field style="width: 100%;">
          <mat-label>Section</mat-label>
          <mat-select [(ngModel)]="this.video.section">
            <mat-option *ngFor="let s of this.sections" [value]="s.sectionTitle">
              {{s.sectionTitle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-row>



      <ion-row *ngIf="this.shouldShowSeries"> 
        <mat-form-field style="width: 100%;">
          <mat-label>Series</mat-label>
          <mat-select [(ngModel)]="this.video.category">
            <mat-option *ngFor="let s of this.series" [value]="s.title">
              {{s.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-row>


      <ion-row style="padding: 0; margin: 0;">

        <mat-form-field style="width: 100%;">
          <input [(ngModel)]="this.video.videoUrl" class="non-bolded-placeholder" matInput placeholder="Url"
            style="width: 100%;">
        </mat-form-field>
      </ion-row>
    </ion-col>

  


    <mat-divider></mat-divider>
    <ion-row>
      <ion-col size="6">
        <button mat-button style="width: 100%" class="opaque-text" (click)="cancel()">Cancel</button>
      </ion-col>

      <ion-col size="6">
        <button mat-button style="width: 100%" class="primary-color" (click)="create()">Done</button>
      </ion-col>
    </ion-row>
  </div>
</div>