<ion-grid style="color: white; padding: 0; margin: 0" *ngIf="this.game" class="custom-grid">
  <ion-row style="padding: 0; margin: 0">
    <ion-col size="6" style="padding: 0; margin: 0">

      <ion-grid style="padding: 0; margin: 0; " class="section-div-dark" [ngClass]="{'winner': (isWinner(1))}">
        <ion-row style="padding: 0; margin: 0">
          <ion-col size="4"
            style="padding: 0; margin: 0; border-top-left-radius: 10px;   border-bottom-left-radius: 10px;background-image: url('../../../assets/temecula.jpg'); background-size:cover;"
            [ngStyle]="{'background-image': 'url(' + this.getTeam1Url() + ')'}"
            (click)="showTeam1Participants()">

          </ion-col>

          <ion-col size="9" style="padding: 5px 5px 5px 10px">
            <ion-row style="font-weight: 100; font-size: 14px;">
              {{this.game.team1Name}}
            </ion-row>

            <ion-row>
              <span style="font-size: 30px; white-space: nowrap; text-align: right;"> {{getTeam1Score() | number : '1.0-1' }} </span>

            </ion-row>
          </ion-col>


        </ion-row>
      </ion-grid>


    </ion-col>
  
    
    <ion-col size="1">
      <p style="font-size: 12px; text-align: center; color:rgba(255,255,255,0.4); width: 100%; line-height: 60px; margin: 0;"> vs</p>
    </ion-col>
    
    <ion-col size="6" style="padding: 0; margin: 0">

     
      <ion-grid style="padding: 0; margin: 0; " class="section-div-dark" [ngClass]="{'winner': (isWinner(2))}">
        <ion-row style="padding: 0; margin: 0">
          

          <ion-col size="9" style="padding: 5px 10px 5px 5px">
            <ion-row style="font-weight: 100; font-size: 14px;">
              <span style=" text-align: right; width: 100%;"> {{this.game.team2Name}} </span>
            </ion-row>

            <ion-row>
              <span style="font-size: 30px; white-space: nowrap; text-align: right; width: 100%;"> {{getTeam2Score() | number : '1.0-1' }} </span>

            </ion-row>
          </ion-col>

          <ion-col size="4"
            style="padding: 0; margin: 0; border-top-right-radius: 10px;   border-bottom-right-radius: 10px;background-image: url('../../../assets/temecula.jpg'); background-size:cover;"
            [ngStyle]="{'background-image': 'url(' + this.getTeam2Url() + ')'}"
            (click)="showTeam2Participants()">

          </ion-col>


        </ion-row>
      </ion-grid>





    </ion-col>
  </ion-row>
</ion-grid>