import { EnzyAddress, GoogleAddressResult } from "src/app/knocking/knocking.service";

export function parseAddressComponentsIntoLead(address: GoogleAddressResult): EnzyAddress {
    if(!address) return;
    const city: string = address.address_components?.find(c => c.types.includes("locality"))?.short_name;
    const state: string = address.address_components?.find(c => c.types.includes("administrative_area_level_1"))?.short_name;
    const zip: string = address.address_components?.find(c => c.types.includes("postal_code"))?.short_name;
    const street_number: string = address.address_components?.find(c => c.types.includes("street_number"))?.short_name;
    const route: string = address.address_components?.find(c => c.types.includes("route"))?.short_name;
    const fullAddress: string = address.formatted_address;
    return  {
        fullAddress,
        city,
        state,
        zip,
        streetAddress: street_number + " " + route,
    }
  }