import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApplicantData, applicantHasBackgroundCheck, applicantHasBankInfo, applicantHasDocuments, applicantHasRepAgreement, applicantHasSunderEmail, applicantHasw9, applicantIdBadgePrinted, applicantIsScar } from 'src/app/onboarding/onboarding-wizard/onboarding-store.service';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
// import { Employee } from 'src/app/shared/models/employees.model';
import { AdminDirectoryService } from 'src/app/shared/services/admin-directory.service';

import { Status } from 'src/app/shared/models/admin-directory.model';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { ApplicantComponent } from '../applicant/applicant.component';
import { ApplicantsStoreService } from 'src/app/shared/services/admin-directory-store.service';
import { DateFormatService } from 'src/app/shared/services/date-format.service';



@Component({
  selector: 'app-admin-directory',
  templateUrl: './admin-directory.component.html',
  styleUrls: ['./admin-directory.component.scss'],
})
export class AdminDirectoryComponent implements OnInit, AfterViewInit {
  step = 0;

  filtersConfig: AdminDirectoryFilterConfig = {
    hasSunderEmail: false,
    emptyApplicant: false,
    documents: false,
    needsDocuments: false,
    repAgreement: false,
    needsRepAgreement: false,
    bankInfo: false,
    needsBankInfo: false,
    w9: false,
    needsW9: false,
    backgroundCheck: false,
    needsBackgroundCheck: false,
    scar: false,
    idBadgePrinted: false,
    idBadgeNeeded: false,
  }

  selectedIndex: number = 0;

  dataSource_all: MatTableDataSource<ApplicantData>;
  displayedColumnsApplicants: string[] = ['imageUrl', 'fullName', 'status', 'dateSubmitted', 'needsDocuments'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginatorApplicants: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  // applicants: ApplicantData[] = [];

  usersMode: string = "Active";
  applicantsMode: string = "Submitted";
  status: Status[] = [];

  constructor(public applicantsStoreService: ApplicantsStoreService, private dialog: MatDialog, public router: Router, public adminDirectoryService: AdminDirectoryService, public dateFormatService: DateFormatService) { }


  ngAfterViewInit() {
    if (!this.dataSource_all) { return }
    this.dataSource_all.sort = this.sort;
    this.dataSource_all.paginator = this.paginatorApplicants;
  }


  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginatorApplicants = mp;
    if (!this.dataSource_all) { return }
    this.dataSource_all.paginator = this.paginator;
  }

  async ngOnInit() {
    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    this.applicantsStoreService.applicants.subscribe((applicants: ApplicantData[]) => {
      this.setDataSource()
    });

    this.dialog.closeAll();
  }


  setDataSource() {
    setTimeout(() => {
      this.dataSource_all = new MatTableDataSource(this.applicantsStoreService.getApplicants().filter(person => this.employeeShouldBeShown(person)));
      this.dataSource_all.sortingDataAccessor = (item, header) => {
        switch (header) {
          case 'dateSubmitted': return item.insertDateTime.toString();
          case 'fullName': return item.firstName ? item.firstName.toLowerCase() : item.lastName ? item.lastName.toLowerCase() : item.emailAddress;
          case 'status': return item.status;
        }
      }
      this.dataSource_all.paginator = this.paginatorApplicants;
      this.dataSource_all.sort = this.sort;
      this.applicantsStoreService.cacheApplicantsList();
    });
  }



  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);

    this.dataSource_all.filter = this.searchValue.trim().toLowerCase();
  }

  clearSearch() {
    this.searchOn = false;
    this.searchValue = "";
    this.setDataSource();

  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";

  applyFilterApplicants(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_all.filter = filterValue.toLowerCase();

    if (this.dataSource_all.paginator) {
      this.dataSource_all.paginator.firstPage();
    }
  }

  changeApplicantsMode(option: string) {
    this.clearSearch();
    this.applicantsMode = option;
    this.setDataSource()
  }


  changeUsersMode(option: string) {
    this.usersMode = option;
  }

  onTabChanged($event) {
    this.selectedIndex = $event.index;
  }

  selectRow(selectedApplicant: ApplicantData) {
    // console.log(selectedApplicant)

    let ref = this.dialog.open(ApplicantComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        applicant: selectedApplicant,
      }
    })

    ref.afterClosed().subscribe(async updatedApplicant => {
      if (!updatedApplicant) { return }
      selectedApplicant = updatedApplicant;
      await this.applicantsStoreService.cacheApplicantsList();
      this.setDataSource();
    })
  }




  getRowImage(row: ApplicantData) {
    if (row && row.userImageUrl) { return row.userImageUrl }
    else { return "../../../assets/headshots/default-avatar-user.jpg" }
  }


  getInitialsOnRow(row: any) {
    let initial: string = "";
    if (row.preferredFirstName) { initial += row.preferredFirstName[0] }
    if (!row.preferredFirstName && row.firstName) { initial += row.firstName[0] }
    if (row.lastName) { initial += row.lastName[0] }
    return initial.toUpperCase();
  }

  getNameOnRow(row: ApplicantData) {
    if (!row) { return }
    if (row.fullname) { return row.fullname }
    else if (row.firstName && row.lastName) { return row.firstName + " " + row.lastName }
    else { return row.emailAddress }

  }

  employeeShouldBeShown(row: ApplicantData): boolean {
    if (this.applicantsMode === "All") {
      return true;
    }

    if (row.status !== this.applicantsMode) { return false }
    if (this.specialCheckForLegacyStatus(row)) { return false }

    if (this.noFiltersApplied()) return true;
    return (this.applicantHasDocuments(row) && this.filtersConfig?.documents) ||
      (!this.applicantHasDocuments(row) && this.filtersConfig?.needsDocuments) ||
      (this.applicantHasRepAgreement(row) && this.filtersConfig?.repAgreement) ||
      (!this.applicantHasRepAgreement(row) && this.filtersConfig?.needsRepAgreement) ||
      (this.applicantHasBankInfo(row) && this.filtersConfig?.bankInfo) ||
      (!this.applicantHasBankInfo(row) && this.filtersConfig?.needsBankInfo) ||
      (this.applicantHasw9(row) && this.filtersConfig?.w9) ||
      (!this.applicantHasw9(row) && this.filtersConfig?.needsW9) ||
      (this.applicantHasBackgroundCheck(row) && this.filtersConfig?.backgroundCheck) ||
      (!this.applicantHasBackgroundCheck(row) && this.filtersConfig?.needsBackgroundCheck) ||
      (this.applicantHasNoneCompleted(row) && this.filtersConfig?.emptyApplicant) ||
      (this.applicantHasSunderEmail(row) && this.filtersConfig?.hasSunderEmail) ||
      (this.applicantIsScar(row) && this.filtersConfig?.scar) ||
      (this.applicantBadgePrinted(row) && this.filtersConfig?.idBadgePrinted) ||
      (this.applicantBadgeNeeded(row) && this.filtersConfig?.idBadgeNeeded)
  }

  specialCheckForLegacyStatus(applicant: ApplicantData) {
    return this.applicantsMode === "Legacy" && (applicant.status === "Legacy - Probation" || applicant.status === "Legacy - Deactivated")
  }


  noFiltersApplied(): boolean {
    return Object.keys(this.filtersConfig).filter(key => this.filtersConfig[key]).length === 0;
  }

  getFilterKeys(): string[] {
    return Object.keys(this.filtersConfig);
  }

  getFilterKeyTitle(key: string): string {
    return camelCaseToSentenceCase(key);
  }

  getFilterValue(key: string): boolean {
    return this.filtersConfig[key];
  }

  toggleFilter(key: string): void {
    this.filtersConfig[key] = !this.filtersConfig[key];
    this.setDataSource();
  }

  getStatusOnRow(row: ApplicantData) {
    return row.status ? row.status : "Inactive"
  }

  getDateOnRow(row: ApplicantData) {
    return row.insertDateTime ? this.dateFormatService.formatDateFromString(row.insertDateTime) : "-"
  }

  applicantHasSunderEmail(row: ApplicantData): boolean {
    return applicantHasSunderEmail(row);
  }

  applicantHasDocuments(row: ApplicantData): boolean {
    return applicantHasDocuments(row);
  }

  applicantHasRepAgreement(row: ApplicantData): boolean {
    return applicantHasRepAgreement(row);
  }
  applicantHasBankInfo(row: ApplicantData): boolean {
    return applicantHasBankInfo(row);
  }

  applicantHasw9(row: ApplicantData): boolean {
    return applicantHasw9(row);
  }
  applicantHasBackgroundCheck(row: ApplicantData): boolean {
    return applicantHasBackgroundCheck(row);
  }

  applicantHasNoneCompleted(row: ApplicantData): boolean {
    return !this.applicantHasDocuments(row) &&
      !this.applicantHasRepAgreement(row) &&
      !this.applicantHasBankInfo(row) &&
      !this.applicantHasw9(row) &&
      !this.applicantHasBackgroundCheck(row)
  }

  applicantIsScar(row: ApplicantData): boolean {
    return applicantIsScar(row);
  }

  applicantBadgePrinted(row: ApplicantData): boolean {
    return applicantIdBadgePrinted(row);
  }

  applicantBadgeNeeded(row: ApplicantData): boolean {
    return !applicantIdBadgePrinted(row);
  }


  getStatusCount(status: string) {
    if (status === "All") {
      return this.applicantsStoreService.getApplicants().length
    }
    else {
      return this.applicantsStoreService.getApplicants().filter(a => a.status === status).length

    }
  }


}

export interface AdminDirectoryFilterConfig {
  hasSunderEmail: boolean,

  emptyApplicant: boolean,
  documents: boolean,
  needsDocuments: boolean,
  repAgreement: boolean,
  needsRepAgreement: boolean,
  bankInfo: boolean,
  needsBankInfo: boolean,
  w9: boolean,
  needsW9: boolean,
  backgroundCheck: boolean,
  needsBackgroundCheck: boolean,
  scar: boolean,
  idBadgePrinted: boolean,
  idBadgeNeeded: boolean,
}
