import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IonRouterOutlet } from '@ionic/angular';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { AddSectionComponent } from '../add-section/add-section.component';
import { Section } from '../library.component';

@Component({
  selector: 'app-genre-section',
  templateUrl: './genre-section.component.html',
  styleUrls: ['./genre-section.component.scss'],
})
export class GenreSectionComponent implements OnInit {
  shouldShowAdminButtons: boolean = false;
  @Input() size: string;

  section: Section;
  @Input() set sectionInput(newSection:  Section) {
    this.section = newSection;
    this.getSeries();

  }


  _seriesImages: Map<string, string>;
  @Input() set seriesImages(newSeriesImages:  Map<string, string>) {
    this._seriesImages = newSeriesImages;
  }



  constructor(public userDetailsService: UserDetailsService, public dialog: MatDialog, public router: Router, public routerOutlet: IonRouterOutlet,  private sanitizer: DomSanitizer,private zone: NgZone    ) { 
    this.userDetailsService.getUserId().then(res=> {
      if (res.toString() === "19") {
        this.shouldShowAdminButtons = true;
      }
    })
  }

  ngOnInit() {

  }
  

  isXL() {
    return this.size === "xl";
  }

  getImageForSeries(series:string): SafeUrl {
    let url: string = this._seriesImages.get(series)
    if (!url) url = 'https://enzy-bucket1.s3.us-east-2.amazonaws.com/conversation/2050EF94867E-A207-4315-920A-0247E881B2C3.gif';
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }


  goToLink(url: string){
    window.open(url, "_blank");
  }

  series: any;
  getSeries(){
    this.series =  Array.from(this.section.videosMap.keys());

  }


  navigateToSectionDetails(section: string) {
    this.routerOutlet.swipeGesture = true;
    let videos = this.section.videosMap.get(section);
    // let sectionImage = this.getImageForSection(section);

    let url: string = this._seriesImages.get(section)

    this.router.navigateByUrl('/library-section-detail', { state: { videos: videos, sectionImage: url } });

  }

  edit() {
    let dialogRef = this.dialog.open(AddSectionComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        section: this.section,
        seriesImages: this._seriesImages
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.section = res;

        this.zone.run(()=> {
          console.log("redraw")
        });
      }
    })
  }

}
