import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss'],
})
export class InvitationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InvitationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  resend() {
    this.dialogRef.close("resend")
  }

  edit() {
    this.dialogRef.close("edit")

  }

}

