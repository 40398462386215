<ion-content>
  <div style="padding: 20px; max-width: 600px; display: block; margin: auto;">
  <div class=" mat-elevation-z3 " >
    <div style=" margin-top:0px" >
      <mat-accordion class="cool-box-large">
        <mat-expansion-panel [expanded]="false" style="padding: 0px; margin: 0px;"
          class="mat-elevation-z0 cool-box-large">
          <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'"
            style="padding:0px; margin: 0px; padding-right: 20px; padding-left: 10px; ">

            <h4 style="font-size: 14px; padding-left: 5px; padding-bottom: 5px;" mat-line>
              Question 1
            </h4>
          </mat-expansion-panel-header>
          <div style="width: 100%; margin: 0; padding: 0 15px 0 15px;">

            <p style="text-align: left;">Answer</p>

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div style="height: 10px"></div>

  <div class=" mat-elevation-z3 " >
    <div style=" margin-top:0px" >
      <mat-accordion class="cool-box-large">
        <mat-expansion-panel [expanded]="false" style="padding: 0px; margin: 0px;"
          class="mat-elevation-z0 cool-box-large">
          <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'"
            style="padding:0px; margin: 0px; padding-right: 20px; padding-left: 10px; ">

            <h4 style="font-size: 14px; padding-left: 5px; padding-bottom: 5px;" mat-line>
              Question 2
            </h4>
          </mat-expansion-panel-header>
          <div style="width: 100%; margin: 0; padding: 0 15px 0 15px;">

            <p style="text-align: left;">Answer</p>

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  
</div>

</ion-content>