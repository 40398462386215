<div  *ngIf="this.report.reportCellList && this.report.reportCellList.list" matRipple  class="example-ripple-container" [matRippleUnbounded]="false"
  [ngStyle]="{'background-image': 'url(' + this.getBackgroundImage() + ')'}" (click)="openRecordCard()"
  style="text-align: left; background-color: #383838; background-size:cover;  padding: 0; margin: 0; height: 100%; border-radius: 10px; background-position: center; background-size: cover; ">

  <div style="height: 100%; width: 100%;    border-radius: 10px;">

    <div class="gradient-reverse" style="height: 90px; overflow: hidden; padding: 10px; width: 100%; padding-left: 20px; padding-right: 20px">
      <p  style="padding: 0; margin: 0;  font-size: 14px; text-align: left; width: 100%; white-space: normal; line-height: 20px;  color: white">{{getReportName()}} </p>
    </div>


    <div style="height: 30px"></div>

    <div class="gradient" style="padding-left: 20px; padding-right: 20px">


      <ion-row style="padding: 0; margin: 0; width: 100%; height: 40px">
        <span class="text-shadow"
          style="font-size: 50px; color: white; font-weight: 900;">{{getFirstPlaceValue()}}</span>
      </ion-row>
      <ion-row style="padding: 0; margin: 0; width: 100%;height: 50px ">
        <span class="text-shadow truncated" style="font-size: 14px; color: white; font-weight: 900;">{{getFirstPlace()}}</span>
      </ion-row>
    </div>
  </div>

</div>