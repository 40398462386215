import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DashboardAlertModel } from '../../cr-dashboard.service';
import { interval, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators'

@Component({
  selector: 'enzy-survey-response-pop-up',
  templateUrl: './survey-response-pop-up.component.html',
  styleUrls: ['./survey-response-pop-up.component.scss'],
})
export class SurveyResponsePopUpComponent implements OnInit {

  progressbarValue = 100;
  curSec: number = 0;

  image: string;

  constructor(public dialogRef: MatDialogRef<SurveyResponsePopUpComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,) {


    setTimeout(() => {
      this.dialogRef.close();
    },
      5000);


    this.startTimer(3);

    this.image = this.getImage();
  }


  startTimer(seconds: number) {
    const timer$ = interval(1000);

    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec * 100 / seconds;
      this.curSec = sec;

      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }



  ngOnInit() { }




  getImage() {
    if (this.data?.response?.numericResponse >= 4) {
      const rndInt = randomIntFromInterval(1, 5)
      return "../../../../assets/clap"+rndInt+".gif"
    }
    else if (this.data?.response?.numericResponse <= 3) {
      const rndInt = randomIntFromInterval(1, 5)
      return "../../../../assets/yikes"+rndInt+".gif"    }
  }

}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}