import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-more-information-modal',
  templateUrl: './more-information-modal.component.html',
  styleUrls: ['./more-information-modal.component.scss'],
})
export class MoreInformationModalComponent implements OnInit {
  reason: string;
  
constructor(public dialogRef: MatDialogRef<MoreInformationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {}

  submit() {
    if (this.reason) {
      this.dialogRef.close(this.reason);
    }
  }

}
