import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { DashboardData, UserData } from 'src/app/shared/models/dashboard.model';
import { Employee } from 'src/app/shared/models/employees.model';
import { ReportCell, ReportCellDataPoint, ReportCellList } from 'src/app/shared/models/report-cell-list.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ReportDataPoint, ReportsService } from 'src/app/shared/services/reports.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';



@Component({
  selector: 'app-expandable-leaderboard',
  templateUrl: './expandable-leaderboard.component.html',
  styleUrls: ['./expandable-leaderboard.component.scss'],
})
export class ExpandableLeaderboardComponent implements OnInit {

  @Input()
  report: DashboardData;


  @Input()
  groupByString: string;

  @Input()
  showString: string;

  paginationTotalLength: number = 0;
  highLightedRowName: string = "";
  paginationSize: number = 50;
  paginationData: PageEvent;

  pageEvent: PageEvent;


  @Input()
  isDashboard: boolean;

  @Input() set reportCellList(newReportCellList: ReportCellList) {
    this.parseData(newReportCellList);
  }


  @Input() set dateSpanString(value: string) {

  }

  @Input()
  chartDataSource: string;

  sessionKey: string;

  listOfEntries: ReportDataPoint[] = [];


  specialColoring: boolean = false;

  constructor(public userDetailsService: UserDetailsService, public dialog: MatDialog, private snackBarService: SnackbarService, public dashboardService: DashboardService, public router: Router, private bottomSheet: MatBottomSheet, private zone: NgZone, private localStorage: Storage, public reportsService: ReportsService) {
    this.dateSpanString = "Year To Date"; //TODO: change this to be consistent with Input statements
  }

  getImageUrlByRow(row: ReportCell): string {
    return row.cellList[0].imageUrl
  }
  
  ngOnInit() {
 
    this.initUserDataValues();    

    if (this.router.url === '/employee-analytics') {
      this.specialColoring = false;
    }
    else {
      this.specialColoring = true;
    }
  }




  async initUserDataValues() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.setPaginationData();

  }


  parseData(reportCellList: ReportCellList) {
    let output: any[] = [];
    if (reportCellList.list) {

      output = reportCellList.list.map((cell: ReportCell) => {
        let format = cell.cellList[cell.cellList.length - 1].value;
        if (format) { format = format }

        return {
          x: (cell.cellList[0].employee) ? (cell.cellList[0].employee.fullName) : cell.cellList[0].value,
          y: format,
          trend: cell.trend,
          rank: cell.rank
        }
      })
    }



    this.listOfEntries = output;


    this.listOfEntries.sort(function (a, b) {
      return +a.x - +b.x;
    });

    this.setAllUrls(reportCellList);


  }

  imageUrl0: string = '/assets/headshots/default_avatar.png';
  imageUrl1: string = '/assets/headshots/default_avatar.png';
  imageUrl2: string = '/assets/headshots/default_avatar.png';

  setAllUrls(reportCellList: ReportCellList) {
    this.imageUrl0 = this.getImageUrlAtIndex(0, reportCellList);
    this.imageUrl1 = this.getImageUrlAtIndex(1, reportCellList);
    this.imageUrl2 = this.getImageUrlAtIndex(2, reportCellList);
  }

  getEmployeeByRow(row: ReportCell): Employee {
    return row.cellList[0].employee
  }

  getInitialsForNonEmployeeRow(row: ReportCell): string {
    const maxIntialsLength: number = (row.cellList[0].value.length > 2) ? 2 : row.cellList[0].value.length;
    return row.cellList[0].value.slice(0, maxIntialsLength);
  }


  public getImageUrlAtIndex(index: number, reportCellList: ReportCellList): string {
    if (!reportCellList || !reportCellList.list) {
      return '/assets/headshots/default_avatar.png'
    }
    let cell = reportCellList.list[index]
    if (cell && cell.cellList[0].employee) {
      return cell.cellList[0].employee.userDto.imageUrlOrDefault;
    }
    return '/assets/headshots/default_avatar.png'
  }

  getNameAtIndex(index: number): string {
    if (this.listOfEntries[index]) {
      return this.listOfEntries[index].x

    } else {
      return 'Jon Smith'
    }
  }

  getCountAtIndex(index: number): number {
    if (this.listOfEntries[index]) {
      return +this.listOfEntries[index].y

    } else {
      return 0
    }

  }

  getSumAtIndex(index: number): number {
    if (this.listOfEntries[index]) {
      return +this.listOfEntries[index].y

    } else {
      return 0
    }


  }

  getListFourThroughTen() {

  }



  showDetail(user: any): void {

  }


  getCellValue(cell: ReportCell): string {
    let v = cell.cellList[0]
    if (v.employee && v.employee !== undefined) {
      let output = v.employee.fullName;
      return (output) ? output : v.value;
    }
    return v.value;
  }

  getCellRightColumn(cell: ReportCell): string[] {
    const rightSideData: ReportCellDataPoint[] = cell?.cellList?.slice(1);    
    return rightSideData.map(col => col.value);
  }


  setPaginationData() {
    // Extract info
    if (this.report === undefined) { return }
    if (!this.report.reportCellList.listNav) { return }
    const paginationDescription: string = this.report.reportCellList.listNav.showing;
    // FORMATED EXAMPLE "<offset>-<offset + pageSize> of <totalLength>"
    const tempArray: string[] = paginationDescription.split("of ");
    this.paginationTotalLength = +(tempArray[tempArray.length - 1].replace(/\,/g, ""));

  }

  public changePagination(pageEvent: PageEvent) {
    this.paginationData = pageEvent;
    // Pass report menu and offset to endpoint to reload list data
    this.loadReportCellListByReportMenu(this.report);
  }


  private loadReportCellListByReportMenu(report: DashboardData) {
    this.setReportMenuSelectedOptions(report);
    this.clearUnwantedFilters(report);

    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    const offset: number = (this.paginationData) ? this.paginationData.pageIndex * this.paginationData.pageSize : 0

    this.reportsService.getDataReportList(report.reportMenu, offset, true).then((reportCellList: any) => {
      this.highLightedRowName = (reportCellList.highlightedValue) ? reportCellList.highlightedValue : this.highLightedRowName;
      report.reportCellList = reportCellList;

      this.setPaginationData();
      this.dialog.closeAll()


    }, (error) => {

      console.error("Get Report List Error", error)
      this.snackBarService.displaySnackBarMessage("Report Failed to load", false);

      this.dialog.closeAll()

    });
  }

  getHeaders(): string[] {
    return []
  }


  private reorderSelectedReportShowOptions(report: DashboardData): void {
    let first = report.reportMenu.selectedStratField
    report.reportMenu.showOptionList.sort((a, b) => {

      return a.field === first ? -1 : b.field === first ? 1 : 0;
    })
  }

  private setReportMenuSelectedOptions(report: DashboardData) {
    const groupByField: string = report.reportMenu.selectedStratField;
    report.reportMenu.showOptionList.forEach(item => {
      item.isSelected = false;
      if (item.text === "Project Count" || item.field === groupByField) {
        item.isSelected = true;
      }
    })
  }

  private clearUnwantedFilters(report: DashboardData) {
    report.reportMenu.filterTextList.forEach(item => {
      item.includeValue = null;
    })
  }

}
