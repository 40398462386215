import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { FcmService } from './shared/services/fcm.service';
import { MessagingService } from './shared/services/messaging.service';
import { ThemeService } from './shared/services/theme-service.service';
import { ShowOptions, SplashScreen } from '@capacitor/splash-screen';

import { Storage } from '@ionic/storage';
import { MessagingSocketService } from './shared/services/messaging-socket.service';

import { MessagingStoreService } from './shared/services/messaging-store.service';
import { HeaderService } from './shared/components/my-header/header.service';
import { UserDetailsService } from './shared/services/user-details.service';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { App, AppState } from '@capacitor/app';

import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { Badge } from '@robingenz/capacitor-badge';

import { Storage as capStorage } from '@capacitor/storage';
import { AppStateService } from './shared/services/app-state.service';
import { SnackbarService } from './shared/services/snackbar.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'home'
    },
    {
      title: 'Workforce Micro Surveys',
      url: '/workforce-micro-surveys',
      icon: 'assignment'
    },
    {
      title: 'Employees',
      url: '/employees',
      icon: 'people'
    }

    ,
    {
      title: 'Tasks',
      url: '/tasks',
      icon: 'list_alt'
    },



    ,
    {
      title: 'Opportunities',
      url: '/opportunities',
      icon: 'credit_card'
    },
    {
      title: 'Retention Campaigns',
      url: '/retention-campaigns',
      icon: 'play_for_work'
    }

    ,
    {
      title: 'Settings',
      url: '/settings',
      icon: 'settings'
    }

    ,
    {
      title: 'Help',
      url: '/help',
      icon: 'help'
    }
  ];

  analyticsSelected = true;
  employeesSelected = false;
  campaignsSelected = false;
  incentivesSelected = false;
  messagesSelected = false;


  constructor(
    private platform: Platform,
    // public splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public toastController: ToastController,
    public fcmService: FcmService,
    public userDetailsService: UserDetailsService,
    public messagingStoreService: MessagingStoreService,
    public messagingSocketService: MessagingSocketService,
    public themeService: ThemeService,
    public headerService: HeaderService,
    public localStorage: Storage,
    private mobileAccessibility: MobileAccessibility,
    public appStateService: AppStateService,
    public snackbarService: SnackbarService,

  ) {
    this.initializeApp();



  }


  async initService() {

    await this.localStorage.create();
    await this.localStorage.defineDriver(CordovaSQLiteDriver);
    this.localStorage.get('themeSelection').then((selection: string) => {
      if (selection) {
        this.themeService.setTheme(selection);
      }
      else {
        this.themeService.setTheme("");
      }
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  initializeApp() {
    this.fcmService.startPushListeners();
    this.messagingStoreService.storeOutstandingNotifications();

    this.initService();

    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        // alert("app is active")


        this.messagingStoreService.storeOutstandingNotifications();
        this.messagingStoreService.loadAllConversations();
        this.messagingSocketService.reconnectIfDisconnected();

        await this.appStateService.setTimeAppWasOpened(Date.now().toString());

        // let diff = await this.appStateService.getTimeDifference();

        // if (diff >= 0 && this.platform.is("ios")) {
        //   let options: ShowOptions = {
        //     showDuration: 2000,
        //   }
        //   SplashScreen.show(options);

        //   // this.initializeApp();

        //   // location.reload();

        //   this.router.navigate(["/home/0"]);

        //   // setTimeout(() => {
        //   //   // location.reload();
        //   //   // this.snackbarService.displaySnackBarMessage(diff);
        //   // },
        //   //   1000);
        // }

      } else {

        await this.appStateService.setTimeAppWasClosed(Date.now().toString());


        if (!this.platform.is("mobileweb") && !this.platform.is("desktop")) {
          if (this.messagingStoreService.totalUnreadMessages === 0) {
            Badge.set({ count: -1 });
          }
          else {
            Badge.set({ count: this.messagingStoreService.totalUnreadMessages });
          }
          // 
        }
      }
    });

    this.platform.ready().then(() => {
      if (!(this.platform.is("mobileweb") || this.platform.is("desktop"))) {
        this.statusBar.styleDefault();
        this.mobileAccessibility.usePreferredTextZoom(false);

      }
      this.messagingSocketService.initializeSocket();
      if (!(this.platform.is("mobileweb") || this.platform.is("desktop"))) {
        // this.splashScreen.hide();
        SplashScreen.hide();
      }
    });

  }
}