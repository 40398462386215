<div class="app-background-color" style="min-width: 300px; padding: 20px;">

  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Name
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.firstName + " " + this.data.invite.lastName}}
    </div>
  </ion-toolbar>



  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Email
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.emailAddress}}
    </div>
  </ion-toolbar>


  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Phone
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.phoneNumber}}
    </div>
  </ion-toolbar>


  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Office
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.office}}
    </div>
  </ion-toolbar>


  <ion-toolbar class="transparent-bg">
    <div slot="start">
      Status
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.status}}
    </div>
  </ion-toolbar>


  <ion-toolbar class="transparent-bg">
    <div slot="start">
      SCAR
    </div>

    <div slot="end" style="font-weight: 100;">
      {{this.data.invite.scar}}
    </div>
  </ion-toolbar>


  <button style="width: 100%;" mat-button class="primary-color" (click)="resend()">  Resend Invitation</button>
  <button style="width: 100%;" mat-button  (click)="edit()">  Edit Invitation</button>

</div>