import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { FooterStoreService } from '../shared/components/my-footer/footer-store.service';
import { FooterService } from '../shared/components/my-footer/footer.service';
import { WelcomeWizardComponent } from '../shared/components/welcome-wizard/welcome-wizard.component';
import { DEFAULT_USER_DATA, UserData } from '../shared/models/dashboard.model';
import { BusySpinnerService } from '../shared/services/busy-spinner.service';
import { DeviceDetailsService } from '../shared/services/device-details.service';
import { FcmService } from '../shared/services/fcm.service';
import { LoginService } from '../shared/services/login.service';
import { MessagingSocketService } from '../shared/services/messaging-socket.service';
import { MessagingStoreService } from '../shared/services/messaging-store.service';
import { ProfileService } from '../shared/services/profile.service';
import { SnackbarService } from '../shared/services/snackbar.service';
import { UserDetailsService } from '../shared/services/user-details.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email = '';
  password = '';

  userData: UserData = { ...DEFAULT_USER_DATA }

  showLoginPage: boolean = true;

  secondsUserHasBeenOnPage: number = 0;


  constructor(
    public localStorage: Storage,
    public router: Router,
    private fcmService: FcmService,
    public snackbarService: SnackbarService,
    public deviceDetailsService: DeviceDetailsService,
    private dialog: MatDialog,
    public messagingStoreService: MessagingStoreService,
    public messagingSocketService: MessagingSocketService,
    public userDetailsService: UserDetailsService,
    public profileService: ProfileService,
    public loginService: LoginService,
    public footerService: FooterService,
    public footerStoreService: FooterStoreService,
    public busySpinnerService: BusySpinnerService
  ) { }


  ionViewDidEnter() {
    if (history.state.userLoggedOut) {
      this.showLoginPage = true;
    } else {
      this.showLoginPage = false;

      setInterval((timeout) => {
        this.secondsUserHasBeenOnPage += 1;
      }, 1000)

      this.checkIfAlreadyLoggedIn();
    }
  }


  async attemptLogin() {
    let result = await this.loginService.attemptLogin(this.email, this.password);
    if (!result) {
      this.snackbarService.displaySnackBarMessage("Something went wrong. Please try again.", false)
      return;
    }
    else if (result.errorMessage) {
      this.snackbarService.displaySnackBarMessage(result.errorMessage, false)
      return;
    }
    else {
      this.processSuccessfulLogin(result);
    }
  }

  private async checkIfAlreadyLoggedIn(): Promise<void> {
    await this.userDetailsService.ensureUserDataIsLoaded();

    const sessionKey: string = await this.userDetailsService.getSessionKey();

    if (!sessionKey) {
      this.showLoginPage = true;
      return;
    }
    else {
      this.footerService.setFooterTabs();
      this.navigateAwayFromLogin();


      // We will want to think about this in the future. Do we want to just let people in if they have a sessionKey
      // and it's not valid? Do we want to check if a sessionKey is valid every time they open the app?

      // let result = await this.loginService.checkIfCurrentSessionKeyIsValid();
      // if (result.message && result.message === 'Success') {
      //   this.navigateAwayFromLogin();
      // } else {
      //   this.snackbarService.displaySnackBarMessage(result.errorMessage ? result.errorMessage : "Something went wrong. Try again.")
      // }
    }

  }



  private async processSuccessfulLogin(result: any): Promise<void> {

    this.busySpinnerService.start();


    this.fcmService.initPush(result['sessionKey']);

    await this.saveUserDataToLocalStorage(result);
    this.messagingSocketService.initializeSocket();
    this.messagingSocketService.connectToSocketRooms();
    await this.loginService.checkOnboardingRights();

    let res = await this.profileService.getWorkspaces();
    this.profileService.setSelectedWorkspacesFromLogin(res);


    await this.footerService.setFooterTabs();

    this.navigateAwayFromLogin();
    this.busySpinnerService.stop();

  }
  

  private async saveUserDataToLocalStorage(result: UserData): Promise<void> {
    this.userData = result;

    await this.userDetailsService.setSessionKey(result.sessionKey);

    const attributeMap: any = result.attributeMap
    if (attributeMap) {
      this.userData.salesRepRegion = attributeMap['Sales Rep Region'];
      this.userData.salesRepTeam = attributeMap['Sales Rep Team'];
    }

    this.userDetailsService.setUserData(this.userData);
  }

  private async navigateAwayFromLogin() {
    this.loginService.userHasLoggedIn = true;
    if (this.loginService.appOpenedFromNotification) { return }
    await this.footerStoreService.loadFirstFooterUrlFromCache();
    this.router.navigate([this.footerStoreService.firstFooterUrl]);

    setTimeout(() => {
      this.checkImageValue();
    }, 100);
  }

  async checkImageValue() {
    const data = await this.userDetailsService.getUserData();

    if (data !== null) {
      if (data.imageUrl === null || data.imageUrl === undefined || data.imageUrl === '') {
        let dialogRef = this.dialog.open(WelcomeWizardComponent, {
          maxWidth: '100vw !important',
          width: '100%',
          height: '100%',
          data: { userData: this.userData },
          panelClass: 'transparent',
          disableClose: false
        })
      }
    }

  }

  public async openPasswordHelperInBrowser() {
    this.router.navigate(["/reset-password"])
  }

  ionViewWillLeave() {
    this.secondsUserHasBeenOnPage = 0;
  }

  joinSunder() {
    this.router.navigateByUrl('/join-sunder', { state: {} });
  }
}
