import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, Inject, Input, Renderer2 } from "@angular/core";
import { ISurveyApi } from "src/app/shared/services/survey.api";
import { ISurveyService } from "src/app/surveys/survey.service";


@Directive({
    selector: "[responsesBySessionId]"
})
export class ResponsesBySessionId {

    private _sessionId: string;
    private infoButton: HTMLElement;

    @Input() set sessionId(use: string) {
        this._sessionId = use;
        if (use) {
            this.init(); // TODO: fill this in once the code is ready
        }
    };

    constructor(    
        private surveyService: ISurveyService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document) {}

    init() {
        if (this.infoButton) return false;

        this.infoButton = this.document.createElement('mat-button')
        const infoIcon = this.document.createElement('mat-icon');
        this.renderer.appendChild(this.infoButton, infoIcon);
        this.renderer.appendChild(infoIcon, this.renderer.createText('info'));

        this.renderer.addClass(infoIcon, 'mat-icon');
        this.renderer.addClass(infoIcon, 'material-icons');
        // this.renderer.addClass(infoButton, 'top-right');
      
        infoIcon.onclick = (event) => {this.onClick(event)};

        this.renderer.appendChild(this.elementRef.nativeElement, this.infoButton);
    
    }

    async onClick($event: MouseEvent) {
        return await this.surveyService.openRelatedResponsesModal(this._sessionId);
    }

    private sessionIdFromEvent($event: MouseEvent): string {
        return "$event."
    }

    


}