import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GeneralInputModalComponent } from 'src/app/shared/components/general-input-modal/general-input-modal.component';
import { UserPickerComponent } from 'src/app/shared/components/user-picker/user-picker.component';
import { CampaignOverview, Employee } from 'src/app/shared/models/employees.model';
import {  CampaignBackendModel, Incentive, MilestoneBackend } from 'src/app/shared/models/incentives.model';
import { DEFUALT_MILESTONE } from '../new-incentive.component';

@Component({
  selector: 'app-new-milestone-incentive',
  templateUrl: './new-milestone-incentive.component.html',
  styleUrls: ['./new-milestone-incentive.component.scss'],
})
export class NewMilestoneIncentiveComponent implements OnInit {

  

  @Input()
  campaignDetails: CampaignBackendModel;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {}


  async openAddMilestonePrompt() {
    let dialogRef = this.dialog.open(GeneralInputModalComponent, {
      maxWidth: '100vw !important',

      data: {
        title: 'New Teams',
        prompt: "Seperate the teams with commas to enter multiple teams",
      }
    });

    dialogRef.afterClosed().subscribe(async (data: string) => {
      if (data !== undefined) {
        const dataString: string = data;
        
        // TODO: PUSH THIS TO BACKEND
      }
    });
  }

  // TODO: Move metric typing into an interface
  async addDefaultMilesone() {
    const newMilestone: MilestoneBackend = {...DEFUALT_MILESTONE};
    this.campaignDetails.milestones.push(newMilestone);
  }

  // TODO: encode the fact that milestones can't have the same minValue 

  describeMilestone(m: MilestoneBackend): string {
    return JSON.stringify(m);
  }

  // selectedUsersChanged(event: Employee[]): void {
  //   this.campaignDetails.eligibleUsers = event.map(e => +e?.userId).filter(id => id !== null);
  // }

  async openSelectUsersDialog() {
    const ref = this.dialog.open(UserPickerComponent);
  }

}
