import { Injectable } from '@angular/core';
import { AppNotification } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notifications: AppNotification[] = [
    {
      name: "You are in 2nd place the Sales competition!",
      status: "unseen",
      date: "now"
    },
    {
      name: "You dropped two spots in the installs competition",
      status: "unseen",
      date: "15 min ago"
    },
    {
      name: "You just got a new install!",
      status: "seen",
      date: "3 days ago"
    }
    ,
    {
      name: "Eric Nielsen just sent you a message",
      status: "seen",
      date: "3 days ago"
    },
    {
      name: "You just won the Installs competition for the week!",
      status: "seen",
      date: "3 days ago"
    },
    {
      name: "You are now in 10th place for All-Time Sales!",
      status: "seen",
      date: "4 days ago"
    },
    {
      name: "Your customer, Stacey Adams, just cancelled their order.",
      status: "seen",
      date: "4 days ago"
    },
    {
      name: "Your customer, Tyler Smith, just cancelled their order.",
      status: "seen",
      date: "5 days ago"
    }
    ,
    {
      name: "Max Britton just sent you a message",
      status: "seen",
      date: "6 days ago"
    },
    {
      name: "You just got a raise!",
      status: "seen",
      date: "6 days ago"
    }
  ];

  constructor() { }
}
