import { AfterViewChecked, AfterViewInit, Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MatDialog, MatToolbar } from "@angular/material";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { UserData } from "../../models/dashboard.model";
import { HeaderService } from "../my-header/header.service";
import { IonGrid, NavController, Platform } from "@ionic/angular";
import { MessagingStoreService } from "../../services/messaging-store.service";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard"
import { LoginService } from "../../services/login.service";
import { ThemeService } from "../../services/theme-service.service";
import { UserDetailsService } from "../../services/user-details.service";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { LeadsService } from "src/app/leads/leads.service";
import { TicketsService } from "src/app/tickets/tickets.service";
import { LeadsStoreService } from "src/app/leads/leads-store.service";
import { FooterService, FooterTab, footerTabSortComparator } from "./footer.service";
import { FooterStoreService } from "./footer-store.service";
import { BusySpinnerService } from "../../services/busy-spinner.service";
import { SnackbarService } from "../../services/snackbar.service";

@Component({
  selector: "app-my-footer",
  templateUrl: "./my-footer.component.html",
  styleUrls: ["./my-footer.component.scss"],

})
export class MyFooterComponent implements OnInit {
  isDemoUser = false;
  accessLevel: number = 0;
  isDev = false;

  shouldShowOnboaringRights: boolean = false;
  shouldShowAdminRights: boolean = false;
  shouldShowTicketingRights: boolean = false;



  constructor(
    private renderer: Renderer2,
    public platform: Platform,
    public footerService: FooterService,
    private localStorage: Storage,
    public nav: HeaderService,
    public router: Router,
    public loginService: LoginService,
    private dialog: MatDialog,
    public messagingStoreService: MessagingStoreService,
    private navController: NavController,
    private themeService: ThemeService,
    public userDetailsService: UserDetailsService,
    public leadsService: LeadsService,
    public ticketsService: TicketsService,
    public leadsStoreService: LeadsStoreService,
    public footerStoreService: FooterStoreService,
    public snackbarService: SnackbarService

  ) {
    this.initConstructorCalls();
  }


  async initConstructorCalls() {

    await this.localStorage.create();
    await this.localStorage.defineDriver(CordovaSQLiteDriver);


    this.platform.backButton.subscribeWithPriority(10, () => {

      if (this.router?.url?.includes("/game-detail") || this.router.url === "/home-drill-down/region" || this.router.url === "/home-drill-down/team" || this.router.url === "/home-drill-down/employee" || this.router.url === "/analytics/0" || this.router.url === "/record-detail") {
        this.navController.pop();
      }
      else if (this.router.url === "/messages") {
        //Don't allow them to use it
      }
    });


    if (!(this.platform.is("mobileweb") || this.platform.is("desktop"))) {
      Keyboard.addListener("keyboardWillShow", (info: KeyboardInfo) => {
        if (!this.splitPaneEnabled) {
          this.nav.hideFooter();
          this.nav.showKeyboardToolbar();
        }
      });

      Keyboard.addListener("keyboardWillHide", () => {
        if (!this.splitPaneEnabled()) {
          this.nav.showFooter();
          this.nav.hideKeyboardToolbar();
        }
      });
    }



    this.localStorage.get("userData").then((data: UserData) => {
      if (data !== null) {
        this.accessLevel = data.accessLevel;

        if (data.emailAddress === "steve.jobs@email.com") {
          this.isDemoUser = true;
        } else {
          this.isDemoUser = false;
        }

        if (
          data.emailAddress === "hunterbjenkins@gmail.com" ||
          data.emailAddress === "adam.dellapiana@gmail.com" ||
          data.emailAddress === "carypjenkins@gmail.com" ||
          data.emailAddress === "price.jenkins@gmail.com"
        ) {
          this.isDev = true;
        } else {
          this.isDev = false;
        }
      }
    });
  }




  getFooterTabs() {

    let length = this.footerStoreService.footerTabs.length;

    if (length >= 5) {
      return this.footerStoreService.footerTabs.slice(0, 4).sort((a, b) => footerTabSortComparator(a, b));
    } else {
      return this.footerStoreService.footerTabs.sort((a, b) => footerTabSortComparator(a, b));
    }

  }

  calculateNumberOfTabs(): number {
    if (this.screenIsXS() || this.screenIsSmall()) {
      return 5;
    }
    else if (this.screenIsMedium()) {
      return 5;
    }
    else if (this.screenIsLarge()) {
      return 5;
    }
    else if (this.screenIsXL()) {
      return 5;
    }
  }

  screenIsXS() {
    return this.platform.width() > 0 && this.platform.width() <= 576;
  }

  screenIsSmall() {
    return this.platform.width() > 576 && this.platform.width() <= 768;
  }

  screenIsMedium() {
    return this.platform.width() > 768 && this.platform.width() <= 992;
  }

  screenIsLarge() {
    return this.platform.width() > 992 && this.platform.width() <= 1200;
  }

  screenIsXL() {
    return this.platform.width() > 1200;
  }


  shouldShowMoreButton() {
    return this.footerStoreService.footerTabs.length >= 5;
  }


  @ViewChild('gridFooter') gridFooter: IonGrid;




  getMoreFooterTabs() {
    return this.footerStoreService.footerTabs.slice(4, this.footerStoreService.footerTabs.length).sort((a, b) => footerTabSortComparator(a, b));
  }


  async ngOnInit() {
    console.log("test")

    setTimeout(() => {
      this.checkIfUserHasOnboardingRights();
      this.checkIfUserHasAdminRights();
      this.checkIfUserHasTicketRights();
    },
      1000);

  }


  splitPaneEnabled() {
    if (this.platform.width() <= 770) {
      return false;
    }
    else {
      return true;
    }
  }

  tabIsSelected(tab: FooterTab) {
    return this.router.url.includes(tab.routerUrl)
  }


  moreTabIsSelected() {
    let returnVal = false;
    if (this.footerStoreService.footerTabs.length >= 5) {
      this.getMoreFooterTabs().forEach(tab => {
        if (this.router.url.includes(tab.routerUrl)) {
          returnVal = true;
        }
      })
    }
    return returnVal;
  }

  processTabAction(tab: FooterTab) {
    this.dialog.closeAll();
    this.navController.navigateForward(tab.routerUrl, { animated: false })
  }

  getTabChips(tab: FooterTab) {
    if (tab.title !== "Messages" && tab.title !== "Leads") { return }

    if (tab.title === "Messages") {
      return this.messagingBadgeVal
    }
    else if (tab.title === "Leads" && this.userShouldDisplayLeadChips()) {
      return this.moreBadgeVal
    }
  }


  getTabChipsForMoreDrawer() {
    let returnVal = 0;
    this.getMoreFooterTabs().forEach(tab => {
      if (tab.title === "Leads" && this.userShouldDisplayLeadChips()) {
        returnVal += this.moreBadgeVal;
      }
      if (tab.title === "Messages") {
        returnVal += this.messagingBadgeVal;

      }
    })
    return returnVal > 0 ? returnVal : null;
  }

  userShouldDisplayLeadChips() {
    return this.leadsService.leadOptionsConfig.userIsTeamLeadManager || this.leadsService.leadOptionsConfig.userHasLeadAdminRights
  }

  keyContacts() {
    this.dialog.closeAll();
    this.navController.navigateForward("/key-contacts", { animated: false })
  }


  async logOut() {
    this.dialog.closeAll();
    await this.userDetailsService.clearUserData();
    await this.userDetailsService.clearSessionKey();

    this.snackbarService.displaySnackBarMessage("Logging out...", true, 500)
    await this.delay(500);

    this.router.navigateByUrl('/login', { state: { userLoggedOut: true } });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  get messagingBadgeVal(): number {
    const val = this.messagingStoreService.getTotalUnreadMessages();
    if (val >= 99) {
      return 99
    }
    return val !== 0 ? val : null;
  }



  get moreBadgeVal(): number {
    const val = this.leadsStoreService.getUnassignedLeads().length;
    if (val >= 99) {
      return 99
    }
    return val !== 0 ? val : null;
  }

  isIOS() {
    return this.platform.is("ios");
  }

  isLightTheme() {

    return this.themeService.theme?.includes("dark");
  }


  async checkIfUserHasOnboardingRights() {
    const res = await this.localStorage.get("HAS_ONBOARDING_RIGHTS");
    if (res === null) {
      await this.loginService.checkOnboardingRights();
      this.shouldShowOnboaringRights = this.loginService.hasOnboardingRights
    } else {
      this.shouldShowOnboaringRights = res;
    }
  }


  async checkIfUserHasAdminRights() {
    const res = await this.userDetailsService.getAccessLevel();
    if (res === 10) { this.shouldShowAdminRights = true }
    else { this.shouldShowAdminRights = false; }
  }




  async checkIfUserHasTicketRights() {
    const res = this.ticketsService.ticketOptionsConfig?.userHasTicketAdminRights;
    this.shouldShowTicketingRights = res;
  }

}
