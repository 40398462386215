import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { Series } from 'src/app/shared/models/library.model';
import { LibraryService } from 'src/app/shared/services/library.service';
import { Section } from '../../library.component';

@Component({
  selector: 'app-video-section-card',
  templateUrl: './video-section-card.component.html',
  styleUrls: ['./video-section-card.component.scss'],
})
export class VideoSectionCardComponent implements OnInit {

  video: ResourceFile;
  sections: Section[] = [];
  series: Series[] = [];

  shouldShowSeries: boolean;

  constructor(public libraryService: LibraryService, public dialogRef: MatDialogRef<VideoSectionCardComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) { 

    this.sections = this.libraryService.allSections;
    this.series = this.libraryService.allSeries;
    
    if (this.data?.video) {
      this.video = this.data.video; 
    }
    else {
      this.video = {
        title: "",
        thumbnailUrl: "",
        videoUrl: "",
        trainerName: "",
        id: Math.random(),
        category: "",
        section: "",
        resourceType: this.libraryService.selectedResourceType
      }
    }

    if (this.data?.category)  {
      this.video.category = this.data.category;
      let tempSeries: Series = {
        title: this.video.category,
        backgroundImage: null,
        section: null
      }

      this.series.push(tempSeries)

    }

    if (this.data?.section)  {
      this.video.section = this.data.section;
      let tempSection: Section = {
        sectionTitle: this.data.section,
        videosMap: null
      }
      this.sections.push(tempSection)
    }

    if (this.data?.shouldShowSeries === false) {
      this.shouldShowSeries = false;
    } else {
      this.shouldShowSeries = true;
    }

    
  }

  ngOnInit() {}

  create() {
    this.dialogRef.close(this.video)
  }

  cancel() {
    this.dialogRef.close();
  }

  selectedFile: any;

  // getFileReader(): FileReader {
  //   const fileReader = new FileReader();
  //   const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  //   return zoneOriginalInstance || fileReader;
  // }


  onFileSelected(event) {
    this.selectedFile = event.target.files[0];

    this.video.thumbnailFile = this.selectedFile;

    // let reader = this.getFileReader();

    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.video.thumbnailUrl = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(event.target.files[0]);

  }


  onFileSelectedSource(event) {
    this.video.videoFile = event.target.files[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      // this.video.thumbnailUrl = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(event.target.files[0]);
  }

}
