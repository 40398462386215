<div class="app-background-color" style="padding: 10px; min-width: 100px;">
  <div style="height: 10px"></div>
  <h1 style="text-align: center; margin: 0;">{{getTitle()}}</h1>
  <div style="height: 10px"></div>
  <div class="section-div" 
    style="text-align: left; padding: 10px; margin-bottom: 20px;">
    <table>
      <tr *ngFor="let key of getKeys()" style=" font-size: 22px; font-weight: 100; margin: 5px 0 0 0;">
        <td style="padding-right: 10px;">
          {{camelCaseToSentenceCase(key)}}
        </td>
        <td> 
          {{getValue(key)}}
        </td>
      </tr>
    </table>
    <!-- <div *ngFor="let key of getKeys()" >

      <h1 style=" font-size: 22px; font-weight: 100; margin: 5px 0 0 0;">{{getValue(key)}}</h1>
    </div> -->
    <!-- <div style="height: 10pÎx;"></div> -->
    <!-- <mat-divider></mat-divider>
    <span style="font-weight: 100; text-align: center; width: 100%; display: block; padding: 10px"
      class="opaque-text">{{getFooterText()}}</span> -->
  </div>
</div>