<div class="section-div" style="padding: 10px; text-align: left;">

  <ion-toolbar class="transparent-bg">

    <div slot="start">
      {{this.ticket.title}}
    </div>
  </ion-toolbar>
  <div style="height:5px"></div>

  <mat-divider></mat-divider>

  <div style="height:5px"></div>

  <ion-toolbar class="transparent-bg">
    <div slot="start" class="opaque-text">
      Assigned to:
    </div>
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>

    <div slot="start">
      <div style="height: 10px;"></div>
      <app-user-icon class='user-icon' matListAvatar [employee]='getAssignedEmployee()'></app-user-icon>
    </div>

    <div slot="start">
      <div style="width: 5px;"></div>
    </div>

    <div slot="start">
      {{getAssignedEmployeeName()}}
    </div>

  </ion-toolbar>

  <ion-toolbar class="transparent-bg">
    <div slot="start" class="opaque-text">
      Status:
    </div>
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>

    <div slot="start">
      {{this.ticket.ticketStatus}}
    </div>
  </ion-toolbar>


  <ion-toolbar class="transparent-bg">
    <div slot="start" class="opaque-text">
      Creation Date:
    </div>
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>

    <div slot="start">
      {{this.dateFormatService.formatDateFromString(this.ticket.creationDate)}}
    </div>
  </ion-toolbar>



  <!-- <ion-grid>
    <ion-row>
      <ion-col size='4' class="ticket-title">
      </ion-col>
      <ion-col size='6'></ion-col>
      <ion-col size='2' *ngIf='ticket?.assignedUser'>
        <span class="ticket-assigned-employee">
          <app-user-icon class='user-icon' matListAvatar [employee]='getAssignedEmployee()'></app-user-icon>
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ticket-description" size='12'>
        {{this.ticket.description}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class='ticket-status' size='12'>
        {{this.ticket.ticketStatus}}
      </ion-col>
    </ion-row>
    <ion-row *ngIf='this.ticket?.notes?.length > 0'>
      <ul>
        <li *ngFor='let note of this.ticket.notes'>
          <ion-col class='ticket-note' size='12'>
            {{note}}
          </ion-col>
        </li>
      </ul>
    </ion-row>
    <div *ngFor="let note of this.ticket.notes" style="max-width: 600px;">
      <app-note-card [note]="note"></app-note-card>
    </div>
    <app-multi-file-upload [fileUrls]='this.ticket?.fileUrls'></app-multi-file-upload>


  </ion-grid> -->
</div>