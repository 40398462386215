import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENZY_BASE_URL, REST_BASE_URL } from '../models/constants';
import { FilterData } from '../models/dashboard.model';
import { Employee } from '../models/employees.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private http: HttpClient,
  ) {}

  getAllEmployees(sessionKey: string, limit: number = 100000, startIndex: number = 0): Promise<any> {
    const url =
      REST_BASE_URL + '/employeeList/' +
      sessionKey + 
      "?startIndex=" + startIndex +
      "&limit=" + limit;
    return this.http.get<any>(url).toPromise();
  }

  getIndexedEmployees( sessionKey: string, index: string, filterData: FilterData): Observable<Employee[]> {
    const url =
    // 'http://localhost:8080/rest/employees/' +
      REST_BASE_URL + '/employeeList/' +
      sessionKey +
      index +
      '&department=' + filterData['selectedDepartment'] +
      '&gender=' + filterData['selectedGender'] +
      '&minMonthsOnJob=' + filterData['minMonthsOnJob'] +
      '&maxMonthsOnJob=' + filterData['maxMonthsOnJob'] +
      '&minSalary=' + filterData['minSalary'] +
      '&maxSalary=' + filterData['maxSalary'] +
      '&minFlightRiskScore=' + filterData['selectedFlightRisk'] +
      '&maxFlightRiskScore=' + filterData['selectedFlightRisk'] +
      '&keyword=' + filterData['keyWord'] +
      '&orderKey=' + filterData['selectedOption'];
    return this.http.get<Employee[]>(url);
  }

  employeeHasImage(employee: Employee): boolean {
    return employee?.imageUrl !== null && employee.imageUrl.length > 0;
  }

  getEmployeeInitials(employee: Employee): string {
    if ( employee && employee.userDto && employee.userDto.preferredFirstName !== null) {
      if (!employee.userDto.lastName) {return '-'}
      if (!employee.userDto.preferredFirstName) {return '-'}
      if (employee.userDto.lastName.length === 0 ) {return '-'}
      if (employee.userDto.preferredFirstName.length === 0 ) {return '-'}
      return (employee?.userDto?.preferredFirstName[0] + employee?.userDto?.lastName[0]).toUpperCase();
    }
    return '';
  }

}
