<ion-toolbar matRipple class="example-ripple-container transparent-bg" [matRippleUnbounded]="false" (click)="drillDown()">
  <div slot="start" *ngIf="showRank()" style="width: 50px;">
    #{{this.leaderboardCell.rank}}
  </div>


  <div slot="start" *ngIf="showImage">
    <div style="height: 10px;"></div>
    <app-user-icon [shouldExpandImage]="false" [imageUrl]="this.leaderboardCell.imageThumbnail"
      [initials]='getCellInitials()'></app-user-icon>
  </div>

  <div slot="start" *ngIf="showImage">
    <div style="width: 5px;"> </div>
  </div>
  <div slot="start" style="font-weight: 100;">
    {{this.leaderboardCell.title.length <= 24 ? this.leaderboardCell.title : this.leaderboardCell.title.substring(0,20) + "..."}}
  </div>
  <div slot="end">
    {{this.leaderboardCell.value}}
  </div>

  <div slot="end" *ngIf="showTrend()">
    <div style="width: 10px;"> </div>
  </div>


  <div slot="end" *ngIf="showTrend()"
    [ngClass]="{'green': this.leaderboardCell?.trend >= 0, 'red': this.leaderboardCell?.trend < 0 }"
    style="min-width: 50px !important; height: 25px; text-align: center;">

    <span *ngIf="this.leaderboardCell?.trend > 0" class="material-icons iconAlign" style="font-size: 16px;">
      arrow_upward
    </span>

    <span *ngIf="this.leaderboardCell?.trend < 0" class="material-icons iconAlign" style="font-size: 16px;">
      arrow_downward
    </span>
    {{getCellTrendString()}}
  </div>

</ion-toolbar>
<mat-progress-bar 
  *ngIf="this.getShowBar()"
  mode="determinate" 
  value="{{getBarValue()}}"></mat-progress-bar>
