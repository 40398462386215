import { array } from '@amcharts/amcharts4/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { IonContent, IonRouterOutlet, Platform } from '@ionic/angular';
import { SectionCarouselComponent } from '../company-records/section-carousel/section-carousel.component';
import { ExpandedImageModalComponent } from '../messaging/expanded-image-modal/expanded-image-modal.component';
import { BusySpinnerComponent } from '../shared/components/busy-spinner/busy-spinner.component';
import { ResourceFile } from '../shared/models/help.model';
import { Series } from '../shared/models/library.model';
import { HelpService } from '../shared/services/help.service';
import { LibraryService } from '../shared/services/library.service';
import { UserDetailsService } from '../shared/services/user-details.service';
import { AddSectionComponent } from './add-section/add-section.component';
import { AdminLibraryComponent } from './admin-library/admin-library.component';
import { EditFeaturedSectionComponent } from './edit-featured-section/edit-featured-section.component';


@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit {

  categoryVideoUrls: Map<string, ResourceFile[]>;
  categoryImageUrls: Map<string, string>;

  currentOptions: ResourceFile[] = [];

  videoSections: Section[];
  podcastSections: Section[];
  documentSections: Section[];


  videoSectionsFeatured: Featured;
  podcastSectionsFeatured: Featured;
  documentSectionsFeatured: Featured;

  selectedTab = "Videos";

  shouldShowAdminButtons: boolean = false;
  constructor(
    public routerOutlet: IonRouterOutlet,
    public libraryService: LibraryService,
    public userDetailsService: UserDetailsService,
    private sanitizer: DomSanitizer,
    public helpService: HelpService,
    public platform: Platform,
    public dialog: MatDialog,
    public router: Router) {


    this.getLibraryAdminUsers();

  }

  async getLibraryAdminUsers() {
    let result = await this.libraryService.getLibraryAdmins();
    console.log(result)
    this.shouldShowAdminButtons = result.hasRights;
  }


  ngOnInit() {
    this.initFeaturedObjects();

    let ref = this.dialog.open(BusySpinnerComponent, {
      panelClass: "transparent",
      disableClose: false,
    });

    this.helpService.getTrainingVideos().then(res => {
      this.currentOptions = res;
      this.extractResultToMap(res, "video");
      this.currentOptions = this.libraryService.allResources;
      this.videoSectionsFeatured = res.featuredSection;
      const sectionsFromResult: Section[] = res.allSections;
      this.videoSections = this.cleanUpSectionsFromServer(sectionsFromResult);

      ref.close();
    })
    this.helpService.getDocuments().then(res => {
      console.log(res)

      this.extractResultToMap(res, "document");

      this.documentSectionsFeatured = res.featuredSection
      const sectionsFromResult: Section[] = res.allSections;
      this.documentSections = this.cleanUpSectionsFromServer(sectionsFromResult);
    });


    this.helpService.getPodcasts().then(res => {
      this.extractResultToMap(res, "podcast");
      this.podcastSectionsFeatured = res.featuredSection
      const sectionsFromResult: Section[] = res.allSections;
      this.podcastSections = this.cleanUpSectionsFromServer(sectionsFromResult);
    });

  }

  initFeaturedObjects() {
    this.videoSectionsFeatured = { title: "", videoThumbnail: "", videoUrl: "" };
    this.podcastSectionsFeatured = { title: "", videoThumbnail: "", videoUrl: "" };
    this.documentSectionsFeatured = { title: "", videoThumbnail: "", videoUrl: "" };

  }

  addSection() {
    let dialogRef = this.dialog.open(AddSectionComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.videoSections.push(res)
      }
    })
  }



  updatedSelectedTab(option: string) {
    this.selectedTab = option;
  }


  openFreedomResources() {
    this.goToLink("https://portal.freedomforever.com/communications/1M-V8GphpxhnkluJpgGZnDNoj-2wCwwIo")
  }


  ionViewDidEnter() {
    this.routerOutlet.swipeGesture = false;
  }



  tabHeaderImageUrl: string = "https://enzy-bucket1.s3.us-east-2.amazonaws.com/messagingImages/userId19_17bca707-690f-488e-85b6-edf318e07b8c_Artboard%2010.png"

  getTabHeaderImage() {
    // return this.tabHeaderImageUrl
    if (this.selectedTab === 'Videos') {
      return this.videoSectionsFeatured?.videoThumbnail
    } else if (this.selectedTab === 'Podcasts') {
      return this.podcastSectionsFeatured?.videoThumbnail
    } else if (this.selectedTab === 'Documents') {
      return this.documentSectionsFeatured?.videoThumbnail
    }
  }

  shouldShowPlayButton() {
    if (this.selectedTab === 'Videos') {
      return this.videoSectionsFeatured?.videoUrl !== null
    } else if (this.selectedTab === 'Podcasts') {
      return this.podcastSectionsFeatured?.videoUrl !== null
    } else if (this.selectedTab === 'Documents') {
      return this.documentSectionsFeatured?.videoUrl !== null
    }
  }

  getTabHeaderTitle() {
    if (this.selectedTab === 'Videos') {
      return this.videoSectionsFeatured?.title
    } else if (this.selectedTab === 'Podcasts') {
      return this.podcastSectionsFeatured?.title
    } else if (this.selectedTab === 'Documents') {
      return this.documentSectionsFeatured?.title
    }
  }



  extractResultToMap(result: any, type: string): void {
    this.extractAllSectionsFromServer(result, type)
    this.extractAllSeriesFromServer(result, type);
    this.extractAllResourcesFromServer(result);
    this.extractCategoryImagesFromServer(result);
  }

  extractCategoryImagesFromServer(result) {
    const categoryMap: Map<string, string> = new Map<string, string>();
    const categoryImages = result.categoryImages;
    Object.keys(categoryImages).forEach(key => {
      categoryMap.set(key, categoryImages[key]);
    })

    // this.categoryImageUrls = categoryMap;
    if (!this.categoryImageUrls) {
      this.categoryImageUrls = categoryMap;
    } else {
      this.categoryImageUrls = new Map([...categoryMap.entries(), ...this.categoryImageUrls?.entries()]);
    }

  }

  extractAllSectionsFromServer(result: any, type: string) {
    result.allSections.forEach(section => {
      section.resourceType = type
      this.libraryService.allSections.push(section)
    });

  }

  extractAllResourcesFromServer(result: any) {
    const map: Map<string, ResourceFile[]> = new Map<string, ResourceFile[]>();
    const trainingVideos = result.trainingVideos;

    Object.keys(trainingVideos).forEach((key, index) => {
      map.set(key, trainingVideos[key]);
      let videos = trainingVideos[key];
      videos.forEach(item => {
        item.status = "uploaded"
        this.libraryService.allResources.push(item)
      })
      this.libraryService.allSeries.forEach(seriesKey => {
        if (seriesKey.title === key) {
          seriesKey.resources = trainingVideos[key];
        }
      })
    })

    this.categoryVideoUrls = map;
  }

  extractAllSeriesFromServer(result: any, type: string) {
    let allSeries: Map<string, string> = result.categoryImages;


    Object.keys(allSeries).forEach((key, index) => {

      let series: Series = {
        title: key,
        backgroundImage: "",
        resources: [],
        section: "",
        resourceType: type
      }
      result.allSections.forEach(section => {
        let sectionTitle = section.sectionTitle;
        let sectionVideosMap = Array.from(Object.keys(section.videosMap)); //TODO: change backend object from "videosMap" to "videosMap"

        if (sectionVideosMap.includes(key)) {
          series.section = sectionTitle;
        }

      })
      this.libraryService.allSeries.push(series);
    })

    Object.values(allSeries).forEach((value, index) => {
      this.libraryService.allSeries[index].backgroundImage = value;
    })
  }

  cleanUpSectionsFromServer(inputSections: Section[]): Section[] {
    const tempSections: Section[] = [];

    for (let section of inputSections) {
      const tempMap: Map<string, ResourceFile[]> = new Map<string, ResourceFile[]>();
      Object.keys(section.videosMap).forEach(key => {
        tempMap.set(key, section.videosMap[key]);
      })
      section.videosMap = tempMap;
      tempSections.push(section);
    }
    return tempSections;

  }

  getSectionTitles(sections: Section[]): string[] {
    if (!sections) return [];
    const output = sections.map(v => v.sectionTitle);
    return output;
  }

  sectionHasMultipleSeries(sections: Section[], sectionTitle: string): boolean {
    const section = sections.find(s => s.sectionTitle === sectionTitle);
    if (!section) false;
    return Array.from(section.videosMap.keys()).length > 1;
  }

  getSeriesForSection(sections: Section[], sectionTitle: string): string[] {
    const section = sections.find(s => s.sectionTitle === sectionTitle);
    if (!section) return [];
    return Array.from(section.videosMap.keys());
  }

  getSectionFilesMap(sections: Section[], sectionTitle: string): Map<string, ResourceFile[]> {
    const section = sections.find(s => s.sectionTitle === sectionTitle);
    if (!section) return new Map<string, ResourceFile[]>();
    return section.videosMap;
  }

  listOfFilesForSection(sections: Section[], sectionTitle: string): ResourceFile[] {
    const section = sections.find(s => s.sectionTitle === sectionTitle);
    if (!section) [];
    const listOfLists = Array.from(section.videosMap.values());
    if (listOfLists.length === 0) return [];
    return Array.from(section.videosMap.values())[0];
  }

  getThumbnailUrl(video: ResourceFile) {
    if (video.thumbnailUrl) { return video.thumbnailUrl }
    else { return "../../../assets/placeholder-image.png" }

  }



  openFeaturedVideo() {
    if (this.selectedTab === "Videos") {
      if (!this.videoSectionsFeatured.videoUrl) { return }

      let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',

        data: {
          video: this.videoSectionsFeatured?.videoUrl,
        }
      });
    }
    else if (this.selectedTab === "Podcasts") {
      if (!this.podcastSectionsFeatured.videoUrl) { return }
      let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',

        data: {
          video: this.podcastSectionsFeatured?.videoUrl,
        }
      });
    }


    else if (this.selectedTab === "Documents") {
      if (!this.documentSectionsFeatured.videoUrl) { return }
      let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',

        data: {
          video: this.documentSectionsFeatured?.videoUrl,
        }
      });
    }
  }

  getSections(): string[] {
    if (!this.categoryVideoUrls) return [];
    return Array.from<string>(this.categoryVideoUrls.keys());
  }

  getImageForSection(section: string): SafeUrl {

    // return this.sanitizer.bypassSecurityTrustStyle(`url("../../assets/office.jpg")`)
    let url: string = this.categoryImageUrls.get(section)
    if (!url) url = 'https://enzy-bucket1.s3.us-east-2.amazonaws.com/conversation/2050EF94867E-A207-4315-920A-0247E881B2C3.gif';
    // return url;
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
    // return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getVideosForSection(section: string): ResourceFile[] {
    const videos: ResourceFile[] = this.categoryVideoUrls.get(section)
    if (!videos) return [];
    return videos;
  }

  navigateToSectionDetails(section: string) {
    this.routerOutlet.swipeGesture = true;

    let videos = this.getVideosForSection(section);
    // let sectionImage = this.getImageForSection(section);

    let url: string = this.categoryImageUrls.get(section)


    this.router.navigateByUrl('/library-section-detail', { state: { videos: videos, sectionImage: url } });

  }

  @ViewChild("content") content: IonContent;

  searchOn: boolean = false;
  setSearchOn() {
    this.content.scrollToTop();
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  searchSend() {
    this.currentOptions = this.libraryService.allResources.filter(r => (r.title + "_" + r.trainerName + "_" + r.category).toLowerCase().includes(this.searchValue.toLowerCase()));
  }


  appHasSafeArea() {
    return this.platform.is("ios")
  }

  openVideo(video: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        video: video,
      }
    });
  }

  navigateToFreedom() {
    this.goToLink("https://portal.freedomforever.com/communications/1M-V8GphpxhnkluJpgGZnDNoj-2wCwwIo")
  }

  navigateToDocumentLink(documentLink: string) {
    this.goToLink(documentLink);
  }

  edit(section: Section) {

    let dialogRef = this.dialog.open(AddSectionComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        section: section,

      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.videoSections.push(res)
      }
    })
  }

  openAdminLibrary() {
    let mode = "";
    if (this.selectedTab.includes("Document")) {
      mode = "document";
    } else if (this.selectedTab.includes("Video")) {
      mode = "video";
    } else if (this.selectedTab.includes("Podcast")) {
      mode = "podcast";
    }

    this.libraryService.selectedResourceType = this.selectedTab;

    let dialogRef = this.dialog.open(AdminLibraryComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    })
  }


  editFeaturedSection(section: string) {
    let featuredSection: Featured;
    if (section === "videos") {
      featuredSection = this.videoSectionsFeatured;
    } else if (section === "documents") {
      featuredSection = this.documentSectionsFeatured;
    } else if (section === "podcasts") {
      featuredSection = this.podcastSectionsFeatured;
    }


    let type = "";
    if (this.selectedTab.includes("Document")) {
      type = "document";
    } else if (this.selectedTab.includes("Video")) {
      type = "video";
    } else if (this.selectedTab.includes("Podcast")) {
      type = "podcast";
    }

    let dialogRef = this.dialog.open(EditFeaturedSectionComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        featuredSection: featuredSection,
        type: type

      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {

        if (section === "videos") {
          this.videoSectionsFeatured = res;
        } else if (section === "documents") {
          this.documentSectionsFeatured = res;
        } else if (section === "podcasts") {
          this.podcastSectionsFeatured = res;
        }
      }
    })
  }
}

export interface Section {
  sectionTitle: string;
  videosMap: Map<string, ResourceFile[]>;

  resourceType?: string;
}

export interface Featured {
  title: string;
  videoThumbnail: string;
  videoUrl: string;

  videoThumbnailFile?: File;
}