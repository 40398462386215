import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { LeadChanges } from 'src/app/shared/services/messaging.service';
import { LeadModel, getDefaultNewLead } from '../leads.model';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss'],
})
export class LeadModalComponent implements OnInit {

  lead: LeadModel = getDefaultNewLead();
  
  mode: LeadModalMode = "view";

  constructor(
    public dialogRef: MatDialogRef<LeadModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {lead: LeadModel, mode: LeadModalMode},
    ) {
    if (data?.lead) {
      this.lead = data.lead;
    } else {
      this.lead = getDefaultNewLead();
    }

    if (data?.mode){
      this.mode = data.mode;
    }
  
   }

  ngOnInit() {}

  getFields(_lead: LeadModel = this.lead): string[] {
    return Object.keys(_lead).filter(key => !this.fieldHasCustomUiInput(key));
  }

  fieldHasCustomUiInput(key: string): boolean {
    const customKeys: string[] = [
      "leadId",
      "assignedRep",
      "leadStatus",
    ]
    return customKeys.includes(key);
  }

  getFieldTitle(camelCaseWord: string): string {
    return camelCaseToSentenceCase(camelCaseWord);
  }


  async saveLeadChanges(lead: LeadModel) {
    Object.assign(this.lead, lead);
    this.dialogRef.close({lead: this.lead});
  }


  close() {
    if (this.mode=="view") {
      this.dialogRef.close({lead: this.lead}); 
    } else {
      this.dialogRef.close();

    }
  }

  
}

export type LeadModalMode = "new" | "view" | "edit";