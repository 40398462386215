import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { SurveyModalComponent } from "src/app/cr-dashboard/components/dashboard-alert-card/accuracy-modal/survey-modal.component";
import { DashboardAlertModel, ICRDashboardService } from "src/app/cr-dashboard/cr-dashboard.service";
import { LeadModel } from "src/app/leads/leads.model";
import { LeadsService } from "src/app/leads/leads.service";
import { GenericMapModalComponent } from "src/app/shared/components/generic-map-modal/generic-map-modal.component";
import { Employee } from "src/app/shared/models/employees.model";
import { LeaderboardCell } from "src/app/shared/models/leaders.model";
import { EmployeeStoreService } from "src/app/shared/services/employee-store.service";
import { LeadersStoreService } from "src/app/shared/services/leaders-store.service";
import { ISurveyService } from "src/app/surveys/survey.service";
import { LeaderboardViewType } from "../leaders.component";
import { GenericDrillDownApi, GenericObjectResponse } from "./generic-drill-down.api";

@Injectable()
export class LeaderboardDrillDownService {

    constructor(
        private dialog: MatDialog,
        private genericDrillDownApi: GenericDrillDownApi,
        private router: Router, 
        private leadersStoreService: LeadersStoreService, 
        private employeeStoreService: EmployeeStoreService,
        private leadService: LeadsService,
        // private crDashboardService: ICRDashboardService,
        private surveyService: ISurveyService,
    ) {

    }

    async gridDrillDown(leaderboardCell: LeaderboardCell, forOption: string, selectedIndex: number, _routeId: number) {
        return this.drillDown(
            leaderboardCell,
            forOption,
            selectedIndex,
            _routeId,
            "grid"
        )
    }

    async listDrillDown(leaderboardCell: LeaderboardCell, forOption: string, selectedIndex: number, _routeId: number, showBar: boolean) {
        const viewType: LeaderboardViewType = showBar ? "bar_list" : "list";
        return this.drillDown(
            leaderboardCell,
            forOption,
            selectedIndex,
            _routeId,
            viewType
        )
      }

    private async drillDown(leaderboardCell: LeaderboardCell, forOption: string, selectedIndex: number, _routeId: number, viewType: LeaderboardViewType) {
        if (leaderboardCell.employeeId) {
            this.drillDownToEmployee(+leaderboardCell.employeeId);
        } else if(!!leaderboardCell.id && !!leaderboardCell.idType) { 
            this.genericObjectDrillDown(leaderboardCell.id, leaderboardCell.idType);
        } else {
            this.drillDownToLeaderboardPage(
                leaderboardCell,
                forOption,
                selectedIndex,
                _routeId,
                viewType
            )
        }
    }

    private drillDownToEmployee(userId: number) {
        const employee: Employee = this.employeeStoreService.getUser(userId);
        this.router.navigateByUrl('/home-drill-down/employee', { state: { employee: employee } }); 
    }

    private async genericObjectDrillDown(objectId: string, objectType: string) {
        const res: GenericObjectResponse = await this.genericDrillDownApi.getGenericObject(objectId, objectType);
        this.drillDownByGenericResult(res, objectType);
    }

    private async drillDownToLeaderboardPage(leaderboardCell: LeaderboardCell, forOption: string, selectedIndex: number, _routeId: number, viewType: LeaderboardViewType) {
        let tempId = _routeId;
        tempId += 1;
        await this.leadersStoreService.processDrillDown(tempId, leaderboardCell.objectIndex, leaderboardCell.title);
        this.router.navigateByUrl('/home/'+tempId, { 
            state: { 
                viewType, 
                leaderboardCell , 
                forOption, 
                selectedIndex }});
    }

    private drillDownByGenericResult(res: GenericObjectResponse, objectType: string) {
        
        if (!!res.lead) this.openLead(res.lead);
        else if (!!res.genericObject) this.openGenericObject(res.genericObject, objectType);
        else if (!!res.project) this.openProject(res.project);
        else if (!!res.surveyResponseDescription) this.openSurveyResponseDescription(res.surveyResponseDescription);

    }

    private async openGenericObject(res:{[keys: string]: string}, objectType: string ) {
        let dialogRef: MatDialogRef<GenericMapModalComponent> = this.dialog.open(GenericMapModalComponent, {
            data: {
              map: res,
              objectType,
            },
            minWidth: "500px",
            minHeight: "20vh"
      
        })

        const result: any = await dialogRef.afterClosed().toPromise();

        return result; // behavior is not well-defined yet
    }

    private openLead(res:LeadModel) {
        this.leadService.openLeadUsingModal(res);
    }
    
    private openProject(res: {[keys: string]: string}) {
        this.openGenericObject(res, "Project"); // TODO: we could make this custom for a project
    }
    
    private async openSurveyResponseDescription(res: DashboardAlertModel) {
        const success: boolean = await this.surveyService.openRelatedResponsesModal(res.sessionId);
        if (!success) {
            const generic = {};
            Object.keys(res).forEach(key => generic[key] = res[key])
            this.openGenericObject(generic, "Project");

        }
    }

}
