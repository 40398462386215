import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { Subject } from "rxjs";
import { DashboardAlertModel } from "src/app/cr-dashboard/cr-dashboard.service";
import { adamLog } from "../generic-functions";
import { PollResponse } from "../models/poll.model";
import { MessageModel } from "./messaging.service";
import { UserDetailsService } from "./user-details.service";

@Injectable({
  providedIn: "root",
})
export class MessagingSocketService {
  socketMessages$: Subject<MessageModel> =  new Subject();
  socketReactionsAdded$: Subject<ReactionCommand> =  new Subject();
  socketReactionsRemoved$: Subject<ReactionCommand> =  new Subject();
  socketEditMessage$: Subject<EditMessageCommand> =  new Subject();
  socketDeleteMessage$: Subject<DeleteMessageCommand> =  new Subject();
  socketImageReceived$: Subject<ImageReceivedCommand> =  new Subject();
  socketMarkUserAsRead$: Subject<MarkAsReadCommand> =  new Subject();
  socketRemovePollResponse$: Subject<RemovePollResponseCommand> =  new Subject();
  socketAddPollResponse$: Subject<AddPollResponseCommand> =  new Subject();
  // socketSurveyMetricSummary$: Subject<SurveyMetricSummary> =  new Subject();
  socketDashboardAlertModel$: Subject<DashboardAlertModel> =  new Subject();
  socketSurveyDispositioned$: Subject<{location: string}> =  new Subject();

  
  socketHasBeenDisconnectedLongerThanFiveSeconds: boolean = false;

  constructor(
    private socket: Socket, 
    private userDetailsService: UserDetailsService,
    ){
  }

  initializeSocket(): void {
    this.startFiveSecondDisconnectedTimeout();

    this.socket.connect();

    this.connectToSocketRooms();
    this.setUpSocketListeners();
  }

  async connectToSocketRooms(): Promise<void> {

    const userId = await this.userDetailsService.getUserId();
    const sessionKey = await this.userDetailsService.getSessionKey();
    this.socket.emit(
      "userSubscribe",
      userId + ''
    );

    this.socket.emit(
      "userSubscribe",
      sessionKey + ''
    );
  }

  setUpSocketListeners(): void {
    this.socket.removeAllListeners();
    this.socket.on("disconnect", (event) => {
      adamLog("SOCKET DISCONNECTED!!");
      this.startFiveSecondDisconnectedTimeout();
    });

    this.socket.on("connect", (event) => {
      adamLog("SOCKET CONNECTED!!");
      this.socketHasBeenDisconnectedLongerThanFiveSeconds = false;
    });

    this.socketSubscribe<ReactionCommand>("ADD_REACTION", this.socketReactionsAdded$);
    this.socketSubscribe<ReactionCommand>("REMOVE_REACTION", this.socketReactionsRemoved$);
    
    this.socketSubscribe<MessageModel>("MESSAGE", this.socketMessages$);
    this.socketSubscribe<EditMessageCommand>("EDIT_MESSAGE", this.socketEditMessage$);
    this.socketSubscribe<DeleteMessageCommand>("DELETE_MESSAGE", this.socketDeleteMessage$);
    this.socketSubscribe<ImageReceivedCommand>("IMAGE_MESSAGE_RECEIVED_BY_SERVER", this.socketImageReceived$);
    this.socketSubscribe<MarkAsReadCommand>("MARK_USER_AS_READ", this.socketMarkUserAsRead$);
    this.socketSubscribe<AddPollResponseCommand>("ADD_POLL_RESPONSE", this.socketAddPollResponse$);
    
    this.socketSubscribe<RemovePollResponseCommand>("REMOVE_POLL_RESPONSE", this.socketRemovePollResponse$);
    // this.socketSubscribe<SurveyMetricSummary>("SURVEY_METRIC", this.socketSurveyMetricSummary$);
    this.socketSubscribe<DashboardAlertModel>("SURVEY_RESPONSE_DESCRIPTION", this.socketDashboardAlertModel$);
    this.socketSubscribe<{location: string}>("SURVEY_DISPOSITIONED", this.socketSurveyDispositioned$);
  }

  private socketSubscribe<T>(socketCommandType: string, subscriptionToPush: Subject<T>) {
    this.socket.on(socketCommandType, (data: T) => {
      subscriptionToPush.next(data);
    });
  }

  handleAddReaction(reactionCommand: ReactionCommand) {
    this.socketReactionsAdded$.next(reactionCommand);
  }
  
  handleRemoveReaction(reactionCommand: ReactionCommand) {
    this.socketReactionsRemoved$.next(reactionCommand);
  }

  socketIsConnected(): boolean {
    return this.socket?.ioSocket?.connected
  }

  displayReconnectingBar(): boolean {
    return !this.socketIsConnected() && this.socketHasBeenDisconnectedLongerThanFiveSeconds;
  }

  private startFiveSecondDisconnectedTimeout(): void {
    setTimeout(() => {
      this.socketHasBeenDisconnectedLongerThanFiveSeconds = !this.socketIsConnected();
    }, 5000)
  }

  reconnectIfDisconnected(): void {
    if (this.socketIsConnected()) return;
    console.log("reconnecting socket")
    this.initializeSocket();
  }

  private disconnect(): void {
    this.socket.disconnect();
  }

}

export interface ReactionCommand {
  userId: number;
  reaction: string;
  messageId: number;
  commandType: string;
}

export interface EditMessageCommand {
  userId: number;
  newContents: string;
  messageId: number;
  conversationId: number;
  isLatestMessage: boolean;
}

export interface DeleteMessageCommand {
  messageId: number;
}

export interface ImageReceivedCommand {
  frontendId: string;
}

export interface MarkAsReadCommand {
  userMarkedAsRead: number;
  conversationId: number;
}

export interface RemovePollResponseCommand {
  conversationId: number;
  messageId: number;

  optionId: number;
  userId: number;
}


export interface AddPollResponseCommand {
  conversationId: number;
  messageId: number;

  pollResponse: PollResponse;
}