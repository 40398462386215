
<ion-grid>
  <ion-row>
    <ion-col size="6" *ngFor="let key of tallys.getKeysForTodaysTally()" style="padding: 10px;">
      <enzy-tally-tracker [key]="key" [setList]="tallys.getValueForKeyInTodaysTally(key)" (valueChanged)="handleValueChanged(key, $event)"></enzy-tally-tracker>

    </ion-col>
  </ion-row>
</ion-grid>
<!-- <div *ngFor="let key of tallys.getKeysForTodaysTally()" style="padding: 10px;">
</div> -->


