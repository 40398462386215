import { Platform } from "@ionic/angular";
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Poll, PollOption } from "../../models/poll.model";
import { MessagingService } from "../../services/messaging.service";
import { MatDialog } from "@angular/material";
import { BusySpinnerComponent } from "../busy-spinner/busy-spinner.component";
import { SnackbarService } from "../../services/snackbar.service";

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit {

  poll: Poll;
  endTime: boolean = true;
  conversationId: number;
  pollHasBeenSubmitted: boolean;

  constructor(private platform: Platform,
    public messagingService: MessagingService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private navCtrl: NavController) {
    this.initializePoll();
    this.conversationId = history.state.conversationId;
    this.pollHasBeenSubmitted = false;
    // this.completeDate = new Date();
    // this.localCompleteDate = this.completeDate.toISOString();
    // this.localCompleteDate = this.localCompleteDate.substring(0, this.localCompleteDate.length - 1);
  }

  ngOnInit() { }

  initializePoll() {
    this.poll = this.getBlankPoll();
    // this.completeDate = new Date();
    // this.localCompleteDate = this.completeDate.toISOString();
    // this.localCompleteDate = this.localCompleteDate.substring(0, this.localCompleteDate.length - 1);
  }

  getBlankOption(): PollOption {
    return {
      id: null,
      description: "",
      responses: []
    }
  }

  getBlankPoll(): Poll {
    let today = new Date();
    return {
      id: null,
      title: "",
      question: "",
      options: [this.getBlankOption(), this.getBlankOption()],
      endTime: null,
      multipleAnswers: false,
      isPublic: false
    }
  }

  addOption() {
    this.poll.options.push(this.getBlankOption());
  }

  removeOption(index: number) {
    this.poll.options.splice(index, 1);
  }

  goBack() {
    this.navCtrl.back();
  }


  isIOS() {
    return this.platform.is("ios");
  }

  modelChanged(newObj) {
    // console.log(newObj);
  }

  toggleEndTime() {
    this.endTime = !this.endTime;
  }

  async sendPollMessage() {
    if (this.pollHasBeenSubmitted) {return}

    if (this.poll.title === "" || this.poll.options[0].description === "" || this.poll.options[1].description === "") {
      
      this.snackbarService.displaySnackBarMessage("Missing fields", false);

      return;
    }


    this.pollHasBeenSubmitted = true;
    let busy = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: true
    })
    // console.log("POLL", this.poll);
    const res = await this.messagingService.sendPollMessage(this.conversationId, "", "test_id_adam", this.poll);


     setTimeout(() => {
      this.pollHasBeenSubmitted = false;
    }, 1000)

    // console.log("SEND POLL RESULT", res);
    this.goBack();
    busy.close();

  }

  @ViewChild('datetimePicker', { read: ElementRef, static: false }) private pickerDate: ElementRef;

  openPicker() {
    this.pickerDate.nativeElement.click();
  }

  writeValue(value: any) {
    // console.log('Picked', value);
  }

  testMethod($event) {
    // console.log($event)
  }


}
