<link href="https://fonts.googleapis.com/css?family=Istok+Web&display=swap" rel="stylesheet">

<shared-my-header>
</shared-my-header>

<ion-content>
  <div >
    <div style="max-width: 600px; display: block; margin: auto;">




      <div style="height: 20px"></div>
      <mat-form-field style="width: 80%; display: block; margin: auto;">
        <input matInput placeholder="Name" [(ngModel)]="name" (keyup.enter)="submitName()">
      </mat-form-field>





      <div style="height: 20px;"></div>

      <div *ngIf="this.name !== ''">
        <p style="text-align: center;">Access Code</p>
        <div
          style="box-shadow: inset 0px 0px 17px -9px rgba(0,0,0,0.75);
             width: 80%; height: 50px; border-radius: 5px; background-color:rgba(255,255,255,0.1);font-size: 20px; line-height: 50px; text-align: center; display: block; margin: auto;">
          AbkD3</div>
        <div style="height: 10px;"></div>

        <button mat-raised-button
          style="width: 80%; height: 50px; border-radius: 25px; background-color:#00BCE1; font-size: 20px; font-weight: 10; display: block; margin: auto;"
          (click)="createRep()">Create</button>

      </div>



    </div>

  </div>
</ion-content>