export const LOCAL_SERVER: boolean = false;

export const REST_BASE_URL: string = LOCAL_SERVER ? 'http://localhost:8080/rest' : 'https://enzyhr.com/rest';
export const ENZY_BASE_URL: string = LOCAL_SERVER ? 'http://localhost:8080' : 'https://enzyhr.com';


// export const SOCKET_BASE_URL: string = 'http://sundermessagingserver-env.eba-jwpmh822.us-west-1.elasticbeanstalk.com'
export const SOCKET_BASE_URL: string = 'https://dammitright.sundermessagingserver.com'
// export const SOCKET_BASE_URL: string = 'ws://localhost:3000'


export const APP_VERSION: string = "2.2.7"
export const APP_IS_DEV_MODE: boolean = false;