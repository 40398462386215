<ion-header style="position: sticky; top: 0; z-index: 9999;">
  <ion-toolbar>
    <div slot="start" style="width: 10px;">
    </div>
    <div slot="start">
      <button mat-icon-button (click)="close()">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>
    <ion-title style="text-align: center;"><span>{{mode | titlecase}}</span>  Lead</ion-title>

    <div slot="end">
      <button style="opacity: 0;"  mat-button class="primary-color">
        Edit
      </button>
    </div>

    <div slot="end" style="width: 10px;">
    </div>
  </ion-toolbar>
</ion-header>

<div class="app-background-color" style="padding: 10px; overflow: hidden;">  
  <app-lead-info *ngIf="this.mode === 'view'" [lead]='lead' (submitLead)="saveLeadChanges($event)">
  </app-lead-info>


  <app-leads-form [mode]="this.mode" *ngIf="this.mode === 'new' || this.mode === 'edit'" [lead]='lead' (submitLead)="saveLeadChanges($event)">
  </app-leads-form>
</div>