import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { camelCaseToSentenceCase } from '../../generic-functions';
import { EmployeeStoreService } from '../../services/employee-store.service';
import { TableFilterService } from '../../services/table-filter.service';
import { TableFilterButton } from '../table-filter/table-filter.component';

@Component({
  selector: 'enzy-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
})
export class SearchHeaderComponent implements OnInit {


  @Input() filtersObjects: TableFilterButton[] = [];
  @Output() iconEmitter = new EventEmitter<any>();
  @Output() filterEmitter = new EventEmitter<any>();
  @Output() searchInputEmitter = new EventEmitter<any>();
  @Output() filterClearedEmitter = new EventEmitter<any>();


  icons: string[];
  @Input() set iconsInput(newIcons: string[]) {
    this.icons = newIcons;
    this.setupSelectedHeaderIcons();
  }



  options: SearchHeaderIcon[] = [
    { title: "View", icon: "view_list", primaryButton: false },
    { title: "Settings", icon: "settings", primaryButton: false },
    { title: "Filters", icon: "filter_alt", primaryButton: false, hasBadge: true },
    { title: "Drag", icon: "low_priority", primaryButton: false },
    { title: "Add", icon: "add", primaryButton: true }
  ]

  selectedIcons: SearchHeaderIcon[] = [];


  constructor(public tableFilterService: TableFilterService, public employeeStoreService: EmployeeStoreService) {

  }

  ngOnInit() {
    this.getSelectedFilters();
  }

  setupSelectedHeaderIcons() {
    this.icons.forEach(icon => {
      let index = this.options.findIndex(i => i.title === icon);
      let index2 = this.selectedIcons.findIndex(i => i.title === icon)

      if (index >= 0 && index2 < 0) {
        this.selectedIcons.push(this.options[index]);
      }
    })

    this.getSelectedFilters();
  }




  //SEARCH METHODS
  searchValue: string = "";
  searchOn: boolean = true;

  @ViewChild("searchInput") searchInput: ElementRef;

  turnOnSearch() {
    this.searchOn = true;

    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    },
      100);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchInputEmitter.emit("");
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputEmitter.emit(filterValue);
  }


  handleAction(icon: SearchHeaderIcon) {
    if (icon.icon === "view_list") {
      icon.icon = "grid_view"
    } else if (icon.icon === "grid_view") {
      icon.icon = "view_list"
    }
    if (icon.icon === "low_priority") {
      icon.icon = "done"
    } else if (icon.icon === "done") {
      icon.icon = "low_priority"
    }

    this.iconEmitter.emit(icon);
  }


  openFilters() {
    this.filterEmitter.emit();
  }

  getIcon(icon: SearchHeaderIcon) {
    return icon.icon;
  }
  selectedFilters: TableFilterButton[] = [];

  getSelectedFilters() {

    this.filtersObjects.forEach(filter => {
      let index = this.selectedFilters.findIndex(f => f.label === filter.label);

      if (filter.selectedValue && index < 0) {
        this.selectedFilters.push(filter)
      }
    })
    return this.selectedFilters
  }

  removeFilter(filter: TableFilterButton) {
    // this.getSelectedFilters();
    let index = this.selectedFilters.findIndex(f => f.label === filter.label);
    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
      this.filterClearedEmitter.emit(filter)
    }
  }

  getCorrectFormatForSelectedValue(filter: TableFilterButton) {
    if (filter.type === "boolean" || filter.type === "emptyObjectKey") { return "" }
    if (filter.type === 'employeeLookup') {
      return ": " + this.getEmployeeFormat(filter)
    }
    else {
      return ": " + this.generalFormat(filter)
    }
  }


  getEmployeeFormat(filter: TableFilterButton) {
    let displayValue = "";
    filter.selectedValue.forEach((val,index) => {
      displayValue += this.employeeStoreService.getUserName(+val)
      if (index !== filter.selectedValue.length - 1) { displayValue += ", " }
    })

    return displayValue
  }

  generalFormat(filter: TableFilterButton) {
    let displayValue = "";
    filter?.selectedValue?.forEach((val, index) => {
      displayValue += this.getSentenceCase(val) 
      if (index !== filter.selectedValue.length - 1) { displayValue += ", " }
     
    })

    return displayValue
  }


  getSentenceCase(option: string) {
    return camelCaseToSentenceCase(option)
  }
}


export class SearchHeaderIcon {
  title: string;
  icon: string;
  primaryButton: boolean;
  hasBadge?: boolean = false;
}