<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<shared-my-header>

</shared-my-header>
<ion-content>
  <div style="max-width: 600px; display: block; margin: auto; padding: 10px; padding-top: 20px">



    <ion-grid class="section-div" style="padding: 10px;">
      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">First Name</ion-label>
        <input style="width: 100%; text-align: right; border: none;" matInput [(ngModel)]='this.firstName' autocomplete="off"
          type="text" />
      </ion-item>


      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Last Name</ion-label>
        <input style="width: 100%; text-align: right; border: none;" matInput [(ngModel)]='this.lastName' autocomplete="off"
          type="text" />
      </ion-item>


      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Email</ion-label>
        <input style="width: 100%; text-align: right; border: none;" matInput [(ngModel)]='this.emailAddress' autocomplete="off"
          type="text" />
      </ion-item>

      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Phone</ion-label>
        <input appPhoneMask style="width: 100%; text-align: right; border: none;" matInput [(ngModel)]='this.phoneNumber'
          autocomplete="off" type="text" />
      </ion-item>


      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Team</ion-label>
        <input style="width: 100%; text-align: right; border: none;" type="text" aria-label="Number" matInput [formControl]="teamControl" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </ion-item>

      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">SCAR</ion-label>
        <div style="width: 100%;">
          <mat-checkbox  [(ngModel)]="scarProgram" style="float: right; margin-right: 20px;" ></mat-checkbox>
        </div>
      </ion-item>

      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Note</ion-label>
        <div style="width: 100%;">
          <input style="width: 100%; text-align: right; border: none;" matInput [(ngModel)]='this.note'
          autocomplete="off" type="text" />
        </div>
      </ion-item>

      <ion-item class="item-background-color">
        <ion-label class="opaque-text" position="fixed">Recruiter</ion-label>
        <div style="width: 100%;">
          <button mat-button class="primary-color" (click)="changeAssignedUsers(); $event.stopPropagation()" style="float: right; border-radius: 30px;">
          {{getEmployeeName(recruiterId)}} <span class="material-icons">
            arrow_drop_down
            </span>
        </button>
        </div>
      </ion-item>

     
    </ion-grid>

    <div style="height: 20px;"></div>


    <button mat-raised-button class="primary-color" style="width: 100%; height: 50px; border-radius: 25px; font-size: 20px;   
      display: block; margin: auto;" (click)="sendInvite()">
      Send Invitation

    </button>


  </div>


  <div *ngIf='getInvitations().length > 0' style="max-width: 600px; display: block; margin: auto; padding: 10px">
    <div style="text-align: left; padding-bottom: 10px; padding-top: 20px;padding-left: 10px;">
      My Invites
    </div>

    <app-invitation-table (onEdit)="edit($event)" (onReinvite)="reinvite($event)" [dataInput]='this.getInvitations()'
      class="section-div"></app-invitation-table>
  </div>

</ion-content>
<app-my-footer>

</app-my-footer>