import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { fileToResourceFile } from 'src/app/shared/file-functions';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { Series } from 'src/app/shared/models/library.model';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AddNewModalComponent } from '../../admin-library/add-new-modal/add-new-modal.component';
import { Section } from '../../library.component';
import { VideoSectionCardComponent } from '../video-section-card/video-section-card.component';

@Component({
  selector: 'app-series-section-card',
  templateUrl: './series-section-card.component.html',
  styleUrls: ['./series-section-card.component.scss'],
})
export class SeriesSectionCardComponent implements OnInit {
  series: Series;
  sections: Section[] = [];

  constructor(public busySpinner: BusySpinnerService, public snackbarService: SnackbarService, public bottomSheet: MatBottomSheet, public libraryService: LibraryService, public dialogRef: MatDialogRef<SeriesSectionCardComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.sections = this.libraryService.allSections;

    if (this.data?.series) {
      this.series = this.data.series;
      console.log(this.series)
    } else {
      this.series = {
        title: "",
        backgroundImage: "",
        resources: [],
        id: Math.random() + "",
        section: ""
      }
    }


    if (this.data?.section) {
      this.series.section = this.data.section;
      let tempSection: Section = {
        sectionTitle: this.data.section,
        resourceType: this.libraryService.selectedResourceType,
        videosMap: null
      }
      this.sections.push(tempSection)
    }

  }

  ngOnInit() { }

  async create() {
    if (this.series.resources.length === 0) {

      this.snackbarService.displaySnackBarMessage("You must have add at least 1 video.", false);

      return;
    }

    this.busySpinner.start();

    let res = await this.libraryService.setSeries(this.series.title, this.libraryService.selectedResourceType, this.series.backgroundImageFile, this.series.backgroundImage);
    console.log(res)

    if (this.series.resources) {
      this.series.resources.forEach(async (video, index) => {
        console.log(video)
        let result = await this.libraryService.setResourceFile(video.title, video.videoFile, video.videoUrl, video.thumbnailFile, video.thumbnailUrl, video.trainerName, video.category, video.section, video.resourceType);

        if (!result.success) {
          this.busySpinner.stop();

          this.snackbarService.displaySnackBarMessage("There was an error. Please try again.", false);

          return;
        }

        if (index === this.series.resources.length) {
          this.busySpinner.stop();

          this.dialogRef.close(this.series)
        }
      })
    }
    this.busySpinner.stop();

    this.dialogRef.close(this.series)
  }
  cancel() {
    this.dialogRef.close();
  }

  // getFileReader(): FileReader {
  //   const fileReader = new FileReader();
  //   const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
  //   return zoneOriginalInstance || fileReader;
  // }

  selectedFile: any;

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];

    this.series.backgroundImageFile = this.selectedFile;

    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.series.backgroundImage = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(event.target.files[0]);

  }


  addNewVideo() {
    let dialogRef = this.bottomSheet.open(AddNewModalComponent);

    dialogRef.afterDismissed().subscribe((res: {videos: File[], command: string}) => {
      if (!res) { return }
      if (res?.command === "addVideoFromLink") {
        let ref = this.dialog.open(VideoSectionCardComponent, {
          data: {
            section: this.series.section,
            category: this.series.title,
          }
        });

        ref.afterClosed().subscribe(res2 => {
          if (res2) {
            this.series.resources.push(res2)
          }
        })
      }
      else {
        const resourceFiles: ResourceFile[] = res.videos.map(f => fileToResourceFile(f))
        resourceFiles.forEach((element: ResourceFile) => {
          element.section = this.series.section;
          element.category = this.series.title;
          this.series.resources.push(element);
        });

      }
    })
  }


  deleteVideo(video: ResourceFile) {
    const index: number = this.series.resources.indexOf(video);
    if (index !== -1) {
      this.series.resources.splice(index, 1);
    }
  }


  editVideo(video: ResourceFile) {
    let ref = this.dialog.open(VideoSectionCardComponent, {
      data: {
        video: video
      }
    });
  }


  getSectionOptions() {
    let res = [];
    this.sections.forEach(s => {
      if (this.libraryService.selectedResourceType.toLowerCase().includes(s.resourceType)) {
        res.push(s)
      }
    });
    return res
  }

}

