<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchCase="this.type === 'chip'">
    <span class="opaque-div circle" style="object-fit: cover; margin-top: -10px; margin-left: 5px; border-radius: 50%;">

      <span *ngIf="!rowHasImage()" class="initials" > {{getInitials()}}</span>
      <img style="object-fit: cover; border-radius: 50%;" *ngIf="rowHasImage()" src={{getImageUrl()}} />

    </span>
  </ng-container>
  
  <ng-container *ngSwitchCase="this.type === 'small'">
    <span class="opaque-div circle" style="object-fit: cover; margin-top: -10px;">

      <span *ngIf="!rowHasImage()" class="initials"> {{getInitials()}}</span>
      <img style="object-fit: cover;" *ngIf="rowHasImage()" src={{getImageUrl()}} />

    </span>
  </ng-container>

  <ng-container *ngSwitchCase="this.type === 'large'">
    <span (click)="show()" class="large-circle opaque-div" style="object-fit: cover; margin-top: -10px;">

      <span *ngIf="!rowHasImage()" class="initials"> {{getInitials()}}</span>
      <img style="object-fit: cover;" *ngIf="rowHasImage()" src={{getImageUrl()}} />
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="this.type === 'xl'">

    <span (click)="show()" class="opaque-div"
      style="object-fit: cover; height: 100px; width: 100px;  border-radius: 50%; display: block; margin: auto;">

      <div *ngIf="!rowHasImage()" class="gridInitials"> {{getInitials()}}</div>
      <img class="xlImage" *ngIf="rowHasImage()" src={{getImageUrl()}} />

    </span>
  </ng-container>

  <ng-container *ngSwitchCase="this.type === 'grid'">
    <span class="opaque-div"
      style="object-fit: cover; height: 100px; width: 100px; float: left; border-radius: 50%;">

      <div *ngIf="!rowHasImage()" class="gridInitials"> {{getInitials()}}</div>
      <img  class="gridImage" *ngIf="rowHasImage()"
        src={{getImageUrl()}} />

    </span>
  </ng-container>

  <ng-container *ngSwitchCase="this.type === 'gridSmall'">
    <span class="opaque-div"
      style="object-fit: cover; height: 75px; width: 75px; float: left; border-radius: 50%; ">

      <div *ngIf="!rowHasImage()" class="gridInitialsSmall"> {{getInitials()}}</div>
      <img  class="gridImage" *ngIf="rowHasImage()"
        src={{getImageUrl()}} />

    </span>
  </ng-container>


  <ng-container *ngSwitchCase="this.type === 'pinned'">
    <span class="opaque-div"
      style="object-fit: cover; height: 75px; width: 75px; border-radius: 50%; display: block; margin: auto;">

      <div *ngIf="!rowHasImage()" class="gridInitialsSmall"> {{getInitials()}}</div>
      <img  class="gridImage" *ngIf="rowHasImage()"
        src={{getImageUrl()}} />

    </span>
  </ng-container>

</ng-container>