<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div class="section-div" (click)="showAdvancedAnalytics()" style="width: 100%; height: 300px;">

  <ion-toolbar class="transparent-bg" style="height: 75px; ">
    <div slot="start">
      <div style="width: 15px;"></div>
    </div>
    <div slot="start" style="text-align: left;">
      <h1 style="font-size: 18px; margin-bottom: 5px; margin-top: 10px;  height: auto !important;">{{getTitle()  }}</h1>
      <p class="opaque-text" style="margin: 0; font-size: 14px;">{{getCategory()}}</p>
    </div>

    <div slot="end">
      <mat-spinner *ngIf="!this.compInitialzed" [diameter]="30"></mat-spinner>
    </div>

    <div slot="end" *ngIf="this.inDragMode">
      <span class="material-icons opaque-text">
        drag_indicator
      </span>
    </div>

    <div slot="end">
      <div style="width: 15px;"></div>
    </div>
  </ion-toolbar>


  <ion-row style="padding: 0; margin: 0; width: 100%; min-height: 170px; max-height: 170px; overflow: hidden;">

    <ion-col size="12" style="text-align: center; padding: 0px; ">
      <div *ngIf="report.reportCellList.list !== undefined">
        <div *ngIf="report.reportCellList.list?.length === 0">
          <span style="line-height: 200px;"> No Results </span>
        </div>
      </div>

      <div *ngIf="this.isSpecificViewType('column')">
        <div style="padding: 10px">
          <app-custom-bar-chart [reportCellList]="report.reportCellList" [isDashboard]="true"> </app-custom-bar-chart>
        </div>
      </div>

      <div [hidden]="!this.isSpecificViewType('pie')">
        <enzy-pie-chart [dataInput]="this.parseData(this.report.reportCellList)"
          [pieChartConfig]="this.getPieChartConfig()"></enzy-pie-chart>
      </div>

      <!-- <div style="height: 150px" *ngIf="this.isSpecificViewType('map')">
        <app-employee-map [employees]="employeeList" [showToggle]="false"></app-employee-map>
      </div> -->

      <div *ngIf="this.isSpecificViewType('leaderboard')">
        <ion-col size="12" style="text-align: center; padding: 0px; ">
          <div style="height: 30px"></div>
          <app-expandable-leaderboard [isDashboard]='true' [reportCellList]='this.report.reportCellList'>
          </app-expandable-leaderboard>

        </ion-col>
        <div style="height:10px"> </div>


      </div>

      <div *ngIf="this.isSpecificViewType('line')" style="padding: 10px">
        <enzy-line-chart [dataInput]="this.parseData(this.report.reportCellList)"
          [lineChartConfig]="this.getLineChartConfig()"></enzy-line-chart>
      </div>


      <div *ngIf="this.isSpecificViewType('table')">
        
        <div [hidden]="report.reportCellList.list && report.reportCellList.list?.length === 1">
          <div *ngIf="report.reportCellList">
            <div style="padding: 0px;">
              <div *ngIf="report.reportCellList.list !== undefined">

                <div *ngIf="report.reportCellList.list?.length !== 0">
                  <app-drill-down-card [reportCellList]='report.reportCellList' [reportMenu]='report.reportMenu'>
                  </app-drill-down-card>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="report.reportCellList.list">
          <div *ngIf="report.reportCellList.list?.length === 1">
            <h1 style="font-size: 42px; line-height: 200px;  text-align: center;  margin: 0;"
              > {{report.reportCellList.list[0].cellList[1].value}} </h1>
          </div>
        </div>
      </div>


    </ion-col>
  </ion-row>

  <ion-toolbar class="transparent-bg">
    <div slot="start">
      <div style="width: 15px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button class="filter-button" style="width: 40px;" (click)="$event.stopPropagation(); handleDownload()">
        <span class="material-icons">
          file_download
        </span>
      </button>
    </div>
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>

    <div slot="start">
      <button mat-icon-button class="filter-button" style="width: 40px;" (click)="$event.stopPropagation(); handleFavorite()" >
        <span class="material-icons" *ngIf="this.report.freshReport.isFavorite">
          favorite
        </span>

        <span class="material-icons" *ngIf="!this.report.freshReport.isFavorite" (click)="$event.stopPropagation(); handleFavorite()">
          favorite_border
        </span>
      </button>
    </div>

  </ion-toolbar>

</div>
