<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div style=" overflow-y: hidden; height: 100vh;">


  <ion-header no-border class="whiteHeader">
    <ion-toolbar no-border class="whiteHeader">

      <button mat-fab class="circle-button"
        style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent;"
        (click)="goBack()">
        <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
      </button>

      <div style=" padding-bottom: 0; margin: 0; ">
        <ion-grid style="padding: 0; margin-top: -20px;">



          <ion-row style="padding: 0; margin: 0;">
            <h1 style="text-align: center; color: white; margin: 0; width: 100%; font-size: 22px;">Create New
              Competition</h1>
          </ion-row>
          <!-- 
        <ion-row style="padding: 0; margin: 0;">
          <p style="text-align: center; color: rgba(255,255,255, 0.6); margin: 0; font-weight: 100; padding-top: 5px; width: 100%; font-size: 14px;"> {{selectedDataSetOption}} &nbsp;| &nbsp;{{selectedTimePeriodOptions}} </p>
        </ion-row> -->
        </ion-grid>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="darkTheme" style="padding: 20px;">

      <mat-form-field style="width: 100%;">
        <input style="color:white;" matInput placeholder="Title" [(ngModel)]="title">
      </mat-form-field>




      <ion-grid style="margin: 0; padding: 0">
        <ion-row style="margin: 0; padding: 0">
          <ion-col size="10">
            <h1 style="color: white; margin: 0; padding: 0;">Regular Season</h1>
          </ion-col>
          <ion-col size="2">
            <mat-slide-toggle [(ngModel)]="regularSeason"> </mat-slide-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>
      
    




        <div *ngIf="regularSeason">
          <button class="filter-button-long" mat-button (click)="openTeams()">
            <span class="zeroLineHeight" style="color: rgba(255,255,255,0.7)">Selected Teams: &nbsp;</span>
  
            <div class="zeroLineHeight" style="color: white"  *ngFor="let team of this.selectedTeams">
              {{team}}
            </div>
  
          </button>

          <div style="height: 30px"></div>

          <mat-form-field style="width: 100%;">
            <input style="color:white;" matInput placeholder="Regular Season Length (Weeks)" [(ngModel)]="regularSeasonLength">
          </mat-form-field>

  
        </div>
       


        <ion-grid style="margin: 0; padding: 0">
          <ion-row style="margin: 0; padding: 0">
            <ion-col size="10">
              <h1 style="color: white; margin: 0; padding: 0;">Post Season</h1>
            </ion-col>
            <ion-col size="2">
              <mat-slide-toggle [(ngModel)]="postSeason"> </mat-slide-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div *ngIf="postSeason" >
          <p style="color: white; margin: 0; padding: 0;">Size Of Tournament</p>

          <div style="height: 5px;"></div>
          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;">
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 2, 'filter-button' : this.tournamentSize !== 2}"  mat-button (click)="updateTournamentSize(2)">
    
                <span class="zeroLineHeight">2</span>
                
              </button>
            </div>
    
            <div style="padding: 5px;"></div>
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 4, 'filter-button' : this.tournamentSize !== 4}"  mat-button (click)="updateTournamentSize(4)">
    
                <span class="zeroLineHeight">4</span>
                
              </button>
            </div>
    
    
    
            <div style="padding: 5px;"></div>
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 8, 'filter-button' : this.tournamentSize !== 8}"  mat-button (click)="updateTournamentSize(8)">
    
                <span class="zeroLineHeight" >8</span>
                
              </button>
            </div>
    
    
            <div style="padding: 5px;"></div>
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 16, 'filter-button' : this.tournamentSize !== 16}"  mat-button (click)="updateTournamentSize(16)">
    
                <span class="zeroLineHeight">16</span>
                
              </button>
            </div>

            <div style="padding: 5px;"></div>
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 32, 'filter-button' : this.tournamentSize !== 32}"  mat-button (click)="updateTournamentSize(32)">
    
                <span class="zeroLineHeight" >32</span>
                
              </button>
            </div>

            <div style="padding: 5px;"></div>
    
            <div>
              <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 64, 'filter-button' : this.tournamentSize !== 64}"  mat-button (click)="updateTournamentSize(64)">
    
                <span class="zeroLineHeight">64</span>
                
              </button>
            </div>
    
            <div style="padding: 10px;"></div>
          </div>
        </div>
   


        <div style="height: 100px"></div>
        <button style="width: 100%; background-color: #00BCE1; color: white; width: 100%;" mat-button (click)="create()"> Create </button>

    </div>
  </ion-content>
</div>