import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { ResourceFile } from '../../models/help.model';

@Component({
  selector: 'app-grid-carousel',
  templateUrl: './grid-carousel.component.html',
  styleUrls: ['./grid-carousel.component.scss'],
})
export class GridCarouselComponent implements OnInit {
  @Input() trainingVideos: ResourceFile[];

  @Input() title: string;
  constructor(public dialog: MatDialog) { }

  ngOnInit() {}

  openVideoMessage(video: string){
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        video: video,
      }
    });
  }

}
