import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { REST_BASE_URL } from "../models/constants";
import { UserDetailsService } from "./user-details.service";

@Injectable({
    providedIn: 'root'
})
export class ConversationService {


    constructor(private http: HttpClient, public userDetailsService: UserDetailsService) {

    }

    public async addParticipants(conversationId: number, participantIds: string[]): Promise<any> {
      const sessionKey: string = await this.userDetailsService.getSessionKey();
      let url: string = REST_BASE_URL + '/conversations/addParticipants/' + sessionKey;

      let body: any = new FormData();
      body.append('participantIds', participantIds);
      body.append('conversationId', '' + conversationId);

      return this.http.post(url, body).toPromise();
    }

    public async deleteParticipant(conversationId: number, participantId: string): Promise<any> {
      const sessionKey: string = await this.userDetailsService.getSessionKey();
      let url: string = REST_BASE_URL + '/conversations/deleteParticipant/' + sessionKey;

      let body: any = new FormData();
      body.append('participantId', participantId);
      body.append('conversationId', '' + conversationId);

      return this.http.post(url, body).toPromise();
    }

    public async setConvoImage(conversationId: number, convoImageFile: any): Promise<any> {
      const sessionKey: string = await this.userDetailsService.getSessionKey();
      let url: string = REST_BASE_URL + '/conversations/updateImage/' + sessionKey;

      let body: any = new FormData();
      body.append('imageFile', convoImageFile, convoImageFile.name);
      body.append('conversationId', '' + conversationId);

      return this.http.post(url, body).toPromise();
    }


  public async setConversationMuted(conversationId: number, isMuted: boolean): Promise<any> {
    if(!conversationId) return;
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/conversations/setConversationMuted/' + sessionKey;

    let body = new FormData();
    body.append('convoId', conversationId + '');
    body.append('muted', isMuted + '');

    return this.http.post<any>(url, body).toPromise();

  }

  public async setConversationPinned(conversationId: number, isPinned: boolean): Promise<any> {
    if(!conversationId) return;
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/conversations/setConversationPinned/' + sessionKey;

    let body = new FormData();
    body.append('convoId', conversationId + '');
    body.append('pinned', isPinned + '');

    return this.http.post<any>(url, body).toPromise();

  }
}