import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { title } from 'process';
import { newTallyDataPoint, TallyDataPoint } from '../tallys';

@Component({
  selector: 'enzy-tally-tracker',
  templateUrl: './tally-tracker.component.html',
  styleUrls: ['./tally-tracker.component.scss'],
})
export class TallyTrackerComponent implements OnInit {

  list: TallyDataPoint[];

  @Input() key: string;

  @Input() set setList(_list: TallyDataPoint[]) {
    this.list = _list;
  }

  @Output() valueChanged: EventEmitter<TallyDataPoint[]> = new EventEmitter<TallyDataPoint[]>();

  constructor() { }

  ngOnInit() {}

  getValue(): number {
    return this.list.length;
  }

  getTitle(): string {
    return this.key;
  }

  increment() {
    this.list.push(newTallyDataPoint(this.key));
    this.valueChanged.emit(this.list);
  }
  
  decrement() {
    if (this.getValue() === 0) {return}
    this.list.pop();
    this.valueChanged.emit(this.list);
  }

  openMessageOptions(){
    console.log("test")
  }
}
