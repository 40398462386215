<ion-header no-border class="no-padding-header ion-no-border">
  <div style="width: 100%;">
    <ion-toolbar class="no-padding-header  ">
      <div slot="start">
        <button mat-fab class="app-text-color"
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>
    </ion-toolbar>
  </div>
</ion-header>
<ion-content>


  <div class="no-context-menu app-background-color app-text-color" style="max-width: 1200px; display: block; margin: auto;">
    <div style="height: 10px;"></div>
    <h1 style="text-align: center; margin: 0; font-size: 18px;"> New Event</h1>


    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <mat-form-field style="width: 100%;" floatLabel='never'>
            <input matInput [(ngModel)]="eventName" placeholder="Event Name"
              style="width: 100%; font-weight: 100;">
          </mat-form-field>

        </ion-col>

      </ion-row>


      <!-- <mat-divider></mat-divider> -->

      <ion-row>
        <ion-col size="4">
          <span style='line-height: 65px;'>Start Time</span>
        </ion-col>

        <ion-col size="8">

          <ion-item>
            <ion-label>Date</ion-label>
            <ion-datetime style="text-align: right;"  placeholder="Select Date"></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label>Time</ion-label>
            <ion-datetime style="text-align: right;" display-format="h:mm A" picker-format="h:mm A"
              placeholder="Select Time" ></ion-datetime>
          </ion-item>

        </ion-col>

      </ion-row>


      <!-- <mat-divider></mat-divider> -->

      <ion-row>
        <ion-col size="4">
          <span style='line-height: 65px;'>End Time</span>
        </ion-col>

        <ion-col size="8">
          <ion-item>
            <ion-label>Date</ion-label>
            <ion-datetime style="text-align: right;"  placeholder="Select Date"></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label>Time</ion-label>
            <ion-datetime style="text-align: right;" display-format="h:mm A" picker-format="h:mm A"
              placeholder="Select Time" ></ion-datetime>
          </ion-item>

        </ion-col>

      </ion-row>


      <!-- <mat-divider></mat-divider> -->

      <ion-row>
        <ion-col size="4">
          <span style='line-height: 65px;'>All Day</span>

        </ion-col>

        <ion-col size="8">
          <div style="height: 20px;"> </div>
          <mat-slide-toggle style="float: right;"></mat-slide-toggle>
        </ion-col>

      </ion-row>

      <!-- <mat-divider></mat-divider> -->
      <ion-row>
        <ion-col size="12">
          <mat-form-field style="width: 100%;" floatLabel='never'>
            <input matInput [(ngModel)]="location" placeholder="Location"
              style="width: 100%; font-weight: 100;">
          </mat-form-field>

        </ion-col>

      </ion-row>
      <!-- <mat-divider></mat-divider> -->

      <div style="height: 20px;"></div>


      <button style="display: block; margin: auto;" mat-button class="primary-color">Create</button>


    </ion-grid>

  </div>
</ion-content>