import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { REST_BASE_URL } from '../../models/constants';
import { UserDetailsService } from '../../services/user-details.service';
import { FooterStoreService } from './footer-store.service';

@Injectable()
export class FooterService {


    constructor(public userDetailsService: UserDetailsService, public router: Router, private http: HttpClient, public footerStoreService: FooterStoreService) {
    }


    public async setFooterTabs() {
        let result = await this.getFooterTabs();

        let tabs = result.tabs;
        await this.footerStoreService.setfooterTabs(tabs);
    }


    public async getFooterTabs(): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const userId = await this.userDetailsService.getUserId();
        let url: string = REST_BASE_URL + '/footer/' + sessionKey;
        url += "?userId=" +userId;
        return this.http.get<any[]>(url).toPromise();
    }

}


export class FooterTab {
    title: string;
    icon?: string;
    iconImageUrl?: string;
    routerUrl: string;
    position: number;

}

export function footerTabSortComparator(a: FooterTab, b: FooterTab): number {
    if (a.position === b.position) return 0;
    return a.position < b.position ? -1 : 1;
  }