import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';
import { CRDashboardService, ICRDashboardService, DashboardAlertModel } from '../../cr-dashboard.service';

@Component({
  selector: 'enzy-dashboard-alert-card',
  templateUrl: './dashboard-alert-card.component.html',
  styleUrls: ['./dashboard-alert-card.component.scss'],
})
export class DashboardAlertCardComponent implements OnInit {
  @Input() alert: DashboardAlertModel;
  @Output() onSurveyWasDispositioned = new EventEmitter<boolean>();
  @Input() largeFont: boolean;

  @Input() shouldShowIcon: boolean = true;

  constructor(
    public crDashboardService: ICRDashboardService,
    public dateFormatService: DateFormatService
  ) {

  }

  ngOnInit() {
  }

  isAlertSurvey(survey: DashboardAlertModel) {
    return survey?.dispositionable && !survey?.dispositioned
  }

  greenResponse(survey: DashboardAlertModel) {
    return survey?.dispositionable && survey?.dispositioned
  }

  async disposition() {

    if ((this.alert.surveyId === 11 || this.alert.surveyId === 12 || this.alert.surveyId === 13)
      && this.alert.response?.includes("Accuracy")) {
      this.crDashboardService.openAccuracyDetails(this.alert);
      // console.log("Accuracy....", details);
      return
    }

    if (!this.alert.dispositionable) { return }
    if (this.alert.dispositionable && this.alert.dispositioned) { return }
    // this.survey.dispositioned = true;
    const success: boolean = await this.crDashboardService.dispositionSurvey(this.alert);
    if (success) this.alert.dispositioned = true;
    this.onSurveyWasDispositioned.emit(true);
  }

  getSurveyTimeStamp() {
    return this.alert?.timeStamp ? genericHumanFriendlyTime(this.alert?.timeStamp) : "No Issues";
  }
}
