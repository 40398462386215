import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { DataReportMenu } from 'src/app/shared/models/dashboard-report-list.model';
import { DashboardData, UserData } from 'src/app/shared/models/dashboard.model';
import { TwoTeamMatchup, Incentive, Team } from 'src/app/shared/models/incentives.model';
import { ReportCellList } from 'src/app/shared/models/report-cell-list.model';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { Storage } from '@ionic/storage';
import { StratFieldEnum } from 'src/app/shared/enums/stratFieldEnum';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss'],
})
export class TeamDetailComponent implements OnInit {

  id: string;

  leaderboardReport: DashboardData;
  team: Team;
  selectedOption: string = "People"

  foundGames: TwoTeamMatchup[] = [];
  sessionKey: string = "";

  constructor(public userDetailsService: UserDetailsService, public router: Router, private nav: HeaderService, public incentivesService: IncentivesService, private reportsService: ReportsService, private localStorage: Storage) {
    this.team = history.state.team;

    this.foundGames = this.incentivesService.findGamesForSpecificTeam(this.team.name);


   this.initUserDataValues();
    
  }

  async initUserDataValues(){
    this.sessionKey = await this.userDetailsService.getSessionKey();

    this.loadLeaderboard();

  }


  ionViewWillLeave(){
    
  }

  ionViewWillEnter() {
    

    ;
  }

  ngOnInit() {
    this.team = history.state.team;

  }


  goBack() {
    this.router.navigate(['game-detail']);
  }

  selectOption(option: string){
    this.selectedOption = option;
  }


  private loadLeaderboard(): void {

    this.reportsService.getDefaultDataReportMenu(this.sessionKey).subscribe((reportMenu: DataReportMenu) => {

      if (reportMenu.errorMessage !== null) {
      } else {
        this.leaderboardReport = {
          reportCellList: new ReportCellList(), 
          reportOrder: -1, 
          freshReport: {
            id: 2, objectIndex: 0, reportName: "Leaderboard",
            stratField: "",
            sortName: "",
            sortDesc: false,
            shareType: "",
            companyId: 0,
            userId: 0,
            isEditable: true,
            viewType: "Table",
            isFavorite: false, 
            reportCategory: null,
            reportId: null,
          },
          reportMenu: reportMenu,
          hasBeenSaved: false,
          viewFilter: 'Table'
        }

        this.leaderboardReport.reportMenu.viewType = "Leaderboard";
        this.leaderboardReport.reportMenu.selectedStratField = StratFieldEnum.SalesRepTeam; 

        this.reportsService.getDataReportList(this.leaderboardReport.reportMenu, null, true).then((reportList: any) => {
          this.leaderboardReport.reportMenu = reportList.menu;

          if (this.leaderboardReport.reportMenu !== null) {

            this.setReportMenuSelectedOptions(this.leaderboardReport);
            this.reorderSelectedReportShowOptions(this.leaderboardReport);

            this.changeSortBy("Project Count (Descending)");

            this.leaderboardReport.reportMenu.filterDateList[0].timePeriodValue = "lastQuarter";

            this.reportsService.getDataReportList( this.leaderboardReport.reportMenu, 0, true, true).then((reportList: any) => {
              this.leaderboardReport.reportCellList = reportList;
              // this.highLightedRowName = (reportList.highlightedValue) ? reportList.highlightedValue : this.highLightedRowName;
              // this.selectedTimePeriodOptions = this.leaderboardReport.reportMenu.timePeriodMap.lastQuarter


              // this.setPaginationDataStart();

              // this.initialLoadRef.close();


              // this.updateReportsTimePeriod("Last Quarter");

            });

          } else {
            // this.initialLoadRef.close();
          }
        });
      }


    });
  }

  private reorderSelectedReportShowOptions(report: DashboardData): void {
    let first = report.reportMenu.selectedStratField
    report.reportMenu.showOptionList.sort((a, b) => {

      return a.field === first ? -1 : b.field === first ? 1 : 0;
    })
  }

  private setReportMenuSelectedOptions(report: DashboardData) {
    const groupByField: string = report.reportMenu.selectedStratField;
    report.reportMenu.showOptionList.forEach(item => {
      item.isSelected = false;
      if (item.text === "Project Count" || item.field === groupByField) {
        item.isSelected = true;
      }
    })
  }

  private changeSortBy(newSortBy: string): void {
    let optionDescription = newSortBy;

    const ascendingString: string = " (Ascending)";
    const descendingString: string = " (Descending)";

    let isDescending: boolean = optionDescription.endsWith(descendingString);

    const amountToTrim: number = (isDescending) ? descendingString.length : ascendingString.length
    let sortOption: string = optionDescription.slice(0, optionDescription.length - amountToTrim);

    if (this.leaderboardReport.reportMenu !== null) {
      this.leaderboardReport.reportMenu = this.reportsService.changeMenuToSortByOption(this.leaderboardReport.reportMenu, sortOption, isDescending);
    }

  }


  


}
