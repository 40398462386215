import { L, O } from '@angular/cdk/keycodes';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatPaginator, MatSort, MatTableDataSource, MatBottomSheet } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { fileToResourceFile } from 'src/app/shared/file-functions';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { Series } from 'src/app/shared/models/library.model';
import { LibraryService } from 'src/app/shared/services/library.service';
import { AddSectionComponent } from '../add-section/add-section.component';
import { SeriesSectionCardComponent } from '../add-section/series-section-card/series-section-card.component';
import { VideoSectionCardComponent } from '../add-section/video-section-card/video-section-card.component';
import { Section } from '../library.component';
import { AddNewModalComponent } from './add-new-modal/add-new-modal.component';




@Component({
  selector: 'app-admin-library',
  templateUrl: './admin-library.component.html',
  styleUrls: ['./admin-library.component.scss'],
})
export class AdminLibraryComponent implements OnInit {

  displayedColumns_resources: string[] = ['thumbnailUrl', 'title', 'trainerName', 'section', 'category', 'resourceType', 'status',];
  dataSource_resources: MatTableDataSource<ResourceFile>;
  @ViewChild('paginatorResources', { read: MatPaginator }) paginator_resources: MatPaginator;
  @ViewChild('sortResources', { read: MatSort }) sort_resources: MatSort;


  displayedColumns_categories: string[] = ['backgroundImage', 'title', 'section', 'videos'];
  dataSource_categories: MatTableDataSource<Series>;
  @ViewChild('paginatorCategories', { read: MatPaginator }) paginator_categories: MatPaginator;
  @ViewChild('sortCategories', { read: MatSort }) sort_categories: MatSort;


  displayedColumns_sections: string[] = ['title'];
  dataSource_sections: MatTableDataSource<Section>;
  @ViewChild('paginatorSections', { read: MatPaginator }) paginator_sections: MatPaginator;
  @ViewChild('sortSections', { read: MatSort }) sort_sections: MatSort;

  selectedRow: any;

  selectedTab: string = "Resources";

  constructor(public libraryService: LibraryService, public bottomSheet: MatBottomSheet, private zone: NgZone, public dialogRef: MatDialogRef<AddSectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    this.setUpTables();
  }

  setUpTables() {
    this.setUpResources();
    this.setUpCategories();
    this.setUpSections();
  }

  setUpResources() {
    let resources = []
    this.libraryService.allResources.forEach( v => {
      if( this.libraryService.selectedResourceType.toLowerCase().includes(v.resourceType) ) {
        resources.push(v)
      }
    });

    this.dataSource_resources = new MatTableDataSource(resources);
  }


  setUpCategories() {
    let categories = []
    this.libraryService.allSeries.forEach( v => {
      if( this.libraryService.selectedResourceType.toLowerCase().includes(v.resourceType) ) {
        categories.push(v)
      }
    });

    this.dataSource_categories = new MatTableDataSource(categories);
  }


  setUpSections() {
    let sections = []
    this.libraryService.allSections.forEach( v => {
      if( this.libraryService.selectedResourceType.toLowerCase().includes(v.resourceType) ) {
        sections.push(v)
      }
    });

    this.dataSource_sections = new MatTableDataSource(sections);
  }


  ngOnInit() { }

  ngAfterViewInit() {
    this.dataSource_resources.paginator = this.paginator_resources;
    this.dataSource_resources.sort = this.sort_resources;

    this.dataSource_categories.paginator = this.paginator_categories;
    this.dataSource_categories.sort = this.sort_categories;

    this.dataSource_sections.paginator = this.paginator_sections;
    this.dataSource_sections.sort = this.sort_sections;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_resources.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_resources.paginator) {
      this.dataSource_resources.paginator.firstPage();
    }
  }

  updateSeries(s: string) {
    if (s !== this.selectedRow.category) {
      this.selectedRow.category = s;
      this.selectedRow.inEditMode = true;
    }

  }

  addNewSection() {
    this.selectedTab = "Sections"
    this.openAddNewModal();

  }


  addNewSeries() {
    this.selectedTab = "Series";
    this.openAddNewModal();

  }

  updateSection(s: string) {
    if (s !== this.selectedRow.section) {
      this.selectedRow.section = s;
      this.selectedRow.inEditMode = true;
    }
  }


  goBack() {
    this.dialogRef.close();
  }

  addFile() {
    let ref = this.dialog.open(VideoSectionCardComponent);

    ref.afterClosed().subscribe(res => {
      if (!res) { return }
      this.libraryService.allResources.push(res);
      this.dataSource_resources.data = this.libraryService.allResources;
    })
  }


  selectRow(row: any) {
    console.log(row)
    this.selectedRow = row;
    // let ref = this.dialog.open(VideoSectionCardComponent, {

    //   data: {
    //     video: this.selectedRow
    //   }
    // });

  }

  updateType(type: string) {
    this.selectedRow.resourceType = type;
  }

  selectRow_categories(row: any) {
    this.selectedRow = row;
    let ref = this.dialog.open(SeriesSectionCardComponent, {
      data: {
        series: this.selectedRow
      }
    });
  }


  selectRow_sections(row: any) {
    console.log(row);
    this.selectedRow = row;
    let ref = this.dialog.open(AddSectionComponent, {
      maxWidth: '90vw !important',
        width: '80%',
        height: '80%',
      data: {
        section: this.selectedRow
      }
    });
  }
  updatedSelectedTab(option: string) {
    this.libraryService.selectedResourceType = option;

    this.setUpTables();
  }


  setEditMode() {
    this.selectedRow.inEditMode = true;
  }


  upload(row: any) {
    console.log(row)
    row.status = "uploading";
    //TODO: replace the null with row.thumbnailFile
    this.libraryService.setResourceFile(row.title, row.videoFile, row.videoUrl, null, null, row.trainerName, row.category, row.section, row.resourceType).then(res => {
      console.log(res)
      if (res.success) {
        row.status = "uploaded";
      } else {
        row.status = "failed";
      }

      this.selectedRow.isAddingNewSection = false;
      this.selectedRow.inEditMode = false;

    })
  }


  openAddNewModal() {
    if (this.selectedTab === 'Resources') {
      let dialogRef = this.bottomSheet.open(AddNewModalComponent);

      dialogRef.afterDismissed().subscribe((res: {videos: File[], command: string}) => {
        if (!res) { return }
        if (res?.command === "addVideoFromLink") {
          let ref = this.dialog.open(VideoSectionCardComponent, {
          });

          ref.afterClosed().subscribe(res2 => {
            if (res2) {
              this.libraryService.allResources.push(res2);
              this.dataSource_resources.data = this.libraryService.allResources;
            }
          })
        }
        else if (res?.videos) {
          const resourceFiles: ResourceFile[] = res.videos.map(f => fileToResourceFile(f))
          this.libraryService.allResources.push(...resourceFiles);
          this.dataSource_resources.data = this.libraryService.allResources;
        }

      })
    }
    else if (this.selectedTab === 'Series') {
      let ref = this.dialog.open(SeriesSectionCardComponent);
    }

    else if (this.selectedTab === 'Sections') {
      let ref = this.dialog.open(AddSectionComponent, {
        maxWidth: '90vw !important',
          width: '80%',
          height: '80%',
      });
    }
  }


  onThumbnailSelected(event: any) {
    this.setEditMode();

    this.selectedRow.thumbnailFile = event.target.files[0];
    // let reader = this.getFileReader();

    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.selectedRow.thumbnailUrl = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(event.target.files[0]);
  }


  setSelectedTab(option: string) {
    this.selectedTab = option;
  }

  getSeriesToDisplay() {
    let selectedRowSection = this.selectedRow?.section;
    return this.getSeriesFromSection(selectedRowSection);
  }


  getSeriesFromSection(selectedRowSection: string): string[] {
    let arr = []
    this.libraryService.allSections.forEach(section => {
      if (section.sectionTitle === selectedRowSection) {
        arr = Array.from(section.videosMap.keys());
      }
    })
    return arr
  }

}

