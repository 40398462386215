<shared-my-header>
</shared-my-header>
<ion-content>
    <ion-toolbar style=" width: 100vw; height: 60px; position: sticky;top: 0;  padding: 10px; z-index: 999; ">
        <div style=" max-width: 1200px; display: block; margin: auto;">

            <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
                <div style="padding: 5px;"></div>



                <div style="width: 100%">
                    <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
                        <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
                            search
                        </i>
                        <span *ngIf="!searchOn" style="float:left; " class="zeroLineHeight opaque-text">Search
                        </span>

                        <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
                            style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                            <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue"
                                (keyup)="searchSend()">

                        </mat-form-field>
                    </button>

                </div>
                <!-- <div style="padding: 5px"></div> -->
                <div>
                    <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
                        style="margin-left: -50px; z-index: 999;">
                        <i class="material-icons" style="font-size: 14px;">
                            clear
                        </i>
                    </button>
                </div>


            </div>
        </div>
    </ion-toolbar>

    <div style="max-width: 1200px; display: block; margin: auto;">

        <app-section-carousel title='My Best' [reports]="this.currentOptions_my">
        </app-section-carousel>
        <div style="height: 5px"></div>

        <app-section-carousel title='Individual' [reports]="this.currentOptions_individual">
        </app-section-carousel>
        <div style="height: 5px"></div>
        <app-section-carousel title='Team' [reports]="this.currentOptions_team">
        </app-section-carousel>
        <div style="height: 5px"></div>
        <app-section-carousel title='Region' [reports]="this.currentOptions_region">
        </app-section-carousel>
        <div style="height: 5px"></div>
        <app-section-carousel title='Company' [reports]="this.currentOptions_company">
        </app-section-carousel>
    </div>

</ion-content>

<app-my-footer>
</app-my-footer>


<!-- <app-section-carousel 
 title='Team'>
</app-section-carousel>



<app-section-carousel 
 title='Region'>
</app-section-carousel>



<app-section-carousel 
 title='Company'>
</app-section-carousel> -->


<!-- <ion-content>

    <div *ngFor="let report of this.getCompanyRecordsReports()" style="display: block; margin: auto; height: 280px;" class="section-div">
        <div *ngIf='report && report.reportCellList' style="min-width: 200px;display: block;">
            <ion-grid style="padding: 0;">
                <ion-row>
                    <ion-col size="12" style="padding: 0">
                        <div>

                            <ion-grid style="margin: 0; padding: 10px;">
                                <ion-row>
                                    <ion-col size="10" style="padding: 0; margin: 0">
                                        <div style="position: relative; text-align: left;">
                                            <p style="font-size: 14px; min-height: 20px; margin-top: 10px; font-weight: 500; padding-left: 5px;"
                                                >
                                                {{report?.freshReport?.reportName | titlecase }}
                                            </p>
                                        </div>
                                    </ion-col>

                                    <ion-col size="2" style="padding: 0; margin: 0">
                                        <button style="float: right" class="app-text-color" mat-icon-button
                                            [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>

                                        <mat-menu #menu="matMenu">
                                            <ng-template matMenuContent style="width: 100px !important;">

                                                <button  mat-menu-item>
                                                    <mat-icon style="opacity: 0.2;">push_pin</mat-icon>
                                                    <span>Unpin</span>
                                                </button>

                                                <button mat-menu-item>
                                                    <mat-icon>download</mat-icon>

                                                    <span>Download</span>
                                                </button>
                                            </ng-template>
                                        </mat-menu>


                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                            <button mat-button [disableRipple]="true"
                                style="padding: 0px; margin: 0; width: 100%; margin-top: -10px"
                               >

                                <div>

                                    <app-dashboard-card [reportCellList]='report.reportCellList' [report]='report'>
                                    </app-dashboard-card>
                                </div>
                            </button>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>

</ion-content> -->