import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IonRouterOutlet } from '@ionic/angular';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { HelpService } from 'src/app/shared/services/help.service';

@Component({
  selector: 'app-linked-videos-page',
  templateUrl: './linked-videos-page.component.html',
  styleUrls: ['./linked-videos-page.component.scss'],
})
export class LinkedVideosPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute, 
    public routerOutlet: IonRouterOutlet, 
    private sanitizer: DomSanitizer,
    public helpService: HelpService, 
    public dialog: MatDialog,
    public router: Router,
  ) { 
    // extract video id from url
    this.extractVideoIdFromUrl()
  }

  ngOnInit() {
    // get video url
    // this.getVideoInformation("NqNP2DTxg8")
  }

  async extractVideoIdFromUrl() {
    this.activatedRoute.queryParams.subscribe((params)=> {
      let videoId: string = params['videoId'];
      
      if (videoId === null) {
        // this.getVideoInformation("NqNP2DTxg8")
      } else {
        this.getVideoInformation(videoId);
      }
    })
    // const params = await this.activatedRoute.queryParams.toPromise();

  }

  getVideoInformation(id: string) {
    this.helpService.getTrainingVideoById(id).then(res => {
      console.log(res);
      this.openVideo(res.object.fileUrl);
    })
  }

  openVideo(video: string){
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        video: video,
      }
    });
  }

}
