import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomBarChartRowData } from '../../../models/dashboard.model';
import { ReportCellList } from '../../../models/report-cell-list.model';
import { ReportDataPoint } from '../../../services/reports.service';

@Component({
  selector: 'app-custom-bar-chart',
  templateUrl: './custom-bar-chart.component.html',
  styleUrls: ['./custom-bar-chart.component.scss'],
})
export class CustomBarChartComponent implements OnInit {

  rows: CustomBarChartRowData[];

  @Input() isDashboard: boolean;

  @Input() set reportCellList(newReportCellList: ReportCellList) {
    this.getTableData(newReportCellList);
  }


  getTableData(reportCellList: ReportCellList): ReportDataPoint[] {
    let output: any[] = [];
    let highest: number = 0;
    if(reportCellList.list) {

      highest = 0;
      reportCellList.list.forEach((reportCell, index) => {

        let nextNumber: number = (reportCell.cellList[1].value) ? +(reportCell.cellList[1].value).replace(/\$|,|%/g, '') : null;
        highest = (nextNumber > highest) ? nextNumber : highest;
      })

      output = reportCellList.list.map((cell: any)=> {

        let amount: number = (cell.cellList[1].value) ? +(cell.cellList[1].value.replace(/\$|,|%/g, '')) : null;

        let value: number = (amount / highest) * 100

        return {
          "title" : (cell.cellList[0].employee) ? cell.cellList[0].employee.fullName : cell.cellList[0].value,
          "amount": cell.cellList[1].value,
          "value": value
        }
      })
    }

      this.rows = output;


    return output;

  }

  constructor(public router: Router) { }

  ngOnInit() {}

}
