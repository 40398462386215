<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<shared-my-header>
</shared-my-header>
<ion-content *ngIf='userCanEditTournament()'>
  <div style="max-width: 1200px; display: block; margin: auto;">



    <div>
      <div>
        <h1>Tournament Information</h1>
      </div>


      <mat-card>


        <ion-grid>
          <ion-row>
            <ion-col size="6">
              <mat-form-field placeholder="Name" *ngIf='tournament' style="width: 100%;">
                <mat-label>Tournament Name</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.campaignName">
              </mat-form-field>
            </ion-col>
            <ion-col size="6">
              <mat-form-field placeholder="backgroundUrl" *ngIf='tournament' style="width: 100%;">
                <mat-label>Background Image</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.backgroundUrl">
              </mat-form-field>
            </ion-col>

            <ion-col size="6">
              <mat-form-field placeholder="Thumbnail Image" *ngIf='tournament' style="width: 100%;">
                <mat-label>Thumbnail Image</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.thumbnailUrl">
              </mat-form-field>
            </ion-col>



            <ion-col size="6">
              <mat-form-field placeholder="groupType" *ngIf='tournament' style="width: 100%;">
                <mat-label>Group By</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.groupType">
              </mat-form-field>
            </ion-col>

            <ion-col size="6">
              <mat-form-field placeholder="Start Date" *ngIf='tournament' style="width: 100%;">
                <mat-label>Start Date</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.startDate">
              </mat-form-field>
            </ion-col>

            <ion-col size="6">
              <mat-form-field placeholder="End Date" *ngIf='tournament' style="width: 100%;">
                <mat-label>End Date</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.endDate">
              </mat-form-field>
            </ion-col>


            <ion-col size="6">
              <mat-form-field placeholder="Rules Description" *ngIf='tournament' style="width: 100%;">
                <mat-label>Rules Description</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.rulesDescription">
              </mat-form-field>
            </ion-col>


            <ion-col size="6">
              <mat-form-field placeholder="Rules Url" *ngIf='tournament' style="width: 100%;">
                <mat-label>Rules Url</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.rulesUrl">
              </mat-form-field>
            </ion-col>

            <ion-col size="6">
              <mat-form-field placeholder="Current Round" *ngIf='tournament' style="width: 100%;">
                <mat-label>Current Round</mat-label>
                <input type="text" matInput [(ngModel)]="tournament.currentRound">
              </mat-form-field>
            </ion-col>

            <ion-col size="6">
              <mat-form-field placeholder="Is Locked" *ngIf='tournament' style="width: 100%;">
                <mat-label>Is Locked</mat-label>
                <input type="boolean" matInput [(ngModel)]="tournament.isLocked">
              </mat-form-field>
            </ion-col>
            <!-- <ion-col size="6">
        </ion-col> -->

          </ion-row>
        </ion-grid>
      </mat-card>


    </div>

    <div style="height: 20px;"></div>

    <app-edit-tournament-rounds *ngIf='this.tournament?.rounds' #roundsComponent [_rounds]='this.tournament?.rounds'>

    </app-edit-tournament-rounds>

    <div>
      <button mat-raised-button class="primary-color" style="width: 100%;" (click)='saveAll()'>Save</button>

    </div>
  </div>
  <div style="height: 100px;"></div>

</ion-content>


<app-my-footer>
</app-my-footer>