<ion-grid>
  <ion-row>
    <ion-col size="4" style="text-align: left; font-weight: 100;" class="opaque-text">
      {{title}}
    </ion-col>

    <ion-col size="8" style="text-align: left; font-weight: 100;">

      <div *ngIf="type === 'text'">
        {{value}}
      </div>
      <div *ngIf="type === 'address'" (click)="openMapsApp(this.lead.fullAddress)" class="hyper-text-color">
        {{value}}
      </div>

      <div *ngIf="type === 'email' && this.lead.email" (click)="sendEmail(this.lead.email)" class="hyper-text-color">
        {{value}}
      </div>
      <div *ngIf="type === 'phone' && this.lead.phoneNumber">
        <div slot="start">
            {{ this.lead.phoneNumber }} 
            <br>
            <a class="hyper-text-color" style="text-decoration: none;" [href]="'tel:' + this.lead.phoneNumber">Call</a> 
            <span style="color: gray;"> &nbsp;&nbsp; | &nbsp;&nbsp; </span> 
            <a class="hyper-text-color" style="text-decoration: none;" [href]="this.sanitize('sms:' + this.lead.phoneNumber)">Text</a>
        </div>
      </div>



      <div *ngIf="type === 'checkbox'">
        <mat-checkbox [disabled]="shouldDisableSatCheckbox()" (change)="checkSat()" [(ngModel)]="this.lead.isCompleted"
          style="display: block; margin: auto; padding-right: 10px; font-size: 16px;"></mat-checkbox>
      </div>


      <div *ngIf="type === 'button'">
        <button style="font-weight: 100; font-size: 16px;" mat-button class="opaque-div"
          [matMenuTriggerFor]="leadStatusMenu" *ngIf="title === 'Status'">
          {{this.lead.leadStatus}} <span class="material-icons iconAlign">
            arrow_drop_down
          </span>
        </button>


        <div *ngIf="title === 'Closer'">
          <button style="font-weight: 100; font-size: 16px;" *ngIf="userCanChangeAssignedRep()"
            [ngClass]="{'red-button': leadIsUnassigned() , 'opaque-div': !leadIsUnassigned() }" class="opaque-div"
            mat-button (click)='openSelectSingleUserPickerModal(); $event.stopPropagation()'>
            {{getEmployeeName(this.lead?.assignedRep)}}

            <span class="material-icons">
              arrow_drop_down
            </span>

          </button>

          <span *ngIf="!userCanChangeAssignedRep()" style="font-weight: 100;"
            [ngClass]="{'red-text': (getEmployeeName(this.lead?.assignedRep) === 'Unassigned') }">
            <span [ngClass]="{'opaque-text': (getEmployeeName(this.lead?.assignedRep) === 'Assigned') }">
              {{getEmployeeName(this.lead?.assignedRep)}}
            </span>
          </span>
        </div>
      </div>

    </ion-col>
  </ion-row>
</ion-grid>

<mat-menu #leadStatusMenu="matMenu">
  <ng-template matMenuContent>
    <div *ngFor="let option of getLeadStatusOptions()">
      <button mat-menu-item style="text-align: center;" (click)="selectLeadStatus(option)">
        {{option}}
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-divider></mat-divider>