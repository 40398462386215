import { ResourceFile } from "./models/help.model";


export function fileToResourceFile(item: File): ResourceFile {
    let resourceType = "";

      if (item.type.includes("image")) {
        resourceType = "document"
      } else if (item.type.includes("video")) {
        resourceType = "video"
      }

      let output: ResourceFile = {
        title: item.name,
        thumbnailUrl: "",
        trainerName: "",
        category: "",
        videoUrl: "",
        id: 0,
        videoFile: item,
        resourceType: resourceType
      }
      return output;
}