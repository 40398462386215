import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-generate-new-rep',
  templateUrl: './generate-new-rep.component.html',
  styleUrls: ['./generate-new-rep.component.scss'],
})
export class GenerateNewRepComponent implements OnInit {

  name: string = "";
  constructor(public router: Router,   private snackbarService: SnackbarService,    ) { }

  ngOnInit() {}


  createRep() {
    this.snackbarService.displaySnackBarMessage("Rep Created", true);

    this.router.navigate(['/onboarding-list']);

  }

  submitName(): void {

  }

}
