import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class DateFormatService {

    constructor(public datepipe: DatePipe) { }


    convertUTCDateToLocaleDate(date: string): string {
        let newDate = new Date(date + " UTC").toLocaleString();
        if (newDate !== 'Invalid Date'){
            return newDate
        } else {
            return '-'
        }
    }

    formatDateFromString(date: string): string {
        if (!date) {
            return ""
        }

        if (date.includes("T")) {
            date = date.replace("T", " ")
        }

        let _date: Date = new Date(date.replace(/-/g, "/"));
        if (_date.toString() === "Invalid Date") {
            return ""
        }
        if (_date) {
            return this.datepipe.transform(_date, 'EE, MMM dd hh:mma');
        }
    }

    getDateFromString(date: string): Date {
        if (date.includes("T")) {
            date = date.replace("T", " ")
        }
        return new Date(date.replace(/-/g, "/"))
    }


    saveDateWithCorrectFormat(date: string): string {
        if (date.includes("T")) {
            date = date.replace("T", " ")
        }
        date = date.replace(/-/g, "/");
        return date;
    }

    convertDateToFormat(date: Date, format: string = "yyyy-MM-dd"): string {
        return this.datepipe.transform(date, format);
    }


    isToday(time: string) {
        if (!time) return false;
        time = time.replace(/-/g, "/")
        const today = new Date()
        const inputDate = this.getDateFromString(time);

        return inputDate.getDate() == today.getDate() &&
            inputDate.getMonth() == today.getMonth() &&
            inputDate.getFullYear() == today.getFullYear()
    }

    isTodayUTC(time: string) {
        if (!time) return false;
        time = time.replace(/-/g, "/")
        const today = new Date()
        const inputDate = new Date(time + " UTC");

        return inputDate.getDate() == today.getDate() &&
            inputDate.getMonth() == today.getMonth() &&
            inputDate.getFullYear() == today.getFullYear()
    }


    isTomorrow(time: string) {
        if (!time) return false;
        const today = new Date()
        const inputDate = this.getDateFromString(time);

        return inputDate.getDate() == today.getDate() + 1 &&
            inputDate.getMonth() == today.getMonth() &&
            inputDate.getFullYear() == today.getFullYear()
    }


    isThisWeek(time: string) {
        if (!time) return false;
        const today = new Date()
        var nextWeek = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7).toDateString());
        const inputDate = this.getDateFromString(time);
        const convertedDate = Date.parse(inputDate.toDateString());

        return (nextWeek > convertedDate && !this.isPast(time))
    }



    isThisMonth(time: string) {
        if (!time) return false;
        const today = new Date()
        const inputDate = this.getDateFromString(time);

        return inputDate.getMonth() == today.getMonth() &&
            inputDate.getFullYear() == today.getFullYear()
    }


    isThisYear(time: string) {
        if (!time) return false;
        const today = new Date()
        const inputDate = this.getDateFromString(time);

        return inputDate.getFullYear() == today.getFullYear()
    }


    isPast(time: string) {
        if (!time) return false;
        const today = new Date()
        const inputDate = this.getDateFromString(time);

        return inputDate.getDate() < today.getDate() ||
            inputDate.getMonth() < today.getMonth() ||
            inputDate.getFullYear() < today.getFullYear()
    }


}
