<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<link href="https://fonts.googleapis.com/css2?family=Rubik&display=swap" rel="stylesheet">
<shared-my-header></shared-my-header>


<enzy-search-header [filtersObjects]="filtersObjects" [iconsInput]="getHeaderIcons()"
  (searchInputEmitter)="handleSearchInputAction($event)" (filterEmitter)="openFilters()"
  (iconEmitter)="handleIconAction($event)" (filterClearedEmitter)="handleClearedFilter($event)">
</enzy-search-header>

<ion-content #content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="" pullingText="Pull Down to Refresh" refreshingSpinner="none" refreshingText="">
    </ion-refresher-content>
  </ion-refresher>


  <div class="reports-container" cdkDropListGroup [cdkDropListGroupDisabled]="!inDragMode" style="margin-top: 5px">
    <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate"
      (cdkDropListDropped)="dropListDropped($event)"></div>
    <div cdkDropList *ngFor="let report of this.dataSource?.filteredData.slice(0,currentCount)"
      [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)">
      <div cdkDrag class="report-box" (cdkDragMoved)="dragMoved($event);">
        <app-dashboard-card (showAnalytics)="this.showAdvancedAnalytics($event)" [report]="report"
          [inDragMode]="this.inDragMode">
        </app-dashboard-card>
      </div>
    </div>
  </div>
  <ion-infinite-scroll threshold="100px" style="width: 100%" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>

<app-my-footer>
</app-my-footer>