import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SurveyResponse } from '../cr-dashboard/cr-dashboard.service';
import { ISurveyApi } from '../shared/services/survey.api';
import { SurveyResponsesModalComponent } from './survey-responses-modal/survey-responses-modal.component';



@Injectable()
export abstract class ISurveyService {
    abstract openRelatedResponsesModal(sessionId: string): Promise<any>;
}

@Injectable()
export class SurveyService implements ISurveyService {

    constructor(
        private api: ISurveyApi,
        private dialog: MatDialog,

        ) {}

    async openRelatedResponsesModal(sessionId: string): Promise<boolean> {
        const res: SurveyResponse[] = await this.api.getRelatedSurveyResponses(sessionId);
        // open popup 
        if (res?.length > 0)
            this.openModal(res);
        return res?.length > 0;
    } 

    private async openModal(responses: SurveyResponse[]): Promise<any> {
        let dialogRef: MatDialogRef<SurveyResponsesModalComponent> = this.dialog.open(SurveyResponsesModalComponent, {
            data: {
                responses
            },
            maxWidth: "600px",
            maxHeight: "100vh"
        })

        const res = await dialogRef.afterClosed().toPromise();
        return res;
    }

}