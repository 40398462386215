import { DataReportMenu, DEFUALT_DATA_REPORT_MENU } from './dashboard-report-list.model';
import { DEFAULT_FRESH_REPORT, FreshReport } from './fresh-report.model';
import { DEFAULT_REPORT_CELL_LIST, ReportCellList } from './report-cell-list.model';


export class UserData {
  accessLevel: number;
  attributeMap: any;
  bestFirstName: string;
  bestImageThumbnailUrl: string;
  bestImageUrl: string;
  birthDate: string;
  companyAccessLevel: number;
  companyId: number;
  companyRole: string;
  creationDateTime: any;
  emailAddress: string;
  errorMessage: string;
  firstName: string;
  fullName: string;
  gender: string;
  id: string;
  imageThumbnailUrl: string;
  imageUrl: string;
  imageUrlOrDefault: string;
  isAppAdmin: boolean;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  preferredFirstName: string;
  role: string;
  sessionKey: string;
  sessionStart: string;
  workspaceId: number;
  zendeskRole: string;
  salesRepRegion: string;
  salesRepTeam: string;
  
}

export const DEFAULT_USER_DATA: UserData = {
  accessLevel: null,
  attributeMap: null,
  bestFirstName: null,
  bestImageThumbnailUrl: null,
  bestImageUrl: null,
  birthDate: null,
  companyAccessLevel: null,
  companyId: null,
  companyRole: null,
  creationDateTime: null,
  emailAddress: null,
  errorMessage: null,
  firstName: null,
  fullName: null,
  gender: null,
  id: null,
  imageThumbnailUrl: null,
  imageUrl: null,
  imageUrlOrDefault: null,
  isAppAdmin: null,
  lastName: null,
  middleName: null,
  phoneNumber: null,
  preferredFirstName: null,
  role: null,
  sessionKey: null,
  sessionStart: null,
  workspaceId: null,
  zendeskRole: null,
  salesRepTeam: null,
  salesRepRegion: null
}




export class FilterData {
  selectedDepartment = '';
  selectedGender = [''];
  minSalary = '';
  maxSalary = '';
  minMonthsOnJob = '';
  maxMonthsOnJob = '';
  selectedOption = 'preferredFirstName';
  selectedFlightRisk = '';
  keyWord = '';
}




export class QueryData  {
  employeeQueryString_index = '';
  employeeQueryString_additionalParams = '';
}


export class SnapshotData {
  name: string;
  amount: number;
}

export class TrendData {
  trendChartData_amChart: DateReportData[];
  dataMax: number;
  dataMin: number;
  dataAvg: number;
  dataTotal: number;
  trendChartData_googleChart: any[][];
}

export const DEFAULT_TREND_DATA: TrendData = {
  trendChartData_amChart: [],
  dataMax: null,
  dataMin: null,
  dataAvg: null,
  dataTotal: null,
  trendChartData_googleChart:[], //Will this cause issues?
}

export class DashboardData { //ReportModel

  freshReport: FreshReport;
  reportMenu: DataReportMenu;

  reportOrder: number;
  hasBeenSaved: boolean;
  viewFilter: string;

  reportCellList: ReportCellList;

  onDashboard?: boolean;

  //TECHDEBT: these should be put into their own class
  trendData?: TrendData;
  columnChartData?: CustomBarChartRowData[];
}



export const DEFAULT_DASHBOARD_DATA: DashboardData = {
  freshReport: {...DEFAULT_FRESH_REPORT},
  reportMenu: {...DEFUALT_DATA_REPORT_MENU},

  reportOrder: null,
  hasBeenSaved: false,
  viewFilter: null,

  reportCellList: {...DEFAULT_REPORT_CELL_LIST},

  onDashboard: null,

  //TECHDEBT: these should be put into their own class
  trendData: {...DEFAULT_TREND_DATA},
  columnChartData: [],
}




export class CustomBarChartRowData {
  title: string;
  amount: string;
  value: number;
}

export class DateReportData {
  date: Date;
  dataPointValue: number;
  lineColor?: string;
}



export class AppNotification {
  name: string;
  status: string;
  date: string;
}
