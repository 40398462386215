<ion-header no-border class="no-padding-header ion-no-border">
  <div style="width: 100%;">
    <ion-toolbar class="no-padding-header  ">
      <div slot="start">
        <button mat-fab class="app-text-color"
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>

      <div>
        <h1 style="text-align: center; margin: 0; font-size: 18px;"> {{videos[0]?.category}}</h1>
      </div>


      <div slot="end">
        <button mat-fab class="app-text-color"
          style="opacity: 0; text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>
    </ion-toolbar>
  </div>
</ion-header>
<ion-content>



  <ion-toolbar style=" width: 100%; height: 60px; position: sticky;top: 0;  padding: 3px; z-index: 999; ">
    <div style=" max-width: 1200px; display: block; margin: auto;">

      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
        <div style="padding: 5px;"></div>

        <div style="width: 100%">
          <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
            <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
              search
            </i>
            <span *ngIf="!searchOn" style="float:left; " class="zeroLineHeight opaque-text">Search
            </span>

            <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue" (keyup)="searchSend()">
            </mat-form-field>
          </button>

        </div>
        <div style="padding: 5px"></div>
        <div>
          <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
            style="margin-left: -50px; z-index: 999;">
            <i class="material-icons" style="font-size: 14px;">
              clear
            </i>
          </button>
        </div>

      </div>
    </div>
  </ion-toolbar>

  <div style="height: 10px;"></div>

  <div style="padding: 10px;">
    <main>
      <div *ngFor="let video of this.currentOptions; let i = index">
        <article>
          <div style="display: flex;">
            <app-video-card style='margin: 0 auto;' [video]="video" ></app-video-card>
          </div>
         
        </article>
      </div>
    </main>

  </div>

</ion-content>