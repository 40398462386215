import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Employee } from '../../models/employees.model';
import { EmployeeStoreService } from '../../services/employee-store.service';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
})
export class UserPickerComponent implements OnInit {

  @ViewChild('wrapperArea') wrapperElement: ElementRef;
  @ViewChild('selectedRecipientsArea') selectedRecipientsElement: ElementRef;
  // @ViewChild('virtualScrollWindow') virtualScrollWindow: ElementRef;

  @Output() selectedUsersOutput: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  searchValue: string = "";

  removable = true;
  selectable = true;

  showAllSelected: boolean = true;


  @Input()
  selectedUserIds: number[] = [];
  
  selectedUsers(): Employee[] {
    return this.selectedUserIds ? this.selectedUserIds.map(id => this.employeeStoreService.getUser(id)) : [];
  }
  
  

  constructor(
    public employeeStoreService: EmployeeStoreService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getAllUsers(): Employee[] {
    return this.employeeStoreService?.users?.filter(e=>(e.userDto?.preferredFirstName + " " + e.userDto?.lastName).toLowerCase().includes(this.searchValue.toLowerCase()));
  }

  getAllUsersSorted(): Employee[] {
    return this.getAllUsers()?.sort((a, b) => {return a?.userDto?.firstName?.localeCompare(b?.userDto?.firstName)})
  }

  getAllUsersToRender(): Employee[] {
    return this.getAllUsersSorted()?.filter(e1=>!this.selectedUsers()?.find(e2=> this.sameUser(e1, e2)));
  }

  onNgModelChange(event: any) {
    console.log(event);
  }

  printSelected(): string {
    return JSON.stringify(this.selectedUsers);
  }

  sameUser(userA: Employee, userB: Employee): boolean {
    return userA === userB;
    // return userA.userDto.id === userB.userDto.id
  }

  unselectUser(user: Employee, index: number) {
    const idx: number = this.selectedUserIds.findIndex(id => +user?.userId === +id);
    if (idx !== null) {
      this.selectedUserIds.splice(idx, 1);
      this.selectedUsersOutput.emit(this.selectedUsers());
    }
    this.cdr.detectChanges();
  }

  selectUser(user: Employee) {
    console.log("another")
    if (!this.selectedUserIds) this.selectedUserIds = [];
    this.selectedUserIds.push(+user?.userId);
    this.selectedUsersOutput.emit(this.selectedUsers());
    this.searchValue = "";
    // this.cdr.detectChanges();
  }

  selectedUsersToShow(): Employee[] {
    return this.showAllSelected ? this.selectedUsers() : this.selectedUsers().slice(0,5);
  }

  showMoreButtonText(): string {
    return this.showAllSelected ? "Show Less" : "Show All";
  }

  toggleShowAllSelected(): void {
    this.showAllSelected = !this.showAllSelected;
  }

  heightOf(element: ElementRef): number {
    return element?.nativeElement?.offsetHeight;
  }

  calculateListHeight(): string {
    const fullHeight: number = this.heightOf(this.wrapperElement) || 1000;
    const selectedUsersFormHeight: number = this.heightOf(this.selectedRecipientsElement) || 200;
    const heightForListOfAllUsers: number = fullHeight - selectedUsersFormHeight - 40;

    return heightForListOfAllUsers + "px";
  }

  shouldShowMoreButton(): boolean {
    return (this.selectedUsers?.length) > 5;
  }

}
