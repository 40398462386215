import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-advanced-analytics-modal',
  templateUrl: './advanced-analytics-modal.component.html',
  styleUrls: ['./advanced-analytics-modal.component.scss'],
})
export class AdvancedAnalyticsModalComponent implements OnInit {

  public reportName: string = "";

  constructor(public dialogRef: MatDialogRef<AdvancedAnalyticsModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.reportName = this.data.reportName;
  }

  save() {
    this.dialogRef.close(this.reportName);
  }

  cancel() {
    this.dialogRef.close();
  }

  leave() {
    this.dialogRef.close('leave');
  }

  stay() {
    this.dialogRef.close();
  }

}
