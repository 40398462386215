import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SurveyResponse } from 'src/app/cr-dashboard/cr-dashboard.service';


@Component({
  selector: 'enzy-survey-responses-modal',
  templateUrl: './survey-responses-modal.component.html',
  styleUrls: ['./survey-responses-modal.component.scss'],
})
export class SurveyResponsesModalComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<SurveyResponsesModalComponent>, 
    @Inject(MAT_DIALOG_DATA) private data: {responses: SurveyResponse[]}) {

    }

  ngOnInit() {}

  getResponses(): SurveyResponse[] {
    return this.data?.responses ? this.data.responses : [];
  }

  close() {
    this.dialogRef.close();  
  }


  getResponseLeft(response): string {
    return response?.surveyTitle;
  }

  getResponseRight(response): string {
    return response?.response;
  }

}
