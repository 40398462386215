
<div class="no-context-menu app-background-color app-text-color" style="height: 100%;">

  <ion-grid style=" padding: 0; height: 100%;" >
    <div style="height: 10px;"></div>
    <div>
     <h1 style="text-align: center; font-size: 18px; padding: 0; margin:0">{{selectedOption}}</h1>
    </div>
    <div style="height: 10px;"></div>

    <div style="height: 40vh;">
      <ion-grid style="padding: 0">
        <div *ngFor="let voter of voters" class="app-background-color">
          <ion-row >
            <ion-col size="auto" style="width: 60px !important">
              <img [src]="voter.imageUrl" class="userImage" />
            </ion-col>
            <ion-col>
              <h1 style="padding: 10px 0 0 0; margin: 0; font-size: 14px;">{{ getFullName(voter) }}</h1>
            </ion-col>
  
          </ion-row>
        </div>
      </ion-grid>
  
    </div>
  </ion-grid>
  </div>