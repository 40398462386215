import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-new-incentive-modal',
  templateUrl: './new-incentive-modal.component.html',
  styleUrls: ['./new-incentive-modal.component.scss'],
})
export class NewIncentiveModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NewIncentiveModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  newIncentive(type: string) {
    this.dialogRef.close(type);
  }

}
