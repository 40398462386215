<ion-header class="app-background-color">
  <ion-toolbar class="transparent-bg">
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button (click)="cancel()">

        <span class="material-icons">
          close
        </span>

      </button>
    </div>
    <div>
      <h1 style="text-align: center; padding: 0; margin: 0; font-size: 18px;"> Update Profile Picture</h1>
    </div>

    <div slot="end">
      <button style="opacity: 0;" mat-icon-button (click)="cancel()">Cancel</button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="app-background-color">
  <div style="max-width: 600px; display: block; margin: auto; padding: 10px">
    <div>
      Please upload a clear image of your face (selfies are acceptable) to be used for your ID badge. 
    </div>
    <ion-toolbar *ngIf="myImage" class="transparent-bg">
      <div slot="start">
        <button mat-raised-button (click)="reset()">Reset</button>
      </div>


      <div slot="end">
        <button mat-raised-button class="primary-color" (click)="accept()">Accept</button>
      </div>
    </ion-toolbar>

    <div style="height: 20px;"></div>
    <button *ngIf="!myImage"
      style="width: 100%; height: 400px; background-image: url('../../../../assets/placeholder-image.png'); background-size:100% 100%;   background-repeat: no-repeat;
      "
      (click)="fileInput.click()" mat-raised-button>Select Image</button>

    <input hidden #fileInput type="file" (change)="fileChangeEvent($event)" />
    <image-cropper *ngIf="myImage" [imageChangedEvent]="imageChangedEvent" [imageBase64]="myImage"
      [maintainAspectRatio]="true" [aspectRatio]="3 / 3" format="png" (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">

    </image-cropper>


    <!-- <h1> cropped image </h1>
  <img [src]="croppedImage"> -->


  </div>

</ion-content>