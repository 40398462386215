import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-under-construction-modal',
  templateUrl: './under-construction-modal.component.html',
  styleUrls: ['./under-construction-modal.component.scss'],
})
export class UnderConstructionModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UnderConstructionModalComponent>) { }

  ngOnInit() {}

  ok() {
    this.dialogRef.close();
  }

  

}
