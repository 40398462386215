

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REST_BASE_URL } from '../models/constants';
import { UserDetailsService } from './user-details.service';


@Injectable({
  providedIn: 'root'
})
export class RecruitService {

  constructor(private http: HttpClient, 
    public userDetailsService: UserDetailsService   ) { }  


  public async getTeams(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/getTeams/' + sessionKey;
    return this.http.get<any>(url).toPromise();
  }

  public async getMySentInvitations(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/applicantInvitation/' + sessionKey;
    return this.http.get<any>(url).toPromise();
  }


}

