import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { APP_IS_DEV_MODE } from "./models/constants";

export function adamLog(input: any) {
  if (!APP_IS_DEV_MODE) return;
    console.log("%c" + input, "color:yellow;font-family:system-ui;font-weight:bold;font-size:16px")
}

export function adamAlert(input: any) {
  if (!APP_IS_DEV_MODE) return;
    alert(input)
}


export function camelCaseToSentenceCase(input: string): string {
  
  const result = input.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function getBlobFromFile(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    // Is this a "real" file? In other words, is this an instance of the original `File` class (not the one overriden by cordova-plugin-file).
    // If so, then we need to use the "real" FileReader (not the one overriden by cordova-plugin-file).
    if (file instanceof Blob) {
      const realFileReader = (fileReader as any)._realReader;
      if (realFileReader) {
        fileReader = realFileReader;
      }
    }

    fileReader.onloadend = function () {
      resolve(new Blob([this.result], { type: file.type }));
    };

    fileReader.onerror = function (err) {
      console.error("Inside fileReader.onerror", err);
      reject(err);
    };

    fileReader.readAsArrayBuffer(file);
  });
}


export async function paramFromRoute(route: ActivatedRoute, paramName: string): Promise<string> {
  const params = await route.queryParams.pipe(take(1)).toPromise();
  return params[paramName];
}



export async function callPhoneNumber(phoneNumber: string) {

}


export async function sendEmail(email: string) {
  window.open('mailto:' + email);
}