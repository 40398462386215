<div style="text-align: center;">
  <div style="height: 10px;"></div>

  <h1 style="margin: 5px"><span class="material-icons">
      handyman
    </span>
  </h1>
  <br>
  <p style="margin: 5px" > This page is in Beta</p>

  <button mat-button style="width: 100%;" (click)="ok()"> OK </button>
  <div style="height: 10px;"></div>
</div>