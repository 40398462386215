import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { Employee } from 'src/app/shared/models/employees.model';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TicketsService } from 'src/app/tickets/tickets.service';
import { ExternalLeadsService } from '../external-lead-submission/external-leads.service';
import { Appointment, DEFAULT_APPOINTMENT, DEFAULT_LEAD, DEFAULT_LEAD_INPUT, getDefaultNewLead, LeadInput, LeadModel, Note } from '../leads.model';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'enzy-external-lead-form',
  templateUrl: './external-lead-form.component.html',
  styleUrls: ['./external-lead-form.component.scss'],
})
export class ExternalLeadFormComponent implements OnInit {
  @Input() officeKey: string;
  @Input() companyKey: string;


  personKey: string;
  @Input() set personKeyInput(newPersonKey: string) {
    this.personKey = newPersonKey;
  }



  public lead: LeadInput = { ...DEFAULT_LEAD_INPUT };
  public appointment: Appointment = { ...DEFAULT_APPOINTMENT };
  newNote: string = "";
  userId: number;

  constructor(
    public employeeStoreService: EmployeeStoreService,
    public dateFormatService: DateFormatService,
    public ticketsService: TicketsService,
    public userDetailsService: UserDetailsService,
    public leadsService: LeadsService,
    public externalLeadsService: ExternalLeadsService,
    public busySpinnerService: BusySpinnerService
  ) { }

  async ngOnInit() {
    this.userId = +(await this.userDetailsService.getUserId());
  }


  @Output() submitLead = new EventEmitter<LeadInput>();

  options = {
    types: [],
    componentRestrictions: { country: ['USA'] }
  }
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address, lead: LeadInput) {
    lead.fullAddress = address.formatted_address;
    this.parseAddressComponentsIntoLead(address, lead);
  }

  private parseAddressComponentsIntoLead(address: Address, lead: LeadInput) {
    if (!address) return;

    const city: string = address.address_components?.find(c => c.types.includes("locality"))?.short_name;
    lead.city = city ? city : lead.city;
    const state: string = address.address_components?.find(c => c.types.includes("administrative_area_level_1"))?.short_name;
    lead.state = state ? state : lead.state;
    const zip: string = address.address_components?.find(c => c.types.includes("postal_code"))?.short_name;
    lead.zip = zip ? zip : lead.zip;
    const street_number: string = address.address_components?.find(c => c.types.includes("street_number"))?.short_name;
    const route: string = address.address_components?.find(c => c.types.includes("route"))?.short_name;
    lead.streetAddress = street_number || route ? street_number + " " + route : lead.streetAddress;
  }

  getEmployee(field: string): Employee {
    const userId: number = +this.lead[field];
    const output = this.employeeStoreService.getUser(userId);
    // console.log(output);
    return output;
  }

  getNoteEmployee(note: Note) {
    const userId: number = note.authorId;
    const output = this.employeeStoreService.getUser(userId);
    console.log(output);
    return output;
  }


  async submitLeadOutput() {
    if (!this.checkForEmptyRequiredFields()) { return }
    if (!this.checkForValidPhoneNumber()) { return }
    this.newNoteFromInput();
    this.formatAppointmentDate();
    this.formatPrequal();
    this.formatSetterInfo();


    this.busySpinnerService.start();
    const res = await this.externalLeadsService.createNewLead(this.lead, this.officeKey, this.companyKey, this.personKey);
    this.busySpinnerService.stop();

    if (res.success) {
      this.resetLead();
    }

  }

  formatAppointmentDate() {
    if (this.appointment.startDateTime) {
      this.lead.appointmentDateTimeUTC = this.dateFormatService.saveDateWithCorrectFormat(this.appointment.startDateTime)
      this.lead.appointmentTitle = this.appointment.title;
    }
  }

  formatPrequal() {
    if (this.lead.preQualification) {
      this.lead.preQualification = this.lead.preQualification.toString();
    }
  }

  formatSetterInfo() {
    this.lead.sourceSetterFirstName = this.personKey ? this.personKey : "";
    this.lead.sourceSetterLastName = this.personKey ? this.personKey : "";
    this.lead.sourceSetterEmail = this.personKey ? this.personKey : "";

  }


  newNoteFromInput() {
    if (!(this.newNote?.length > 0)) return;
    if (this.newNote.length > 1000) { return }
    this.lead.notes.push(this.newNote);
    this.newNote = "";
  }

  getDropDownOptions(field: string) {
    return ['Home Owner', 'Good Roof', 'Good Credit', 'HOA'];
  }

  getFieldTitle(camelCaseWord: string): string {
    if (camelCaseWord === "customerFirstName") { return "First Name" }
    if (camelCaseWord === "customerLastName") { return "Last Name" }
    if (camelCaseWord === "phoneNumber") { return "Phone" }
    if (camelCaseWord === "fullAddress") { return "Address" }
    if (camelCaseWord === "appointmentDate") { return "Date" }
    if (camelCaseWord === "appointmentTime") { return "Time" }

    return camelCaseToSentenceCase(camelCaseWord);
  }

  checkForValidPhoneNumber(): boolean {
    if (this.lead.phoneNumber?.length < 14) {
      let alertString = "Invalid Phone Number";
      alert(alertString);
      return false;
    }

    if (this.lead.phoneNumber?.length >= 15) {
      this.lead.phoneNumber = this.lead.phoneNumber.substring(0, 14);
    }
    return true;
  }


  checkForEmptyRequiredFields(): boolean {
    let emptyRequiredFields: string[] = [];
    let fields = this.getFields();

    fields.forEach(field => {
      if (!this.lead[field] && this.fieldIsRequired(field)) {
        emptyRequiredFields.push(camelCaseToSentenceCase(field))
      }
    })

    if (!this.appointment.startDateTime && this.isCompanyKey()) {
      emptyRequiredFields.push("Appointment Time")
    }

    if (emptyRequiredFields.length > 0) {
      let alertString = "Missing: " + emptyRequiredFields.toString();
      alert(alertString);
      return false;
    }
    return true;
  }

  getFields(_lead: LeadInput = this.lead): string[] {
    return Object.keys(_lead);
  }


  fieldIsRequired(field: string): boolean {
    return this.requiredLeadFields().includes(field);
  }

  private requiredLeadFields(): string[] {
    if (this.isPersonKey()) {
      return [
        "phoneNumber",
        "email",
        "customerFirstName",
        "customerLastName",
        "fullAddress",
      ]
    }
    else {
      return [
        "sourceSetterEmail",
        "sourceSetterFirstName",
        "sourceSetterLastName",
        "customerUtilityBillUrls",
        "phoneNumber",
        "email",
        "customerFirstName",
        "customerLastName",
        "fullAddress",
      ]
    }

  }

  resetLead() {
    this.lead = { ...DEFAULT_LEAD_INPUT };
    this.lead.customerUtilityBillUrls = [];
    this.lead.notes = [];
  }


  isPersonKey() {
    return this.personKey
  }

  isCompanyKey() {
    return this.companyKey || this.officeKey
  }

  getDisclaimer() {
    return "By submitting you agree Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."

  }


}
