import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { fileToResourceFile } from 'src/app/shared/file-functions';
import { ResourceFile } from 'src/app/shared/models/help.model';
import { Series } from 'src/app/shared/models/library.model';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AddNewModalComponent } from '../admin-library/add-new-modal/add-new-modal.component';
import { Section } from '../library.component';
import { SeriesSectionCardComponent } from './series-section-card/series-section-card.component';
import { VideoSectionCardComponent } from './video-section-card/video-section-card.component';

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss'],
})
export class AddSectionComponent implements OnInit {
  section: Section;
  type: string;
  canChangeType: boolean = true;

  videoIndex: number = 0;

  videos: ResourceFile[] = [];
  series: Series[] = [];
  _seriesImages: Map<string, string>;

  constructor(public bottomSheet: MatBottomSheet, public busySpinner: BusySpinnerService, public libraryService: LibraryService, private zone: NgZone, public dialogRef: MatDialogRef<AddSectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private sanitizer: DomSanitizer, public snackbarService: SnackbarService) {

    if (this.data?.section) {
      this.canChangeType = false;
      this.section = this.data.section;

      if (this.section.videosMap.size > 1) {
        this.type = "series"
      }
      else {
        this.type = "videos"
      }

      if (this.type === "series") {
        this._seriesImages = this.data.seriesImages;

        let keys = this.section.videosMap.keys();
        this.section.videosMap.forEach(series => {
          let title = keys.next().value


          let s: Series = {
            title: title,
            resources: [],
            backgroundImage: "",
            id: "",
            section: ""
          }


          s.backgroundImage = this.getImageForSeries(s);

          this.series.push(s)

          series.forEach(videos => {
            s.resources.push(videos);
            // this.videos.push(videos)
          })

        })
      } else if (this.type === "videos") {
        this.videos = Array.from(this.section.videosMap.values())[0]
      }




      //TODO: here I need to extract the videosMap on the section object to the respective videos and series objects
    } else {
      let videosMap: Map<string, ResourceFile[]> = new Map<string, ResourceFile[]>();

      this.section = {
        sectionTitle: "",
        videosMap: videosMap
      }
    }


  }


  getImageForSeries(series: Series): string {
    let url: string = this._seriesImages?.get(series.title);
    if (!url) url = 'https://enzy-bucket1.s3.us-east-2.amazonaws.com/conversation/2050EF94867E-A207-4315-920A-0247E881B2C3.gif';
    series.backgroundImage = url;
    return url;
    // return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }


  ngOnInit() { }

  goBack() {
    this.dialogRef.close();
  }

  setType(type: string) {
    this.type = type;
  }


  addNewVideo() {
    let dialogRef = this.bottomSheet.open(AddNewModalComponent);

    dialogRef.afterDismissed().subscribe((res: {videos: File[], command: string}) => {
      if (!res) { return }
      if (res?.command === "addVideoFromLink") {
        let ref = this.dialog.open(VideoSectionCardComponent, {
          data: {
            section: this.section.sectionTitle,
            shouldShowSeries: false
          }
        });

        ref.afterClosed().subscribe(res2 => {
          if (res2) {
            this.videos.push(res2)
          }
        })
      }
      else if (res?.videos) {
        const resourceFiles: ResourceFile[] = res.videos.map(f => fileToResourceFile(f))
        resourceFiles.forEach((element: ResourceFile) => {
          element.section = this.section.sectionTitle;
          element.resourceType = this.libraryService.selectedResourceType;
          this.videos.push(element);
        });

      }
    })
  }

  removeVideo(v: ResourceFile) {
    const index: number = this.videos.indexOf(v);

    if (index !== -1) {
      this.videos.splice(index, 1);
      if (this.type === "videos") {
        this.section.videosMap.set(this.section.sectionTitle, this.videos)
      }
    }
  }

  editVideo(v: ResourceFile) {
    let ref = this.dialog.open(VideoSectionCardComponent, {
      data: {
        video: v,
        section: this.section.sectionTitle,
        shouldShowSeries: false
      }
    });
  }

  openSeries(series: Series) {
    let ref = this.dialog.open(SeriesSectionCardComponent, {
      // maxWidth: '100vw !important',
      // width: '100%',
      // height: '100%',
      data: {
        series: series
      }
    });
  }

  addNewSeries() {
    let ref = this.dialog.open(SeriesSectionCardComponent, {
      data: {
        section: this.section.sectionTitle,

      }

    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        this.series.push(res)
      }
    })
  }

  save() {
    this.busySpinner.start();

    if (!this.type) {
      this.snackbarService.displaySnackBarMessage("Please select a type.", false);
      this.busySpinner.stop();

      return;
    }
    

    if (this.type === 'videos' && this.videos.length === 0) {

      this.snackbarService.displaySnackBarMessage("You must have add at least 1 video.", false);
      this.busySpinner.stop();

      return;
    }

    if (this.type === 'videos') {
      this.videos.forEach(async (video, index) => {
        let result = await this.libraryService.setResourceFile(video.title, video.videoFile, video.videoUrl, video.thumbnailFile, video.thumbnailUrl, video.trainerName, video.category, video.section, video.resourceType);

        if (!result.success) {
          this.busySpinner.stop();

          this.snackbarService.displaySnackBarMessage("There was an error. Please try again.", false);

          return;
        }

        if (index === this.videos.length - 1) {
          this.busySpinner.stop();
          this.dialogRef.close(this.section)
        }


      })
    }



    if (this.type === 'series' && this.series.length === 0) {

      this.snackbarService.displaySnackBarMessage("You must have add at least 1 series.", false);
      this.busySpinner.stop();

      return;
    }

    if(this.type === 'series') {
      this.series.forEach(s => {
        console.log(s)
      })
    }







  }



  deleteVideo(video: ResourceFile) {
    const index: number = this.videos.indexOf(video);
    if (index !== -1) {
      this.videos.splice(index, 1);
    }
  }





}
