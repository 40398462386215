<div style=" max-height: 60vh; min-height: 60vh; " >
  <div class="app-background-color app-text-color" style="padding: 10px;">

  <ion-grid class="app-toolbar-background-color" style="position: sticky; top: 0;  z-index: 99; padding: 0; margin: 0; height: 120px;">
    <!-- <div style="height: 15px"></div>

    <ion-row style="padding: 0; margin: 0">
      <ion-col size="9" style="padding: 0; margin: 0; padding-right: 5px">
        <input   class="input filter-button" type="text "  placeholder="GIF Search" style="width: 100%; height: 40px; padding-right: 5px; border-radius: 25px; border-width: 0.01em;" [(ngModel)]="searchTerm" (keyup.enter)="search()">
      </ion-col>

      <ion-col size="3" style="padding:0; margin: 0; padding-left: 5px"> 
        <button class="primary-color" style=" width: 100%; height: 40px; border-radius: 25px; " mat-button (click)="search()">
          Search
        </button>      
      </ion-col>
    </ion-row>
    <div style="font-size: 12; padding-left:5px" class="opaque-text">
      <div style="height: 5px"></div>
      <img style="border-radius: 5px; " src="../../../../assets/PoweredBy_200px-White_HorizLogo.png" />
    </div> -->
    <div style="height: 15px"></div>


    <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">



      <div style="width: 100%">
        <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
          <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
            search
          </i>
          <span *ngIf="!searchOn" style="float:left; " class="zeroLineHeight opaque-text">Search Giphy
          </span>

          <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
            style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
            <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchTerm" >

          </mat-form-field>


        </button>

      </div>
      <div style="padding: 5px"></div>
      <div>
        <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
          style="margin-left: -50px; z-index: 999;">
          <i class="material-icons" style="font-size: 14px;">
            clear
          </i>
        </button>
      </div>
      <div style="padding: 5px;"></div>

      <div>
        <button (click)="search()" class=" primary-color" style=" border-radius: 22px;
        font-size: 14px;
        width: 100%;
        height: 40px;" mat-button>
          Search
        </button>
      </div>


    </div>
    <div style="font-size: 12; padding-left:5px" class="opaque-text">
      <div style="height: 5px"></div>
      <img style="border-radius: 5px; " src="../../../../assets/PoweredBy_200px-White_HorizLogo.png" />
    </div>
  </ion-grid>
 

  <ion-grid style="width: 100%">
    <ion-row>
        <ion-col size="6" *ngFor="let result of giphyService.searchResults$ | async">

          <button mat-button style="padding: 0; width: 100%;" (click)="grabGif(result.images.fixed_width.url)">
            <img style="border-radius: 5px; width: 100%; display: block; margin: auto;"  [src]="result.images.fixed_width.url" [alt]="result.title">

          </button>
          <div style="height: 5px"></div>
        </ion-col>




    </ion-row>

    <ion-row>
      <button  *ngIf="giphyService.pageSize !== 0 && this.searchWasPressed" (click)="loadMore()" mat-button style="width: 100%">Load More</button>
    </ion-row>
  </ion-grid>


</div>

</div>