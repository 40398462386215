import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { FullReaction } from 'src/app/shared/services/messaging.service';
import { NgZone } from "@angular/core";

@Component({
  selector: 'app-reactions-modal',
  templateUrl: './reactions-modal.component.html',
  styleUrls: ['./reactions-modal.component.scss'],
})
export class ReactionsModalComponent implements OnInit {

  reactions: FullReaction[];

  reactionOptions: string[] = [];

  reactionsMap: Map<string, FullReaction[]>;
  currentReaction: string;

  constructor(private zone: NgZone, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { 
    this.mapReactions(this.data.reactions);
    this.currentReaction = this.data.selectedReaction;
    this.segmentChanged(this.currentReaction);
  }

  ngOnInit() {}


  getDebugString(): string {
    return JSON.stringify(this.reactions)
  }

  mapReactions(reactionList: FullReaction[]) {
    this.reactionsMap = new Map<string, FullReaction[]>();
    reactionList.sort();
    for (let reaction of reactionList) {
      const currentList = this.reactionsMap.get(reaction.reaction);
      if (currentList) {
        currentList.push(reaction);
        this.reactionsMap.set(reaction.reaction, currentList)
      } else {
        this.reactionsMap.set(reaction.reaction, [reaction]);
      }
    }

    this.reactionOptions = this.getReactionOptions();
    this.reactions = this.reactionsMap.get(this.reactionOptions[0]);

   
  }

  ionViewWillLeave() {
    this.currentReaction = "";
  }

  ionViewWillEnter(): void {
    this.mapReactions(this.data?.reactions);

  }

  getReactionOptions(): string[] {
    return Array.from(this.reactionsMap.keys());
  }

  getReactionsByReactionType(reactionType: string): FullReaction[] {
    return this.reactionsMap.get(reactionType);
  }

  segmentChanged(reaction: string): void {
    this.reactions = this.reactionsMap.get(reaction);
  }



}

