import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/shared/models/employees.model';
import { LeaderboardCell } from 'src/app/shared/models/leaders.model';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { LeadersStoreService } from 'src/app/shared/services/leaders-store.service';
import { LeaderboardDrillDownService } from '../services/leaderboard-drill-down.service';

@Component({
  selector: 'app-leaderboard-cell',
  templateUrl: './leaderboard-cell.component.html',
  styleUrls: ['./leaderboard-cell.component.scss'],
})
export class LeaderboardCellComponent implements OnInit {
  routeId: string;
  leaderboardCell: LeaderboardCell;


  forOption: string;
  @Input() set forOptionInput(newForOption: string) {
    this.forOption = newForOption;
  }

  @Input() showImage: boolean = true;
  @Input() showBar: boolean = false;

  selectedIndex: number;
  @Input() set selectedIndexInput(newSelectedIndex: number) {
    this.selectedIndex = newSelectedIndex;
  }


  @Input() set leaderboardCellInput(newleaderboardCell: LeaderboardCell) {
    this.leaderboardCell = newleaderboardCell;
  }

  constructor(
    private drillDownService: LeaderboardDrillDownService, 
    private _route: ActivatedRoute, 
    private leadersStoreService: LeadersStoreService, 
    public employeeStoreService: EmployeeStoreService) { }

  ngOnInit() {
    this.routeId = this._route.snapshot.paramMap.get('id');
  }

  getCellTrendString(): string {
    let val = this.leaderboardCell.trend + "";

    if (val[0] === '-') {
      val = val.substring(1);
    }

    return val;
  }

  async drillDown() {
    this.drillDownService.listDrillDown(
      this.leaderboardCell, 
      this.forOption, 
      this.selectedIndex, 
      +this.routeId, 
      this.showBar);
  }

  cellHasThumbnailImage(): boolean {
    return this.leaderboardCell?.imageThumbnail !== null && this.leaderboardCell?.imageThumbnail?.length > 0;
  }

  getCellInitials(): string {
    const titleArray: string[] = this.leaderboardCell.title.split(" ")
    let output: string = "-";
    if (titleArray.length>0 && titleArray[0]?.length > 0) output = titleArray[0][0];
    if (titleArray.length>1 && titleArray[1]?.length > 0) output += titleArray[1][0];
    return output.toUpperCase();
  }

  private getBarMax(): number {
    return this.leadersStoreService.getBarMax(+this.routeId);
  }

  getBarValue(): number {
    const value = this.leaderboardCell?.value;
    if (!value) return;
    if (value.includes("%")) {
      return +(value.replace("%", ""));
    }
    const max = this.getBarMax();
    return +value * (100/max);
  }

  getShowBar(): boolean {
    return this.showBar;
  }

  showRank(): boolean {
    return !!this.leaderboardCell.rank && !this.showBar;
  }

  showTrend(): boolean {
    return this.leaderboardCell?.trend !== null  && !this.showBar;
  }



}
