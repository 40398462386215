import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  eventName: string = "";
  location: string = "";
  constructor(private navCtrl: NavController) { }

  ngOnInit() {}


  goBack() {
    this.navCtrl.back();
  }

}
