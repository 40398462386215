<div *ngIf="message.reportLines.length > 0">
  <div 
    style="padding: 10px; font-size: 14px; font-weight: 500; text-align: left; width: 100%; display: block;">
    <!-- ({{getMessagedNumber()}}/ {{message.reportLines.length}})  -->

    <ion-toolbar   class="transparent-bg">
      <div slot="start" matRipple class="example-ripple-container"  style="width: 100%; height: 100%; line-height: 60px;" (click)="toggleExpand()">
        {{this.message.contents.split(':')[0]}}
      </div>

      <div slot="end">
        <button mat-button class="reactionsLabel opaque-div opaque-text" style="font-weight: 100;">
          <mat-icon style="font-weight: 100;">check_box</mat-icon> <span>{{getMessagedNumber()}}</span>
        </button>
      </div>

      <div slot="end" (click)="messageUnmessagedUsers()">
        <button mat-button class="reactionsLabel opaque-div opaque-text" style="font-weight: 100;">
          <mat-icon style="font-weight: 100;">check_box_outline_blank</mat-icon> <span>{{getUnmessagedNumber()}}</span>
        </button>
      </div>
    </ion-toolbar>


  </div>

  <mat-divider *ngIf="this.isExpanded"></mat-divider>

  <div *ngIf="isExpanded" style="padding: 10px;">



    <div *ngFor="let line of message.reportLines">
      <ion-toolbar class="transparent-bg opaque-div-extra-light" style=" border-radius: 50px;"
        (click)="showDetail(line)">
        <div slot="start">
          <app-user-icon [shouldExpandImage]="true" [isGridSmall]="true" [employee]="getEmployee(line.lineUserId)"
            [initials]="getInitials(line.lineUserId)"></app-user-icon>
        </div>

        <div slot="start">
          <div style="width: 10px;"> </div>
        </div>

        <div slot="start" style=" margin-top: -2px">
          <span style="font-size: 14px">{{line.lineName.split(",")[0] }}</span>
          <br *ngIf="line.lineValue">
          <span *ngIf="line.lineValue" style="margin-top: -5px; font-size: 18px; ">{{line.lineValue}}</span>
          <br>
          <span style="margin-top: -5px; font-size: 14px" class="opaque-text">{{line.lineTime}}</span>
        </div>

        <div slot="end">

          <mat-icon style="font-weight: 100;" *ngIf='isCompleted(line)' class="opaque-text">check_box</mat-icon>
          <mat-icon style="font-weight: 100;" *ngIf='!isCompleted(line)' class="opaque-text">check_box_outline_blank</mat-icon>

          <!-- <span class="material-icons opaque-text">
            navigate_next
          </span> -->
        </div>

        <div slot="end">
          <div style="width: 15px;"></div>
        </div>

      </ion-toolbar>

      <div style="height: 10px;"></div>

    </div>

  </div>

  <!-- <button mat-button (click)="toggleExpand()" class="opaque-text" style="width: 100%;">
    <span *ngIf="!this.isExpanded">
      OPEN
    </span>


    <span *ngIf="this.isExpanded">CLOSE</span>

  </button> -->
</div>

<div *ngIf="message.reportLines.length === 0" style="padding: 10px">
  <ion-label text-wrap>
    <span style="font-size: 17px; white-space: pre-line;">{{this.message.contents}}</span>
  </ion-label>
</div>