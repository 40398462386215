<div style=" overflow-y: hidden; height: 100vh">
  <ion-header>
    <div style="width: 100%; z-index: 999;">
      <ion-toolbar no-border class="transparentHeader" >
        <button mat-fab class="circle-button" style="text-align: center; box-shadow: none; margin-left: -5px;" (click)="back()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
        <div style=" max-width: 600px; display: block; margin: auto;">

        <p style="text-align: center;  font-size: 22px"> {{data.name}} </p>

        <mat-form-field class="example-chip-list" style="width: 100%; padding: 10px;">
          <mat-chip-list #chipList >
            <ion-grid>
              <ion-row>
                <mat-chip  *ngFor="let option of selectedOptions" [selectable]="selectable"
                [removable]="removable" (removed)="remove(option)">
                  {{option}} 
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
              </ion-row>
              <ion-row>
                <input placeholder="Search"
                (keyup)="applyFilter($event)"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)"
               >
              </ion-row>
            </ion-grid>
           
          </mat-chip-list>
        </mat-form-field>
      </div>
      </ion-toolbar>
    </div>
  </ion-header>
  
  <ion-content>
    <div  style="padding: 10px;">

      <div style="max-width: 600px; display: block; margin: auto;">
        <div *ngFor="let option of formattedOptions;">
          <button  style="width: 100%; text-align: left; padding-left: 0;" [ngClass]="{'selected': selectedOptions.includes(option), 'not-selected': !selectedOptions.includes(option) }" mat-button (click)="selectOption(option)">
            <div>{{option}}</div>
          </button>
        </div>
        <div style="height: 400px;"></div>
      </div>
    </div>
  </ion-content>
 
</div>
