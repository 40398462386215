<!-- <mat-spinner></mat-spinner> -->
<!-- <mat-spinner style="padding: 20px !important;" [diameter]='getSpinnerDiameter()' class="custom-spinner"></mat-spinner> -->
<div style="background-color: white; height: 75px; width: 75px;">
    <div style="height: 15px;"></div>
    <img style="width: 75px; display: block; margin: auto;" src="{{gifSrc}}" />


    <!-- <div  ng-init="varname='../../../../assets/animatelogo.gif'">
        <img ng-src="{{varname}}">
</div> -->


<!-- <div class="col-xs-12 text-center">
    <img src="" id="gif" alt="">
</div> -->



</div>
