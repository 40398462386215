import { Component, Input, OnInit } from '@angular/core';
import { MessageModel } from 'src/app/shared/services/messaging.service';

@Component({
  selector: 'app-reply-card',
  templateUrl: './reply-card.component.html',
  styleUrls: ['./reply-card.component.scss'],
})
export class ReplyCardComponent implements OnInit {

  @Input()
  message: MessageModel

  @Input()
  imageUrl: string = "";


  displaySender: string = "";
  displayContent: string = "";
  constructor() {

  }

  ngOnInit() {
    if (this.message) {
      let body = this.message.contents;

      if(body.startsWith("RπPLY")) {
         body = this.message.contents.substring(5, this.message.contents.length);

         let responseCard = body.split("π");


         this.displaySender = responseCard[0];
         this.displayContent = responseCard[1];

      }
      else {
        this.displayContent = body;
        this.displaySender = this.message.fromUserName;
      }

    


    }
  
  }




  isGif(){
    let isGiphy = this.message.contents.includes("https://") && this.message.contents.includes("giphy.com");
    return isGiphy;
  }

  isImage(){
    return this.message.messageImage;
  }

  isImageResponse(){
    return this.message.contents.includes("https://enzy-bucket1");
  }

}
