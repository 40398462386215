
  <mat-toolbar style="position: fixed; top: 0; width: 100%; height: 200px; padding: 0; background-color: #383838; background-image: url({{this.incentive?.backgroundURL}}); background-size: cover;">
    <div style="width: 100%;">
      <div style="height: 30px"> </div>

      <ion-toolbar class="no-padding-header transparent-bg">
        <div slot="start">
          <button mat-fab class="app-text-color"
            style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important; color: white"
            (click)="goBack()">
            <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
          </button>
        </div>

        <div slot="end">
          <!-- <button mat-fab class="app-text-color"
            style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important; color: white"
            (click)="loadMilestoneData()">
            <mat-icon style="text-align: center; padding-left: 5px;">table_chart</mat-icon>
          </button> -->
        </div>

   

      </ion-toolbar>

      
      


      <div class="gradient-detail">
        <ion-grid style="padding: 0; margin: 0;  padding: 0; margin: 0;  ">
          <ion-row style="padding: 0; margin: 0;">
            <ion-col size="2" style="text-align: center; ">
            </ion-col>

            <ion-col size="8" style="text-align: center; padding: 0; margin: 0;">

              <div style="height: 50px"> </div>
              <ion-grid style="padding: 0; margin: 0;">
                <ion-row style="padding: 0; margin: 0;">
                  <ion-col style="padding: 0; margin: 0;">
                    <h1 style="padding: 0; margin: 0; font-size: 26px"> {{incentive?.campaignName}} </h1>
                  </ion-col>
                </ion-row>

                <ion-row style="padding: 0; margin: 0;">
                  <ion-col style="padding: 0; margin: 0;">
                    <p style="padding: 0; margin: 0; font-size: 14px; font-weight: 100;">
                      Your Installs </p>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>

            <ion-col size="2" style="text-align: right;">

            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

    </div>
  </mat-toolbar>

  <ion-content>
  <div style="height: 200px;"></div>
  <div style="padding: 20px" class="darkTheme">
    <div style="max-width: 400px; display: block; margin: auto;">

      <ion-grid >
        <ion-row>
          <ion-col size="1">#</ion-col>
          <ion-col size="5">Name</ion-col>

          <ion-col size="2">kW</ion-col>
          <ion-col size="4">Install Date</ion-col>
        </ion-row>
      </ion-grid>


     <div *ngFor="let install of installs; let i = index">

      <ion-grid >
        <ion-row style="font-weight: 100;" >
            <ion-col size="1">{{i+1}}</ion-col>
            <ion-col size="5">{{install['Customer Name']}}</ion-col>

            <ion-col size="2">{{install['Kilowatts']}}</ion-col>
            <ion-col size="4">{{install['Installation Date']}}</ion-col>
          </ion-row>
        </ion-grid>
      
     </div>

    </div>
  </div>
</ion-content>