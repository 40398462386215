import { string } from '@amcharts/amcharts4/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { LeadsService } from '../leads.service';

@Component({
  selector: 'enzy-lead-manager-admin',
  templateUrl: './lead-manager-admin.component.html',
  styleUrls: ['./lead-manager-admin.component.scss'],
})
export class LeadManagerAdminComponent implements OnInit {

  adminAssignments: AdminAssigment[] = TEST_ADMIN_ASSIGNMENTS;
  teamManagerAssignments: AdminAssigment[] = TEST_ADMIN_ASSIGNMENTS;
  searchVal: string = "";
  constructor(
    public employeeStoreService: EmployeeStoreService,
    public leadsService: LeadsService,
    public dialogRef: MatDialogRef<LeadManagerAdminComponent>, 

  ) { }

  ngOnInit() {
    this.initAdmins();
    this.initTeamManagers();
  }



  getSortedAssignments(): AdminAssigment[] {
    let assignments = this.adminAssignments.sort((a, b) => {
      if (a.team===b.team) return 0;
      return b.team < a.team ? 1 : -1;
    })
    return assignments.filter(a => a.team.toLowerCase().includes(this.searchVal.toLowerCase()) )
  }
  
  async initAdmins() {
    const res: AdminAssigment[] = await this.leadsService.getTeamLeadAdmins();
    this.adminAssignments = res;
  }

  async initTeamManagers() {
    const teamManagers: AdminAssigment[] = await this.leadsService.getTeamManagers();
    this.teamManagerAssignments = teamManagers;
  }

  getEmployee(userId: number) {
    return this.employeeStoreService.getUser(userId);
  }

  getLeadAdminUserIds(assignment: AdminAssigment): number[] {
    return assignment.userIds;
  }

  getTeamManagerUserIds(team: string): number[] {
    return this.teamManagerAssignments.find(a=>a.team===team)?.userIds;
  }

  async selectNewEmployees(assignment: AdminAssigment) {
    const newUserIds: number[] = (await this.employeeStoreService.selectUsersModal(assignment.userIds));
    if (newUserIds){
      assignment.userIds = newUserIds;
      this.leadsService.updateLeadAdmin(newUserIds, assignment.team);
    }
  }

  async selectNewTeamManagers(team: string) {
    const assignment: AdminAssigment = this.teamManagerAssignments.find(a=>a.team===team);
    const newUserIds: number[] = (await this.employeeStoreService.selectUsersModal(assignment?.userIds));
    if (newUserIds){
      if (assignment)
        assignment.userIds = newUserIds;
      this.leadsService.updateTeamManagers(newUserIds, team);
    }
  }

  close() {
    this.dialogRef.close();
  }

}

export interface AdminAssigment {
  team: string,
  type: string, // team manager, lead administrator
  userIds: number[]
}

export const TEST_ADMIN_ASSIGNMENTS = [
  // {team: "Tampa", userIds: [489, 2]},
  // {team: "Houston", userIds: [19]},
]