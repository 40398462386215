<h1 *ngIf='trainingVideos.length>0' style="font-size: 20px; font-weight: 500; padding-left: 20px; margin-bottom: 0;">
  {{ title }}
</h1>
<div *ngIf='trainingVideos.length>0' class="report-carousel" style="overflow-y: hidden; padding: 0px;">

  <div *ngFor="let video of trainingVideos"
    style=" margin-left: 20px;display: block;min-width: 300px; height: 180px; background-color: #383838; background-image: url({{video.thumbnailUrl}}); background-size:cover;"
    class="section-div" (click)="openVideoMessage(video.videoUrl)">
    <div style="min-width: 200px;display: block;" >

      <!-- -->
      <ion-grid style="padding: 0;">
          <ion-row>
            <ion-col size="12" style="padding: 0">
              <div>

                <ion-grid style="margin: 0; padding: 10px;">
                  <ion-row>
                    <ion-col size="12" style="padding: 0; margin: 0">
                      <div style="position: relative; text-align: left;">
                        <p
                          style="font-size: 14px; min-height: 20px; margin-top: 10px; font-weight: 500; padding-left: 5px;">
                          {{video.title | titlecase }}
                        </p>
                        <!-- <p
                          style="font-size: 14px; min-height: 20px; margin-top: 10px; font-weight: 500; padding-left: 5px;">
                          {{video.market | titlecase }}
                        </p> -->
                      </div>
                    </ion-col>

               
                  </ion-row>
                </ion-grid>
               
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
    </div>
  </div>

  <div style="padding: 10px;"></div>

</div>