import { Component, Input, OnInit } from '@angular/core';
import { MatchParticipant } from '../edit-tournament/edit-tournament.component';

@Component({
  selector: 'app-championship-card',
  templateUrl: './championship-card.component.html',
  styleUrls: ['./championship-card.component.scss'],
})
export class ChampionshipCardComponent implements OnInit {

  @Input()
  matchParticipants: MatchParticipant[];


  constructor() { }

  ngOnInit() {}

  getNumberOfRows(): number {
    return Math.floor(this.matchParticipants.length/2 ) + (this.matchParticipants.length % 2)
  }

  getLeftParticipantForRow(rowNumber: number): MatchParticipant {
    return this.matchParticipants[rowNumber * 2];
  }

  getRightParticipantForRow(rowNumber: number): MatchParticipant {
    const index = rowNumber * 2 + 1;
    if (index >= this.matchParticipants.length) {
      return null;
    }
    return this.matchParticipants[index];
  }

}

