import { Component, OnInit } from '@angular/core';
import { TournamentModel } from 'src/app/shared/services/incentives.service';

@Component({
  selector: 'app-new-competition-incentive',
  templateUrl: './new-competition-incentive.component.html',
  styleUrls: ['./new-competition-incentive.component.scss'],
})
export class NewCompetitionIncentiveComponent implements OnInit {

  competition: TournamentModel;

  constructor() { }

  ngOnInit() {}

}

