import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { MessageSelectRecipientComponent } from 'src/app/messaging/message-select-recipient/message-select-recipient.component';
import { Employee } from '../../models/employees.model';
import { EmployeeStoreService } from '../../services/employee-store.service';
import { MessageModel, ReportLine } from '../../services/messaging.service';

@Component({
  selector: 'app-enzy-assistant-card',
  templateUrl: './enzy-assistant-card.component.html',
  styleUrls: ['./enzy-assistant-card.component.scss'],
})
export class EnzyAssistantCardComponent implements OnInit {

  isExpanded: boolean = true;

  message: MessageModel; 
  @Input() set messageInput(newMessage: MessageModel) {
    this.message = newMessage;
  }

  @Output() activeReportLines = new EventEmitter<ReportLine[]>();

  constructor(public dialog: MatDialog, private employeeStoreService: EmployeeStoreService, public router: Router) { }

  ngOnInit() {}

    getUserImage(lineUserId: number) {
    return this.employeeStoreService.getUser(lineUserId).userDto.imageUrl ? this.employeeStoreService.getUser(lineUserId).userDto.imageUrl : "../../../assets/headshots/default_avatar.png"
  }


  getEmployee(lineUserId: number) {
    const output = this.employeeStoreService.getUser(lineUserId)
    
    return output;
  }

  initials: string;
  getInitials(lineUserId: number): string {
    let employee = this.employeeStoreService.getUser(lineUserId)
    if (employee && employee.userDto) {
      const firstInitial: string = employee?.userDto?.preferredFirstName?.length > 0 ? employee?.userDto?.preferredFirstName[0] : "";
      const lastInitial: string = employee?.userDto?.lastName?.length > 0 ? employee?.userDto?.lastName[0] : "";
      return firstInitial + lastInitial;
    }
    if (this.initials) {
      return this.initials;
    }
    return '';
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  showDetail(reportLine: ReportLine) {
    let employee = this.employeeStoreService.getUser(reportLine.lineUserId)
    this.activeReportLines.emit([reportLine]);

    this.router.navigateByUrl('/home-drill-down/employee', { state: { employee: employee, reportLine: reportLine } });

  }

  isCompleted(line: ReportLine): boolean {
    return line.isCompleted;
  }

  getMessagedNumber() {
    let val = 0;
    this.message.reportLines.forEach(element => {
        if (this.isCompleted(element)) {
          val += 1;
        }
    });
    return val;
  }

  getUnmessagedNumber() {
    return this.message.reportLines.length - this.getMessagedNumber();
  }



  async messageUnmessagedUsers() {
    let incompleteLines: ReportLine[] = this.message.reportLines.filter(line=>!line.isCompleted);

    // Use the incomplete lines to open the select recipients page
    const employeesToMessage: Employee[] = incompleteLines.map(line => this.employeeStoreService.getUser(+line.lineUserId));

    let ref = this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        employeesToMessage: employeesToMessage,
        messageReportLines: incompleteLines,
        isBCC: true,

      },
      panelClass: 'transparent',
    })

    const res = await ref.afterClosed().toPromise();
    if (!res) { return }
    console.log(res)
    if (res.selectedUsers_toSend?.length > 0) {
      incompleteLines = this.filterReportLinesBySelectedUsers(incompleteLines, res.selectedUsers_toSend);
    }
    if (!res.isBCC) {      
      this.router.navigateByUrl('/messages/'+res.conversationId, { state: { messages: res.messages, reportLines: incompleteLines } });

    } else if (res.isBCC) {
        this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend, reportLines: incompleteLines } });
    }
  }


  filterReportLinesBySelectedUsers(reportLines: ReportLine[], selectedUsers: Employee[]): ReportLine[] {
    return reportLines.filter(l=> selectedUsers.find(e=>+e.userDto?.id === +l.lineUserId));
  }
}
