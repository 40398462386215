import { Component, OnInit } from '@angular/core';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadsStoreService } from '../leads-store.service';
import { Appointment, LeadModel, Task } from '../leads.model';
import { LeadsService, tasksIn, tasksSortComparator, appointmentsIn, appiontmentSortComparator } from '../leads.service';


@Component({
  selector: 'enzy-lead-calendar',
  templateUrl: './lead-calendar.component.html',
  styleUrls: ['./lead-calendar.component.scss'],
})
export class LeadCalendarComponent implements OnInit {


  myUserId: number;
  appointments: Appointment[] = [];
  selectedFilter: string = "This Week";
  selectedType: string = "Appointments"



  constructor(public leadsService: LeadsService, public leadsStoreService: LeadsStoreService, public userDetailsService: UserDetailsService, public dateFormatService: DateFormatService) {
    this.initCalendar();

  }

  ngOnInit() {
  }


  async initCalendar() {
    this.myUserId = +(await this.userDetailsService.getUserId());
    this.appointments = this.getAppointments();
  }


  getMyLeads(): LeadModel[] {
    return this.leadsStoreService.getLeads().filter(t => (JSON.stringify(t))).filter(t => (t.assignedRep === this.myUserId));
  }




  // ----------------------------- TASKS -----------------------------

  getTasks(): Task[] {
    return tasksIn(this.getMyLeads()).sort((a, b) => tasksSortComparator(a, b));
  }

  getTasksByFilter(): Task[] {
    return this.getTasks().filter(task => this.taskMeetsFilter(task));

  }

  taskMeetsFilter(task: Task): boolean {
    return this.timeMeetsFilter(task.dueDate);
  }


  getLeadAssociatedWithTask(task: Task) {
    const lead: LeadModel = this.getMyLeads().find(l => l?.tasks?.includes(task));
    return lead
  }


  getNameFromTask(task: Task) {
    let lead = this.getLeadAssociatedWithTask(task);
    let firstName = lead.customerFirstName ? lead.customerFirstName : "";
    let lastName = lead.customerLastName ? lead.customerLastName : "";
    return firstName + " " + lastName;
  }


  openLeadForTask(task: Task) {
    const lead: LeadModel = this.getMyLeads().find(l => l?.tasks?.includes(task));
    if (lead)
      this.openCardPopup(lead);
  }



  saveTaskChanges(task: Task) {
    // console.log(task)
    let lead = this.getLeadAssociatedWithTask(task);
    this.leadsService.saveLeadTasks(lead);
  }



  // ----------------------------- APPOINTMENTS -----------------------------
  getAppointments(): Appointment[] {
    return appointmentsIn(this.getMyLeads()).sort((a, b) => appiontmentSortComparator(a, b));
  }

  getAppointmentsByFilter() {
    return this.getAppointments().filter(appt => this.apptMeetsFilter(appt))
  }


  private apptMeetsFilter(appt: Appointment) {
    return this.timeMeetsFilter(appt.startDateTime);
  }

  private timeMeetsFilter(time: string) {
    if (this.selectedFilter === 'This Week') return this.dateFormatService.isThisWeek(time);
    else if (this.selectedFilter === 'Today') return this.dateFormatService.isToday(time);
    else if (this.selectedFilter === 'Tomorrow') return this.dateFormatService.isTomorrow(time);
    else if (this.selectedFilter === 'This Week') return this.dateFormatService.isThisWeek(time);
    else if (this.selectedFilter === 'Past') return this.dateFormatService.isPast(time);
    return true;
  }

  getLeadAssociatedWithAppointment(appointment: Appointment) {
    const lead: LeadModel = this.getMyLeads().find(l => l?.appointments?.includes(appointment));
    return lead
  }


  getNameFromAppointment(appointment: Appointment) {
    let lead = this.getLeadAssociatedWithAppointment(appointment);
    let firstName = lead.customerFirstName ? lead.customerFirstName : "";
    let lastName = lead.customerLastName ? lead.customerLastName : "";
    return firstName + " " + lastName;
  }

  openLeadForAppointment(appointment: Appointment) {
    const lead: LeadModel = this.getMyLeads().find(l => l?.appointments?.includes(appointment));
    if (lead)
      this.openCardPopup(lead);
  }




  // ----------------------------- OTHER -----------------------------


  selectFilter(filter: string) {
    this.selectedFilter = filter;
  }


  selectType(type: string) {
    this.selectedType = type;
  }

  getFilterOptions(): string[] {
    return [
      'This Week',
      'Today',
      'Tomorrow',
      'Past',
      'All'
    ]
  }


  getTypeOptions(): string[] {
    return [
      'Appointments',
      'Tasks',

    ]
  }



  async openCardPopup(lead: LeadModel) {
    const editedLead: LeadModel = await this.leadsService.openLeadUsingModal(lead, "view");
    // await this.leadsService.safeUpdateLead(lead, editedLead);
    lead = editedLead;
  }


}
