import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { camelCaseToSentenceCase } from '../../generic-functions';

@Component({
  selector: 'enzy-picker-modal',
  templateUrl: './picker-modal.component.html',
  styleUrls: ['./picker-modal.component.scss', ],
})
export class PickerModalComponent implements OnInit {

  singleSelect: boolean = false;
  type: string = "";
  options: string[] = [];

  selectedItems: string[] = [];


  searchValue: string = "";
  constructor(
    public dialogRef: MatDialogRef<PickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { singleSelect?: boolean, type?: string, options?: string[] },
  ) {
    if (data?.singleSelect) {
      this.singleSelect = data.singleSelect;
    }

    if (data?.type) {
      this.type = data.type;
    }


    if (data?.options) {
      this.options = data.options;
    } else {
      this.options = []
    }

  }

  getAllOptions(): string[] {
    return this.options.filter(option => option.toLowerCase().includes(this.searchValue))
  }

  getSentenceCase(option: string) {
    return camelCaseToSentenceCase(option)
  }

  selectedItemsToShow() {
    return this.selectedItems
  }

  unselectItem(index: number) {
    this.selectedItems.splice(index, 1);
  }


  ngOnInit() { }


  add(option: string) {
    this.selectedItems.push(option)
    this.searchValue = "";
    if (this.singleSelect) {
      this.close()
    }
  }
  close() {
    this.dialogRef.close({ selectedOptions: this.selectedItems })
  }
}
