<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<ion-header no-border class="no-padding-header ion-no-border">
  <div style="width: 100%;">

  <ion-toolbar class="no-padding-header  ">
    <div slot="start">

      <button mat-fab class="app-text-color"
        style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
        (click)="goBack()">
        <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
      </button>
    </div>

    <div slot="end" *ngIf='selectUserMode'>
      <button mat-button class=" primary-color" style=" border-radius: 22px;
        font-size: 14px;
        display: block; margin: auto;" (click)="messagePeopleInReport(selectedReport)">
        Next
      </button>
    </div>
    <div slot="end" *ngIf='selectUserMode'>
      <div style="width: 5px;"></div>
    </div>

    <div slot="end" *ngIf='selectUserMode'>
      <button class=" primary-color" style=" border-radius: 22px;
        font-size: 14px;
        display: block; margin: auto;" mat-icon-button (click)='exitSelectUserMode()'>
        <i class="material-icons">
          close
        </i>
      </button>

    </div>


    <div slot="end" *ngIf='!selectUserMode && !shouldShowDownload()'>
      <button (click)="downloadViaEmail(selectedReport)"  style="border-radius: 22px; font-size: 14px; display: block; margin: auto;" mat-icon-button>
        <mat-icon>download</mat-icon>
      </button>
    </div>


    <div slot="end" *ngIf='!selectUserMode && shouldShowDownload()'>
      <button (click)="download(selectedReport)"  style="border-radius: 22px; font-size: 14px; display: block; margin: auto;" mat-icon-button>
        <mat-icon>download</mat-icon>
      </button>
    </div>

    <div slot="end">
      <div style="width: 10px;"></div>
    </div>

    <div slot="end" *ngIf='!selectUserMode && selectedReport?.freshReport?.isFavorite'>
      <button (click)="removeFavorite(selectedReport)"  style="border-radius: 22px; font-size: 14px; display: block; margin: auto;" mat-icon-button>
        <mat-icon>favorite</mat-icon>
      </button>
    </div>


    <div slot="end" *ngIf='!selectUserMode && selectedReport?.freshReport?.isFavorite === false'>
      <button (click)="favorite(selectedReport)"  style=" border-radius: 22px; font-size: 14px; display: block; margin: auto;" mat-icon-button>
        <mat-icon>favorite_border</mat-icon>
      </button>
    </div>

    <div slot="end">
      <div style="width: 10px;"></div>
    </div>

    <div slot="end" *ngIf='!selectUserMode && isMessagableReport(selectedReport)'>
      <button (click)="enterSelectUserMode()" class="primary-color" style=" border-radius: 22px; font-size: 14px;display: block; margin: auto;" mat-icon-button>
        <i class="material-icons">
          chat
        </i>
      </button>
    </div>

    


    <div slot="end">
      <div style="width: 10px;"></div>
    </div>

  </ion-toolbar>

  <ion-toolbar no-border class="no-padding-header transparentHeader ">


    <div style="padding-bottom: 0; margin: 0; ">
      <ion-grid style="padding: 0; margin-top: -20px;">
        <ion-row style="padding: 10px; margin: 0;">
          <h1 style="text-align: center; width: 100%; font-size: 24px !important;">
            {{selectedReport?.freshReport.reportName}}
          </h1>
        </ion-row>
      </ion-grid>
    </div>



  </ion-toolbar>
  </div>
</ion-header>

<ion-content>
  <div style="padding: 0px;" *ngIf="this.selectedReport" >
    <app-report-detail [selectUserMode]='selectUserMode' [reportInput]="selectedReport" class="mat-elevation-z3">
    </app-report-detail>

  </div>
</ion-content>