import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    constructor(public snackbar: MatSnackBar) { }

    displaySnackBarMessage(message: string,  success: boolean = true, duration: number = 3000, actionLabel: string = "OK") {
        this.snackbar.open(message, actionLabel, {
          duration: duration,
          panelClass: success ? ['app-success-snackbar'] : ['app-failure-snackbar']
        })
      }
}
