import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-edit-message-modal',
  templateUrl: './edit-message-modal.component.html',
  styleUrls: ['./edit-message-modal.component.scss'],
})
export class EditMessageModalComponent implements OnInit {

  @ViewChild('messageInput')
  messageInputComponent: ElementRef;

      @ViewChild('autosize',{static: false}) autosize: CdkTextareaAutosize;
  public messageContents: string = "";

  constructor(public dialogRef: MatDialogRef<EditMessageModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.messageContents = this.data.messageContents;
    console.log (this.data.messageContents)

  }

  ngAfterViewInit() {
    this.messageInputComponent.nativeElement.value = this.messageContents;

  }

  sendMessage() {
    if (this.messageContents.length === 0) { this.dialogRef.close({event: 'cancel'})}
    this.dialogRef.close({event: 'sendMessage', message: this.messageInputComponent.nativeElement.value})
  }

  cancel() {
    this.dialogRef.close({event: 'cancel'});
  }

}
