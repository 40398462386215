<button mat-button class="card" style="padding: 0; margin: 0;">

  <mat-card class="card"
    style="background-color: #383838; background-image: url({{incentive.thumbnailUrl}}); background-size:cover;  padding: 0; margin: 0;">

    <div class="top">
      <ion-toolbar class="transparent-bg">
        <div slot="end">
          <mat-icon class="specialText" *ngIf="incentive.isLocked"> lock</mat-icon>
        </div>
        <div slot="end">
          <div style="width: 10px;"></div>
        </div>
      </ion-toolbar>
    </div>

  </mat-card>
</button>