import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { ThemeService } from 'src/app/shared/services/theme-service.service';

export const DEFAULT_LINE_CHART_CONFIG: LineChartConfig = {
  height: null,
  showAxis: null
}
export class LineChartConfig {
  height: number;
  showAxis: boolean;
}



@Component({
  selector: 'enzy-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnDestroy {
  chart: am4charts.XYChart;
  public categoryAxis;
  public valueAxis;
  public lineSeries5;
  compInitialzed: boolean = false;

  data: any;
  @Input() set dataInput(newData: any) {
    if (newData.length !== 0) {
      this.data = newData;
      this.setUpLineChart();
    }
  }

  @Input() lineChartConfig: LineChartConfig = { ...DEFAULT_LINE_CHART_CONFIG }


  constructor(public themeService: ThemeService, private zone: NgZone) { }
  ngOnDestroy(): void {
    this.chart?.dispose();
    this.compInitialzed = false;
  }

  ngOnInit() {
  }





  @ViewChild('lineChartElement') lineChartElement: ElementRef<HTMLElement>;

  async setUpLineChart() {
    if (!this.lineChartElement) { return }
    if (this.compInitialzed) { return }
    am4core.options.autoDispose = true;
    am4core.options.animationsEnabled = false;

    this.chart = am4core.create(this.lineChartElement.nativeElement, am4charts.XYChart);
    this.chart.svgContainer.autoResize = true
    this.chart.data = this.data;
    this.loadLineGraphView();

    if (this.themeService.theme && this.themeService.theme.includes("dark")) {
      this.categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      this.valueAxis.renderer.labels.template.fill = am4core.color("#fff");
      this.valueAxis.renderer.grid.template.stroke = am4core.color("#fff");
      this.lineSeries5.fill = am4core.color("#44515e"); //PRIMARY COLOR

    } else {
      this.categoryAxis.renderer.labels.template.fill = am4core.color("#000");
      this.valueAxis.renderer.labels.template.fill = am4core.color("#000");
      this.valueAxis.renderer.grid.template.stroke = am4core.color("#000");
      this.lineSeries5.fill = am4core.color("#f7f7f7"); //PRIMARY COLOR
    }

    this.lineSeries5.stroke = am4core.color(this.themeService.primaryColor);

    this.compInitialzed = true;

  }


  private loadLineGraphView() {
    this.zone.runOutsideAngular(() => {

      this.chart.dateFormatter.dateFormat = "yyyy-MM-dd";

      this.chart.fontSize = 12;
      this.chart.padding(10, 5, 0, 0);
      this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      this.categoryAxis.renderer.grid.template.location = 0;
      this.categoryAxis.renderer.ticks.template.disabled = false;
      this.categoryAxis.renderer.line.opacity = 0;
      this.categoryAxis.renderer.grid.template.disabled = false;
      this.categoryAxis.renderer.minGridDistance = 40;
      this.categoryAxis.dataFields.category = "date";
      this.categoryAxis.startLocation = 0.4;
      this.categoryAxis.endLocation = 0.6;

      this.categoryAxis.renderer.labels.template.horizontalCenter = "right";
      this.categoryAxis.renderer.labels.template.verticalCenter = "middle";
      this.categoryAxis.renderer.labels.template.rotation = 315;
      this.categoryAxis.renderer.labels.template.disabled = !this.lineChartConfig.showAxis;


      this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      this.valueAxis.renderer.line.opacity = 0;
      this.valueAxis.renderer.ticks.template.disabled = false;
      this.valueAxis.min = 0;

      this.valueAxis.renderer.labels.template.disabled = !this.lineChartConfig.showAxis;


      // this.getLineChartColumnNumberFormat(); //TODO

      this.lineSeries5 = this.chart.series.push(new am4charts.LineSeries());
      this.lineSeries5.dataFields.categoryX = "date";
      this.lineSeries5.dataFields.valueY = "dataPointValue";
      this.lineSeries5.tooltipText = "{valueY.value}";
      this.lineSeries5.tooltip.pointerOrientation = "vertical";
      this.lineSeries5.tooltip.background.fillOpacity = 0.5;

      if (this.lineChartConfig.showAxis) {
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.cursor.xAxis = this.categoryAxis;
      }

      this.lineSeries5.fillOpacity = 0.5;
      this.lineSeries5.strokeWidth = 3;



      this.lineSeries5.propertyFields.stroke = "lineColor";
      this.lineSeries5.propertyFields.fill = "'lineColor'";

      let bullet5 = this.lineSeries5.bullets.push(new am4charts.CircleBullet());
      bullet5.circle.radius = 4;
      bullet5.circle.fill = am4core.color("#fff");
      bullet5.circle.strokeWidth = 3;


      if (this.lineChartConfig.showAxis) {
        let scrollbarX = new am4core.Scrollbar();
        scrollbarX.marginBottom = 20;
        this.chart.scrollbarX = scrollbarX;
      }

    });
  }

  getHeight() {
    return this.lineChartConfig.height
  }

  // private getLineChartColumnNumberFormat() {
  //   if (this.selectedReport.reportCellList.list && this.selectedReport.reportCellList.list[0]) {
  //     if (this.selectedReport.reportCellList.list[0].cellList[1].value[0] === '$') {
  //       this.chart.numberFormatter.numberFormat = '$#.#a';
  //     }
  //     else if (this.selectedReport.reportCellList.list[0].cellList[1].value[0] === '%') {
  //       this.chart.numberFormatter.numberFormat = '$##.#';
  //     }
  //     else {
  //       this.chart.numberFormatter.numberFormat = '#.#a';

  //     }
  //   }

  // }

}

