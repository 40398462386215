import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DatesService } from '../../services/dates.service';
import { IncentiveDataPoint } from '../../services/incentives.service';
import { AnyObjectDescriptionComponent } from '../any-object-description/any-object-description.component';

@Component({
  selector: 'app-installations-list',
  templateUrl: './installations-list.component.html',
  styleUrls: ['./installations-list.component.scss'],
})
export class InstallationsListComponent implements OnInit {

  installations: IncentiveDataPoint[] = [];
  title: string = "";

  constructor(public dialogRef: MatDialogRef<AnyObjectDescriptionComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dateService: DatesService) { 

    this.installations = this.data.installations
    this.title = this.data.title
  }

  ngOnInit() {}

  public getStringForInstall(install: IncentiveDataPoint, index: number): string {
    let output: string = '';
    output += 'Install ' + index ;
    output += install['Customer Name'] + '\n';
    output += install['Customer Address'] + install['Customer City'] + ', ' + install['Customer State'];
    output += '\n' + install['Installation Date'];
    
    return output;
  }

  public getName(install: IncentiveDataPoint, index: number): string  {
    return 'Install ' + index + ' - ' + install['Customer Name'] + '\n';

  }

  public getAddress(install: IncentiveDataPoint): string {
    return install['Customer Address'] + install['Customer City'] + ', ' + install['Customer State']
  }

  public getInstallDate(install: IncentiveDataPoint): string {
    return this.dateService.humanFriendlyDate(install['Installation Date']);
  }
}
