import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { ReportCell } from 'src/app/shared/models/report-cell-list.model';

export interface TableData {
  rank: number; 
  name: string;
  value: string;
  date: string;
}


@Component({
  selector: 'app-record-detail',
  templateUrl: './record-detail.component.html',
  styleUrls: ['./record-detail.component.scss'],
})
export class RecordDetailComponent implements OnInit {
  report: DashboardData;
  displayedColumns: string[] = ['rank', 'name', 'value', 'date'];
  displayedColumns_company: string[] = ['rank', 'value', 'date'];

  tableType: string = "normal";

  dataSource: MatTableDataSource<TableData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public router: Router, private nav: HeaderService) { 
    // if (!history.state.report) {
    //   this.goBack();
    //   return;
    // }
    this.report = history.state.report;


    if (this.report.reportCellList.list[0] === undefined) {
      this.tableType = "normal";
      let tableDataSource: TableData[] = [{
        rank: 0, 
        name: "-",
        value: "-",
        date: "-"
      }];
      this.dataSource = new MatTableDataSource(tableDataSource);

      return;
    }

    if (this.report.reportCellList.list[0].cellList[2] === undefined) {
      this.tableType = 'company'
      let tableDataSource: TableData[] = this.extractTableDataFromReport_company();
      this.dataSource = new MatTableDataSource(tableDataSource);
    } else {
      this.tableType = 'normal'

      let tableDataSource: TableData[] = this.extractTableDataFromReport();
      this.dataSource = new MatTableDataSource(tableDataSource);
    }


    
  }


  extractTableDataFromReport_company():  TableData[] {
    let tempDataSource: TableData[] = [];

    this.report.reportCellList.list.forEach(item => {

      tempDataSource.push( {
        rank: item.rank,
        name: "Sunder",
        value: item.cellList[1] ? item.cellList[1].value : "",
        date: item.cellList[0].value
      })
    })

    return tempDataSource
  }

  extractTableDataFromReport(): TableData[] {
    let tempDataSource: TableData[] = [];

    this.report.reportCellList.list.forEach(item => {


      tempDataSource.push( {
        rank: item.rank,
        name: item.cellList[0].value,
        value: item.cellList[2] ? item.cellList[2].value : "",
        date: item.cellList[1].value
      })
    })

    return tempDataSource
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



  ngOnInit() {
   
  }
  goBack() {
    this.router.navigate(['company-records']);
  }


  getBackgroundImage() {
    let image = this.report.reportCellList?.list[0]?.cellList[0].imageUrl;
    if (!image) {
      image = "../../../assets/install.jpg";
    }
    return image;
  }


  getFirstPlace() {
    if (!this.report.reportCellList.list) {return}
    return this.report.reportCellList?.list[0]?.cellList[0].value
  }
  getFirstPlaceValue() {
    if (!this.report.reportCellList?.list[0]?.cellList[2]) {return}
    return this.report.reportCellList?.list[0]?.cellList[2].value
  }


}

