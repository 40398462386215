<ion-grid style="color: white; padding: 0; margin: 0"  class="custom-grid">
  <ion-row style="padding: 0; margin: 0" *ngFor='let val of matchParticipants.slice(0, getNumberOfRows()); let rowNumber= index'>
    <ion-col size="6" style="padding: 0; margin: 0">

      <ion-grid style="padding: 0; margin: 0; " class="section-div-dark">
        <ion-row style="padding: 0; margin: 0">
          <ion-col size="4"
            style="padding: 0; margin: 0; border-top-left-radius: 10px;   border-bottom-left-radius: 10px; background-size:cover;"
            [ngStyle]="{'background-image': 'url(' + this.getLeftParticipantForRow(rowNumber).teamImageUrl + ')'}"

            >

          </ion-col>

          <ion-col size="9" style="padding: 5px 5px 5px 10px">
            <ion-row style="font-weight: 100; font-size: 14px;">
              {{this.getLeftParticipantForRow(rowNumber).teamName}}
            </ion-row>

            <ion-row>
              <span style="font-size: 30px; white-space: nowrap; text-align: right;"> {{this.getLeftParticipantForRow(rowNumber).teamScore}} </span>

            </ion-row>
          </ion-col>


        </ion-row>
      </ion-grid>


    </ion-col>
  
    
    <ion-col size="1">
      <!-- <p style="font-size: 12px; text-align: center; color:rgba(255,255,255,0.4); width: 100%; line-height: 60px; margin: 0;"> vs</p> -->
    </ion-col>
    
    <ion-col size="6" style="padding: 0; margin: 0" *ngIf='this.getRightParticipantForRow(rowNumber)'>

     
      <ion-grid style="padding: 0; margin: 0; " class="section-div-dark">
        <ion-row style="padding: 0; margin: 0">
          

          <ion-col size="9" style="padding: 5px 10px 5px 5px">
            <ion-row style="font-weight: 100; font-size: 14px;">
              <span style=" text-align: right; width: 100%;"> {{this.getRightParticipantForRow(rowNumber).teamName}} </span>
            </ion-row>

            <ion-row>
              <span style="font-size: 30px; white-space: nowrap; text-align: right; width: 100%;"> {{this.getRightParticipantForRow(rowNumber).teamScore}} </span>

            </ion-row>
          </ion-col>

          <ion-col size="4"
            style="padding: 0; margin: 0; border-top-right-radius: 10px;   border-bottom-right-radius: 10px;background-size:cover;"
            [ngStyle]="{'background-image': 'url(' + this.getRightParticipantForRow(rowNumber).teamImageUrl + ')'}"

            >

          </ion-col>


        </ion-row>
      </ion-grid>





    </ion-col>
  </ion-row>

  <div style="height: 20px;"></div>


</ion-grid>