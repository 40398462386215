import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalController } from '@ionic/angular';
import { Employee } from 'src/app/shared/models/employees.model';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';

@Component({
  selector: 'app-employee-list-modal',
  templateUrl: './employee-list-modal.component.html',
  styleUrls: ['./employee-list-modal.component.scss'],
})
export class EmployeeListModalComponent implements OnInit {

  employees: Employee[];
  scores: {[key: number]: number};

  constructor(
    public employeeStoreService: EmployeeStoreService,
    public dialogRef: MatDialogRef<EmployeeListModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {scores: {[key: number]: number}}
    ) { 
      this.employees = Object.keys(data.scores).map(id => this.employeeStoreService.getUser(+id));
      this.scores = data.scores
    }

  ngOnInit() {}
  
  public dismiss() {
    this.dialogRef.close()
  }

  getScore(employee: Employee): number {
    const score: number = this.scores[employee?.userId];
    return score;
  }

}
