<ion-content>
  <div class="darkTheme">

    <div style="padding: 20px; color: white;">

      <button mat-button style="background-color: rgba(255, 255, 255, 0.1); color: white; width: 100%;"> Notification Settings </button>

      <div style="height: 20px;"> </div>
      <div *ngFor="let notification of this.notificationsService.notifications">
        <div style="height: 10px;"> </div>

        <h1 *ngIf="notification.status === 'unseen'" style="font-size: 14px; margin: 5px;">
          <span class="material-icons iconAlign" style="color: #00BCE1">
            fiber_manual_record
          </span>
          {{notification.name}}
        </h1>


        <h1 *ngIf="notification.status === 'seen'" style="font-size: 14px; margin: 5px; color: rgba(255, 255, 255, 0.8)">
          {{notification.name}}
        </h1>
        <p style="font-size: 14px; color: rgba(255, 255, 255, 0.6); margin: 5px;"> {{notification.date}} </p>

        <div style="height: 10px;"> </div>
        <mat-divider> </mat-divider>
      </div>

    </div>

  </div>
</ion-content>
