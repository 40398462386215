import { GeocoderLocationType, LatLng, LatLngBounds } from "@agm/core";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material";
import { environment } from "src/environments/environment";
// import { environment } from './../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultNewLead, LeadModel } from "../leads/leads.model";
import { newTallyDataPoint, TallyDataPoint } from "../leads/prospects/tallys";
import { MapMarker } from "../shared/components/geo-map/geo-map.component";
import { parseAddressComponentsIntoLead } from "../shared/functions/map.functions";
import { KnockModalComponent } from "./knock-modal/knock-modal.component";

@Injectable({
    providedIn: "root",
})
export class KnockingService {
    constructor(
        private bottomSheet: MatBottomSheet,
        private http: HttpClient,
        ) {

    }

    public async doorKnockedModal(marker: MapMarker): Promise<{pin?: MapMarker, deletePin?: string}> {
      let ref = this.bottomSheet.open(KnockModalComponent, {
          data: {
            pin: marker
          }
        });
    
      ref.afterDismissed().subscribe((res) => {
        if (!res) {return}
      });

      const res:  {pin?: MapMarker, deletePin?: string} = await ref.afterDismissed().toPromise();

      if (res?.pin) {
        marker.pinImageUrl = res.pin.pinImageUrl;
        marker = res.pin; // TODO: Deep copy?
      }

      return res;      
    
    }

    public async latLongToAddress(lat: number, lng: number): Promise<EnzyAddress> {

      const GOOGLE_MAPS_API_KEY: string = environment.GOOGLE_MAPS_API_KEY;

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`

      const result: GoogleAddressResponse = await this.http.get<any>(url).toPromise();

      return this.extractAddressFromResults(result);

    }

    private extractAddressFromResults(result: GoogleAddressResponse): EnzyAddress {
      if (!result.results || result.results?.length === 0) return null;
      return parseAddressComponentsIntoLead(result.results[0]);

    }

}

export interface EnzyAddress {
  fullAddress?: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
}

export interface GoogleAddressResponse {
  results: GoogleAddressResult[] 
}

export interface GoogleAddressResult {
  types: string[],
  formatted_address: string,
  address_components: GoogleAddressComponent[],
  partial_match: boolean,
  place_id: string,
  postcode_localities: string[],
  geometry: GoogleAddressGeometry
  
}

export interface GoogleAddressGeometry {
  location: LatLng,
  location_type: GeocoderLocationType
  viewport: LatLngBounds,
  bounds: LatLngBounds
}

export interface GoogleAddressComponent {
    short_name: string,
    long_name: string,
    postcode_localities: string[],
    types: string[],
  
}


export function knockedDoorFromMarker(marker: MapMarker): KnockedDoor {
  if (!marker?.knockedDoor) {
    const newKnockedDoor: KnockedDoor = {
      lat: marker.lat,
      lng: marker.lng,
      lead: getDefaultNewLead(),
      knockStatus: "Not Home",
      knocks: [newTallyDataPoint(KNOCKING_TALLY_KEY)],
    };

    marker.knockedDoor = newKnockedDoor;    
  }
  return marker.knockedDoor;

}

function markerFromKnockedDoor(door: KnockedDoor): MapMarker {
  return {
    serverId: null,
    frontendId: uuidv4(),
    lat: door.lat,
    lng: door.lng,
    knockedDoor: door,
    weightNumber: Math.random(), // TODO: Implement
    draggable: false,
    pinType: Math.floor(Math.random() * 5), // TODO: Implement
    pinImageUrl: pinUrlForDoor(door), // TODO Impelemnt
  };
}

export function pinUrlForDoor(door: KnockedDoor): string {
  let status: string = door?.knockStatus;
    if(status === "Not Home") {// gray
      return "http://maps.google.com/mapfiles/ms/micons/purple-dot.png"; 
    } else if(status === "Not Interested") { // red
      return "https://maps.google.com/mapfiles/ms/micons/red-dot.png"; 
    } else if(status === "Follow Up") { // yellow
      return "https://maps.google.com/mapfiles/ms/micons/yellow-dot.png";
    } else if(status === "Lead Created") { // blue
      return "https://maps.google.com/mapfiles/ms/micons/blue-dot.png";
    } else {
      return "https://maps.google.com/mapfiles/ms/micons/green-dot.png"; // green
    }

}

export type KnockStatus =  "Not Home" | "Not Interested" | "Follow Up" | "Lead Created" | "Project Submitted";
export interface KnockedDoor {
  lat: number;
  lng: number;
  lead: LeadModel;
  projectId?: number;
  knockStatus: KnockStatus;
  knocks: TallyDataPoint[];

}

export const KNOCKING_TALLY_KEY: string = "knocks";