import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInfo } from '@capacitor/device';

import { REST_BASE_URL } from '../models/constants';
import { UserDetailsService } from './user-details.service';


@Injectable({
    providedIn: 'root'
})
export class AdminToolService {

    constructor(
        private http: HttpClient,
        public userDetailsService: UserDetailsService
    ) { }


    public async getEmailVerificationCode(email: string): Promise<EmailVerificationResponse> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url = REST_BASE_URL + '/adminTool/getLatestApplicantCode/' + sessionKey + "?applicantEmail=" + email;
        return this.http.get<any>(url).toPromise();
    }
    public async getUserDeviceDetails(userId: number): Promise<
    {
        deviceDetails: DeviceDetails[],
        otherDetails: {team: string, region: string},
        sessionKey: string
    }> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url = REST_BASE_URL + '/adminTool/getUserExtraInfo/' + sessionKey + "?userId=" + userId;
        const result: {
            deviceDetails: DeviceDetails[],
            otherDetails: {team: string, region: string},
            sessionKey: string} = await this.http.get<any>(url).toPromise();
        result.deviceDetails.forEach(d => {d.deviceInfo = JSON.parse("" + d.deviceInfo)})
        return result;
    }

    public async updateTeamImage(teamName: string, imageUrl: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const url = REST_BASE_URL + '/adminTool/updateTeamImage/' + sessionKey;
        let body = new FormData();
        body.append('teamName', teamName);
        body.append('imageUrl', imageUrl);

        return this.http.post<any>(url, body).toPromise();
    }

}


export class EmailVerificationResponse {
    emailVerificationCode: string;
    success: boolean;
    message?: string;
}
export class DeviceDetails {
    userId: number;
    firebaseToken: string;
    deviceInfo: DeviceInfo;
    appVersion: string;
    deviceId: string;
    insertDateTime: string;
}