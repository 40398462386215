<div class="app-background-color" style="width: 80vw; height: 300px; padding: 10px">
  <h1 style="margin: 0;">Warning</h1>

  <ion-grid>
    <div style="height: 20px;"></div>

    <ion-row >
      <span style="font-weight: 100;">
        It looks like you're trying to send a $FIRST message in a Group chat. This will not parse out the first name like expected. 
      </span>
    </ion-row>

    <div style="height: 20px;"></div>
    <ion-row>
      <ion-col size="6">
        <button mat-button class="primary-color" style='width: 100%;'  (click)="cancel()">
          Cancel
        </button>
      </ion-col>


      <ion-col size="6">
        <button mat-button  style='width: 100%;' (click)="okay()">
          Proceed Anyway
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>