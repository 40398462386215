import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Employee } from 'src/app/shared/models/employees.model';
import { LeaderboardCell } from 'src/app/shared/models/leaders.model';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { LeadersStoreService } from 'src/app/shared/services/leaders-store.service';
import { LeaderboardDrillDownService } from '../services/leaderboard-drill-down.service';

@Component({
  selector: 'app-leaderboard-grid-cell',
  templateUrl: './leaderboard-grid-cell.component.html',
  styleUrls: ['./leaderboard-grid-cell.component.scss'],
})
export class LeaderboardGridCellComponent implements OnInit {
  routeId: string;

  leaderboardCell: LeaderboardCell;

  @Input() set leaderboardCellInput(newleaderboardCell: LeaderboardCell) {
    this.leaderboardCell = newleaderboardCell;
  }


  selectedIndex: number;
  @Input() set selectedIndexInput(newSelectedIndex: number) {
    this.selectedIndex = newSelectedIndex;
  }


  forOption: string;
  @Input() set forOptionInput(newForOption: string) {
    this.forOption = newForOption;
  }

  constructor(
    private drillDownService: LeaderboardDrillDownService, 
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.routeId = this._route.snapshot.paramMap.get('id');
  }


  async drillDown() {
    this.drillDownService.gridDrillDown(this.leaderboardCell, this.forOption, this.selectedIndex, +this.routeId);

  }

  getCellInitials(): string {
    const titleArray: string[] = this.leaderboardCell.title.split(" ")
    let output: string = "-";
    if (titleArray.length>0 && titleArray[0]?.length > 0) output = titleArray[0][0];
    if (titleArray.length>1 && titleArray[1]?.length > 0) output += titleArray[1][0];
    return output.toUpperCase();
  }


}
