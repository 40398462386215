import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { REST_BASE_URL } from '../../models/constants';

@Injectable()
export class HeaderService {
  visible: boolean;
  textVisible: boolean = true;
  footerVisible: boolean = true;
  showMessagingToolbar: boolean = false;
  asOfTime: string = "";


  constructor(private zone: NgZone, public router: Router, private http: HttpClient,) {
    this.visible = true;
  }

  hideFooter() {
    this.footerVisible = false;
    this.zone.run(() => {
      console.log('HIDE FOOTER (outside zone)');
    });
  }

  showFooter() {
    this.footerVisible = true;
    this.zone.run(() => {
      console.log('SHOW FOOTER (outside zone)');
    });
  }


  showKeyboardToolbar() {
    this.showMessagingToolbar = true;
    this.zone.run(() => {
      console.log('SHOW KEYBOARD TOOLBAR (outside zone)');
    });
  }

  hideKeyboardToolbar() {
    this.showMessagingToolbar = false;
    this.zone.run(() => {
      console.log('HIDE KEYBOARD TOOLBAR (outside zone)');
    });
  }


  hideText(){
    this.textVisible = false;
  }
  showText(){
    this.textVisible = true;

  }

  toggle() { this.visible = !this.visible; }


  public async checkLatestAppVersion(): Promise<any> {
    let url: string = REST_BASE_URL + '/appVersion/';
    return this.http.get<any[]>(url).toPromise();
  }

}
