import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralInputModalComponent } from 'src/app/shared/components/general-input-modal/general-input-modal.component';
import { DEFAULT_TOURNAMENT_ROUND, TwoTeamMatchup } from 'src/app/shared/models/incentives.model';
import { FreshReportService } from 'src/app/shared/services/fresh-report.service';
import { IncentivesService, TournamentModel, TournamentRound } from 'src/app/shared/services/incentives.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { saveAs } from 'file-saver'; 
import { Platform } from "@ionic/angular";
import { EditTournamentRoundsComponent } from './edit-tournament-rounds/edit-tournament-rounds.component';

@Component({
  selector: 'app-edit-tournament',
  templateUrl: './edit-tournament.component.html',
  styleUrls: ['./edit-tournament.component.scss'],
})
export class EditTournamentComponent implements OnInit {

  @ViewChild('roundsComponent') roundsComponent : EditTournamentRoundsComponent;

  tournament: TournamentModel;

  testBool: boolean = true;


  accessLevel: number = 0;
  userId: string;

  constructor(private activatedRoute: ActivatedRoute, 
    public dialog: MatDialog,
    public router: Router, 
    public platform: Platform,
    public freshReportService: FreshReportService,
    public userDetailsService: UserDetailsService,
    public incentivesService: IncentivesService) { 
    this.activatedRoute.queryParams.subscribe(params => {
      let id: number = +params['id'];
      
      if (id === -1) {
        this.createNewTournament();
        return;
      } else {
        this.getTournament(id);
      }
    }); 
    this.initAccessLevel();

  }

  private async initAccessLevel() {
    this.accessLevel = await this.userDetailsService.getAccessLevel();
    this.userId = await this.userDetailsService.getUserId() + "";
  }


  async getTournament(id: number) {
    const res = await this.incentivesService.getTournamentDetails(id);
    this.tournament = res;
  }


  createNewTournament() {
    this.tournament = {
      rounds: [{...DEFAULT_TOURNAMENT_ROUND}],
      id: null,
      companyId: 6,
      campaignName: "New Tournament",
      campaignType: "tournament",
      startDate: "2021-01-01",
      endDate: "2021-12-01",
      backgroundUrl: null,
      thumbnailUrl: null,
      groupType: "team",
      rulesDescription: null,
      rulesUrl: null,
      currentRound: "Round 1",
      championshipRound: null
    };    
  }



  private updateRoundsOrder() {
    let index: number = 0;
    for (let round of this.tournament?.rounds) {
      round.roundOrder = index++;
    }
  }

  async saveAll() {
    // TODO: Send the modified tournament to the backend
    this.updateRoundsOrder();
    this.roundsComponent.updateRoundMatchups();

    let res = await this.incentivesService.saveTournamentDetails(this.tournament);
    // console.log(res);
    this.router.navigateByUrl("incentives");
  }

  userCanEditTournament(): boolean {
    // return true;
    return this.userId === '489' || this.userId === '19' || this.userId === '1610'
    return this.accessLevel >= 10;
  }


  ngOnInit() {}


  

}

export interface MatchParticipant {
  teamName: string;
  teamScore: number; 
  teamImageUrl: string; 
  teamUseOverrideScore: boolean; 
  teamMultiplier: number;
  teamDeals: number;
  teamSelfGen: number;
  teamCalculatedRealizationRate: number;
}