import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatDialog, MatBottomSheetRef } from '@angular/material';
import { Platform } from '@ionic/angular';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-edit-drawer',
  templateUrl: './edit-drawer.component.html',
  styleUrls: ['./edit-drawer.component.scss'],
})
export class EditDrawerComponent implements OnInit {

  constructor(    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public platform: Platform,
  public userDetailsService: UserDetailsService,
  public messagingService: MessagingService,
  public messagingStoreService: MessagingStoreService,
  private _bottomSheetRef: MatBottomSheetRef<EditDrawerComponent>) { }

  ngOnInit() {}

  edit() {
    this._bottomSheetRef.dismiss("edit")
  }

  share() {

  }
  
  remove() {
    this._bottomSheetRef.dismiss("remove")

  }
}
