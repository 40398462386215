import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserData } from 'src/app/shared/models/dashboard.model';

@Component({
  selector: 'app-edit-info-modal',
  templateUrl: './edit-info-modal.component.html',
  styleUrls: ['./edit-info-modal.component.scss'],
})
export class EditInfoModalComponent implements OnInit {
  userData_copy: UserData;

  constructor(public dialogRef: MatDialogRef<EditInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.userData_copy = JSON.parse(JSON.stringify(this.data.userData));

    }

  ngOnInit() {}


  save() {    
    this.dialogRef.close(this.userData_copy);
  }

}
