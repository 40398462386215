<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">



<ion-header no-border class="no-padding-header ion-no-border app-background-color">
  <div style="width: 100%;">

    <ion-toolbar no-border class="no-padding-header " style=" max-width: 1200px; display: block; margin: auto;">

      <button mat-fab class="circle-button" matRipple class="example-ripple-container" [matRippleUnbounded]="false"
        style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent;"
        (click)="goBack()">
        <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
      </button>
      <div *ngIf="this.employee">
        <ion-grid style="padding: 0; margin-top: -20px;">
          <ion-row style="padding: 0; margin: 0;">

            <div style="display: block; margin: auto;">
              <app-user-icon [shouldExpandImage]="true" [isExtraLarge]="true" [employee]="this.employee"
                [initials]=getInitials()></app-user-icon>
            </div>

          </ion-row>

          <div style="height: 15px"></div>

          <ion-row style="padding: 0; margin: 0;">
            <h1 style="text-align: center;  margin: 0; width: 100%; font-size: 22px;"> <span style="font-weight: 100;">
                Rep:&nbsp;</span>{{employee.fullName | titlecase}}
            </h1>
          </ion-row>

          <ion-row>
            <div style="max-width: 250px; display: block; margin: auto;" *ngIf="this.accessLevel >= 10">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <button (click)="messageRep()" mat-button style="width: 60px; height: 60px;" class="section-div">
                      <mat-icon style="width: 60px !important;">chat</mat-icon>
                    </button>
                  </ion-col>

                  <ion-col *ngIf="this.phoneNumber !== ''">
                    <a href={{this.phoneNumber}} matRipple class="example-ripple-container" [matRippleUnbounded]="false"
                      style="width: 60px; height: 60px;" class="section-div">
                      <mat-icon style="width: 60px !important; height: 60px !important; line-height: 60px;">call
                      </mat-icon>
                    </a>
                  </ion-col>

                  <!-- we don't want people texting outside of enzy. In case they are recruited away from the company -->
                  <!-- <ion-col *ngIf="this.phoneNumber !== ''">
                    <a [href]="this.sanitize('sms:' + this.employee.userDto.phoneNumber)"matRipple class="example-ripple-container" [matRippleUnbounded]="false"
                      style="width: 60px; height: 60px;" class="section-div">
                      <mat-icon style="width: 60px !important; height: 60px !important; line-height: 60px;">textsms
                      </mat-icon>
                    </a>
                  </ion-col> -->


                  <ion-col *ngIf="this.email !== ''">
                    <button (click)="mailto()" mat-button style="width: 60px; height: 60px;" class="section-div">
                      <mat-icon style="width: 60px !important;">email</mat-icon>
                    </button>
                  </ion-col>

                </ion-row>
              </ion-grid>
            </div>
          </ion-row>
        </ion-grid>
      </div>

    </ion-toolbar>
  </div>
</ion-header>
<ion-content>
  <ion-grid style="max-width: 1200px; display: block; margin: auto;">
    <ion-row>
      <ion-col size="12">
        <div *ngIf="this.accessLevel >= 10" style="height: 350px;">
          <div *ngIf="reports?.length > 0">
    
            <div class="scrolling-wrapper" style="max-width: 1200px; display: block; margin: auto; ">
              <div class="scrolling-wrapper-flexbox">
                <div>
                  <div style="width: 10px;"></div>
                </div>
          
                <div *ngFor="let report of reports" style="padding: 10px;">
                  <app-dashboard-card (showAnalytics)="this.showAdvancedAnalytics($event)" [report]="report" [homeDrillDown]="true"></app-dashboard-card>
                </div>
            
                <div>
                  <div style="width: 10px;"></div>
                </div>
              </div>
            </div>


    
          </div>
        </div>

        <div style="height: 20px"></div>
        <div style="padding: 0;">

          <ion-toolbar class="no-padding-header transparent-bg">
            <div slot="start">
              <h4 style="font-size: 20px; text-align: left; padding-bottom: 5px; padding-left: 10px">
                Messages
              </h4>
            </div>

            <div slot="end">
              <button (click)="messageRep()" class=" primary-color" style=" border-radius: 22px;
                font-size: 14px;
                width: 60px;
                height: 40px; display: block; margin: auto;" mat-button>
                <i class="material-icons">
                  add
                </i>
              </button>
            </div>
          </ion-toolbar>
          <div>
            <div>
              <div *ngIf='this.conversations.length === 0' class="opaque-text"
                style="font-size: 14px; text-align: center;">
                (No conversations yet)
              </div>

              <div style="height: 10px"></div>
              <mat-divider></mat-divider>

              <div *ngFor="let conversation of this.conversations; let i = index" style='width: 100%'>
                <div style='width: 100%; text-align: left;'>
                  <app-conversation-cell (click)="openMessageView(conversation)" [conversation]='conversation'>
                  </app-conversation-cell>
                </div>

                <mat-divider></mat-divider>
              </div>


            </div>
          </div>
        </div>
        <div style="height: 80px"></div>

      </ion-col>

    </ion-row>


  </ion-grid>

</ion-content>