
<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<ion-content #content>

  <div>

    <!-- <button (click)='openVideo("https://library-videos.s3.us-west-1.amazonaws.com/Deliverables/Personal+Development+Training/246+Mindset.mp4")'>
      open video
    </button> -->


  </div>


</ion-content>