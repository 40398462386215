import { Injectable } from "@angular/core";
import { Storage as capStorage } from '@capacitor/storage';

@Injectable()
export abstract class ICacheService {
    abstract getValue(key: string): Promise<string>;
    abstract setValue(key: string, value: any): Promise<void>;
}

@Injectable()
export class CacheService implements ICacheService {    
    constructor() {}

    async getValue(key: string): Promise<string> {
        return (await capStorage.get({key})).value
    }

    async setValue(key: string, value: string): Promise<void> {
        capStorage.set({key, value});
    }
}

