<div>
  <enzy-start-end-date-picker [startEndTime]="startEndTime">
  </enzy-start-end-date-picker>
  
  <ion-grid>
    <div *ngFor="let key of getStatKeys(); let index = index">
      <ion-row>
        <ion-col>
          <span style="font-weight: 100;">{{key}}</span>
        </ion-col>
        <ion-col>
          {{getStatValue(key)}}
          &nbsp; &nbsp;
          <span style="font-weight: 100;" class="opaque-text" *ngIf="getStatRatio(key, index)">
            ({{getStatRatio(key, index)|number: '1.0-2'}} : 1)
          </span>
        </ion-col>
      </ion-row>

      <mat-divider></mat-divider>
    </div>
    <div style="height: 100px;">

    </div>
    <div>
      <ion-row>
        <ion-col>
          <span style="font-weight: 100;">Leads Submitted</span>
        </ion-col>
        <ion-col>
          {{getLeadsSubmittedTotal()}}
          &nbsp; &nbsp;
          <span style="font-weight: 100;" class="opaque-text">
            ({{getLeadsRatio()|number: '1.0-2'}} : 1)
          </span>
        </ion-col>
      </ion-row>
    </div>

    <mat-divider></mat-divider>

    <div>
      <ion-row>
        <ion-col>
          <span style="font-weight: 100;">Leads Sat</span>
        </ion-col>
        <ion-col>
          {{getLeadsThatSatTotal()}}
          &nbsp; &nbsp;
          <span style="font-weight: 100;" class="opaque-text">
            ({{getLeadsThatSatRatio()|number: '1.0-2'}}:1)
          </span>
        </ion-col>
      </ion-row>
    </div>

    <mat-divider></mat-divider>

    <!-- <div>
      <ion-row>
        <ion-col>
          <span style="font-weight: 100;">Deals Submitted</span>
        </ion-col>
        <ion-col>
          1
          <span>
            
          </span>
        </ion-col>
      </ion-row>
    </div>

    <mat-divider></mat-divider> -->

  </ion-grid>
  <div *ngIf="showLawOfAverages()">
    <h2>
      Law of Averages
    </h2>
    <p>
      This is the gospel according to Max Ganley. The following ratios hold up accross the industry for most average reps. 
    </p>
    <p>
      50 Homeowners per week
    </p>
    <p>
      5 Appointments Set per week
    </p>
    <p>
      3 Sits per week
    </p>
    <p>
      1 Close per week
    </p>
    <p>
      4 closes per month becomes 3 installs per month
    </p>
    
  </div>
</div>