import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { BackendUser } from 'src/app/shared/models/employees.model';

@Component({
  selector: 'app-voter-details',
  templateUrl: './voter-details.component.html',
  styleUrls: ['./voter-details.component.scss'],
})
export class VoterDetailsComponent implements OnInit {

  selectedOption: string = ""
  voters: BackendUser[] = []
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,) {
    // console.log(this.data)

    this.selectedOption = this.data.option;
    this.voters = this.data.responses;
  }

  ngOnInit() {}

  getFullName(user: BackendUser): string {
    if (user.fullName) return user.fullName;
    return user.preferredFirstName + " " + user.lastName;
  }

}
