import { Injectable } from "@angular/core";
import { Storage as capStorage } from '@capacitor/storage';


@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    //------------------OPEN------------------
    // public timeAppWasOpened: string;
    public openKeyValue = "timeAppWasOpened";

    async clearTimeAppWasOpened(): Promise<any> {
        return await capStorage.remove({ key: this.openKeyValue });
    }

    async setTimeAppWasOpened(date: string): Promise<any> {
        return await capStorage.set({
            key: this.openKeyValue,
            value: date,
        });
    }


    async getTimeAppWasOpened(): Promise<any> {
        // if (this.timeAppWasOpened) { return this.timeAppWasOpened }
        let timeAppWasOpened = (await capStorage.get({ key: this.openKeyValue }))?.value
        return timeAppWasOpened;
    }



    //------------------CLOSED------------------
    // public timeAppWasClosed: string;
    public closedKeyValue = "timeAppWasClosed";


    async clearTimeAppWasClosed(): Promise<any> {
        return await capStorage.remove({ key: this.closedKeyValue });
    }

    async setTimeAppWasClosed(date: string): Promise<any> {
        return await capStorage.set({
            key: this.closedKeyValue,
            value: date,
        });
    }


    async getTimeAppWasClosed(): Promise<any> {
        // if (this.timeAppWasClosed) { return this.timeAppWasClosed }
        let timeAppWasClosed = (await capStorage.get({ key: this.closedKeyValue }))?.value
        return timeAppWasClosed;
    }




    async getTimeDifference(): Promise<any> {
        let open = await this.getTimeAppWasOpened();
        let close = await this.getTimeAppWasClosed();

        let difference = Math.abs(open - close); //  Math.floor((diff/1000)/60);
        let format =  Math.floor((difference/1000)/60);

        return format
    }


}
