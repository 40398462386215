<button (click)="close()" mat-raised-button
  style="position: absolute; top: 70px; left: 10px;background-color: white; color: black; z-index: 999; "><span style=""
    class="material-icons">
    close
  </span>
</button>

<div class="parent">

  <div *ngIf="this.data.image" style="height: 100vh; width: 100vw;" > 
    <pinch-zoom [properties]="zoomProperties" style="height: 100vh; width: 100vw;">
      <img src="{{this.data.image}}" />
    </pinch-zoom>
  </div>



  <div class="child" *ngIf="this.data.video">
    <video controls autoplay style="width: 100%; max-height: 100vh;">
      <source [src]="this.data.video" type="video/mp4">
    </video>
  </div>


  <div *ngIf="this.data.pdfUrl" style="width: 100%;">
<!-- 
    <embed [src]="getPdfUrl()" 
    style="width: 100%;height: 500px" 
    type="application/pdf"> -->


    <!-- <iframe [src]='getPdfUrl()' width="640" height="360" frameborder="0"
    webkitallowfullscreen mozallowfullscreen allowfullscreen>
</iframe> -->

    <!-- <object [data]="this.data.pdfUrl | safeUrl" type="application/pdf" width="100%" height="100%" ></object> -->
  </div>

</div>