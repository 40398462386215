import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApplicantFile, DEFAULT_APPLICANT_FILE } from 'src/app/onboarding/onboarding-wizard/onboarding-store.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ApplicantDocument } from '../applicant.component';

@Component({
  selector: 'enzy-additional-document-modal',
  templateUrl: './additional-document-modal.component.html',
  styleUrls: ['./additional-document-modal.component.scss'],
})
export class AdditionalDocumentModalComponent implements OnInit {


  applicantFile: ApplicantFile = {...DEFAULT_APPLICANT_FILE};


  constructor(public dialogRef: MatDialogRef<AdditionalDocumentModalComponent>,   public snackbarService: SnackbarService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  save(){
    if(this.applicantFile)
      this.dialogRef.close(this.applicantFile)
  }

  onFileSelected(event) {
    this.applicantFile.file = event.target ? event.target.files[0] : event[0];

    this.applicantFile.title = this.applicantFile.file.name;

  }

  onFileDropped(event) {
    const file: File = event.target ? event.target.files[0] : event[0];
    // console.log(file.type)
    if(file.type === "application/pdf") 
      this.onFileSelected(event);
    else
      this.snackbarService.displaySnackBarMessage("Type mismatch. Please select a PDF.", false);
    }


  getTypeOptions() {
    return [
      "W9",
      "Bank",
      "Other"
    ]
  }
}
