import { C } from "@angular/cdk/keycodes";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DashboardAlertModel, SurveyResponse } from "src/app/cr-dashboard/cr-dashboard.service";
import { LeadModel } from "src/app/leads/leads.model";
import { serverGetRequestV1 } from "src/app/shared/functions/rest.functions";
import { UserDetailsService } from "src/app/shared/services/user-details.service";

const GET_GENERIC_OBJECT_REST_PATH: string = "/genericObject"

@Injectable({
    providedIn: "root"
})
export class GenericDrillDownApi {

    constructor(
        private http: HttpClient,
        private userService: UserDetailsService,
    ) {

    }

    public async getGenericObject(objectId: string, objectType: string): Promise<GenericObjectResponse> {
        const params: {[key:string]: string} = {
            objectId,
            objectType,
        }
        const res = await serverGetRequestV1<GenericObjectResponse>(
            GET_GENERIC_OBJECT_REST_PATH,
            params,
            this.http,
            this.userService
        );
        return res;
    }
}

export type GenericObjectResponse = {
    success: boolean,
    message?: string,
    genericObject?: {[keys: string]: string},
    lead?: LeadModel,
    project?:  {[keys: string]: string}, // TODO: make this not generic
    surveyResponseDescription?: DashboardAlertModel,

}