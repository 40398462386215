import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { paramFromRoute } from "src/app/shared/generic-functions";
import { REST_BASE_URL } from "src/app/shared/models/constants";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { LeadInput, LeadModel } from "../leads.model";


@Injectable({
    providedIn: "root",
})
export class ExternalLeadsService {

    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private snackbar: SnackbarService
    ) { }

    async getExternalTeamFromParams(): Promise<string> {
        return paramFromRoute(this.activatedRoute, "team");
    }

    async getExternalCompanyFromParams(): Promise<string> {
        return paramFromRoute(this.activatedRoute, "company");
    }

    public async createNewLead(lead: LeadInput, officeKey: string, companyKey: string, personKey: string): Promise<any> {
        let url: string = REST_BASE_URL + '/leads/insertExternalLead/';

        if (officeKey && companyKey) {
            url += "company" + "?companyKey=" + companyKey + "&officeKey=" + officeKey;
        }
        else if (personKey) {
            url += "person" + "?personKey=" + personKey;
        }


        let body = JSON.stringify(lead);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

        const res = await this.http.post<any>(url, body, { headers: headers }).toPromise();
        if (res.success) {
            this.snackbar.displaySnackBarMessage(personKey ? "Successful Submission" : "Lead successfully created");
        }
        else {
            this.snackbar.displaySnackBarMessage(res.message, false)
        }
        return res;
    }
}
