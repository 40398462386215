import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { AddNewModalComponent } from 'src/app/library/admin-library/add-new-modal/add-new-modal.component';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { UserPickerModalComponent } from 'src/app/shared/components/user-picker-modal/user-picker-modal.component';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { Employee } from 'src/app/shared/models/employees.model';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.scss'],
})
export class MultiFileUploadComponent implements OnInit {

  @Input() fileUrls: string[];

  constructor(
    public incentivesService: IncentivesService,
    public dialog: MatDialog,
    public bottomSheet: MatBottomSheet,

  ) { }

  ngOnInit() { }


  expandImage(url: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        image: url,
      }
    });
  }


  deleteImage(url: string) {

    const index: number = this.fileUrls.indexOf(url);
    if (index !== -1) {
      this.fileUrls.splice(index, 1);
    }
  }


  addNewFiles() {
    let dialogRef = this.bottomSheet.open(AddNewModalComponent);

    dialogRef.afterDismissed().subscribe((res: { videos: File[], command: string }) => {
      console.log(res?.videos.length);
      if (res?.videos) this.uploadFiles(res.videos);
    });
  }


  async uploadFiles(files: File[]) {
    for (let file of files) {
      this.uploadFile(file);
    }
  }


  async uploadFile(file: File) {
    const res = await this.incentivesService.saveImage(file, "TEMP+FILE");
    this.fileUrls.push(res);
    return res;
  }

}
