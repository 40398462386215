import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { ApplicantData, ApplicantFile, BankInfo } from 'src/app/onboarding/onboarding-wizard/onboarding-store.service';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { ResourceFile } from '../models/help.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { UserDetailsService } from './user-details.service';
import { jsPDF } from "jspdf";
import { EmployeeStoreService } from './employee-store.service';
import { MatDialog } from '@angular/material';
import { BusySpinnerComponent } from '../components/busy-spinner/busy-spinner.component';
import { Employee } from '../models/employees.model';
import { Status } from '../models/admin-directory.model';


@Injectable({
  providedIn: 'root'
})
export class AdminDirectoryService {

  applicantStatuses = [
    'All',
    'In Progress',
    'Submitted',
    'Needs Rep Info',
    'Probation',
    'Approved',
    'Denied',
    'Legacy',
    'Legacy - Probation',
    'Legacy - Approved',
    'Legacy - Deactivated'

  ]

  constructor(private dialog: MatDialog, private http: HttpClient, public userDetailsService: UserDetailsService, private employeeStoreService: EmployeeStoreService) { }

  activeUsers: Employee[] = [];
  inactiveUsers: Employee[] = [];


  public async getAllApplicants(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/applicants/' + sessionKey;

    return this.http.get<any[]>(url).toPromise();
  }


  public async getInvitingUserDetails(id: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();

    let url: string = REST_BASE_URL + '/employee/' + sessionKey + "?userId=" + id;

    return this.http.get<any[]>(url).toPromise();
  }


  public async getApplicantFromUserId(userId: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/applicant/fromUserId/' + sessionKey;
    url += "?userId=" + userId;
    return this.http.get<any[]>(url).toPromise();
  }



  public async getAllEmployees(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/allEmployees/' + sessionKey;

    return this.http.get<any[]>(url).toPromise();
  }



  public async getStatus(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/getStatus/' + sessionKey;



    return this.http.get<any[]>(url).toPromise();
  }


  public async moreInformationApplicant(applicantId: string, applicantEmailAddress: string, reason: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/moreInformationApplicant/' + sessionKey + "?applicantId=" + applicantId + "&applicantEmailAddress=" + applicantEmailAddress + "&denialReason=" + reason;

    return this.http.post<any[]>(url, {}).toPromise();
  }





  public async denyApplicant(applicantId: string, applicantEmailAddress: string, reason: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/denyApplicant/' + sessionKey + "?applicantId=" + applicantId + "&applicantEmailAddress=" + applicantEmailAddress + "&denialReason=" + reason;

    return this.http.post<any[]>(url, {}).toPromise();
  }



  public async approveApplicant(applicantId: string, email: string = null): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/approveApplicant/' + sessionKey + "?applicantId=" + applicantId;
    if (email) {
      url += "&emailAddress=" + email;
    }
    return this.http.get<any[]>(url).toPromise();
  }


  public async setApplicantStatus(applicantId: string, status: string, applicantEmailAddress: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/setApplicantStatus/' + sessionKey + "?applicantId=" + applicantId + "&status=" + status + "&applicantEmailAddress=" + applicantEmailAddress;
    return this.http.get<any[]>(url).toPromise();
  }

  public async setApplicantStatusByApplicant(applicantSessionKey: string, applicantId: string, applicantEmailAddress: string): Promise<any> {
    let url: string = REST_BASE_URL + '/setApplicantStatus/applicantUpdate/' + applicantSessionKey + "?applicantId=" + applicantId + "&applicantEmailAddress=" + applicantEmailAddress;
    return this.http.post<any[]>(url, {}).toPromise();
  }



  public async insertW9FromAdmin(applicantId: string, applicantEmailAddress: string, w9File: File): Promise<any> {
    let sessionKey = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/insertApplicantW9File/fromAdmin/' + sessionKey;
  
    let body = new FormData();

    body.append('applicantEmailAddress', applicantEmailAddress);
    body.append('applicantId', applicantId);
    body.append('w9File', w9File);

  
    return this.http.post<any[]>(url, body).toPromise();
  }


  public async insertRepAgreementFromAdmin(applicantId: string, applicantEmailAddress: string, repAgreement: File): Promise<any> {
    let sessionKey = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/insertApplicantRepAgreement/fromAdmin/' + sessionKey;

    let body = new FormData();

    body.append('applicantEmailAddress', applicantEmailAddress);
    body.append('applicantId', applicantId);
    body.append('repAgreementFile', repAgreement);
    return this.http.post<any[]>(url, body).toPromise();
  }

  public async insertIdentificationFromAdmin(applicantId: string, applicantEmailAddress: string, driversLicense: File, birthCertificate: File, socialSecurityCard: File, passport: File): Promise<any> {
    let sessionKey = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/insertApplicantIdentification/fromAdmin/' + sessionKey;
  
    let body = new FormData();
 
    body.append('applicantEmailAddress', applicantEmailAddress);
    body.append('applicantId', applicantId);

    if (driversLicense) {
      body.append('driversLicense', driversLicense);
    }

    if (birthCertificate) {
      body.append('birthCertificate', birthCertificate);
    }

    if (socialSecurityCard) {
      body.append('socialSecurityCard', socialSecurityCard);
    }

    if (passport) {
      body.append('passport', passport);
    }

  
    return this.http.post<any[]>(url, body).toPromise();
  }

  public async createUserFromApplicant(applicantId: string, applicantEmailAddress: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/createUserFromApplicant/' + sessionKey + "?applicantId=" + applicantId + "&emailAddress=" + applicantEmailAddress;

    return this.http.get<any[]>(url).toPromise();
  }


  public async adminInsertBankInfo(info: BankInfo, applicantId: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/insertApplicantBank/adminUpdate/' + sessionKey;

    let body = new FormData();

    body.append('accountHolderName', info.accountHolderName ? info.accountHolderName : "");
    body.append('applicantId', applicantId ? applicantId : "");
    body.append('bankName', info.bankName ? info.bankName : "");
    body.append('accountType', info.accountType ? info.accountType : "");
    body.append('achRoutingNumber', info.achRoutingNumber + "" ? info.achRoutingNumber+ "" : "");
    body.append('accountNumber', info.accountNumber+ "" ? info.accountNumber+ "" : "");
    body.append('wireRoutingNumber', info.wireRoutingNumber + "" ? info.wireRoutingNumber+ "" : "");
    body.append('imageFile', info.imageFile);
    body.append('type', info.type ? info.type+ "" : "");

    return this.http.post<any>(url, body).toPromise();
  }

  public async adminInsertApplicantBasicInfo(applicant: ApplicantData): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/insertApplicantBasicInfo/adminUpdate/' + sessionKey;

    let body = new FormData();

    body.append('applicantId', applicant.id ? applicant.id : "");
    body.append('firstName', applicant.firstName ? applicant.firstName : "");
    body.append('lastName', applicant.lastName ? applicant.lastName : "");
    body.append('phoneNumber', applicant.phoneNumber ? applicant.phoneNumber : "");
    body.append('emailAddressSecondary', applicant.emailAddressSecondary ? applicant.emailAddressSecondary : "");
    body.append('officeLocation', applicant.officeLocation  ? applicant.officeLocation : "");
    body.append('address', applicant.address ? applicant.address : "");
    body.append('city', applicant.city ? applicant.city : "");
    body.append('state', applicant.state ? applicant.state : "");
    body.append('zip', applicant.zip ? applicant.zip : "");
    body.append('dateOfBirth', applicant.dateOfBirth ? applicant.dateOfBirth : "");
    body.append('imageFile', applicant.userImage);
    body.append('notes', applicant.notes ? applicant.notes : "");
    body.append('insertDateTime', applicant.insertDateTime);
    body.append('scar', applicant.scar+"");
    body.append('idBadgePrinted', applicant.idBadgePrinted+"");

    return this.http.post<any>(url, body).toPromise();
  }

  public async addApplicantNote(applicantId: string, newNote: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/applicantNotes/' + sessionKey;

    let body = new FormData();
    body.append('applicantId', applicantId);
    body.append('newNoteContents', newNote);

    return this.http.post<any>(url, body).toPromise();
  }



  public async addApplicantFile(applicantFile: ApplicantFile): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/applicantFiles/' + sessionKey;

    let body = new FormData();
    body.append('applicantId', applicantFile.applicantId);
    body.append('file', applicantFile.file, applicantFile.file.name);
    body.append('title', applicantFile.title);

    return this.http.post<any>(url, body).toPromise();
  }


  async downloadBadge(applicant: ApplicantData, option: string): Promise<boolean> {

    let lengthOfFirstName = applicant.firstName.length;
    let lengthOfLastName = applicant.lastName.length;

    if (lengthOfFirstName + lengthOfLastName > 15) {
      const doc = new jsPDF();
      doc.text("See below for generated badge", 10, 10)
      doc.addPage("a6", "p");

      doc.setFontSize(25);
      doc.text(applicant.firstName, 10, 10);
      doc.text(applicant.lastName, 10, 20);

      doc.setFontSize(15);
      doc.text("Energy Consultant", 10, 30);

      const repId: string = this.generateRepId(applicant);
      doc.text("ID # " + repId, 10, 40);

      const imageUrl: string = await this.generateApplicantImageUrl(applicant);


      if (option === "1") {
        doc.addImage(imageUrl, "JPEG", 30, 50, 50, 50);
        doc.addImage("../../../assets/badge.png", "JPEG", 0, 120, 105, 30);
        // doc.save(applicant.firstName + applicant.lastName + ".pdf");
        window.open(doc.output('bloburl').toString())


      } else if (option === "2") {
        doc.addImage(imageUrl, "JPEG", 30, -10, 50, 50, null, null, -90);
        doc.addImage("../../../assets/badge.png", "JPEG", 0, 120, 105, 30);
        // doc.save(applicant.firstName + applicant.lastName + ".pdf");
        window.open(doc.output('bloburl').toString())
      }


      return true
    }
    else {


      const doc = new jsPDF();
      doc.text("See below for generated badge", 10, 10)
      doc.addPage("a6", "p");

      doc.setFontSize(25);
      doc.text(applicant.firstName + " " + applicant.lastName, 10, 10);

      doc.setFontSize(15);
      doc.text("Energy Consultant", 10, 20);

      const repId: string = this.generateRepId(applicant);
      doc.text("ID # " + repId, 10, 30);

      const imageUrl: string = await this.generateApplicantImageUrl(applicant);


      if (option === "1") {
        doc.addImage(imageUrl, "JPEG", 30, 40, 50, 50);
        doc.addImage("../../../assets/badge.png", "JPEG", 0, 120, 105, 30);
        // doc.save(applicant.firstName + applicant.lastName + ".pdf");
        window.open(doc.output('bloburl').toString())


      } else if (option === "2") {
        doc.addImage(imageUrl, "JPEG", 30, -10, 50, 50, null, null, -90);
        doc.addImage("../../../assets/badge.png", "JPEG", 0, 120, 105, 30);
        // doc.save(applicant.firstName + applicant.lastName + ".pdf");
        window.open(doc.output('bloburl').toString())
      }


      return true

    }




  }

  generateRepId(applicant: ApplicantData): string {
    let userId = applicant.userId;
    let employee = this.employeeStoreService.getUser(+userId);
    if (!employee) { return "" }
    return employee.employeeId ? employee.employeeId : "";
  }

  async generateApplicantImageUrl(applicant: ApplicantData): Promise<string> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let output: string = REST_BASE_URL + "/applicantUserImage/" + sessionKey;
    output += "?applicantId=" + applicant.id;
    return output;
  }



  async getApplicantFile(file: ApplicantFile): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + "/applicantFiles/getFile/" + sessionKey + "?applicantId=" + file.applicantId + "&url=" + file.file;
    console.log(url)
    return this.http.get<any>(url).toPromise();
  }




  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

}