import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatBottomSheetRef, MatDialogRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { fileToResourceFile } from 'src/app/shared/file-functions';
import { getBlobFromFile } from 'src/app/shared/generic-functions';
import { ResourceFile } from 'src/app/shared/models/help.model';

@Component({
  selector: 'app-add-new-modal',
  templateUrl: './add-new-modal.component.html',
  styleUrls: ['./add-new-modal.component.scss'],
})
export class AddNewModalComponent implements OnInit {
  files: any[] = [];

  shouldShowDragAndDrop: boolean = false;

  constructor(private _bottomSheetRef: MatBottomSheetRef<AddNewModalComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public zone: NgZone,
  private sanitizer: DomSanitizer,
) { }

  ngOnInit() { }


  fileBrowseHandler($event) {
    this.onFileDropped($event)
  }

  onFileDropped($event) {
    for (const item of $event) {
      this.files.push(item);
    }
  }

  done() {
    this._bottomSheetRef.dismiss({ videos: this.files });
  }

  cancel() {
    this._bottomSheetRef.dismiss();

  }


  /**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  /**
* Delete file from files list
* @param index (File index)
*/
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }


  addVideo() {
    this._bottomSheetRef.dismiss({ command: "addVideoFromLink" })
  }

  toggleDragAndDrop() {
    this.shouldShowDragAndDrop = !this.shouldShowDragAndDrop;
  }


  takePicture() {

  }


  selectedFile: any;
  imgURL: any;

  // Take new photo
  onFileSelected(event: any) {

    const Img = new Image();

    // const filesToUpload = (event.target.files);
    const URL = window.URL || window.webkitURL;
    Img.src = URL.createObjectURL(event.target.files[0]);

    getBlobFromFile(event.target.files[0]).then((res) => {
      let objectURL = URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });

    this.zone.run(() => {
      console.log("redrawing component ");
    });

    this.selectedFile = event.target.files[0];

    this.files.push(this.selectedFile)

  }

}
