<div style="height: 200px; min-width: 450px">
  <app-user-picker
  [selectedUserIds]='this.campaignDetails.eligibleUsers'
  style="height: 200px; width: 100%"></app-user-picker>
</div>

<div *ngFor='let milestone of this.campaignDetails.milestones' >

  <app-edit-milestone-row [milestone]='milestone'></app-edit-milestone-row>

</div>
<div>
  <button mat-button class="button-primary" (click)='addDefaultMilesone()'>
    New Milestone 
  </button>
</div>