<div style="padding: 10px;">



  <ion-toolbar class="transparent-bg">
    <div slot="start">
      {{this.message.contents}}
    </div>

    <div slot="end">
      <button mat-icon-button (click)="navigateToLead()">
        <span class="material-icons iconAlign">
          chevron_right
          </span>
          
      </button>
    </div>
  </ion-toolbar>

  <mat-divider></mat-divider>
  <ion-toolbar class="transparent-bg">

    <div slot="start">
      <h1 style='margin-top: 5px; margin-bottom: 5px; font-size: 20px;'>{{this.leadsService.getCustomerName(this.message.lead)}}</h1>
    </div>

  </ion-toolbar>

  <ion-toolbar class="transparent-bg">

    <div slot="start">
     <span class="opaque-text">{{this.message.lead?.fullAddress}}</span> 
    </div>

  </ion-toolbar>

  <div style="height: 10px;"></div>

  <div class="opaque-div" *ngFor="let change of this.message.leadChanges" style="border-radius:10px; padding: 5px; margin-bottom: 3px;">
    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <span style="font-weight: 500;">{{ getColumnName(change) }}</span>
      </div>

    </ion-toolbar>

    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <div style="width: 20px;"></div>
      </div>

      <div slot="start">
        {{change.oldValue}}
      </div>

      <div slot="start">
        <span class="material-icons iconAlign">
          arrow_right_alt
        </span>

      </div>
      <div slot="start">
        {{change.newValue}}
      </div>

    </ion-toolbar>


  </div>

</div>