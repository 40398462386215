import { Platform } from '@ionic/angular';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AdvancedAnalyticsComponent } from 'src/app/reports/advanced-analytics/advanced-analytics.component';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { StratFieldEnum } from 'src/app/shared/enums/stratFieldEnum';
import { DataReportMenu } from 'src/app/shared/models/dashboard-report-list.model';
import { DashboardData, UserData } from 'src/app/shared/models/dashboard.model';
import { Employee } from 'src/app/shared/models/employees.model';
import { FreshReport } from 'src/app/shared/models/fresh-report.model';
import { Incentive } from 'src/app/shared/models/incentives.model';
import { ReportCellList } from 'src/app/shared/models/report-cell-list.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { FreshReportService } from 'src/app/shared/services/fresh-report.service';
import { IncentivesStoreService } from 'src/app/shared/services/incentives-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, ConversationsResponse, MessagingService, ReportLine } from 'src/app/shared/services/messaging.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { MessageSelectRecipientComponent } from 'src/app/messaging/message-select-recipient/message-select-recipient.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-employee-drill-down',
  templateUrl: './home-employee-drill-down.component.html',
  styleUrls: ['./home-employee-drill-down.component.scss'],
})
export class HomeEmployeeDrillDownComponent implements OnInit {
  employee: Employee;
  leaderboardReport: DashboardData;
  sessionKey: string;
  selectedTimePeriodOptions: string;
  selectedDataSetOption: string;
  accessLevel: number = -1;
  conversations: ConversationModel[] = [];
  currentInstallCount: number = 0;
  phoneNumber: string = "";
  incentives: Incentive[] = [];
  email: string = "";
  personalInformationRecords: any[] = [];

  originReportLine: ReportLine;

  progressBarValue: number = 0;

  constructor(public incentivesService: IncentivesService,
    private snackBar: MatSnackBar,
    private navController: NavController,
    public router: Router,
    private platform: Platform,
    private nav: HeaderService,
    private localStorage: Storage,
    private dialog: MatDialog,
    public messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    public userDetailsService: UserDetailsService,
    public dashboardService: DashboardService,
    public freshReportService: FreshReportService,
    public incentivesStoreService: IncentivesStoreService,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public reportsService: ReportsService) {




  }

  ionViewWillLeave() {
  }

  ionViewWillEnter() {

  }


  ngOnInit() {
    this.route.params.subscribe(val => {

      if (!history.state.employee) {
        // this.router.navigate(["/home"])
        return;
      }

      this.employee = history.state.employee;
      this.originReportLine = history.state.reportLine;
      this.selectedTimePeriodOptions = history.state.selectedTimePeriodOptions;
      this.selectedDataSetOption = history.state.selectedDataSetOption;
      this.initializeValues();

    });
  }

  async initializeValues(): Promise<void> {

    this.initUserDataValues();

  }

  async initUserDataValues() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.accessLevel = await this.userDetailsService.getAccessLevel();

    if (this.employee.userDto.phoneNumber) {
      this.phoneNumber = "tel:" + this.employee.userDto.phoneNumber.replace("+", "");
    }

    if (this.employee.attributeMap["Sales Rep Email"]) {
      this.email = this.employee.attributeMap["Sales Rep Email"];
    }


    this.getReports();
    this.loadConversations();
  }


  mailto() {


    this.platform.ready().then(() => {
      window.open('mailto:' + this.email);
    });
  }

  getBackgroundImage(record: PersonalRecord) {
    return record.image ? record.image : ""
  }



  goBack(): void {
    this.navController.pop();
  }

  public reports: DashboardData[];

  private async getReports(): Promise<void> {
    const reportsResponse: any = await this.freshReportService.getEmployeeReports(this.employee.userDto.id);
    const tempReports: DashboardData[] = reportsResponse.reports.map((report, index) => {
      return {
        freshReport: new FreshReport(),
        reportMenu: report.menu,
        reportCellList: report,
        hasBeenSaved: true,
        viewFilter: 'Table',
        reportOrder: index
      }
    });
    tempReports.forEach(data => {
      data.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(data.reportMenu.viewType)
      data.viewFilter = data.reportMenu.viewType;
      data.freshReport.id = data.reportOrder;
      if (data.reportMenu?.title) {
        data.freshReport.reportName = data.reportMenu?.title;
      }
    })
    this.reports = tempReports;
  }


  async showAdvancedAnalytics(report: DashboardData) {
    this.dashboardService.showAdvancedAnalytics(report);
  }

 



  private loadConversations(): void {
    this.conversations = this.messagingStoreService.getStoredConversations().filter(c => this.includesParticipant(c));

  }

  private includesParticipant(convo: ConversationModel): boolean {
    return convo?.participants?.findIndex(p => p.id === this.employee?.userDto?.id) >= 0;
  }



  initials: string;
  getInitials(): string {
    if (this.employee && this.employee.userDto.preferredFirstName !== null) {
      return (this.employee?.userDto?.preferredFirstName[0] + this.employee?.userDto?.lastName[0]).toUpperCase();
    }
    if (this.initials) {
      return this.initials;
    }
    return '';
  }


  messageRep() {
    this.dialog.closeAll();
    // this.router.navigateByUrl('/selectRecipient', { state: { employeesToMessage: [this.employee] } });



      let ref = this.dialog.open(MessageSelectRecipientComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',
        data: {
          employeesToMessage: [this.employee],
          reportLines: [this.originReportLine]
        },
        panelClass: 'transparent',
      })
  
      ref.afterClosed().subscribe(res => {
        if (!res) {return}
        const reportLines: ReportLine[] = res.reportLines ? res.reportLines : [];
        if (!res.isBCC) {
          this.router.navigate(["/messages/" + res.conversationId], {state: {reportLines: [this.originReportLine]}});
        } else if (res.isBCC) {
          this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend, reportLines: [this.originReportLine]  } });
        }

      })
      

  }

  openMessageView(model: ConversationModel) {

    if (model.unreadMessageCount > 0) {
      this.messagingStoreService.markSelectedConvoAsReadLocally(model);
      this.messagingService.markConvoRead( model.conversationId).then((result) => {
      });
    }

    this.dialog.closeAll();
    this.router.navigateByUrl("/messages/" + model.conversationId);
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
}



}


export class PersonalRecord {
  label: string;
  value: any;
  image: string;
}