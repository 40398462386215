import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-welcome-email-modal',
  templateUrl: './welcome-email-modal.component.html',
  styleUrls: ['./welcome-email-modal.component.scss'],
})
export class WelcomeEmailModalComponent implements OnInit {
  emailAddress: string = "";
  constructor(public dialogRef: MatDialogRef<WelcomeEmailModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {}


  submit() {
    this.dialogRef.close(this.emailAddress);
  }

}
