<div class="app-background-color app-text-color" *ngIf="data.mode === 'editName'" style="padding: 20px; max-width: 500px; display: block; margin: auto">


  <ion-grid>
    <ion-row>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Edit Name</mat-label>
          <input matInput [(ngModel)]="reportName" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </form>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <button mat-button (click)="cancel()" style="width:100%"> Cancel </button>
      </ion-col>

      <ion-col size="6">
        <button mat-button (click)="save()" style="width:100%"> OK </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>


<div  class="app-background-color app-text-color" *ngIf="data.mode === 'leave'" style="padding: 20px; max-width: 500px; display: block; margin: auto">
  <p> Are you sure you want to leave? </p>
  <p> You will lose unsaved changes </p>


  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <button mat-button (click)="leave()" style="width:100%"> Leave </button>
      </ion-col>

      <ion-col size="6">
        <button mat-button (click)="stay()" style="width:100%"> Stay </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>