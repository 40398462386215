import { Feature } from '@agm/core/services/google-maps-types';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { LibraryService } from 'src/app/shared/services/library.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AddSectionComponent } from '../add-section/add-section.component';
import { Featured } from '../library.component';

@Component({
  selector: 'app-edit-featured-section',
  templateUrl: './edit-featured-section.component.html',
  styleUrls: ['./edit-featured-section.component.scss'],
})
export class EditFeaturedSectionComponent implements OnInit {

  featuredSection: Featured;
  type: string = "video";

  constructor(public snackbarService: SnackbarService, public busySpinner: BusySpinnerService, public libraryService: LibraryService, private zone: NgZone, public dialogRef: MatDialogRef<AddSectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    if (this.data?.featuredSection) {
      this.featuredSection = this.data.featuredSection
    } else {
      this.featuredSection = {
        title: "",
        videoUrl: "",
        videoThumbnail: ""
      }
    }

    if(this.data?.type) {
      this.type = this.data?.type
    }
  }
  ngOnInit() {}

  goBack() {
    this.dialogRef.close();
  }

  async done() {
  this.busySpinner.start();

   let res = await this.libraryService.setFeaturedSection(this.featuredSection.title, this.featuredSection.videoThumbnailFile, this.featuredSection.videoUrl, this.type);

   if (res.success) {
    this.dialogRef.close(this.featuredSection);
    this.busySpinner.stop();
    this.snackbarService.displaySnackBarMessage("Upload Successful", true);

   }
   else {
    this.busySpinner.stop();
    this.snackbarService.displaySnackBarMessage("An Error Occurred", false);

   }

  }

  selectedFile: any;

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];

    this.featuredSection.videoThumbnailFile = this.selectedFile;

    // let reader = this.getFileReader();

    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.featuredSection.videoThumbnail = event.target.result;
    };

    reader.onerror = (event: any) => {
      console.log("File could not be read: " + event.target.error.code);
    };

    reader.readAsDataURL(event.target.files[0]);

  }
}
