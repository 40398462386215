<ion-header class="ion-no-border app-background-color">
  <ion-toolbar class="transparent-bg" style="max-width: 1200px; display: block; margin: auto;">
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button (click)="goBack()">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>


    <div slot="end">
      <button mat-button  class="primary-color" (click)="done()">
        Done
      </button>
    </div>


  </ion-toolbar>
</ion-header>

<ion-content class="app-background-color">
  <div style='max-width: 600px; display: block; margin: auto;'>

    <button (click)="fileInput.click()" 
      style="width: 200px; height: 100px; display: block; margin: auto; background-image: url({{this.featuredSection?.videoThumbnail}}); background-size: cover; object-fit: cover; background-repeat: no-repeat;"
      mat-raised-button>
      <span class="material-icons" style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
        photo_camera
      </span>
    </button>

    <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="image">


    <ion-grid>
      <ion-row>
        <ion-col size="12" style="padding: 0; margin: 0;">
          <ion-row style="padding: 0; margin: 0;">
            <mat-form-field style="width: 100%;" floatLabel='never'>
              <input [(ngModel)]="featuredSection.title" class="non-bolded-placeholder" matInput
                placeholder="Section Title" style="width: 100%;">
            </mat-form-field>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="5">

          <mat-form-field style="width: 100%;" floatLabel='never'>
            <input [(ngModel)]="featuredSection.videoUrl" class="non-bolded-placeholder" matInput placeholder="URL"
              style="width: 100%;">
          </mat-form-field>
        </ion-col>


        <ion-col size=2>
          <span class="opaque-text">Or</span>
        </ion-col>
  
        <ion-col size="5">
          <button class="primary-color" mat-button> Select File </button>
        </ion-col>

      </ion-row>








    </ion-grid>
  </div>
</ion-content>