import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Geolocation } from '@capacitor/geolocation';
import { SnackbarService } from './snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    constructor(public snackbarService: SnackbarService) { }

    async getLocationCoordinates() {
        const coordinates = await Geolocation.getCurrentPosition();
        this.snackbarService.displaySnackBarMessage("Your location:" + coordinates.coords.longitude + " " + coordinates.coords.latitude);
        console.log(coordinates)
        return coordinates;
    }

}
