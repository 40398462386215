export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAvJ4cdveTBhbowI2VWOnZXgTVfgv1IAus",
    authDomain: "enzy-74bf3.firebaseapp.com",
    databaseURL: "https://enzy-74bf3.firebaseio.com",
    projectId: "enzy-74bf3",
    storageBucket: "enzy-74bf3.appspot.com",
    messagingSenderId: "710237939254",
    appId: "1:710237939254:web:48ba8475a1e758772eda97",
    measurementId: "G-0LCXGLW1BD"
  },
  GOOGLE_MAPS_API_KEY: "AIzaSyAc6pNCTa65o_Vzo18IOdPCvbqcPyOPM-c",
};
