import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-new-note-modal',
  templateUrl: './new-note-modal.component.html',
  styleUrls: ['./new-note-modal.component.scss'],
})
export class NewNoteModalComponent implements OnInit {
  newNoteContents: String;
  constructor(public dialogRef: MatDialogRef<NewNoteModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  submit() {
    this.dialogRef.close(this.newNoteContents)
  }

}
