import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadProfileImageService {

  constructor() { }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  handleImageSelection(event: Event): Observable<string> {
    const file = (<HTMLInputElement>event.target).files[0];

    let fileReader = this.getFileReader();

    fileReader.readAsDataURL(file);
    return new Observable((observer) => {
      fileReader.onloadend = () => {
        observer.next(<string>fileReader.result);
        observer.complete();
      };
    });
  }
  
}
