
<div class="app-background-color" style="padding: 10px; overflow: hidden; height: 400px;">
  <div *ngIf='!this.selectSingleUser'>
    <ion-toolbar>
      <div slot="end">
        <button class="primary-color" mat-raised-button (click)='close()'>
          Done
        </button>
      </div>

    </ion-toolbar>

  </div>

  <app-user-picker style="height: 360px;"
    (selectedUsersOutput)='handleUsersSelected($event)'
    [selectedUserIds]='selectedUserIds'
    >
  </app-user-picker>

</div>