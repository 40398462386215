

  <div class="appointments-section" style="max-width: 600px; display: block; margin: auto;">




    <ion-toolbar class="transparent-bg">

      <div slot="start">
        <div style="width:10px"></div>
      </div>
      <div slot="start">
        <button class="filter-button" [matMenuTriggerFor]="timePeriodFilter" style="border-radius: 20px;" mat-button>
          {{selectedFilter}}
          <i class="material-icons">
            arrow_drop_down
          </i>
        </button>
      </div>

      <div slot="start">
        <div style="width:10px"></div>
      </div>

      <div slot="start">
        <button class="filter-button" [matMenuTriggerFor]="typeFilter" style="border-radius: 20px;" mat-button>
          {{selectedType}}
          <i class="material-icons">
            arrow_drop_down
          </i>
        </button>
      </div>


      <mat-menu #timePeriodFilter="matMenu">
        <ng-template matMenuContent>

          <div *ngFor="let option of getFilterOptions()">
            <button mat-menu-item style="text-align: center;" (click)="selectFilter(option)">
              {{option}}
            </button>
          </div>
        </ng-template>
      </mat-menu>


      <mat-menu #typeFilter="matMenu">
        <ng-template matMenuContent>

          <div *ngFor="let option of getTypeOptions()">
            <button mat-menu-item style="text-align: center;" (click)="selectType(option)">
              {{option}}
            </button>
          </div>
        </ng-template>
      </mat-menu>


    </ion-toolbar>



    <div style="padding: 10px">
      <div class="section-div" style="text-align: left; padding: 10px;">

        <div *ngIf="selectedType === 'Appointments'">
          <div *ngFor="let appointment of getAppointmentsByFilter(); let i = index">
            <!-- <span style="font-weight: 100;">{{getNameFromAppointment(appointment)}}</span> -->

            <enzy-appointment-row [appointment]="appointment" (click)="openLeadForAppointment(appointment)"></enzy-appointment-row>
            <div style="height: 20px;"></div>
          </div>

          <div *ngIf="getAppointmentsByFilter().length === 0" style="text-align: center; line-height: 60px;" class="opaque-text">No Appointments</div>

        </div>

        <div *ngIf="selectedType === 'Tasks'">
          <div *ngFor="let task of getTasksByFilter(); let i = index">
            <span style="font-weight: 100;">{{getNameFromTask(task)}}</span>

            <enzy-task-row (saveTaskChanges)="saveTaskChanges($event)" [task]="task" (click)="openLeadForTask(task)"></enzy-task-row>
            <div style="height: 20px;"></div>
          </div>

          <div *ngIf="getTasksByFilter().length === 0" style="text-align: center; line-height: 60px;" class="opaque-text">No Tasks</div>

        </div>

       

      </div>
    </div>

  </div>
