<ion-grid>
  <div *ngFor="let row of rows; let i = index">
    <div *ngIf="isDashboard && i < 4">
      <ion-row style="padding: 0; margin: 0; ">
        <ion-col size="6" style="text-align: left; padding: 0; margin: 0;  font-size: 14px;  font-weight: 100; ">
          {{row.title}}
        </ion-col>

        <ion-col size="6" style="text-align: right; padding: 0; margin: 0; font-size: 14px; font-weight: 500;">
          {{row.amount}}
        </ion-col>
      </ion-row>

      <ion-row style="padding: 0; margin: 0; margin-bottom: 10px;">
        <ion-col size="12" style="padding: 0; margin: 0;">
          <mat-progress-bar mode="determinate" value="{{row.value}}"></mat-progress-bar>
        </ion-col>
      </ion-row>
      <div style="height: 10px;"> </div>
    </div>




    <!--ADVANCED Analytics Page; they can have as many as they want-->
    <div *ngIf="!isDashboard">
      <ion-row style="padding: 0; margin: 0;" >
        <ion-col size="6" style="text-align: left; padding: 0; margin: 0; font-size: 14px; font-weight: 100;">
          {{row.title | titlecase}}
        </ion-col>

        <ion-col size="6" style="text-align: right; padding: 0; margin: 0;font-size: 14px; font-weight: 500;">
          {{row.amount}}
        </ion-col>
      </ion-row>

      <ion-row style="padding: 0; margin: 0; margin-top: 5px;">
        <ion-col size="12" style="padding: 0; margin: 0;">
          <mat-progress-bar mode="determinate" value="{{row.value}}"></mat-progress-bar>
        </ion-col>
      </ion-row>
      <div style="height: 30px;"> </div>
    </div>


  </div>

</ion-grid>
