<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<ion-header no-border class="no-padding-header ion-no-border">
  <div style="width: 100%; max-width: 1200px; display: block; margin: auto;">
    <ion-toolbar class="no-padding-header  ">
      <div slot="start">
        <button mat-fab class="app-text-color"
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>

      <div>
        <h1 style="text-align: center; margin: 0; font-size: 18px;"> Reset Password</h1>
      </div>


      <div slot="end">
        <button mat-fab class="app-text-color"
          style="opacity: 0; text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>
    </ion-toolbar>
  </div>
</ion-header>

<ion-content>
  <div style="max-width: 600px; display: block; margin: auto;">

    <div style="height: 50px"></div>
  
    <div style="height: 20px"></div>
    <img style="height: 50px; display: block; margin: auto; border-radius: 10px;" src="/assets/enzyLogoColor.png" alt="">
    <div style="height: 10px"></div>
  
    <div>
  
      <!-- <p style="text-align: center;">BETA</p> -->
  
      <div style="height: 20px"></div>
      <mat-form-field style="width: 80%; display: block; margin: auto;">
        <input matInput placeholder="Email" [(ngModel)]="email" (keyup.enter)="reset()">
      </mat-form-field>
  
  
      <div style="height: 20px;"></div>

      
      <div *ngIf="wasSubmitted"> 
        <div *ngIf="success" style="background-color: #6dbf6e; color: white; width: 80%; display: block; margin: auto; height: 50px; border-radius: 10px;box-shadow: 0px 1px 7px -3px #000000;">
          <p style="text-align: center; line-height: 50px;">Submitted Successfully! Check Email.</p>
        </div>

        <div *ngIf="!success" style="background-color: #d8db6b; color: black; width: 80%; display: block; margin: auto; height: 50px; border-radius: 10px;box-shadow: 0px 1px 7px -3px #000000;">
          <p style="text-align: center; line-height: 50px;">{{errorMessage}}</p>
        </div>

        <div style="height: 20px;"></div>
      </div>

  
      <button mat-raised-button
        style="width: 80%; height: 50px; border-radius: 25px;   font-size: 20px;  display: block; margin: auto;"
        class="primary-color" (click)="reset()">Submit</button>
      <div style="height: 10px;"></div>
  
  
  
  
    </div>
  </div>
</ion-content>
