import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BasicFilterComponent } from '../reports/filters/basic-filter/basic-filter.component';
import { BusySpinnerComponent } from '../shared/components/busy-spinner/busy-spinner.component';
import { KeyWordSearchComponent } from '../shared/components/key-word-search/key-word-search.component';
import { SearchHeaderIcon } from '../shared/components/search-header/search-header.component';
import { TableFilterButton, TableFilterComponent } from '../shared/components/table-filter/table-filter.component';
import { DashboardData, UserData } from '../shared/models/dashboard.model';
import { Incentive } from '../shared/models/incentives.model';
import { IncentivesStoreService } from '../shared/services/incentives-store.service';
import { IncentivesService, incentivesSortComparator, TournamentModel } from '../shared/services/incentives.service';
import { TableFilterService } from '../shared/services/table-filter.service';
import { UserDetailsService } from '../shared/services/user-details.service';
import { TicketModel } from '../tickets/tickets.service';
import { NewIncentiveModalComponent } from './new-incentive-modal/new-incentive-modal.component';



@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss'],

})
export class IncentivesComponent implements OnInit {
  isDemoUser = false;
  userData: UserData;

  sessionKey: string = "";

  incentives: Incentive[] = [];

  dataSource: MatTableDataSource<Incentive>;


  updateDataSource() {
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(this.getIncentivesForTable());
    }

    this.dataSource.data = this.getIncentivesForTable();
  }


  getIncentivesForTable(): Incentive[] {
    return this.incentives?.filter(t => this.tableFilterService.shouldDisplay(t, this.filtersObjects, t.startDate)).sort((a, b) => incentivesSortComparator(a, b));;
  }




  handleClearedFilter(tableFilter: TableFilterButton) {
    this.filtersObjects.forEach(filter => {
      if (filter.label === tableFilter.label) {
        filter.selectedValue = null;
      }
    })
    this.updateDataSource();
 }


  getHeaderIcons() {
    return []
  }


  filtersObjects: TableFilterButton[] = [
    {
      objectKey: "startDate",
      label: "Start Date",
      selectedValue: null,
      type: "date",
    },
    {
      objectKey: "isLocked",
      label: "Locked",
      selectedValue: null,
      type: "boolean",
    },


    
  ]


  handleSearchInputAction(searchValue: string) {
    this.dataSource.filter = searchValue.trim().toLowerCase();
  }


  handleIconAction(icon: SearchHeaderIcon) {
    if (icon.title === "Filters") {
      this.openFilters();
    }
  }

  openFilters() {
    let ref = this.bottomSheet.open(TableFilterComponent, { data: { filters: this.filtersObjects } });
    ref.afterDismissed().subscribe((res: TableFilterButton[]) => {
      if (!res) { return }
      this.filtersObjects = res;

      this.updateDataSource();
    });
  }





  constructor(public routerOutlet: IonRouterOutlet,
    public router: Router,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    public incentivesService: IncentivesService,
    public incentivesStoreService: IncentivesStoreService,
    public bottomSheet: MatBottomSheet,
    public tableFilterService: TableFilterService
  ) {
  }


  ionViewDidEnter() {
    this.routerOutlet.swipeGesture = false;
  }


  ngOnInit() {
    this.initIncentives();

  }

  async initIncentives() {
    this.userData = await this.userDetailsService.getUserData();
    this.sessionKey = await this.userDetailsService.getSessionKey();

    this.incentivesStoreService.incentives.subscribe(incentives => {
      this.incentives = incentives;
      this.updateDataSource();
    });



    this.incentivesStoreService.fetchLatestIncentives();
    // this.incentivesStoreService.fetchCurrentPrizes(this.campaignId);


  }



  doRefresh(event) {

    this.incentivesStoreService.fetchLatestIncentives();
    // this.incentivesService.getIncentives()

    this.incentivesService.getIncentiveMilestoneEmployee(this.sessionKey, "2", "5");


    let spinner = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    setTimeout(() => {
      event.target.complete();
      spinner.close();
    }, 1000);
  }




  showDetail(id: string) {
    this.router.navigate(['/incentives/' + id]);
  }

  public processIncentiveAction(incentive: Incentive) {
    if (!incentive.isLocked || this.userData.accessLevel === 10) {
      this.routerOutlet.swipeGesture = true;

      if (incentive.campaignType === "tournament") {
        this.router.navigateByUrl('/game-detail?id=' + incentive?.id, { state: { incentive: incentive } });
      } else if (incentive.campaignType === "milestone") {
        this.router.navigateByUrl('/milestones', { state: { incentive: incentive } });
      }

    }
    else {
      this.openLockedDialog("never");
    }
  }

  openNewIncentive() {
    let dialogRef = this.dialog.open(NewIncentiveModalComponent);

    dialogRef.afterClosed().subscribe((data: string) => {
      if (data !== undefined) {
        if (data === 'competition') {
          this.router.navigate(['/new-competition/']);
        }
      }
    });
  }

  openLockedDialog(date: string): void {
    if (this.isDemoUser === false) {
      const dialogRef = this.dialog.open(LockedIncentiveComponent, {
        width: '300px',
        data: { name: 'test', date: date }
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }

  }

  createTournament() {
    this.router.navigateByUrl("incentives/edit-tournament?id=" + -1);
  }

  userCanCreateTournament(): boolean {
    return this.userData?.id + '' === '489' || this.userData?.id + '' === '19' || this.userData?.id + '' === '1610'
  }

  openNewIncentiveModal() {
    this.router.navigateByUrl("incentives/new-incentive");
  }

}


@Component({
  selector: 'locked-incentive',
  templateUrl: 'locked-incentive.html',
})
export class LockedIncentiveComponent {

  constructor(
    public dialogRef: MatDialogRef<LockedIncentiveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
