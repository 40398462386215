import { A } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { IonRouterOutlet, NavController, Platform } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { ExpandImageModalComponent } from 'src/app/shared/components/expand-image-modal/expand-image-modal.component';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { UserData } from 'src/app/shared/models/dashboard.model';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, MessageModel, MessagingService, ParticipantModel } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { MessageListFilterEnum } from '../message-list-filter-enum';
import { MessageSelectRecipientComponent } from '../message-select-recipient/message-select-recipient.component';

@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss'],
})
export class ConversationListComponent implements OnInit, OnChanges {

  @Input() isMobile: boolean;

  // TODO: REPLACE WITH MESSAGING STORE SERVICE

  userId: string;
  currentAmountOfConversationsToRender: number = 15;
  customColumnSize = 4;
  currentOptions: ConversationModel[];


  constructor(public platform: Platform, 
    private dialog: MatDialog, 
    public routerOutlet: IonRouterOutlet, 
    public userDetailsService: UserDetailsService, 
    private zone: NgZone, 
    public messagingService: MessagingService, 
    public messagingStoreService: MessagingStoreService, 
    public router: Router, 
    private localStorage: Storage) {

    this.currentOptions = this.messagingStoreService.getStoredConversations();
    this.messagingStoreService.storeOutstandingNotifications();
    this.checkIfMessageMapIsEmpty();

  }

  async checkIfMessageMapIsEmpty() {
    const messageMapString: string = await this.localStorage.get("MESSAGE_MAP");
    const messageMap: Map<number, MessageModel[]> = JSON.parse(messageMapString);
    if (!messageMap || JSON.stringify(messageMap) === "{}") {
      let ref =  this.dialog.open(BusySpinnerComponent, {
        panelClass: "transparent",
        disableClose: false,
      });
      await this.messagingStoreService.loadAllConversations();
      ref.close();
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {

    this.currentOptions = this.messagingStoreService.getStoredConversations();
    this.initUserDataValues();
    this.setupConversations();

    this.platform.ready().then(() => {
      //Mobile
      if (this.platform.width() <= 500) {
        this.customColumnSize = 4
      } else if (this.platform.width() > 500 && this.platform.width() <= 800) {
        this.customColumnSize = 3
      }
      else {
        this.customColumnSize = 2
      }
    });
  }


  async initUserDataValues() {
    this.userId = await this.userDetailsService.getUserId();
    this.startConversationListener();
  }


  async setupConversations(): Promise<void> {

    this.currentOptions = await this.getFilteredConversationsFromStore();

    this.zone.run(() => {
      // console.log('redrawing component ');
    });
  }

  // getSortedConversations(): ConversationModel[] {
  //   return this.conversations.sort((convo1, convo2) => (convo1 > convo2) ? 1 : -1);
  // }

  openMessageView(model: ConversationModel) {

    this.messagingStoreService.storeOutstandingNotifications();

    this.router.navigateByUrl('/messages/' + model.conversationId);

    if (model.unreadMessageCount > 0) {
      this.messagingStoreService.markSelectedConvoAsReadLocally(model);
      this.messagingService.markConvoRead(model.conversationId).then((result) => {
        
      });
    }

  }

  newMessagePopup() {
    this.router.navigate(['/selectRecipient/']);
  }

  conversationWasClicked: number = -1;


  getConversationTitle(convo: ConversationModel): string {
    if (convo.title) return convo.title;
    if (convo.participants.length === 2 && (convo.participants[0].id === this.userId)) { return convo.participants[1].preferredFirstName + ' ' + convo.participants[1].lastName }
    if (convo.participants.length === 2 && (convo.participants[1].id === this.userId)) { return convo.participants[0].preferredFirstName + ' ' + convo.participants[0].lastName }
    return convo.participants.filter(user => user.id !== this.userId).map(user => user.preferredFirstName + ' ' + user.lastName).join(', ');
  }

  startConversationListener(): void {
    this.messagingStoreService.triggerRedraw$?.subscribe(async (inputString) => {
      this.currentOptions = await this.getFilteredConversationsFromStore();
      this.zone.run(() => {
        // console.log('redrawing component ');
      });
    })

    this.messagingService.triggerConvoReload$?.subscribe(async (inputString) => {
      this.currentOptions = await this.getFilteredConversationsFromStore();
      this.zone.run(() => {
        // console.log('redrawing component ');
      });
    })
  }



  currentFilter: MessageListFilterEnum = MessageListFilterEnum.ALL;

  selectAllFilter() {
    this.currentFilter = MessageListFilterEnum.ALL;
    this.searchSend();
  }

  selectUnreadFilter() {
    this.currentFilter = MessageListFilterEnum.UNREAD;
    this.searchSend();
  }

  selectDirectMessageFilter() {
    this.currentFilter = MessageListFilterEnum.DIRECT_MESSAGE;
    this.searchSend();
  }

  selectExcludeAutoChats() {
    this.currentFilter = MessageListFilterEnum.EXCLUDE_AUTO;
    this.searchSend();
  }

  allFilterSelected(): boolean {
    return this.currentFilter === MessageListFilterEnum.ALL;
  }

  unreadFilterSelected(): boolean {
    return this.currentFilter === MessageListFilterEnum.UNREAD;
  }

  directMessageFilterSelected(): boolean {
    return this.currentFilter === MessageListFilterEnum.DIRECT_MESSAGE;
  }

  excludeAutoChatfilterSelected(): boolean {
    return this.currentFilter === MessageListFilterEnum.EXCLUDE_AUTO;
  }

  keyword: string = "test";
  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";

  async searchSend() {
    this.currentOptions = await this.getFilteredConversationsFromStore();
  }

  currentFilterMethod(convo: ConversationModel): boolean {
    switch (this.currentFilter) {
      case MessageListFilterEnum.ALL: return true;
      case MessageListFilterEnum.UNREAD: return this.unreadFilter(convo);
      case MessageListFilterEnum.DIRECT_MESSAGE: return this.directMessageFilter(convo);
      case MessageListFilterEnum.EXCLUDE_AUTO: return this.excludeAutoFilter(convo);
      case MessageListFilterEnum.ALL: return true;
      default: return true;

    }
  }

  unreadFilter(convo: ConversationModel): boolean {
    return convo.unreadMessageCount > 0;
  }

  directMessageFilter(convo: ConversationModel): boolean {
    return convo.participants.length <= 2;
  }

  excludeAutoFilter(convo: ConversationModel): boolean {
    return convo.definingTeam === null || convo.definingTeam === '';
  }

  async unreadSortFunction(array: ConversationModel[]): Promise<void> {
    const tempArray: ConversationModel[] = [...array];
    let index: number = 0;
    const indicesToSplice: number[] = []
    const splicedConvos: ConversationModel[] = [];

    for (const convo of tempArray) {
      if (!await this.messagingService.conversationIsMutedForMe(convo)) {
        // console.log("foundarray", index)
        indicesToSplice.push(index);
      }
      index += 1;
    }

    indicesToSplice.reverse().forEach(index => {
      splicedConvos.push(...array.splice(index, 1));
    });

    splicedConvos.reverse();
    array.unshift(...splicedConvos);

  }

  getConversationStringOfParticipants(conversation: ConversationModel): string {
    return conversation.participants.map(participant => participant.preferredFirstName + " " + participant.lastName).join(",");
  }

  getConversationsToShow() {
    if (!this.currentOptions) return [];
    return this.getNonPinnedConversations().slice(0, this.currentAmountOfConversationsToRender);
  }

  getPinnedConversations(): ConversationModel[] {
    return this.currentOptions.filter(convo => this.messagingService.conversationIsPinnedForMe(convo, this.userId));
  }

  getNonPinnedConversations(): ConversationModel[] {
    return this.currentOptions.filter(convo => !this.messagingService.conversationIsPinnedForMe(convo, this.userId));
  }


  newConversation() {

    let ref = this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      panelClass: 'transparent',
    })

    ref.afterClosed().subscribe(res => {
      if (!res) { return }
      if (!res.isBCC) {
        this.router.navigateByUrl("/messages/" + res.conversationId);
      } else if (res.isBCC) {
        this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend } });
      }
    })

    // this.router.navigate(["/selectRecipient/"]);
  }

  async getFilteredConversationsFromStore(): Promise<ConversationModel[]> {
    const output = this.messagingStoreService.getStoredConversations()
      .filter(c => this.currentFilterMethod(c))
      .filter(c => this.filterBySearchString(c));

    if (this.currentFilter === MessageListFilterEnum.UNREAD) {
      await this.unreadSortFunction(output);
    }

    return output;
  }

  private filterBySearchString(p: ConversationModel): boolean {
    return ((p.title ? p.title : this.getConversationTitle(p)) + this.getConversationStringOfParticipants(p)).toLowerCase().includes(this.searchValue.toLowerCase())
  }



  increaseNumberOfConversationsToRender() {
    this.currentAmountOfConversationsToRender += 10;
  }


  async triggerLoadNewConversations(event) {
    const start = document.getElementById('end-');

    if (this.isInViewport(start)) {

      this.increaseNumberOfConversationsToRender();

    }
  }

  isInViewport(element): boolean {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  notScrolling(): boolean {
    return true;
  }


}
