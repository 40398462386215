<div class="app-background-color" style="padding: 10px">
  <div style="height: 10px"></div>
  <h1 style="text-align: center; margin: 0;">{{getTitle()}}</h1>
  <div style="height: 10px"></div>
  <div *ngFor="let alert of getSessionResponses()" class="section-div" 
    style="text-align: left; padding: 10px; margin-bottom: 20px;">
    <!-- <table> -->
      <ion-toolbar class="transparent-bg" *ngFor="let a of getOrderedResponses(alert)">
          <div slot="start">
            <span style="font-weight: 100;">{{getSurveyTitle(a)}}:</span>
          </div>
          <div slot="start">
            <div style="width: 10px;"></div>
          </div>

          <div slot="start">
            {{getResponse(a)}}

          </div>
      </ion-toolbar>



      <!-- <tr *ngFor="let a of getOrderedResponses(alert)">
        <td style="padding-right: 10px">
          <span style="font-weight: 100;">{{getSurveyTitle(a)}}</span>
        </td>
        <td>
          {{getResponse(a)}}
        </td>
      </tr> -->
    <!-- </table> -->
    <!-- <div *ngFor="let a of alert">
      <h1 style=" font-size: 22px; font-weight: 100; margin: 5px 0 0 0;">{{getResponse(a)}}</h1>
    </div> -->
    <div style="height: 10px;"></div>
    <mat-divider></mat-divider>
    <span style="font-weight: 100; text-align: center; width: 100%; display: block; padding: 10px"
      class="opaque-text">{{this.getTimeStamp(alert[0])}}</span>
  </div>

  <div *ngIf="this.data?.res.length === 0" style="text-align: center; font-weight: 100;">
    No issues! Keep up the great work 😊
  </div>
</div>