
  <ion-toolbar class="opaque-div">
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <ion-datetime 
        class="filter-button"
        style="text-align: center; padding: 0; padding-top: 10px; width: 120px;" 
        [(ngModel)]="startEndTime.startTime"
        placeholder="Start Date" 
        pickerFormat='MMM DD, YYYY' 
        displayFormat='MMM DD, YYYY'>
      </ion-datetime>
    </div>

    <div slot="start">
      <div style="width: 30px; text-align: center;">
        to
      </div>
    </div>

    <!-- <ion-datetime slot="start" style="text-align: right;" [(ngModel)]="endTime" placeholder="End Date"
      pickerFormat='MMM DD, YYYY' displayFormat='MMM DD, YYYY'></ion-datetime> -->


      <div slot="start">
        <ion-datetime 
          class="filter-button" 
          style="text-align: center; padding: 0; padding-top: 10px; width: 120px;" 
          [(ngModel)]="startEndTime.endTime"  
          placeholder="Start Date" 
          pickerFormat='MMM DD, YYYY' 
          displayFormat='MMM DD, YYYY'>
        </ion-datetime>
      </div>

  </ion-toolbar>