<div class="app-background-color">

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <img src="{{data.imageUrl}}" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        
        <button mat-button (click)="delete()" style="width: 100%;">Delete</button>
      </ion-col>

     
    </ion-row>
  </ion-grid>

</div>