import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-any-object-description',
  templateUrl: './any-object-description.component.html',
  styleUrls: ['./any-object-description.component.scss'],
})
export class AnyObjectDescriptionComponent implements OnInit {

  objectToDisplay: any = {};
  title: string = "";

  constructor(public dialogRef: MatDialogRef<AnyObjectDescriptionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
  }

  ngOnInit() {
    this.objectToDisplay = this.data.object
    this.title = this.data.title

  }

  getColumnOneList(): string[] {
    return Object.keys(this.objectToDisplay)
  }

  getColumnTwoValueByKey(key: string): string {
    return this.objectToDisplay[key]
  }

}
