import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { DEFAULT_TICKET, TicketModel, TicketsService } from '../tickets.service';

@Component({
  selector: 'app-ticket-form-modal',
  templateUrl: './ticket-form-modal.component.html',
  styleUrls: ['./ticket-form-modal.component.scss'],
})
export class TicketFormModalComponent implements OnInit {

  @Input() ticket: TicketModel = { ...DEFAULT_TICKET };
  isNewTicket: boolean = true;

  constructor(
    public ticketsService: TicketsService,
    public dialogRef: MatDialogRef<TicketFormModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {ticket: TicketModel},
  ) {
    if (data?.ticket) {
      this.ticket = data.ticket;
      this.isNewTicket = false;

      if (this.ticket.ticketStatus === null) {
        this.ticket.ticketStatus = "Submitted"
      }
    } 
 
   }


  ngOnInit() {}

  getFields(_ticket: TicketModel = this.ticket): string[] {
    return Object.keys(_ticket).filter(key => !this.fieldHasCustomUiInput(key));
  }

  fieldHasCustomUiInput(key: string): boolean {
    const customKeys: string[] = [
      "ticketId",
      "assignedRep",
      "accountStatus",
    ]
    return customKeys.includes(key);
  }

  getFieldTitle(camelCaseWord: string): string {
    return camelCaseToSentenceCase(camelCaseWord);
  }

  async saveTicketChanges() {
    this.dialogRef.close({ticket: this.ticket});
  }


  close() {
    this.dialogRef.close();
  }
}
