<button *ngIf="this.title !== null || this.description !== null" mat-button (click)="openLink()"
  style="height: 150px; width: 275px; overflow: hidden;" class="opaque-div">



  <ion-grid style="padding: 0;">
    <ion-row *ngIf="title === null">
      <div style="width: 100%;">

      <span class="material-icons" style="text-align: center;">
        language
        </span>
      </div>

      <ion-label text-wrap style="text-align: left; font-size:14px; font-weight: 100; word-break: break-all; white-space: normal;">
        {{this.messageContent}}
      </ion-label>
    </ion-row>
    <div style="border-radius: 5px; padding: 0px;" *ngIf="title !== null">
      <img style="object-fit: contain; width: 60%; height: 75px; display: block; margin: auto; border-radius: 5px;" src="{{this.image}}" />


      <ion-row>
        <ion-label text-wrap style="text-align: left; font-size: 18px; word-break: break-all; white-space: normal">
          {{ (title.length>26)? (title | slice:0:26)+'...':(title) }}
        </ion-label>

      </ion-row>
      <ion-row>
        <ion-label text-wrap style="text-align: left; font-weight: 100; word-break: break-all; white-space: normal">
          {{ (url.length>26)? (url | slice:0:26)+'...':(url) }}
        </ion-label>
      </ion-row>


    </div>

  </ion-grid>
</button>



<button *ngIf="this.title === null && this.description === null" mat-button (click)="openLink()" class="opaque-div"
  style="height: 150px;">
  loading...
</button>