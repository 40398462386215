<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">



    <div class="mat-elevation-z0">


      <div style="padding-left: 0px; padding-right: 0px;">
        <ion-grid style="padding: 0;">

          <div *ngIf="isDashboard">
            <ion-grid>
              <ion-row>
                <ion-col size="4" style="text-align: center;">
                  <div class="circleDiv">
                    <!-- <h1 style="line-height: 60px; font-size: 40px"> 🥈 </h1> -->
                    <img src="/assets/second.png" />

                  </div>
                  <!-- {{(selectedReport.freshReport.reportName.length>35)? (selectedReport.freshReport.reportName | slice:0:35)+'...':(selectedReport.freshReport.reportName)}} -->
                  <p *ngIf="this.listOfEntries[1]" class="truncated"  style="margin:0; padding: 0; font-size: 14px; padding-top: 10px; padding-bottom: 5px; font-weight: 100;"> 
                    {{this.listOfEntries[1].x}}
                  </p>
                  <p *ngIf="this.listOfEntries[1]" class="special-app-text-color" style="margin:0; padding: 0; font-size: 16px;margin-top: -20px;"> <span *ngIf="this.listOfEntries[1].y[0] ==='$'"> $</span>{{this.listOfEntries[1].y | customCurrency : 2 }} <span *ngIf="this.listOfEntries[1].y[this.listOfEntries[1].y.length-1] ==='%'">% </span></p>

                </ion-col>
                <ion-col size="4" style="text-align: center;">
                  <div class="circleDivBig">
                    <!-- <h1 style="line-height: 80px; font-size: 60px"> 🥇 </h1> -->
                    <img src="/assets/first.png" />

                  </div>
                  <p *ngIf="this.listOfEntries[0]" class="truncated" style="margin:0; padding: 0; font-size: 14px;padding-top: 10px; padding-bottom: 5px; font-weight: 100;"> 
                    {{this.listOfEntries[0].x}}
                  </p>
                  <p *ngIf="this.listOfEntries[0]" class="special-app-text-color" style="margin:0; padding: 0; font-size: 16px; margin-top: -20px;"> <span *ngIf="this.listOfEntries[0].y[0] ==='$'"> $</span>{{this.listOfEntries[0].y | customCurrency : 2}}<span *ngIf="this.listOfEntries[0].y[this.listOfEntries[0].y.length-1] ==='%'">% </span></p>

                </ion-col>
                <ion-col size="4" style="text-align: center;">
                  <div class="circleDiv">
                    <!-- <h1 style="line-height: 60px; font-size: 40px"> 🥉 </h1> -->
                    <img src="/assets/third.png" />

                  </div>
                  <p *ngIf="this.listOfEntries[2]" class="truncated" style="margin:0; padding: 0; font-size: 14px;padding-top: 10px; padding-bottom: 5px; font-weight: 100;">
                    {{this.listOfEntries[2].x}}
                    </p>
                  <p *ngIf="this.listOfEntries[2]" class="special-app-text-color"  style="margin:0; padding: 0; font-size: 16px;  margin-top: -20px;">  
                    <span *ngIf="this.listOfEntries[2].y[0] ==='$'"> $</span>{{this.listOfEntries[2].y | customCurrency : 2}}<span *ngIf="this.listOfEntries[2].y[this.listOfEntries[2].y.length-1] ==='%'">% </span>
                  </p>

                </ion-col>
              </ion-row>
            </ion-grid>

          </div>






          <div *ngIf="!isDashboard">


              <div style="padding: 0px;">          
                  <div *ngIf="report">
                    
                    <div *ngFor='let row of report.reportCellList.list; let i = index;'>
                      <div class="slot" style="width: 100%; padding: 0; margin: 0; height: 50px;">
                        <div id="item-{{i}}">


                          <ion-toolbar style="padding: 10px;" class="transparent-bg">
                            <div slot="start" style="width: 40px;">
                              #{{row.rank}}
                            </div>
                            <div slot="start" style="width: 5px"></div>
                            <div slot="start">
                              <div style="margin-bottom: -10px">
                                <app-user-icon [imageUrl]="getImageUrlByRow(row)" [employee]=getEmployeeByRow(row)
                                  [initials]=getInitialsForNonEmployeeRow(row)></app-user-icon>
                              </div>
          
                            </div>
          
                            <div slot="start">
                              <span class="truncated" style="padding-left: 10px;  margin-left: -5px;">
                                {{getCellValue(row)}}
                              </span>
                            </div>
          
                            <div *ngFor='let dataPoint of getCellRightColumn(row)' slot="end">
                              <div slot="start" style="width: 30px;"></div>
                              {{dataPoint}}
                            </div>

                            <div slot="end" style="width: 10px"></div>
                            <div slot="end" *ngIf="row.trend !== null">
                              <div
                                style="height: 25px; width: 60px; padding-top: 5px; border-radius: 5px; margin-top: -4px; color: white; text-align: center;"
                                [ngStyle]="row.trend < 0 ? {'background' : 'rgba(244, 67, 53, 1)'} : {'background' : '#35CE8D'}">
                                <span *ngIf="row.trend < 0">
                                  <span class="material-icons iconAlign" style="  font-size: 16px; padding-bottom: 2px;">
                                    south
                                  </span>
                                  {{row.trend | absoluteValue}}
                                </span>
          
          
                                <span *ngIf="row.trend > 0" style="color: white">
                                  <span class="material-icons iconAlign" style="  font-size: 16px; padding-bottom: 2px;">
                                    north
                                  </span>
                                  {{row.trend | absoluteValue}}
                                </span>
          
          
                                <span *ngIf="row.trend === 0">
                                  -
                                </span>
          
                              </div>
                            </div>
                          </ion-toolbar>


                        </div>
                      </div>
          
                    </div>
                  </div>
          
                 
              </div>
  
          </div>

        </ion-grid>


      </div>


    </div>
