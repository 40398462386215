

export interface LeadModel {
  leadId: number,
  setterId: number,
  setterEmail: string,
  setterName: string,
  sourceLeadId: string,
  sourceMarket: string,
  customerUtilityBillUrls: string[],
  notes: Note[],
  tasks: Task[],
  appointments: Appointment[],
  appointmentDate: string,
  appointmentTime: string,
  preQualification: any,
  customerFirstName: string,
  customerLastName: string,
  fullAddress: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  phoneNumber: string,
  email: string,
  leadStatus: string,
  projectStatus: string,
  assignedRep: number,
  sitDate: string,
  isCompleted: boolean,
  creationDate: string,
}

export const DEFAULT_LEAD: LeadModel = {
  leadId: 0,
  setterId: null,
  setterEmail: null,
  setterName: null,
  sourceLeadId: null,
  sourceMarket: null,
  customerUtilityBillUrls: [],
  notes: [],
  tasks: [],
  appointments: [],
  appointmentDate: null,
  appointmentTime: null,
  preQualification: null,
  customerFirstName: null,
  customerLastName: null,
  fullAddress: null,
  streetAddress: null,
  city: null,
  state: null,
  zip: null,
  phoneNumber: null,
  email: null,
  leadStatus: null,
  projectStatus: null,
  assignedRep: null,
  sitDate: null,
  isCompleted: null,
  creationDate: null,
}

/*

  private String sourceSetterEmail;
        private String sourceLeadId;
        private List<String> customerUtilityBillUrls;
        private List<String> notes;
        private String appointmentDateTimeUTC;
        private String preQualification;
        private String customerFirstName;
        private String customerLastName;
        private String fullAddress;
        private String streetAddress;
        private String city;
        private String state;
        private String zip; 
        private String phoneNumber;
        private String email;
        private List<Object> customFields;
*/


// //This is used only for external lead submission
export interface LeadInput {
  sourceSetterEmail: string,
  sourceSetterFirstName: string,
  sourceSetterLastName: string,
  sourceLeadId: string,
  sourceMarket: string,
  customerUtilityBillUrls: string[],
  notes: string[],
  appointmentDateTimeUTC: string,
  appointmentTitle: string,
  preQualification: string,
  customerFirstName: string,
  customerLastName: string,
  fullAddress: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string,
  phoneNumber: string,
  email: string,
  customFields?: string
}



export const DEFAULT_LEAD_INPUT: LeadInput = {
  sourceSetterEmail: null,
  sourceSetterFirstName: null,
  sourceSetterLastName: null,
  sourceLeadId: null,
  sourceMarket: null,
  customerUtilityBillUrls: [],
  notes: [],
  appointmentDateTimeUTC: null,
  appointmentTitle: null,
  preQualification: null,
  customerFirstName: null,
  customerLastName: null,
  fullAddress: null,
  streetAddress: null,
  city: null,
  state: null,
  zip: null,
  phoneNumber: null,
  email: null,
}




export interface Note {
  note: string;
  authorId: number;
  creationDate: string;
}

export const DEFAULT_NOTE: Note = {
  note: null,
  authorId: null,
  creationDate: null,
}


export interface Task {
  description: string,
  authorId: number,
  creationDate: string,
  dueDate: string,
  status: string,
  readonly completionDate: string,
  isCompleted: boolean,
}

export const DEFAULT_TASK: Task = {
  description: null,
  authorId: null,
  creationDate: null,
  dueDate: null,
  status: null,
  completionDate: null,
  isCompleted: false
}
export interface Appointment {
  title: string,
  location: string,
  startDateTime: string,
  endDateTime: string,
  hostId: number,
  description: string,
  creationDate: string,
}

export const DEFAULT_APPOINTMENT: Appointment = {
  title: "Appointment",
  location: null,
  startDateTime: null,
  endDateTime: null,
  hostId: null,
  description: null,
  creationDate: null,
}

export interface LeadOptionsConfigInterface {
  leadStatus: string[],
  sourceMarket: string[],

  restrictGoogleMapsApi: boolean,
  userHasLeadAdminRights: boolean, // This means they are a company admin over leads
  userIsTeamLeadManager: boolean, // Should this be more precise to say which teams they manage?
  // teamsManagedByUser: string[];

  shouldShowLawOfAverages: boolean,
  userCanAssignRepsForTheirSets: boolean,

}

export const DEFUALT_LEAD_OPTIONS_CONFIG: LeadOptionsConfigInterface = {
  leadStatus: [],
  sourceMarket: [],
  restrictGoogleMapsApi: false,
  userHasLeadAdminRights: false,
  userIsTeamLeadManager: false,
  // teamsManagedByUser: [],
  shouldShowLawOfAverages: false,
  userCanAssignRepsForTheirSets: false,
}

export function getDefaultNewLead(): LeadModel {
  return {
    leadId: null,
    setterId: null,
    setterEmail: null,
    setterName: null,
    sourceLeadId: null,
    sourceMarket: null,
    customerUtilityBillUrls: [],
    notes: [],
    tasks: [],
    appointments: [],
    appointmentDate: null,
    appointmentTime: null,
    preQualification: null,
    customerFirstName: null,
    customerLastName: null,
    fullAddress: null,
    streetAddress: null,
    city: null,
    state: null,
    zip: null,
    phoneNumber: null,
    email: null,
    leadStatus: null,
    projectStatus: null,
    assignedRep: null,
    sitDate: null,
    isCompleted: false,
    creationDate: null,
  };
}