<ion-grid>
  <ion-row>
    <form style="width: 100%; padding: 10px;">
      <mat-form-field style="width: 100%;">
        <mat-label>Keyword</mat-label>
        <input matInput [(ngModel)]="keyword" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </form>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <button mat-button style="width:100%"> Search </button>
    </ion-col>
  </ion-row>
</ion-grid>
