import { LinkPreview } from '@angular-material-extensions/link-preview';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Link} from 'ngx-linkifyjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinkPreviewService {
  private _accessKey = 'b55cd8cea7c65471dcd055c2e103bb1e';
  private _apiURL = 'https://api.linkpreview.net/';

  onLinkFound: EventEmitter<Array<Link>> = new EventEmitter<Array<Link>>();

  links: Link[] = [];

  constructor(private http: HttpClient) {
    this.onLinkFound.subscribe((links: Array<Link>) => this.links = links);
  }

  fetchLink(url: string): Observable<LinkPreview> {
    const params = new HttpParams()
      .append('key', this._accessKey)
      .append('q', url);

    return this.http.get(this._apiURL, {params: params}).pipe(map(value => value as LinkPreview));
  }
}
