import { Component, OnInit } from '@angular/core';
import { adamLog } from 'src/app/shared/generic-functions';
import { Employee } from 'src/app/shared/models/employees.model';
import { AdminToolService, DeviceDetails, EmailVerificationResponse } from 'src/app/shared/services/admin-tool.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-admin-tools',
  templateUrl: './admin-tools.component.html',
  styleUrls: ['./admin-tools.component.scss'],
})
export class AdminToolsComponent implements OnInit {

  email: string = "";
  emailVerificationResponse: EmailVerificationResponse;

  employee: Employee;
  deviceDetails: DeviceDetails[];
  employeeSessionKey: string;
  userExtraDetails: {
    team: string,
    region: string,
  };

  constructor(
    public adminToolsService: AdminToolService,
    public snackbarService: SnackbarService,
    public employeeStoreService: EmployeeStoreService,

    ) { }

  ngOnInit() { }

  async lookupCode() {
    let res: EmailVerificationResponse = await this.adminToolsService.getEmailVerificationCode(this.email);
    this.emailVerificationResponse = res;
  }


  async selectUser() {
    const userId: number = await this.employeeStoreService.selectSingleUserModal();
    adamLog(userId);
    this.employee = this.employeeStoreService.getUser(userId);
    const res = (await this.adminToolsService.getUserDeviceDetails(userId));
    this.employeeSessionKey = res.sessionKey;
    this.deviceDetails = res.deviceDetails;
    this.userExtraDetails = res.otherDetails;
  }


  getEmployeeDescription(): string {
    return JSON.stringify(this.employee);
  }

  getUserFieldsToDisplay(): string[] {
    if (!this.employee) return [];
    return [
      "phoneNumber",
      "emailAddress",
      "id",

    ]
  }

  getUserFieldValue(field: string): string {
    if(!this.employee?.userDto) return null;
    return this.employee?.userDto[field];
  }
  
  getUserExtraFieldsToDisplay(): string[] {
    if (!this.userExtraDetails) return [];
    return Object.keys(this.userExtraDetails);
  }
  
  getUserExtraFieldValue(field: string): string {
    if (!this.userExtraDetails) return null;
    return this.userExtraDetails[field];
  }

  getListOfToolsThatNeedToBeBuilt(): string[] {
    return [
      "Lookup user device info/sessionkey/app version",
      "Update region image",
      "List of test users, logins, and their purpose",

    ]
  }

  fileUrls: string[] = [];
  nameOfTeamForImageUpdate: string;

  async saveTeamImage() {
    if(!(this.fileUrls?.length >= 1)) {
      this.snackbarService.displaySnackBarMessage("Select a file");
      return;
    }
    try {
      const res = await this.adminToolsService.updateTeamImage(this.nameOfTeamForImageUpdate, this.fileUrls[0]);
      if (!res) this.snackbarService.displaySnackBarMessage("Upload failed");
      if (res) {
        
        this.snackbarService.displaySnackBarMessage("Image updated successfully" );
        this.clearTeamUploadVariables();
      }
    } catch(e) {
      this.snackbarService.displaySnackBarMessage("Upload failed");
    }
  }

  private clearTeamUploadVariables() {
    this.fileUrls = [];
    this.nameOfTeamForImageUpdate = null;
  }

  /**
   * Phone number
   * Email
   * User Id
   * 
   * Sessionkey
   * App version
   * Device Details
   * Device Type
   * 
   * 
   * Is app admin
   * Is company admin
   * 
   * 
   */

}

