<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<div >


  

  <div style="padding: 50px; text-align: center;">

    <div style="height: 100px"></div>
    <h1 style="color: white;">Welcome To Enzy </h1>
    <div style="height: 50px"></div>
      <!-- <img  *ngIf="!this.data.userData.imageUrl" src="{{this.data.userData.imageUrl}}" style="height: 70px; width: 70px; border-radius: 50%; " /> -->
      <img   src="../../../../assets/headshots/default_avatar.png" style="height: 70px; width: 70px; border-radius: 50%; " />

      <div style="height: 30px"></div>

      <div style="display: block; margin: auto;">
        <form (ngSubmit)="onSubmit()">
          <div>
            <button style="display: block; margin: auto; background: #00BCE1; color: white" type="button" mat-button (click)="openUpdateProfilePhoto()">Add Profile Picture</button>
            <!-- <input hidden (change)="onFileSelected()" #fileInput type="file" id="file">  -->
            <!-- <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="image" > -->
          </div>
          <div>
            <!-- <button *ngIf="selectedFile !== null" mat-button style="width: 100%" type="submit" name="upload">Save</button> -->
          </div>
        </form>
      </div>
      
      <div style="height: 10px"></div>
    <button mat-button style="width: 100%; color: rgba(255, 255, 255, 0.5)" (click)="dismiss()"> Not now </button>

  </div>
        


</div>
