import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MatDialog, MatTableDataSource, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { Project } from 'src/app/shared/services/reports.service';

@Component({
  selector: 'app-list-of-projects',
  templateUrl: './list-of-projects.component.html',
  styleUrls: ['./list-of-projects.component.scss'],
})
export class ListOfProjectsComponent implements OnInit {

  projects: Project[];
  dataSource: MatTableDataSource<Project>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {data: Project[], searchFilter: string},
    private _bottomSheetRef: MatBottomSheetRef<ListOfProjectsComponent>,
    public zone: NgZone,
    private dialog: MatDialog
    ) { 
    this.searchValue = data.searchFilter;
    this.projects = data.data;
    this.dataSource = new MatTableDataSource(this.projects);

  }


  applyFilter(event: Event) {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.applySearch(filterValue);
  }

  applySearch(filterValue: string) {
    this.dataSource.filter = filterValue?.trim()?.toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  closeModal() {
    this.dialog.closeAll();
  }

  ngOnInit() {
    this.initializeSearch();
  }


  initializeSearch() {
    // this.setSearchOn();
    this.applySearch(this.searchValue)
    this.setSearchOn();

  }

  getProjectName(project: Project): string {
    return project["Customer Name"];
  }

  getColumnsToShow(): string[] {
    return [
      "Customer Name",
      "Sales Rep Name",
      "Stage",
      "Sales Rep Team",
      "Sales Rep Region",
    ]
  }


  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    // this.searchSend();
    this.applySearch("");
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  currentOptions: any[] = [];
  allOptions: any[] = [];

  currentInstallCount: number = 0;
  progressBarValue: number = 0;
  campaignId: string = '2';

  searchSend() {
    if (this.searchValue?.length === 0) {
      this.currentOptions = this.allOptions;
      return;
    };
    this.currentOptions = this.allOptions.filter(r => (r.helpDescription + " " + r.helpEmailAddress).toLowerCase().includes(this.searchValue.toLowerCase()));

  }

}
