<div class="app-background-color" style="padding: 20px"> 
  <h1 style="padding:0; margin: 0; font-size: 20px">Make sure you have fully completed this form.</h1> 
  <div style="height: 5px;"></div>
  <p style="margin: 0;">Including the following elements:</p>
  <div style="height: 5px;"></div>
  <ul>
    <li>Name and/or Business Name</li>
    <li>Address</li>
    <li>Tax ID Number</li>
    <li>Signature</li>

  </ul>


  <ion-grid>
    <ion-row>
      <ion-col size="6" >
        <button (click)="close()" style="width: 100%; border-radius: 25px;" mat-raised-button>Stay</button>

      </ion-col>

      <ion-col size="6" >
        <button (click)="continue()" style="width: 100%;  border-radius: 25px; background-color:#00BCE1; color: white;   display: block; margin: auto;"  mat-raised-button>Continue</button>

      </ion-col>

    </ion-row>
  </ion-grid>

</div>