import { Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/shared/models/employees.model';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TicketModel } from '../tickets.service';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent implements OnInit {

  @Input() ticket: TicketModel;

  constructor(
    public employeeStoreService: EmployeeStoreService,
    public dateFormatService: DateFormatService,
    public userDetailsService: UserDetailsService,
  ) {}


  ngOnInit() {
    console.log(this.ticket)
   }

  getTicketDebugDescription(): string {
    return JSON.stringify(this.ticket);
  }

  getAssignedEmployee(): Employee {
    if (!this.ticket?.assignedUser) return null;
    const output: Employee = this.employeeStoreService.getUser(this.ticket?.assignedUser);
    // console.log(output);
    return output;
  }

  getAssignedEmployeeName() {
    let employee = this.getAssignedEmployee();
    let firstName = employee?.userDto.firstName ? employee?.userDto.firstName : "";
    let lastName = employee?.userDto.lastName ? employee?.userDto.lastName : "";

    return firstName + " " + lastName;

  }

  ticketIsAssignedToMe(): boolean {
    return false;
  }

  canEditTicket(): boolean {
    return this.ticketIsAssignedToMe();
  }

}
