import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { Incentive } from 'src/app/shared/models/incentives.model';
import { IncentiveDataPoint } from 'src/app/shared/services/incentives.service';

@Component({
  selector: 'app-milestone-detail',
  templateUrl: './milestone-detail.component.html',
  styleUrls: ['./milestone-detail.component.scss'],
})
export class MilestoneDetailComponent implements OnInit {

  installs: any[];

  @Input() incentive: Incentive; 

  constructor(public router: Router, private nav: HeaderService) {

    this.installs = history.state.installs;
    this.incentive = history.state.incentive;

   }

  ngOnInit() {}


  goBack() {
    this.router.navigate(["milestones"]);
  }

  ionViewWillLeave(){
    
    ;
  }

  ionViewWillEnter() {
    ;
    
  }

}
