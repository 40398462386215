import { Injectable } from "@angular/core";
import { GetResult, Storage as capStorage } from '@capacitor/storage';
import { dateIsInRange, getCurrentMysqlDateTimeString, getDateStringForToday } from "src/app/shared/services/dates.service";

@Injectable({
    providedIn: "root",
})
export class Tallys {

    private dailyTallyMap: DailyTallyMap = {};
    private todaysKey: string = getDateStringForToday();

    constructor() {
        this.initTallys();
    }

    private async initTallys() {
        this.dailyTallyMap = await this.loadTallysFromStorage();
        this.includeDefaultKeys();
    }
    
    private async loadTallysFromStorage(): Promise<DailyTallyMap> {
        let getResult: GetResult = (await capStorage.get({ key: TALLEYS_KEY }));
        if (getResult?.value) return JSON.parse(getResult.value);
        return {};
    }

    private async cacheTallys(): Promise<void> {
        return await capStorage.set({
            key: TALLEYS_KEY,
            value: JSON.stringify(this.dailyTallyMap),
        });
    }

    private includeDefaultKeys(): void {
        DEFAULT_PROSPECT_CONFIG.tallyKeys.forEach(key => {
            if (!this.getValueForKeyInTodaysTally(key)) {
                this.setKeyForTodaysTally(key, []);
            }
        })
    }

    private getTodaysTallys(): SingleDayProspectTallyMap {
        if (!this.dailyTallyMap[this.todaysKey]) 
            this.dailyTallyMap[this.todaysKey] = {};
        return this.dailyTallyMap[this.todaysKey];
    }

    async setKeyForTodaysTally(key: string, value: TallyDataPoint[]): Promise<void> {
        this.getTodaysTallys()[key] = value;
        return await this.cacheTallys();
    }


    public getKeysForTodaysTally(): string[] {
        if (!this.getTodaysTallys()) return []
        return Object.keys(this.getTodaysTallys());
    }

    public getValueForKeyInTodaysTally(key: string): TallyDataPoint[] {
        return this.getTodaysTallys()[key];
    }

    public getTallyStatsForDates(startDate: Date, endDate: Date): TallyStatsMap {
        const keysBetweenDates: string[] = Object.keys(this.dailyTallyMap).filter(key => {
            return dateIsInRange(new Date(key), startDate, endDate);
        })
        const output: DailyTallyMap = {};
        for (let key of keysBetweenDates) {
            output[key] = this.dailyTallyMap[key];
        }
        return cumulativeStatsFor(output);
    }

    
}

function cumulativeStatsFor(map: DailyTallyMap): TallyStatsMap {
    const output: TallyStatsMap = {};
    for (let dayKey of Object.keys(map)) {
        const dayMap: SingleDayProspectTallyMap = map[dayKey];
        for (let innerKey of Object.keys(dayMap)) {
            const runningTotal: number = output[innerKey] || 0;
            output[innerKey] = runningTotal + dayMap[innerKey]?.length;
        }
    }
    return output;
}

export interface TallyDataPoint {
    key: string;
    timestamp: string;
    location?: string;
}

export function newTallyDataPoint(key: string): TallyDataPoint {
    return {
        key,
        timestamp: getCurrentMysqlDateTimeString()
    }
}

export interface DailyTallyMap {
    [key: string]: SingleDayProspectTallyMap;
}

export interface SingleDayProspectTallyMap {
    [key: string]: TallyDataPoint[];
}

export interface TallyStatsMap {
    [key: string]: number;
}
const TALLEYS_KEY: string = "TALLEYS";

export interface ProspectConfig {
    tallyKeys: string[],
}


export const DEFAULT_PROSPECT_CONFIG: ProspectConfig =  {
    tallyKeys: [
        "Doors Knocked", 
        "Homeowners talked to",
        "Appointments Set",
        "Sits",
],
}

/**
 * Max Ganley Law of Averages
 * 50 contacts a week/ 10 a day 
 * 10homeowners= 1 appointment 
 * 5 appointments a week = 3 sits
 * 3 sits a week = 1 close
 * 4 closes a month= 3 installs 
 */