import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotification, PushNotificationActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { Observable, Subject } from 'rxjs';
import { REST_BASE_URL } from '../models/constants';
import { Employee } from '../models/employees.model';
import { UserDetailsService } from './user-details.service';
import { v4 as uuidv4 } from 'uuid';
import { Poll } from '../models/poll.model';
import { Media, PhotoResponse } from '@capacitor-community/media';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { LeadModel } from 'src/app/leads/leads.model';
import { DashboardAlertModel } from 'src/app/cr-dashboard/cr-dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public pushMessages$: Subject<PushNotification>;
  
  public pushNotificationActions$: Subject<ActionPerformed>;

  public triggerConvoReload$: Subject<string>;

  public selectedMessageCell = "";

  public convoIdFromNotification: number;

  constructor(private http: HttpClient,
    public platform: Platform,
    public userDetailsService: UserDetailsService) { 
    this.pushMessages$ = new Subject<PushNotification>();
    
    this.triggerConvoReload$ = new Subject<string>();
    this.pushNotificationActions$ = new Subject<PushNotificationActionPerformed>();
  }

  public getConversations(sessionKey: string, otherUserId: string = null): Observable<ConversationsResponse> {
    const url = REST_BASE_URL + '/messages/conversations/' + sessionKey;

    let params: any = {};
    if (otherUserId) {
      params['otherUserId'] = otherUserId
    };
    params['apiVersion'] = 2;


    return this.http.get<ConversationsResponse>(url, {
      params
    });

  }

  public async getMessages(channel: number, offset: number = 0, limit: number = 25, imagesOnly: boolean = false): Promise<MessagesResponse> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let getMessagesFullUrl = REST_BASE_URL + '/messages/messages/' + sessionKey;

    const params: any = {
      channel: '' + channel,
      imagesOnly: imagesOnly + ''
    }
    if (offset !== null && offset >= 0) {
      params['offset'] = offset + '';
    }
    if (limit !== null && limit >= 0) {
      params['limit'] = limit + '';
    }
    

    return this.http.get<MessagesResponse>(getMessagesFullUrl, {
      params: params}).toPromise();

  }

  public getMessageMap(sessionKey: string, channels: number[]): Observable<any> {
    let getMessagesFullUrl = REST_BASE_URL + '/messages/messageMap/' + sessionKey;


    return this.http.get<MessagesResponse>(getMessagesFullUrl, {
      params: {
        channels: '' + channels
      }
    });

  }

  public sendMessage(sessionKey: string, convoId: number, contents: string, messageType: MessageTypeEnum, gifUrl: string, frontendId: string, messageReportLine: ReportLine = null): Promise<any>  {
    const url = REST_BASE_URL + '/messages/sendMessage/' + sessionKey;

    let body = new FormData();
    body.append('messageContents', contents);
    body.append('conversationId', '' + convoId);
    body.append('frontendId', frontendId);
    body.append('messageType', MessageTypeEnum[messageType]);
    if (gifUrl) { 
      body.append('gifUrl', gifUrl)
    }
    if (messageReportLine) {
      body.append('messageIdToMarkCompleted', messageReportLine.messageId + "");
      body.append('reportLineNameToMarkCompleted', messageReportLine.lineName);
    }

    return this.http.post<any>(url, body).toPromise();

  }

  public async sendPollMessage(convoId: number, contents: string, frontendId: string, poll: Poll): Promise<any>  {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/messages/sendPollMessage/' + sessionKey;

    url += '?messageContents=' + contents;
    url += '&conversationId=' + '' + convoId;
    url += '&frontendId=' + frontendId;

    let body = JSON.stringify(poll);
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    return this.http.post<any>(url, body, {headers: headers}).toPromise();

  }

  public sendImageMessage(sessionKey: string, convoId: string, selectedImage: any, contents: string, frontendId: string, imageWidth: number, imageHeight: number, messageReportLine: ReportLine = null): Promise<any> {
    let nextUrl = REST_BASE_URL + '/messages/sendImageMessage/' + sessionKey;

    const body = new FormData();
    body.append('imageFile', selectedImage, selectedImage.name);
    body.append('conversationId', '' + convoId);
    body.append("messageContents", contents);
    body.append("frontendId", frontendId);
    if (imageHeight) {
      body.append("imageHeight", imageHeight + '');
    }
    if (imageWidth) {
      body.append("imageWidth", imageWidth + '');
    }
    if (messageReportLine) {
      body.append('messageIdToMarkCompleted', messageReportLine.messageId + "");
      body.append('reportLineNameToMarkCompleted', messageReportLine.lineName);
    }


    return this.http.post<any>(nextUrl, body).toPromise();
  }

  public getOrCreateConversationByUserList(sessionKey: string, userIds: string[]): Observable<ConversationIdResponse> {
    const url = REST_BASE_URL + '/messages/conversationIdByUsers/' + sessionKey;

    return this.http.get<ConversationIdResponse>(url, {
      params: {
        userIds: userIds
      }
    });
  }

  public deleteMessage(sessionKey: string, messageId: string): Observable<any> {
    const url = REST_BASE_URL + '/messages/deleteMessage/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId);

    return this.http.post<any>(url, body);
  }

  public async reportMessage(messageId: string, reason: string = ""): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();

    const url = REST_BASE_URL + '/messages/reportMessage/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId);
    body.append('reason', reason);

    return this.http.post<any>(url, body).toPromise();
  }

  public editMessage(sessionKey: string, messageId: string, newContents: string): Observable<any> {
    const url = REST_BASE_URL + '/messages/editMessage/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId);
    body.append('newContents', newContents);


    return this.http.post<any>(url, body);
  }


  public async markConvoRead(convoId: number): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/messages/markConversationRead/' + sessionKey;

    let body = new FormData();
    body.append('conversationId', '' + convoId);

    return this.http.post<any>(url, body).toPromise();
  }


  public renameConversation(sessionKey: string, conversationId: number, newConversationTitle: string): Promise<any>  {
    const url = REST_BASE_URL + '/messages/renameConversation/' + sessionKey;

    let body = new FormData();
    body.append('conversationId', conversationId + '');
    body.append('newConversationTitle', newConversationTitle);

    return this.http.post<any>(url, body).toPromise();

  }

  public async deleteConversation(conversationId: number): Promise<any> {
    if(!conversationId) return;
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/conversations/deleteConversation/' + sessionKey;

    let body = new FormData();
    body.append('convoId', conversationId + '');

    return this.http.post<any>(url, body).toPromise();

  }

  public addReaction(sessionKey: string, messageId: number, newReaction: string): Observable<any>  {
    const url = REST_BASE_URL + '/reactions/addReaction/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId + '');
    body.append('reaction', newReaction);

    return this.http.post<any>(url, body);

  }

  public removeReaction(sessionKey: string, messageId: number, newReaction: string): Observable<any>  {
    const url = REST_BASE_URL + '/reactions/removeReaction/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId + '');
    body.append('reaction', newReaction);

    return this.http.post<any>(url, body);

  }

  public async getFullReactions(messageId: string): Promise<any>  {
    if (!messageId) return;
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const url = REST_BASE_URL + '/reactions/getFullReactions/' + sessionKey;

    let body = new FormData();
    body.append('messageId', messageId);

    return this.http.post<any>(url, body).toPromise();

  }

  // TODO: Fix functions under this

  public sendMessages(sessionKey: string, toUsers: Employee[], contents: string): Promise<any>  {
    const url = REST_BASE_URL + '/messages/sendMessage/' + sessionKey;

    let body = new FormData();
    body.append('messageContents', contents);
    body.append('toUsers', toUsers.map(r => r.userDto.id).toString());

    return this.http.post<any>(url, body).toPromise();

  }

  public async sendBccMessage(toUsers: Employee[], contents: string, bccId: string, imageFile: File = null, gifUrl: string = null, reportLines: ReportLine[]): Promise<any>  {
    const sessionKey: string = await this.userDetailsService.getSessionKey();

    const url = REST_BASE_URL + '/messages/sendBcc/' + sessionKey;

    let body = new FormData();
    body.append('messageContents', contents);
    body.append('bccId', bccId);
    body.append('userIds', toUsers.map(r => r.userDto.id).toString());
    if (imageFile) {
      body.append('imageFile', imageFile, imageFile.name);
    }
    if (gifUrl) {
      body.append('gifUrl', gifUrl);
    }
    
    if (reportLines) {
      body.append("reportLines", reportLines + '');
    }

    if (reportLines) {
      body.append('jsonMarkLines', JSON.stringify(reportLines.map(line=> {return {messageId: line.messageId, reportLineName: line.lineName}})));
    }

    // if (reportLines) {
    //   body.append('messageIdsToMarkCompleted', reportLines.map(messageReportLine => messageReportLine.messageId) + "");
    //   body.append('reportLineNamesToMarkCompleted', reportLines.map(messageReportLine => '"' + messageReportLine.lineName + '"').join(','));
    // }
    return this.http.post<any>(url, body).toPromise();

  }



  public notificationIsMessage(notification: PushNotification): boolean {
    return notification.data['notification_type'] === 'MESSAGE';
  }

  public notificationIsReaction(notification: PushNotification): boolean {
    return notification.data['notification_type'] === 'ADD_REACTION' || notification.data['notification_type'] === 'REMOVE_REACTION';
  }


  public async conversationIsMutedForMe(conversation: ConversationModel): Promise<boolean> {
    const myId: string = await this.userDetailsService.getUserId();
    return this.conversationIsMutedForUser(conversation, myId);    
  }

  public conversationIsMutedForUser(conversation: ConversationModel, id: string): boolean {
    const participant: ParticipantModel = conversation?.participants.find(participant => participant.id == id);
    return participant?.isMuted ? participant?.isMuted : false;
  }

  public async setConversationMutedForMe(conversation: ConversationModel, muted: boolean): Promise<any> {
    const myId: string = await this.userDetailsService.getUserId();
    const participant: ParticipantModel = conversation.participants.find(participant => participant.id == myId);
    participant.isMuted = muted;
  }


  public conversationIsPinnedForMe(conversation: ConversationModel, myId: string): boolean {
    const participant: ParticipantModel = conversation?.participants?.find(participant => participant.id == myId);
    return participant?.isPinned;
  }

  public setConversationPinnedForMe(conversation: ConversationModel, myId: string, pinned: boolean) {
    conversation.participants.find(participant => participant.id == myId).isPinned = pinned;
  }


  devTeamEmployeeObejcts: Employee[] = [
  ]


  // *********** PRACTICING WITH NEW MEDIA STUFF **************
  albums = [];
  lastPhotoSaved = null;

  async takeAndSaveVideo() {
    const image = await Camera.getPhoto({
      source: CameraSource.Camera,
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
    });

    const savePhotoResult = await this.saveVideoByPath(image.path);

    this.lastPhotoSaved = savePhotoResult.filePath;

    console.log('savePhotoResult', savePhotoResult);
  }

  async savePhotoByPath(path: string): Promise<PhotoResponse> {
    return await Media.savePhoto({
      path: path,
      album: {name: 'Enzy Documents'},
    });
  }

  async saveVideoByPath(path: string): Promise<PhotoResponse> {
    return await Media.saveVideo({
      path: path,
      album: {name: 'Enzy Documents'},
    });
  }
  // async getAlbums() {
  //   Media.getAlbums().then((getAlbumsResponse) => {
  //     console.log('getAlbumsResponse', getAlbumsResponse);

  //     this.zone.run(() => (this.albums = getAlbumsResponse.albums));
  //   });
  // }
  
}

export interface ConversationModel {
  conversationId: number;
  participants: ParticipantModel[];
  latestMessage: string;
  latestMessageTime: string;
  latestMessageId: number;
  title: string;
  imageUrl: string;
  unreadMessageCount: number;
  ownerId: number;
  definingTeam: string;
  isAdminSendOnly: boolean;
  thumbnailImageUrl?: string;
}

export interface ParticipantModel {
  id: string;
  firstName?: string;
  preferredFirstName?: string;
  lastName: string;
  imageUrl: string;
  isMuted: boolean;
  isPinned: boolean;
  isAdmin: boolean;
  unreadMessageCount: number;
}

export interface MessageModel {
  messageId: string;
  fromUser: string;
  convoId: string;
  contents: string;
  sendDate: string;
  fromUserName: string;
  fromUserImg: string;
  fromUserImgThumbnail?: string;

  reactions: Reaction[];
  messageImage: string;
  messageType: string;
  frontendId: string;
  poll?: Poll;
  bccId?: string;
  reportId?: string;

  isPending?: boolean;
  selectedFile?: any;
  imageHeight?: number;
  imageWidth?: number;
  messageImageThumbnail?: string;
  messageImageThumbnailMedium?: string;
  reportLines?: ReportLine[];

  lead?: LeadModel;
  leadChanges?: LeadChanges[];
  internalNavUrl?: string;

  dashboardEmojis?:string;

  surveyResponseDescription?: DashboardAlertModel;
}

export interface ReportLine {
  line: string;
  lineName: string;
  lineUserId: number;
  lineValue: any;
  messageId: number;
  lineTime: string;
  reportId: string;
  isCompleted: boolean;
}

export interface MessagesResponse {
  message: string;
  errorMessage: string;
  messages: MessageModel[];
}

export interface ConversationIdResponse {
  message: string;
  errorMessage: string;
  conversationId: number;
}

export interface ConversationsResponse {
  message: string;
  errorMessage: string;
  conversations: ConversationModel[];
}

export interface Reaction {
  userId: string;
  reaction: string; //Thumbs up, thumbs down, etc
}

export interface FullReaction {
  userId: string;
  reaction: string;
  userName: string;
  userImageUrl: string;
}

export enum MessageTypeEnum {
  TEXT,
  IMAGE,
  VIDEO,
  REPLY,
  GIF,
  URL,
  LEAD,
}


export class LeadChanges {
  fieldName: string;
  oldValue: string;
  newValue: string;
  creationDateTime: string;
  changedBy: number;
}