
<ion-row style="padding-left: 10px">
  <div *ngFor='let url of this.fileUrls'>
    <img (click)="expandImage(url)" style="width: 100px; height: 100px; object-fit: cover;" [src]='url'>
    <br>
    <button style="margin-top: -10px;" mat-icon-button (click)="deleteImage(url)">
      <span style="font-size: 16px;" class="material-icons">
        delete
      </span>
    </button>
  </div>


  <button style="margin-left: 5px; height: 100px; width: 100px;" class="opaque-div" mat-button
    (click)='addNewFiles()'>
    <span class="material-icons">
      add_photo_alternate
    </span>
  </button>
</ion-row>