<ion-toolbar class="transparent-bg">
  <div slot="start">
    <p>{{this.section.sectionTitle}}</p>
  </div>

  <div slot="start" *ngIf="shouldShowAdminButtons">
    <button mat-icon-button (click)="edit()">
      <span class="material-icons">
        edit
      </span>
    </button>
  </div>
</ion-toolbar>
<div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;"
  [ngClass]="{'xl-container': isXL(), 'normal-container': !isXL() }">

  <div *ngFor='let s of series'>
    <mat-card [style.background-image]='getImageForSeries(s)' (click)="navigateToSectionDetails(s)"
      [ngClass]="{'xl': isXL(), 'normal': !isXL() }"
      style="background-color: #383838; background-size:cover;  padding: 0; margin: 0; margin-right: 15px;  background-position: center;">
      <ion-grid class="specialText" style="padding: 0px">
        <div style="height: 118px;"></div>
      </ion-grid>
    </mat-card>
    <div style="width: 20px;"></div>
  </div>
</div>