import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { adamLog } from "../shared/generic-functions";
import { REST_BASE_URL } from "../shared/models/constants";
import { UserDetailsService } from "../shared/services/user-details.service";
import { Storage } from '@ionic/storage';
import { Note } from "../leads/leads.model";
import { SnackbarService } from "../shared/services/snackbar.service";

@Injectable({
    providedIn: "root",
  })
export class TicketsService {

    tickets: TicketModel[] = [];
    public ticketOptionsConfig: TicketOptionsConfigInterface = getDefaultTicketOptionsConfig();

    constructor(
        private http: HttpClient,
        private localStorage: Storage,
        public userDetailsService: UserDetailsService,
        public snackbarService: SnackbarService
    ) {
        this.initTickets();
    }

    private async initTickets() {
      // await this.localStorage.create();
      // await this.localStorage.defineDriver(CordovaSQLiteDriver);
      // this.loadTicketConfigFromCache();
      this.tickets = await this.getTickets()
      this.ticketOptionsConfig = await this.getTicketConfiguration();
      // this.saveTicketConfigToCache();
    }

    public async createNewTicket(ticket: TicketModel): Promise<any> {
        adamLog("Create Ticket");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/tickets/' + sessionKey;
    
        let body = JSON.stringify(ticket);
        const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
        
        const res = await this.http.post<any>(url, body, {headers: headers}).toPromise();


        if (res.success) {
          this.snackbarService.displaySnackBarMessage("Ticket Created")
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error")
        }

        this.tickets.push(ticket);    
        return res;
    }


    public async saveTicket(ticket: TicketModel): Promise<any> {
        adamLog("Save Ticket");
        const sessionKey: string = await this.userDetailsService.getSessionKey();
    
        let url: string = REST_BASE_URL + '/tickets/updateTicket/' + sessionKey;

        let body = JSON.stringify(ticket);
        const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
        
        const res = await this.http.post<any>(url, body, {headers: headers}).toPromise();

        if (res.success) {
          this.snackbarService.displaySnackBarMessage("Ticket updated")
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error")
        }


        this.tickets.push(ticket);    
        return res;
    }

    public async getTickets(): Promise<TicketModel[]> {
        adamLog("Get Tickets");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/tickets/' + sessionKey;
        
        const res: {output: TicketModel[]} = await this.http.get<any>(url).toPromise();

        return res.output;
    }

    public async getTicketConfiguration(): Promise<TicketOptionsConfigInterface> {
      adamLog("Get Ticket Config");
      const sessionKey: string = await this.userDetailsService.getSessionKey();

      let url: string = REST_BASE_URL + '/tickets/ticketConfig/' + sessionKey;
      
      const res: {output: TicketOptionsConfigInterface} = await this.http.get<any>(url).toPromise();

      return res.output;
  }

    


    // ************** CACHING CONFIG *****************
    async loadTicketConfigFromCache() {
      // this should not overwrite it if it came from the server
      
      const res: TicketOptionsConfigInterface = await this.localStorage.get("TICKET_CONFIG");
      // it should overwrite it if it's just the defaults
      if (this.ticketOptionsConfig?.ticketStatus?.length === 0 
        && res.ticketStatus?.length > 0
        
        ) {
        // this.ticketOptionsConfig = res;
      }
    }

    saveTicketConfigToCache() {
      setTimeout(() => {
        this.localStorage.set("TICKET_CONFIG", this.ticketOptionsConfig);
      }, 1000);
    }
    

}

export interface TicketModel {
    ticketId: number;
    title: string;
    description: string;
    ticketStatus: string;
    reportingUser: number;
    assignedUser: number;
    creationDate: string;
    messageId: number;
    conversationId: number;
    notes: Note[];
    fileUrls: string[];
  }
  export interface TicketOptionsConfigInterface {
    ticketStatus: string[],
    ticketColumns?: string[],
    userHasTicketAdminRights?: boolean,
  }

  export function getDefaultTicketOptionsConfig(): TicketOptionsConfigInterface {
    return {
      ticketStatus: [],
      ticketColumns: [],
      userHasTicketAdminRights: false,

    }}
  
  export const DEFAULT_TICKET: TicketModel = {
    ticketId: null,
    title: null,
    description: null,
    ticketStatus: "Submitted",
    reportingUser: null,
    assignedUser: 6569, //Lansings userId
    creationDate: null,
    messageId: null,
    conversationId: null,
    notes: [],
    fileUrls: [],
  }

  export interface TicketListFilter {
    reportingUser: number,
    assignedUser: number,
    status: string,
    team: string,
  }