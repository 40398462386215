import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LinkPreviewService } from 'src/app/shared/services/link-preview.service';


@Component({
  selector: 'app-link-preview-card',
  templateUrl: './link-preview-card.component.html',
  styleUrls: ['./link-preview-card.component.scss'],
})
export class LinkPreviewCardComponent implements OnInit {

  @Input()
  messageContent: string;

  title: string = null;
  description: string = null;
  image: string = null;
  url: string = null;

  wasError:boolean = false;
  


  constructor(private linkPreviewService: LinkPreviewService) {


  }

  ngOnInit() {
    if (this.messageContent) {
      this.linkPreviewService.fetchLink(this.messageContent).subscribe(res => {
         
        this.title = res.title;
        this.description = res.description;
        this.image = res.image
        this.url = res.url
      },
      error => {
        this.description = this.messageContent
      })
    }

  }


  openLink() {

    var urlRegex = /(https?:\/\/[^ ]*)/;

    var input = this.messageContent;
    var url = input.match(urlRegex)[1];

    window.open(url, "_blank");

  }


}
