import { Component, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MessageModel, MessagesResponse, MessagingService } from 'src/app/shared/services/messaging.service';
import { ExpandedImageModalComponent } from '../expanded-image-modal/expanded-image-modal.component';

@Component({
  selector: 'app-conversation-image-gallery',
  templateUrl: './conversation-image-gallery.component.html',
  styleUrls: ['./conversation-image-gallery.component.scss'],
})
export class ConversationImageGalleryComponent implements OnInit {


  @Input()
  conversationId: number;


  currentOffset: number = 0;
  numberOfMessages: number = 25;

  messages: MessageModel[];

  constructor(
    public messagingService: MessagingService,
    public zone: NgZone,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.fetchImages();

  }

  fetchMore(){
    this.currentOffset += 25;

    this.fetchImages();
  }

  fetchImages() {
    if (!this.conversationId) return;

    this.messagingService.getMessages(this.conversationId, this.currentOffset, this.numberOfMessages, true).then(
      (res) => {
        // console.log(res)
        this.messages = [];
        res.messages.forEach(msg => { 
          if (msg.messageType === "IMAGE") {
            this.messages.push(msg) 
          }
        });
      });
  }

  getImageUrlForThumbnail(message: MessageModel): string {
    if (message.messageImageThumbnail) {
      return message.messageImageThumbnail;
    }
    else return message.messageImage;
  }


  expandImage(img: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        image: img,
      }
    });
  }

}
