import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { UploadProfileImageService } from '../../services/upload-profile-image.service';
import { Camera, CameraResultType } from '@capacitor/camera';

@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-profile-picture.component.html',
  styleUrls: ['./upload-profile-picture.component.scss'],
})
export class UploadProfilePictureComponent implements OnInit {

  public croppedImageBase64 = '';
  /**
   * Image to be cropped as a base64 string.
   * Should be passed in from the component calling this modal.
   */
  @Input() imageBase64 = '';

  constructor(
    private modalController: ModalController, public uploadProfileImageService: UploadProfileImageService,
    public dialogRef: MatDialogRef<UploadProfilePictureComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.checkPermission();

  }

  checkPermission(){
    Camera.requestPermissions().then(res => {
      console.log(res);
    })
  }

  ngOnInit() {
    //
  }
  @ViewChild(ImageCropperComponent, { static: false }) angularCropper: ImageCropperComponent;

  myImage = null;
  croppedImage = null;


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }

  imageChangedEvent: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.uploadProfileImageService.handleImageSelection(event).subscribe(res => {
      this.myImage = res;
      
    })


  }

  accept() {
    this.dialogRef.close(this.croppedImage);

  }

  cancel() {
    this.dialogRef.close();
  }

  reset(){
    this.myImage = null;
  }



imageLoaded(image: LoadedImage) {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}

}
