<div class="app-background-color" *ngIf="this.data.filters" style="padding: 10px;">
  <div style="margin: 0; padding: 0; text-align: center; font-size: 20px;">Filters</div>
  <div *ngFor="let filterButton of this.filters">
    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <button (click)="processFilterAction(filterButton)" class="filter-button" mat-button> <span
            style="font-weight: 100;">{{filterButton.label}}</span>: {{getSelectedValue(filterButton)}}</button>
      </div>


      <div slot="start" *ngIf="filterButton.type==='date' && filterButton.selectedValue === 'Custom'">
        <enzy-start-end-date-picker [startEndTime]="startEndTime">
        </enzy-start-end-date-picker>
      </div>

      <div slot="start" *ngIf="shouldShowClear(filterButton)">
        <button mat-icon-button (click)="removeFilter(filterButton)">
          <span class="material-icons iconAlign">
            close
          </span>
        </button>
      </div>
    </ion-toolbar>
  </div>


  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <button style="width: 100%;" mat-button (click)="clearAll()">Clear All</button>
      </ion-col>


      <ion-col size="6">
        <button style="width: 100%;" mat-button class="primary-color" (click)="save()">Save</button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>