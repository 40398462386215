<ion-content scrollY="false">


  <div style="padding: 10px"
    style="background-color: black; background-image: url({{incentive.backgroundURL}}); background-size:cover; height: 100vh; background-position: top center; overflow-y: hidden;">

    <ion-toolbar class="no-padding-header transparent-bg"
      style="position: sticky; top: 0; max-width: 1200px; display: block; margin: auto; height: 180px;">
      <div style="height: 110px;"></div>

      <div slot="start">
        <button mat-fab 
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important; color: white"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>

    </ion-toolbar>

    <!-- <div style="height: 220px"></div> -->

    <div class="scroll-list" style="padding: 10px;  ">
      <div style="max-width: 400px; display: block; margin: auto;">

        <div *ngFor="let prize of this.prizes">

          <app-prize (click)="loadShopifyLink(prize)" [currentInstallCount]='this.currentInstallCount' [prize]='prize'>
          </app-prize>

          <div style="height: 20px;"></div>

        </div>


      </div>

      <div style="height: 200px;"></div>

    </div>
  </div>
</ion-content>