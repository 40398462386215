import { M } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ActionPerformed, PushNotification, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Subject, Subscription } from 'rxjs';
import { DashboardAlertModel } from 'src/app/cr-dashboard/cr-dashboard.service';
// import { SurveyResponseDescription } from 'src/app/cr-dashboard/cr-dashboard.service';
import { Employee } from '../models/employees.model';
import { Poll, PollOption, PollResponse } from '../models/poll.model';
import { CustomFileService } from './custom-file-service.service';
import { EmployeeStoreService } from './employee-store.service';
import { FcmService } from './fcm.service';
import { AddPollResponseCommand, EditMessageCommand, MarkAsReadCommand, MessagingSocketService, RemovePollResponseCommand } from './messaging-socket.service';
import { ConversationModel, MessageModel, MessagesResponse, MessagingService, ParticipantModel, ReportLine } from './messaging.service';
import { PollService } from './poll.service';
import { UserDetailsService } from './user-details.service';


// const { Network } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class MessagingStoreService {

  private conversations: ConversationModel[] = [];
  public  totalUnreadMessages: number;

  public recievedNotifications: any;


  // private selectedConversation: ConversationModel;

  private messagesMap: Map<number, MessageModel[]>;
  private textBoxContentsMap: Map<number, string>;

  public editorMsg: string = "";
  public triggerRedraw$: Subject<any>;

  private allTempMessages: MessageModel[];
  private tempFilesMap: Map<string, File>;

  public readonly tempMessages: MessageModel[] = [];
  private socketMessageSubscription: Subscription;
  private notificationMessageSubscription: Subscription;
  private notificationActionMessageSubscription: Subscription;
  private editMessageCommandSubscription: Subscription;
  private markUserUnreadSubscription: Subscription;
  private addPollResponseSubscription: Subscription;
  private removePollResponseSubscription: Subscription;

  public messageMapUpdated$: Subject<any>;
  public appIsActive: boolean = true;
  public inEditMessageMode: boolean = false;

  public notifications: any[] = [];


  public storeMessagesMapHasBeenInitializedEvent: Subject<boolean>;
  public storeMessagesMapHasBeenInitialized: boolean = false;
  constructor(public messagingService: MessagingService,
    public userDetailsService: UserDetailsService,
    public localStorage: Storage,
    public fcmService: FcmService,
    public customFileService: CustomFileService,
    public pollService: PollService,
    public employeeStoreService: EmployeeStoreService,
    public snackbar: MatSnackBar,
    private platform: Platform,
    public messagingSocketService: MessagingSocketService) {
    this.initService();
  }

  async initService() {
    this.messagesMap = new Map<number, MessageModel[]>();
    this.textBoxContentsMap = new Map<number, string>();
    this.tempFilesMap = new Map<string, File>();
    this.allTempMessages = [];
    this.triggerRedraw$ = new Subject();
    this.messageMapUpdated$ = new Subject();
    this.storeMessagesMapHasBeenInitializedEvent = new Subject();
    this.startNewMessageHandler();
    // await this.delay(2000);

    this.startNetworkConnectionHandler();
    // await this.delay(2000);

    await this.localStorage.create();
    await this.localStorage.defineDriver(CordovaSQLiteDriver);
    // await this.delay(2000);


    this.loadCachedMessageMap();

    this.loadCachedTempMessageMap();
    this.loadCachedTextBoxContentsMap();
    await this.loadCachedConversations();
    this.updateTotalUnreadMessages();

    // this.loadMessagesFromEveryOutOfDateConversation();
    this.storeOutstandingNotifications();
    this.loadAllConversations();
 
  }


  //Used to mimic slow, unstable networks
  // delay(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  checkIfMessageMapHasInitializedExistingConvo(convoId: number) {
    return this.messagesMap[+convoId] !== undefined;
  }

  //  ************** METHODS FOR LOADING DATA **************
  async loadAllConversations(newConvoId: number = null, refreshingAllMessageMap: boolean = false): Promise<void> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    return this.messagingService.getConversations(sessionKey).toPromise().then(async (result) => {
      const newConversations: ConversationModel[] = result.conversations;
      if (newConversations.length > 0) {
        await this.crossReferenceParticipants(newConversations);
        this.conversations = newConversations;
        this.updateTotalUnreadMessages();
        this.messagingService.triggerConvoReload$.next("JUST REDRAW");
        if (!refreshingAllMessageMap) {
          this.initMessageMap(result.conversations);
        }
        else {
          this.refreshMessageMap(result.conversations)
        }
        this.setTempMessages();
        this.cacheConversations();
      }

    }).catch(async (reason) => {
      // await this.loadCachedConversations();
      // await this.loadCachedMessageMap();
      this.triggerRedraw();

    });
  }

  async refreshMessageMap(conversations: ConversationModel[]) {
    const allConvos: number[] = conversations.map(convo => convo.conversationId);
    console.log(allConvos)
    await this.loadMessagesFromManyConversations(allConvos);
    this.triggerRedraw();
    this.messageMapUpdated$.next("UPDATED");
    await this.cacheMessagesMap();
  }

  async crossReferenceParticipants(convos: ConversationModel[]) {
    if (this.employeeStoreService.users?.length <= 0) {
      await this.employeeStoreService.loadAllUsers();
    }
    for (let convo of convos) {
      for (let user of convo?.participants) {
        const employee: Employee = this.employeeStoreService.getUser(+user?.id);
        user.firstName = employee?.userDto?.firstName;
        user.preferredFirstName = employee?.userDto?.preferredFirstName;
        user.lastName = employee?.userDto?.lastName;
        user.imageUrl = employee?.userDto?.imageUrl;
      }
    }
  }

  async loadMessagesFromManyConversations(conversationIds: number[]) {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    if (conversationIds.length === 0) return;
    const result = await this.messagingService.getMessageMap(sessionKey, conversationIds).toPromise();
    const inputMap = result.messageMap;
    this.updateMessageMap(inputMap);

    this.cacheMessagesMap();

  }

  private updateMessageMap(newMessageMap: any) {
    for (let nextConvoId of Object.keys(newMessageMap)) {
      const newMessagesFromServer: MessageModel[] = newMessageMap[nextConvoId];
      this.updateConversationMessagesInMessageMap(newMessagesFromServer, nextConvoId);
    }
    this.storeMessagesMapHasBeenInitializedEvent.next(true);
    this.storeMessagesMapHasBeenInitialized = true;
    // console.log("FINISHED UPDATING MESSAGES MAP");
  }

  //HERE we dont want to override messages if they exists. Only add new ones.

  private updateConversationMessagesInMessageMap(newMessagesFromServer: MessageModel[], convoId: string): void {
    const priorListOfMessages: MessageModel[] = this.messagesMap[convoId];
    if (priorListOfMessages?.length > 0) {
      this.messagesMap[convoId] = this.mergeMessageLists(priorListOfMessages, newMessagesFromServer);
    } else {
      this.messagesMap[convoId] = newMessagesFromServer;
    }
    this.messageMapUpdated$.next("merged_changes");
  }

  private mergeMessageLists(priorListOfMessages: MessageModel[], newMessagesFromServer: MessageModel[]): MessageModel[] {
    const priorLatestMessage: MessageModel = priorListOfMessages[priorListOfMessages.length - 1];
    const newLatestMessage: MessageModel = newMessagesFromServer[newMessagesFromServer.length - 1];
    if (priorLatestMessage.messageId <= newLatestMessage.messageId) return newMessagesFromServer;
    else {
      // Add every new message from the prior message list to the incoming message list 
      let map: Map<number, MessageModel> = new Map();
      for (let msg of newMessagesFromServer) {
        map.set(+msg.messageId, msg);
      }
      for (let msg of priorListOfMessages) {
        if (!map.has(+msg.messageId)) {
          newMessagesFromServer.push(msg);
        }
      }
    }

    return newMessagesFromServer;
  }

  // Prevent from re-initializing the message map if already initialized 
  async loadMessagesFromConversation(conversationId: number) {
    // if (!this.messagesMap[conversationId]) {
    const result = await this.messagingService.getMessages(conversationId);

    this.messagesMap[conversationId] = result.messages;

    // }
  }


  async loadMessageUpdates(conversationId: number) {
    const result = await this.messagingService.getMessages(conversationId);

    this.messagesMap[conversationId] = result.messages
    this.triggerRedraw("reconnecting");
    this.cacheMessagesMap();
  }

  /**
   * Returns the number of new messages recieved
   */
  async appendLazyLoadedData(convoId: number): Promise<number> {
    const messages: MessageModel[] = this.messagesMap[convoId];
    const response: MessagesResponse = await this.messagingService.getMessages(convoId, messages.length);
    messages.unshift(...response.messages);
    this.triggerRedraw("lazyLoad");
    return response.messages.length;
  }


  //When you first load all of the conversations this will be called 
  async initMessageMap(conversations: ConversationModel[]) {
    // Load all out of date messages
    this.loadMessagesFromEveryOutOfDateConversation();

    const firstTwentyConvoIds: number[] = conversations.slice(0, 24).map(convo => convo.conversationId);
    await this.loadMessagesFromManyConversations(firstTwentyConvoIds);

    this.triggerRedraw();
    this.messageMapUpdated$.next("UPDATED");

    await this.cacheMessagesMap();

  }

  /**
   * 
   */
  async loadMessagesFromEveryOutOfDateConversation() {
    const convoIds: number[] = this.conversations.filter(c => !this.allMessagesLoaded(c) && this.latestMessageIdIsNotNull(c))
      .map(convo => convo.conversationId);

    await this.loadMessagesFromManyConversations(convoIds);

    this.triggerRedraw();
    this.messageMapUpdated$.next("UPDATED");
    this.loadMessagesFromEveryPinnedConversation();
  }

  async loadMessagesFromEveryPinnedConversation() {
    const myId: string = await this.userDetailsService.getUserId();
    const convoIds: number[] = this.conversations.filter(c => this.messagingService.conversationIsPinnedForMe(c, myId))
      .map(convo => convo.conversationId);

    await this.loadMessagesFromManyConversations(convoIds);

    this.triggerRedraw();
    this.messageMapUpdated$.next("UPDATED");
  }

  private latestMessageIdIsNotNull(conversation: ConversationModel): boolean {
    // return true;
    return conversation.latestMessageId !== null && conversation.latestMessageId !== undefined;
  }

  private allMessagesLoaded(conversation: ConversationModel): boolean {
    if (conversation.latestMessageId === 0) { return true }
    const id: number = conversation.conversationId;
    const convoMessages: MessageModel[] = this.messagesMap[id];

    if (!convoMessages) { return false }

    if (
      convoMessages?.length === 0
    ) {
      return false;
    }

    const max: MessageModel = convoMessages.reduce((prev, current) => (+prev?.messageId > +current?.messageId) ? prev : current);
    const output: boolean = +max.messageId === +conversation.latestMessageId;
    return output;
  }

  // private numberOfMessagesInConversationMessageMap(conversation: ConversationModel): number {
  //   const id: number = conversation.conversationId;
  //   const convoMessages: MessageModel[] = this.messagesMap[id];
  //   return convoMessages.length;
  // }

  //  ************** METHODS FOR MANIPULATING DATA **************
  private async updateTotalUnreadMessages() {
    let unreadTemp: number = 0;
    const myId: string = await this.userDetailsService.getUserId();
    const conversationsCopy: ConversationModel[] = [...this.conversations];
    conversationsCopy.forEach((conversation) => {
      if (this.messagingService.conversationIsMutedForUser(conversation, myId)) return;
      unreadTemp += conversation?.unreadMessageCount ? conversation?.unreadMessageCount : 0;
    });

    this.totalUnreadMessages = unreadTemp;
  }

  // private updateSelectedConversation(newConvoId: number = null) {
  //   if (newConvoId) {
  //     const newConvo: ConversationModel = this.conversations.find(convo => convo.conversationId === newConvoId);
  //     if (newConvo) {
  //       this.selectedConversation = newConvo;
  //     }
  //   }
  //   if (
  //     this.selectedConversation === undefined &&
  //     this.conversations[0] !== undefined
  //   ) {
  //     this.selectedConversation = this.conversations[0];
  //   }
  //   this.cacheCurrentConversation();
  // }

  markSelectedConvoAsReadLocally(currentConversation: ConversationModel): void {
    currentConversation.unreadMessageCount = 0;

    this.conversations.forEach(convo => {
      if (convo.conversationId === currentConversation.conversationId) {
        convo.unreadMessageCount = 0;
      }
    })

    this.updateTotalUnreadMessages();
  }

  //  ************** GETTERS **************
  getStoredConversations(): ConversationModel[] {
    return this.conversations;
  }

  getStoredConversationBasedOnId(id: any): ConversationModel {
    return this.conversations.find(conversation => id + "" === conversation?.conversationId + "")
  }

  // getStoredConvoId(): number {
  //   return this.selectedConversation?.conversationId;
  // }

  // getSelectedConversation(): ConversationModel {
  //   return this.selectedConversation;
  // }

  getTotalUnreadMessages(): number {
    return this.totalUnreadMessages;
  }

  async getStoredMessagesForConversation(convoId: number): Promise<MessageModel[]> {

    if (!convoId && this.conversations.length > 0 && this.conversations[0] && this.conversations[0] !== undefined) {
      convoId = this.conversations[0].conversationId;
    }

    if (!convoId) return [];

    let messages: MessageModel[] = this.messagesMap ? this.messagesMap[convoId] : [];
    if (!messages) {
      await this.loadCachedMessageMap(false);
      messages = this.messagesMap[convoId];
    }

    if (!messages) {
      try {
        this.messagesMap[convoId] = (await this.messagingService.getMessages(convoId)).messages;
        messages = this.messagesMap[convoId];
      } catch(e) {
        console.error(e);
      }
    }
    if (!messages) {
      messages = [];
    }
    return messages
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public async storeOutstandingNotifications() {
    await this.fcmService.triggerSaveToken();

    
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;
    PushNotifications.getDeliveredNotifications().then(res=> {

      this.recievedNotifications = res;

    });

    const deliveredNotifications: PushNotificationSchema[] = await this.fcmService.getOutstandingNotifications();

    // devAlert(JSON.stringify(deliveredNotifications));

    console.log("***** STORE NOTIFICATIONS ******");
    // console.log(JSON.stringify(deliveredNotifications));

    for (let notification of deliveredNotifications) {
      this.notifications.push(notification)
      // this.extractNewMessageFromNotification(notification);
      this.fcmService.handleNotification(notification);
    }
    // this.cacheMessagesMap();
    
    this.fcmService.removeAllDeliveredNotifications();


  }

  //  ************** SETTERS **************
  // TODO: on navigation, cache text box AND make sure messagesMap is populated AND update unread
  // setSelectedConversation(newConversation: ConversationModel, newConversationId: number = -1): void {
  //   // if (!this.inEditMessageMode) {
  //   //   this.cacheTextBoxValue(this.selectedConversation?.conversationId, this.editorMsg);
  //   // }
  //   if (newConversation === undefined && newConversationId === -1) {
  //     this.selectedConversation = this.conversations[0];
  //     return;
  //   }

  //   if (newConversationId !== -1) {
  //     this.conversations.forEach(convo => {
  //       if (convo.conversationId.toString() === newConversationId.toString()) {
  //         newConversation = convo;
  //       }
  //     })
  //   }

  //   //ADP: I added && this.messagesMap.size !== 0 because the user can set the SelectedConversation before the messagesMap is initialized and then returns 
  //   if (newConversation?.conversationId && !this.messagesMap[newConversation.conversationId] && this.messagesMap.size !== 0) {
  //     this.messagesMap[newConversation.conversationId] = [];
  //   }

  //   const existingConvo: ConversationModel = this.conversations.find((convo) => convo.conversationId === newConversation.conversationId);
  //   if (!existingConvo) {
  //     this.conversations.push(newConversation);
  //   }

  //   this.selectedConversation = newConversation;
  //   this.updateTotalUnreadMessages();
  //   this.setTempMessages();
  // }

  addConvoIfItIsNew(newConversation: ConversationModel) {
    const existingConvo: ConversationModel = this.conversations.find((convo) => convo.conversationId === newConversation.conversationId);
    if (!existingConvo) {
      this.conversations.push(newConversation);
    }
  }

  private triggerRedraw(status: string = "redraw"): void {
    this.triggerRedraw$.next(status);

  }

  //  ************** TEMP MESSAGE MANAGEMENT **************

  private getTempMessagesForConvo(convoId: string): MessageModel[] {
    return this.allTempMessages?.filter(m => m.convoId === convoId);
  }

  public async setTempMessages(currentConvoId: string = null) {
    // if there's cached ones, load them
    let cachedTempMessages: MessageModel[] = this.getTempMessagesForConvo(currentConvoId);
    cachedTempMessages = cachedTempMessages ? [...cachedTempMessages] : [];
    this.tempMessages.splice(0, this.tempMessages.length);
    this.tempMessages.push(...cachedTempMessages);

  }

  getFileKey(contents: string, convo: string): string {
    return contents + "____" + convo;
  }

  public async addTempMessage(message: MessageModel) {
    if (!this.allTempMessages.some((item) => item.frontendId == message.frontendId && item.convoId === message.convoId)) {
      this.allTempMessages.push(message);
      this.setTempMessages(message.convoId);
    }
    if (message.selectedFile) {
      this.customFileService.saveFileLocally(message.selectedFile, message.frontendId);
    }

    this.cacheTempMessages();

  }

  async cacheImageFileFromMessage(message: MessageModel) {
    if (!message?.selectedFile) return;
    const tempPath: string = "secrets/" + message?.selectedFile?.name

  }

  /**
   * Returns if the message was replaced because it already existed
   * @param messageDto 
   * @returns 
   */
  public async removeMessageFromTempByFrontendId(frontendId: string, convoIdString: string): Promise<number> {
    const existingIndex: number = this.allTempMessages.findIndex((msg) => {
      return msg.frontendId === frontendId && msg.convoId === convoIdString;
    })
    if (existingIndex < 0 || existingIndex >= this.allTempMessages.length) return -1;
    this.allTempMessages.splice(existingIndex, 1)
    await this.setTempMessages();
    this.cacheTempMessages();
    return existingIndex;
  }


  private async loadCachedTempMessageMap(): Promise<void> {
    if (this.messagesMap?.keys?.length > 0) return;
    const tempMessagesString: string = await this.localStorage.get("TEMP_MESSAGES_MAP");
    const tempMessages: MessageModel[] = JSON.parse(tempMessagesString);

    // console.log("FINISHED LOADING MESSAGES FROM LOCAL STORAGE");
    if (tempMessages) {
      this.allTempMessages = tempMessages
    };
  }


  private async cacheTempMessages(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("TEMP_MESSAGES_MAP", JSON.stringify(this.allTempMessages));
    }, 1000);
  }

  public attemptSendAllTempMessages() {
    // for each index

  }

  public updateCurrentConversationParticipants(newParticipants: ParticipantModel[], conversation: ConversationModel) {
    conversation.participants = newParticipants;
  }

  //  ************** SOCKET HANDLING **************

  startNewMessageHandler(): void {
    this.socketMessageSubscription?.unsubscribe();
    this.socketMessageSubscription = this.messagingSocketService.socketMessages$.subscribe((newMessage: MessageModel) => {
      this.incrementParticipantsUnreadCountByConversationId(+newMessage.convoId, +newMessage.fromUser);
      this.appendNewMessageFromSocket(newMessage);
    });
    this.notificationMessageSubscription?.unsubscribe();
    this.notificationMessageSubscription = this.messagingService.pushMessages$.subscribe((notification: PushNotification) => {
      this.extractNewMessageFromNotification(notification);
    });
    this.notificationActionMessageSubscription?.unsubscribe();
    this.notificationActionMessageSubscription = this.messagingService.pushNotificationActions$.subscribe((action: ActionPerformed) => {
      this.extractNewMessageFromNotification(action.notification);
    });

    this.editMessageCommandSubscription?.unsubscribe();
    this.editMessageCommandSubscription = this.messagingSocketService.socketEditMessage$.subscribe((action: EditMessageCommand) => {
      this.updateConversationsFromEditCommand(action);
    });

    this.markUserUnreadSubscription?.unsubscribe();
    this.markUserUnreadSubscription = this.messagingSocketService.socketMarkUserAsRead$.subscribe((action: MarkAsReadCommand) => {
      this.updateConversationsFromMarkAsReadCommand(action);
    });

    this.addPollResponseSubscription?.unsubscribe();
    this.addPollResponseSubscription = this.messagingSocketService.socketAddPollResponse$.subscribe((action: AddPollResponseCommand) => {
      this.addPollResponse(action);
    });

    this.removePollResponseSubscription?.unsubscribe();
    this.removePollResponseSubscription = this.messagingSocketService.socketRemovePollResponse$.subscribe((action: RemovePollResponseCommand) => {
      this.removePollResponse(action);
    });
  }

  private removePollResponse(command: RemovePollResponseCommand) {
    const poll: Poll = this.findPoll(command.conversationId, command.messageId);

    if (poll) {
      const option: PollOption = poll.options.find(o => o.id === command.optionId);
      this.pollService.removeUserVotesFromPollOption(poll, option, command.userId);
    }
    // use poll service
    // this.cacheMessagesMap();
  }

  private addPollResponse(command: AddPollResponseCommand) {
    const poll: Poll = this.findPoll(+command.conversationId, +command.messageId);
    if (poll) {
      const response: PollResponse = command.pollResponse;

      this.pollService.addVoteToPollObject(poll, response);
    }
    // this.cacheMessagesMap();
  }

  private findMessage(conversationId: number, messageId: number): MessageModel {
    const list: MessageModel[] = this.messagesMap[conversationId];
    return list?.find(m => +m.messageId === messageId)
  }

  private findPoll(conversationId: number, messageId: number): Poll {
    const message: MessageModel = this.findMessage(conversationId, messageId);
    return message?.poll;
  }

  private updateConversationsFromEditCommand(command: EditMessageCommand) {
    if (command.isLatestMessage) {
      this.conversations.find(convo => convo.conversationId === +command.conversationId).latestMessage = command.newContents;
      this.messagingService.triggerConvoReload$.next("JUST REDRAW")
    }
  }

  private updateConversationsFromMarkAsReadCommand(command: MarkAsReadCommand) {
    if (command.conversationId) {
      const convo: ConversationModel = this.conversations.find(convo => convo.conversationId === +command.conversationId);
      this.updateConversationByMarkAsReadCommand(command, convo);
      

      this.messagingService.triggerConvoReload$.next("JUST REDRAW")
    }
  }

  public async updateConversationByMarkAsReadCommand(command: MarkAsReadCommand, convo: ConversationModel): Promise<void> {
    const participant: ParticipantModel = convo?.participants.find(p => +p.id === +command.userMarkedAsRead);
    if (participant) {
      participant.unreadMessageCount = 0;
    }

    const userId: string = await this.userDetailsService.getUserId()
    if (command.userMarkedAsRead === +userId) {
      convo.unreadMessageCount = 0;
      this.updateTotalUnreadMessages();
    }
  }

  async incrementParticipantsUnreadCountByConversationId(convoId: number, senderId: number) {
    const convo: ConversationModel = this.conversations.find(c => c.conversationId === convoId);
    if (!convo || convo == null) return;
    this.incrementParticipantsUnreadCountByConversation(convo, senderId);
  }

  public async incrementParticipantsUnreadCountByConversation(convo: ConversationModel, senderId: number) {
    for (let participant of convo.participants) {
      participant.unreadMessageCount = participant.unreadMessageCount + 1;
      if (+participant.id === senderId) {
        participant.unreadMessageCount = 0;

        const userId: string = await this.userDetailsService.getUserId()
        if (senderId === +userId) {
          convo.unreadMessageCount = 0;
          this.updateTotalUnreadMessages();
        }
      }
    }
  }

  private cleanUpCarriageReturn(message: MessageModel) {
    if (message.contents.includes('\r')) {
      message.contents = message.contents.replace(/[\r]+/g, '');
    }
  }

  private async extractNewMessageFromNotification(notification: PushNotificationSchema) {

    const convo_id = notification.data['convo_id'];
    const messageId = notification.data['messageId'];
    const fromUser = notification.data['fromUser'];
    const fromUserName = notification.data['fromUserName'];
    const sendDate = notification.data['sendDate'];
    const fromUserImg = notification.data['fromUserImg'];
    const messageImage = notification.data['messageImage'];
    const messageType = notification.data['messageType'];
    const contents = notification.data['messageContents'];
    const frontendId = notification.data['frontendId'];
    // devAlert("Extract from notification: " + contents);

    if (!convo_id || !messageId) {
      return
    }
    

    let newMessage: MessageModel = {
      messageId: messageId,
      fromUser: fromUser,
      convoId: convo_id,
      contents: contents,
      sendDate: sendDate,
      fromUserName: fromUserName,
      fromUserImg: fromUserImg,
      messageImage: messageImage,
      messageType: messageType,
      reactions: [],
      frontendId: frontendId
    };


    // devAlert("Extract from notification: " + contents);
    // await this.delay(2000)

    this.appendNewMessageFromSocket(newMessage);


    // let convoId: number = +newMessage.convoId;
    // const messageList: MessageModel[] = this.messagesMap[convoId];
    // messageList.push(newMessage); 
    // this.updateConversation(newMessage, true);

    // this.triggerRedraw$.next(newMessage);

  }

  private async appendNewMessageFromSocket(newMessage: MessageModel) {
    this.cleanUpCarriageReturn(newMessage);
    //check what convo the message is a part of then add it to the map
    let convoId: number = +newMessage.convoId;
    const messageList: MessageModel[] = this.messagesMap[convoId];
    let messageIsNew: boolean = false;
    newMessage.isPending = false;
    if (messageList) {
      let indexOfPendingInList = this.messageIsPendingInList(newMessage);

      if (indexOfPendingInList >= 0) {
        this.updateToIsNotPending(newMessage, indexOfPendingInList);
      } else if (!this.messageIsAlreadyInList(newMessage, messageList)) {
        messageIsNew = true;
        messageList.push(newMessage); // TODO: This must be getting orphaned still
        // console.log("Pushing: " + JSON.stringify(newMessage))
      }
      // else if (this.messageIsAlreadyInList(newMessage, messageList)) {
      //   console.log("Already In List: " + JSON.stringify(newMessage))
      // }

      //Right here we need to 
    } else {
      this.messagesMap[convoId] = [newMessage];
    }

    this.messageMapUpdated$.next("SOCKET_MESSAGE")
    this.triggerRedraw$.next(newMessage);
    this.updateConversation(newMessage, messageIsNew);

    this.triggerRedraw$.next(newMessage);

  }

  private updateConversation(newMessage: MessageModel, messageIsNew: boolean): void {
    const convoToUpdate: ConversationModel = this.conversations.find(conversation => conversation.conversationId === +newMessage.convoId);
    if (!convoToUpdate) {
      this.loadAllConversations(); // TODO: this could just load that single conversation
      return;
    }
    if (+convoToUpdate.latestMessageId < +newMessage.messageId) {
      convoToUpdate.latestMessage = this.getLatestMessageString(newMessage);
      convoToUpdate.latestMessageTime = newMessage.sendDate;

    }
    // if (convoToUpdate.conversationId !== this.getStoredConvoId()) {
    if (messageIsNew) {
      convoToUpdate.unreadMessageCount += 1;
    }
    // }
    this.updateTotalUnreadMessages();
    this.conversations.sort((a, b) => {
      if (a.latestMessageTime > b.latestMessageTime) { return -1 }
      if (a.latestMessageTime < b.latestMessageTime) { return 1 }
      if (a.latestMessageTime === b.latestMessageTime) { return 0 }
    })
  }

  private getLatestMessageString(newMessage: MessageModel): string {
    if (newMessage.contents.startsWith("RπPLY")) {
      return newMessage.fromUserName + " sent a reply"
    } else if (newMessage.messageType === "IMAGE") {
      return newMessage.fromUserName + " sent an image"
    } else if (newMessage.messageType === "VIDEO") {
      return newMessage.fromUserName + " sent a video"
    }
    else if (newMessage.messageType === "GIF") {
      return newMessage.fromUserName + " sent a gif"
    }
    else if (newMessage.contents?.length > 0) {
      return newMessage.contents;
    } else {
      return newMessage.fromUserName + " sent a message"
    }
  }

  private messageIsPendingInList(message: MessageModel): number {
    if (message.frontendId==="") return -1;

    const list: MessageModel[] = this.messagesMap[+message.convoId];
    const messageIndex: number = list.findIndex(
      (m) => (m.frontendId === message.frontendId)
    );
    return messageIndex;
  }

  private updateToIsNotPending(newMessage: MessageModel, indexOfPendingInList: number) {
    const convoId: number = +newMessage.convoId;
    newMessage.isPending = false;

    this.messagesMap[convoId][indexOfPendingInList].fromUser = newMessage.fromUser;
    this.messagesMap[convoId][indexOfPendingInList].sendDate = newMessage.sendDate;
    this.messagesMap[convoId][indexOfPendingInList].messageId = newMessage.messageId;
    this.messagesMap[convoId][indexOfPendingInList].isPending = false;
    this.messagesMap[convoId][indexOfPendingInList].messageImage = newMessage.messageImage;

  }

  private messageIsAlreadyInList(message: MessageModel, messageList: MessageModel[]): boolean {
    const messageIndex: number = messageList.findIndex(
      (m) => m.messageId === message.messageId
    );
    return messageIndex >= 0;
  }

  // ****** NETWORK CONNECTION HANDLING *********
  public startNetworkConnectionHandler(): void {
    Network.addListener("networkStatusChange", (status) => this.handleNetworkStatusChange(status));
  }

  private handleNetworkStatusChange(status: ConnectionStatus): void {
    if (status.connected) {
      this.loadMessageUpdates(this.getCurrentIdByRoute());
      this.messagingService.triggerConvoReload$.next("something");
      this.messagingSocketService.reconnectIfDisconnected();
      this.loadAllConversations();

      //send all temp messages 

    } else {
      // TODO: do whatever we need to do here to handle a disconnection happening
    }
  }

  // TODO: refactor - implement this
  getCurrentIdByRoute(): number {
    return 200;
  }

  // ****** CACHING SETTERS ******
  private async cacheConversations(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("CONVERSATIONS", this.conversations);
    }, 1000);
  }

  private async cacheMessagesMap(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("MESSAGE_MAP", JSON.stringify(this.messagesMap));
    }, 1000);
  }

  // ****** CACHING GETTERS ******
  async loadCachedConversations(isFirstTime: boolean = false): Promise<ConversationModel[]> {
    if (this.conversations?.length > 0) return;
    const conversations: ConversationModel[] = await this.localStorage.get("CONVERSATIONS");

    // console.log("FINISHED LOADING CONVERSATIONS FROM LOCAL STORAGE");
    if (conversations) {
      await this.crossReferenceParticipants(conversations);
      this.conversations = conversations;
    }
    // this.editorMsg = this.getTextBoxValue(this.selectedConversation.conversationId);
    return conversations;
  }

  private async loadCachedMessageMap(markUpdated: boolean = true): Promise<Map<number, MessageModel[]>> {
    if (Object.keys(this.messagesMap).length > 0) return;
    const messageMapString: string = await this.localStorage.get("MESSAGE_MAP");
    const messageMap: Map<number, MessageModel[]> = JSON.parse(messageMapString);
    if (markUpdated) {
      this.messageMapUpdated$.next("UPDATED");
    }
    // console.log("FINISHED LOADING MESSAGES FROM LOCAL STORAGE");
    if (messageMap) {
      this.updateMessageMap(messageMap);
    } 
    return messageMap;
  }



  public cacheTextBoxValue(convoId: number, currentTextBoxContents: string) {
    if (!this.textBoxContentsMap) { return }
    this.textBoxContentsMap[convoId] = currentTextBoxContents;
    this.cacheTextBoxContentsMap();
  }

  public getTextBoxValue(convoId: number): string {
    const output: string = this.textBoxContentsMap[convoId];
    return output ? output : "";
  }


  private async loadCachedTextBoxContentsMap(): Promise<Map<number, string>> {
    if (this.textBoxContentsMap?.keys?.length > 0) {
      // this.editorMsg = this.getTextBoxValue(this.selectedConversation.conversationId);
      return;
    };
    const textBoxContentsMapString: string = await this.localStorage.get("TEXT_BOX_CONTENTS_MAP");
    const newContentsMap: Map<number, string> = JSON.parse(textBoxContentsMapString);
    this.messageMapUpdated$.next("UPDATED");
    // console.log("FINISHED LOADING TEXT BOX CONTENTS MAP FROM LOCAL STORAGE");
    if (newContentsMap) this.textBoxContentsMap = newContentsMap;
    // if (newContentsMap) await this.storeOutstandingNotifications();
    // this.editorMsg = this.getTextBoxValue(this.selectedConversation.conversationId);
    return newContentsMap;
  }


  private async cacheTextBoxContentsMap(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("TEXT_BOX_CONTENTS_MAP", JSON.stringify(this.textBoxContentsMap));
    }, 1000);
  }

  getEnzyAssistantConversationId(): number {
    const convoId: number = this.conversations.filter(c=> c.participants.length === 2)
                                              .find(c => c.participants.filter(p=>+p.id===4760).length > 0).conversationId
    if (convoId) return convoId;
    return 3304;
  }

  markManyMessageReportLinesAsCompleteLocally(conversationId: number, originReportLines: ReportLine[]) {
    if (!originReportLines || originReportLines.length === 0) return;
    for (let line of originReportLines) {
      if (line) {
        this.markMessageReportLineAsCompleteLocally(conversationId, line.messageId, line.lineName);
      }
    }
  }

  markMessageReportLineAsCompleteLocally(conversationId: number, messageId: number, reportLineName: string) {
    const message: MessageModel = this.findMessage(conversationId, messageId);
    if (!message) return;

    const reportLine: ReportLine = message.reportLines?.find(l => l.lineName === reportLineName);
    if (!reportLine) return;

    reportLine.isCompleted = true;
  }

  markMessageSurveysDispositionedLocally(convoId: number, surveyResponse: DashboardAlertModel) {
    const chatMessages: MessageModel[] = this.messagesMap[convoId];
    for(let message of chatMessages) {
      if (surveyResponse?.pageVisitId 
        && message?.surveyResponseDescription?.pageVisitId
        && message?.surveyResponseDescription?.surveyPageKey === surveyResponse.surveyPageKey
        && message?.surveyResponseDescription?.pageVisitId <= surveyResponse?.pageVisitId) {
        message.surveyResponseDescription.dispositioned = true;
      }
      if (surveyResponse?.surveyResponseId 
        && message?.surveyResponseDescription?.surveyResponseId
        && message?.surveyResponseDescription?.surveyId === surveyResponse.surveyId
        && message?.surveyResponseDescription?.surveyResponseId <= surveyResponse?.surveyResponseId) {
        message.surveyResponseDescription.dispositioned = true;
      }
    }

  }
}
