
<div style="text-align: center; " class="app-background-color">
  <div style="height: 20px"></div>
  <span class="material-icons" >
    lock
  </span>
  <h1 mat-dialog-title style="text-align: center;">This incentive is locked</h1>
  <div mat-dialog-content style="text-align: center;">
    <p>
      It will be released soon
    </p>
  </div>
  <div style="height: 20px"></div>

</div>
