import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DatesService } from 'src/app/shared/services/dates.service';

@Component({
  selector: 'enzy-available-times',
  templateUrl: './available-times.component.html',
  styleUrls: ['./available-times.component.scss'],
})
export class AvailableTimesComponent implements OnInit {

  currentDate: string;
  availableTimes: string[] = ["10 am", "noon", "2pm", "6pm"]

  constructor(
    public datesService: DatesService,
  ) { }

  ngOnInit() {}

  formatDate(date: string) {
    const formattedDate = new DatePipe('en-US').transform(date, 'MM/dd/yy')
    return formattedDate
  }

  updateDate(event: any) {
    this.currentDate = this.datesService.serverFriendlyDate(event.value.toDateString());
  }

}
