import { TournamentRound } from "../services/incentives.service";
import { LeaderboardFilterOutput } from "../services/leaders.service";
import { Employee } from "./employees.model";

export class MilestoneIncentiveModel {
  campaignDetails: CampaignBackendModel;
  milestones: MilestoneBackend[];
}


export class CampaignBackendModel {
  campaignId: number ; // set by backend
  campaignName: string ;
  campaignType: string ; // competition or milestone
  startDate: string ;
  endDate: string ;
  backgroundURL: string ;
  thumbnailUrl: string ;
  groupType: string ; // individual or team
  isActive: boolean ;
  isLocked: boolean ;

  eligibleUsers?: number[];

  rounds?: TournamentRound[];
  milestones?: MilestoneBackend[];
}
export interface MilestoneBackend {
  minValue: number ;
  tierDescription: string ;
  prizeDescription1: string ;
  prizeDescription2: string ;
  imageUrl: string ;
  prizeUrl: string ;
}

export class Incentive {
  campaignName: string;

  backgroundURL: string;
  thumbnailUrl: string;

  rank: string;
  groupType: string; //Individual or Team
  campaignType: string; //competition or global
  startDate: string; //Infinite or finite
  endDate: string;
  isActive?: boolean;
  // messages: MessageModel[];
  isLocked: boolean;
  id: number;
}

export interface IncentiveRefactored {
  definingFilters: LeaderboardFilterOutput; // Group by, time frame, 
  selectedParticipants: number[];
  selectedGroups: {groupName: string, }[]; // this would be for multiple teams/regions. This may 
  triggers: {thresholdValue: number, metric: string}[]; // metric might be tracked by something else
}


export const DEFAULT_MILESTONE_CAMPAIGN: CampaignBackendModel = {
  campaignId: null,

  campaignName: null,
  campaignType: "milestone",

  backgroundURL: null,
  thumbnailUrl: null,

  groupType: "individual",
  
  startDate: null,
  endDate: null,

  isLocked: false,
  isActive: false
}

export const DEFAULT_TOURNAMENT_ROUND: TournamentRound = {
  isActive: false,
  roundId: null,
  startDate: null,
  endDate: null,
  roundName: "Round 1",
  roundType: "Regular Season",
  matchups: [],
  roundPrizeImage: "",
  roundOrder: 0,
  reportRequestId: null,
  selfGenReportRequestId: null,
  stagePerTeamReportId: null,
  participants: [],
  matchupToTeamIdMap: null,
  teamsToUserMap: null, 
  teamScoresMap: null,
  teamNamesMap: null,
  teamImagesMap: null,
}

export class Team {
  name: string;
  backgroundUrl: string;
  currentRanking: string;
  record: string;
  people: People[];
}


//TODO: do we want to use another object other than people? 
export class People {
  name: string;
}

export class TwoTeamMatchup {

  constructor(
    _team1Name: string,
    _team2Name: string,
    _team1Score: number,
    _team2Score: number,
    _team1Url?: string,
    _team2Url?: string,
  ) {
    this.team1Name = _team1Name;
    this.team2Name = _team2Name;
    this.team1Score = _team1Score;
    this.team2Score = _team2Score;
    this.team1Url = _team1Url;
    this.team2Url = _team2Url;
  }

  team1Name: string;
  team2Name: string;

  team1Score: number; 
  team2Score: number;

  team1Deals: number;
  team2Deals: number;

  team1SelfGen: number;
  team2SelfGen: number;

  team1Multiplier: number; 
  team2Multiplier: number;

  team1UseOverrideScore: boolean;
  team2UseOverrideScore: boolean;


  team1Url?: string; 
  team2Url?: string;

  team1CalculatedRealizationRate: number;
  team2CalculatedRealizationRate: number;
  
}

export class RegularSeasonRound {
  roundName: string;
  isActive: boolean;
  roundId: number;
  roundType: string;
  matchups: TwoTeamMatchup[];
  roundPrizeImage: string;
  startDate: string;
  endDate: string;
}


export class PlayoffRound {
  roundNumber: number;
  // numberOfTeams: number;
  teams: string[];
  numberOfMiddleSpacers: number;
  valueOfMiddleSpacers: number;
  valueOfEdgeSpacers: number;
}

export class Tournament {
  numberOfTeamsAtOutset: number;
  title: string;
  rounds: PlayoffRound[]
}

export class Prize {
  prizeDescription1: string; 
  tierDescription: string; 
  imageUrl: string;
  minValue: number;
  prizeUrl: string;
}