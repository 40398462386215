import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss'],
})
export class DocumentModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DocumentModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
   }


   delete() {
     this.dialogRef.close("delete");
   }

  ngOnInit() {}

}
