<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<div style=" overflow-y: hidden; height: 100vh;">


  <ion-content>
    <div class="bg" style="z-index:99; height: 100vh; " *ngIf="showLoginPage">
      <div style="max-width: 600px; display: block; margin: auto;">

        <div style="height: 50px"></div>
        <div>
          <div style="height: 20px"></div>
          <img style="height: 50px; display: block; margin: auto; border-radius: 10px;" src="/assets/enzyLogoColor.png">
          <div style="height: 10px"></div>
        </div>


        <div>

          <div style="height: 20px"></div>
          <mat-form-field style="width: 80%; display: block; margin: auto;">
            <input matInput placeholder="Email" [(ngModel)]="email" (keyup.enter)="attemptLogin()">
          </mat-form-field>

          <mat-form-field style="width: 80%; display: block; margin: auto;">
            <input type="password" matInput placeholder="Password" [(ngModel)]="password"
              (keyup.enter)="attemptLogin()">
          </mat-form-field>



          <div style="height: 20px;"></div>

          <button mat-raised-button
            style="width: 80%; height: 50px; border-radius: 25px;   font-size: 20px;  display: block; margin: auto;"
            class="primary-color" (click)="attemptLogin()">Login</button>
          <div style="height: 10px;"></div>



          <ion-grid style="width: 80%; display: block; margin: auto;">
            <ion-row>
              <ion-col size="6">
                <button mat-button
                  style="width: 100%;   font-size: 14px; font-weight: 8; text-align: center; background-color: transparent; padding: 0px"
                  (click)="openPasswordHelperInBrowser()">Reset Password</button>
              </ion-col>
              <ion-col size="6">
                <button mat-button
                  style="width: 100%;   font-size: 14px; font-weight: 8; text-align: center; background-color: transparent; padding: 0px"
                  (click)="joinSunder()">Join</button>
              </ion-col>

            </ion-row>
          </ion-grid>
        </div>
      </div>

    </div>
    <div *ngIf="!showLoginPage">

      <div style="text-align: center; max-width: 600px; display: block; margin: auto;">
        <div style="height: 50px"></div>

        <div>
          <div style="height: 20px"></div>

          <img style="height: 50px; display: block; margin: auto; border-radius: 10px;" src="/assets/enzyLogoColor.png"
            alt="">
          <div style="height: 40px"></div>

        </div>


        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <div style="height: 20px"></div>

        <div style="text-align: center;" *ngIf="secondsUserHasBeenOnPage >= 3">
          Auto logging you in.

        </div>

        <div style="height: 20px"></div>

        <div style="text-align: center; background-color: #c7c771; color: white; padding: 10px;"
          *ngIf="secondsUserHasBeenOnPage >= 5">
          Slow Connection Detected...
        </div>
      </div>

    </div>
  </ion-content>
</div>