import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Platform } from "@ionic/angular";
import { Storage } from '@ionic/storage';
import { LineChartConfig } from "src/app/shared/components/charts/line-chart/line-chart.component";
import { PieChartConfig } from "src/app/shared/components/charts/pie-chart/pie-chart.component";
import { DashboardData, DEFAULT_DASHBOARD_DATA, UserData } from 'src/app/shared/models/dashboard.model';
import { Employee } from 'src/app/shared/models/employees.model';
import { ReportCellList } from 'src/app/shared/models/report-cell-list.model';
import { ReportStoreService } from "src/app/shared/services/report-store.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { ThemeService } from "src/app/shared/services/theme-service.service";
import { UserDetailsService } from "src/app/shared/services/user-details.service";

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {

  @Input() report: DashboardData = { ...DEFAULT_DASHBOARD_DATA }

  @Output() showAnalytics = new EventEmitter<any>();
  @Input() homeDrillDown: boolean = false;

  @Input() reportId: string;


  @Input() inDragMode: boolean = false;


  @Input()
  update: boolean;
  compInitialzed: boolean = false;

  constructor(
    public userDetailsService: UserDetailsService,
    public reportsStoreService: ReportStoreService,
    public reportsService: ReportsService, 
    public snackbarService: SnackbarService,
    public platform: Platform,
  ) {}

  getTitle() {
    return this.report.freshReport?.reportName ? this.report.freshReport?.reportName : ""
  }

  getCategory() {
    return this.report?.freshReport?.reportCategory ?  this.report?.freshReport?.reportCategory : "Other"
  }

  ngOnInit(): void {

    //I really dislike this pattern but I'm accepting this tech debt. Essentially the rest/employeeReports endpoint needs to be refactored
    //so the home-employee-drill-down component doesn't have to fetch it's own data. The dashboard-card should fetch the data. But this is a
    //Short-term fix
    if (!this.homeDrillDown)  {
      this.fetchData();
    }
    else {
      this.compInitialzed = true
    }
  }

  async fetchData() {
    await this.reportsStoreService.loadReportData(this.report)
    this.compInitialzed = true;
  }

  parseData(reportCellList: ReportCellList) {
    if (!reportCellList.list) { return [] }
    let output = [];
    output = reportCellList.list.map((cell: any) => {
      return {
        "date": cell.cellList[0].value,
        "dataPointValue": (cell.cellList[1] && cell.cellList[1].value) ? +(cell.cellList[1].value).replace(/\$|,|%/g, '') : ""
      }
    })
    return output
  }



  isSpecificViewType(type: string): boolean {
    if (!this.report.freshReport.viewType) {
      return this.report?.viewFilter?.toLowerCase().includes(type);
    }
    return this.report?.freshReport?.viewType?.toLowerCase().includes(type)
  }


  showAdvancedAnalytics(): void {
    this.showAnalytics.emit(this.report);
  }

  getLineChartConfig(): LineChartConfig {
    return {
      height: 150,
      showAxis: false
    }
  }


  getPieChartConfig(): PieChartConfig {
    return {
      height: 150,
      showLegend: false
    }
  }


  handleDownload() {
    if (this.platform.is("desktop")) {
      this.download()
    } else {
      this.downloadViaEmail();
    }
  }

  download() {
    this.reportsService.downloadReportFromId(this.report.freshReport.id + "").then(res => {
      this.downloadFile(res, this.report.freshReport.reportName)
    })
  }


  downloadViaEmail() {
    this.reportsService.downloadReportFromId(this.report.freshReport.id + "", true).then(res => {
    })
    this.snackbarService.displaySnackBarMessage("Sending report to your email...", true);

  }



  downloadFile(data: ArrayBuffer, name: string) {
    const blob = new Blob([data as BlobPart], { type: 'text/csv' });
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = name + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  handleFavorite() {
    if (this.report.freshReport.isFavorite) {
      this.removeFavorite();
    }
    else {
      this.favorite();
    }
  }

  favorite() {
    this.reportsService.setFavoriteReport(this.report.freshReport.id).then(res => {
      // this.updateNewlyFavoritedReport(report);
      if (res.success) {
        this.report.freshReport.isFavorite = true;
        this.snackbarService.displaySnackBarMessage("Successfully favorited report.")
      }
      else {
        this.snackbarService.displaySnackBarMessage("An error occured. Try again.")
      }
    });
  }


  removeFavorite() {
    this.reportsService.deleteFavoriteReport(this.report.freshReport.id).then(res => {
      
      if (res.success) {
        this.report.freshReport.isFavorite = false;
        this.snackbarService.displaySnackBarMessage("Successfully unfavorited report.")
      }
      else {
        this.snackbarService.displaySnackBarMessage("An error occured. Try again.")
      }
    });
  }

}

