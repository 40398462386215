import { Injectable } from '@angular/core';
import { Camera, CameraPhoto, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: 'root'
})
export class CustomFileService {



  constructor(public localStorage: Storage) {
  }


  public async saveFileLocally(file: File, id: string) {
    this.localStorage.set(id, file);
  }


  public async getFileLocally(id: string): Promise<any> {
    return this.localStorage.get(id);
  }



  public async deletePicture(file: File, id: string) {
    //TODO: NEED TO IMPLEMENT THIS
  }

}
