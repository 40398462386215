import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddNewModalComponent } from 'src/app/library/admin-library/add-new-modal/add-new-modal.component';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { UserPickerModalComponent } from 'src/app/shared/components/user-picker-modal/user-picker-modal.component';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { Employee } from 'src/app/shared/models/employees.model';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadModalMode } from '../lead-modal/lead-modal.component';
import { LeadsDrawerComponent } from '../leads-drawer/leads-drawer.component';
import { Appointment, DEFAULT_APPOINTMENT, DEFAULT_NOTE, getDefaultNewLead, LeadModel, Note } from '../leads.model';
import { LeadsService } from '../leads.service';



@Component({
  selector: 'app-leads-form',
  templateUrl: './leads-form.component.html',
  styleUrls: ['./leads-form.component.scss'],
})
export class LeadsFormComponent implements OnInit {

  @Input() lead: LeadModel = getDefaultNewLead();
  @Input() mode: LeadModalMode;


  public appointment: Appointment = {...DEFAULT_APPOINTMENT}; 

  userId: number;

  @Output() submitLead = new EventEmitter<LeadModel>();

  options = {
    types: [],
    componentRestrictions: { country: ['USA'] }
  }
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address, lead: LeadModel) {
    lead.fullAddress = address.formatted_address;
    this.parseAddressComponentsIntoLead(address, lead);
  }
  
  private parseAddressComponentsIntoLead(address: Address, lead: LeadModel) {
    if(!address) return;

    const city: string = address.address_components?.find(c => c.types.includes("locality"))?.short_name;
    lead.city = city ? city : lead.city;
    const state: string = address.address_components?.find(c => c.types.includes("administrative_area_level_1"))?.short_name;
    lead.state = state ? state : lead.state;
    const zip: string = address.address_components?.find(c => c.types.includes("postal_code"))?.short_name;
    lead.zip = zip ? zip : lead.zip;
    const street_number: string = address.address_components?.find(c => c.types.includes("street_number"))?.short_name;
    const route: string = address.address_components?.find(c => c.types.includes("route"))?.short_name;
    lead.streetAddress = street_number || route ? street_number + " " + route : lead.streetAddress;
  }


  constructor(
    public employeeStoreService: EmployeeStoreService,
    public leadsService: LeadsService,
    public incentivesService: IncentivesService,
    public dialog: MatDialog,
    public dateFormatService: DateFormatService,
    public userDetailsService: UserDetailsService,
    public bottomSheet: MatBottomSheet) {
  
  }

  async ngOnInit() { 

    this.userId = +(await this.userDetailsService.getUserId());

    if (this.mode === 'new') {
      this.lead.setterId = this.userId;
      this.lead.customerUtilityBillUrls = [];
      this.lead.notes = [];
    }

    if (this.mode === 'edit') {
      console.log(this.lead.preQualification)
      if (this.lead.preQualification) {
        let arr = this.lead.preQualification.split(",");

        this.lead.preQualification = arr;
      }
    }
  }


  async submitLeadOutput() {

  
    if (!this.checkForEmptyRequiredFields()) {return}
    if (!this.checkForValidPhoneNumber()) {return}
    this.newNoteFromInput();

    if (this.lead.preQualification) {
      this.lead.preQualification = this.lead.preQualification.toString();
    }

    if (this.mode === 'new') {
      if (!this.appointment.startDateTime) {alert("Missing: Appointment Time"); return; }
      this.appointment.startDateTime = this.dateFormatService.saveDateWithCorrectFormat(this.appointment.startDateTime)
      this.lead.appointments.push(this.appointment);

      this.lead.creationDate = this.dateFormatService.saveDateWithCorrectFormat(Date.now().toLocaleString());
    }


    this.submitLead.emit(this.lead);
    this.resetLead();
  }


  resetLead() {
    this.lead = getDefaultNewLead();
    this.lead.customerUtilityBillUrls = [];
    this.lead.notes = [];
    this.lead.tasks = [];
    this.lead.appointments = [];
  }

  checkForValidPhoneNumber(): boolean {
    if (this.lead.phoneNumber?.length < 14) {
      let alertString = "Invalid Phone Number";
      alert(alertString);
      return false;
    }

    if (this.lead.phoneNumber?.length >= 15) {
      this.lead.phoneNumber = this.lead.phoneNumber.substring(0, 14);
    }
    return true;
  }


  checkForEmptyRequiredFields(): boolean {
    let emptyRequiredFields: string[] = [];

    let fields = this.getFields();

    fields.forEach(field => {
      if (!this.lead[field] && this.fieldIsRequired(field)){
        emptyRequiredFields.push(camelCaseToSentenceCase(field))
      }
    })

    if (this.lead.customerUtilityBillUrls?.length == 0) {
      emptyRequiredFields.push(camelCaseToSentenceCase("customerUtilityBill"))
    }

    if (emptyRequiredFields.length > 0) {
      let alertString = "Missing: " + emptyRequiredFields.toString();
      alert(alertString);
      return false;
    }


    return true;


  }

  getFields(_lead: LeadModel = this.lead): string[] {
    return Object.keys(_lead);
  }

  getFieldTitle(camelCaseWord: string): string {
    if (camelCaseWord === "customerFirstName") {return "First Name"}
    if (camelCaseWord === "customerLastName") {return "Last Name"}
    if (camelCaseWord === "phoneNumber") {return "Phone"}
    if (camelCaseWord === "fullAddress") {return "Address"}
    if (camelCaseWord === "appointmentDate") {return "Date"}
    if (camelCaseWord === "appointmentTime") {return "Time"}

    return camelCaseToSentenceCase(camelCaseWord);
  }

  addUtilityBillFiles() {
    let dialogRef = this.bottomSheet.open(AddNewModalComponent);

    dialogRef.afterDismissed().subscribe((res: { videos: File[], command: string }) => {
      console.log(res?.videos.length);
      if (res?.videos) this.uploadFiles(res.videos);
    });
  }

  async uploadFiles(files: File[]) {
    for (let file of files) {
      this.uploadFile(file);
    }
  }

  async uploadFile(file: File) {
    const res = await this.incentivesService.saveImage(file, "UTILITY+BILL");
    this.lead.customerUtilityBillUrls.push(res);
    return res;
  }



  fieldIsRequired(field: string): boolean {
    return this.requiredLeadFields().includes(field);
  }

  private requiredLeadFields(): string[] {
    return [
      // "sourceMarket",
      // "customerUtilityBillUrls",
      "customerFirstName",
      "customerLastName",
      "fullAddress",
      // "phoneNumber",
      // "email",
    ]
  }

  getEmployee(field: string): Employee {
    const userId: number = +this.lead[field];
    const output = this.employeeStoreService.getUser(userId);
    // console.log(output);
    return output;
  }

  getNoteEmployee(note: Note) {
    const userId: number = note.authorId;
    const output = this.employeeStoreService.getUser(userId);
    console.log(output);
    return output;
  }


  openSelectSingleUserPickerModal(field: string) {
    let dialogRef: MatDialogRef<UserPickerModalComponent> = this.dialog.open(UserPickerModalComponent, {
      data: { selectSingleUser: true },
      minWidth: "300px",
      maxHeight: "100vh"

    })

    dialogRef.afterClosed().subscribe((res: {userIds: number[]}) => {
      if (!res) { return }
      if (res?.userIds && res.userIds.length > 0) {
        this.lead[field] = res.userIds[0];
      }
    })
  }


  newNote: string = "";


  getDropDownOptions(field: string) {
    let output: string[] = this.leadsService.leadOptionsConfig[field];
    return output ? output : 
    ['Home Owner', 'Good Roof', 'Good Credit' , 'HOA']; //TODO: move to backend and make multiselect
  }

  deleteImage(url: string) {

    const index: number = this.lead.customerUtilityBillUrls.indexOf(url);
    if (index !== -1) {
      this.lead.customerUtilityBillUrls.splice(index, 1);
    }
  }

  humanFriendlyDate(dateString: string): string {
    return genericHumanFriendlyTime(dateString);
  }



  newNoteFromInput() {
    if (!(this.newNote?.length > 0)) return;

    if (this.newNote.length > 1000) {return}

    let note: Note = {
      note: this.newNote,
      authorId: this.userId,
      creationDate: null
      
    }
    this.lead.notes.push(note);
    this.newNote = "";
  }


  getAppointmentDate() {
    return this.lead.appointmentDate
  }


  getAppointmentTime() {
    return this.lead.appointmentTime
  }

  addingNewAppointment: boolean = false;

  newDate: string = "";
  newTime: string = "";
  addAppointment() {
    this.addingNewAppointment = true;
  }

  cancelNewAppointment() {
    this.addingNewAppointment = false
  }

  openCustomerConsent() {
    window.open("http://localhost:8100/customer-consent", "_blank");
  }

}


export class LeadSection {
  title: string;
  fields: string[];
}
