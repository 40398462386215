<ion-toolbar class="search-bar-toolbar">

  <div slot="start">
    <div style="width: 10px;"></div>
  </div>

  <div slot="start" style="width: 100%;">
    <button [ngClass]="{'search': !searchOn , 'search-expanded': searchOn }" (click)="turnOnSearch()">
      <input #searchInput *ngIf="searchOn" [(ngModel)]="searchValue" class="search-input" placeholder="Search..."
        (keydown)="applyFilter($event)" type="text" name="search">
    </button>

  </div>
  

  <div slot="start" *ngIf="searchValue">
    <button (click)="clearSearch()" class="search-bar-button" mat-icon-button>
      <i class="material-icons">
        close
      </i>
    </button>
  </div>
  <div slot="end">
    <div style="width: 5px"></div>
  </div>

  <div slot="end" *ngFor="let icon of selectedIcons">
    <button (click)="handleAction(icon)" style="margin-right: 2px;" class="search-bar-button"
      [ngClass]="{'primary-color': icon.primaryButton}" mat-icon-button>
      <i class="material-icons" *ngIf="icon.hasBadge && filtersObjects"
        matBadgeHidden="{{this.tableFilterService.getFilterCounter(filtersObjects) === 0}}"
        matBadge="{{this.tableFilterService.getFilterCounter(filtersObjects)}}">
        {{icon.icon}}
      </i>

      <i class="material-icons" *ngIf="!icon.hasBadge">
        {{getIcon(icon)}}
      </i>

    </button>
  </div>

  <div slot="end">
    <div style="width: 10px;"></div>
  </div>
</ion-toolbar>

<div style="height: 5px"></div>


<div class="scrolling-wrapper" style="max-width: 1200px; display: block; margin: auto;">
  <div class="scrolling-wrapper-flexbox">
    <div>
      <div style="width: 10px;"></div>
    </div>
    <div>
      <button mat-button style="border-radius: 30px;" class="filter-button" (click)="openFilters()">
        Filters ({{this.tableFilterService.getFilterCounter(filtersObjects)}})
      </button>
    </div>

    <div>
      <div style="width: 5px;"></div>
    </div>

    <div  *ngFor="let filter of selectedFilters" style="margin-right: 10px; " (click)="removeFilter(filter)">
      <button mat-button style="border-radius: 30px;" class="filter-button">
        <i>{{filter.label}}<span style="font-weight: 100;">{{getCorrectFormatForSelectedValue(filter)}}</span></i> <span style="font-size: 14px; margin-left: 7px; margin-bottom: -2px;" class="material-icons iconAlign">
          close
        </span>
      </button>
    </div>

    <div>
      <div style="width: 10px;"></div>
    </div>
  </div>
</div>

<div style="height: 10px"></div>
