<ion-header>
  <div style="width: 100%;">
    <ion-toolbar class="no-padding-header ">
      <div slot="start">
        <button mat-fab
          style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
          (click)="goBack()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </div>
    </ion-toolbar>
  </div>
</ion-header>


<ion-content>
  <ion-toolbar style=" width: 100vw; height: 120px; position: sticky;top: 0;  padding: 3px; z-index: 999; ">
    <div style=" max-width: 1200px; display: block; margin: auto;">

      <div style="text-align: center; font-size: 24px;">{{report?.freshReport.reportName}}</div>

      <div style="height: 20px;"> </div>

      <button class="filter-button" mat-button style="width: 100%;">
        <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
          search
        </i>

        <mat-form-field floatLabel='never' appearance="none"
          style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
          <input class="app-text-color" #searchInput placeholder="Search" style=" font-size: 16px;" matInput
            (keyup)="applyFilter($event)">

        </mat-form-field>

      </button>

    </div>
  </ion-toolbar>


  <div>
    <table mat-table [dataSource]="dataSource" matSort style="background:transparent;">

      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rank </th>
        <td mat-cell *matCellDef="let row" style="font-weight:100 ;"> {{row.rank}} </td>
      </ng-container>

      <ng-container matColumnDef="name" *ngIf="this.tableType === 'normal'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row" style="font-weight:100 ;"> {{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="value" class="value-column">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let row" style="font-weight:100 ;"> {{row.value}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row" style="font-weight:100 ;"> {{row.date}} </td>
      </ng-container>

      <ng-container *ngIf="this.tableType === 'normal'">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </ng-container>

      <ng-container *ngIf="this.tableType === 'company'">
        <tr mat-header-row *matHeaderRowDef="displayedColumns_company"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns_company;"></tr>
      </ng-container>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data found</td>
      </tr>
    </table>
  </div>



</ion-content>