import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatMenuTrigger, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ExpandedImageModalComponent } from 'src/app/messaging/expanded-image-modal/expanded-image-modal.component';
import { OnboardingRestService } from 'src/app/onboarding/onboarding-rest.service';
import { ApplicantData, ApplicantFile, ApplicantNote, BankInfo, ListOfNotes } from 'src/app/onboarding/onboarding-wizard/onboarding-store.service';
import { REST_BASE_URL } from 'src/app/shared/models/constants';
import { BackendUser, Employee } from 'src/app/shared/models/employees.model';
import { AdminDirectoryService } from 'src/app/shared/services/admin-directory.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { MoreInformationModalComponent } from '../more-information-modal/more-information-modal.component';
import { WelcomeEmailModalComponent } from '../welcome-email-modal/welcome-email-modal.component';
import { jsPDF } from "jspdf";
import { NewNoteModalComponent } from 'src/app/shared/components/new-note-modal/new-note-modal.component';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { Clipboard } from '@capacitor/clipboard';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AlertController } from '@ionic/angular';
import { AdditionalDocumentModalComponent } from './additional-document-modal/additional-document-modal.component';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { ApplicantsStoreService } from 'src/app/shared/services/admin-directory-store.service';

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss'],
})
export class ApplicantComponent implements OnInit {

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  applicant: ApplicantData;
  dragAndDrop: boolean = false;
  @Input() shouldShowBottomToolbar: boolean = false;


  primaryDocuments: ApplicantDocument[];
  idDocuments: ApplicantDocument[];

  submittedDate: Date;

  constructor(private dialog: MatDialog,
    public alertController: AlertController,
    private onboardingRestService: OnboardingRestService,
    public snackbar: MatSnackBar,
    public userDetailsService: UserDetailsService,
    public busySpinner: BusySpinnerService,
    public snackbarService: SnackbarService,
    public employeeStoreService: EmployeeStoreService,
    public dateFormatService: DateFormatService,
    public applicantsStoreService: ApplicantsStoreService,
    public dialogRef: MatDialogRef<ApplicantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public adminDirectoryService: AdminDirectoryService) {

    if (this.data?.applicant) {
      this.applicant = this.data.applicant
      this.submittedDate = this.dateFormatService.getDateFromString(this.applicant.insertDateTime)
    }

  }

  ngOnInit() {
    this.initInivitingUser();
    this.initPrimaryDocuments();
    this.initIdDocuments();
    this.attachFirstNote();
  }


  async initInivitingUser() {
    this.invitingUser = null;
    let id = this.applicant.invitation.invitingUserId + "";
    const response: any = await this.adminDirectoryService.getInvitingUserDetails(id);
    this.invitingUser = response;
  }

  initPrimaryDocuments() {
    this.primaryDocuments = this.getPrimaryDocuments();
  }

  initIdDocuments() {
    this.idDocuments = this.getIdDocuments();
  }


  getPrimaryDocuments(): ApplicantDocument[] {
    return [
      {
        title: "User Image",
        url: this.applicant.userImageUrl,
        secureKey: "",
        icon: "image",
        exists: this.applicant.userImageUrl !== null
      },
      {
        title: "Rep Agreement",
        url: this.applicant.repAgreement.repAgreementFileUrl,
        secureKey: "",
        icon: "description",
        exists: this.applicant.repAgreement.repAgreementFileUrl !== null
      },
      
      {
        title: "W9",
        url: "",
        secureKey: 'applicantW9',
        icon: "description",
        exists: this.applicant.w9.w9FileUrl !== null
      }, 
      {
        title: "Background Check",
        url: "",
        secureKey: "applicantBackgroundCheck",
        icon: "description",
        exists: this.applicant.backgroundCheck.backgroundCheckFileUrl !== null
      },
      {
        title: "ID Badge",
        url: "",
        secureKey: "",
        icon: "description",
        exists: this.applicant.userImageUrl !== "" && this.applicant.userId !== null
      },
    ]
  }


  getIdDocuments(): ApplicantDocument[] {
    return [
      {
        title: "Social Security",
        url: "",
        secureKey: "applicantSocialSecurityCard",
        icon: "image",
        exists: this.applicant.identification.socialSecurityCard !== null
      },
      {
        title: "Birth Certificate",
        url: "",
        secureKey: "applicantBirthCertificate",
        icon: "image",
        exists: this.applicant.identification.birthCertificate !== null
      },
      {
        title: "Passport",
        url: "",
        secureKey: "applicantPassport",
        icon: "image",
        exists: this.applicant.identification.passport !== null
      },
      {
        title: "Drivers License",
        url: "",
        secureKey: "applicantDriversLicense",
        icon: "image",
        exists: this.applicant.identification.driversLicense !== null
      }
    ]
  }


  async openDoc(file: ApplicantFile) {
    this.processMiscDocumentAction(file);
  }

  async processMiscDocumentAction(document: ApplicantFile) {
    const sessionKey: string = await this.userDetailsService.getSessionKey();

    const whichEndpointToHit: string = "/applicantFiles/getFile/"

    let url: string = REST_BASE_URL + whichEndpointToHit + sessionKey + "?applicantId=" + document.applicantId + "&url=" + document.file;

    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        pdfUrl: url,
      }
    });
    dialogRef.close();

  }

  toggleDragAndDrop(event) {
    if (this.dragAndDrop) {
      this.dragAndDrop = false;
    } else {
      this.dragAndDrop = true;
    }
  }

  onFileDropped(event, document: ApplicantDocument) {
    this.onFileSelected(event, document);
  }


  processDocumentAction(document: ApplicantDocument) {
    if (!document.exists) { return }
    if (document.title === "ID Badge") {
      this.menuTrigger.openMenu();
      return;
    }

    if (document.url !== "") {
      if (document.icon === "image") {
        this.expandImage(document.url)
      } else if (document.icon === "description") {
        this.expandPdf(document.url);
      }
    }
    else if (document.secureKey !== "") {
      if (document.icon === "image") {
        this.expandSecureImage(document.secureKey)
      } else if (document.icon === "description") {
        this.expandSecurePDF(document.secureKey);
      }

    }

  }


  openNewNoteModal() {
    let dialogRef = this.dialog.open(NewNoteModalComponent, {

      // maxWidth: '100vw !important',
      // width: '100%',
      // height: '100%',

    });


    dialogRef.afterClosed().subscribe(res => {
      if (!res) { return }
      this.addNewNote(this.applicant, res);
    })
  }


  userHasChangedStatus: boolean = false;
  changeStatus(status: string) {
    this.applicant.status = status;
    this.editMode = true;
    this.userHasChangedStatus = true;
  }

  setStatus(status: string) {

    if (status === "Probation") {
      let ref = this.dialog.open(WelcomeEmailModalComponent, {
        minWidth: '300px',
        data: {
          applicant: this.applicant
        }
      })

      ref.afterClosed().subscribe(async result => {
        if (!result) { return }
        await this.adminDirectoryService.createUserFromApplicant(this.applicant.id, result);
      })

    }

    this.adminDirectoryService.setApplicantStatus(this.applicant.id, status, this.applicant.emailAddress).then(res => {
      let success = res.success;
      if (!success) { alert("Unexpected error"); return; }

      this.snackbarService.displaySnackBarMessage("Success")


      this.applicant.status = status;

    })
  }

  expandPdf(pdfUrl: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        pdfUrl: pdfUrl,
      }
    });

    dialogRef.close();
  }

  selectedFile: any;


  onSubmit() {
    const payload = new FormData();
    payload.append("imageFile", this.selectedFile, this.selectedFile.name);
    // this.saveProfilePicture(this.sessionKey, payload);
  }

  downloadBadge(option: string) {
    let ref = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    this.adminDirectoryService.downloadBadge(this.applicant, option).then(res => {
      ref.close();
    });
  }

  onFileSelected(event: any, document: ApplicantDocument) {
    if (document.title === "ID Badge") { return }

    this.selectedFile = event.target ? event.target.files[0] : event[0];
    if (document.icon === "image" && !this.selectedFile.type.includes("image")) {
      this.snackbarService.displaySnackBarMessage("Mismatch type. Please select an image.", false)
      return;
    }

    if (document.icon === "description" && !this.selectedFile.type.includes("pdf")) {
      this.snackbarService.displaySnackBarMessage("Mismatch type. Please select a PDF.", false)
      return;
    }

    this.busySpinner.start();

    if (document.title === "Background Check") {
      this.onboardingRestService.insertBackgroundCheckFile(this.selectedFile, this.applicant.id).then(async res => {
        if (res.success) {
          this.busySpinner.stop();
          document.exists = true;
          document.url = res.message;
          this.applicant.backgroundCheck.backgroundCheckFileUrl = document.url;
          await this.applicantsStoreService.cacheApplicantsList();    

          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
      })
    } else if (document.title === "User Image") {
      this.applicant.userImage = this.selectedFile;
      this.onboardingRestService.updateApplicantUserImage(this.applicant.id, this.selectedFile).then(async res => {
        this.busySpinner.stop();

        if (res.success) {
          this.applicant.userImage = this.selectedFile;
          document.exists = true;
          await this.applicantsStoreService.cacheApplicantsList();    
          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
      })
    }

    else if (document.title === "W9") {
      this.adminDirectoryService.insertW9FromAdmin(this.applicant.id, this.applicant.emailAddress, this.selectedFile).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.w9.w9FileUrl = document.url;
          await this.applicantsStoreService.cacheApplicantsList();    

          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
        console.log(res)
      })
    }
    else if (document.title === "Rep Agreement") {
      this.adminDirectoryService.insertRepAgreementFromAdmin(this.applicant.id, this.applicant.emailAddress, this.selectedFile).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.repAgreement.repAgreementFileUrl = document.url;
          await this.applicantsStoreService.cacheApplicantsList();          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
        console.log(res)
      })
    }
    else if (document.title === "Drivers License") {
      this.adminDirectoryService.insertIdentificationFromAdmin(this.applicant.id, this.applicant.emailAddress, this.selectedFile, null, null, null).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.identification.driversLicense = document.url;
          await this.applicantsStoreService.cacheApplicantsList();          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
        console.log(res)
      })
    }
    else if (document.title === "Birth Certificate") {
      this.adminDirectoryService.insertIdentificationFromAdmin(this.applicant.id, this.applicant.emailAddress, null, this.selectedFile, null, null).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.identification.birthCertificate = document.url;
          await this.applicantsStoreService.cacheApplicantsList();          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
        console.log(res)
      })
    }
    else if (document.title === "Social Security") {
      this.adminDirectoryService.insertIdentificationFromAdmin(this.applicant.id, this.applicant.emailAddress, null, null, this.selectedFile, null).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.identification.socialSecurityCard = document.url;
          await this.applicantsStoreService.cacheApplicantsList();
          this.snackbarService.displaySnackBarMessage("Success", true)
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false)
        }
        console.log(res)
      })
    }
    else if (document.title === "Passport") {
      this.adminDirectoryService.insertIdentificationFromAdmin(this.applicant.id, this.applicant.emailAddress, null, null, null, this.selectedFile).then(async res => {
        this.busySpinner.stop();
        if (res.success) {
          document.exists = true;
          document.url = res.message;
          this.applicant.identification.passport = document.url;
          await this.applicantsStoreService.cacheApplicantsList();

          this.snackbarService.displaySnackBarMessage("Success", true);
        } else {
          this.snackbarService.displaySnackBarMessage("Unexpected Error. Try Again.", false);
        }
        console.log(res)
      })
    }
    else {
      this.busySpinner.stop();
    }


  }

  expandImage(img: string) {
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        image: img,
      }
    });
  }

  openMoreInfoModal(applicant: ApplicantData) {
    let ref = this.dialog.open(MoreInformationModalComponent, {
      minWidth: '300px',
      data: {
        applicant: applicant
      }
    })

    ref.afterClosed().subscribe(res => {

      if (res) {
        this.adminDirectoryService.moreInformationApplicant(applicant.id, applicant.emailAddress, res).then(res => {
        });
      }
    })
  }

  approve(applicant: ApplicantData, email: string) {
    this.adminDirectoryService.approveApplicant(applicant.id, email).then(res => {
      // console.log(res)
    })
  }

  hasEnzyAccount() {
    return this.applicant.userId
  }

  async createEnzyAccount() {
    let ref = this.dialog.open(WelcomeEmailModalComponent, {
      minWidth: '300px',
      data: {
        applicant: this.applicant
      }
    })

    ref.afterClosed().subscribe(async result => {
      if (!result) { return }
      let res = await this.adminDirectoryService.createUserFromApplicant(this.applicant.id, result);

      if (res.success) {
        this.snackbarService.displaySnackBarMessage("Successfully created account for: " + result, true);
      } else {
        this.snackbarService.displaySnackBarMessage("Unable to create account for : " + result, false);
      }

    })
  }


  async expandSecurePDF(secureImageType: string) {
    const url: string = await this.assembleUrlFromCloudUrl(this.applicant, secureImageType);


    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        pdfUrl: url,
      }
    });
    dialogRef.close();
  }





  // WORKING =  "http://localhost:8080/rest/applicantW9/VUATkJFgKr4d22WXaTFp28Xtfuq5jAUH?applicantId=D96J5G62UQ"
  // NOT =      "http://localhost:8080/rest/applicantFiles/getFile//VUATkJFgKr4d22WXaTFp28Xtfuq5jAUH?applicantId=D96J5G62UQ"

  async expandSecureImage(secureImageType: string) {
    const url: string = await this.assembleUrlFromCloudUrl(this.applicant, secureImageType);
    let dialogRef = this.dialog.open(ExpandedImageModalComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        image: url,
      }
    });
  }





  // TODO: improve this just a bit more so that we can get the secure, authenticated url for passports, ssns, etc.
  async assembleUrlFromCloudUrl(applicant: ApplicantData, secureImageType: string): Promise<string> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const applicantId = applicant.id; // TODO: Make this not hard coded
    const whichEndpointToHit: string = "/" + secureImageType + "/"

    let output: string = REST_BASE_URL + whichEndpointToHit + sessionKey + "?applicantId=" + applicantId;

    return output;
  }

  attachFirstNote() {
    if(this.applicant.invitation.note) {
      if(this.applicant.listOfNotes.notes.length >= 1) return;
      const firstNote: ApplicantNote = {
        applicantId: this.applicant.id,
        noteId: '',
        note: this.applicant.invitation.note,
        insertTime: this.applicant.insertDateTime,
        updateTime: this.applicant.insertDateTime,
        authorId: this.applicant.invitation.invitingUserId
      }
      this.applicant.listOfNotes.notes.unshift(firstNote);
    }
    return this.applicant.listOfNotes?.notes
  }


  async addNewNote(applicant: ApplicantData, newNote: string) {
    this.busySpinner.start();
    const response: any = await this.adminDirectoryService.addApplicantNote(applicant.id, newNote);

    const newNotes: ListOfNotes = response.object;
    if (response?.message?.toLowerCase().includes("success")) {
      this.applicant.listOfNotes = newNotes;
      this.newNoteInProgress = false;
      this.newNoteContents = '';
      this.applicantsStoreService.cacheApplicantsList();
    }

    this.busySpinner.stop();
  }

  getUserDescriptionForNote(note: ApplicantNote): string {
    const user = this.employeeStoreService.getUser(note.authorId);
    if (!user) { return "" }

    const authorObject: BackendUser = user.userDto;
    return "by " + authorObject.preferredFirstName;
  }



  newNoteInProgress: boolean = false;
  newNoteContents: string = '';
  startAddingNewNote() {
    this.newNoteContents = '';
    this.newNoteInProgress = true;

  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  invitingUser: Employee;


  async saveApplicantUpdates() {
    if (!this.applicant) return;

    this.busySpinner.start();

    if (this.userHasChangedStatus) {
      this.setStatus(this.applicant.status);
    }

    this.applicant.insertDateTime = this.dateFormatService.convertDateToFormat(this.submittedDate);

    let res = await this.adminDirectoryService.adminInsertApplicantBasicInfo(this.applicant);
    if (!res.success) {
      this.snackbarService.displaySnackBarMessage("Failed to save change");
      this.busySpinner.stop();
      return;
    }


    const bankInfo: BankInfo = this.applicant.bank;
    const id: string = this.applicant.id;
    if (bankInfo) {
      let res = await this.adminDirectoryService.adminInsertBankInfo(bankInfo, id);

      if (!res.success) {
        this.snackbarService.displaySnackBarMessage("Failed to save change");
        this.busySpinner.stop();
        return;
      }
    }

    this.busySpinner.stop();
    this.dialogRef.close(this.applicant)

    this.editMode = false;

  }

  getPhoneNumber() {
    if (this.applicant.phoneNumber[0] === "+" && this.applicant.phoneNumber[1] === "1") {
      return this.applicant.phoneNumber.substring(2, this.applicant.phoneNumber.length - 1)
    }
    else {
      return this.applicant.phoneNumber
    }
  }


  editMode: boolean = false;
  setEditMode() {
    this.editMode = true;
  }

  copyContent(section: string) {
    this.snackbarService.displaySnackBarMessage("Copied " + section, true);

    let contents = "";
    if (section === 'Personal Info') {
      contents +=
        "First Name: " + this.applicant.firstName + "\n" +
        "Last Name: " + this.applicant.lastName + "\n" +
        "Address: " + this.applicant.address + "\n" +
        "State: " + this.applicant.state + "\n" +
        "Zip: " + this.applicant.zip + "\n" +
        "Team: " + this.applicant.officeLocation + "\n" +
        "Phone Number: " + this.applicant.phoneNumber + "\n" +
        "Email Address: " + this.applicant.emailAddress + "\n"
    }


    else if (section === 'Banking Info') {
      contents +=
        "Account Holder Name: " + this.applicant.bank.accountHolderName + "\n" +
        "Account Number: " + this.applicant.bank.accountNumber + "\n" +
        "Account Type: " + this.applicant.bank.accountType + "\n" +
        "ACH Routing Number: " + this.applicant.bank.achRoutingNumber + "\n" +
        "Bank Name: " + this.applicant.bank.bankName + "\n" +
        "Wire Routing Number: " + this.applicant.bank.wireRoutingNumber + "\n"
    }


    else if (section === 'Clothing Size') {
      contents +=
        "Shirt Size: " + this.applicant.apparel.shirtSize + "\n" +
        "Hat Size: " + this.applicant.apparel.hatSize + "\n" +
        "Jacket Size: " + this.applicant.apparel.jacketSize + "\n" +
        "Pant/Short Size: " + this.applicant.apparel.pantShortSize + "\n" +
        "Waist Size: " + this.applicant.apparel.waistSize + "\n"

    }

    Clipboard.write({
      string: contents
    });

  }




  async goBack() {
    this.dialogRef.close();
  }


  async addAdditionalDocument() {

    let dialogRef = this.dialog.open(AdditionalDocumentModalComponent);

    dialogRef.afterClosed().subscribe(async (applicantFile: ApplicantFile) => {
      if (!applicantFile) { return }

      applicantFile.applicantId = this.applicant.id;

      this.busySpinner.start();
      let result = await this.adminDirectoryService.addApplicantFile(applicantFile);
      console.log(result)
      this.busySpinner.stop();

      this.snackbarService.displaySnackBarMessage(result.message);

      if (result.message.includes("Success")) {
        applicantFile.file = result.object;
        this.applicant.listOfFiles.files.push(applicantFile)
      }
    })

  }

}

export class ApplicantDocument {
  title: string;
  url: string;
  secureKey: string;
  icon: string;
  exists: boolean;
}


//Status: 
// - Submitted
// - Probation
// - Accepted 
// - Denied