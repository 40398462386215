import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';

import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable()
export class ThemeService {

  constructor(private localStorage: Storage, private platform: Platform,
  ) {

  }

  get theme(): string {
    return document.documentElement.getAttribute('theme');
  }

  set theme(name: string) {
    document.documentElement.setAttribute('theme', name);
  }

  setTheme(name: string) {

    
    this.localStorage.set('themeSelection', name);


    if (name.includes('freedom')) {
      this.primaryColor = "#3fae2a";
    }
    else if (name.includes('seahawks')) {
      this.primaryColor = "#8abe51";
    }
    else if (name.includes('osu')) {
      this.primaryColor = "#cf073a";
    }
    else if (name.includes('larry-miller')) {
      this.primaryColor = "#c00";
    }
    else if (name.includes('enzy')) {
      this.primaryColor = "#E400AE";
    }
    else if (name.includes('sb')) {
      this.primaryColor = "#ccff00";
    }
    else {
      this.primaryColor = "#00BCE1";
    }

    if (name.includes("dark")) {
      if (!(this.platform.is("mobileweb")||this.platform.is("desktop"))) {
        StatusBar.setStyle({style: Style.Dark})
      }

      if (this.platform.is("android")) {
        StatusBar.setBackgroundColor({color: "#404c58"})
      }


    }
    else {
      if (!(this.platform.is("mobileweb")||this.platform.is("desktop"))) {
        StatusBar.setStyle({style: Style.Light})
      }

      if (this.platform.is("android")) {
        StatusBar.setBackgroundColor({color: "#f2f2f2"})
      }

    }


    document.documentElement.setAttribute('theme', name);
  }

  primaryColor: string = "#00BCE1";
}