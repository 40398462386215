import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardData } from '../models/dashboard.model';
import { ReportsService } from './reports.service';
import { Storage } from "@ionic/storage";
import { FreshReportService } from './fresh-report.service';
import { FreshReportResponse } from '../models/fresh-report.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { MatDialog } from '@angular/material';
import { BusySpinnerComponent } from '../components/busy-spinner/busy-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class CompanyRecordsStoreService {

  private _companyRecords_my: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly companyRecords_my: Observable<DashboardData[]> = this._companyRecords_my.asObservable();

  private _companyRecords_individual: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly companyRecords_individual: Observable<DashboardData[]> = this._companyRecords_individual.asObservable();


  private _companyRecords_team: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly companyRecords_team: Observable<DashboardData[]> = this._companyRecords_team.asObservable();


  private _companyRecords_region: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly companyRecords_region: Observable<DashboardData[]> = this._companyRecords_region.asObservable();


  private _companyRecords_company: BehaviorSubject<DashboardData[]> = new BehaviorSubject([]);
  public readonly companyRecords_company: Observable<DashboardData[]> = this._companyRecords_company.asObservable();




  constructor(private dialog: MatDialog, private freshReportService: FreshReportService, private localStorage: Storage) { }


  /*
   Initialize the company records
  */
  async initializeCompanyRecords() {
    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    await this.loadCachedCompanyRecords();
    await this.fetchLatestRecordsFromBackend();

    this.dialog.closeAll();
  }

  

  /*
   Fetch records from the database
  */
  async fetchLatestRecordsFromBackend() {

    const result: any = await this.freshReportService.getFreshReports();
    this.extractIncentivesFromResponse(result.list);
    this.cacheCompanyRecords();
  }

  private extractIncentivesFromResponse(res: any) {
    const tempCompanyRecords_my: DashboardData[] = [];
    const tempCompanyRecords_individual: DashboardData[] = [];
    const tempCompanyRecords_team: DashboardData[] = [];
    const tempCompanyRecords_region: DashboardData[] = [];
    const tempCompanyRecords_company: DashboardData[] = [];

    res.forEach(async element => {
      if (element.reportCategory === "Records") {
        let record: DashboardData = {
          freshReport: {
            id: element.id,
            objectIndex: 0,
            reportName: element.reportName,
            stratField: "",
            sortName: element.sortName,
            sortDesc: element.sortDesc,
            shareType: "",
            companyId: element.companyId,
            userId: element.userId,
            isEditable: element.isEditable,
            viewType: element.viewType,
            isFavorite: element.isFavorite,
            reportCategory: element.reportCategory,
            reportId: element.reportId,
          },
          reportMenu: null,
          reportOrder: 0,
          hasBeenSaved: true,
          viewFilter: "",
          reportCellList: null
        }
        await this.loadReportData(record);
        if (element.subcategory === "Individual Records") {
          tempCompanyRecords_individual.push(record);
        } else if (element.subcategory === "Team Records") {
          tempCompanyRecords_team.push(record);
        }
        else if (element.subcategory === "Regional Records") {
          tempCompanyRecords_region.push(record);
        }
        else if (element.subcategory === "Company Records") {
          tempCompanyRecords_company.push(record);
        }
        else if (element.subcategory === "My Records") {
          tempCompanyRecords_my.push(record);
        }

      }
    });
    this._companyRecords_individual.next(tempCompanyRecords_individual);
    this._companyRecords_team.next(tempCompanyRecords_team);
    this._companyRecords_region.next(tempCompanyRecords_region);
    this._companyRecords_company.next(tempCompanyRecords_company);
    this._companyRecords_my.next(tempCompanyRecords_my);



  }

  private async loadReportData(report: DashboardData) {
    const cellList: ReportCellList = await this.freshReportService.getReportCellListByReportId(report.freshReport.id);
    report.reportCellList = cellList
    report.reportMenu = cellList.menu;
    report.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(report.freshReport?.viewType)
  }




  /*
    Load from local storage
  */
  private async loadCachedCompanyRecords(): Promise<DashboardData[]> {
    //INDIVIDUAL
    const currentListOfCompanyRecords_individual: DashboardData[] = this._companyRecords_individual.getValue();

    if (currentListOfCompanyRecords_individual?.length > 0) return;
    const res1 = await this.localStorage.get("COMPANY_RECORDS_LIST_INDIVIDUAL");
    if (!res1) return;

    const cachedListOfCompanyRecords_individual: DashboardData[] = JSON.parse(res1);

    if (cachedListOfCompanyRecords_individual?.length > 0) {
      this._companyRecords_individual.next(cachedListOfCompanyRecords_individual);
    }



    //TEAM
    const currentListOfCompanyRecords_team: DashboardData[] = this._companyRecords_team.getValue();

    if (currentListOfCompanyRecords_team?.length > 0) return;
    const res2 = await this.localStorage.get("COMPANY_RECORDS_LIST_TEAM");
    if (!res2) return;

    const cachedListOfCompanyRecords_team: DashboardData[] = JSON.parse(res2);

    if (cachedListOfCompanyRecords_team?.length > 0) {
      this._companyRecords_team.next(cachedListOfCompanyRecords_team);
    }


    //REGION
    const currentListOfCompanyRecords_region: DashboardData[] = this._companyRecords_region.getValue();

    if (currentListOfCompanyRecords_region?.length > 0) return;
    const res3 = await this.localStorage.get("COMPANY_RECORDS_LIST_REGION");
    if (!res3) return;

    const cachedListOfCompanyRecords_region: DashboardData[] = JSON.parse(res3);

    if (cachedListOfCompanyRecords_region?.length > 0) {
      this._companyRecords_region.next(cachedListOfCompanyRecords_region);
    }


    //COMPANY
    const currentListOfCompanyRecords_company: DashboardData[] = this._companyRecords_company.getValue();

    if (currentListOfCompanyRecords_company?.length > 0) return;
    const res4 = await this.localStorage.get("COMPANY_RECORDS_LIST_COMPANY");
    if (!res4) return;

    const cachedListOfCompanyRecords_company: DashboardData[] = JSON.parse(res4);

    if (cachedListOfCompanyRecords_company?.length > 0) {
      this._companyRecords_company.next(cachedListOfCompanyRecords_company);
    }

     //COMPANY
     const currentListOfCompanyRecords_my: DashboardData[] = this._companyRecords_my.getValue();

     if (currentListOfCompanyRecords_my?.length > 0) return;
     const res5 = await this.localStorage.get("COMPANY_RECORDS_LIST_MY");
     if (!res5) return;
 
     const cachedListOfCompanyRecords_my: DashboardData[] = JSON.parse(res5);
 
     if (cachedListOfCompanyRecords_my?.length > 0) {
       this._companyRecords_my.next(cachedListOfCompanyRecords_my);
     }


    // console.log("FINISHED LOADING COMPANY RECOREDS FROM LOCAL STORAGE");
  }



  /*
    Save the results to localStorage (in a map?)
  */
  private async cacheCompanyRecords(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("COMPANY_RECORDS_LIST_INDIVIDUAL", JSON.stringify(this._companyRecords_individual.getValue()));
      this.localStorage.set("COMPANY_RECORDS_LIST_TEAM", JSON.stringify(this._companyRecords_team.getValue()));
      this.localStorage.set("COMPANY_RECORDS_LIST_REGION", JSON.stringify(this._companyRecords_region.getValue()));
      this.localStorage.set("COMPANY_RECORDS_LIST_COMPANY", JSON.stringify(this._companyRecords_company.getValue()));

    }, 1000);
  }


}
