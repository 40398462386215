import { Component, Input, OnInit } from '@angular/core';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { Note } from '../leads.model';

@Component({
  selector: 'app-note-card',
  templateUrl: './note-card.component.html',
  styleUrls: ['./note-card.component.scss'],
})
export class NoteCardComponent implements OnInit {

  @Input() note: Note;

  constructor(public employeeStoreService: EmployeeStoreService) { }

  ngOnInit() {}


  getNoteEmployee(note: Note) {
    const userId: number = note.authorId;
    const output = this.employeeStoreService.getUser(userId);
    return output;
  }

  humanFriendlyDate(dateString: string): string {
    if (!dateString) {return ""}
    return genericHumanFriendlyTime(dateString);
  }
}
