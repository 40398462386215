import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonRouterOutlet } from '@ionic/angular';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { ReportCellList } from 'src/app/shared/models/report-cell-list.model';

@Component({
  selector: 'app-record-card',
  templateUrl: './record-card.component.html',
  styleUrls: ['./record-card.component.scss'],
})
export class RecordCardComponent implements OnInit {

  @Input() report: DashboardData;

  constructor(public routerOutlet: IonRouterOutlet, public router: Router, private nav: HeaderService) { }

  ngOnInit() {}

 
  getFirstPlace() {
    if (!this.report.reportCellList.list) {return}
    return this.report.reportCellList?.list[0]?.cellList[0].value
  }
  getFirstPlaceValue() {
    if (!this.report.reportCellList?.list[0]?.cellList[2]) {
      if (!this.report.reportCellList?.list[0]?.cellList[1]) {return "0" }
      return this.report.reportCellList?.list[0]?.cellList[1].value
    }
    return this.report.reportCellList?.list[0]?.cellList[2].value
  }

  getReportName() {
    return this.report.freshReport.reportName;
  }

  openRecordCard() {
    this.routerOutlet.swipeGesture = true;

    this.router.navigateByUrl('/record-detail', { state: { report: this.report } })

  }

  getBackgroundImage() {
    let image = this.report.reportCellList?.list[0]?.cellList[0].imageUrl;
    if (!image) {
      image = "../../../assets/install.jpg";
    }
    return image;
  }

}
