<ion-grid>
<ion-row>
  <ion-col size="6">
    <button mat-button (click)="newIncentive('competition')">Competition</button>
  </ion-col>
  <ion-col size="6">
    <button mat-button (click)="newIncentive('milestone')">Milestone</button>
  </ion-col>
</ion-row>
</ion-grid>
