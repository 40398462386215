
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-expanded-image-modal',
  templateUrl: './expanded-image-modal.component.html',
  styleUrls: ['./expanded-image-modal.component.scss'],
})
export class ExpandedImageModalComponent implements OnInit {

  img: string = "";
  video: string = "";
  
  constructor(public sanitizer:  DomSanitizer, public dialogRef: MatDialogRef<ExpandedImageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)

    if (this.data.pdfUrl){
      window.open(this.data.pdfUrl, "_blank");

    }
  }


  zoomProperties = {
    "double-tap": true, // double tap to zoom in and out.
    "overflow": "hidden",// Am not sure. But Documentation says, it will not render elements outside the container.
    "wheel": true, // Disables mouse - To enable scrolling. Else mouse scrolling will be used to zoom in and out on web.
    "disableZoomControl": "enable", // stops showing zoom + and zoom - images.
    "backgroundColor": "rgba(0,0,0,0)" // Makes the pinch zoom container color to transparent. So that ionic themes can be applied without issues.
   }

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }



  getPdfUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:8080/rest/applicantW9/VUATkJFgKr4d22WXaTFp28Xtfuq5jAUH/embed/U8YN3SEWPQ");

  }
  

}

