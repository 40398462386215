<div class="knock-modal-main">

  <p>
    {{printAddress()}}
  </p>

  <ion-toolbar>
    <button 
      slot="start"
      mat-button
      (click)="delete()">
    delete
    </button>
    <button 
      slot="end"
      mat-button
      class="primary-color" 
      (click)="save()">
    Save
    </button>
  </ion-toolbar>
  <p *ngIf="this.pin?.knockedDoor?.knocks">
    <enzy-tally-tracker 
      [key]="getTallyKey()" 
      [setList]="this.pin?.knockedDoor.knocks" 
      (valueChanged)="handleValueChanged(getTallyKey(), $event)"></enzy-tally-tracker>
  </p>
  
  <div style="height: 20px;"></div>
  
  <div *ngIf="showOptions()" class="scrolling-wrapper-flexbox">
    <div *ngFor="let option of knockStatusOptions()" >
      <button 
      class="filter-button "
      mat-button
      (click)="changeStatus(option)" 
      [ngClass]="{'highlighted': (this.optionIsSelected(option)) }">
        {{option}}
      </button>
    </div>
  
  </div>
  <div *ngIf="!showOptions()" >
    <button
      mat-button
      class="filter-button highlighted"
    >
    {{pin?.knockedDoor.knockStatus}}
    </button>
  </div>
  
  <div style="height: 20px;"></div>
  
  <button mat-button (click)="createLead()" class="fadedButton">
    Create Lead
  </button>
  
  <button mat-button (click)="createProject()" class="primary-color">
    Create Project
  </button>
</div>