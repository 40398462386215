import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { REST_BASE_URL } from "../shared/models/constants";
import { Storage } from "@ionic/storage";
import { ApplicantData, BankInfo, ClothingInfo, Invitation, W9Info } from "./onboarding-wizard/onboarding-store.service";
import { UserDetailsService } from "../shared/services/user-details.service";

@Injectable({
    providedIn: 'root'
})
export class OnboardingRestService {

    public onboardingSessionKey: string = '';

    constructor(public http: HttpClient, public userDetailsService: UserDetailsService, public localStorage: Storage) {

    }

    public async getApplicant() :Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/applicant/' + sessionKey;
        return this.http.get<any>(url).toPromise();
    }

    public insertEmailAddress(emailAddress: string): Promise<any> {
        const url = REST_BASE_URL + '/insertApplicantEmail';

        let body = new FormData();
        body.append('emailAddress', '' + emailAddress);

        return this.http.post<any>(url, body).toPromise();
    }

    public async insertPhoneNumber(phoneNumber: string): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantPhone/' + sessionKey;

        let body = new FormData();
        body.append('phoneNumber', phoneNumber + '');
        return this.http.post<any>(url, body).toPromise();
    }

    public async insertBackgroundCheckFile(backgroundCheckFile: File, applicantId: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const url = REST_BASE_URL + '/insertApplicantBackgroundCheck/' + sessionKey;
        let body = new FormData();
        if (backgroundCheckFile) {
            body.append('applicantId', applicantId);
            body.append('backgroundCheckFile', backgroundCheckFile, backgroundCheckFile?.name);
        }

        return this.http.post<any>(url, body).toPromise();
    }



    public async insertBackgroundCheck(hasCompletedBackgroundCheck: boolean): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantBasicInfo/' + sessionKey + '/backgroundCheck';
        let body = new FormData();
        body.append('hasCompletedBackgroundCheck', new Boolean(hasCompletedBackgroundCheck).toString());
        return this.http.post<any>(url, body).toPromise();
    }

    public async updateApplicantUserImage(applicantId: string, imageFile: File): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const url = REST_BASE_URL + '/insertApplicantUserImage/' + sessionKey;

        let body = new FormData();

        body.append('imageFile', imageFile);
        body.append('applicantId', applicantId);
        

        return this.http.post<any>(url, body, {responseType: "json"}).toPromise();
    }

    public async insertBasicInformation(applicantData: ApplicantData, sKey: string = null): Promise<any> {
        let sessionKey: string = "";
        if (sKey === null) {
             sessionKey = await this.getOnboardingSessionKey();
        }
        else {
            sessionKey = sKey
        }

        const url = REST_BASE_URL + '/insertApplicantBasicInfo/' + sessionKey;

        let body = new FormData();

        body.append('firstName', applicantData.firstName ? applicantData.firstName : "");
        body.append('lastName', applicantData.lastName ? applicantData.lastName : "");
        body.append('officeLocation', applicantData.officeLocation ? applicantData.officeLocation : "");
        body.append('address', applicantData.address ? applicantData.address : "");
        body.append('city', applicantData.city ? applicantData.city : "");
        body.append('state', applicantData.state ? applicantData.state : "");
        body.append('zip', applicantData.zip ? applicantData.zip : "");
        body.append('dateOfBirth', applicantData.dateOfBirth ? applicantData.dateOfBirth : "");
        if (applicantData.userImage) {
            body.append('userImage', applicantData.userImage , applicantData.userImage.name);
        }

        return this.http.post<any>(url, body).toPromise();
    }

    public async insertW9Info(info: W9Info): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantW9/' + sessionKey;

        let body = new FormData();

        body.append('name', info.name ? info.name : "");
        body.append('businessName', info.businessName ? info.businessName : "");
        body.append('employmentId', info.employmentId ? info.employmentId : "");
        body.append('fedTaxClass', info.federalTaxClassification ? info.federalTaxClassification : "");
        body.append('ssn', info.soc ? info.soc : "");


        body.append('address', info.address ? info.address : "");
        body.append('city', info.city ? info.city : "");
        body.append('state', info.state ? info.state : "");
        body.append('zip', info.zip ? info.zip : "");
        body.append('type', info.type ? info.type : "");
        

        body.append('exemptions', info.exemptions ? info.exemptions : "");
        body.append('exemption1', info.exemption1 ? info.exemption1 : "");
        body.append('exemption2', info.exemption2 ? info.exemption2 : "");
        body.append('otherClassification', info.otherClassification ? info.otherClassification : "");


        return this.http.post<any>(url, body).toPromise();
    }

    public async insertBankInfo(info: BankInfo): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantBank/' + sessionKey;

        let body = new FormData();

        body.append('accountHolderName', info.accountHolderName ? info.accountHolderName : "");
        body.append('bankName', info.bankName ? info.bankName : "");
        body.append('accountType', info.accountType ? info.accountType : "");
        body.append('achRoutingNumber', info.achRoutingNumber ? info.achRoutingNumber + "" : "");
        body.append('accountNumber', info.accountNumber ? info.accountNumber + "" : "");
        body.append('wireRoutingNumber', info.wireRoutingNumber ? info.wireRoutingNumber + "" : "");
        body.append('imageFile', info.imageFile ? info.imageFile : "");
        body.append('type', info.type ? info.type : "");
        body.append('hasAgreedToDirectDeposit', info.hasAgreedToDirectDeposit ? info.hasAgreedToDirectDeposit.toString() : "");

        return this.http.post<any>(url, body).toPromise();
    }

    public async insertClothingInfo(info: ClothingInfo ): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantApparel/' + sessionKey;

        let body = new FormData();

        body.append('hatSize', info.hatSize ? info.hatSize : "");
        body.append('jacketSize', info.jacketSize ? info.jacketSize : "");
        body.append('pantShortSize', info.pantShortSize ? info.pantShortSize : "");
        body.append('shirtSize', info.shirtSize ? info.shirtSize : "");
        body.append('waistSize', info.waistSize ? info.waistSize : "");
        body.append('poloShirtSize', info.poloShirtSize ? info.poloShirtSize : "");
        body.append('shoeSize', info.shoeSize ? info.shoeSize : "");
        return this.http.post<any>(url, body).toPromise();
    }


    public async insertApplicantwW9PDF(w9: File ): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantW9File/' + sessionKey;

        let body = new FormData();
        if (w9) {
            body.append('w9File', w9, w9?.name);
        }
       

        return this.http.post<any>(url, body).toPromise();
    }



    public async insertApplicantRepAgreementPDF(repAgreement: File ): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantRepAgreementFile/' + sessionKey;
        let body = new FormData();
        if (repAgreement) {
            body.append('repAgreementFile', repAgreement, repAgreement?.name);
        }
       

        return this.http.post<any>(url, body).toPromise();
    }


    public async insertApplicantIdentification(license: File, bc: File, sc: File, pp: File ): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/insertApplicantIdentification/' + sessionKey;

        let body = new FormData();
        if (license) {
            body.append('driversLicense', license, license?.name);
        }
        if (bc) {
            body.append('birthCertificate', bc, bc?.name);
            
        }
        if (sc) {
            body.append('socialSecurityCard', sc, sc?.name);
            
        }
        if (pp) {
            body.append('passport', pp, pp?.name);
        }

        return this.http.post<any>(url, body).toPromise();
    }

    // ****** VERIFICATION CONTROLLERS ******

    public async verifyEmailCode(code: string): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/verifyApplicantEmail/' + sessionKey;

        let body = new FormData();
        body.append('emailVerificationCode', code);

        return this.http.post<any>(url, body).toPromise();
    }

    public async verifyPhoneCode(code: string): Promise<any> {
        const sessionKey: string = await this.getOnboardingSessionKey();
        const url = REST_BASE_URL + '/verifyApplicantPhone/' + sessionKey;

        let body = new FormData();
        body.append('phoneVerificationCode', code);

        return this.http.post<any>(url, body).toPromise();
    }

    // ****** SEND INVITE CONTROLLER ******
    public async sendInvitation(invitation: Invitation): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const url = REST_BASE_URL + '/sendInvitation/' + sessionKey;

        let body = new FormData();
        
        body.append('firstName', invitation.firstName);
        body.append('lastName', invitation.lastName);
        body.append('emailAddress', invitation.emailAddress);
        body.append('phoneNumber', invitation.phoneNumber);
        body.append('officeLocation', invitation.team);
        body.append('scar', invitation.scarProgram+"");
        body.append('note', invitation.note);
        body.append('recruiterId', invitation.recruiterId.toString());

        return this.http.post<any>(url, body).toPromise();
    }

    // ****** SESSION KEY MANAGEMENT ******

    // ****** CACHING ******
    async ensureStorageLoaded(): Promise<void> {
        this.onboardingSessionKey = await this.localStorage.get("ONBOARDING_SESSION_KEY");
    }

    cacheData(): void {
        this.localStorage.set("ONBOARDING_SESSION_KEY", this.onboardingSessionKey);
    }

    // ****** GETTERS ******
    async getOnboardingSessionKey(): Promise<string> {
        if (this.onboardingSessionKey) { return this.onboardingSessionKey }
        else {
            await this.ensureStorageLoaded;
            return this.onboardingSessionKey;
        }
    }

    // ****** SETTERS ******
    public setOnboardingSessionKey(key: string): void {
        this.onboardingSessionKey = key;
        this.cacheData();
    }
}

