import * as am4charts from "@amcharts/amcharts4/charts";
import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from "@ionic/angular";
import { BusySpinnerComponent } from "src/app/shared/components/busy-spinner/busy-spinner.component";
import { adamLog } from "src/app/shared/generic-functions";
import { Employee } from "src/app/shared/models/employees.model";
import { Incentive, TwoTeamMatchup, RegularSeasonRound } from "src/app/shared/models/incentives.model";
import { EmployeeStoreService } from "src/app/shared/services/employee-store.service";
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { getMatchupIds, getTeamsByMatchup, getUsersForTeam, HeatRound, IncentivesService, MatchupParticipant, TournamentModel, TournamentRound } from "src/app/shared/services/incentives.service";
import { MessageModel } from 'src/app/shared/services/messaging.service';
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { UserDetailsService } from "src/app/shared/services/user-details.service";
import { HeaderService } from '../../shared/components/my-header/header.service';



@Component({
  selector: 'app-game-detail',
  templateUrl: './game-detail.component.html',
  styleUrls: ['./game-detail.component.scss'],
})
export class GameDetailComponent implements OnInit {
  private readonly RULES_SECTION: number = 0;
  private readonly REGULAR_SEASON_SECTION: number = 1;
  private readonly PLAYOFF_SECTION: number = 2;
  private readonly CHAMPIONSHIP_SECTION: number = 3;

  // messages: MessageModel[];

  rounds: RegularSeasonRound[] = [];
  championship: HeatRound;
  rules: string = "";
  rulesImage: string;
  incentive: TournamentModel;

  currentRound: string = "";

  selectedSection: number = this.REGULAR_SEASON_SECTION;
  selectedRound: number = 0;

  accessLevel: number = 0;
  userId: string;


  constructor(
    private activatedRoute: ActivatedRoute, 
    public router: Router,
    private navCtrl: NavController,
    public userDetailsService: UserDetailsService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public platform: Platform,
    public employeeStoreService: EmployeeStoreService,
    public snackbarService: SnackbarService,
    public incentivesService: IncentivesService) {


 
    if (history.state.incentive) {
      this.incentive = history.state.incentive;
      // console.log(this.incentive)
    } else {
      this.activatedRoute.queryParams.subscribe(params => {
        let id: number = +params['id'];
        this.loadIncentives(id);

      });
    }
    this.initAccessLevel();
 

  }

  private async initAccessLevel() {
    this.accessLevel = await this.userDetailsService.getAccessLevel();
    this.userId = await this.userDetailsService.getUserId() + "";
  }

  async loadIncentives(id: number) {
    if (id === NaN) return;
    const res = await this.incentivesService.getTournamentDetails(id);
    this.incentive = res;
    this.rounds = res?.rounds;
    this.championship = res?.championshipRound;
    this.currentRound = res?.currentRound;
    this.setupCurrentRound();
    this.rules = res?.rulesDescription;
    if (res?.rulesUrl) {
      this.rulesImage = res.rulesUrl;
    }

  }

  setupCurrentRound() {
    this.rounds.forEach((round, index) => {
      if (this.currentRound?.includes(round.roundName)) {
        this.selectedRound = index;
      }
    })
  }

  ionViewWillLeave() {

  }

  spinnerRef: any;
  ionViewWillEnter() {


  }

  ngOnInit() {
    this.spinnerRef = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    const tournamentId: number = +this.incentive?.id ? +this.incentive.id : null; // TODO: MAKE THIS NOT HARD-CODED
    if (tournamentId !== null) this.loadIncentives(tournamentId);
    this.spinnerRef.close();

  }


  sliderOnChange(num: any) {
  }



  goBack() {
      this.navCtrl.pop();
   
  }

  goForward() {
    this.router.navigateByUrl('/game-detail-timeline', { state: { incentive: this.incentive } });
  }



  switchToRules(): void {
    this.selectedSection = this.RULES_SECTION;
  }

  switchToRegularSeason(round: number): void {
    let roundSelected = this.rounds[round];
    if (!roundSelected.isActive) {
      this.snackbarService.displaySnackBarMessage("This Round is currently locked.", false);
      return
    }
    this.selectedSection = this.REGULAR_SEASON_SECTION;
    this.selectedRound = round;

  }

  switchToPlayoffs(): void {
    this.selectedSection = this.PLAYOFF_SECTION;
  }

  switchToChampionship(): void {
    // if (!this.currentRound.includes("Championship")) {
    //   this.snackbarService.displaySnackBarMessage("This Round is currently locked.", false); 
    //   return
    // }

    this.selectedSection = this.CHAMPIONSHIP_SECTION;
  }

  showRules(): boolean {
    return this.selectedSection === this.RULES_SECTION;
  }

  showRegularSeason(): boolean {
    return this.selectedSection === this.REGULAR_SEASON_SECTION;
  }

  showPlayoffs(): boolean {
    return this.selectedSection === this.PLAYOFF_SECTION;
  }

  showChampionship(): boolean {
    return this.selectedSection === this.CHAMPIONSHIP_SECTION;
  }

  getSelectedGames(): TwoTeamMatchup[] {
    return this.rounds[this.selectedRound]?.matchups;
  }

  getCurrentMatchIds(): number[] {
    if (!this.incentive?.rounds) return;
    return getMatchupIds(this.incentive?.rounds[this.selectedRound]);
  }

  getTeamsForMatchup(matchId: number): number[] {
    if (!this.incentive?.rounds) return;
    return getTeamsByMatchup(this.incentive?.rounds[this.selectedRound], matchId);
  }

  alertUsers(teamId: number): void {
    const participants: MatchupParticipant[] = getUsersForTeam(this.incentive?.rounds[this.selectedRound], teamId);
    adamLog(participants?.length)
    adamLog(JSON.stringify(participants));
    alert(this.getUsers(teamId).map(e => JSON.stringify(e)).join('\n'));
  }

  getUsers(teamId: number): Employee[] {
    const participants: MatchupParticipant[] = getUsersForTeam(this.incentive?.rounds[this.selectedRound], teamId);
    return participants.map(id => this.employeeStoreService.getUser(id.userId));
  }

  gameFromMatchupId(matchupId: number): TwoTeamMatchup {
    // get teams by matchup
    const teamIds: number[] = this.getTeamsForMatchup(matchupId);
    if (teamIds.length !== 2) return null;
    const team1Id: number = teamIds[0];
    const team2Id: number = teamIds[1];
    const round: TournamentRound = this.incentive?.rounds[this.selectedRound]

    const team1Name: string = round.teamNamesMap[team1Id];
    const team2Name: string = round.teamNamesMap[team2Id];
    const team1Score: number = round.teamScoresMap[team1Id];
    const team2Score: number = round.teamScoresMap[team2Id];
    const team1Url: string = round.teamImagesMap[team1Id];
    const team2Url: string = round.teamImagesMap[team2Id];
    return new TwoTeamMatchup(
      team1Name,
      team2Name,
      team1Score,
      team2Score,
      team1Url,
      team2Url,
    )
  }

  alertParticipant(p) {
    alert(JSON.stringify(p?.sunderScoreMetric))
  }

  getTeam1Participants(matchupId: number): MatchupParticipant[] {
    const teamIds: number[] = this.getTeamsForMatchup(matchupId);
    if (teamIds.length !== 2) return [];
    const team1Id: number = teamIds[0];
    const round: TournamentRound = this.incentive?.rounds[this.selectedRound]
    return round.teamsToUserMap[team1Id]
  }

  getTeam2Participants(matchupId: number): MatchupParticipant[] {
    const teamIds: number[] = this.getTeamsForMatchup(matchupId);
    if (teamIds.length !== 2) return [];
    const team2Id: number = teamIds[1];
    const round: TournamentRound = this.incentive?.rounds[this.selectedRound]
    return round.teamsToUserMap[team2Id]
  }

  getParticipantImage(participant: MatchupParticipant): string {
    const employee: Employee = this.employeeStoreService.getUser(participant.userId);
    if (!employee) return null;
    return employee.userDto.imageThumbnailUrl;
  }

  getParticipantName(participant: MatchupParticipant): string {
    const employee: Employee = this.employeeStoreService.getUser(participant.userId);
    if (!employee) return null;
    return employee.userDto.firstName + " " + employee.userDto.lastName;
  }

  getSelectedRoundPrizeImage() {
    let img = this.rounds[this.selectedRound]?.roundPrizeImage ? this.rounds[this.selectedRound]?.roundPrizeImage : "../../../assets/placeholder-image.png"
    return img;
  }

  editTournament(id: number, event: any = null) {
    if (event) {
      event.stopPropogation();
    }
    this.router.navigateByUrl("incentives/edit-tournament?id=" + this.incentive.id);
  }

  userCanEditTournament(id: number): boolean {
    // return true;
    return this.userId === '489' || this.userId === '19' || this.userId === '1610'
    return this.accessLevel >= 10;
  }

}
