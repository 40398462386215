export enum StratFieldEnum {
    SalesRepRegion = "object000_text013.value",
    SalesRepId = "object001.id",

    SalesRepName = "object000_text009.value",
    SaleDateMonth = "LEFT(object000_date000.value, 7)",
    SalesRepTeam = "object000_text012.value",
    SalesRep = "object000_text010.value",
    Company = "company.companyName",

    LeadSource ="object000_text007.value",

  }