import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AbstractLoadsReportDataComponent } from '../../shared/abstract-classes/abstract-saves-report';
import { AbstractStoresReportInfo } from '../../shared/abstract-classes/abstract-stores-report-info';
import { HeaderService } from '../../shared/components/my-header/header.service';
import { DataReportMenu } from '../../shared/models/dashboard-report-list.model';
import { DashboardData, UserData } from '../../shared/models/dashboard.model';
import { ReportCellList } from '../../shared/models/report-cell-list.model';
import { DashboardService } from '../../shared/services/dashboard.service';
import { FreshReportService } from '../../shared/services/fresh-report.service';
import { ReportDataPoint, ReportsService } from '../../shared/services/reports.service';
import { ReportStoreService } from 'src/app/shared/services/report-store.service';
import { IonContent, IonRouterOutlet } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { IncentivesStoreService } from 'src/app/shared/services/incentives-store.service';
import { TableFilterButton, TableFilterComponent } from 'src/app/shared/components/table-filter/table-filter.component';
import { SearchHeaderIcon } from 'src/app/shared/components/search-header/search-header.component';
import { TableFilterService } from 'src/app/shared/services/table-filter.service';
import { CdkDropListGroup, CdkDropList, CdkDragMove, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AbstractLoadsReportDataComponent, AbstractStoresReportInfo, AfterViewInit {

  sessionKey: string;
  userId: number;
  loaded: boolean = false;
  userFullName: string = "";
  filtersObjects: TableFilterButton[] = [];

  @ViewChild("content") content: IonContent;



  dataSource: MatTableDataSource<DashboardData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  workspaceId: number;

  asOfTime: string = "";

  inEditMode: boolean = false;

  currentCount: number = 12;

  constructor(
    private platform: Platform,
    public bottomSheet: MatBottomSheet,
    public dashboardService: DashboardService,
    public reportStoreService: ReportStoreService,
    public router: Router,
    public dialog: MatDialog,
    public reportsService: ReportsService,
    public freshReportService: FreshReportService,
    public headerService: HeaderService,
    public tableFilterService: TableFilterService,
    public routerOutlet: IonRouterOutlet,
    public userDetailsService: UserDetailsService,
    public profileService: ProfileService,
    public incentivesStoreService: IncentivesStoreService,
    public busySpinnerService: BusySpinnerService,
    private viewportRuler: ViewportRuler,
    public snackbarService: SnackbarService,
  ) {
    this.initReports();
    this.initFilterButtons();
    this.setDefaultFilters();





    this.target = null;
    this.source = null;
  }


  updateTableDataSource(allReports: DashboardData[] = this.getCurrentReports()): void {
    setTimeout(() => {
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource(this.getReportsForDatasource(allReports));
      }
      this.dataSource.data = this.getReportsForDatasource(allReports);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data: DashboardData, filter) => {
        const dataStr = JSON.stringify(data.freshReport.reportName).toLowerCase();
        return dataStr.indexOf(filter) != -1;
      }

      // this.dataSource.filterPredicate = (data, filter: string)  => {
      //   const accumulator = (currentTerm, key) => {
      //     return this.nestedFilterCheck(currentTerm, data, key);
      //   };
      //   const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      //   // Transform the filter by converting it to lowercase and removing whitespace.
      //   const transformedFilter = filter.trim().toLowerCase();
      //   return dataStr.indexOf(transformedFilter) !== -1;
      // };



      this.checkForFavoriteAndAdjustViewAccordingly();
    });
  }


  // nestedFilterCheck(search, data: DashboardData, key) {
  //   if (typeof data[key] === 'object') {
  //     for (const k in data[key]) {
  //       if (data[key][k] !== null) {
  //         search = this.nestedFilterCheck(search, data[key], k);
  //       }
  //     }
  //   } else {
  //     search += data[key];
  //   }
  //   return search;
  // }

  checkForFavoriteAndAdjustViewAccordingly() {
    let isFavorite = false;
    this.filtersObjects.forEach(filter => {
      if (filter.subObjectKey && filter.subObjectKey === "isFavorite" && filter.selectedValue?.toString() === "true") {
        isFavorite = true;
      }
    })

    // this.view = isFavorite ? "Grid" : "List"
  }

  getReportsForDatasource(reports: DashboardData[]): DashboardData[] {
    reports = reports.filter(l => this.shouldDisplay(l) && l.freshReport.reportCategory !== "Records");
    return reports;
  }

  private shouldDisplay(report: DashboardData): boolean {
    return this.tableFilterService.shouldDisplay(report, this.filtersObjects);
  }

  getCurrentReports() {
    return this.reportStoreService.getCurrentReports().filter(r => r.freshReport.reportCategory !== "Records")
  }



  async setDefaultFilters() {
    this.filtersObjects.forEach(async filter => {
      if (filter.objectKey === "freshReport" && filter.subObjectKey === "isFavorite") {
        filter.selectedValue = "true";
      }
    })
  }



  initFilterButtons() {
    this.filtersObjects = [
      {
        objectKey: "freshReport",
        label: "Category",
        subObjectKey: "reportCategory",
        selectedValue: null,
        type: "other",
        options: [
          'All',
          'Production Metrics',
          'Operational Metrics',
        ]
      },
      {
        objectKey: "freshReport",
        subObjectKey: "isFavorite",
        label: "Favorited",
        selectedValue: null,
        type: "boolean",
        options: [
          "All",
          "Favorite",
          "Non-Favorite"
        ]
      },
      {
        objectKey: "freshReport",
        subObjectKey: "viewType",
        label: "Type",
        selectedValue: null,
        type: "other",
        options: [
          'lineChart',
          'pieChart',
          'columnChart',
        ]
      },
    ]
  }


  getHeaderIcons() {
    return this.favoritedFilterIsSelected() ? ['Drag'] : []
  }

  favoritedFilterIsSelected() {
    return true //TODO: Implement this function?
  }


  handleSearchInputAction(searchValue: string) {


    this.dataSource.filter = searchValue.trim().toLowerCase();
  }



  handleIconAction(icon: SearchHeaderIcon) {

    if (icon.title === "Filters") {
      this.openFilters();
    } else if (icon.title === "Drag") {
      this.toggleDragMode(icon);
    }
  }

  toggleDragMode(icon: SearchHeaderIcon) {
    if (icon.icon === "low_priority") {
      this.inDragMode = false;
    } else if (icon.icon === "done") {
      this.inDragMode = true;

    }
  }

  openFilters() {
    let ref = this.bottomSheet.open(TableFilterComponent, { data: { filters: this.filtersObjects } });
    ref.afterDismissed().subscribe((tableFilters: TableFilterButton[]) => {
      if (!tableFilters) { return }
      this.filtersObjects = tableFilters;
      this.updateTableDataSource();
    });
  }

  handleClearedFilter(tableFilter: TableFilterButton) {
    this.filtersObjects.forEach(filter => {
      if (filter.label === tableFilter.label) {
        filter.selectedValue = null;
      }
    })
    this.currentCount = 12;
    this.updateTableDataSource();
  }


  ionViewDidEnter() {
    this.routerOutlet.swipeGesture = false;
  }

  loadData(event) {
    setTimeout(() => {
      console.log('Done');
      if (this.currentCount < this.dataSource.data.length) {
        this.currentCount += 9;
      }

      event.target.complete();

      if (this.currentCount >= this.dataSource.data.length) {
        this.snackbarService.displaySnackBarMessage("All data loaded for given filters")
      }
    }, 500);
  }

  editReport(report: DashboardData) {
    this.inEditMode = true;

    this.showAdvancedAnalytics(report);
  }

  ionViewWillEnter() {
    this.inEditMode = false;
  }

  favorite(report: DashboardData) {
    report.freshReport.isSavingFavorite = true;
    this.reportsService.setFavoriteReport(report.freshReport.id).then(res => {
      this.updateNewlyFavoritedReport(report);
    });
  }

  private async updateNewlyFavoritedReport(report: DashboardData): Promise<void> {
    await this.loadReportData(report);
    report.freshReport.isSavingFavorite = false;
    report.freshReport.isFavorite = true;
  }


  removeFavorite(report: DashboardData) {
    report.freshReport.isSavingFavorite = true;

    this.reportsService.deleteFavoriteReport(report.freshReport.id).then(res => {
      report.freshReport.isSavingFavorite = false;

      report.freshReport.isFavorite = false;

    });
  }

  private async loadReportData(report: DashboardData) {
    const cellList: ReportCellList = await this.freshReportService.getReportCellListByReportId(report.freshReport.id);
    report.reportCellList = cellList
    report.reportMenu = cellList.menu;
    if (report?.reportMenu) {
      report.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(report.freshReport.viewType)
    }
  }

  async initReports() {
    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.reportStoreService.reports.subscribe(nextList => {
      this.updateTableDataSource(nextList)
    });
  }




  async doRefresh(event) {
    this.busySpinnerService.start();
    await this.reportStoreService.fetchLatestReports();
    event.target.complete();
    this.busySpinnerService.stop();
  }


  public storeReportMenuAndData(report: DashboardData, reportMenu: DataReportMenu, reportData: ReportDataPoint[], component: AbstractLoadsReportDataComponent): void {
    this.showAdvancedAnalytics(report);
  }

  afterDataLoads(report: DashboardData) {
    this.showAdvancedAnalytics(report);
  }

  async showAdvancedAnalytics(report: DashboardData) {
    this.dashboardService.showAdvancedAnalytics(report);
  }


  shouldShowDownload() {
    return this.platform.is("desktop")
  }


  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;


  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;


  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }


  inDragMode: boolean = false;

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped(event) {
    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.dataSource.filteredData, this.sourceIndex, this.targetIndex);
      this.reportsService.updateReportOrder(this.dataSource?.filteredData?.filter(r => r.freshReport.isFavorite).map(r => r.freshReport?.reportId));

    }
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }


}


function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
