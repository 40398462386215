<ion-content>

  <div style="width: 100%; position: sticky; top: 0; z-index: 9999;">

    <ion-toolbar no-border class="no-padding-header ">

      <button mat-fab class="circle-button" matRipple class="example-ripple-container" [matRippleUnbounded]="false"
        style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent;"
        (click)="goBack()">
        <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
      </button>



      <div *ngIf="this.applicant">
        <ion-grid style="padding: 0; margin-top: -20px;">
          <ion-row style="padding: 0; margin: 0;">

            <div style="display: block; margin: auto;">
              <app-user-icon [shouldExpandImage]="true" [isExtraLarge]="true" [imageUrl]="applicant.userImageUrl">
              </app-user-icon>
            </div>

          </ion-row>

          <div style="height: 15px"></div>

          <ion-row style="padding: 0; margin: 0;">
            <h1 style="text-align: center;  margin: 0; width: 100%; font-size: 22px;"> <span style="font-weight: 100;">
                &nbsp;</span>{{applicant.firstName | titlecase}} {{applicant.lastName | titlecase}}
            </h1>
          </ion-row>
        </ion-grid>
      </div>



    </ion-toolbar>

    <ion-toolbar style="max-width: 1200px; display: block; margin: auto;">
      <div slot="start">
        <button [disabled]="!editMode" mat-raised-button style="align-self: flex-start;"
          (click)="saveApplicantUpdates()">Save Changes</button>
      </div>

      <div slot="end">
        <button class="primary-color" mat-button [matMenuTriggerFor]="menu">
          <span class="zeroLineHeight">{{this.applicant.status}}</span>
          <i class="material-icons">
            arrow_drop_down
          </i>
        </button>

      </div>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>

          <button mat-menu-item (click)="changeStatus(status)"
            *ngFor="let status of this.adminDirectoryService.applicantStatuses">
            <span>{{status}}</span>
          </button>


        </ng-template>
      </mat-menu>

    </ion-toolbar>
  </div>


  <div class="section-div" style="text-align: left; max-width: 1200px; display: block;margin: auto; padding: 10px;">

    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Personal Info</p>
      </div>

      <div slot="start">
        <button mat-icon-button aria-label="Clear" (click)="copyContent('Personal Info')">
          <i class="material-icons" style="font-size: 14px;">
            content_copy
          </i>
        </button>
      </div>
    </ion-toolbar>



    <ion-toolbar class="transparent-bg">
      <div style="height: 5px;"></div>
      <!-- <div class="scrolling-wrapper-flexbox special-flexbox" style="overflow-y: hidden;"> -->


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Address: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.address" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>



      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">State: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.state" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Zip: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.zip" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>

      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">City: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.city" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>

      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Team: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.officeLocation" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>

      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Phone: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>
        <!-- <div slot="start">
          <span>+1</span>
        </div> -->

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.phoneNumber" (ngModelChange)="setEditMode()" >
        </div>
      </ion-toolbar>

      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Primary Email: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <!-- <input matInput type="text" [(ngModel)]="applicant.emailAddress" (ngModelChange)="setEditMode()"> -->
          <span>{{applicant.emailAddress}}</span>
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Secondary Email: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.emailAddressSecondary" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Id Badge Printed: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>
        <div slot="start" style="width: 100%;">
          <mat-checkbox (ngModelChange)="setEditMode()" [(ngModel)]="applicant.idBadgePrinted"></mat-checkbox>
        </div>

      </ion-toolbar>

      <div style="padding: 10px;"></div>
      <!-- </div> -->
    </ion-toolbar>

    <mat-divider></mat-divider>
    <div style="height: 10px;"></div>
    <ion-row>
      <mat-slide-toggle [checked]="dragAndDrop" (change)="toggleDragAndDrop($event)"><p style="font-size: 12px;">Drag & Drop Files</p></mat-slide-toggle>
    </ion-row>

    <div>
      <p>Primary Documents</p>
    </div>
    <ion-toolbar class="transparent-bg" *ngIf='applicant'>
      <div class="scrolling-wrapper-flexbox" [ngClass]="{'dragAndDrop' : (this.dragAndDrop), 'noDragAndDrop' : (!this.dragAndDrop)}">

        <div *ngFor="let document of primaryDocuments">
          <ion-row>
            <mat-card matRipple [ngClass]="{'selected-document': (document.exists) }"
              class="example-ripple-container mat-elevation-z2" [matRippleUnbounded]="false"
              style="width: 100px; height: 130px; text-align: center; margin-right: 10px;"
              (click)="processDocumentAction(document)">
              <span class="material-icons">
                {{document.icon}}
              </span>
              <div style="font-size: 10px">
                {{document.title}}
              </div>
            </mat-card>
          </ion-row>

          <ion-row>
            <button mat-button style="width: 100px;" (click)="fileInputDocument.click()"
              *ngIf="document.title !== 'ID Badge' ">
              <span *ngIf="document.exists"> Update </span>
              <span *ngIf="!document.exists"> Add </span>
            </button>
          </ion-row>

          <ion-row>
            <input hidden (change)="onFileSelected($event, document)" #fileInputDocument type="file"
              name="image">
            <button *ngIf="document.title === 'ID Badge'" style="padding: 0; width:1px;visibility:hidden;" mat-button
              [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">open</button>
          </ion-row>
          <div *ngIf="document.title !== 'ID Badge' ">
            <ion-row >
              <ion-col size="12">
                <div class="dropzone" uploadFiles (fileDropped)="onFileDropped($event, document)">
                  <span class="material-icons" style='font-size: 40px;'>
                    file_upload
                  </span>
                  <div style="height: 20px;"></div>
                  <h3>Drop {{document.title}} here</h3>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadBadge('1')">Normal</button>
          <button mat-menu-item (click)="downloadBadge('2')">

            <span class="material-icons">
              rotate_90_degrees_ccw
            </span>
          </button>
        </mat-menu>
        <div style="padding: 10px;"></div>
      </div>
    </ion-toolbar>

    <div>
      <p>Identification Documents</p>
    </div>
    <ion-toolbar class="transparent-bg" *ngIf='applicant'>
      <div class="scrolling-wrapper-flexbox" [ngClass]="{'dragAndDrop' : (this.dragAndDrop), 'noDragAndDrop' : (!this.dragAndDrop)}">

        <div style="padding: 2px;"></div>
          <ion-row>

          </ion-row>
        

        <div style="padding: 2px;"></div>

        <div *ngFor="let document of idDocuments">
          <ion-row>
            <mat-card matRipple [ngClass]="{'selected-document': (document.exists) }"
              class="example-ripple-container mat-elevation-z2" [matRippleUnbounded]="false"
              style="width: 100px; height: 130px; text-align: center; margin-right: 10px;"
              (click)="processDocumentAction(document)">
              <span class="material-icons">
                {{document.icon}}
              </span>
              <div style="font-size: 10px">
                {{document.title}}
              </div>
            </mat-card>
          </ion-row>

          <ion-row>
            <button mat-button style="width: 100px;" (click)="fileInputDocument.click()"
              *ngIf="document.title !== 'ID Badge' ">
              <span *ngIf="document.exists"> Update </span>
              <span *ngIf="!document.exists"> Add </span>
            </button>
          </ion-row>

          <ion-row>
            <input hidden (change)="onFileSelected($event, document)" #fileInputDocument type="file"
              name="image">
            <button *ngIf="document.title === 'ID Badge'" style="padding: 0; width:1px;visibility:hidden;" mat-button
              [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">open</button>
          </ion-row>
          <div *ngIf="document.title !== 'ID Badge' ">
            <ion-row >
              <ion-col size="12">
                <div class="dropzone" uploadFiles (fileDropped)="onFileDropped($event, document)">
                  <span class="material-icons" style='font-size: 40px;'>
                    file_upload
                  </span>
                  <div style="height: 20px;"></div>
                  <h3>Drop {{document.title}} here</h3>
        
                </div>
              </ion-col>
        
        
            </ion-row>
          </div>
        </div>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadBadge('1')">Normal</button>
          <button mat-menu-item (click)="downloadBadge('2')">

            <span class="material-icons">
              rotate_90_degrees_ccw
            </span>
          </button>
        </mat-menu>
        <div style="padding: 10px;"></div>
      </div>
    </ion-toolbar>


    <div style="height: 10px;"></div>

    <p>Misc Documents</p>


    <ion-toolbar class="transparent-bg" *ngIf='applicant'>
      <div style="height: 5px;"></div>
      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 180px; padding-top: 10px">

        <div style="padding: 2px;"></div>


        <div *ngFor="let file of this.applicant.listOfFiles.files">
          <ion-row>
            <mat-card (click)="openDoc(file)" matRipple
              class="example-ripple-container mat-elevation-z2 selected-document" [matRippleUnbounded]="false"
              style="width: 100px; height: 130px; text-align: center; margin-right: 10px;">
              <span class="material-icons">
                description
              </span>
              <div style="font-size: 10px">
                {{file.title}}
              </div>
            </mat-card>
          </ion-row>

        </div>

        <ion-row>
          <mat-card matRipple (click)="addAdditionalDocument()" class="example-ripple-container mat-elevation-z2"
            [matRippleUnbounded]="false" style="width: 100px; height: 130px; text-align: center; margin-right: 10px;">
            <div style="height: 20px;"></div>
            <span class="material-icons">
              add
            </span>
            <div style="font-size: 10px">
              Add PDF
            </div>
          </mat-card>
        </ion-row>



        <div style="padding: 10px;"></div>
      </div>
    </ion-toolbar>


    <mat-divider></mat-divider>


    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Banking Info</p>
      </div>

      <div slot="start">
        <button mat-icon-button aria-label="Clear" (click)="copyContent('Banking Info')">
          <i class="material-icons" style="font-size: 14px;">
            content_copy
          </i>
        </button>
      </div>
    </ion-toolbar>
    <ion-toolbar class="transparent-bg">
      <div style="height: 5px;"></div>
      <!-- <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;"> -->

      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Account Holder Name: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.accountHolderName" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Account Number: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.accountNumber" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>




      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Account Type: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.accountType" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>





      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">ACH Routing Number: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.achRoutingNumber" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Bank Name: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.bankName" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>



      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Wire Routing Number: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.bank.wireRoutingNumber" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>



      <div style="padding: 10px;"></div>
      <!-- </div> -->
    </ion-toolbar>


    <mat-divider></mat-divider>

    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Clothing Size</p>
      </div>

      <div slot="start">
        <button mat-icon-button aria-label="Clear" (click)="copyContent('Clothing Size')">
          <i class="material-icons" style="font-size: 14px;">
            content_copy
          </i>
        </button>
      </div>
    </ion-toolbar>

    <ion-toolbar class="transparent-bg">
      <div style="height: 5px;"></div>
      <!-- <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;"> -->



      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Shirt Size: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.apparel.shirtSize" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Hat Size: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.apparel.hatSize" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>




      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Jacket Size: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.apparel.jacketSize" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Pant / Short Size: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.apparel.pantShortSize" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>




      <ion-toolbar class="transparent-bg">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Waist Size: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="applicant.apparel.waistSize" (ngModelChange)="setEditMode()">
        </div>
      </ion-toolbar>



      <div style="padding: 10px;"></div>
      <!-- </div> -->
    </ion-toolbar>

    <mat-divider></mat-divider>

    <p>Inviting User</p>

    <ion-toolbar class="transparent-bg" *ngIf="invitingUser">
      <div style="height: 5px;"></div>
      <!-- <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;"> -->

      <ion-toolbar class="transparent-bg" *ngIf="invitingUser">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Name: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.fullName}}
        </div>
      </ion-toolbar>


      <ion-toolbar class="transparent-bg" *ngIf="invitingUser && invitingUser.userDto">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Email Address: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.userDto.emailAddress}}
        </div>
      </ion-toolbar>



      <ion-toolbar class="transparent-bg" *ngIf="invitingUser && invitingUser.attributeMap">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Region: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.attributeMap['Sales Rep Region']}}
        </div>
      </ion-toolbar>



      <ion-toolbar class="transparent-bg" *ngIf="invitingUser && invitingUser.attributeMap">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Team: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.attributeMap['Sales Rep Team']}}
        </div>
      </ion-toolbar>


      <!-- <ion-toolbar class="transparent-bg" *ngIf="invitingUser && invitingUser.attributeMap">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Team: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.attributeMap['Office Location']}}
        </div>
      </ion-toolbar> -->


      <ion-toolbar class="transparent-bg" *ngIf="invitingUser && invitingUser.attributeMap">
        <div slot="start" style="white-space: nowrap;">
          <span style="font-weight: 100;">Position: </span>
        </div>
        <div slot="start">
          <div style="width: 10px;"></div>
        </div>

        <div slot="start" style="width: 100%;">
          {{invitingUser.attributeMap['Position']}}
        </div>
      </ion-toolbar>



      <div style="padding: 10px;"></div>
      <!-- </div> -->
    </ion-toolbar>

    <mat-divider></mat-divider>

    <ion-toolbar class="transparent-bg">
      <div slot="start" style="white-space: nowrap;">
        <span style="font-weight: 100;">Date Onboarded: </span>
      </div>
      <div slot="start">
        <div style="width: 10px;"></div>
      </div>

      <div slot="start" style="width: 100%;">

        <mat-form-field appearance="fill" style="margin: 0; padding: 0;">
          <input matInput [(ngModel)]="submittedDate" [matDatepicker]="picker" (ngModelChange)="setEditMode()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

      </div>
    </ion-toolbar>


    <mat-divider></mat-divider>
    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Scar Program</p>
      </div>    
    </ion-toolbar>

    <ion-toolbar class="transparent-bg">
      <div slot="start" style="white-space: nowrap;">
        <span style="font-weight: 100;">Enrolled: </span>
      </div>
      <div slot="start">
        <div style="width: 10px;"></div>
      </div>

      <div slot="start" style="width: 100%;">
        <mat-checkbox (ngModelChange)="setEditMode()" [(ngModel)]="applicant.scar"></mat-checkbox>
      </div>
      <div slot="start">
        <div style="width: 10px;"></div>
      </div>

    </ion-toolbar>

    <div style="height: 10px;"></div>
    <mat-divider></mat-divider>




    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Enzy Account</p>
      </div>
      <div slot="start">
        <div style="width: 10px;"></div>
      </div>


      <div slot="start" *ngIf="!hasEnzyAccount()">
        <button class="primary-color" mat-icon-button (click)="createEnzyAccount()">
          <span class="material-icons">
            add
          </span>
        </button>
      </div>


      <div slot="start" *ngIf="hasEnzyAccount()">
        <span style="font-weight: 100; color: green"><span class="material-icons iconAlign">
            check_circle
          </span>This user has an Enzy Account </span>
      </div>

    </ion-toolbar>






    <mat-divider></mat-divider>


    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>Notes</p>
      </div>

      <div slot="start">
        <div style="width: 10px;"></div>
      </div>

      <div slot="start">
        <button class="primary-color" mat-icon-button (click)="openNewNoteModal()">
          <span class="material-icons">
            add
          </span>
        </button>
      </div>
    </ion-toolbar>

    <ion-row class="section-div" style="text-align: left; padding: 10px; "
      *ngFor='let note of this.applicant.listOfNotes.notes; let index = index; trackBy: trackByIndex;'>

      <h2 style="font-weight: 100; font-size: 14px; padding: 0; margin: 0;">
        {{note.note}}
      </h2>
      <div style="height: 10px;"></div>

      <h2 style="font-weight: 100; font-size: 14px; padding: 0; margin: 0;">
        {{note.insertTime}}
      </h2>

      <div style="height: 10px;"></div>
      <h6 class="opaque-text" style="padding: 0; margin: 0;">
        {{getUserDescriptionForNote(note)}}
      </h6>

      <div style="height: 10px;"></div>
    </ion-row>




  </div>

  <div style="height: 30px;"></div>

  <button mat-button (click)="setStatus('Delete')" class="opaque-text" style="display: block; margin: auto;">
    Delete Applicant
  </button>
</ion-content>