import { HttpClient } from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SurveyResponse } from 'src/app/cr-dashboard/cr-dashboard.service';
import { DashboardAlertModel } from 'src/app/cr-dashboard/cr-dashboard.service';
import { serverGetRequestV1, serverPostRequest } from '../functions/rest.functions';
import { adamLog } from '../generic-functions';
import { UserDetailsService } from './user-details.service';



@Injectable()
export abstract class ISurveyApi {
    abstract getSurveyResponses(location: string, date: string): Promise<DashboardAlertModel[]>;
    // abstract getSurveyMetrics(location: string): Promise<SurveyMetricSummary[]>;
    abstract getSenderIdsDashboardShouldListenTo(location: string): Promise<string[]>;
    abstract getRelatedSurveyResponses(sessionId: string): Promise<SurveyResponse[]>;
    abstract disposition(surveyResponse: DashboardAlertModel, location: string): Promise<void>;
}

const SURVEY_METRICS_REST_PATH: string = "/customerSurveys/dashboardMetrics";
const SURVEY_RESPONSES_REST_PATH: string = "/customerSurveys/dashboardResponses";
const SURVEY_CONVERSATIONS_REST_PATH: string = "/customerSurveys/dashboardSenderIds";

const SURVEY_DISPOSITION_REST_PATH: string = "/customerSentiment/disposition";

const SESSION_RESPONSES_PATH: string = "/customerSentiment/sessionResponses";
@Injectable()
export class SurveyApi implements ISurveyApi {

    constructor(
        private http: HttpClient,
        private userService: UserDetailsService,

    ) {}

    // async getSurveyMetrics(location: string): Promise<SurveyMetricSummary[]> {
    //     const params: {[key:string]: string} = {
    //         location

    //     }
    //     const res = await serverGetRequestV1<{object: SurveyMetricSummary[]}>(
    //         SURVEY_METRICS_REST_PATH,
    //         params,
    //         this.http,
    //         this.userService
    //     );
    //     return res.object;
    // }

    async getSurveyResponses(location: string, date: string): Promise<DashboardAlertModel[]> {
        const params: {[key:string]: string} = {
            location: location,
            date: date
        }
        const res = await serverGetRequestV1<{object: DashboardAlertModel[]}>(
            SURVEY_RESPONSES_REST_PATH,
            params,
            this.http,
            this.userService
        );
        return res.object;
    }
    
    async getRelatedSurveyResponses(sessionId: string): Promise<SurveyResponse[]> {
        const params: {[key:string]: string} = {
            sessionId
        }
        const res = await serverGetRequestV1<{object: SurveyResponse[]}>(
            SESSION_RESPONSES_PATH,
            params,
            this.http,
            this.userService
        );
        return res.object;
    }

    async getSenderIdsDashboardShouldListenTo(location: string): Promise<string[]> {
        const params: {[key:string]: string} = {
            location
        }
        const res = await serverGetRequestV1<{object: string[]}>(
            SURVEY_CONVERSATIONS_REST_PATH,
            params,
            this.http,
            this.userService
        );
        return res.object;
    }

    async disposition(surveyResponse: DashboardAlertModel, location: string, userThatDispositioned: number = null): Promise<void> {
        const params: {[key:string]: string} = {
            surveyId: `${surveyResponse.surveyId}`,
            surveyResponseId: `${surveyResponse.surveyResponseId}`,    
            surveyPageKey: surveyResponse.surveyPageKey,
            pageVisitId: `${surveyResponse.pageVisitId}`, 
            // userThatDispositioned: `${userThatDispositioned}`,
            location
        }

        const res = await serverPostRequest(
            SURVEY_DISPOSITION_REST_PATH,
            params,
            null,
            this.http,
            this.userService
        );
        adamLog("dispositioned: " + res.object);
        // return res.object;
    }

}
