<!-- <input #searchInput style=" font-size: 16px;" matInput  [(ngModel)]="searchValue" > -->

<div scrollY='false' #wrapperArea style="min-height: 400px; min-width: 400px;">
  <div  style="min-height: 400px; min-width: 400px; max-height: 100%; max-width: 100%; display: block; margin: auto;">
    <div #selectedRecipientsArea>
      <mat-form-field  class="example-chip-list">
        <mat-label>Selected Users</mat-label>
  
        <mat-chip-list #chipList>
  
            <mat-chip *ngFor="let selectedUser of selectedUsersToShow(); let i = index;" 
              [selectable]="false" 
              [removable]="true"
              (removed)="unselectUser(selectedUser, i)">
  
  
              <ion-toolbar class="transparent-bg">
                <div slot="start">
                  <div style="margin-top: 10px; margin-left: -5px;">
                    <app-user-icon matListAvatar [employee]='selectedUser'>
                    </app-user-icon>
                  </div>
  
                </div>
                <div slot="start" style="width: 10px;">
  
                </div>
  
                <div slot="start">
                  <div class="mat-bod-string sender-name" style="font-size: 16px; white-space: nowrap;">
                    {{selectedUser?.fullName}}</div>
                </div>
              </ion-toolbar>
  
  
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
  
            <mat-chip *ngIf="shouldShowMoreButton()" 
              [selectable]="selectable" 
              (click)="toggleShowAllSelected()">
              <ion-toolbar class="transparent-bg">
                <div slot="start">
                  {{this.showMoreButtonText()}}
                </div>
              </ion-toolbar>
            </mat-chip>
  
  
  
  
          <ion-toolbar class="transparent-bg">
            <input #searchInput [(ngModel)]="searchValue" [matChipInputFor]="chipList">
          </ion-toolbar>
  
        </mat-chip-list>
      </mat-form-field>

    </div>

    <cdk-virtual-scroll-viewport
    #virtualScrollWindow 
    [style.height]='calculateListHeight()'
    itemSize="56" minBufferPx="900" maxBufferPx="1350" class="example-viewport">
      <div *cdkVirtualFor="let user of getAllUsersToRender()" class="example-item">
        <button mat-button style="width: inherit; height: 35px; margin: 0; padding: 0;" (click)="selectUser(user)">
          <ion-toolbar style="height: 50px;" class="transparent-bg">
            <div slot="start">
              <app-user-icon matListAvatar [employee]='user'>
              </app-user-icon>
            </div>
            <div slot="start" style="width: 10px;">
  
            </div>
            <div slot="start">
              <div class="mat-bod-string sender-name" style="font-size: 16px; margin-top: -12px;">
                {{user?.fullName}}
              </div>
            </div>
          </ion-toolbar>
  
        </button>
      </div>
    </cdk-virtual-scroll-viewport>

  </div>
</div>