import { Component, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AbstractLoadsReportDataComponent } from '../../shared/abstract-classes/abstract-saves-report';
import { DataReportMenu } from '../../shared/models/dashboard-report-list.model';
import { DashboardData, UserData } from '../../shared/models/dashboard.model';
import { FreshReport } from '../../shared/models/fresh-report.model';
import { ReportCell, ReportCellList } from '../../shared/models/report-cell-list.model';
import { DashboardService } from '../../shared/services/dashboard.service';
import { FreshReportService } from '../../shared/services/fresh-report.service';
import { HeaderService } from '../../shared/components/my-header/header.service';
import { AdvancedAnalyticsModalComponent } from './advanced-analytics-modal/advanced-analytics-modal.component';
import { StratFieldEnum } from 'src/app/shared/enums/stratFieldEnum';
import { Employee } from 'src/app/shared/models/employees.model';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { ReportStoreService } from 'src/app/shared/services/report-store.service';
import { Location } from "@angular/common";
import { NavController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { MessageSelectRecipientComponent } from 'src/app/messaging/message-select-recipient/message-select-recipient.component';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';


@Component({
  selector: 'app-advanced-analytics',
  templateUrl: './advanced-analytics.component.html',
  styleUrls: ['./advanced-analytics.component.scss'],
})
export class AdvancedAnalyticsComponent implements OnInit, AbstractLoadsReportDataComponent {


  selectedReport: DashboardData = {
    freshReport: new FreshReport(),
    reportMenu: new DataReportMenu(),
    reportCellList: new ReportCellList(),
    hasBeenSaved: false,
    viewFilter: 'Table',
    reportOrder: this.reportStoreService.getCurrentReports()?.length
  };


  selectedReport_copy: DashboardData;

  reportIdFromUrl: string;

  sessionKey: string = "";
  inEditMode: boolean = false;

  userId: number;

  constructor(private reportsService: ReportsService,
    private freshReportService: FreshReportService,
    private location: Location,
    private navCtrl: NavController,
    private snackbarService: SnackbarService,
    private platform: Platform,
    private userDetailsService: UserDetailsService,
    public reportStoreService: ReportStoreService,
    private localStorage: Storage, public dialog: MatDialog, public router: Router, public nav: HeaderService, private dashboardService: DashboardService) {

    this.localStorage.get('userData').then((data: UserData) => {
      this.sessionKey = data.sessionKey;
      this.reportIdFromUrl = data.id;
    });
    this.setupUserId();
  }

  async setupUserId() {
    this.userId = +(await this.userDetailsService.getUserId());
  }


  ngOnInit() {


  }

  ionViewWillLeave() {

  }

  ionViewWillEnter() {

    this.selectedReport = this.dashboardService.selectedReport;
    if (history.state.inEditMode) {
      this.inEditMode = history.state.inEditMode
    }

    if (history.state.messageUsersMode) {
      this.selectUserMode = history.state.messageUsersMode;
      this.selectAll();
    }



    if (this.selectedReport === undefined) {
      this.router.navigate(["/analytics/"]);
    } else {
      this.selectedReport_copy = JSON.parse(JSON.stringify(this.dashboardService.selectedReport));
    }

  }

  async getReportById(): Promise<void> {

  }

  selectAll(): void {
    this.selectedReport.reportCellList.list.forEach(cell => cell.isSelected = true);
  }

  saveReport() {
    if (this.canEditReport(this.selectedReport)) {
      this.selectedReport.hasBeenSaved = true;

      this.afterDataLoads(this.selectedReport);

    }
  }

  shouldShowDownload() {
    return this.platform.is("desktop")
  }


  favorite(report: DashboardData) {
    this.reportsService.setFavoriteReport(report.freshReport.id).then(res => {
      if (res.success) {
        report.freshReport.isFavorite = true;
        this.snackbarService.displaySnackBarMessage("Successfully favorited report.")
      }
      else {
        this.snackbarService.displaySnackBarMessage("An error occured. Try again.")
      }
    });
  }


  removeFavorite(report: DashboardData) {
    this.reportsService.deleteFavoriteReport(report.freshReport.id).then(res => {
      if (res.success) {
        report.freshReport.isFavorite = false;
        this.snackbarService.displaySnackBarMessage("Successfully unfavorited report.")
      }
      else {
        this.snackbarService.displaySnackBarMessage("An error occured. Try again.")
      }
    });
  }


  editReport() {
    this.inEditMode = !this.inEditMode;
  }

  afterDataLoads(report: DashboardData) {
    // this.dashboardService.addReportp(this.selectedReport, this.sessionKey);

    let reportOrder = report.reportOrder;

    //This means it's a new report
    if (reportOrder === this.reportStoreService.getCurrentReports().length) {
      this.reportStoreService.getCurrentReports().push(report);
    }

    this.freshReportService.saveFreshReportMenu(report, this.sessionKey).subscribe(res => {
      this.dashboardService.mapNewReportViewTypeName(reportOrder);

    })
    this.freshReportService.updateFreshReportName(report, this.sessionKey).subscribe(res => console.log(res));
  }



  download(report: DashboardData) {
    this.reportsService.downloadReportFromId(report.freshReport.id + "").then(res => {
      this.downloadFile(res, report.freshReport.reportName)
    })
  }


  downloadViaEmail(report: DashboardData) {
    this.reportsService.downloadReportFromId(report.freshReport.id + "", true).then(res => {
    })
    this.snackbarService.displaySnackBarMessage("Sending report to your email...", true);

  }



  downloadFile(data: ArrayBuffer, name: string) {
    const blob = new Blob([data as BlobPart], { type: 'text/csv' });
    // const url= window.URL.createObjectURL(blob);
    // window.open(url);

    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = name + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);


  }



  deleteReport() {
    this.selectedReport.hasBeenSaved = false;
    this.freshReportService.deleteFreshReportMenu(this.selectedReport, this.sessionKey).subscribe(res => console.log(res));
    this.snackbarService.displaySnackBarMessage('Report Deleted', true);
    this.router.navigate(['/analytics/']);
  }


  isMessagableReport(report: DashboardData) {
    return report?.reportMenu.selectedStratField === StratFieldEnum.SalesRepId || report?.reportMenu.selectedStratField === StratFieldEnum.SalesRep || report?.reportMenu.selectedStratField === StratFieldEnum.SalesRepRegion || report?.reportMenu.selectedStratField === StratFieldEnum.SalesRepTeam || report?.reportMenu.selectedStratField === StratFieldEnum.Company
  }

  selectUserMode: boolean = false;

  enterSelectUserMode(): void {


    if (this.selectedReport.freshReport.viewType !== "tablemessagePeopleInReport") {


      this.selectedReport.reportMenu.viewType = "Table";

    }
    this.selectUserMode = true;
  }

  exitSelectUserMode(): void {
    this.selectUserMode = false;
  }



  messagePeopleInReport(report: DashboardData) {

    let employeesToMessage: Employee[] = [];
    let cellsToUse: ReportCell[] = this.selectedReport?.reportCellList?.list;
    if (this.selectUserMode) {
      cellsToUse = this.selectedReport?.reportCellList?.list.filter(cell => cell.isSelected)
    }

    if (report.reportMenu.selectedStratField === StratFieldEnum.SalesRepId || report.reportMenu.selectedStratField === StratFieldEnum.SalesRep) {
      cellsToUse.forEach(item => {
        let employee = item.cellList[0].employee
        employeesToMessage.push(employee);
      })

      // this.router.navigateByUrl('/selectRecipient', { state: { employeesToMessage: employeesToMessage } });


      let ref = this.dialog.open(MessageSelectRecipientComponent, {
        maxWidth: '100vw !important',
        width: '100%',
        height: '100%',
        data: {
          employeesToMessage: employeesToMessage
        },
        panelClass: 'transparent',
      })

      ref.afterClosed().subscribe(res => {
        if (!res) { return }
        if (!res.isBCC) {
          this.router.navigateByUrl("/messages/" + res.conversationId);
        } else if (res.isBCC) {
          this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend } });
        }
      })

    }
    else if (report.reportMenu.selectedStratField === StratFieldEnum.SalesRepRegion || report.reportMenu.selectedStratField === StratFieldEnum.SalesRepTeam || report.reportMenu.selectedStratField === StratFieldEnum.Company) {

      let newReport: DashboardData = JSON.parse(JSON.stringify(report));

      newReport.reportMenu.selectedStratField = StratFieldEnum.SalesRep;
      newReport.reportMenu.filterTextList.forEach(item => {
        if (item.text === 'Sales Rep Team') {
          item.includeValue = "" + cellsToUse[0].cellList[0].value;
        }

      })



      this.reportsService.getDataReportList(newReport.reportMenu,).then((reportList: any) => {

        reportList.list.forEach(item => {
          let employee = item.cellList[0].employee
          employeesToMessage.push(employee);
        })


        // cellsToUse.forEach(item => {
        //   let employee = item.cellList[0].employee
        //   employeesToMessage.push(employee);
        // })
        // this.router.navigateByUrl('/selectRecipient', { state: { employeesToMessage: employeesToMessage } });


        let ref = this.dialog.open(MessageSelectRecipientComponent, {
          maxWidth: '100vw !important',
          width: '100%',
          height: '100%',
          data: {
            employeesToMessage: employeesToMessage
          },
          panelClass: 'transparent',
        })

        ref.afterClosed().subscribe(res => {
          if (!res) { return }
          if (!res.isBCC) {
            this.router.navigateByUrl("/messages/" + res.conversationId);
          } else if (res.isBCC) {
            this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend } });
          }
        })


      })
    }
  }

  messageSelected(): void {

  }

  goBack() {
    this.navCtrl.back();

  }

  editName() {
    if (!this.canEditReport(this.selectedReport)) {
      return;
    }
    const dialogRef = this.dialog.open(AdvancedAnalyticsModalComponent, {
      width: '95%',
      data: { mode: "editName", reportName: this.selectedReport.freshReport.reportName }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        this.selectedReport.freshReport.reportName = result;
        this.selectedReport.hasBeenSaved = false;

      }
    });
  }

  share() {


  }

  duplicate() {
    let duplicateReport: DashboardData = JSON.parse(JSON.stringify(this.dashboardService.selectedReport));

    duplicateReport.freshReport.reportName = duplicateReport.freshReport.reportName + " Copy";
    duplicateReport.freshReport.id = 0;

    duplicateReport.reportOrder = this.reportStoreService.getCurrentReports().length;

    duplicateReport.onDashboard = true;


    this.afterDataLoads(duplicateReport);

  }


  canEditReport(report: DashboardData): boolean {
    if (report) {
      if (report.freshReport.shareType) {
        return report.freshReport.shareType.toLowerCase() !== 'view' || report.freshReport.userId === this.userId;

      }
    }
    return true;
  }



  onSwipeLeft($event) {
    this.goBack();
  }

  onSwipeRight($event) {

  }
}
