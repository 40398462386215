import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { Platform } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AreYouSureModalComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure-modal.component';
import { UserPickerModalComponent } from 'src/app/shared/components/user-picker-modal/user-picker-modal.component';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { Employee } from 'src/app/shared/models/employees.model';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { LeadChanges } from 'src/app/shared/services/messaging.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { LeadsDrawerComponent } from '../leads-drawer/leads-drawer.component';
import { LeadsStoreService } from '../leads-store.service';
import { Appointment, DEFAULT_NOTE, getDefaultNewLead, LeadModel, Note, Task } from '../leads.model';
import { LeadsService } from '../leads.service';
@Component({
  selector: 'app-lead-info',
  templateUrl: './lead-info.component.html',
  styleUrls: ['./lead-info.component.scss'],
})
export class LeadInfoComponent implements OnInit {

  @Input() lead: LeadModel = getDefaultNewLead();


  private actionsSection: LeadSection[] = [];
  private detailsSection: LeadSection[] = [];

  formattedPhoneNumber: string = ""
  userId: number;
  // addedFilters: string[] = [];
  leadChanges: LeadChanges[] = [];


  @Output() submitLead = new EventEmitter<LeadModel>();

  options = {
    types: [],
    componentRestrictions: { country: ['USA'] }
  }


  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address, lead: LeadModel) {
    lead.fullAddress = address.formatted_address;
  }


  constructor(
    public employeeStoreService: EmployeeStoreService,
    public leadsService: LeadsService,
    public incentivesService: IncentivesService,
    public dialog: MatDialog,
    public snackbarService: SnackbarService,
    public dateFormatService: DateFormatService,
    public platform: Platform,
    public locationService: LocationService,
    public leadsStoreService: LeadsStoreService,
    public userDetailsService: UserDetailsService,
    public bottomSheet: MatBottomSheet) {


    this.actionsSection = [
      {
        title: "Appointments",
        leadField: "appointments", 
        type: "text"

      }, {
        title: "Attachments",
        leadField: "customerUtilityBillUrls", 
        type: "text"
      }, {
        title: "Notes",
        leadField: "notes", 
        type: "text"
      }, {
        title: "Tasks",
        leadField: "tasks", 
        type: "text"
      }
    ]



    this.detailsSection = [

      // { title: "First Name", leadField: "customerFirstName" },
      // { title: "Last Name", leadField: "customerLastName" },

      { title: "Next Appt", leadField: "appointments", type: "text" },
      { title: "Phone Number", leadField: "phoneNumber", type: "phone" },
      { title: "Email", leadField: "email", type: "email" },
      { title: "Address", leadField: "fullAddress", type: "address" },
      { title: "Did this Sit?", leadField: "isCompleted", type: "checkbox" },
      { title: "Team", leadField: "sourceMarket", type: "text" },
      { title: "Setter", leadField: "setterId", type: "text" },
      { title: "Status", leadField: "leadStatus", type: "button" },
      { title: "Closer", leadField: "assignedRep", type: "button" },

      { title: "Prequal", leadField: "preQualifications", type: "text" },
      { title: "Sat Date", leadField: "sitDate", type: "text" },
      { title: "Date Created", leadField: "creationDate", type: "text" },

    ]


  }

  async ngOnInit() {
    this.userId = +(await this.userDetailsService.getUserId());

    this.formattedPhoneNumber = "tel:" + this.lead.phoneNumber?.replace("+", "");
    this.leadChanges = await this.leadsService.getLeadChanges(this.lead.leadId);
  }

  changeDescription(change: LeadChanges): string {
    return JSON.stringify(change);
  }

  shouldDisableSatCheckbox() {
    return this.lead.isCompleted || (this.userId !== this.lead.assignedRep)
  }



  async submitLeadOutput() {
    let emptyRequiredFields = this.checkForEmptyRequiredFields();

    if (emptyRequiredFields.length > 0) {
      let alertString = "Missing: " + emptyRequiredFields.toString();
      alert(alertString);
      return;
    }

    if (this.newNote) {
      this.lead.notes.push(
        {
          ...DEFAULT_NOTE,
          authorId: +(await this.userDetailsService.getUserId()),
          note: this.newNote
        })
    }



    this.submitLead.emit(this.lead);
    this.lead = getDefaultNewLead();
  }

  checkForEmptyRequiredFields(): string[] {
    let emptyRequiredFields: string[] = [];


    if (this.lead.customerUtilityBillUrls?.length == 0) {
      emptyRequiredFields.push(camelCaseToSentenceCase("customerUtilityBill"))
    }

    return emptyRequiredFields
  }


  fieldIsRequired(field: string): boolean {
    return this.requiredLeadFields().includes(field);
  }

  private requiredLeadFields(): string[] {
    return [
      "setterEmail",
      "sourceMarket",
      "customerUtilityBillUrls",
      "customerFirstName",
      "customerLastName",
      "fullAddress",
      "streetAddress",
      "phoneNumber",
      "email",
    ]
  }

  // getEmployee(field: string): Employee {
  //   const userId: number = +this.lead[field];
  //   const output = this.employeeStoreService.getUser(userId);
  //   // console.log(output);
  //   return output;
  // }

  getEmployee(userId: number) {
    if (userId === -1) { return "Assigned" }
    if (userId === null) { return "Unassigned" }

    return this.employeeStoreService.getUserName(userId) ? this.employeeStoreService.getUserName(userId) : "-";
  }


  getNoteEmployee(note: Note) {
    const userId: number = note.authorId;
    const output = this.employeeStoreService.getUser(userId);
    console.log(output);
    return output;
  }


  openSelectSingleUserPickerModal(field: string) {
    let dialogRef: MatDialogRef<UserPickerModalComponent> = this.dialog.open(UserPickerModalComponent, {
      data: { selectSingleUser: true },
      minWidth: "300px",
      maxHeight: "100vh"

    })

    dialogRef.afterClosed().subscribe((res: { userIds: number[] }) => {
      if (!res) { return }
      if (res?.userIds && res.userIds.length > 0) {
        this.lead[field] = res.userIds[0];
      }
    })
  }


  newNote: string = "";


  deleteImage(url: string) {

    const index: number = this.lead.customerUtilityBillUrls.indexOf(url);
    if (index !== -1) {
      this.lead.customerUtilityBillUrls.splice(index, 1);
    }
  }

  humanFriendlyDate(dateString: string): string {
    return genericHumanFriendlyTime(dateString);
  }



  openLeadDrawer(mode: string) {
    let ref = this.bottomSheet.open(LeadsDrawerComponent, { data: { title: mode, customerName: this.lead.customerFirstName + " " + this.lead.customerLastName } });
    ref.afterDismissed().subscribe((res) => {
      if (!res) { return }
      if (mode.includes("Appointments")) {
        this.lead.appointments.push(res);
        this.leadsService.saveLeadAppointments(this.lead);
      }
      else if (mode.includes("Tasks")) {
        this.leadsService.saveNewTask(this.lead, res);

      }
      else if (mode.includes("Notes")) {
        let note: Note = res;

        note.authorId = this.userId;
        this.leadsService.saveNewNote(this.lead, note);
      }
    });
  }


  updateAppointment(appt: Appointment) {
    this.leadsService.saveLeadAppointments(this.lead);

  }


  async editDetails() {
    const editedLead: LeadModel = await this.leadsService.openLeadUsingModal(this.lead, "edit");
    await this.leadsService.safeUpdateLead(this.lead, editedLead);
    if (editedLead) {
      this.lead = editedLead;
      this.leadsStoreService.cacheLeadsList();

      this.submitLead.emit(this.lead);

    }
  }


  //@ADAM - todo this needs to move the backend
  getLeadStatusOptions(): string[] {
    return this.leadsService.leadOptionsConfig.leadStatus;
  }


  selectLeadStatus(option: string) {
    if (!option) { return }
    const oldLead: LeadModel = { ...this.lead };
    this.lead.leadStatus = option;
    this.leadsService.safeUpdateLead(oldLead, this.lead);
  }


  public openMapsApp(location: any) {
    if (this.platform.is('android')) {
      window.location.href = 'geo:' + location;
    } else {
      window.location.href = 'maps://maps.apple.com/?q=' + location;
    }
  }


  getLeadInfoRowValue(section: LeadSection) {
    if (section.title === "Setter") {
      return this.getEmployee(this.lead.setterId)
    }

    if (section.title === "Closer") {
      return this.getEmployee(this.lead.assignedRep)
    }


    if (section.title === "Prequal") {
      return this.lead.preQualification?.toString()
    }

    if (section.title === "Sat Date") {
      return this.dateFormatService.formatDateFromString(this.lead.sitDate)
    }

    if (section.title === "Date Created") {
      return this.dateFormatService.convertUTCDateToLocaleDate(this.lead.creationDate);
    }

    if (section.title === "Next Appt") {
      return this.dateFormatService.formatDateFromString(this.lead.appointments[this.lead.appointments.length -1]?.startDateTime)
    }



    return this.lead[section.leadField]
  }

  getLeadChanges(): LeadChanges[] {
    return this.leadChanges;
  }

  showLeadChanges(): boolean {
    return false;
  }

  getActionsSection(): LeadSection[] {
    return this.actionsSection;
  }
  getDetailsSection(): LeadSection[] {
    return this.detailsSection;
  }

  saveTaskChanges(task: Task) {
    this.leadsService.saveLeadTasks(this.lead);
  }

  deleteAppointment(index: number) {
    if (this.lead?.appointments?.length > 1) {
      this.lead.appointments.splice(index, 1);
      this.leadsService.saveLeadAppointments(this.lead);
    }
    else  
      this.snackbarService.displaySnackBarMessage("Can't delete appointment.")
  }

}

export class LeadSection {
  title: string;
  leadField: string;
  type: string;
}


