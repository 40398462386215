<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<span  *ngIf="id === '0'">
  <shared-my-header #headerSize (onRefreshPage)="this.doRefresh()">
  </shared-my-header>
</span>

<ion-content #ionContent [scrollY]='viewType==="grid"'>
  <ion-toolbar #toolbarArea *ngIf="id !== '0'"
    style="max-width: 1200px; display: block; margin: auto; position: sticky;top: 0; z-index: 999;">

    <div class="safe-area-top"></div>

    <button mat-fab class="app-text-color" matRipple class="example-ripple-container" [matRippleUnbounded]="false"
      style="text-align: center; box-shadow: none; margin-left: -5px; background-color: transparent !important;"
      (click)="goBack()">
      <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
    </button>

    <div style=" padding-bottom: 0; margin: 0; ">
      <ion-grid style="padding: 0; margin-top: -20px;">
        <ion-row style="padding: 0; margin: 0;">
          <div style="display: block; margin: auto;">
            <app-user-icon [shouldExpandImage]="true" [isExtraLarge]="true"
              [imageUrl]="this.inputtedLeaderboardCell?.image"></app-user-icon>
            <!-- <img style="height: 100px; width: 100px;" [src]="this.inputtedLeaderboardCell?.image" /> -->
          </div>
        </ion-row>
        <div style="height: 10px"></div>

        <div style="height: 5px"></div>

        <ion-row style="padding: 0; margin: 0;">
          <h1 style="text-align: center;  margin: 0; width: 100%; font-size: 22px;"><span style="font-weight: 100;">
              {{forOption}}:&nbsp;</span>{{this.inputtedLeaderboardCell?.title}}</h1>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>

  <ion-toolbar #toolbarArea [ngClass]="{'normal': (id === '0'), 'drillDown': (id !== '0') }"
    style=" width: 100vw; height: 95px; position: sticky; padding: 5px 10px 5px 10px; z-index: 999; ">
    <div style=" max-width: 1200px; display: block; margin: auto;">

      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px; margin-bottom: 5px;">
        <div style="width: 100%">
          <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
            <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
              search
            </i>
            <span *ngIf="!searchOn" style="float:left; " class="zeroLineHeight opaque-text">Search
            </span>

            <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue" (keyup)="searchSend()">
            </mat-form-field>
          </button>

        </div>
        <div style="padding: 5px"></div>
        <div>
          <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
            style="margin-left: -50px; z-index: 999;">
            <i class="material-icons" style="font-size: 14px;">
              clear
            </i>
          </button>
        </div>
        <div>
          <button class=" filter-button" style=" border-radius: 22px;
          font-size: 14px;
          width: 40px;
          height: 40px;" 
          mat-icon-button 
          [matMenuTriggerFor]="viewTypeMenu"
          >
            <i *ngIf="this.viewType === 'grid'" class="material-icons" style="font-size: 20px">
              apps
            </i>
            <i *ngIf="this.viewType === 'list'" class="material-icons" style="font-size: 20px">
              list
            </i>
            <i *ngIf="this.viewType === 'bar_list'" class="material-icons" style="font-size: 20px">
              bar_chart
            </i>
          </button>
          <mat-menu #viewTypeMenu>
            <ng-template matMenuContent>
              <button mat-menu-item (click)="changeViewType('list')">
                List
              </button>
              <button mat-menu-item (click)="changeViewType('grid')">
                Cards
              </button>
              <button mat-menu-item (click)="changeViewType('bar_list')">
                Bar List
              </button>
            </ng-template>
          </mat-menu>
        </div>
        <div style="padding: 5px;"></div>

        <div>
          <button *ngIf="shouldShowMessageUsers() && shouldShowViewProjects()" class="primary-color" style=" border-radius: 22px;
          font-size: 14px;
          width: 40px;
          height: 40px;" [matMenuTriggerFor]="menu" mat-icon-button>
            <i class="material-icons" style="font-size: 20px">
              more_vert
            </i>
          </button>

          <mat-menu #menu="matMenu">
            <ng-template matMenuContent>
              <button *ngIf="shouldShowMessageUsers()" (click)="messageUsers()" mat-menu-item>
                <mat-icon>chat</mat-icon>
                <span>Message Users</span>
              </button>
              <button mat-menu-item (click)='this.openProjectsModal()' *ngIf="shouldShowViewProjects()">
                <mat-icon>insights</mat-icon>
                <span>View Projects</span>
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </div>


      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px; ">
        <div *ngIf="this.shouldShowCustomDates()" style="margin-right: 5px;">
          <button class="filter-button" mat-button (click)="pickerStart.open()">
            <span class="zeroLineHeight" style="margin-top: -10px;"> Start Date: &nbsp; </span>

            <span class="zeroLineHeight" style="font-weight: 100;"> {{formatDate(this.getCurrentLeaderboardFilter().startDate)}} &nbsp;</span>

            <i class="material-icons">
              event
            </i>
          </button>


          <mat-form-field style="padding: 0; width:1px;visibility:hidden;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="pickerStart" (dateChange)="addStartDate($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerStart" [disableRipple]="true">
              <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </mat-form-field>
        </div>


        <div *ngIf="this.shouldShowCustomDates()" style="margin-right: 5px;">
          <button class="filter-button" mat-button (click)="pickerEnd.open()">
            <span class="zeroLineHeight" style="margin-top: -10px;"> End Date: &nbsp; </span>

            <span class="zeroLineHeight" style="font-weight: 100;"> {{formatDate(this.getCurrentLeaderboardFilter().endDate)}} &nbsp;</span>

            <i class="material-icons">
              event
            </i>
          </button>


          <mat-form-field style="padding: 0; width:1px;visibility:hidden;" appearance="fill">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="pickerEnd" (dateChange)="addEndDate($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd" [disableRipple]="true">
              <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngFor="let filter of getFilters()">
          <div style="margin-right: 5px;">
            <app-leader-filter-button [filterInput]="filter"></app-leader-filter-button>
          </div>
        </div>

        <!-- <div style="padding: 5px;"></div> -->
        <div>
          <button class="filter-button" mat-button [matMenuTriggerFor]="zeroMenu">
            <span class="zeroLineHeight">{{this.getCurrentZeroValuesString()}}</span>
            <i class="material-icons">
              arrow_drop_down
            </i>
          </button>
        </div>

        <div style="padding: 10px;"></div>
      </div>

    </div>


    <mat-menu #zeroMenu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item (click)="this.changeZeroType(true)" style="text-align: center;  ">
          <span *ngIf="currentlyShowingZeroValues()">
            Show All
          </span>
          <span *ngIf="!currentlyShowingZeroValues()" style="   font-weight: 100;">
            Show All
          </span>
        </button>
        <button mat-menu-item (click)="this.changeZeroType(false)" style="text-align: center;  ">
          <span *ngIf="!currentlyShowingZeroValues()">
            Hide Zeroes
          </span>
          <span *ngIf="currentlyShowingZeroValues()" style="   font-weight: 100;">
            Hide Zeroes
          </span>
        </button>
      </ng-template>
    </mat-menu>
    <div style="height: 30px;"></div>
  </ion-toolbar>

  <div #summaryArea style="display: block; margin: auto; max-width: 600px;">

    <ion-row style="padding: 0; margin: 0;">
      <ion-col *ngFor="let summaryResult of this.getSummaryResults(); let i = index"
        [size]="12 / this.getSummaryResults().length" style="padding: 0; margin: 0;">
        <button style="border-radius: 0; width: 100%;" [ngClass]="{'selected-button': (this.selectedIndex === i), 'app-bottom-border' : (this.selectedIndex !== i)}"
          mat-button (click)="setSummaryResult(i)">
          <ion-grid>
            <ion-row>
              <span class="my-custom-label opaque-text"> {{summaryResult.title}} </span>
            </ion-row>
            <ion-row>
              <span
                style="text-align: center; width: 100%; font-size: 22px; font-weight: 100; height: 30px;">{{this.getSummaryValueFormatted(summaryResult)}}</span>
            </ion-row>
          </ion-grid>
        </button>
      </ion-col>
    </ion-row>
  </div>


  <div *ngIf="viewType === 'list' || viewType === 'bar_list'" style="overflow-x: hidden; display: block; margin: auto; max-width: 600px; padding: 0 10px 0 10px;">
    <cdk-virtual-scroll-viewport
    #virtualScrollWindow 
    [style.height]='calculateListHeight()' 
    itemSize="56" minBufferPx="2000" maxBufferPx="5000">
      <div *cdkVirtualFor="let leaderboardCell of this.getCellsToDisplay()">
        <app-leaderboard-cell 
          [showImage]="this.getShouldShowImage()" 
          [forOptionInput]="this.getForOption()" 
          [leaderboardCellInput]="leaderboardCell"
          [selectedIndexInput]="this.selectedIndex"
          [showBar]="viewType === 'bar_list'">
        </app-leaderboard-cell>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div *ngIf="viewType === 'grid'" style="display: block; margin: auto; max-width: 1200px;  padding: 0 10px 0 10px;">
    <main>
      <div *ngFor='let cell of this.getCellsToDisplay(); let i = index;'>
        <article>
          <app-leaderboard-grid-cell [forOptionInput]="this.getForOption()" [leaderboardCellInput]="cell"
            [selectedIndexInput]="this.selectedIndex">
          </app-leaderboard-grid-cell>
        </article>
      </div>
    </main>
  </div>

</ion-content>

<span  *ngIf="id === '0'">
  <app-my-footer #footerElement>
  </app-my-footer>
</span>