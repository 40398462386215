import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';

import { Device, DeviceInfo } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetailsService {

    constructor(public platform: Platform) { 
        this.loadDeviceInfo();
    }

    private loadDeviceInfo(): void {
    }

    public async getDeviceInfo(): Promise<DeviceInfo> {
        return await Device.getInfo();
    }
}