<button class="filter-button" mat-button [matMenuTriggerFor]="filterMenu">
  {{this.getSelectedFilter()}}
  <i class="material-icons">
    arrow_drop_down
  </i>
</button>

<mat-menu #filterMenu="matMenu">
  <button mat-menu-item *ngFor="let key of this.getKeys()"
    [ngClass]="{'selected': key === this.selectedFilter, 'not-selected': key !== this.selectedFilter }"
    (click)="updateSelection(key)" style="text-align: center;">
    {{this.filter?.map[key]}}
  </button>

  <mat-divider *ngIf="filter?.title?.toLowerCase()?.includes('time')"></mat-divider>

  <button mat-menu-item *ngIf="filter?.title?.toLowerCase()?.includes('time')"
    [ngClass]="{'selected': this.selectedFilter === 'Custom Date' , 'not-selected': this.selectedFilter !== 'Custom Date' }"
    (click)="updateSelection('Custom Date')" style="text-align: center;">

    Custom Date
  </button>

</mat-menu>