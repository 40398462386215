<ion-content class="app-background-color" style="min-width: 620px; min-height: 80vh;">

  <ion-toolbar class="transparent-bg" style="max-width: 600px; display: block; margin: auto;">
    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      <button mat-icon-button (click)="cancel()">
        <span class="material-icons">
          close
        </span>
      </button>
    </div>


    <div slot="end">
      <button mat-button class="primary-color" (click)="create()">
        Save
      </button>
    </div>
  </ion-toolbar>

  <div style="padding: 10px; max-width: 600px; display: block; margin: auto;">
    <button (click)="fileInput.click()" *ngIf="this.series.backgroundImage"
      style="width: 75px; height: 120px; display: block; margin: auto; background-image: url({{this.series.backgroundImage}}); background-size: cover; object-fit: cover; background-repeat: no-repeat; background-position: center; "
      mat-raised-button>
      <span class="material-icons" style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
        photo_camera
      </span>
    </button>



    <button (click)="fileInput.click()" *ngIf="!this.series.backgroundImage && !this.series.backgroundImageFile"
      style="width: 75px; height: 120px; display: block; margin: auto; background-image: url('../../../assets/placeholder-image.png'); object-fit: cover; background-position: center;  background-size: cover; background-repeat: no-repeat;"
      mat-raised-button>
      <span style="padding-top: 50px; text-shadow: 0px 1px 4px #000000; color:white;">
      </span>
    </button>

    <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="image">

    <ion-col size="12" style="padding: 0; margin: 0;">
      <ion-row style="padding: 0; margin: 0;">
        <mat-form-field style="width: 100%;">
          <input [(ngModel)]="this.series.title" class="non-bolded-placeholder" matInput placeholder="Series Title"
            style="width: 100%;">
        </mat-form-field>
      </ion-row>


      <ion-row>
        <mat-form-field style="width: 100%;">
          <mat-label>Section</mat-label>
          <mat-select [(ngModel)]="this.series.section">
            <mat-option *ngFor="let s of getSectionOptions()" [value]="s.sectionTitle">
              {{s.sectionTitle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ion-row>

    </ion-col>


    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <p>{{this.series.resources.length}} Resource<span *ngIf="this.series.resources.length !== 1">s</span></p>
      </div>

      <div slot="end">
        <button mat-raised-button class="primary-color" (click)="addNewVideo()" style="display: block; margin: auto;">
          <span class="material-icons">
            add
          </span>
          
        </button>
      </div>
    </ion-toolbar>

    <ion-toolbar class="transparent-bg" *ngFor="let video of this.series.resources">
      <div slot="start">
        <app-video-card [displayMode]="'small'" style="display: block; margin: auto;" [video]="video"></app-video-card>
      </div>
      <div slot="start">
        <div style="width: 10px;"> </div>
      </div>
      <div slot="start" style='font-size: 12px;'>
        <ion-row>
          <input style="min-width: 300px;" matInput type="text" [(ngModel)]="video.title">
        </ion-row>

        <ion-row style="margin-top: 10px;">
          <input style="min-width: 300px;" class="opaque-text" matInput type="text" [(ngModel)]="video.trainerName">
        </ion-row>

      </div>
 

      <div slot="end">
        <button mat-icon-button (click)="editVideo(video)">
          <span class="material-icons">
            edit
          </span>
        </button>
      </div>

      <div slot="end">
        <button mat-icon-button (click)="deleteVideo(video)">
          <span class="material-icons">
            delete
          </span>
        </button>
      </div>


      <!--  -->
    </ion-toolbar>



  </div>
</ion-content>