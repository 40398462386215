import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { StratFieldEnum } from 'src/app/shared/enums/stratFieldEnum';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { CompanyRecordsStoreService } from 'src/app/shared/services/company-records-store.service';
@Component({
  selector: 'app-company-records',
  templateUrl: './company-records.component.html',
  styleUrls: ['./company-records.component.scss'],
})
export class CompanyRecordsComponent implements OnInit {

  @Input()
  reports: DashboardData[];

  @Input()
  title: string;

  @Output() showAnalytics = new EventEmitter<any>();

  @Output() messagePeopleInReportEmitter = new EventEmitter<any>();
  @Output() removeFavoriteEmitter = new EventEmitter<any>();
  @Output() downloadEmitter = new EventEmitter<any>();


  companyRecords_individual: DashboardData[];
  companyRecords_team: DashboardData[];
  companyRecords_region: DashboardData[];
  companyRecords_company: DashboardData[];
  companyRecords_my: DashboardData[];


  currentOptions_individual: DashboardData[] = [];
  currentOptions_team: DashboardData[] = [];
  currentOptions_region: DashboardData[] = [];
  currentOptions_company: DashboardData[] = [];
  currentOptions_my: DashboardData[] = [];



  constructor(public dialog: MatDialog, public companyRecordsStoreService: CompanyRecordsStoreService) {
    if (this.reports && this.reports.length > 0) {
    }
  }


  
  ngOnInit() {
    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    this.initCompanyRecords();

  }

  showAdvancedAnalytics(report): void {
    this.showAnalytics.emit(report);
  }

  messagePeopleInReport(report): void {
    this.messagePeopleInReportEmitter.emit(report);
  }

  removeFavorite(report): void {
    this.removeFavoriteEmitter.emit(report);
  }

  download(report): void {
    this.downloadEmitter.emit(report);
  }

  isMessagableReport(report: DashboardData) {
    return report.reportMenu.selectedStratField === StratFieldEnum.SalesRep || report.reportMenu.selectedStratField === StratFieldEnum.SalesRepRegion || report.reportMenu.selectedStratField === StratFieldEnum.SalesRepTeam || report.reportMenu.selectedStratField === StratFieldEnum.Company
  }


  searchOn: boolean = false;
  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();
  }
  @ViewChild('searchInput') searchElement: ElementRef;

  searchValue: string = "";
  searchSend() {
    this.currentOptions_individual = this.companyRecords_individual.filter(r => (r.freshReport.reportName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.currentOptions_team = this.companyRecords_team.filter(r => (r.freshReport.reportName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.currentOptions_region = this.companyRecords_region.filter(r => (r.freshReport.reportName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.currentOptions_company = this.companyRecords_company.filter(r => (r.freshReport.reportName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.currentOptions_my = this.companyRecords_my.filter(r => (r.freshReport.reportName).toLowerCase().includes(this.searchValue.toLowerCase()));


  }




  async initCompanyRecords() {

    await this.companyRecordsStoreService.initializeCompanyRecords();


    this.companyRecordsStoreService.companyRecords_individual.subscribe(nextList => {
      this.companyRecords_individual = nextList;
      this.currentOptions_individual = this.companyRecords_individual;
    });


    this.companyRecordsStoreService.companyRecords_team.subscribe(nextList => {
      this.companyRecords_team = nextList;
      this.currentOptions_team = this.companyRecords_team;

    });

    this.companyRecordsStoreService.companyRecords_region.subscribe(nextList => {
      this.companyRecords_region = nextList;
      this.currentOptions_region = this.companyRecords_region;

    });

    this.companyRecordsStoreService.companyRecords_company.subscribe(nextList => {
      this.companyRecords_company = nextList;
      this.currentOptions_company = this.companyRecords_company;

    });

    this.companyRecordsStoreService.companyRecords_my.subscribe(nextList => {
      this.companyRecords_my = nextList;
      this.currentOptions_my = this.companyRecords_my;


    });



  }





}
