<div style=" overflow-y: hidden; height: 100vh;">


  <ion-header *ngIf="drillDown === false">
    <div style="width: 100%; z-index: 999;">
      <ion-toolbar no-border class="whiteHeader">
        <button mat-fab class="circle-button" style="text-align: center; box-shadow: none; margin-left: -5px;"
          (click)="back()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>

        <p style="text-align: center; padding-left: 15px; font-size: 22px"> Filters</p>
        

      </ion-toolbar>
    </div>
  </ion-header>

  <ion-header *ngIf="drillDown === true">
    <div style="width: 100%; z-index: 999;">
      <ion-toolbar no-border class="whiteHeader">
        <button mat-fab class="circle-button" style="text-align: center; box-shadow: none; margin-left: -5px;"
          (click)="backDrillDown()">
          <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
        </button>
      </ion-toolbar>
    </div>
  </ion-header>

  <ion-content>
    <div class="darkTheme" style="padding: 10px;">
      <div style=" max-width: 600px; display: block; margin: auto;">





      <ion-grid *ngIf="drillDown === false;">

        <div *ngFor="let f of this.data.filterTextList; let i = index">

          <ion-row>
            <ion-col size="12" style="padding: 0; margin: 0">
              <button  class="select-button" mat-button>

                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col size="5" style="padding: 0; margin: 0; text-align: left;">
                      {{f.text}} &nbsp; &nbsp; &nbsp;
                    </ion-col>

                    <ion-col size="7" style="padding: 0; margin: 0; text-align: right;">
                      <span  *ngIf="f.includeValue !== null">
                        {{ (f.includeValue.length>21) ? (f.includeValue | slice:0:21)+'...' : (f.includeValue |
                        slice:0:-2) }}
                      </span>

                      <span style="color: rgba(255, 255, 255, 0.6); float: right;" *ngIf="f.excludeValue !== null">
                        {{(f.excludeValue.length>21)? (f.excludeValue | slice:0:21)+'...':(f.excludeValue| slice:0:-2)}}
                      </span>
                    </ion-col>

                    <!-- <ion-col size="1" style="padding: 0; margin: 0;">
                      <span class="material-icons"
                        style="float: right; padding-top: 7px; text-align: right; margin-right: -5px;">
                        keyboard_arrow_right
                      </span>
                    </ion-col> -->

                  </ion-row>
                </ion-grid>




              </button>
            </ion-col>
          </ion-row>

        </div>




        <!-- DATES -->
        <div *ngFor="let f of this.data.filterDateList; let i = index">

          <div *ngIf="i === 0 && i !== 1">

            <ion-row>
              <ion-col size="12" style="padding: 0; margin: 0">
                <button class="select-button" mat-button>

                  <ion-grid style="padding: 0; margin: 0;">
                    <ion-row style="padding: 0; margin: 0;">
                      <ion-col size="5" style="padding: 0; margin: 0; text-align: left;">
                        Sales Date &nbsp; &nbsp; &nbsp;
                      </ion-col>

                      <ion-col size="7" style="padding: 0; margin: 0; text-align: right;">
                        <span  *ngIf="f.minValue !== null">
                          {{f.minValue}},
                        </span>
                        <span  *ngIf="f.maxValue !== null">
                          {{f.maxValue}}
                        </span>

                        <span  *ngIf="f.timePeriodValue !== null">
                          {{f.timePeriodValue | titlecase}}
                        </span>
                      </ion-col>

                      <!-- <ion-col size="1" style="padding: 0; margin: 0;">
                        <span class="material-icons"
                          style="float: right; padding-top: 7px; text-align: right; margin-right: -5px;">
                          keyboard_arrow_right
                        </span>
                      </ion-col> -->

                    </ion-row>
                  </ion-grid>

                </button>
              </ion-col>
            </ion-row>
          </div>

          <div *ngIf="i !== 0 && i === 1">


            <ion-row>
              <ion-col size="12" style="padding: 0; margin: 0">
                <button  class="select-button" mat-button>


                  <ion-grid style="padding: 0; margin: 0;">
                    <ion-row style="padding: 0; margin: 0;">
                      <ion-col size="5" style="padding: 0; margin: 0; text-align: left;">
                        Installs Date &nbsp; &nbsp; &nbsp;
                      </ion-col>

                      <ion-col size="7" style="padding: 0; margin: 0; text-align: right;">
                        <span  *ngIf="f.minValue !== null">
                          {{f.minValue}},
                        </span>
                        <span  *ngIf="f.maxValue !== null">
                          {{f.maxValue}}
                        </span>

                        <span  *ngIf="f.timePeriodValue !== null">
                          {{f.timePeriodValue | titlecase}}
                        </span>
                      </ion-col>

                      <!-- <ion-col size="1" style="padding: 0; margin: 0;">
                        <span class="material-icons"
                          style="float: right; padding-top: 7px; text-align: right; margin-right: -5px;">
                          keyboard_arrow_right
                        </span>
                      </ion-col> -->

                    </ion-row>
                  </ion-grid>

                </button>
              </ion-col>
            </ion-row>
          </div>
        </div>


        <div *ngFor="let f of this.data.filterNumberList; let i = index">

          <ion-row>
            <ion-col size="12" style="padding: 0; margin: 0">
              <button  class="select-button" mat-button>


                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col size="5" style="padding: 0; margin: 0; text-align: left;">
                      Gross System Price &nbsp; &nbsp; &nbsp;
                    </ion-col>

                    <ion-col size="7" style="padding: 0; margin: 0; text-align: right;">
                      <span  *ngIf="f.value !== null">
                        {{f.value}}
                      </span>
                      <span  *ngIf="f.excludeValue !== null">
                        {{f.excludeValue}}
                      </span>
                    </ion-col>

                    <!-- <ion-col size="1" style="padding: 0; margin: 0;">
                      <span class="material-icons"
                        style="float: right; padding-top: 7px; text-align: right; margin-right: -5px;">
                        keyboard_arrow_right
                      </span>
                    </ion-col> -->

                  </ion-row>
                </ion-grid>
              </button>
            </ion-col>
          </ion-row>



        </div>

        <div style="height: 20px;"> </div>



      </ion-grid>

      <!-- ================================================================================================== -->

      <ion-grid *ngIf="drillDown === true;">


        <!-- TEXT FILTERS -->
        <div *ngFor="let f of this.data.filterTextList; let i = index">
          <div *ngIf="f.text === this.selectedDrillDownFilter.text">
            <ion-row>


              <ion-col size="12">
                <p style="text-align: center; font-size: 20px; padding-top: 10px;  margin: 0;">
                  {{this.selectedDrillDownFilter.text}} </p>
              </ion-col>
            </ion-row>
            <ion-row style="padding-top: 10px; padding-bottom: 10px;">
              <div style="width: 100%">



                <!-- INCLUDE -->
                <ion-col size="6">


                  <!-- If we want autocomplete -->
                  <div *ngIf="this.data.groupByString === this.selectedDrillDownFilter.text">
                    <mat-form-field style="width: 100%" class="example-chip-list">
                      <mat-chip-list #chipListInclude>
                        <mat-chip *ngFor="let fruit of frontEndFilters[i].selectedInclude" [selectable]="selectable"
                          [removable]="removable" (removed)="removeInclude(fruit, i)">
                          {{fruit}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input #autoInclude  placeholder="Include" #fruitInput1
                          [formControl]="inputCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipListInclude"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="addInclude($event, i)">
                      </mat-chip-list>

                      <mat-autocomplete class="single-autocomplete" #auto="matAutocomplete"
                        (optionSelected)="selectedInclude($event, i)">
                        <mat-option style="color:white;" *ngFor="let option of filteredOptions | async; let i = index"
                          [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>


                  <!-- No Autocomplete -->
                  <div *ngIf="this.data.groupByString !== this.selectedDrillDownFilter.text">
                    <mat-form-field style="width: 100%" class="example-chip-list">
                      <mat-chip-list #chipListInclude>
                        <mat-chip *ngFor="let fruit of frontEndFilters[i].selectedInclude" [selectable]="selectable"
                          [removable]="removable" (removed)="removeInclude(fruit, i)">
                          {{fruit}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input  placeholder="Include" #fruitInput2 [formControl]="inputCtrl"
                          [matChipInputFor]="chipListInclude" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="addInclude($event, i)">
                      </mat-chip-list>
                    </mat-form-field>
                  </div>



                </ion-col>


                <!-- EXCLUDE -->
                <ion-col size="6">

                  <!-- If we want autocomplete -->
                  <div *ngIf="this.data.groupByString === this.selectedDrillDownFilter.text">
                    <mat-form-field class="example-chip-list">
                      <mat-chip-list #chipListExclude>
                        <mat-chip *ngFor="let fruit of frontEndFilters[i].selectedExclude" [selectable]="selectable"
                          [removable]="removable" (removed)="removeExclude(fruit, i)">
                          {{fruit}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input #autoExclude  placeholder="Exclude" #fruitInput3
                          [formControl]="inputCtrlExclude" [matAutocomplete]="auto2" [matChipInputFor]="chipListExclude"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="addExclude($event, i)">
                      </mat-chip-list>
                      <mat-autocomplete class="single-autocomplete" #auto2="matAutocomplete"
                        (optionSelected)="selectedExclude($event.option.value, i)">
                        <mat-option style="color:white;"
                          *ngFor="let option of filteredOptionsExclude | async; let i = index" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>


                  <!-- No Autocomplete -->
                  <div *ngIf="this.data.groupByString !== this.selectedDrillDownFilter.text">
                    <mat-form-field class="example-chip-list">
                      <mat-chip-list #chipListExclude>
                        <mat-chip *ngFor="let fruit of frontEndFilters[i].selectedExclude" [selectable]="selectable"
                          [removable]="removable" (removed)="removeExclude(fruit, i)">
                          {{fruit}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input #autoExclude  placeholder="Exclude" #fruitInput3
                          [formControl]="inputCtrlExclude" [matChipInputFor]="chipListExclude"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="addExclude($event, i)">
                      </mat-chip-list>
                    </mat-form-field>
                  </div>




                </ion-col>


              </div>
            </ion-row>

          </div>
        </div>


        <!-- DATE FILTERS -->
        <div *ngFor="let f of this.data.filterDateList; let i = index">

          <div *ngIf="f.minText === this.selectedDrillDownFilter.minText">

            <ion-row>
              <ion-col size="12">
                <p *ngIf="i === 0 && i !== 1" style="text-align: center; padding-top: 10px;  margin: 0; font-size: 20px;"> Sales
                  Date </p>
                <p *ngIf="i !== 0 && i === 1" style="text-align: center; padding-top: 10px;  margin: 0; font-size: 20px;"> Installs
                  Date </p>
              </ion-col>
            </ion-row>

            <ion-row>
              <div style="width: 100%;">

                <ion-grid>
                  <ion-row>
                    <ion-col size="12">
                      <mat-tab-group mat-stretch-tabs>
                        <mat-tab label="Time Period">
                          <div style="height: 20px;"> </div>
                          <mat-form-field class="example-chip-list">


                            <input  matInput placeholder="Include" [formControl]="inputCtrlDate"
                              [matAutocomplete]="autoSaleDate" [(ngModel)]="f.timePeriodValue">
                            <button mat-button *ngIf="f.timePeriodValue" matSuffix mat-icon-button aria-label="Clear"
                              >
                              <mat-icon >close</mat-icon>
                            </button>

                            <mat-autocomplete #autoSaleDate="matAutocomplete">
                              <mat-option style=""
                                *ngFor="let option of filteredOptionsDate | async; let i = index" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-autocomplete>

                          </mat-form-field>


                        </mat-tab>
                        <mat-tab label="Exact Dates">


                          <ion-col size="6">
                            <mat-form-field class="full-width">
                              <mat-label>{{f.minText}}</mat-label>
                              <input  matInput [matDatepicker]="date1" [(ngModel)]="f.minValue">
                              <mat-datepicker-toggle matSuffix [for]="date1"></mat-datepicker-toggle>
                              <mat-datepicker #date1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                          </ion-col>

                          <ion-col size="6">
                            <mat-form-field class="full-width">
                              <mat-label>{{f.maxText}}</mat-label>
                              <input  matInput [matDatepicker]="date2" [(ngModel)]="f.maxValue">
                              <mat-datepicker-toggle matSuffix [for]="date2"></mat-datepicker-toggle>
                              <mat-datepicker #date2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                          </ion-col>

                        </mat-tab>
                      </mat-tab-group>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </ion-row>

          </div>

        </div>

        <div *ngFor="let f of this.data.filterNumberList; let i = index">
          <div *ngIf="f.minText === this.selectedDrillDownFilter.minText">

            <ion-row>
              <ion-col size="12">
                <p style="text-align: center; font-size: 20px; padding-top: 10px;  margin: 0;">Gross System Price</p>
              </ion-col>
            </ion-row>
            <ion-row style="padding-top: 10px; padding-bottom: 10px;">
              <div style="width: 100%;">

                <ion-col size="6">
                  <mat-form-field class="full-width">
                    <mat-label>{{f.minText}}</mat-label>
                    <input  matInput [(ngModel)]="f.value">
                  </mat-form-field>
                </ion-col>

                <ion-col size="6">
                  <mat-form-field class="full-width">
                    <mat-label>{{f.maxText}}</mat-label>
                    <input  matInput [(ngModel)]="f.excludeValue">
                  </mat-form-field>
                </ion-col>



              </div>
            </ion-row>
          </div>

        </div>
        <div *ngIf="this.filterType === 'text' ">
          <!-- <div style="padding: 0px" *ngIf="this.inputCtrl.value === '' && this.inputCtrlExclude.value === ''">
            <button mat-button (click)="apply()" style="width: 100%; background-color: #00BCE1; color:white;"> Apply
              Filter </button>
          </div> -->

          <div style="padding: 0px" *ngIf="this.inputCtrl.value !== '' || this.inputCtrlExclude.value !== ''">
            <button mat-button 
              style="width: 100%;"> Add Filter </button>
          </div>
        </div>

       


        <div style="height: 10px"> </div>

        <!-- <div style="padding: 0px">
      <button mat-button (click)="clearSingle(selectedDrillDownFilter)" style="background-color: rgba(255, 255, 255, 0.1); "> Clear Filter </button>
    </div> -->

      </ion-grid>


      </div>
    </div>

    <!-- <mat-toolbar *ngIf="!this.drillDown"
      style=" width: 100%;  height: 60px; position: sticky;bottom: 110px; background-color: #3f4d58; padding: 3px; z-index: 999;">
      <button mat-button (click)="save()" style="width: 100%; background-color: #00BCE1; color:white;"> Apply All
      </button>
      <div style="height: 10px"> </div>
      <button mat-button (click)="clearAll()"
        style="width: 100%; background-color: rgba(255, 255, 255, 0.0); "> Clear All </button>
    </mat-toolbar> -->


    <!-- <button *ngIf="!this.drillDown" (click)="save()" style="width: 60px; height: 60px; border-radius: 30px; position: fixed; bottom: 50px; right: 15px; z-index: 1000; background-color: #00BCE1; " mat-raised-button>
      <i class="material-icons">
        save
      </i>
    </button> -->


    <!-- <button *ngIf="!this.drillDown" (click)="clearAll()" style="width: 60px; height: 60px; border-radius: 30px; position: fixed; bottom: 50px; right: 15px; z-index: 1000; background-color: #00BCE1; " mat-raised-button>
      <i class="material-icons">
        delete
      </i>
    </button> -->

  </ion-content>

</div>