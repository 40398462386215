import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-expand-image-modal',
  templateUrl: './expand-image-modal.component.html',
  styleUrls: ['./expand-image-modal.component.scss'],
})
export class ExpandImageModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExpandImageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
   }

  ngOnInit() {}

  sliderOpt = {
    zoom: {
      maxRatio: 1,
    },
  };

}
