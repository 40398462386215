<div class="app-background-color container" style="height: 100%; width: 100%;">

  <div class="content">

    <mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar>

    <div style="height: 50px;"></div>
    <h1 style="font-size: 28px"> {{this.data?.response?.header}}
    </h1>

    <h1 style="font-size: 60px"> {{this.data?.response?.iconString}}
    </h1>

    <br>
    <img [src]="this.image" style="max-height: 200px; border-radius: 10px; -webkit-box-shadow: 0px 3px 16px -6px #000000; 
    box-shadow: 0px 3px 16px -6px #000000;" />

  </div>


</div>