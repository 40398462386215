<div class="table-scroll" style="background-color: transparent; overflow-x: scroll; overflow-y: scroll; display: block; margin: auto; "
[ngStyle]="{'max-width': this.getWidth() + 'px'}"
>
  <table mat-table [dataSource]="dataSource" matSort style="min-width: 100% !important; background-color: transparent;">

    
    <ng-container matColumnDef="date"  style="height: 10px;">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf='selectUserMode' style="padding-left: 3px;">
          <mat-checkbox [(ngModel)]="selectAll" (ngModelChange)="selectAllClicked()"></mat-checkbox>
        </span>
        <span [ngStyle]="selectUserMode ? {'padding-left' : '15px'} : {'padding-left' : '0px'}">
          {{this.getXHeader() | uppercase}}
        </span>
      </th>
      <td style='z-index: 900;' mat-cell *matCellDef="let row" (click)='select(row)'
        style=" text-align: left; font-size: 14px; ">
        <span *ngIf="getXFromCell(row) !== null" style="margin-top: -15px; font-weight: 100; height: 100%;">
          <label *ngIf='selectUserMode' style="padding-left: 5px; padding-right: 13px; height: 100%;">

            <mat-checkbox style='z-index: 1000; width: 100px' [(ngModel)]="row.isSelected" (ngModelChange)="selectRow(row)"
              (click)="$event.stopPropagation()"></mat-checkbox>
          </label>
          <span>
            {{getXFromCell(row)}}
          </span>
        </span>
        <span *ngIf="getXFromCell(row) === null" style="margin-top: -15px; font-weight: 100;"> User </span>
      </td>
    </ng-container>

    <ng-container *ngFor='let col of this.getSelectedHeaders(); let i = index' style="margin-right:50px">
      <ng-container [matColumnDef]='col' style="height: 10px; min-width: 500px; max-width: 500px ;">
        <th mat-header-cell *matHeaderCellDef style="text-align: left;"> {{col | uppercase}} </th>
        <td mat-cell *matCellDef="let row"
          style="text-align: left;  font-size: 14px; margin-top: -15px; font-weight: 100">

          {{this.getValueForRowCol(row, i + 1)}}
        </td>
      </ng-container>
    </ng-container>

    <!-- 
    <ng-container matColumnDef="dataPointValue" [sticky]="true" style="height: 10px">
      <th mat-header-cell *matHeaderCellDef> {{this.yHeader}} </th>
      <td mat-cell *matCellDef="let row" class="truncated special-app-text-color" style="text-align: right;  font-size: 14px; margin-top: -15px; "> {{row.y}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="getDisplayColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayColumns();" class="table-row"></tr>
  </table>
</div>