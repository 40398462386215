import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DashboardAlertModel } from 'src/app/cr-dashboard/cr-dashboard.service';
import { genericHumanFriendlyTime } from 'src/app/shared/services/dates.service';

@Component({
  selector: 'enzy-accuracy-modal',
  templateUrl: './accuracy-modal.component.html',
  styleUrls: ['./accuracy-modal.component.scss'],
})
export class AccuracyModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AccuracyModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {res: DashboardAlertModel[][]}, 
    public dialog: MatDialog,) {
   }

  ngOnInit() {}

  getTitle(): string {
    return "Today's Accuracy Issues";
  }

  getSurveyTitle(alert: DashboardAlertModel): string {
    // if (!alert) {return ""}
    return alert?.surveyTitle || "";
  }

  getSessionResponses() {
    return this.data?.res;
  }

  getOrderedResponses(responses: DashboardAlertModel[]): DashboardAlertModel[] {
    return responses.sort((a,b)=> a.surveyId - b.surveyId).filter(s => !!s.surveyId);
  }

  getTimeStamp(alert: DashboardAlertModel) {
    if (!alert) {return ""}
    return genericHumanFriendlyTime(alert.timeStamp)
  }

  getResponse(alert: DashboardAlertModel) {    
    if (!alert || !alert.response || !alert.surveyId) {return}
    // if (alert.response === "Accuracy" || alert.response === "THUMBS_UP" || alert.response === "THUMBS_DOWN" || alert.response === "1" || alert.response === "2" || alert.response === "3" ) {return}

    let response = alert.response;

    if (response?.toLowerCase().includes("drink")) { response = response + " 🥤"}
    else if (response?.toLowerCase().includes("dessert")) { response = response + " 🍮"}
    else if (response?.toLowerCase().includes("wrong")) { response = response + " ❌"}
    else if (response?.toLowerCase().includes("utensils")) { response = response + " 🍴"}

  

    return response
  }

}
