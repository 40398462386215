
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, from, Observable } from "rxjs";
import { Incentive, Prize } from "../models/incentives.model";
import { UserDetailsService } from "./user-details.service";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { IncentivesService } from "./incentives.service";

@Injectable({
  providedIn: 'root'
})
export class IncentivesStoreService {

  private _incentives: BehaviorSubject<Incentive[]> = new BehaviorSubject([]);
  private _prizes: BehaviorSubject<Prize[]> = new BehaviorSubject([]);
  private _installCount: BehaviorSubject<number> = new BehaviorSubject(0);

  public readonly incentives: Observable<Incentive[]> = this._incentives.asObservable();
  public readonly prizes: Observable<Prize[]> = this._prizes.asObservable();
  public readonly installCount: Observable<number> = this._installCount.asObservable();

  private prizesMap: Map<string, Prize[]> = new Map<string, Prize[]>();
  private incentiveInstallMap: Map<string, number> = new Map<string, number>();

  constructor(public userDetailsService: UserDetailsService, public httpClient: HttpClient, public localStorage: Storage, public incentivesService: IncentivesService) {
    this.initializeIncentives();
  }

  async initializeIncentives() {
    // await this.localStorage.create();
    // await this.localStorage.defineDriver(CordovaSQLiteDriver);
    await this.initFromCachedIncentives();
    this.fetchLatestIncentives();
  }

  async fetchLatestIncentives() {
    const result: any = await this.incentivesService.getIncentives();
    this.extractIncentivesFromResponse(result);
    this.cacheIncentivesList();
  }

  private extractIncentivesFromResponse(res: any) {
    const tempIncentives: Incentive[] = [];

    res.forEach(element => {
      let incentive: Incentive = {
        id: element.campaignId,
        backgroundURL: element.backgroundURL,
        thumbnailUrl: element.thumbnailUrl,
        campaignName: element.campaignName,
        rank: null,
        groupType: "region",
        campaignType: element.campaignType,
        startDate: element.startDate,
        endDate: element.endDate,
        isLocked: element.isLocked
      }

      tempIncentives.push(incentive);
    });
    this._incentives.next(tempIncentives);

  }

  async fetchCurrentPrizes(campaignId: string): Promise<any> {
    this.setPrizeListFromMap(campaignId);

    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const userId: string = await this.userDetailsService.getUserId();
    const result = await this.incentivesService.getIncentiveMilestoneStatusForUser(sessionKey, campaignId, userId);

    const newPrizeList: Prize[] = result?.milestoneList;
    if (!newPrizeList) return;
    this._prizes.next(newPrizeList);
    this.prizesMap[campaignId] = newPrizeList;

    const newInstallCount: number = result.myValue;
    this._installCount.next(newInstallCount);
    this.incentiveInstallMap[campaignId] = newInstallCount;

    this.cachePrizesMap();
    this.cacheInstallTotalMap();
  }

  private setPrizeListFromMap(campaignId: string): void {
    this._prizes.next(this.prizesMap[campaignId]);
    this._installCount.next(this.incentiveInstallMap[campaignId]);
  }

  async initFromCachedIncentives() {
    await this.loadCachedIncentives();
    await this.loadCachedPrizesMap();
    await this.loadCachedIncentiveInstallMap();
  }

  private async cacheIncentivesList(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("INCENTIVES_LIST", JSON.stringify(this._incentives.getValue()));
      console.log("CACHED INCENTIVES")
    }, 1000);
  }

  private async cachePrizesMap(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("PRIZES_MAP", JSON.stringify(this.prizesMap));
      console.log("CACHED PRIZES MAP")
    }, 1000);
  }

  private async cacheInstallTotalMap(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("INCENTIVE_INSTALL_MAP", JSON.stringify(this.incentiveInstallMap));
      console.log("CACHED PRIZES MAP")
    }, 1000);
  }

  private async loadCachedIncentives(): Promise<Incentive[]> {
    const currentListOfIncentives: Incentive[] = this._incentives.getValue();

    if (currentListOfIncentives?.length > 0) return;
    const res = await this.localStorage.get("INCENTIVES_LIST");
    if (!res) return;
    const cachedListOfIncentives: Incentive[] = JSON.parse(res);

    if (cachedListOfIncentives?.length > 0) {
      this._incentives.next(cachedListOfIncentives);
    }
    // console.log("FINISHED LOADING INCENTIVES FROM LOCAL STORAGE");

  }


  private async loadCachedPrizesMap(): Promise<Incentive[]> {

    if (this.prizesMap?.keys?.length > 0) return;
    const res = await this.localStorage.get("PRIZES_MAP");
    if (!res) return;
    const cachedMap: Map<string, Prize[]> = JSON.parse(res);

    if (Object.keys(cachedMap).length > 0) {
      this.prizesMap = cachedMap;
    }
    // console.log("FINISHED LOADING PRIZES FROM LOCAL STORAGE");

  }

  private async loadCachedIncentiveInstallMap(): Promise<Incentive[]> {

    if (this.incentiveInstallMap?.keys?.length > 0) return;
    const res = await this.localStorage.get("INCENTIVE_INSTALL_MAP");
    if (!res) return;
    const cachedMap: Map<string, number> = JSON.parse(res);

    if (Object.keys(cachedMap).length > 0) {
      this.incentiveInstallMap = cachedMap;
    }
    // console.log("FINISHED LOADING PRIZES FROM LOCAL STORAGE");

  }

}
