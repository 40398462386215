<div style="padding: 20px" class="app-background-color app-text-color">
  <!-- <p>
    {{this.prompt}}
  </p> -->
  <form style="width: 100%" class="example-form">
    <mat-form-field style="width: 100%">
      <mat-label>{{this.title}}</mat-label>
      <input matInput [(ngModel)]="this.textToSubmit" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
  </form>

  <button (click)="submit()" mat-button style="width: 100%; background-color: #00BCE1; color: white;">{{submitButtonString}}</button>
</div>

