import { Component, OnInit } from "@angular/core";
import { LeadInfoComponent } from "./lead-info.component";

@Component({
    selector: 'app-test-extending-lead-info',
    templateUrl: './lead-info.component.html',
    styleUrls: ['./lead-info.component.scss'],
  })
  export class TestExtendLeadInfoComponent extends LeadInfoComponent implements OnInit {

    
  }
