<div class="opaque-div" style="width: 100%; border-radius: 10px;">
  <ion-toolbar class="transparent-bg" style="width: 100%;">
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>
    <div slot="start">
      <mat-checkbox (change)="check($event)"  (click)="$event.stopPropagation()"
      [(ngModel)]="task.isCompleted"></mat-checkbox>
    </div>
    <div slot="start">
      <div style="width: 10px;"></div>
    </div>


    <div slot="start">
      {{task.description}}
    </div>


    <div slot="end">
      {{  getDate()}}
    </div>
    <div slot="end">
      <div style="width: 5px;"></div>
    </div>
  </ion-toolbar>
</div>