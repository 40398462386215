<div class="app-background-color" style="padding: 0; width: 100%; height: 400px;">

  <div *ngIf='!this.singleSelect'>
    <ion-toolbar>
      <div slot="end">
        <button class="primary-color" mat-raised-button (click)="close()">
          Done
        </button>
      </div>
      <div slot="end">
        <div style="width: 10px;"></div>
      </div>
    </ion-toolbar>
  </div>

  <mat-form-field class="example-chip-list" style="padding: 10px;">
    <mat-label>Selected</mat-label>

    <mat-chip-list #chipList>



      <mat-chip *ngFor="let item of selectedItemsToShow(); let i = index;" [selectable]="false" [removable]="true" (removed)="unselectItem(i)">
        {{getSentenceCase(item)}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <ion-toolbar class="transparent-bg">
        <input #searchInput [(ngModel)]="searchValue" [matChipInputFor]="chipList">
      </ion-toolbar>

    </mat-chip-list>
  </mat-form-field>

  <div *ngFor="let option of getAllOptions()" class="app-background-color">
    <button style="width: 100%; text-align: left;" (click)="add(option)" mat-button>{{getSentenceCase(option)}}</button>
  </div>

</div>