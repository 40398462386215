import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'enzy-start-end-date-picker',
  templateUrl: './start-end-date-picker.component.html',
  styleUrls: ['./start-end-date-picker.component.scss'],
})
export class StartEndDatePickerComponent implements OnInit {
  
  @Input() startEndTime: {startTime: string, endTime: string};
  
  constructor() { }

  ngOnInit() {}


  // TODO: add logic that keeps start and end date from being higher or lower

}
