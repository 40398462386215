import { Component, Input, OnInit } from '@angular/core';
import { Aggregate } from 'src/app/shared/models/report-cell-list.model';

@Component({
  selector: 'app-dashboard-card-footer',
  templateUrl: './dashboard-card-footer.component.html',
  styleUrls: ['./dashboard-card-footer.component.scss'],
})
export class DashboardCardFooterComponent implements OnInit {

  @Input()
  aggregate: Aggregate;

  @Input()
  onRankingsPage: boolean = false; 

  roundedAverage: number;

  
  constructor() { }

  ngOnInit() {
    this.roundedAverage = Math.round((this.aggregate.average + Number.EPSILON) * 10) / 10
  }

}
