import { DataReportMenu, DEFUALT_DATA_REPORT_MENU } from './dashboard-report-list.model';
import { Employee } from './employees.model';
export class Aggregate {
    average: number;
    averageString: string;
    count: number;
    countString: string;
    high: number;
    highString: string;
    low: number;
    lowString: string;
    total: number;
    totalString: string;
    totalFormatted: string;
}

export const DEFAULT_AGGREGATE: Aggregate = {
    average: null,
    averageString: null,
    count: null,
    countString: null,
    high: null,
    highString: null,
    low: null,
    lowString: null,
    total: null,
    totalString: null,
    totalFormatted: null,
}

export class ReportCellList {
    errorMessage: string;
    list: ReportCell[];
    listNav: ListNav;
    message: string;
    aggregate: Aggregate;
    menu: DataReportMenu;
    highlightedValue: string;
    dataTime: any;
    columnHeaders: string[];
}

export const DEFAULT_REPORT_CELL_LIST: ReportCellList = { 
    errorMessage: null,
    list: [],
    listNav: null, //WILL THIS CAUSE ISSUES?
    message: null,
    aggregate: {...DEFAULT_AGGREGATE},
    menu: {...DEFUALT_DATA_REPORT_MENU},
    highlightedValue: null,
    dataTime: null,
    columnHeaders: [],
}




export class ListNav {
    columnHeaderList: any[];
    headerHref: string;
    href: string;
    limit: number;
    nextDisabled: string;
    nextHref: string;
    numberShown: number;
    offset: number;
    offsetBeanList: any[];
    offsetLabel: string;
    previousDisabled: string;
    previousHref: string;
    showing: string;
    sortNameLabel: string;
}

export class ReportCell {
    cellList: ReportCellDataPoint[];
    rank?: number;
    trend?: number;
    isSelected: boolean;
}

export class ReportCellDataPoint {
    employee: Employee;
    employeeEmailAddress: any;
    value: string;
    imageUrl?: string;
    imageThumbnailUrl?: string;
}
