import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { EmployeeListModalComponent } from 'src/app/archived/employee-list-modal/employee-list-modal.component';
import { TwoTeamMatchup, Team } from 'src/app/shared/models/incentives.model';
import { MatchupParticipant } from 'src/app/shared/services/incentives.service';

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent implements OnInit {

  @Input() game: TwoTeamMatchup

  @Input() team1Participants: MatchupParticipant[] = [];
  @Input() team2Participants: MatchupParticipant[] = [];

  constructor(
    public router: Router,
    private dialog: MatDialog,
    ) { }

  ngOnInit() {}


  getTeam1Score(){
    return this.game.team1Score ? this.game.team1Score : 0;
  }

  getTeam2Score() {
    return this.game.team2Score ? this.game.team2Score : 0;

  }

  getTeam1Url(): string {
    return this.game.team1Url ? this.game.team1Url : "../../../assets/install.jpg";

  }

  getTeam2Url() {
    return this.game.team2Url ? this.game.team2Url : "../../../assets/install.jpg";

  }

  isWinner(team: number) {
    if (team === 1) {
      return this.game.team1Score > this.game.team2Score
    }

    else if (team === 2) {
      return this.game.team2Score > this.game.team1Score
    }
  }

  showTeam1Participants() {
    this.showModalOfParticipants(this.team1Participants);

  }

  showTeam2Participants() {
    this.showModalOfParticipants(this.team2Participants);
  }

  private showModalOfParticipants(participants: MatchupParticipant[]) {
    if (!participants || participants.length === 0) return;
    let scores: {[key: number]: number} = {};
    participants.forEach(p => scores[p.userId] = p.score);
    this.dialog.open(EmployeeListModalComponent, {
      panelClass: 'transparent',
      disableClose: false, 
      width: '500px',
      height: '500px',
      data: {scores}
    })
  }

}

