import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Employee } from '../../models/employees.model';

@Component({
  selector: 'app-user-picker-modal',
  templateUrl: './user-picker-modal.component.html',
  styleUrls: ['./user-picker-modal.component.scss'],
})
export class UserPickerModalComponent implements OnInit {

  // todo: should this select just one as well?
  selectSingleUser: boolean = false;
  selectedUserIds: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<UserPickerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectSingleUser?: boolean, selectedUserIds?: number[] },
  ) {
    if (data?.selectSingleUser !== null) {
      this.selectSingleUser = data.selectSingleUser;
    }
    if (!this.selectSingleUser && data?.selectedUserIds) {
      this.selectedUserIds = data.selectedUserIds;
    }
  }

  ngOnInit() { }

  handleUsersSelected(employees: Employee[]) {
    if (employees?.length > 0 && this.selectSingleUser) {
      const employeeId: number = employees[0]?.userId;
      this.dialogRef.close({ userIds: [employeeId] })

    }
  }



  // Close dialog and pass list of users down
  close() {
    this.dialogRef.close({ userIds: this.selectedUserIds })
  }

  cancel() {
    this.dialogRef.close();
  }
}
