
    <div>
      <h1>Round Information</h1>
    </div>


    <!-- <div style="height: 50px;" > -->
      <div cdkDropList cdkDropListOrientation="horizontal" class="round-example-list" (cdkDropListDropped)="roundDrop($event)">
        <div class="round-example-box" *ngFor="let round of this.rounds; let i = index" cdkDrag>
          <div>
            <button [ngStyle]="currentIndex === i ? {'background-color' : '#00BCE1'} : {'background-color' : 'white'}"
              class="filter-button" (click)='switchRound(i)'>
              {{round?.roundName}}
            </button>
          </div>          
        </div>
       
      </div>
    <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 70px;">
      
      <div style="width: 20px;"></div>
      <span>
        <button mat-raised-button class="filter-button primary-color" (click)='addNewRound()'>
          Add New Round
        </button>
      </span>
      <div style="width: 20px;"></div>
      <span>
        <button mat-raised-button class="filter-button primary-color" (click)='duplicateSelectedRound()'>
          Duplicate Selected Round
        </button>
      </span>
    </div>

    <div>
      <mat-card>


        <ion-grid>
          <ion-row>
            <ion-col size="4">
              <mat-form-field placeholder="Round Name" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Name</mat-label>
                <input type="text" matInput [(ngModel)]="this.rounds[currentIndex].roundName">
              </mat-form-field>
            </ion-col>

            <!-- <ion-col size="4">
              <mat-form-field placeholder="Round Type" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Type</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].roundType">
              </mat-form-field>
            </ion-col> -->


            <!-- <ion-col size="4">
              <mat-form-field placeholder="Start Date" *ngIf='rounds' style="width: 100%;">
                <mat-label>Start Date</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].startDate">
              </mat-form-field>
            </ion-col>

            <ion-col size="4">
              <mat-form-field placeholder="End Date" *ngIf='rounds' style="width: 100%;">
                <mat-label>End Date</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].endDate">
              </mat-form-field>
            </ion-col> -->

            <!-- <ion-col size="4">
              <mat-form-field placeholder="Is Active" *ngIf='rounds' style="width: 100%;">
                <mat-label>Is Active</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].isActive">
              </mat-form-field>
            </ion-col> -->


            <!-- <ion-col size="4">
              <mat-form-field placeholder="Round Prize Image" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Prize Image</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].roundPrizeImage">
              </mat-form-field>
            </ion-col> -->

            <!-- <ion-col size="4">
              <mat-form-field placeholder="Round Report Id" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Report</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].reportRequestId">
              </mat-form-field>
            </ion-col>

            <ion-col size="4">
              <mat-form-field placeholder="Round Report Id" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Self Gen Report</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].selfGenReportRequestId">
              </mat-form-field>
            </ion-col>


            <ion-col size="4">
              <mat-form-field placeholder="Round Report Id" *ngIf='rounds' style="width: 100%;">
                <mat-label>Round Stage Per Team Report</mat-label>
                <input type="text" matInput [(ngModel)]="rounds[currentIndex].stagePerTeamReportId">
              </mat-form-field>
            </ion-col> -->

            <!-- <ion-col size="4">
              
              <button mat-raised-button 
                      class="filter-button primary-color"
                      style="width: 100%;" 
                      (click)='downloadCurrentRoundProjectsCSV()'>
               Download Round Projects
              </button>

            </ion-col> -->
          </ion-row>

          <ion-row>
            <div >
              <button title="This will be the image shown at the top of the selected round." 
                        type="button"
                        mat-button style="text-align:center;" 
                        (click)="addRoundFile.click()">
                      Add Round Incentive Image
                      </button>
                      <input hidden (change)="onRoundImageFileSelected($event)" #addRoundFile type="file" value="" name="Attach">
                      <img 
                      *ngIf='this.rounds[currentIndex].roundPrizeImage' 
                      [src]='this.rounds[currentIndex].roundPrizeImage' 
                      style="max-width: 100px;"/>
        
            </div>
          </ion-row>

          <ion-row>

            <ion-col size="12">
              <mat-form-field [floatLabel]="'never'">
                <mat-label>Start Date</mat-label>
                <input 
                matInput 
                required
                [(ngModel)]='this.rounds[this.currentIndex].startDate'
                autocomplete="off" 
                [matDatepicker]="pickerStart" 
                (dateChange)="addStartDate($event)">
                <mat-datepicker-toggle matSuffix [for]="pickerStart" [disableRipple]="true">
                  <mat-icon matDatepickerToggleIcon>event</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            
              </mat-form-field>
        
              <span>
                to 
              </span>
              
              <mat-form-field [floatLabel]="'never'">
                <mat-label>End Date</mat-label>
                <input matInput required
                [(ngModel)]='this.rounds[this.currentIndex].endDate'
                autocomplete="off" 
                [matDatepicker]="pickerEnd" 
                (dateChange)="addEndDate($event)">
                <mat-datepicker-toggle matSuffix [for]="pickerEnd" [disableRipple]="true">
                  <mat-icon matDatepickerToggleIcon>event</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            
              </mat-form-field>
            </ion-col>
          </ion-row>
          <ion-row>
            <mat-checkbox [(ngModel)]="rounds[currentIndex].isActive" labelPosition="after">
              Round Is Active
           </mat-checkbox>
          </ion-row>
        </ion-grid>
      </mat-card>

    </div>

    <div style="height: 20px;"></div>

    <div>
        <ion-row>
          <ion-col size="3">
            <h1>
              Matchup Information
            </h1>
          </ion-col>
          <ion-col size="2">
            <button mat-raised-button 
                class="filter-button primary-color"
                (click)='checkAll()'>
              Check all
           </button>
          </ion-col>
          <ion-col size="2">
            <button mat-raised-button 
                class="filter-button primary-color"
                (click)='uncheckAll()'>
                Uncheck All
            </button>
          </ion-col>
         
        </ion-row>

    </div>

    <p>
      Note: Checking a box allows you to manually enter the score based on any necessary adjustments
    </p>

    <div class="example-container">

      <div cdkDropList #leftList="cdkDropList" [cdkDropListData]="leftTeams" [cdkDropListConnectedTo]="[rightList]"
        class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of leftTeams; let i = index;" cdkDrag>
          <ion-row>

            <ion-col size="3">
              <mat-form-field floatLabel='never' appearance="none"
                style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input #nameInput type="text" style=" font-size: 16px;" matInput [(ngModel)]="item.teamName"
                  placeholder="score">

              </mat-form-field>
            </ion-col>
            <ion-col size="2">
              <mat-form-field floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input #multiplierInput type="number" style="padding-left: 10px; font-size: 16px;" matInput [(ngModel)]="item.teamMultiplier"
                placeholder="multiplier">
              </mat-form-field>
              <br />
              <div>
                ({{item.teamCalculatedRealizationRate | number : '1.0-4' }})
              </div>
            </ion-col>
            <ion-col size="1">
              X
            </ion-col>
            <ion-col size="2">
              {{item.teamDeals}} deals <br />({{item.teamSelfGen}} self)
            </ion-col>
            <ion-col size="1">
              =
            </ion-col>
            <ion-col size="2">
              <mat-form-field floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <mat-checkbox [(ngModel)]="item.teamUseOverrideScore" labelPosition="before">
                  
                <input #scoreInput [disabled]="!item.teamUseOverrideScore"  type="number" style="padding-left: 10px; font-size: 16px;" matInput [(ngModel)]="item.teamScore"
                placeholder="score">
              </mat-checkbox>

              </mat-form-field>
            </ion-col>
            <ion-col size="1">
              <mat-icon style="cursor:pointer;" (click)='removeTeam(i, "left")'>
                remove_circle
              </mat-icon>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <button mat-button style="width: 100%;" (click)='addTeams("left")'>Add teams</button>

    </div>

    <div class="example-container">

      <div cdkDropList #rightList="cdkDropList" [cdkDropListData]="rightTeams" [cdkDropListConnectedTo]="[leftList]"
        class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of rightTeams; let i = index;" cdkDrag>
          <ion-row>
            <ion-col size="3">
              <mat-form-field floatLabel='never' appearance="none"
                style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input #nameInput type="text" style=" font-size: 16px;" matInput [(ngModel)]="item.teamName"
                  placeholder="score">

              </mat-form-field>
            </ion-col>
            <ion-col size="2">
              <mat-form-field floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input #multiplierInput type="number" style="padding-left: 10px; font-size: 16px;" matInput [(ngModel)]="item.teamMultiplier"
                placeholder="multiplier">
              </mat-form-field>
              <br />
              <div>
                ({{item.teamCalculatedRealizationRate | number : '1.0-4' }})
              </div>
            </ion-col>
            
            <ion-col size="1">
              X
            </ion-col>
            <ion-col size="2">
              {{item.teamDeals}} deals <br />({{item.teamSelfGen}} self)
            </ion-col>
            <ion-col size="1">
              =
            </ion-col>
            <ion-col size="2">
              <mat-form-field floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <mat-checkbox [(ngModel)]="item.teamUseOverrideScore" labelPosition="before">
                  
                <input #scoreInput [disabled]="!item.teamUseOverrideScore"  type="number" style="padding-left: 10px; font-size: 16px;" matInput [(ngModel)]="item.teamScore"
                placeholder="score">
              </mat-checkbox>

              </mat-form-field>
            </ion-col>
            <ion-col size="1">
              <mat-icon style="cursor:pointer;" (click)='removeTeam(i, "right")'>
                remove_circle
              </mat-icon>
            </ion-col>
          </ion-row>

        </div>

      </div>
      <button mat-button style="width: 100%;" (click)='addTeams("right")'>Add teams</button>

    </div>
