import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { LeadsService } from 'src/app/leads/leads.service';
import { MapMarker } from 'src/app/shared/components/geo-map/geo-map.component';
import { KnockingService, KNOCKING_TALLY_KEY, KnockStatus, pinUrlForDoor } from '../knocking.service';

@Component({
  selector: 'enzy-knock-modal',
  templateUrl: './knock-modal.component.html',
  styleUrls: ['./knock-modal.component.scss'],
})
export class KnockModalComponent implements OnInit {

  pin: MapMarker;


  constructor(
    public _bottomSheetRef: MatBottomSheetRef<KnockModalComponent>, 
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {pin: MapMarker},
    private leadsService: LeadsService,
    private knockingService: KnockingService,
    private detector: ChangeDetectorRef,

  ) { 
    this.pin = data?.pin;
    this.initAddress();
  }

  ngOnInit() {}

  private async initAddress() {
    if (this.pin?.address || !this.pin?.lat || !this.pin?.lng) return;
    this.pin.address = await this.knockingService.latLongToAddress(this.pin?.lat, this.pin?.lng);
    this.detector.detectChanges();
  }

  printAddress(): string {
    return this.pin?.address?.fullAddress;
  }

  handleValueChanged(key: string, newTally: any) {
    // this.knockedDoor?.knocks?.push(newTally);
  }

  getTallyKey(): string {
    return KNOCKING_TALLY_KEY;
  }

  showOptions(): boolean {
    return this.pin?.knockedDoor?.knockStatus !== "Lead Created" 
      && this.pin?.knockedDoor?.knockStatus !== "Project Submitted"
  }
  knockStatusOptions(): string[] {
    return [
      "Not Home" ,
      "Not Interested",
      "Follow Up",
    ]
  }

  changeStatus(option: KnockStatus) {
    if (!this.pin?.knockedDoor) return;
    this.pin.knockedDoor.knockStatus = option;
    this.pin.pinImageUrl = pinUrlForDoor(this.pin.knockedDoor)
  }
  
  optionIsSelected(option: KnockStatus): boolean {
    return this.pin?.knockedDoor.knockStatus === option
  }
  
  async createLead() {
    if (!this.pin?.knockedDoor) return;
    this.pin.knockedDoor.lead = (await this.leadsService.createNewLeadUsingModal())?.lead;
    this.changeStatus("Lead Created");
    this.data?.pin?.frontendId
  }
  
  createProject() {
    // this.initAddress();

    if (!this.pin?.knockedDoor) return;
    this.changeStatus("Project Submitted");

    alert("Create Project");
  }


  save() {
    this._bottomSheetRef.dismiss({pin: this.pin});
  }

  delete() {
    this._bottomSheetRef.dismiss({deletePin: this.data?.pin?.frontendId});
  }

}
