import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import {
  Component, NgZone, OnInit, ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { IonContent, IonRouterOutlet, Platform } from "@ionic/angular";
import { BusySpinnerComponent } from "../shared/components/busy-spinner/busy-spinner.component";
import { HeaderService } from "../shared/components/my-header/header.service";
import { devAlert } from "../shared/debug-functions";
import { CustomFileService } from "../shared/services/custom-file-service.service";
import { LoginService } from "../shared/services/login.service";
import { MessagingSocketService } from "../shared/services/messaging-socket.service";
import { MessagingStoreService } from "../shared/services/messaging-store.service";
import {
  MessagingService
} from "../shared/services/messaging.service";
import { UserDetailsService } from "../shared/services/user-details.service";





export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('1000ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('1000ms', style({ opacity: 0 }))]
  )
]);

const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0 }),
      stagger(100, [
        animate('1.5s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
])


@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  animations: [listAnimation, fadeAnimation]
})
export class MessagesComponent implements OnInit {
  refresherVar: string = "test";
  sessionKey: string = "";
  isMobile: boolean = false;


  @ViewChild("content") private content: IonContent;

  constructor(
    public zone: NgZone,
    public platform: Platform,
    public nav: HeaderService,
    public dialog: MatDialog,
    public messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    public userDetailsService: UserDetailsService,
    public messagingSocketService: MessagingSocketService,
    public customFileService: CustomFileService,
    public loginService: LoginService,
    public headerService: HeaderService,
    public router: Router,
    public routerOutlet: IonRouterOutlet,

  ) {
    this.platform.ready().then(() => {
      if (this.platform.width() > 800) {
        this.isMobile = false;
      }
      else {
        this.isMobile = true;
      }

    });

  }


  ngOnInit() {
    this.routerOutlet.swipeGesture = false;

  }


  ionViewDidEnter() {
    if (history.state.fromNotificationConvoId) {
      // if (!this.canNavigateFromNotification) {
      //   this.messagingStoreService.storeMessagesMapHasBeenInitializedEvent.subscribe(res => {
      //     if (res) {
      //       this.canNavigateFromNotification = true;

      //       this.router.navigateByUrl("/messages/" + history.state.fromNotificationConvoId, { state: { fromNotification: true } })
      //     }
      //   })


      // } else {
      //   this.router.navigateByUrl("/messages/" + history.state.fromNotificationConvoId, { state: { fromNotification: true } })
      // }

      this.router.navigateByUrl("/messages/" + history.state.fromNotificationConvoId, { state: { fromNotification: true } })


      //If the store / component is still initializing we'll want to wait for that to finish...

    }

  }

  // canNavigateFromNotification: boolean = false;
  // ready() {
  //   this.canNavigateFromNotification = true;
  // }



  ionViewWillEnter() { }


  async doRefresh(event) {
    let ref = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    try {
      await this.messagingStoreService.loadAllConversations(null, true);
      event.target.complete();
      ref.close();
    }
    catch (e) {
      event.target.complete();
      ref.close();
      devAlert(e)
    }



  }



}
