<div class="app-background-color" style="min-height: 200px; min-width: 300px; text-align: center; position: relative;">

  <div style="height: 20px;"></div>
  <h1 style="margin: 0;">{{displayText}}</h1>

  <p *ngIf="!actionCanBeUndone" style="font-weight: 100;">This action cannot be undone</p>

  <div style="height: 50px;"></div>

  <ion-grid style="position: relative; bottom: 0; width: 100%;">
    <ion-row>
      <ion-col>
        <button style="width: 100%;" mat-button (click)="cancel()">Cancel</button>
      </ion-col>

      <ion-col>
        <button style="width: 100%;" mat-button  (click)="confirm()" class="primary-color">Confirm</button>
      </ion-col>
    </ion-row>
  </ion-grid>

</div>