
<div class="no-context-menu app-background-color app-text-color" style="height: 100%;">

<ion-grid style=" padding: 0; height: 100%;" >

  <div *ngIf='reactionOptions?.length > 0'>
    <ion-segment scrollable [(ngModel)]='currentReaction'>
      
      <ion-segment-button (click)="segmentChanged(reactionType)" *ngFor="let reactionType of reactionOptions; let i = index" [value]="reactionType">
        {{ reactionType }}
      </ion-segment-button>
    </ion-segment>
  </div>
  <div style="height: 40vh;">
    <ion-grid style="padding: 0">
      <div *ngFor="let reaction of reactions" class="app-background-color">
        <ion-row >
          <ion-col size="auto" style="width: 60px !important">
            <img [src]="reaction.userImageUrl" class="userImage" />
          </ion-col>
          <ion-col>
            <h1 style="padding: 10px 0 0 0; margin: 0; font-size: 14px;">{{ reaction.userFullName }}</h1>
          </ion-col>

        </ion-row>
      </div>
    </ion-grid>

  </div>
</ion-grid>
</div>