<div class="app-background-color" style="padding: 10px; min-width: 400px;">
  <p>Add PDF</p>

  <div>
    <ion-row >
      <ion-col size="12">
        <div class="dropzone" uploadFiles (fileDropped)="onFileDropped($event)">
          <!-- <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" /> -->
          <button *ngIf="!this.applicantFile.file" (click)="addBackgroundFile.click()" mat-button class="opaque-div"
          style="height: 100px; width: 100px; display: block; margin: auto;">
          <span class="material-icons">
            file_upload
          </span>
        </button>
        </div>
      </ion-col>


    </ion-row>
  </div>



  <div *ngIf="this.applicantFile.file" class="primary-color"
    style="height: 100px; width: 100px; display: block; margin: auto; text-align: center; border-radius: 5px;">
    <span class="material-icons" style="line-height: 100px;">
      description
    </span>
  </div>


  <input hidden (change)="onFileDropped($event)" #addBackgroundFile type="file" value="" name="Attach">



  <ion-item class="item-background-color">
    <ion-label class="opaque-text" position="fixed">Title</ion-label>
    <input style="width: 100%; text-align: right;" matInput [(ngModel)]='this.applicantFile.title' autocomplete="off"
      type="text" />
  </ion-item>


<!-- 
  <ion-item class="item-background-color">
    <ion-label class="opaque-text" position="fixed">Type</ion-label>
    <mat-select single style="text-align: right;" [(ngModel)]='this.applicantFile.type'>
      <mat-option *ngFor="let option of getTypeOptions()" [value]="option"
        [(ngModel)]='this.applicantFile.type' ngDefaultControl>
        {{option}} &nbsp;
      </mat-option>
    </mat-select>
  </ion-item> -->


  <div style="height: 10px;"></div>

  <button (click)="save()" mat-button class="primary-color" style="width: 100%;">
    Save
  </button>


</div>