<div class="no-context-menu">


  <ion-grid style=" padding: 0;" class="app-background-color app-text-color">
    <div *ngIf="this.mode === 'normal' " style="max-height: 80vh;">
      <div style="height: 300px; background-size: cover; position: relative;"
        *ngIf="this.conversation?.imageUrl"
        [ngStyle]="{'background-image': 'url(' + this.imageUrl + ')'}">
        <div class="gradient" style="position: absolute; bottom: 0">
          <h1 class="specialText">{{this.conversation?.title}}</h1>
        </div>


      </div>

      <div *ngIf='this.canChangeConversationImage()'>

        <div style="display: block; margin: auto;">
          <form (ngSubmit)="onSubmit()">
            <div>
              <!-- <button style="display: block; margin: auto; background: transparent; color: rgba(255, 255, 255, 0.4);" type="button" mat-button (click)="fileInput.click()">Edit Photo</button> -->
              <input hidden (change)="onFileSelected($event)" #fileInput type="file" name="image">
            </div>
            <div>
            </div>
          </form>
        </div>


        <button mat-button style="width: 100%" (click)="fileInput.click()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                add_photo_alternate
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              <span *ngIf="this.conversation?.imageUrl === null"> Add</span>
              <span *ngIf="this.conversation?.imageUrl !== null"> Edit </span>
              Group Image

            </div>
          </ion-toolbar>


        </button>


        <mat-divider></mat-divider>
      </div>



      <div>
        <button mat-button style="width: 100%" (click)="openParticipants()">


          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                groups
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Group Members ({{this.conversation?.participants?.length}})

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <div>
        <button mat-button style="width: 100%" (click)="openPhotoGallery()">
          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                collections
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Photo Gallery
            </div>
          </ion-toolbar>
        </button>
        <mat-divider></mat-divider>
      </div>

      <div *ngIf='!this.conversationIsAutomaticallyManaged()'>
        <button mat-button style="width: 100%" (click)="addPeople()">
          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                person_add_alt_1
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Add People

            </div>
          </ion-toolbar>


        </button>
        <mat-divider></mat-divider>
      </div>



      <div *ngIf='!this.conversationIsAutomaticallyManaged()'>
        <button mat-button style="width: 100%" (click)="openRenameConversationDialog()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                create
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              <span *ngIf="this.conversation?.title === null"> Add</span>
              <span *ngIf="this.conversation?.title !== null"> Edit </span>
              Group Name
            </div>
          </ion-toolbar>


        </button>
        <mat-divider></mat-divider>
      </div>


      <div *ngIf="this.conversation?.participants.length > 2 && !this.conversationIsAutomaticallyManaged()">
        <button mat-button style="width: 100%" (click)="leaveChat()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                logout
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Leave Chat

            </div>
          </ion-toolbar>
        </button>
        <mat-divider></mat-divider>
      </div>


      <div>
        <button mat-button style="width: 100%">
          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                notifications_off
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Mute

            </div>

            <div slot="end">
              <mat-slide-toggle [checked]="isMuted" (change)="toggleMuted($event)"></mat-slide-toggle>

            </div>
          </ion-toolbar>

        </button>
        <mat-divider></mat-divider>
      </div>

      <div>
        <button mat-button style="width: 100%">
          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                push_pin
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Pin Conversation

            </div>

            <div slot="end">
              <mat-slide-toggle [checked]="isPinned()" (change)="togglePinned($event)"></mat-slide-toggle>

            </div>
          </ion-toolbar>

        </button>
        <mat-divider></mat-divider>
      </div>
    </div>


    <div *ngIf="this.mode === 'participants'" style="min-height: 100vh;">
      <div class="app-toolbar-background-color" style="position: sticky; top: 0; width: 100%; z-index: 99">
        <div style="height: 20px"></div>
        <ion-row>
          <ion-col size="2">
            <button mat-button (click)="back()">
              <span class="material-icons">
                arrow_back_ios
              </span>
            </button>
          </ion-col>
          <ion-col size="10">
            <h4 style=" padding: 0; margin: 0; padding-top: 5px; padding-bottom: 5px;"> Group Members
              ({{this.conversation?.participants.length}})
            </h4>
          </ion-col>

        </ion-row>
        <ion-row style="padding: 10px;">

          <button mat-button class="filter-button">
            <mat-form-field floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue" placeholder="Search"
                (keyup)="searchSend()">

            </mat-form-field>
          </button>
        </ion-row>

      </div>
      <div style="height: 30px;"></div>
      <ion-row>

        <ion-col size="6" *ngFor="let person of getUsersToShow()" style="height: 150px;">
          <app-user-icon [imageUrl]="person.imageUrl" [isLarge]="true" [initials]="getInitials(person)"></app-user-icon>
          <div style="text-align: center; width: 100%;   padding-top: 10px; height: 150px;">
            <span *ngIf='isAdmin(person)' class="material-icons" style="font-size: 20px;">
              person
            </span>{{person.preferredFirstName}} {{person.lastName}} ({{numberOfUnread(person)}})

            <button mat-mini-fab class="reaction-button iconAlign" style="box-shadow: none;"
              *ngIf='this.canRemoveParticipants()' (click)="removeParticipant(person)">
              <span class="material-icons" style="font-size: 20px;">
                delete
              </span>

            </button>

          </div>


        </ion-col>



      </ion-row>
    </div>

    <div *ngIf="this.mode === 'gallery'" style="min-height: 100vh;">
      <div class="app-toolbar-background-color" style="position: sticky; top: 0; width: 100%; z-index: 99">
        <div style="height: 20px"></div>
        <ion-row>
          <ion-col size="2">
            <button mat-button (click)="back()">
              <span class="material-icons">
                arrow_back_ios
              </span>
            </button>
          </ion-col>
          <ion-col size="10">
            <h4 style=" padding: 0; margin: 0; padding-top: 5px; padding-bottom: 5px;"> 
              Photo Gallery  
            </h4>
          </ion-col>

        </ion-row>
       
      </div>

      <ion-row>
        <app-conversation-image-gallery [conversationId]='+this.conversation?.conversationId'></app-conversation-image-gallery>



      </ion-row>
    </div>

    <div *ngIf="this.mode === 'profile'">
      <div
        style="height: 500px; background-image: url({{this.fromUserImg}}); background-position: center; background-size: cover;object-fit: cover;position: relative; ">
        <div class="gradient" style="position: absolute; bottom: 0">
          <h1 class="specialText">{{this.fromUserName}}</h1>
        </div>
      </div>

      <div style="padding: 10px; ">
        <ion-row>

          <ion-col size="6">
            <button (click)="directMessage()" mat-button style="width: 100%;" class="primary-color"> Direct Message </button>
          </ion-col>

          <ion-col size="6">
            <button (click)="viewProfile()" mat-button style="width: 100%;" class="primary-color"> View Profile </button>
          </ion-col>
        </ion-row>

  
    </div>


      <mat-divider></mat-divider>


    </div>

    <div *ngIf="this.mode === 'unread'">
      <div class="app-toolbar-background-color" style="position: sticky; top: 0; width: 100%; z-index: 99">
        <div style="height: 20px"></div>
        <ion-row>
          <ion-col size="2">
            <button mat-button (click)="backToOptions()">
              <span class="material-icons">
                arrow_back_ios
              </span>
            </button>
          </ion-col>

        </ion-row>
       
      </div>
      <ion-segment [(ngModel)]='category'>
      
        <ion-segment-button (click)="segmentChanged('read')" value = 'read'>
          Have Read ({{getNumberOfRead()}})
        </ion-segment-button>
        <ion-segment-button (click)="segmentChanged('unread')" value = 'unread'>
          Have Not Read ({{getNumberOfUnread()}})
        </ion-segment-button>
      </ion-segment>
      <ion-grid>
        <div *ngIf='category == "read"'>
          <!-- <ion-row *ngFor='let participant of getParticipantsThatHaveReadCurrentMessage()'>
            {{participant.preferredFirstName}} {{participant.lastName}}
          </ion-row> -->

          <div *ngFor='let participant of getParticipantsThatHaveReadCurrentMessage()'>
            <ion-toolbar style="height: 40px;" class="transparent-bg">
              <div slot="start" style="width: 20px;">
                
                
              </div>
              <div slot="start">
                <div class="mat-bod-string sender-name" style="font-size: 16px; margin-top: -12px;">
                  {{participant.preferredFirstName}} {{participant.lastName}}</div>
              </div>
            </ion-toolbar>
          </div>
          
        </div>
        <div *ngIf='category == "unread"'>
          <!-- <ion-row *ngFor='let participant of getParticipantsThatHaveNotReadCurrentMessage()'>
            {{participant.preferredFirstName}} {{participant.lastName}}
          </ion-row> -->
          <div *ngFor='let participant of getParticipantsThatHaveNotReadCurrentMessage()'>
            <ion-toolbar style="height: 40px;" class="transparent-bg">
              <div slot="start" style="width: 20px;">
    
              </div>
              <div slot="start">
                <div class="mat-bod-string sender-name" style="font-size: 16px; margin-top: -12px;">
                  {{participant.preferredFirstName}} {{participant.lastName}}</div>
              </div>
            </ion-toolbar>
          </div>
          
        </div>
       
      </ion-grid>


    </div>

    <div *ngIf="this.mode === 'options'">
      <!-- <div style="height: 15px"></div> -->
      <!-- <p style="text-align: center; margin: 0; padding-bottom: 10px;">Message sent at {{this.message.sendDate}}</p> -->
      <ion-row *ngIf="!isEnzyAssistantMessage()">
        <ion-col>
          <button class="reaction-button" (click)="react('👍')" mat-mini-fab>👍 </button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('👎')" mat-mini-fab>👎</button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('❓')" mat-mini-fab>❓</button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('🔥')" mat-mini-fab>🔥 </button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('💥')" mat-mini-fab>💥</button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('🤣')" mat-mini-fab>🤣 </button>
        </ion-col>
        <ion-col>
          <button class="reaction-button" (click)="react('❤️')" mat-mini-fab>❤️</button>
        </ion-col>
      </ion-row>


      <div style="height: 20px"></div>
      <div *ngIf="!isReply && !isPollMessage() && !isEnzyAssistantMessage()">

        <button mat-button style="width: 100%" (click)="reply()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                reply
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Reply

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>


      <div *ngIf="isEnzyAssistantMessage() && messageHasReportLines()">
        <mat-divider></mat-divider>

        <button mat-button style="width: 100%" (click)="messageAllUsers()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                chat
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Message All

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>


        <button mat-button style="width: 100%" (click)="messageIncompleteUsers()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                chat
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Message Only Users Without Messages

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <div *ngIf="isEnzyAssistantMessage() && this.message?.reportId?.length > 0">
        <mat-divider></mat-divider>

        <button mat-button style="width: 100%" (click)="openReport()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                insights
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Open Report Details

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>

      </div>


      <div *ngIf="thisMessageIsFromCurrentUser && !this.messageIsTemp() && !isPollMessage()">
        <button mat-button style="width: 100%" (click)="openEditModal()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                create
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Edit

            </div>
          </ion-toolbar>
        </button>


        <mat-divider></mat-divider>
      </div>


      <div *ngIf="this.userCanDeleteMessage()">

        <button mat-button style="width: 100%" (click)="deleteMessage()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                delete
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Delete

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <div *ngIf="this.isIOS() &&  this.message.messageImage && this.message.messageType === 'IMAGE'">

        <button mat-button style="width: 100%" (click)="saveImage()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                save
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Save Image

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <div *ngIf="this.isIOS() && this.message.messageImage && this.message.messageType === 'VIDEO'">

        <button mat-button style="width: 100%" (click)="saveVideo()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                save
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              Save Video

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <div>

        <button mat-button style="width: 100%" (click)="showUsersThatHaveReadMessage()">

          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                group
              </span>
            </div>

            <div slot="start">
              <div style="width: 10px"></div>
            </div>

            <div slot="start">
              {{getUsersReadSectionTitle()}}

            </div>
          </ion-toolbar>

        </button>

        <mat-divider></mat-divider>
      </div>

      <button mat-button style="width: 100%" (click)="copy()">
        <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
          <div slot="start">
            <span class="material-icons iconAlign">
              content_copy
            </span>
          </div>

          <div slot="start">
            <div style="width: 10px"></div>
          </div>

          <div slot="start">
            Copy Text

          </div>
        </ion-toolbar>

      </button>
      <mat-divider></mat-divider>


      <button mat-button style="width: 100%" (click)="reportMessage()">
        <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
          <div slot="start">
            <span class="material-icons iconAlign">
              flag
            </span>
          </div>

          <div slot="start">
            <div style="width: 10px"></div>
          </div>

          <div slot="start">
            Report 

          </div>
        </ion-toolbar>

      </button>

      <div *ngIf='canOpenTicket()'>
        <mat-divider></mat-divider>
  
  
        <button mat-button style="width: 100%" (click)="openNewTicket()">
          <ion-toolbar class="transparent-bg" style="width: 100%; height: 50px;">
            <div slot="start">
              <span class="material-icons iconAlign">
                work_outline
              </span>
            </div>
  
            <div slot="start">
              <div style="width: 10px"></div>
            </div>
  
            <div slot="start">
              New Ticket 
  
            </div>
          </ion-toolbar>
  
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <ion-row>
      <div style="height: 50px;"></div>
    </ion-row>
  </ion-grid>
</div>