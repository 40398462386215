<div>

  <div style="height: 5px;"></div>
  <p style="color: white; margin: 0;">Divisions</p>
  <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;">

    <!-- <div>
      <button  [ngClass]="{'filter-button-selected' : this.tournamentSize === 2, 'filter-button' : this.tournamentSize !== 2}"  mat-button (click)="updateTournamentSize(2)">

        <span class="zeroLineHeight">2</span>
        
      </button>
    </div>

    <div style="padding: 5px;"></div> -->

    <div>
      <button  [ngClass]="{'filter-button-selected' : this.selectedDivision === 'Alpha', 'filter-button' : this.selectedDivision !== 'Alpha'}"  mat-button (click)="updateSelectedDivision('Alpha')">

        <span class="zeroLineHeight">Alpha</span>
        
      </button>
    </div>



    <div style="padding: 5px;"></div>

    <div>
      <button  [ngClass]="{'filter-button-selected' : this.selectedDivision === 'Beta', 'filter-button' : this.selectedDivision !== 'Beta'}"  mat-button (click)="updateSelectedDivision('Beta')">

        <span class="zeroLineHeight" >Beta</span>
        
      </button>
    </div>


    <div style="padding: 5px;"></div>

    <div>
      <button  [ngClass]="{'filter-button-selected' : this.selectedDivision === 'Charlie', 'filter-button' : this.selectedDivision !== 'Charlie'}"  mat-button (click)="updateSelectedDivision('Charlie')">

        <span class="zeroLineHeight">Charlie</span>
        
      </button>
    </div>


    <div style="padding: 5px;"></div>

    <div>
      <button  [ngClass]="{'filter-button-selected' : this.selectedDivision === 'Delta', 'filter-button' : this.selectedDivision !== 'Delta'}"  mat-button (click)="updateSelectedDivision('Delta')">

        <span class="zeroLineHeight">Delta</span>
        
      </button>
    </div>

    <div style="padding: 10px;"></div>
  </div>
</div>


<ion-grid style="margin: 0; padding: 0;  overflow-y: scroll;">
  <ion-row style="margin: 0; padding: 0;   overflow-y: scroll;" [style.width.px]="widthOfGrid">

    <div *ngFor="let round of this.tournament.rounds; let roundIndex = index;" style="padding-right: 10px;">

      <ion-col size="1" class="round" style="background-color: lightgray; margin:0;padding: 0; width: 200px">
        <h1 style="color: white; font-size: 18px; text-align: center;"><span
            *ngIf="roundIndex < this.tournament.rounds.length - 2">Round {{round.roundNumber}}</span> <span
            *ngIf="roundIndex === this.tournament.rounds.length - 2">Championship</span> <span
            *ngIf="roundIndex === this.tournament.rounds.length - 1">Winner</span></h1>




        <!-- START EDGE SPACER -->
        <div [style.height.px]="round.valueOfEdgeSpacers" style="background-color: transparent;">

        </div>
        <div *ngFor="let team of round.teams; let i = index">

          <!-- HANDLE FIRST ROUND SLIGHTLY DIFFERENT -->
          <div *ngIf="round.roundNumber === 1">
            <div class="team">{{team}}</div>

            <!-- MIDDLE SPACER -->
            <div *ngIf="i % 2 !== 0" [style.height.px]="round.valueOfMiddleSpacers"
              style="background-color: transparent;">
            </div>

            <span *ngIf="i % 2 === 0" [style.line-height.px]="round.valueOfMiddleSpacers"
              style="float: right; margin-right: -12px; margin-top: -5px; color: white;">—</span>

            <!-- LINE -->
            <div *ngIf="i % 2 === 0">
              <mat-divider></mat-divider>
            </div>
          </div>


          <div *ngIf="round.roundNumber !== 1">
            <div class="team">{{team}}</div>

            <!-- MIDDLE SPACER -->
            <div *ngIf="i !== round.teams.length-1" [style.height.px]="round.valueOfMiddleSpacers"
              [style.line-height.px]="round.valueOfMiddleSpacers" [ngClass]="{'connectingSpacer': (i % 2 === 0)}"
              style="background-color: transparent;">
              <span *ngIf="i % 2 === 0" style="float: right; margin-right: -12px; color: white;">—</span>
            </div>
          </div>

        </div>
        <!-- END EDGE SPACER -->
        <div [style.height.px]="round.valueOfEdgeSpacers" style="background-color: transparent;">

        </div>

      </ion-col>
    </div>



  </ion-row>
</ion-grid>