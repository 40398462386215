<div style="background-color: transparent;">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100% !important; background-color: transparent;" >

    <ng-container matColumnDef="date" style="height: 10px;">
      <th mat-header-cell *matHeaderCellDef> {{this.xHeader | uppercase}}</th>
      <td mat-cell *matCellDef="let row" style=" text-align: left; font-size: 14px; "> 
        <span class="truncated" *ngIf="row.x !== null" style=" font-weight: 100;"> 
          {{row.x}}
        </span> 
        <span *ngIf="row.x === null" style="font-weight: 100;"> User </span> 

      
      </td>
    </ng-container>


    <ng-container matColumnDef="dataPointValue" style="height: 20px;">
      <th mat-header-cell *matHeaderCellDef> {{this.yHeader | uppercase}} </th>
      <td mat-cell *matCellDef="let row" class="truncated " style="text-align: right;  font-size: 14px;  font-weight: 100; line-height: 20px;"> {{row.y}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
  </table>
</div>
