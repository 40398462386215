export class FreshReportResponse {
    message: string;
    errorMessage: string;
    list: FreshReport[];
    listNav: ListNav;

}

export class FreshReport {
    id: number;
    objectIndex: number;
    reportName: string;
    stratField: string;
    sortName: string;
    sortDesc: boolean;
    shareType: string;
    companyId: number;
    userId: number;
    isEditable: boolean;
    viewType: string;
    isFavorite: boolean;
    isSavingFavorite?: boolean;
    reportCategory: string;
    reportId: string;
    //reportOrder: number;
}

export const DEFAULT_FRESH_REPORT: FreshReport = {
    id: null,
    objectIndex: null,
    reportName: null,
    stratField: null,
    sortName: null,
    sortDesc: null,
    shareType: null,
    companyId: null,
    userId: null,
    isEditable: null,
    viewType: null,
    isFavorite: null,
    isSavingFavorite: null,
    reportCategory: null,
    reportId: null,
}


export class ListNav {
    offset: number;
    columnHeaderList: any[];
    limit: number;
    previousDisabled: string;
    offsetLabel: string;
    sortNameLabel: string;
    nextDisabled: string;
    showing: string;
    href: string;
    previousHref: string;
    nextHref: string;
    headerHref: string;
    numberShown: number;
    offsetBeanList: any[];
}
