import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-report',
  templateUrl: './map-report.component.html',
  styleUrls: ['./map-report.component.scss'],
})
export class MapReportComponent implements OnInit {

  markersOn = false;

  constructor() { }

  ngOnInit() {}

  onMapLoad(mapInstance: google.maps.Map) {
    // this.map = mapInstance;
    // const coords: any[] = this.markers.map((m: marker) => this.getWeightedLatLngFromMarker(m))
    // this.heatmap = new google.maps.visualization.HeatmapLayer({
    //   map: this.map,
    //   data: coords
    // });
  }

}
