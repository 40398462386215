<div class="dashboardPage section-div" >
  <ion-grid class="customSize" style="height: 80px; max-width: 600px; display: block; margin: auto">
    <ion-row style="">
      <ion-col size="2">
        <div >
          <div style="font-size: 14px">
            {{aggregate.lowString}}
          </div>
          <div style="font-size: 12px;font-weight: 100;">
            Low
          </div>
        </div>
      </ion-col>

      <ion-col size="2">
        <div >

          <div style="font-size: 14px">
            {{roundedAverage}}
          </div>
          <div style="font-size: 12px; font-weight: 100;">
            Avg
          </div>
        </div>
      </ion-col>


      <ion-col size="2">
        <div >

          <div style="font-size: 14px">
            {{aggregate.highString}}
          </div>
          <div style="font-size: 12px;  font-weight: 100;">
            High
          </div>
        </div>
      </ion-col>

      <ion-col size="2">
        <div>

          <div style=" font-size: 14px" *ngIf="aggregate.totalString">
            {{aggregate.totalString}}
          </div>

          <div style=" font-size: 14px" *ngIf="!aggregate.totalString">
            -
          </div>
          <div style="font-size: 12px;  font-weight: 100;">
            Total
          </div>
        </div>
      </ion-col>

      <ion-col size="2">
        <div >

          <div style="font-size: 14px">
            {{aggregate.countString}}
          </div>
          <div style="font-size: 12px; font-weight: 100;">
            Count
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
