import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RecruitService } from 'src/app/shared/services/recruit.service';
import { SelectionModel } from '@angular/cdk/collections';
import { OnboardingRestService } from '../onboarding-rest.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { Invitation } from '../onboarding-wizard/onboarding-store.service';
import { LeadModel } from 'src/app/leads/leads.model';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';

@Component({
  selector: 'app-onboarding-list',
  templateUrl: './onboarding-list.component.html',
  styleUrls: ['./onboarding-list.component.scss'],
})
export class OnboardingListComponent implements OnInit {

  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  teamControl = new FormControl();
  scarProgram: boolean = false;
  note: string;
  recruiterId: number;
  recruiterName: string;

  filteredOptions: Observable<string[]>;
  selection = new SelectionModel<LeadModel>(true, []);

  inviationsResponse: any;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.teamList.filter(option => option.toLowerCase().includes(filterValue));
  }

  teamList: string[] = [
    "Team 1",
    "Team 2",
  ]

  constructor(public router: Router, private zone: NgZone,
    public onboardingRestService: OnboardingRestService,
    public recruitService: RecruitService,
    public employeeStoreService: EmployeeStoreService,
    public userDetailsService: UserDetailsService,) {
    this.recruitService.getTeams().then(res => {
      this.teamList = res;

      this.filteredOptions = this.teamControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );


    })
    this.setDefaultRecruiter();
  }

  ngOnInit() {
    this.loadMyInvitations();
  }


  async loadMyInvitations() {
    this.inviationsResponse = await this.recruitService.getMySentInvitations();

    this.zone.run(() => {
      console.log("redrawing component for reloading messages");
    });
  }


  
  async setDefaultRecruiter() {
    const res = await this.userDetailsService.getUserId();
    this.recruiterId = parseInt(res);
    this.recruiterName = await this.userDetailsService.getUserName();
  }

  getStringForInvite(invite: any): string {
    let output: string = "";
    output = JSON.stringify(invite);
    return output;

  }

  getEmployeeName(userId: number) {
    return this.employeeStoreService.getUserName(userId) ? this.employeeStoreService.getUserName(userId) : "-";
  }

  getInvitations(): any[] {
    if (this.inviationsResponse?.object?.length) {
      return this.inviationsResponse.object;
    }
    return [];
  }


  getFullNameFromInvitation(invitation: any): string {
    return invitation.firstName + " " + invitation.lastName;
  }

  getStatusFromInvitation(invitation: any): string {
    return invitation.status;
  }


  async changeAssignedUsers() {
    const res: number = await this.employeeStoreService.selectSingleUserModal();
    this.recruiterId = res;
    this.recruiterName = await this.getEmployeeName(res);

  }


  async sendInvite(inputInvitation: Invitation = null): Promise<void> {
    let invitation: Invitation;

    if (inputInvitation) {
      invitation = inputInvitation
    } else {
      invitation = {
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        team: this.teamControl.value,
        scarProgram: this.scarProgram,
        note: this.note,
        recruiterId: this.recruiterId
      }
    }
    
    if (!this.everythingIsFilledOut(invitation)) {
      alert("Please fill in all the fields");
      return;
    };
    const result = await this.onboardingRestService.sendInvitation(invitation);
    if (result.success) {

      alert("Invitation sent successfully")
      this.clearFields();
    }
    else { alert("Error. Please Try Again."); console.log(result) }
    await this.loadMyInvitations();
  }

  private clearFields() {
    this.firstName = "";
    this.lastName = "";
    this.emailAddress = "";
    this.phoneNumber = "";
    this.teamControl.setValue("");
    this.scarProgram = false;
    this.note = "";
    this.recruiterId = null;
    this.recruiterName = "";
  }

  private everythingIsFilledOut(invite: Invitation): boolean {
    if (
      !invite.firstName ||
      !invite.lastName ||
      !invite.emailAddress ||
      !invite.phoneNumber ||
      (!invite.team && !invite.office) ||
      (!invite.recruiterId)
    ) return false;
    return invite.firstName.length > 0 &&
      invite.lastName.length > 0 &&
      invite.emailAddress.length > 0 &&
      invite.phoneNumber.length > 0 &&
      (invite.team?.length > 0 || invite.office.length > 0)
  }


  reinvite(invite){ 
    this.sendInvite(invite);
  }

  edit(invite) {
    this.firstName = invite.firstName;
    this.lastName = invite.lastName;
    this.emailAddress = invite.emailAddress;
    this.phoneNumber = invite.phoneNumber;
    this.teamControl.setValue(invite.office);
    this.note = invite.note;
    this.recruiterId = invite.recruiterId;
  }

  // newRep(){
  //   this.router.navigate(["/new-rep"]);
  // }

}
