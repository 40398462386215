<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">



<div style="max-width: 600px; display: block; margin: auto; min-height: 100vh;">

  <div style="padding: 5px;">
    <ion-toolbar class="transparent-bg">
      <div slot="start">
        <h1 style="margin: 0; font-size: 18px; padding: 0;"> {{this.lead.customerFirstName}} {{this.lead.customerLastName}}</h1>
      </div>
      <div slot="end">
        <button mat-icon-button (click)="editDetails()">
          <mat-icon class="iconAlign">edit</mat-icon>
        </button>
      </div>
    </ion-toolbar>
  </div>

  <div style="padding: 10px; border-top-left-radius: 10px; border-top-right-radius: 10px;" class="section-div">
    <div *ngFor="let section of getDetailsSection()">
      <enzy-lead-info-row [title]="section.title" 
      [value]="getLeadInfoRowValue(section)" 
      [type]="section.type"
        [lead]="this.lead"></enzy-lead-info-row>
    </div>

    <mat-accordion multi>

      <mat-expansion-panel *ngFor="let section of this.getActionsSection()" class="mat-elevation-z0" style="border-bottom: 1px solid rgba(0,0,0,.12);">
        <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'" style=" padding: 10px; font-weight: 100; font-size: 16px;">
          <mat-panel-title >
            <span class="opaque-text">{{section.title}} &nbsp;({{this.lead[section.leadField].length}}) </span>
          </mat-panel-title>

        </mat-expansion-panel-header>


        <div *ngIf="section.title === 'Appointments'">
          <div *ngFor='let appointment of this.lead.appointments; let i = index' style="margin-bottom: 10px; width: 100%;">
            <enzy-appointment-row [appointment]="appointment" 
            (updateAppointment)="updateAppointment(appointment)"
            (deleteAppointment)="deleteAppointment(i)"
            >
            </enzy-appointment-row>
          </div>
        </div>
        <div *ngIf="section.title === 'Attachments'">
          <app-multi-file-upload [fileUrls]='this.lead.customerUtilityBillUrls'></app-multi-file-upload>
        </div>

        <div *ngIf="section.title === 'Notes'">
          <ion-row *ngFor='let note of this.lead["notes"]' style="margin-bottom: 10px;">
            <app-note-card [note]="note"></app-note-card>
          </ion-row>
        </div>


        <div *ngIf="section.title === 'Tasks'">
          <div *ngFor='let task of this.lead.tasks' style="margin-bottom: 10px;">
            <enzy-task-row (saveTaskChanges)="saveTaskChanges($event)" [task]="task"></enzy-task-row>
          </div>
        </div>


        <button *ngIf="section.title !=='Attachments'" mat-icon-button (click)="openLeadDrawer(section.title)"
          class="primary-color" style="display: block; margin: auto;">
          <span class="material-icons">
            add
          </span>
        </button>

      </mat-expansion-panel>

    </mat-accordion>
  </div>

  <!-- Show changes at bottom -->
  <div *ngIf="showLeadChanges()">
    <div *ngFor="let change of getLeadChanges()">
      <mat-card style="padding: 10px; margin: 20px;">
       <ion-row>
         <ion-col size="4">
           Date 
         </ion-col>
         <ion-col size="8">
           <i>{{change.creationDateTime}}</i>
         </ion-col>
       </ion-row> 
       <ion-row>
         <ion-col size="4">
           New Stage 
         </ion-col>
         <ion-col size="8">
           <i>{{change.newValue}}</i>
         </ion-col>
       </ion-row> 
       <ion-row>
         <ion-col size="4">
           Changed By 
         </ion-col>
         <ion-col size="8">
           {{change.changedBy}}
         </ion-col>
       </ion-row> 
      </mat-card>
    </div>
  </div>
</div>


