
<ion-header style="position: sticky; top: 0; z-index: 9999;">
  <ion-toolbar>
    <div slot="start" style="width: 10px;">
    </div>
    <div slot="start">
      <button mat-icon-button (click)="cancel()" >
        <span class="material-icons">
          close
        </span>
      </button>
    </div>
    <ion-title style="text-align: center;">Ticket</ion-title>

    <div slot="end" >
      <button mat-button class="primary-color" (click)="save()">
       Save
      </button>
    </div>

    
    <div slot="end" style="width: 10px;">
    </div>
  </ion-toolbar>
</ion-header>


<ion-grid style="max-width: 600px; padding: 10px; min-height: 100vh;">

  <p class="opaque-text">Status</p>

  <div class="section-div" style="padding: 10px;">

    <ion-row>
      <button class="opaque-div" mat-button [matMenuTriggerFor]="ticketStatusMenu">
        {{ticket.ticketStatus}}
        <span class="material-icons">
          arrow_drop_down
        </span>
      </button>

      <mat-menu #ticketStatusMenu="matMenu">
        <ng-template matMenuContent>
          <div *ngFor="let option of getTicketStatusOptions()">
            <button mat-menu-item style="text-align: center;" (click)="selectTicketStatus(option)">
              {{option}}
            </button>
          </div>
        </ng-template>

      </mat-menu>
    </ion-row>
  </div>


  <p class="opaque-text">Info</p>

  <div class="section-div" style="padding: 10px;">
    <ion-row>
      <mat-form-field>
        <input matInput [required]='true' [(ngModel)]='this.ticket["title"]' autocomplete="off" type="text"
          [placeholder]="getFieldTitle('title')" />
      </mat-form-field>

    </ion-row>

    <ion-row>
      <mat-form-field style="width: 100%;">
        <textarea matInput [required]='true' [(ngModel)]='this.ticket["description"]' autocomplete="off" type="text"
          [placeholder]="getFieldTitle('description')" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6"></textarea>
      </mat-form-field>

    </ion-row>


    <ion-row *ngIf="this.ticket.reportingUser">
      <span class="opaque-text">Reported By:</span>

      <ion-toolbar class="transparent-bg">
        <div slot="start">
          <app-user-icon matListAvatar [employee]='getReportedByEmployee()'>
          </app-user-icon>
        </div>
        <div slot="start" style="margin-top: -10px;">
          <span>
            &nbsp; {{getReportedByEmployee()?.userDto?.firstName}} {{getReportedByEmployee()?.userDto?.lastName}}
          </span>

        </div>

      </ion-toolbar>

    </ion-row>
    <div style="height: 10px;"></div>



    <ion-row>
      <span class="opaque-text">Assigned To:</span>

      <ion-toolbar class="transparent-bg" (click)="selectAssignedEmployee()">
        <div slot="start">
          <app-user-icon matListAvatar [employee]='getAssignedEmployee()'>
          </app-user-icon>
        </div>
        <div slot="start" style="margin-top: -10px;">
          <span>
            &nbsp; {{getAssignedEmployee()?.userDto?.firstName}} {{getAssignedEmployee()?.userDto?.lastName}}
          </span>
        </div>

        <div slot="end">
          <span class="material-icons">
            edit
          </span>
        </div>

      </ion-toolbar>
    </ion-row>
  </div>






  <p class="opaque-text">Notes</p>
  <div class="section-div" style="padding: 10px; text-align: left;">

    <ion-row *ngIf='this.ticket?.notes?.length > 0'>

    </ion-row>
    <ion-row *ngFor='let note of this.ticket.notes' style="margin-bottom: 10px;">
      <app-note-card [note]="note"></app-note-card>
    </ion-row>

    <mat-divider></mat-divider>
    <ion-toolbar class="transparent-bg">
      <div slot="start" style="width: 100%;">
        <textarea matInput [(ngModel)]='this.newNoteText' autocomplete="off" type="text" placeholder="new note"
          class="opaque-div" style="padding: 10px;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
      </div>

      <div slot="end">
        <button mat-button class="primary-color" (click)='newNoteFromInput()' style="height: 55px; border-radius: 0px;">
          Add Note
        </button>

      </div>
    </ion-toolbar>
  </div>



  <p class="opaque-text">Files</p>

  <div class="section-div" style="padding: 10px;">
    <app-multi-file-upload [fileUrls]='this.ticket.fileUrls'></app-multi-file-upload>

  </div>

  <div style="height: 20px;"></div>


</ion-grid>