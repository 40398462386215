<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<shared-my-header>
</shared-my-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="" pullingText="Pull Down to Refresh" refreshingSpinner="none" refreshingText="">
    </ion-refresher-content>
  </ion-refresher>

  <div class="split-pane-border" style="overflow-x: hidden;">
    <app-conversation-list [isMobile]="this.isMobile" >
    </app-conversation-list>
  </div>
</ion-content>

<app-my-footer>
</app-my-footer>