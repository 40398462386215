import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserDetailsService } from "./user-details.service";
import { Storage } from '@ionic/storage';
import { REST_BASE_URL } from "../models/constants";
import { Series } from "../models/library.model";
import { Section } from "src/app/library/library.component";
import { ResourceFile } from "../models/help.model";

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    allDocuments: ResourceFile[] = [];

    allResources: ResourceFile[] = [];
    allSeries: Series[] = [];
    allSections: Section[] = [];

    selectedResourceType: string;

    constructor(private http: HttpClient, private userDetailsService: UserDetailsService, private localStorage: Storage) {
    }


    public async setResourceFile(title: string, videoFile: File, videoUrl: string, thumbnailFile: File, thumbnailUrl: string, trainerName: string, category: string, section: string, type: string): Promise<any> {
        if (!videoFile && !videoUrl) { return }

        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url: string = REST_BASE_URL + '/insertTrainingVideo/' + sessionKey;

        let body = new FormData();

        body.append('title', title);
        body.append('category', category);
        body.append('section', section);
        body.append('trainerName', trainerName);

        if (type.toLowerCase().includes('video')) {
            body.append('type', 'video');
        } else if (type.toLowerCase().includes('document')) {
            body.append('type', 'document');
        } else if (type.toLowerCase().includes('podcast')) {
            body.append('type', 'podcast');
        }

        if (videoFile) {
            body.append('libraryFile', videoFile, videoFile?.name);
        }

        if (videoUrl) {
            body.append('libraryUrl', videoUrl);
        }

        if (thumbnailFile) {
            body.append('libraryThumbnailFile', thumbnailFile, thumbnailFile?.name);
        }

        if (thumbnailUrl) {
            body.append('libraryThumbnailUrl', thumbnailUrl);
        }


        return this.http.post<any[]>(url, body).toPromise();
    }


    public async setFeaturedSection(title: string, videoThumbnail: File, videoUrl: string, category: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url: string = REST_BASE_URL + '/featuredSection/' + sessionKey;

        let body = new FormData();

        body.append('title', title);
        body.append('videoUrl', videoUrl);
        body.append('category', category);


        if (videoThumbnail) {
            body.append('videoThumbnailFile', videoThumbnail, videoThumbnail?.name);
        }

        return this.http.post<any[]>(url, body).toPromise();
    }

    public async setSeries(category: string, resourceType: string, categoryImage: File, categoryImageUrl: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url: string = REST_BASE_URL + '/insertResourceCategory/' + sessionKey;

        let body = new FormData();

        body.append('category', category);

        if (resourceType.toLowerCase().includes('video')) {
            body.append('resourceType', 'video');
        } else if (resourceType.toLowerCase().includes('document')) {
            body.append('resourceType', 'document');
        } else if (resourceType.toLowerCase().includes('podcast')) {
            body.append('resourceType', 'podcast');
        }


        if (categoryImageUrl) {
            body.append('categoryImageUrl', categoryImageUrl);
        }

        if (categoryImage) {
            body.append('categoryImage', categoryImage, categoryImage?.name);
        }

        return this.http.post<any[]>(url, body).toPromise();
    }


    public async getLibraryAdmins(): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/adminLibraryUsers/' + sessionKey;

        return this.http.get<any>(url).toPromise();
    }



}