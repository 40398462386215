import { AgmCoreModule } from '@agm/core';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollDispatchModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmployeeListModalComponent } from './archived/employee-list-modal/employee-list-modal.component';
import { LibraryComponent } from './library/library.component';
import { BracketComponent } from './incentives/bracket/bracket.component';
import { CreateNewCompetitionComponent } from './incentives/create-new-competition/create-new-competition.component';
import { GameCardComponent } from './incentives/game-card/game-card.component';
import { GameDetailComponent } from './incentives/game-detail/game-detail.component';
import { IncentivesComponent } from './incentives/incentives.component';
import { MilestoneDetailComponent } from './incentives/milestones/milestone-detail/milestone-detail.component';
import { MilestoneComponent } from './incentives/milestones/milestone/milestone.component';
import { PrizeComponent } from './incentives/milestones/prize/prize.component';
import { NewIncentiveModalComponent } from './incentives/new-incentive-modal/new-incentive-modal.component';
import { TeamDetailComponent } from './incentives/team-detail/team-detail.component';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material.module';
import { ConversationCellComponent } from './messaging/conversation-cell/conversation-cell.component';
import { EditMessageModalComponent } from './messaging/edit-message-modal/edit-message-modal.component';
import { EmojiModalComponent } from './messaging/emoji-modal/emoji-modal.component';
import { MessageActionsDrawerComponent } from './messaging/message-actions-drawer/message-actions-drawer.component';
import { MessageCellComponent } from './messaging/message-cell/message-cell.component';
import { ConversationListComponent } from './messaging/conversation-list/conversation-list.component';
import { MessageSelectRecipientComponent } from './messaging/message-select-recipient/message-select-recipient.component';
import { MessagesComponent } from './messaging/messages.component';
import { ReplyCardComponent } from './messaging/reply-card/reply-card.component';
import { NotificationsPageComponent } from './notifications/notifications-page/notifications-page.component';
import { GenerateNewRepComponent } from './onboarding/onboarding-list/generate-new-rep/generate-new-rep.component';
import { OnboardingListComponent } from './onboarding/onboarding-list/onboarding-list.component';
import { EditInfoModalComponent } from './profile/edit-info-modal/edit-info-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeEmployeeDrillDownComponent } from './rankings/home-employee-drill-down/home-employee-drill-down.component';
import { AdvancedAnalyticsModalComponent } from './reports/advanced-analytics/advanced-analytics-modal/advanced-analytics-modal.component';
import { AdvancedAnalyticsComponent } from './reports/advanced-analytics/advanced-analytics.component';
import { DashboardCardFooterComponent } from './reports/dashboard/dashboard-card/dashboard-card-footer/dashboard-card-footer.component';
import { DashboardCardComponent } from './reports/dashboard/dashboard-card/dashboard-card.component';
import { DashboardComponent } from './reports/dashboard/dashboard.component';
import { ExpandableLeaderboardComponent } from './reports/expandable-leaderboard/expandable-leaderboard.component';
import { AdvancedFiltersComponent } from './reports/filters/advanced-filters/advanced-filters.component';
import { BasicFilterComponent } from './reports/filters/basic-filter/basic-filter.component';
import { MapReportComponent } from './reports/map-report/map-report.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { AnyObjectDescriptionComponent } from './shared/components/any-object-description/any-object-description.component';
import { BusySpinnerComponent } from './shared/components/busy-spinner/busy-spinner.component';
import { DrillDownTableCardComponent } from './shared/components/drill-down-table-card/drill-down-table-card.component';
import { DrillDownTableComponent } from './shared/components/drill-down-table/drill-down-table.component';
import { ExpandImageModalComponent } from './shared/components/expand-image-modal/expand-image-modal.component';
import { GeneralInputModalComponent } from './shared/components/general-input-modal/general-input-modal.component';
import { GiphyComponent } from './shared/components/giphy/giphy.component';
import { InstallationsListComponent } from './shared/components/installations-list/installations-list.component';
import { KeyWordSearchComponent } from './shared/components/key-word-search/key-word-search.component';
import { CustomBarChartComponent } from './shared/components/my-footer/custom-bar-chart/custom-bar-chart.component';
import { HeaderService } from './shared/components/my-header/header.service';
import { UnderConstructionModalComponent } from './shared/components/under-construction-modal/under-construction-modal.component';
import { UserIconComponent } from './shared/components/user-icon/user-icon.component';
import { WelcomeWizardComponent } from './shared/components/welcome-wizard/welcome-wizard.component';
import { AbsoluteValuePipe } from './shared/pipes/absolute-value.pipe';
import { CustomCurrencyPipe } from './shared/pipes/custom-currency.pipe';

import { MatLinkPreviewModule } from '@angular-material-extensions/link-preview';
import { LinkPreviewCardComponent } from './messaging/link-preview-card/link-preview-card.component';
import { ExpandedImageModalComponent, SafeUrlPipe } from './messaging/expanded-image-modal/expanded-image-modal.component';
import { ThemeService } from './shared/services/theme-service.service';
import { FaqComponent } from './shared/faq/faq.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { environment } from 'src/environments/environment';
import { MentionModule } from 'angular-mentions';


import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { SOCKET_BASE_URL } from './shared/models/constants';

import { ReactionsModalComponent } from './messaging/reactions-modal/reactions-modal.component';
import { IonicGestureConfig } from 'src/utils/IonicGestureConfig';
// const config: SocketIoConfig = { url: SOCKET_BASE_URL, options: {secure: true} };
const config: SocketIoConfig = { url: SOCKET_BASE_URL };


import {HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import { LongPressDirective } from './shared/long-press.directive';

import {  MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material';
import { NoRightClickDirective } from './shared/no-right-click.directive';
import { GridCarouselComponent } from './shared/components/grid-carousel/grid-carousel.component';

import {FileOpener} from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { CompanyRecordsComponent } from './company-records/company-records/company-records.component';
import { SectionCarouselComponent } from './company-records/section-carousel/section-carousel.component';
import { RecordCardComponent } from './company-records/record-card/record-card.component';
import { RecordDetailComponent } from './company-records/record-detail/record-detail.component';
import { SwipeModule } from './swipe/swipe.module';
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};


import { PinchZoomModule } from 'ngx-pinch-zoom';
import { AdminDirectoryComponent } from './admin/admin-directory/admin-directory.component';
import { ApplicantComponent } from './admin/applicant/applicant.component';
import { MoreInformationModalComponent } from './admin/more-information-modal/more-information-modal.component';
import { W9WarningComponent } from './onboarding/w9-warning/w9-warning.component';
import { EditTournamentComponent } from './incentives/edit-tournament/edit-tournament.component';

import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { DocumentModalComponent } from './onboarding/document-modal/document-modal.component';
import { KeyContactsComponent } from './key-contacts/key-contacts/key-contacts.component';
import { ChampionshipCardComponent } from './incentives/championship-card/championship-card.component';
import { GenericProgressModalComponent } from './shared/components/generic-progress-modal/generic-progress-modal.component';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InvitationTableComponent } from './onboarding/invitation-table/invitation-table.component';
import { WelcomeEmailModalComponent } from './admin/welcome-email-modal/welcome-email-modal.component';
import { ListOfProjectsComponent } from './rankings/list-of-projects/list-of-projects.component';
import { ConversationImageGalleryComponent } from './messaging/conversation-image-gallery/conversation-image-gallery.component';
import { CalendarComponent } from './shared/components/calendar/calendar.component';
import { LibrarySectionDetailComponent } from './library/library-section-detail/library-section-detail.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadProfilePictureComponent } from './shared/components/upload-profile-picture/upload-profile-picture.component';
import { NewNoteModalComponent } from './shared/components/new-note-modal/new-note-modal.component';
import { ConversationComponent } from './messaging/conversation/conversation.component';
import { LinkedVideosPageComponent } from './linked-videos/linked-videos-page/linked-videos-page.component';
import { GenreSectionComponent } from './library/genre-section/genre-section.component';
import { VideoCardComponent } from './library/video-card/video-card.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { LeaderFilterButtonComponent } from './rankings/leader-filter-button/leader-filter-button.component';
import { LeaderboardCellComponent } from './rankings/leaderboard-cell/leaderboard-cell.component';
import { LeaderboardGridCellComponent } from './rankings/leaderboard-grid-cell/leaderboard-grid-cell.component';
import { ParallaxHeaderDirective } from './shared/parallaxHeader.directive';
import { BccGuardRailModalComponent } from './shared/components/bcc-guard-rail-modal/bcc-guard-rail-modal.component';
import { AddSectionComponent } from './library/add-section/add-section.component';
import { VideoSectionCardComponent } from './library/add-section/video-section-card/video-section-card.component';
import { SeriesSectionCardComponent } from './library/add-section/series-section-card/series-section-card.component';
import { SeriesCardComponent } from './library/series-card/series-card.component';
import { EditDrawerComponent } from './library/edit-drawer/edit-drawer.component';
import { EditFeaturedSectionComponent } from './library/edit-featured-section/edit-featured-section.component';
import { AdminLibraryComponent } from './library/admin-library/admin-library.component';
import { UploadFilesDirective } from './shared/uploadFiles.directive';
import { AddNewModalComponent } from './library/admin-library/add-new-modal/add-new-modal.component';
import { LeadersComponent } from './rankings/leaders.component';
import { NewIncentiveComponent } from './incentives/new-incentive/new-incentive.component';
import { NewCompetitionIncentiveComponent } from './incentives/new-incentive/new-competition-incentive/new-competition-incentive.component';
import { NewMilestoneIncentiveComponent } from './incentives/new-incentive/new-milestone-incentive/new-milestone-incentive.component';
import { EditMilestoneRowComponent } from './incentives/milestones/edit-milestone-row/edit-milestone-row.component';
import { EditTournamentRoundsComponent } from './incentives/edit-tournament/edit-tournament-rounds/edit-tournament-rounds.component';
import { UserPickerComponent } from './shared/components/user-picker/user-picker.component';
import { SharedModule } from './shared.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AdminToolsComponent } from './tools/tools/admin-tools.component';
import { LeadChangesCardComponent } from './messaging/lead-changes-card/lead-changes-card.component';
import { AdminPageComponent } from './admin/admin-page/admin-page.component';
import { InvitationModalComponent } from './onboarding/invitation-modal/invitation-modal.component';
import { AdditionalDocumentModalComponent } from './admin/applicant/additional-document-modal/additional-document-modal.component';
import { TableFilterComponent } from './shared/components/table-filter/table-filter.component';
import { IncentiveCardComponent } from './incentives/incentive-card/incentive-card.component';
import { FooterService } from './shared/components/my-footer/footer.service';

@NgModule({
  declarations: [
    PrizeComponent,
    GenreSectionComponent,
    VideoCardComponent,
    AddNewModalComponent,
    AdditionalDocumentModalComponent,
    SeriesCardComponent,
    AdminLibraryComponent,
    VideoSectionCardComponent,
    EditFeaturedSectionComponent,
    EditDrawerComponent,
    TableFilterComponent,
    SeriesSectionCardComponent,
    DocumentModalComponent,
    AdminToolsComponent,
    ResetPasswordComponent,
    CalendarComponent,
    IncentiveCardComponent,
    UploadProfilePictureComponent,
    KeyContactsComponent,
    AddSectionComponent,
    BccGuardRailModalComponent,
    W9WarningComponent,
    RecordDetailComponent,
    LeaderFilterButtonComponent,
    CompanyRecordsComponent,
    RecordCardComponent,
    SectionCarouselComponent,
    LibrarySectionDetailComponent,
    GridCarouselComponent,
    NoRightClickDirective,
    UploadFilesDirective,
    ParallaxHeaderDirective,
    NewNoteModalComponent,
    SafeUrlPipe,
    MessageActionsDrawerComponent,
    OnboardingListComponent,
    ConversationComponent,
    MilestoneDetailComponent,
    GenerateNewRepComponent,
    FaqComponent,
    DrillDownTableCardComponent,
    MilestoneComponent,
    ExpandImageModalComponent,
    CreateNewCompetitionComponent,
    NewIncentiveModalComponent,
    GameCardComponent,
    BracketComponent,
    EmployeeListModalComponent,
    TeamDetailComponent,
    UnderConstructionModalComponent,
    AppComponent,
    LeadersComponent,
    EditInfoModalComponent,
    NotificationsPageComponent,
    HomeEmployeeDrillDownComponent,
    EmojiModalComponent,
    GenericProgressModalComponent,
    AdminPageComponent,
    AnyObjectDescriptionComponent,
    AdvancedFiltersComponent,
    AdvancedAnalyticsModalComponent,
    DrillDownTableComponent,
    DashboardCardFooterComponent,
    ReportDetailComponent,
    CustomBarChartComponent,
    EditMessageModalComponent,
    BasicFilterComponent,
    ExpandableLeaderboardComponent,
    ProfileComponent,
    WelcomeWizardComponent,
    GameDetailComponent,
    IncentivesComponent,
    AdvancedAnalyticsComponent,
    DashboardComponent,
    DashboardCardComponent,
    LeaderboardCellComponent,
    LoginComponent,
    ChampionshipCardComponent,
    ConversationListComponent,
    MessagesComponent,
    LibraryComponent,
    MessageSelectRecipientComponent,
    BusySpinnerComponent,
    InvitationModalComponent,
    CustomCurrencyPipe,
    AbsoluteValuePipe, 
    KeyWordSearchComponent,
    ConversationCellComponent,
    MapReportComponent,
    InstallationsListComponent,
    GeneralInputModalComponent,
    ExpandedImageModalComponent,
    ReactionsModalComponent,
    AdminDirectoryComponent,
    MoreInformationModalComponent,
    LeaderboardGridCellComponent,
    ApplicantComponent,
    EditTournamentComponent,
    EditTournamentRoundsComponent,
    NewIncentiveComponent,
    NewCompetitionIncentiveComponent,
    NewMilestoneIncentiveComponent,
    InvitationTableComponent,
    WelcomeEmailModalComponent,   
    ListOfProjectsComponent,
    ConversationImageGalleryComponent,
    LinkedVideosPageComponent,
    EditMilestoneRowComponent,
  ],
  entryComponents: [],
  imports: [
    SharedModule,
    AppRoutingModule,
    ImageCropperModule,
    MentionModule,
    PinchZoomModule,
    HammerModule,
    MatLinkPreviewModule.forRoot(),
    CommonModule,
    BrowserModule,
    IonicModule.forRoot({ animated: true}),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    SwipeModule,
    PortalModule,
    ScrollingModule,
    ScrollDispatchModule,
    HttpClientModule,
    GooglePlaceModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    SocketIoModule.forRoot(config),
    // GoogleChartsModule.forRoot()
  ],
  providers: [
    StatusBar,
    HeaderService,
    FooterService,
    MobileAccessibility,
    AppVersion,
    ThemeService,
    DatePipe,
    SplashScreen,
    // {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue:     globalRippleConfig},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
function Injectable() {
  throw new Error('Function not implemented.');
}

