import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Incentive, Prize } from 'src/app/shared/models/incentives.model';
import { IncentivesStoreService } from 'src/app/shared/services/incentives-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';



@Component({
  selector: 'app-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss'],
})
export class MilestoneComponent implements OnInit {

  incentive: Incentive;

  selectedWeek: number = 1;

  currentInstallCount = 0;

  sessionKey: string;
  userId: string;

  prizes: Prize[] = [];

  constructor(
    public router: Router,
    private navCtrl: NavController,
    public incentivesStoreService: IncentivesStoreService,
    public incentivesService: IncentivesService) {

    this.incentive = history.state.incentive;

    this.incentivesStoreService.prizes.subscribe(prizes => this.prizes = prizes);
    this.incentivesStoreService.installCount.subscribe(count => this.currentInstallCount = count);

    this.incentivesStoreService.fetchCurrentPrizes(this.incentive.id + "");

  }

  ngOnInit() {

  }


  goBack() {        
    this.navCtrl.pop();
  }

  updateWeek(week: number) {
    this.selectedWeek = week;
  }

  loadShopifyLink(prize: Prize) {

    window.open(prize.prizeUrl, "_blank");

  }

  loadMilestoneData(): void {
    this.incentivesService.getIncentiveMilestoneDataForUser(this.sessionKey, this.incentive.id + "", this.userId).subscribe((installs: any[]) => {

      this.router.navigateByUrl('/milestone-detail', { state: { installs: installs , incentive: this.incentive} });

    })
  }



  download() {
    this.incentivesService.downloadIncentives(this.sessionKey, 2).subscribe(res => {
      this.downloadFile(res),
        error => console.log('Error downloading the file.')
    });
  }


  downloadFile(data: ArrayBuffer) {
    const blob = new Blob([data as BlobPart], { type: 'text/csv' });
    // const url= window.URL.createObjectURL(blob);
    // window.open(url);

    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "YearOfGear.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);


  }
}


