<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">


<div>


  <div
    style="position: fixed; left: 50%; transform: translateX(-50%); bottom: 0; width: 100%; z-index: 1000;">
    <app-dashboard-card-footer *ngIf='selectedReport.reportCellList.aggregate'
      [aggregate]='selectedReport.reportCellList.aggregate'> </app-dashboard-card-footer>
  </div>

  <div *ngIf="this.selectedReport.reportMenu.viewType === 'Line Chart'">
    <enzy-line-chart [dataInput]="this.parseData(this.selectedReport.reportCellList)" [lineChartConfig]="this.getLineChartConfig()"></enzy-line-chart>
  </div>


  <div *ngIf="this.selectedReport.reportMenu.viewType === 'Pie Chart'">
    <enzy-pie-chart [dataInput]="this.parseData(this.selectedReport.reportCellList)" [pieChartConfig]="this.getPieChartConfig()"></enzy-pie-chart>
  </div>



  <div
    *ngIf="this.selectedReport.reportCellList.list !== undefined && this.selectedReport.reportCellList.list.length !== 0">
    <div *ngIf="this.selectedReport.reportMenu.viewType === 'Table'"
      style="margin:auto; display: block;">
      <div *ngIf="selectedReport.reportCellList">
        <app-drill-down-table [reportCellList]='selectedReport.reportCellList'
          [reportMenu]='selectedReport.reportCellList.menu' [selectUserMode]='selectUserMode'>
        </app-drill-down-table>
      </div>
    </div>

    <div style="padding: 10px;">
      <div *ngIf="this.selectedReport.reportMenu.viewType === 'Column Chart'"
        style="max-width: 600px; margin:auto; display: block;">
        <app-custom-bar-chart [isDashboard]="false" [reportCellList]="this.selectedReport.reportCellList">
        </app-custom-bar-chart>
      </div>

    </div>

    <div *ngIf="selectedReport.reportMenu.viewType === 'Leaderboard'"
      style="max-width: 600px; margin:auto; display: block;">
      <app-expandable-leaderboard [isDashboard]='false' [report]='this.selectedReport'> </app-expandable-leaderboard>
    </div>


    <div *ngIf="selectedReport.reportMenu.viewType === 'Map'" style="max-width: 600px; margin:auto; display: block;">
      <app-map-report style="
                height: 500px;
                width: 100%;
                display: block;"></app-map-report>
    </div>

  </div>



  <div
    *ngIf="this.selectedReport.reportCellList.list !== undefined && this.selectedReport.reportCellList.list.length === 0"
    style="text-align: center;">
    <div style="height: 100px"></div>
    <span class="material-icons">
      search_off
    </span>

    <p> No results found. <br> Please adjust filters and try again. </p>
  </div>


  <div style="margin: auto;">
    <mat-paginator
      *ngIf="this.selectedReport.reportMenu.viewType === 'Table' || this.selectedReport.reportMenu.viewType === 'Leaderboard' "
      style="background-color: transparent; " [length]="paginationTotalLength" [pageSize]="paginationSize"
      [hidePageSize]="true" (page)="this.changePagination($event)"></mat-paginator>
  </div>

  <div style="height: 200px;"></div>

</div>