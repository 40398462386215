import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Workspace } from '../models/api-response.model';
import { REST_BASE_URL } from '../models/constants';
import { UserDetailsService } from './user-details.service';
import { Storage } from "@ionic/storage";
import { P } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient,
    public userDetailsService: UserDetailsService,
    public localStorage: Storage,
  ) {
   
    this.initSelectedWorkspace();
  }

  initSelectedWorkspace(){
    this.loadSelectedWorkspaceFromCache().then(res => {
      if (res) {
        this.selectedWorkspace = res
      }
    });
  }

  selectedWorkspace: Workspace;
  workspaces: Workspace[] = [];

  public async getWorkspaces(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/userWorkspaces/' + sessionKey;
    return this.http.get<any>(url).toPromise();
  }



  public async setWorkspace(workspaceId: number): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/updateUserWorkspace/' + sessionKey;
    url += "?workspaceId=" + workspaceId;
    return this.http.get<any>(url).toPromise();
  }


  async updateMyPreferredName(newPreferredName: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    const userId: string = await this.userDetailsService.getUserId();
    let url: string = REST_BASE_URL + "/updateEmployee/" + sessionKey;
    url += "?userId=" + userId;
    url += "&preferredFirstName=" + newPreferredName;
    // let body = new FormData();
    //     body.append('userId', userId);
    //     body.append('preferredFirstName', newPreferredName);

    return this.http.get(url).toPromise();
  }


  setSelectedWorkspacesFromLogin(res: any) {
    this.workspaces = res.workspaces;

    this.workspaces.forEach(workspace => {
      if (workspace.isSelected) {
        this.selectedWorkspace = workspace;
        this.cacheSelectedWorkspace(workspace);
        return;
      }
    })
  }


  //***************CACHE METHODS****************/
  cacheSelectedWorkspace(workspace: Workspace) {
    setTimeout(() => {
      this.localStorage.set("SELECTED_WORKSPACE", workspace);
      console.log("CACHED SELECTED WORKSPACE")
    }, 10);
  }

  async loadSelectedWorkspaceFromCache(): Promise<Workspace> {
    let res = await this.localStorage.get("SELECTED_WORKSPACE");
    if (res) {
      return res;
    } else {
      return null;
    }
  }

  public async checkIfCachedWorkspace(): Promise<any> {
    return this.localStorage.get("SELECTED_WORKSPACE")
  }
}
