<mat-card style="margin-bottom: 10px;" >
  <ion-grid>
    <ion-col size='6'>
      <ion-row>
        <ion-col size='3' style="margin-right: 10px;">
          <mat-form-field >
            <input matInput required
            [(ngModel)]='this.milestone.prizeDescription1' 
            autocomplete="off" 
            type="text" 
            placeholder="Prize Description" />
          </mat-form-field>
        </ion-col>
        <ion-col size="3" style="margin-right: 10px;">
          <mat-form-field>
            <input matInput required
            (change)="onTierChange($event)"
            [(ngModel)]='this.milestone.minValue' 
            autocomplete="off" 
            type="number" 
            min="0"
            step="1"
            placeholder="Minimum Value" />
          </mat-form-field>
        </ion-col>
        <ion-col size="3" style="margin-right: 10px;">
          <mat-form-field style="width: 100%;">
            <mat-label>Competition Metric</mat-label>
            <mat-select [(ngModel)]="this.selectedTier" (selectionChange)="onTierChange($event)">
              <mat-option *ngFor="let tier of getTierOptions()" [value]="tier">
                {{tier}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="3">
          <button type="button"
                mat-button style="text-align:center;" 
                (click)="addPrizeImageFile.click()">
              {{this.milestone.imageUrl ? "Edit" : "Add"}} Prize Image
              </button>
              <input hidden (change)="saveFile($event)" #addPrizeImageFile type="file" value="" name="Attach">
          <img *ngIf='this.milestone.imageUrl' [src]='this.milestone.imageUrl' style="max-width: 100px;" />
        </ion-col>
        <ion-col size="3">
          <mat-form-field >
            <input matInput 
            [(ngModel)]='this.milestone.prizeUrl' 
            autocomplete="off" 
            type="text" 
            placeholder="Prize Url (Navigation)" />
          </mat-form-field>
        </ion-col>
      </ion-row>
    </ion-col>
    <!-- <ion-col size='3'>
      <app-prize [prize]='milestone'></app-prize>
    </ion-col> -->
    
  </ion-grid>

</mat-card>

<mat-divider></mat-divider>
