import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Workspace } from '../../models/api-response.model';
import { APP_VERSION } from '../../models/constants';
import { HEADER_ANIMATIONS } from '../../models/header-animations';
import { ProfileService } from '../../services/profile.service';
import { HeaderService } from './header.service';


@Component({
  selector: 'shared-my-header',
  templateUrl: './my-header.component.html',
  styleUrls: ['./my-header.component.scss'],
  animations: HEADER_ANIMATIONS,
})
export class MyHeaderComponent implements OnInit {

  outOfDateApp: boolean = false;

  selectedWorkspaceImage: string = "";

  @Output() onRefreshPage = new EventEmitter<any>();


  constructor(
    private platform: Platform,
    public router: Router,
    public nav: HeaderService,
    public dialog: MatDialog,
    public headerService: HeaderService,
    public profileService: ProfileService    ) {
    this.checkAppVersion();

    this.profileService.checkIfCachedWorkspace().then(async res => {
      if (!res) {
        let res = await this.profileService.getWorkspaces();
        this.profileService.setSelectedWorkspacesFromLogin(res);
      }
    })

  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  goToAppStore() {
    if (this.platform.is("ios")) {
      this.goToLink("https://apps.apple.com/us/app/enzy/id1554333386");
    } else {
      this.goToLink("https://play.google.com/store/apps/details?id=com.sunder.sales&hl=en_US&gl=US");
    }
  }
  async checkAppVersion() {
    if (!this.platform.is("ios") && !this.platform.is("android")) {return}

    let res = await this.headerService.checkLatestAppVersion();
    if (!res.success) {return}
    
    if (APP_VERSION.startsWith(res.appVersion)) {
      this.outOfDateApp = false;
    } else {
      this.outOfDateApp = true;
    }
  }

  selectedWorkspace: Workspace;

  getWorkspaceImage() {
    return this.selectedWorkspace?.imageUrl
  }

  ngOnInit(): void {
    this.profileService.loadSelectedWorkspaceFromCache().then(res=> {
      this.selectedWorkspaceImage = res.imageUrl;
    })
    this.checkWorkspace();
  }

  ///https://enzy-bucket1-resized.s3.us-east-2.amazonaws.com/userProfileImages/userId689_name
  
  getTitle(): string {
    if (this.router.url.includes('/home')) return "Leaders";
    if (this.router.url === '/analytics') return "Reports";
    if (this.router.url === '/incentives') return "Incentives";
    if (this.router.url === '/messages') return "Messages";
    if (this.router.url === '/profile') return "Profile";
    if (this.router.url === '/library') return "Videos";
    if (this.router.url === '/campaigns') return "Incentives Admin";
    if (this.router.url === '/onboarding-list') return "Recruit";
    if (this.router.url === '/new-rep') return "New Rep";
    if (this.router.url === '/faq') return "FAQ";
    if (this.router.url === '/sign-contract') return "Carbon Credit Agreement";
    if (this.router.url === '/company-records') return "Records";
    if (this.router.url === '/admin-directory') return "User Management";
    if (this.router.url === '/key-contacts') return "Help";
    if (this.router.url.includes('/lead-management')) return "Leads";
    if (this.router.url.includes('dashboard')) return "Live Dashboard";
    if (this.router.url === '/lead-submission') return "Lead Submission";
    if (this.router.url === '/ticket-management') return "Ticket Admin";
    if (this.router.url === '/admin-tools') return "Admin Tools";
    if (this.router.url === '/admin-page') return "Admin";
    if (this.router.url === '/calendar') return "Calendar";
    if (this.router.url === '/tally-tracker') return "Tallys";

    if (this.router.url.includes('edit-tournament')) return "Edit Mode";
    return "";
  }
  async checkWorkspace(){
    let res = await this.profileService.getWorkspaces();

    let workspaces = res.workspaces;

    workspaces.forEach(workspace => {
      if (workspace.isSelected) { this.selectedWorkspace = workspace }
    })
  }


  //-Eric asked to be able to refresh the leaderboard. This is a quick win for him
  refreshPage() {
    this.onRefreshPage.emit(true)
  }

}
