
import { Injectable } from "@angular/core";
import { Storage as capStorage } from '@capacitor/storage';
import { FooterTab } from "./footer.service";


@Injectable({
    providedIn: 'root'
})
export class FooterStoreService {

    //DEFAULT FOOTERS
    footerTabs: FooterTab[] = [
        {

            title: "Leaders",
            iconImageUrl: "../../../../assets/leadersIcon.svg",
            routerUrl: "/home/0",
            position: 0

        },
        {
            title: "Messages",
            iconImageUrl: "../../../../assets/messagingIcon.svg",
            routerUrl: "/messages",
            position: 1
        },
    ];

    firstFooterUrl: string = "/home/0"; //Most people will navigate to the leaderboard

    constructor() {
        this.initFooter();
    }

    async initFooter() {
        await this.loadFooterTabsFromCache();
    }


    async clearfooterTabs(): Promise<any> {
        return await capStorage.remove({ key: 'footerTabs' });
    }

    async setfooterTabs(footerTabs: FooterTab[]): Promise<any> {
        this.footerTabs = footerTabs;
        // this.footerTabs?.push({
        //     title: "Knocking",
        //     icon: "fire",
        //     routerUrl: "/knocking",
        //     position: this.footerTabs.length,
        // })
        let firstTab = footerTabs[0]?.routerUrl ? footerTabs[0]?.routerUrl : "/home/0";
        await this.setFirstFooterUrl(firstTab);
        return await capStorage.set({
            key: 'footerTabs',
            value: JSON.stringify(footerTabs),
        });
    }

    async setFirstFooterUrl(firstTabUrl: string): Promise<any> {
        return await capStorage.set({
            key: 'firstFooterUrl',
            value: JSON.stringify(firstTabUrl),
        });
    }


    async loadFooterTabsFromCache() {
        let result: FooterTab[] = JSON.parse((await capStorage.get({ key: "footerTabs" }))?.value);
        if (result) {
            this.footerTabs = result;
        }
    }


     async loadFirstFooterUrlFromCache() {
        let result: string = JSON.parse((await capStorage.get({ key: "firstFooterUrl" }))?.value);
        if (result) {
            this.firstFooterUrl = result;
        }
    }
}