
<div class="opaque-div" style="width: 100%; border-radius: 10px;" (click)="openAppointmentInfo()">
  <ion-toolbar class="transparent-bg" style="width: 100%;">

    <div slot="start">
      <div style="width: 5px;"></div>
    </div>
    <div slot="start">
      {{appointment.title}}
    </div>
  
    <div slot="end">
      {{this.dateFormatService.formatDateFromString(appointment.startDateTime)}}
    </div>
    
    <div slot="end" >
      <button (click)="this.delete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  
  </ion-toolbar>
</div>
