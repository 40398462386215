import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { Storage } from '@ionic/storage';
import { AbstractLoadsReportDataComponent } from 'src/app/shared/abstract-classes/abstract-saves-report';
import { BusySpinnerComponent } from 'src/app/shared/components/busy-spinner/busy-spinner.component';
import { LineChartConfig } from "src/app/shared/components/charts/line-chart/line-chart.component";
import { PieChartConfig } from "src/app/shared/components/charts/pie-chart/pie-chart.component";
import { DataReportMenu, GroupByOption, ShowOption } from 'src/app/shared/models/dashboard-report-list.model';
import { DashboardData, UserData } from 'src/app/shared/models/dashboard.model';
import { Employee } from 'src/app/shared/models/employees.model';
import { ReportCellDataPoint, ReportCellList } from 'src/app/shared/models/report-cell-list.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { ThemeService } from "src/app/shared/services/theme-service.service";
import { UserDetailsService } from "src/app/shared/services/user-details.service";
import { AdvancedFiltersComponent } from '../filters/advanced-filters/advanced-filters.component';
import { BasicFilterComponent } from '../filters/basic-filter/basic-filter.component';



@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss'],
})
export class ReportDetailComponent implements OnInit {

  selectedReport: DashboardData;
  @Input() set reportInput(newReport: DashboardData) {
    this.selectedReport = newReport;
    this.parseData(this.selectedReport.reportCellList)
  }



  @Input()
  fromHomePage: boolean = false;

  @Input()
  selectUserMode: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  paginationData: PageEvent;

  paginationTotalLength: number = 0;
  paginationSize: number = 50;

  userId: number;

  constructor(
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    private reportsService: ReportsService) {

  }

  ngOnInit() {
    // this.setPaginationData();
    console.log(this.selectedReport)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setPaginationData(); //TODO: Some reports don't have a reportMenu, which causes some crazy errors
    },500)
  }

  getLineChartConfig(): LineChartConfig {
    return {
      height: 600,
      showAxis: true
    }
  }

  getPieChartConfig(): PieChartConfig {
    return {
      height: 500,
      showLegend: true
    }
  }


  // TODO: When can I call this to initialize it while guaranteed to have a reportCellList?
  setPaginationData() {
    // Extract info
    if (!this.selectedReport.reportCellList.listNav) { return }
    const paginationDescription: string = this.selectedReport.reportCellList.listNav.showing;
    // FORMATED EXAMPLE "<offset>-<offset + pageSize> of <totalLength>"
    const tempArray: string[] = paginationDescription.split("of ");
    this.paginationTotalLength = +(tempArray[tempArray.length - 1].replace(/\,/g, ""));

  }

  changePagination(pageEvent: PageEvent) {
    this.paginationData = pageEvent;

    // Pass report menu and offset to endpoint to reload list data
    this.loadReportCellListByReportMenu(this.selectedReport.reportMenu);


  }


  /**
   * Parses data for the line chart and the pie chart?
   *
   *
   * @param reportCellList
   */
   parseData(reportCellList: ReportCellList) {
    //  console.log(reportCellList)
    if (reportCellList.list) {

      let output = reportCellList.list.map((cell: any) => {
        return {
          "date": cell.cellList[0].value,
          "dataPointValue": (cell.cellList[1] && cell.cellList[1].value) ? +(cell.cellList[1].value).replace(/\$|,|%/g, '') : ""
        }
      })

      return output
    }
  }

  attemptNumberParse(input: string): boolean {
    return (typeof +input === 'number')
  }


  private reorderSelectedReportShowOptions(): void {
    let first = this.selectedReport.reportMenu.selectedStratField
    this.selectedReport.reportMenu.showOptionList.sort((a, b) => {

      return a.field === first ? -1 : b.field === first ? 1 : 0;
    })
  }

       

  private loadReportCellListByReportMenu(reportMenu: DataReportMenu) {

    // this.isLoading = true;
    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    // if (this.showOptionString !== "" && this.groupByString !== "") {
    //   this.setReportMenuSelectedOptions();
    // }

    this.reorderSelectedReportShowOptions();


    const offset: number = (this.paginationData) ? this.paginationData.pageIndex * this.paginationData.pageSize : 0
    
    if (this.selectedReport.reportMenu.viewType !== 'Leaderboard') {
      this.reportsService.getDataReportList( reportMenu, offset, true, false, "", 50).then((reportCellList: any) => {

        this.selectedReport.reportCellList = reportCellList;
        this.parseData(this.selectedReport.reportCellList);

        // Preserve the view type
        const priorViewType = this.selectedReport.reportMenu.viewType;
        this.selectedReport.reportMenu = reportCellList.menu;
        this.selectedReport.reportMenu.viewType = priorViewType;


        // this.isLoading = false;
        this.dialog.closeAll()

        this.setPaginationData();
      }, (error) => {

        this.snackBarService.displaySnackBarMessage("Report Failed to load", false);
        this.dialog.closeAll();
      });
    }

    else if (this.selectedReport.reportMenu.viewType === 'Leaderboard') {
      this.reportsService.getDataReportList(reportMenu, offset, true, false, "", 50).then((reportCellList: any) => {
        this.selectedReport.reportCellList = reportCellList;
        this.parseData(this.selectedReport.reportCellList);

        // Preserve the view type
        const priorViewType = this.selectedReport.reportMenu.viewType;
        this.selectedReport.reportMenu = reportCellList.menu;
        this.selectedReport.reportMenu.viewType = priorViewType;

        // this.isLoading = false;
        this.dialog.closeAll()

        this.setPaginationData();
      }, (error) => {

        console.error("Get Report List Error", error)

        this.snackBarService.displaySnackBarMessage("Report Failed to load", false);

        this.dialog.closeAll();
      });
    }
  }
}
