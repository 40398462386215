<ion-content>

  <mat-toolbar
    style="background-color: #383838; background-image: url('{{this.team.backgroundUrl}}'); background-size: cover; height: 200px; padding: 0; margin: 0;">
    <div class="gradient-detail">
      <div style="height: 30px"> </div>
      <ion-grid style="padding: 0; margin: 0;  padding: 0; margin: 0;  ">
        <ion-row style="padding: 0; margin: 0;">
          <ion-col size="2" style="text-align: center; ">


            <button mat-mini-fab color="'white'" style="text-align: center;" (click)="goBack()">
              <mat-icon style="text-align: center; padding-left: 5px;">arrow_back_ios</mat-icon>
            </button>
          </ion-col>

          <ion-col size="8" style="text-align: center; padding: 0; margin: 0;">

            <div style="height: 50px"> </div>
            <ion-grid style="padding: 0; margin: 0;">
              <ion-row style="padding: 0; margin: 0;">
                <ion-col style="padding: 0; margin: 0;">
                  <h1 style="padding: 0; margin: 0; font-size: 30px"> {{team.currentRanking}} {{team.name}} </h1>
                </ion-col>
              </ion-row>

              <ion-row style="padding: 0; margin: 0;">
                <ion-col style="padding: 0; margin: 0;">
                  <p style="padding: 0; margin: 0; padding-bottom: 10px; font-size: 15px"> {{team.record}} </p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>

          <ion-col size="2" style="text-align: right;">

          </ion-col>
        </ion-row>

        <ion-row>
          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden;">


            <div style="padding: 5px;"></div>

            <div>
              <button
                [ngClass]="{'filter-button' : this.selectedOption !== 'People', 'selected-filter-button' : this.selectedOption === 'People'}"
                mat-button (click)="selectOption('People')">
                <span class="zeroLineHeight">People</span>
              </button>
            </div>



            <div style="padding: 5px;"></div>

            <div>
              <button
                [ngClass]="{'filter-button' : this.selectedOption !== 'Games', 'selected-filter-button' : this.selectedOption === 'Games'}"
                mat-button (click)="selectOption('Games')">

                <span class="zeroLineHeight">Games</span>


              </button>
            </div>


            <div style="padding: 5px;"></div>

            <div>
              <button
                [ngClass]="{'filter-button' : this.selectedOption !== 'Standings', 'selected-filter-button' : this.selectedOption === 'Standings'}"
                mat-button (click)="selectOption('Standings')">


                <span class="zeroLineHeight">Standings</span>


              </button>
            </div>

            <div style="padding: 5px;"></div>

            <div>
              <button
                [ngClass]="{'filter-button' : this.selectedOption !== 'Trophies', 'selected-filter-button' : this.selectedOption === 'Trophies'}"
                mat-button (click)="selectOption('Trophies')">


                <span class="zeroLineHeight">Trophies</span>


              </button>
            </div>


            <div style="padding: 5px;"></div>

            <div style="height: 10px;"></div>
          </div>
        </ion-row>
        <ion-row>
          <div style="height: 10px;"></div>

        </ion-row>

      </ion-grid>
    </div>
  </mat-toolbar>

  <div style="padding: 20px" class="darkTheme">

    <div *ngIf="this.selectedOption === 'People'">
      <div *ngFor="let person of this.team.people">
        <ion-grid style="color: white;">
          <ion-row style="padding-bottom: 15px;">
            <ion-col size="2">
              <app-user-icon [employee]="null" [initials]="person.name[0]+person.name[1]"></app-user-icon>
            </ion-col>
            <ion-col size="10"> <span style="margin-top: -30px">{{person.name}}</span></ion-col>
          </ion-row>

        </ion-grid>
      </div>
    </div>
    <div *ngIf="this.selectedOption === 'Games'">
      <div *ngFor="let game of this.foundGames">

        <app-game-card [game]='game'></app-game-card>


        <div style="height: 20px;"></div>
        <mat-divider></mat-divider>
        <div style="height: 20px;"></div>

      </div>
    </div>
    <div *ngIf="this.selectedOption === 'Standings'">
      <app-expandable-leaderboard [isDashboard]='false' [report]='this.leaderboardReport'
              > </app-expandable-leaderboard>
    </div>
    <div *ngIf="this.selectedOption === 'Trophies'">

      <p style="margin: 0; padding: 0; color: white; padding-bottom: 20px">Team Banners</p>
      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 280px;">


        <div style="position: relative; margin-left:-30px">
          <div class="ribbon-1">
            <p style="padding: 0; margin: 0; font-size: 40px; line-height: 2; text-align: center;"> 2019</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Sunder</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Champs</p>

          </div>
        </div>


        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div class="ribbon-2">
            <p style="padding: 0; margin: 0; font-size: 40px; line-height: 2; text-align: center;"> 2018</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Semi</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Finalists</p>

          </div>
        </div>
        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div class="ribbon-1">
            <p style="padding: 0; margin: 0; font-size: 40px; line-height: 2; text-align: center;"> 2017</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Sunder</p>
            <p style="padding: 0; margin: 0; font-size: 25px; line-height: 2; text-align: center;"> Champs</p>

          </div>
        </div>


        <div style="height: 10px;"></div>
      </div>



      <p style="margin: 0; padding: 0; color: white; padding-bottom: 20px">Team Records</p>
      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 250px;">


        <div style="position: relative; ">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 10px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/troph.webp" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Most Sales In One Day</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>


        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 10px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/chest.webp" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Most Revenue Generate In One Year</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 15px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/solar.webp" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Most Installs Last Year</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>


        <div style="height: 10px;"></div>
      </div>


      <p style="margin: 0; padding: 0; color: white; padding-bottom: 20px">Prizes Won</p>
      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 200px;">


        <div style="position: relative; ">
          <div class="gameCard" style="padding: 0; margin: 0;">
            <mat-card class="gameCard box"
              style="background-color: #383838; background-image: url('/assets/CaribbeanTrip.jpg'); background-size:100% 100%;  padding: 0; margin: 0;">
             
  
              
              <div class="gradient">
                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col style="padding: 0; margin: 0;">
                      <h1 style="padding: 0; margin: 0; line-height: 150px; text-align: center"> Caribbean Trip</h1>
                    </ion-col>
                  </ion-row>

                </ion-grid>
              </div>
            </mat-card>
          </div>
        </div>


        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div class="gameCard" style="padding: 0; margin: 0;">
            <mat-card class="gameCard box"
              style="background-color: #383838; background-image: url('/assets/AppleWatch.png'); background-size:100% 100%;  padding: 0; margin: 0;">
             
  
              
              <div class="gradient">
                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col style="padding: 0; margin: 0;">
                      <h1 style="padding: 0; margin: 0; line-height: 150px; text-align: center"> Apple Watch</h1>
                    </ion-col>
                  </ion-row>

                </ion-grid>
              </div>
            </mat-card>
          </div>
        </div>
        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div class="gameCard" style="padding: 0; margin: 0;">
            <mat-card class="gameCard box"
              style="background-color: #383838; background-image: url('/assets/cash.jpg'); background-size:100% 100%;  padding: 0; margin: 0;">
             
  
              
              <div class="gradient">
                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col style="padding: 0; margin: 0;">
                      <h1 style="padding: 0; margin: 0; line-height: 150px; text-align: center;"> $1,000 Cash</h1>
                    </ion-col>
                  </ion-row>

                </ion-grid>
              </div>
            </mat-card>
          </div>
        </div>
        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div class="gameCard" style="padding: 0; margin: 0;">
            <mat-card class="gameCard box"
              style="background-color: #383838; background-image: url('/assets/Macbook.jpg'); background-size:100% 100%;  padding: 0; margin: 0;">
             
  
              
              <div class="gradient">
                <ion-grid style="padding: 0; margin: 0;">
                  <ion-row style="padding: 0; margin: 0;">
                    <ion-col style="padding: 0; margin: 0;">
                      <h1 style="padding: 0; margin: 0; line-height: 150px; text-align: center">Macbook</h1>
                    </ion-col>
                  </ion-row>

                </ion-grid>
              </div>
            </mat-card>
          </div>
        </div>


        <div style="height: 10px;"></div>
      </div>


      <p style="margin: 0; padding: 0; color: white; padding-bottom: 20px">Team All-Stars</p>
      <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 250px;">


        <div style="position: relative; ">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 5px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/headshots/hshot1.jpg" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Bryan Amaro</p>
                  <p style="padding: 0; margin: 0; font-weight: 100;">315 Sales</p>

                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>


        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 5px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/headshots/hshot2.jpg" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Kyle Becker</p>
                  <p style="padding: 0; margin: 0; font-weight: 100;">310 Sales</p>

                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
        <div style="padding: 5px;"></div>
        <div style="position: relative;">
          <div  class="sectionDiv">
            <ion-grid>
              <div style="height: 5px"></div>
              <ion-row>
                <ion-col size="12">
                  <img  src="/assets/headshots/hshot3.jpg" style="object-fit: cover; height: 120px; width: 120px; border-radius: 50%; display: block; margin: auto;" />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" style="text-align: center;">
                  <p style="padding: 0; margin: 0;">Nick Sanchez</p>
                  <p style="padding: 0; margin: 0; font-weight: 100;">299 Sales</p>

                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>


        <div style="height: 10px;"></div>
      </div>
    </div>


  </div>
</ion-content>