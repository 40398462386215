import { L } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { MessageActionsDrawerComponent } from 'src/app/messaging/message-actions-drawer/message-actions-drawer.component';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Appointment, DEFAULT_APPOINTMENT, DEFAULT_NOTE, DEFAULT_TASK, Note, Task } from '../leads.model';

@Component({
  selector: 'app-leads-drawer',
  templateUrl: './leads-drawer.component.html',
  styleUrls: ['./leads-drawer.component.scss'],
})
export class LeadsDrawerComponent implements OnInit {

  title = "";
  public appointment: Appointment = { ...DEFAULT_APPOINTMENT };
  public task: Task = { ...DEFAULT_TASK };
  public note: Note = { ...DEFAULT_NOTE };

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) 
    public data: {
      title: string,
      appointment?: Appointment,
    },
    public snackbarService: SnackbarService,
    public dateFormatService: DateFormatService,
    private _bottomSheetRef: MatBottomSheetRef<LeadsDrawerComponent>) {
    this.title = data.title

    if (this.data.appointment) {
      this.appointment = this.data.appointment;
    }


  }

  ngOnInit() { }

  cancel() {
    this._bottomSheetRef.dismiss();
  }

  save() {
    if (this.title === "Appointments") {
      if (!this.appointment.startDateTime) {
        this.snackbarService.displaySnackBarMessage("Please add start time")
        return;
      }

      this.appointment.startDateTime = this.dateFormatService.saveDateWithCorrectFormat(this.appointment.startDateTime)
      this._bottomSheetRef.dismiss(this.appointment);
    } else if (this.title === "Tasks") {

      if (this.task.dueDate) {
        this.task.dueDate = this.dateFormatService.saveDateWithCorrectFormat(this.task.dueDate)
      }

      this._bottomSheetRef.dismiss(this.task);
    } else if (this.title === "Notes") {
      if (this.note.note.length > 1000) { return }
      this._bottomSheetRef.dismiss(this.note);
    }
  }

}

/*
    description: string,
    authorId: number,
    creationDate: string,
    dueDate: string,
    status: string,
    completionDate: string,
*/
