<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<ion-header class="no-padding-header" [ngClass]="{'android-header-height': !isIOS()}">
  <div style='max-width: 1200px; display: block; margin: auto;'>

    <ion-toolbar class="no-padding-header" [ngClass]="{'android-header-height': !isIOS(), 'ios-height': isIOS() }">

      <ion-buttons slot="start">
        <ion-button style="height: 50px; width: 50px;" (click)="back()">
          <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title> <span matLine style="padding: 0; margin: 0; font-size: 17px; font-weight: 400;">
          <span class="primary-color" style="border-radius: 5px; padding: 1px;" *ngIf="isBcc">Private</span>
          &nbsp;{{getConversationTitle()}}</span>
      </ion-title>
      <ion-buttons slot="end">

        <button *ngIf="this.getGroupImage() !== '' " mat-fab (click)="openDrawer()" class="opaque-div"
          style="height: 30px; width: 30px; padding: 0; border-radius: 5px; background-size: cover;"
          [ngStyle]="{'background-image': 'url(' + this.getGroupImage() + ')'}">
        </button>



        <button *ngIf="this.getGroupImage() === '' " mat-fab (click)="openDrawer()" class="primary-color"
          style="height: 30px; width: 30px; padding: 0; border-radius: 5px; background-size: cover;"
          [ngStyle]="{'background-image': 'url(' + this.getGroupImage() + ')'}">

          <span style="margin-top: -30px; color: white;" class="material-icons circle-icon opaque-div">
            groups
          </span>
        </button>
        <div style="padding: 5px"></div>
      </ion-buttons>

    </ion-toolbar>

    <mat-progress-bar *ngIf="shouldShowSendingMessageBar()" mode="determinate" [value]="sendingMessageBarValue">
    </mat-progress-bar>

    <div *ngIf='this.displayReconnectingMessage()'
      style="height: 30px; font-size: 12 px; background-color: #c7c771; color: white; text-align: center; padding-top: 5px"
      (click)='this.reconnect()'>
      Trying to connect...
    </div>

  </div>
</ion-header>
<ion-content #content scrollY="false">

  <div #scrollMe class="custom-scroll" style=" overflow-x: hidden; height: 100%; width: 100%; overflow-anchor: none; "
    (scroll)="triggerLoadNewMessages($event)">

    <div style='max-width: 1200px; display: block; margin: auto;'
      [ngStyle]="replyMessage ? {'opacity' : 0.1} : {'opacity' : 1} ">


      <div *ngIf="!this.isBcc">
        <div *ngIf="messages && messages.length !== 0" id="start-{{this.conversation.conversationId}}" style="height: 20px; " class="opaque-text">
          <span style="display: block; margin: auto; text-align: center; width: 100%; font-size:12px; margin-top: 10px;" *ngIf='!allMessagesLoaded && isLoading'> Fetching more... </span>
          <span style="display: block; margin: auto; text-align: center; width: 100%; font-size:12px; margin-top: 10px;" *ngIf='!allMessagesLoaded && !isLoading'>  </span>
          <span  style="display: block; margin: auto; text-align: center; width: 100%; font-size:12px; margin-top: 10px;" *ngIf='allMessagesLoaded'> All Messages Loaded</span>
        </div>
  
        <div *ngIf='isLoading' style="text-align: center; width: 100%;">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div>

          <ion-grid>


            <div #ngForMessages [@listAnimation]="messages.length"
              *ngFor="let message of getSortedMessages(); let i = index; let last = last; trackBy: identifyMessage "
              class="my-animation">

              <div *ngIf="previousMessageHasDifferentSendDate(i)">


                <div style="height: 20px;"></div>

                <mat-divider></mat-divider>

                <div style="margin-top: -10px;">
                  <span class="filter-button opaque-text"
                    style="display: block; margin: auto; width: 100%; text-align: center; max-width: 100px; height: 20px; font-weight: 100;">
                    {{getDateStringForIndex(i) | date: 'MMM dd' }}
                  </span>
                </div>

                <div style="height: 20px;"></div>
              </div>



              <!-- <ion-row> -->
              <app-message-cell id="message-{{message.messageId}}" (onAttemptResend)="this.resendMessage(message)"
                (onEditMessage)="this.editMessage(message)" (onDirectMessage)=" this.reloadMessages()"
                (onReplyMessage)="reply($event)" [messageInput]='message' [messageIndex]='(messages.length - i) - 1'
                [userId]="userId" [thumbsUpCount]="0" [thumbsDownCount]="0" [showUserIcon]="shouldShowUserIcon(i)"
                [thisMessageIsFromCurrentUser]="message.fromUser.toString() === userId.toString()"
                [sessionKey]='sessionKey' [isScrolling]="scroll" [isPending]="message.isPending"
                (activeReportLines)='this.setActiveReportLines($event)'>
              </app-message-cell>


              <!-- </ion-row> -->
            </div>

          </ion-grid>

          <div *ngFor="let message of this.messagingStoreService.tempMessages; let i = index" class="my-animation">
            <app-message-cell id="message-{{message.messageId}}" (onAttemptResend)="this.resendMessage(message)"
              (onDirectMessage)=" this.reloadMessages()" (onReplyMessage)="reply($event)" [messageInput]='message'
              [userId]="userId" [thumbsUpCount]="0" [thumbsDownCount]="0" [messageIndex]='null'
              [thisMessageIsFromCurrentUser]="message.fromUser === userId" [sessionKey]='sessionKey'
              [isScrolling]="scroll" [isPending]="message.isPending"
              (activeReportLines)='this.setActiveReportLines($event)'>
            </app-message-cell>
            <div style="height: 8px;"></div>
          </div>

          <div *ngIf="messages && messages.length !== 0 && !this.settingNewMessages" id="end"
            style="color: transparent; height: 2px;">
            end
          </div>

          <button class="message-toolbar-button" ion-button icon-only item-left mat-icon-button
            *ngIf="shouldShowDownArrow()" (click)="scrollToElement(target)"
            style="position: fixed; bottom: 150px; right: 10px; z-index: 999;">
            <span class="material-icons">
              arrow_downward
            </span>
          </button>
          <div #target></div>
        </div>
      </div>
    </div>

    <!-- <span style="text-align: center; width: 100vw; font-weight: 100; display: block; margin: auto;" class="opaque-text">You're up to date 🎉 </span> -->
    <div style="height: 115px;"></div>
  </div>
  <div no-border class="messaging-footer">
    <mat-divider></mat-divider>
    <div style='max-width: 1200px; display: block; margin: auto;'>
      <div *ngIf="replyMessage" style="padding: 20px">
        <app-reply-card [message]="replyMessage"></app-reply-card>
      </div>
      <div *ngIf="this.selectedFile" style="padding: 20px">
        <div style="display: block; margin: auto;">
          <img [src]="imgURL" height="200" style="display: block; margin: auto;" *ngIf="imgURL" (load)="onLoad()"
            #selectedImage>
        </div>
      </div>

      <div *ngIf="this.selectedGif !== ''" style="padding: 20px">
        <ion-img style="height: 200px; display: block; margin: auto;" [src]="this.selectedGif"></ion-img>
      </div>

      <div class="input-wrap " *ngIf='showChatTextBox()'>
        <textarea matInput #chatInput [(ngModel)]="this.messagingStoreService.editorMsg" (keyup.enter)="sendMsg()"
          placeholder="Message"
          style="width: 100%; min-height: 35px; background-color: transparent; font-weight: 100; padding: 0px;"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="15"
          [mention]="mentionPeople" [mentionConfig]="mentionConfig"></textarea>
        <div style="padding: 5px"></div>

      </div>


      <div class="input-wrap" *ngIf='showChatTextBox()'>
        <div style="padding: 5px"></div>




        <button class="message-toolbar-button" ion-button icon-only item-left mat-icon-button (click)="openGif()"
          style="text-align:center; width: 40px;">
          <span class="material-icons">
            gif
          </span>
        </button>
        <div style="padding: 5px"></div>


        <div style="display: block; margin: auto;">
          <form (ngSubmit)="onSubmit()">
            <div>
              <button class="message-toolbar-button" ion-button icon-only item-left mat-icon-button type="button"
                mat-button style="text-align:center; width: 40px;" (click)="fileInput.click()">

                <span class="material-icons">
                  attach_file
                </span>

              </button>
              <input hidden (change)="onFileSelected($event)" #fileInput type="file" value="" name="Attach">
            </div>
            <div>
            </div>
          </form>
        </div>


        <div style="padding: 5px"></div>

        <button class="message-toolbar-button" ion-button icon-only item-left mat-icon-button (click)="openPoll()"
          style="text-align:center; width: 40px;">
          <span class="material-icons">
            poll
          </span>
        </button>
        <div style="padding: 5px"></div>

        <div style="width: 80%;">
        </div>


        <button *ngIf="replyMessage || this.selectedFile || this.selectedGif !== ''"
          class="message-toolbar-button special-color-border" ion-button icon-only item-left mat-icon-button
          (click)="clear()" style="text-align:center; width: 40px; background-color: transparent;">
          <span class="material-icons special-app-text-color ">
            delete
          </span>
        </button>

        <div style="padding: 5px"></div>

        <button mat-button icon-only item-right mat-icon-button (click)="sendMsg()" style="text-align:center;"
          class="primary-color">
          <span *ngIf='!this.messagingStoreService.inEditMessageMode' class="material-icons">
            send
          </span>
          <span *ngIf='this.messagingStoreService.inEditMessageMode' class="material-icons">
            check
          </span>
        </button>
        <div style="padding: 5px"></div>

      </div>
      <div style="height: 20px"></div>
    </div>
  </div>
</ion-content>