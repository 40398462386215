import { Component, Input, OnInit } from '@angular/core';
import { MilestoneBackend } from 'src/app/shared/models/incentives.model';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';

@Component({
  selector: 'app-edit-milestone-row',
  templateUrl: './edit-milestone-row.component.html',
  styleUrls: ['./edit-milestone-row.component.scss'],
})
export class EditMilestoneRowComponent implements OnInit {

  @Input()
  milestone: MilestoneBackend;

  selectedTier: string = "SUBMITTED";

  constructor(
    public spinnerService: BusySpinnerService,
    public incentivesService: IncentivesService,

  ) { }

  ngOnInit() {}

  // TODO: This CANNOT be hard coded
  getTierOptions(): string[] {
    return ["SUBMITTED", "INSTALLS"];
  }

  onTierChange(event: string): void {
    console.log("Before", this.milestone.tierDescription);

    this.milestone.tierDescription = this.milestone.minValue + " " + this.selectedTier;
    console.log("After", this.milestone.tierDescription);
  }


  async saveFile(event: Event) {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const selectedFile: File = eventTarget.files[0];
    
    this.spinnerService.start();

    //upload file
    try {
      const newUrl: string = await this.incentivesService.saveImage(selectedFile, "MILESTONE+IMAGE" + this.milestone.tierDescription);
      this.milestone.imageUrl = newUrl;
    } catch(e) {
      console.error(e);
    }
    this.spinnerService.stop();
  }

  describeMilestone(m: MilestoneBackend): string {
    return JSON.stringify(m);
  }
}
