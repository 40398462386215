<div 
  responsesBySessionId
  [sessionId]="shouldShowIcon ? alert.sessionId: null"
  class="section-div center-text "
  [ngClass]="{'alert-box': isAlertSurvey(this.alert), 'green-box': greenResponse(alert)}" (click)="disposition()"
  style="min-height: 200px; margin-bottom: 20px; border-radius: 5px; width: 100%; padding: 10px ">


  <ion-row>
    <span [ngClass]="{'large-font': this.largeFont, 'small-font' : (!this.largeFont)}"
      style="width: 100%;">{{alert?.header}}</span>
  </ion-row>


  <ion-row>
    <span [ngClass]="{'large-font-icon': this.largeFont, 'small-font-icon' : (!this.largeFont)}"
      style="width: 100%;">{{alert?.iconString}}</span>
  </ion-row>

  <ion-row>
    <span [ngStyle]="this.largeFont ? {'font-size' : '20px'} : {'font-size' : '15px'}"
      style="font-weight: 100; display: block; margin: auto;" class="opaque-text"
      [ngClass]="{'alert-box': isAlertSurvey(alert), 'green-box': greenResponse(alert)}">
      {{this.getSurveyTimeStamp()}}
      <span *ngIf="isAlertSurvey(alert)">|
        Tap to clear</span></span>
  </ion-row>

</div>