<shared-my-header>
</shared-my-header>

<ion-content>
  <div style="height: 10px;"></div>

  <button mat-button *ngIf="customerSupportUserId" class="section-div" (click)="chatWithCustomerSupport()"
    style="max-width: 250px; display: block; margin: auto;">
    <div style="height: 10px;"></div>
    <app-user-icon class='user-icon' matListAvatar [isExtraLarge]="true" [employee]='getCustomerSupportUser()'>
    </app-user-icon>

    <div style="height: 5px;"></div>

    <h1 style="margin: 0;">{{getCustomerSupportUserName()}}</h1>
    <!-- <div style="height: 5px;"></div> -->
    <span class="opaque-text">Chat with Customer Support <span style="font-size: 20px;" class="material-icons iconAlign">
      chevron_right
      </span></span>
  </button>

  <div style="height: 20px;"></div>

  <mat-tab-group style="display: block; margin: auto; max-width: 1200px;">
    <mat-tab label="My Tickets">
      <div class='main-container'>

        <div class="ticket-card" *ngFor="let ticket of getMyTickets()">
          <app-ticket-card [ticket]='ticket'></app-ticket-card>
          <div style="height: 7px;"></div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Help Options">
      <div class='main-container'>

        <div style="
        line-height: 60px;    
        vertical-align: middle;
        padding: 0 10px 0 10px;
        margin-bottom: 10px;" *ngFor='let option of currentOptions' class="section-div" (click)='mailto(option)'>

          <ion-row>
            <ion-col size="10">
              <div style="text-align: left;">
                {{option.helpDescription}}
              </div>
            </ion-col>
            <ion-col size="2">
              <div style="text-align: right;">
                <i class="material-icons" style="font-size: 14px;">
                  arrow_forward_ios
                </i>
              </div>
            </ion-col>
          </ion-row>


        </div>
        <br>
      </div>
    </mat-tab>
  </mat-tab-group>




</ion-content>

<app-my-footer>

</app-my-footer>