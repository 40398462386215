<button  mat-button class="gameCard section-div-dark" style="padding: 0; margin: 0;">
  <mat-card class="gameCard section-div-dark">
    
    <ion-grid style="padding: 0; margin: 0;">

      <ion-row>
        <ion-col size="4" style="padding: 0; margin: 0;">
          <div class="img-wrapper">


            <img     
            [ngClass]="{'big-img' : this.prize.minValue >= 90, 'prize-img' : this.prize.minValue < 90}" 
           src="{{prize.imageUrl}}"/>

          </div>
        </ion-col>

        <ion-col size="8">
          <ion-row style="padding: 0; margin: 0;">
            <ion-col style="padding: 0; margin: 0;">
              <p style="padding: 0; margin: 0;  font-size: 20px; text-align: left;">{{prize.tierDescription | titlecase}} </p>
            </ion-col>
          </ion-row>
  
          <ion-row style="padding: 0; margin: 0;">
            <ion-col style="padding: 0; margin: 0;" size="12">
  
        
              <p  style="padding: 0; margin: 0;  font-size: 14px; text-align: left; width: 100%; white-space: normal; line-height: 20px; height: 65px; font-weight: 100;">{{prize.prizeDescription1}} </p>
  
            </ion-col>
          </ion-row>
  
         
          <ion-row>
            <span  style="font-weight: 400;"> {{getNumberOfInstallsLeft()}} </span>
          </ion-row>

          <ion-row style="padding: 0px; margin: 0;">
            <ion-col size="11" style="padding: 0; margin: 0;">
              <mat-progress-bar [ngClass]="{'completed': (progressBarValue >= 100), 'in-progress': (progressBarValue < 100) }" mode="determinate" value="{{progressBarValue}}"></mat-progress-bar>

            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>


   
      </ion-grid>

    </mat-card>
</button>