<!-- <shared-my-header>
</shared-my-header> -->

<link href="https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">



<ion-header class="ion-no-border">
  <div *ngIf="!searchOn">

    <ion-toolbar class="transparent-bg" mode="ios">
      <div slot="start">
        <button mat-button (click)="setSearchOn()">
          <span class="zeroLineHeight">
            <span class="material-icons iconAlign">
              search
            </span>

          </span>
        </button>
      </div>

      <ion-title>
        <h1 style="font-size: 24px;  margin: 0; line-height: 1;   font-family: 'Rubik', sans-serif; line-height: 30px;">
          <span>Library</span>
          <!-- <mat-icon>help_outline</mat-icon> -->
        </h1>

      </ion-title>



      <div slot="end">
        <button mat-button [matMenuTriggerFor]="menu">
          <span class="zeroLineHeight">{{selectedTab}}</span>
          <i class="material-icons">
            arrow_drop_down
          </i>
        </button>


        <mat-menu #menu="matMenu">
          <ng-template matMenuContent>

            <button mat-menu-item (click)="updatedSelectedTab('Podcasts')">
              <mat-icon>podcasts</mat-icon>
              <span>Podcasts</span>
            </button>


            <button mat-menu-item (click)="updatedSelectedTab('Videos')">
              <mat-icon>ondemand_video</mat-icon>
              <span>Videos</span>
            </button>


            <button mat-menu-item (click)="updatedSelectedTab('Documents')">
              <mat-icon>description</mat-icon>
              <span>Documents</span>
            </button>


          </ng-template>
        </mat-menu>
      </div>
    </ion-toolbar>
  </div>

  <div *ngIf="searchOn">
    <div class="safe-area-top"></div>
    <ion-toolbar style=" width: 100vw; height: 60px; position: sticky;top: 0;  padding: 3px; z-index: 999; ">
      <div style=" max-width: 1200px; display: block; margin: auto;">

        <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
          <div style="padding: 5px;"></div>

          <div style="width: 100%">
            <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
              <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
                search
              </i>

              <mat-form-field floatLabel='never' appearance="none"
                style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue" (keyup)="searchSend()">

              </mat-form-field>


            </button>

          </div>
          <div style="padding: 5px"></div>
          <div>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
              style="margin-left: -50px; z-index: 999;">
              <i class="material-icons" style="font-size: 14px;">
                clear
              </i>
            </button>
          </div>

          <div style="padding: 5px;"></div>


        </div>
      </div>
    </ion-toolbar>
  </div>


</ion-header>

<ion-content #content appParallaxHeader [scrollEvents]="true">

  <div [hidden]="searchOn" class="parallax-image" [style.background-image]="'url(' + this.getTabHeaderImage() + ')'"
    (click)="openFeaturedVideo()">
    <div style="position: absolute; bottom: 0;  width: 100%; height: 50px; line-height: 50px;">
      <span class="text-shadow"
        style="font-size: 21px; color: white; font-weight: 900; padding:0 10px 0 10px; text-shadow: 0px 2px 7px #000000">
        {{getTabHeaderTitle()}} </span>
    </div>
  </div>

  <div style="max-width: 1200px; display: block; margin: auto;">
    <div *ngIf="selectedTab === 'Podcasts' " style="padding: 10px;" [hidden]="this.searchOn">

      <button mat-raised-button (click)="editFeaturedSection('podcasts')" *ngIf="shouldShowAdminButtons"> Edit Featured Section </button>
      <button mat-raised-button (click)="openAdminLibrary()" *ngIf="shouldShowAdminButtons"> Admin </button>

      <div *ngFor='let section of this.podcastSections'>
        <div *ngIf='sectionHasMultipleSeries(this.podcastSections, section.sectionTitle)'>
          <app-genre-section [sectionInput]="section" [size]="'xl'" [seriesImages]="categoryImageUrls">
          </app-genre-section>
        </div>
        <div *ngIf='!sectionHasMultipleSeries(this.podcastSections, section.sectionTitle)'>


          <ion-toolbar class="transparent-bg">
            <div slot="start">
              <p>{{section.sectionTitle}}</p>
            </div>
            <div slot="start" *ngIf="shouldShowAdminButtons">
              <button mat-icon-button (click)="edit(section)" >
                <span class="material-icons">
                  edit
                </span>
              </button>

            </div>
          </ion-toolbar>

          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 160px; ">
            <span *ngFor='let video of listOfFilesForSection(this.podcastSections, section.sectionTitle)'
              style="margin-right: 15px;">
              <app-video-card [video]="video"></app-video-card>
              <div style="min-width: 20px"></div>
            </span>
          </div>
        </div>
      </div>

    </div>


    <div *ngIf="selectedTab === 'Videos' " style="padding: 10px;" [hidden]="this.searchOn">
      <button mat-raised-button (click)="editFeaturedSection('videos')" *ngIf="shouldShowAdminButtons"> Edit Featured Section </button>
      <button mat-raised-button (click)="openAdminLibrary()" *ngIf="shouldShowAdminButtons"> Admin </button>

      <div *ngFor='let section of this.videoSections'>
        <div *ngIf='sectionHasMultipleSeries(this.videoSections, section.sectionTitle)'>
          <app-genre-section [sectionInput]="section" [size]="'xl'" [seriesImages]="categoryImageUrls">
          </app-genre-section>
        </div>
        <div *ngIf='!sectionHasMultipleSeries(this.videoSections, section.sectionTitle)'>


          <ion-toolbar class="transparent-bg">
            <div slot="start">
              <p>{{section.sectionTitle}}</p>
            </div>
            <div slot="start" *ngIf="shouldShowAdminButtons">
              <button mat-icon-button (click)="edit(section)">
                <span class="material-icons">
                  edit
                </span>
              </button>

            </div>
          </ion-toolbar>

          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 160px; ">
            <span *ngFor='let video of listOfFilesForSection(this.videoSections, section.sectionTitle)'
              style="margin-right: 15px;">
              <app-video-card [video]="video"></app-video-card>
              <div style="min-width: 20px"></div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTab === 'Documents' " style="padding: 10px;" [hidden]="this.searchOn">
      <button mat-raised-button (click)="editFeaturedSection('documents')" *ngIf="shouldShowAdminButtons"> Edit Featured Section </button>
      <button mat-raised-button (click)="openAdminLibrary()" *ngIf="shouldShowAdminButtons"> Admin </button>


      <div *ngFor='let section of this.documentSections'>
        <div *ngIf='sectionHasMultipleSeries(this.documentSections, section.sectionTitle)'>
          <app-genre-section [sectionInput]="section" [size]="'xl'" [seriesImages]="categoryImageUrls">
          </app-genre-section>
        </div>
        <div *ngIf='!sectionHasMultipleSeries(this.documentSections, section.sectionTitle)'>


          <ion-toolbar class="transparent-bg">
            <div slot="start">
              <p>{{section.sectionTitle}}</p>
            </div>
            <div slot="start">
              <button mat-icon-button (click)="edit(section)" *ngIf="shouldShowAdminButtons">
                <span class="material-icons">
                  edit
                </span>
              </button>

            </div>
          </ion-toolbar>

          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 160px; ">
            <span *ngFor='let video of listOfFilesForSection(this.documentSections, section.sectionTitle)'
              style="margin-right: 15px;">
              <app-video-card [video]="video"></app-video-card>
              <div style="min-width: 20px"></div>
            </span>
          </div>
        </div>
      </div>

    </div>


    <div *ngIf="this.searchOn" style="padding: 0px; min-height: 90vh; max-width: 600px; display: block; margin: auto;">
      <div *ngFor="let video of this.currentOptions" style="display: block; margin: auto;  width: 100%;">
        <div style="min-width: 330px; width: 100%;  display: block; margin: auto;">
          <ion-toolbar (click)="openVideo(video.videoUrl)" matRipple class="example-ripple-container transparent-bg"
            [matRippleUnbounded]="false">

            <div slot="start">
              <div style="width: 15px;"></div>
            </div>
            <div slot="start">
              <img class="app-border" style="height:45px; border-radius: 5px; max-width: 70px; min-width: 70px;"
                [src]="this.getThumbnailUrl(video)" />
            </div>

            <div slot="start">
              <div style="width: 10px;"></div>
            </div>

            <div slot="start">
              {{video.title}} &nbsp; <br> <span style='font-weight: 100;'>{{video.category}}</span> &nbsp; <br> <span
                style="font-weight: 100;">{{video.trainerName}}</span>
            </div>




          </ion-toolbar>
        </div>
        <div style="height:20px"></div>

      </div>

    </div>



    <!-- <app-grid-carousel [trainingVideos]='this.getVideosForSection(section)' [title]='section'>

    </app-grid-carousel> -->
  </div>

  <!-- <app-grid-carousel *ngIf="this.trainingVidoes_recruitingTips" [trainingVideos]='this.trainingVidoes_recruitingTips' [title]="'Recruiting Tips'"></app-grid-carousel>

  <app-grid-carousel *ngIf="this.trainingVidoes_theClose" [trainingVideos]='this.trainingVidoes_theClose' [title]="'The Close'"></app-grid-carousel> -->

</ion-content>

<app-my-footer>
</app-my-footer>