import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatTableModule,
  MatStepperModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatIconModule,
  MatPaginatorModule,
  MatSortModule,
  MatBadgeModule,
  MatMenuModule,
  MatDividerModule,
  MatDatepickerModule,
  MatChipsModule, 
  MatCheckboxModule,
  MatCardModule,
  MatTabsModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatRadioModule

} from '@angular/material';

@NgModule({
  exports: [
    MatTableModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTabsModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSortModule,
    MatBadgeModule,
    MatMenuModule,
    MatDatepickerModule,
    MatChipsModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    MatRadioModule,

    
  ]
})
export class MaterialModule {}
