import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent, MatTabChangeEvent } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { adamLog, getBlobFromFile } from 'src/app/shared/generic-functions';
import { REST_BASE_URL } from 'src/app/shared/models/constants';
import { CampaignBackendModel, DEFAULT_MILESTONE_CAMPAIGN, DEFAULT_TOURNAMENT_ROUND, MilestoneBackend } from 'src/app/shared/models/incentives.model';
import { BusySpinnerService } from 'src/app/shared/services/busy-spinner.service';
import { DatesService } from 'src/app/shared/services/dates.service';
import { EmployeeStoreService } from 'src/app/shared/services/employee-store.service';
import { IncentivesService } from 'src/app/shared/services/incentives.service';
import { LeaderboardFilterOutput, LeadersService } from 'src/app/shared/services/leaders.service';
import { EditTournamentRoundsComponent } from '../edit-tournament/edit-tournament-rounds/edit-tournament-rounds.component';

@Component({
  selector: 'app-new-incentive',
  templateUrl: './new-incentive.component.html',
  styleUrls: ['./new-incentive.component.scss'],
})
export class NewIncentiveComponent implements OnInit {

  @ViewChild('roundsComponent') roundsComponent : EditTournamentRoundsComponent;

  selectedTabIndex: number = 0;
  // filters: LeaderboardFilterOutput;

  campaignDetails: CampaignBackendModel = {
    ...DEFAULT_MILESTONE_CAMPAIGN, 
    milestones: [{...DEFUALT_MILESTONE}], 
    rounds: [{...DEFAULT_TOURNAMENT_ROUND}]
  };

  constructor(
    public employeeStoreService: EmployeeStoreService,
    public leadersService: LeadersService,
    public datesService: DatesService,
    public incentivesService: IncentivesService,
    public sanitizer: DomSanitizer,
    public spinnerService: BusySpinnerService,
    ) { 
      this.initializeFilters();
    }

  ngOnInit() {
    this.selectedTabIndex = 1;     
    this.campaignDetails.groupType = "Individual";
    this.campaignDetails.campaignType = this.getIncentiveTypes()[this.selectedTabIndex].toLowerCase();

  }

  getIncentiveTypes(): string[] {
    return ["Competition", "Milestone"];
  }

  async saveIncentive(): Promise<void> {
    console.log("campaign details", this.campaignDetails)
    console.log("Milestone Details:", this.campaignDetails.milestones)

    if (!this.incentiveHasRequiredFields(this.campaignDetails) ) {
      alert("You must fill all required fields before submitting the campaign.");
    } else {
      adamLog("ALL FIELDS COMPLETE");
      this.roundsComponent.updateRoundMatchups();
      this.campaignDetails.milestones = this.campaignDetails.milestones;
      try{
        const res = await this.incentivesService.saveCampaign(this.campaignDetails);
        console.log("RES", res)

      } catch (e) {
        console.error(e);
      }
    }
  }

  incentiveHasRequiredFields(details: CampaignBackendModel): boolean {
    const requiredFields: string[] = [
      "campaignName",
      "campaignType",
      "startDate",
      "endDate",
      "groupType",
    ]
    const unfilledFields: string[] = requiredFields.filter(field => details[field]===null);
    console.log("incomplete fields", unfilledFields);

    const necessaryMilestonesAreComplete: boolean = details.campaignType !== "milestone" || this.allMilestonesAreComplete();

    return unfilledFields.length === 0 && necessaryMilestonesAreComplete;

  }

  allMilestonesAreComplete(): boolean {
    return this.campaignDetails.milestones.filter(m => !this.milestoneHasRequiredFields(m)).length === 0;
  }

  milestoneHasRequiredFields(milestone: MilestoneBackend): boolean {
    const requiredFields: string[] = [
      "minValue",
      "tierDescription",
      "prizeDescription1",
    ]
    const unfilledFields: string[] = requiredFields.filter(field => milestone[field]===null);
    console.log("incomplete milestone fields", unfilledFields);
    return unfilledFields.length === 0;
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    this.campaignDetails.campaignType = this.getIncentiveTypes()[this.selectedTabIndex].toLowerCase();
  }

  showCompetitionEditor(): boolean {
    return this.selectedTabIndex === 0;
  }

  showMilestoneEditor(): boolean {
    return this.selectedTabIndex === 1;
  }

  printEmployees(): void {
    console.log("reps", this.employeeStoreService.users)
  }


  addStartDate(event: MatDatepickerInputEvent<Date>) {
    const date = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.campaignDetails.startDate = date;
    console.log(date);

  }

  addEndDate(event: MatDatepickerInputEvent<Date>) {
    const date = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.campaignDetails.endDate = date;
    console.log(date);

  }

  // ************ FUNCTIONS FOR SAVING IMAGES ***************
  private setupLocalUrlsIfNeeded(selectedFile: File): void {

    // Store image loacally with a url that can be used pre-upload
    const Img = new Image();
    const URL = window.URL || window.webkitURL;
    Img.src = URL.createObjectURL(selectedFile);

    getBlobFromFile(selectedFile).then((res) => {
      let objectURL = URL.createObjectURL(res);
      const fileUrl: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });
  }

  async onThumbnailFileSelected(event: Event): Promise<void>  {
    this.saveFile(event, this.saveThumbnailFile);
  }

  async onBackgroundFileSelected(event: Event): Promise<void> {
    this.saveFile(event, this.saveBackgroundFile);
  }

  async saveBackgroundFile(selectedFile: File, incentivesService: IncentivesService, campaignDetails: CampaignBackendModel): Promise<void> {
    const newBackgroundUrl: string = await incentivesService.saveIncentiveBackgroundImage(selectedFile);
    campaignDetails.backgroundURL = newBackgroundUrl;
  }

  async saveThumbnailFile(selectedFile: File, incentivesService: IncentivesService, campaignDetails: CampaignBackendModel): Promise<void> {
    const newThumbnailUrl: string = await incentivesService.saveThumbnailImage(selectedFile);
    campaignDetails.thumbnailUrl = newThumbnailUrl;
  }

  async saveFile(event: Event, saveFileToServerCallback: (file:File, incentivesService: IncentivesService, campaignDetails: CampaignBackendModel)=>Promise<void>) {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const selectedFile: File = eventTarget.files[0];
    
    // this.setupLocalUrlsIfNeeded(selectedFile);

    this.spinnerService.start();

    //upload file
    try {
      await saveFileToServerCallback(selectedFile, this.incentivesService, this.campaignDetails);
    } catch(e) {
      console.error(e);
    }
    this.spinnerService.stop();
  }

  

  async initializeFilters(): Promise<void> {
    // const res: LeaderboardFilterOutput = await this.leadersService.getFilterOutput();
    // this.filters = res;
  }
  
}

export const DEFUALT_MILESTONE: MilestoneBackend = {
  minValue: null,
  tierDescription: "0 SUBMITTED", // TODO: this shouldn't be hard-coded because it will be different for other teams
  prizeDescription1: null,
  prizeDescription2: null,
  imageUrl: null,
  prizeUrl: null
}
