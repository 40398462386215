import { Component, OnInit } from '@angular/core';
import { PlayoffRound, Tournament } from 'src/app/shared/models/incentives.model';
import { IncentivesService } from 'src/app/shared/services/incentives.service';

@Component({
  selector: 'app-bracket',
  templateUrl: './bracket.component.html',
  styleUrls: ['./bracket.component.scss'],
})
export class BracketComponent implements OnInit {



widthOfGrid: string = "1500"; //TODO Calculate this
tournamentSize = 8;

selectedDivision: string = "Alpha";

tournament: Tournament;

  constructor(public incentivesService: IncentivesService) { 
    this.tournament = this.incentivesService.createTournament(this.tournamentSize, "test");
  }

  ngOnInit() {}


  updateTournamentSize(size: number) {
    this.tournamentSize = size;
    this.tournament = this.incentivesService.createTournament(this.tournamentSize, "test");
  }

  updateSelectedDivision(type: string){
    this.selectedDivision = type;
  }

}

