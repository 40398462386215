import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from "@ionic/storage";
import { LeadsStoreService } from 'src/app/leads/leads-store.service';
import { LeadsService } from 'src/app/leads/leads.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TicketsService } from 'src/app/tickets/tickets.service';

@Component({
  selector: 'enzy-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent implements OnInit {
  //TOOD: We'll want to think about the best way to limit which pages people see

  private allPages: Page[] = [

    {
      title: "Enzy.co",
      icon: "language",
      url: "https://enzy.co/"
    },
    {
      title: "User Management",
      icon: "recent_actors",
      url: "/admin-directory"
    },
    {
      title: "Tools",
      icon: "construction",
      url: "/admin-tools"
    },

    {
      title: "Tickets",
      icon: "bug_report",
      url: "/ticket-management"
    }
  ]
  private hasOnboardingRights: boolean;

  constructor(public router: Router,
    private localStorage: Storage,
    public loginService: LoginService,
    public userDetailsService: UserDetailsService,
    public leadsService: LeadsService,
    public ticketsService: TicketsService,
    public leadsStoreService: LeadsStoreService,
  ) {
    this.checkIfUserHasOnboardingRights();
  }

  ngOnInit() { }

  navigate(page: Page) {
    if (!page.url) { return }
    if (page.url === "https://enzy.co/") {
      window.open(page.url, "_blank");
      return;
    }
    this.router.navigate(["/" + page.url])
  }

  adminLinks () {
    return this.allPages.filter(page => {
      return this.pageShouldBeIncluded(page);
    });
  }


  async checkIfUserHasOnboardingRights() {
    let res = await this.localStorage.get("HAS_ONBOARDING_RIGHTS");
    if (res === null) {
      res = await this.loginService.checkOnboardingRights();
      
    }
    this.hasOnboardingRights = res;
  }

  pageShouldBeIncluded(page: Page): boolean {
    if(page.title === "Report Builder" || page.title === "Enzy.co")
      return true;
    else if(page.title === "Tickets")
      return this.ticketsService.ticketOptionsConfig.userHasTicketAdminRights;
    else if(page.title === "Lead Management")
      return false;
    else if(page.title === "Tools" || page.title === "User Management"){
      return this.hasOnboardingRights;
    }
  } 


  get leadBadge(): number {
    const val = this.leadsStoreService.getUnassignedLeads().length;
    if (val >= 99) {
      return 99
    }
    return val !== 0 ? val : null;
  }

  getChip(page: Page) {
    if (page.title === "Lead Management") {
      return this.leadBadge;
    }
  }
}

export class Page {
  title: string;
  icon: string;
  url: string;
}

