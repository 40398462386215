import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'enzy-available-time-row',
  templateUrl: './available-time-row.component.html',
  styleUrls: ['./available-time-row.component.scss'],
})
export class AvailableTimeRowComponent implements OnInit {

  @Input()
  time: string;

  constructor() { }

  ngOnInit() {}

}
