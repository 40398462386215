import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { ResourceFile } from '../models/help.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { UserDetailsService } from './user-details.service';


@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private http: HttpClient, public userDetailsService: UserDetailsService) { }  


  public async getTrainingVideos() : Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/trainingVideos/includeCategoryImages/' + sessionKey;

    return this.http.get<any>(url).toPromise();
  }

  public async getPodcasts() : Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/trainingVideos/includeCategoryImages/' + sessionKey;
    url += "?resourceType=podcast"
    return this.http.get<any>(url).toPromise();
  }


  public async getDocuments() : Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/trainingVideos/includeCategoryImages/' + sessionKey;
    url += "?resourceType=document"
    return this.http.get<any>(url).toPromise();
  }

  public async getTrainingVideoById(videoId: string) : Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/trainingVideos/videoById';
    let params: any = { videoId: videoId };
    return this.http.get<any>(url, { params }).toPromise();
  }

  public async getHelpContacts() : Promise<HelpRestResponse> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/contactList/' + sessionKey;

    return this.http.get<HelpRestResponse>(url).toPromise();
  }


  public async getCustomerSupportInfo() : Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/customerSupportInfo/' + sessionKey;

    return this.http.get<HelpRestResponse>(url).toPromise();
  }

}

export interface RestResponseWithObject {
  message: string;
  object: any;
}

export interface HelpRestResponse {
  message: string;
  object: HelpItem[];
}

export interface HelpItem {
  helpId: number;
  companyId: number;
  helpEmailAddress: string;
  helpAppAction: string;
  helpDescription: string;
}