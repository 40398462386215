import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-busy-spinner',
  templateUrl: './busy-spinner.component.html',
  styleUrls: ['./busy-spinner.component.scss'],
})
export class BusySpinnerComponent implements OnInit, OnDestroy {

  @Input() 
  diameter: number;

  gifSrc = "";

  constructor() { 
    this.gifSrc = "../../../../assets/enzy-spinner.gif"
  }

  ionViewWillEnter() {
    
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.gifSrc = ""
  }

  getSpinnerDiameter(): number {
    return this.diameter || 80;
  }

}
