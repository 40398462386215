
  <div style="max-width: 600px; display: block; margin: auto;">

    <div style="margin-right: 5px;">
      <button class="filter-button" mat-button (click)="pickerStart.open()">
        <span class="zeroLineHeight" style="margin-top: -10px;"> Start Date: &nbsp; </span>

        <span class="zeroLineHeight" style="font-weight: 100;"> {{formatDate(currentDate)}} &nbsp;</span>

        <i class="material-icons">
          event
        </i>
      </button>


      <mat-form-field style="padding: 0; width:1px;visibility:hidden;" appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="pickerStart" (dateChange)="updateDate($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerStart" [disableRipple]="true">
          <mat-icon matDatepickerToggleIcon>event</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngFor="let time of availableTimes">
      <enzy-available-time-row [time]="time">
  
      </enzy-available-time-row>
    </div>

  </div>

