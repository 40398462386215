import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BasicFilterComponent } from 'src/app/reports/filters/basic-filter/basic-filter.component';

@Component({
  selector: 'app-create-new-competition',
  templateUrl: './create-new-competition.component.html',
  styleUrls: ['./create-new-competition.component.scss'],
})
export class CreateNewCompetitionComponent implements OnInit {

  title: string = "";
  selectedTeams: string[] = [];

  postSeason: boolean = false;
  tournamentSize: number = 0;
  

  regularSeason:boolean = false; 
  regularSeasonLength: number = 0;

  constructor(public router: Router, public dialog: MatDialog,) { }

  ngOnInit() {}


  goBack(): void {
    this.router.navigate(['/incentives']);
  }


  openTeams() {
    let dialogRef = this.dialog.open(BasicFilterComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',

      data: {
        name: 'Teams',
        currentOption: this.selectedTeams,
        options: ["Team 1", "Team 2", "Team 3", "Team 4"],
        multipleOptions: true
      }
    });

    dialogRef.afterClosed().subscribe((data: string[]) => {
      if (data !== undefined) {
          this.selectedTeams = data;
      }
    });
  }

  updateTournamentSize(size: number) {
    this.tournamentSize = size;
  }

  create() {

  }
}
