import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENZY_BASE_URL, REST_BASE_URL } from '../models/constants';
import { FilterData } from '../models/dashboard.model';
import { Employee } from '../models/employees.model';
import { EmployeeService } from './employee.service';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

import { Storage } from '@ionic/storage';
import { UserDetailsService } from './user-details.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { UserPickerModalComponent } from '../components/user-picker-modal/user-picker-modal.component';
import { adamLog } from '../generic-functions';
import { PickerModalComponent } from '../components/picker-modal/picker-modal.component';

@Injectable({
    providedIn: 'root'
})
export class EmployeeStoreService {

    users: Employee[];
    usersMap: Map<number, Employee>;

    constructor(
        public userDetailsService: UserDetailsService,
        public localStorage: Storage,
        public dialog: MatDialog,
        public employeeService: EmployeeService,
    ) {
        this.initService();
    }

    async initService() {
        this.users = [];

        await this.localStorage.create();
        await this.localStorage.defineDriver(CordovaSQLiteDriver);

        try {
            await this.loadCachedUsers();
        } catch (e) {
            console.log(e)
        }
        this.loadAllUsers();
    }

    async loadAllUsers() {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        const res = await this.employeeService.getAllEmployees(sessionKey);
        if (!res || !res.list) return;
        this.users.splice(0, this.users.length);
        this.users.push(...res['list']);
        this.updateEmployeeMap();

        this.cacheUsers();
        // this.users = res['list'];

    }

    private async cacheUsers(): Promise<void> {
        setTimeout(() => {
            this.localStorage.set("USERS", this.users);
        }, 1000);
    }

    async loadCachedUsers() {
        if (this.users?.length > 0) return;
        let cachedUsers: Employee[];
        try {
            cachedUsers = await this.localStorage.get("USERS");

        } catch (e) {
            console.error(e)

            return;
        }

        // console.log("FINISHED LOADING USERS FROM LOCAL STORAGE");
        if (cachedUsers) {
            this.users = cachedUsers;
            this.updateEmployeeMap();
        }
    }

    updateEmployeeMap(): void {
        const tempMap: Map<number, Employee> = new Map<number, Employee>();
        for (let employee of this.users) {
            tempMap.set(+employee.userDto?.id, employee);
        }
        this.usersMap = tempMap;
    }

    getUser(id: number): Employee {
        return this.usersMap?.get(id);
    }

    getUserName(id: number): string {
        const output = this.getUser(id);
        if (!output) { return "" }
        let firstName: string = output.userDto.firstName ? output.userDto.firstName : ""
        let lastName: string = output.userDto.lastName ? output.userDto.lastName : ""
        return firstName + " " + lastName;
    }

    async selectSingleUserModal(): Promise<number> {
        let dialogRef: MatDialogRef<UserPickerModalComponent> = this.dialog.open(UserPickerModalComponent, {
            data: { selectSingleUser: true },
            minWidth: "300px",
            height: "400px"

        })

        const userList: number[] = (await dialogRef.afterClosed().toPromise())?.userIds;
        return userList?.length > 0 ? userList[0] : null;
    }


    async selectPicker(options?: string[], singleSelect: boolean = true): Promise<string[]> {
        let dialogRef: MatDialogRef<PickerModalComponent> = this.dialog.open(PickerModalComponent, {
            data: { 
                singleSelect: singleSelect, 
                type: "other",
                options: options
            },
            minWidth: "300px",
            height: "400px"

        })
        const selectedOption: string[] = (await dialogRef.afterClosed().toPromise())?.selectedOptions;

        return selectedOption ? selectedOption : null;
    }



    async selectUsersModal(currentUsers: number[] = []): Promise<number[]> {
        let dialogRef: MatDialogRef<UserPickerModalComponent> = this.dialog.open(UserPickerModalComponent, {
            data: {
                selectSingleUser: false,
                selectedUserIds: currentUsers,
            },
            minWidth: "300px",
            height: "400px"
        })
        return (await dialogRef.afterClosed().toPromise())?.userIds;
    }
}
