import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatchParticipant } from 'src/app/incentives/edit-tournament/edit-tournament.component';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { TwoTeamMatchup, Incentive, PlayoffRound, Team, Tournament, RegularSeasonRound, CampaignBackendModel } from '../models/incentives.model';
import { ChartGenerationService } from './chart-generation.service';
import { RestResponseWithObject } from './help.service';
import { ReportsService } from './reports.service';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class IncentivesService {


  createTournament(numberOfTeamsAtOutset: number, title: string): Tournament {
    let tournament = new Tournament();

    tournament.title = title;
    tournament.numberOfTeamsAtOutset = numberOfTeamsAtOutset;
    tournament.rounds = [];

    let numberOfRounds = Math.ceil(Math.sqrt(tournament.numberOfTeamsAtOutset)) + 1


    let reverseIndex = numberOfRounds - 1;
    let totalHeight = 0;

    if (numberOfTeamsAtOutset === 4) {
      totalHeight = 210
    } else if (numberOfTeamsAtOutset === 8) {
      totalHeight = 430
    }
    else if (numberOfTeamsAtOutset === 16) {
      totalHeight = 870
    }
    else if (numberOfTeamsAtOutset === 32) {
      totalHeight = 1750
    }
    else if (numberOfTeamsAtOutset === 64) {
      totalHeight = 3510
    }


    let valueOfEdgeSpacers: number[] = [0, 25, 80, 190, 410, 850, 1730]
    let numberOfTeamsInGivenRound: number = 0;
    let numberOfMiddleSpacers: number = 0;
    let valueOfMiddleSpacers: number = 0;


    for (let i: number = 1; i <= numberOfRounds; i++) {

      if (i === 1) {
        if (tournament.numberOfTeamsAtOutset === 32) {
          reverseIndex -= 1;
        } else if (tournament.numberOfTeamsAtOutset === 64) {
          reverseIndex -= 2;
        }

        let startingTeams: string[] = [];
        for (let team = 0; team < numberOfTeamsAtOutset; team++) {
          startingTeams.push("team " + team);
        }

        let roundTmp: PlayoffRound = {
          roundNumber: i,
          teams: startingTeams,
          numberOfMiddleSpacers: -1,
          valueOfMiddleSpacers: 10,
          valueOfEdgeSpacers: 0
        }
        tournament.rounds.push(roundTmp)

      }
      else {
        reverseIndex -= 1;

        numberOfMiddleSpacers = (Math.pow(2, reverseIndex) - 1);

        numberOfTeamsInGivenRound = numberOfMiddleSpacers + 1; 
        valueOfMiddleSpacers = (((totalHeight - (numberOfTeamsInGivenRound * 50)) - ((valueOfEdgeSpacers[i - 1]) * 2)) / numberOfMiddleSpacers); 


        let currentTeams: string[] = [];
        for (let team = 0; team < numberOfTeamsInGivenRound; team++) {
          currentTeams.push("team " + team);
        }


        let roundTmp: PlayoffRound = {
          roundNumber: i,
          teams: currentTeams,
          numberOfMiddleSpacers: numberOfMiddleSpacers,
          valueOfMiddleSpacers: valueOfMiddleSpacers,
          valueOfEdgeSpacers: valueOfEdgeSpacers[i - 1]
        }
        tournament.rounds.push(roundTmp)

        if (i === 2) {
          tournament.rounds[0].numberOfMiddleSpacers = numberOfMiddleSpacers
        }

      }

    }


    return tournament
  }


  findGamesForSpecificTeam(team: string): TwoTeamMatchup[] {
    let foundGames: TwoTeamMatchup[] = [];
    // TODO: re-implement this (maybe with a backend call)

    return foundGames;

  }





  constructor(
    public userDetailsService: UserDetailsService, 
    private http: HttpClient) { }

  public async getIncentives(): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/campaigns/' + sessionKey;

    return this.http.get<Incentive[]>(url).toPromise();
  }

  public getIncentiveMilestoneEmployee(sessionKey: string, id: string, minValue: string) {
    let url = REST_BASE_URL + '/milestoneEmployees/' + sessionKey + "?campaignId=" + id + "&minValue=" + minValue;

    this.http.get<DataReportMenu>(url);
  }

  public getIncentiveMilestoneStatusForUser(sessionKey: string, campaignId: string, userId: string): Promise<any> {
    let url = REST_BASE_URL + '/milestoneCampaign/' + sessionKey + "?campaignId=" + campaignId + "&userId=" + userId;

    return this.http.get<any>(url).toPromise();
  }


  public getIncentiveMilestoneDataForUser(sessionKey: string, campaignId: string, userId: string): Observable<IncentiveDataPoint[]> {
    let url = REST_BASE_URL + '/employeeMilestoneData/' + sessionKey + "?campaignId=" + campaignId + "&userId=" + userId;

    return this.http.get<any>(url);
  }

  public downloadIncentives(sessionKey: string, campaignId: number): Observable<any> {
    let url = REST_BASE_URL + '/milestoneEmployeesCsv/' + sessionKey + "?campaignId=" + campaignId + "&minValue=" + "0";

    return this.http.get(url, { responseType: "arraybuffer" });
  }

  public async getTournamentDetails(campaignId: number): Promise<any> {
    const sessionKey = await this.userDetailsService.getSessionKey();
    let url = REST_BASE_URL + '/campaigns/tournament/' + sessionKey + "?campaignId=" + campaignId;

    return this.http.get(url).toPromise();
  }

  public async saveTournamentDetails(tournament: TournamentModel) {
    const sessionKey = await this.userDetailsService.getSessionKey();
    const isNewTournament: boolean = tournament.id === null;
    let url = REST_BASE_URL + '/tournaments/tournament/' + sessionKey + "?isNewTournament=" + isNewTournament;
    let body = JSON.stringify(tournament);
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    return this.http.post(url, body, {headers: headers}).toPromise();
  }

  public async saveCampaign(campaign: CampaignBackendModel) {
    const sessionKey = await this.userDetailsService.getSessionKey();
    const isNewTournament: boolean = campaign.campaignId === null;
    let url = REST_BASE_URL + '/campaigns/' + sessionKey + "?isNewCampaign=" + isNewTournament;
    let body = JSON.stringify(campaign);
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});

    return this.http.post(url, body, {headers: headers}).toPromise();
  }

  public async saveIncentiveBackgroundImage(imageFile: File): Promise<string> {
    return this.saveImage(imageFile, "BACKGROUND+IMAGE");
  }

  public async saveThumbnailImage(imageFile: File): Promise<string> {
    return this.saveImage(imageFile, "THUMBNAIL+IMAGE");
  }

  public async saveRoundIncentiveImage(imageFile: File): Promise<string> {
    const roundIdentifier: string = "todo";
    return this.saveImage(imageFile, "ROUND+INCENTIVE+IMAGE+" + roundIdentifier);
  }  
  

  public async saveImage(image: File, identifierString: string = "TEMP+IMAGE"): Promise<string> {
    const sessionKey = await this.userDetailsService.getSessionKey();

    let url = REST_BASE_URL + '/uploadImage/' + sessionKey;

    const body = new FormData();
    body.append('imageFile', image, image.name);
    body.append('imagePurpose', identifierString);
    

    const res: RestResponseWithObject = (await this.http.post<RestResponseWithObject>(url, body).toPromise())
    return res.object;
  }
}


export function incentivesSortComparator(a: Incentive, b: Incentive): number {
  if (a.startDate === b.startDate) return 0;
  return a.startDate > b.startDate ? -1 : 1;
}


export interface IncentiveDataPoint {
  'Customer Address': string,
  'Customer City': string,
  'Customer Name': string,
  'Customer State': string,
  'Installation Date': string,
  'Kilowatts': string,
  'Sale Date': string,
  'Stage': string
}

export interface TournamentModel {
  rounds: TournamentRound[];
  companyId: number;
  id: number;
  campaignName: string;
  campaignType: string;
  startDate: string;
  endDate: string;
  backgroundUrl: string;
  thumbnailUrl: string;
  groupType: string;
  rulesDescription: string;
  rulesUrl: string;
  currentRound: string;
  championshipRound: HeatRound
  isLocked?: string;


}

export interface HeatRound {
  heatParticipants: MatchParticipant;
}
export interface TournamentRound {
  isActive: boolean;
  roundId: number;
  roundOrder: number;
  startDate: string,
  endDate: string,
  roundName: string;
  roundType: string;
  matchups: TournamentMatchup[];
  roundPrizeImage: string;

  // TODO: this needs to be abstracted into an advanced tournament calculation
  reportRequestId: string;
  selfGenReportRequestId: string;
  stagePerTeamReportId: string;

  participants: MatchupParticipant[];
  matchupToTeamIdMap: {[key: number]: number[]},
  teamsToUserMap: {[key: number]: MatchupParticipant[]},
  teamScoresMap: {[key: number]: number},
  teamNamesMap: {[key: number]: string},
  teamImagesMap: {[key: number]: string},
}

export interface MatchupParticipant {
  roundId: number;
  matchNumber: number; 
  teamId: number;

  teamName: string;
  teamImageUrl: string;

  userId: number;

  overrideScore: number;
  score: number;
  calculatedScore: number;
}

export function getMatchupIds(round: TournamentRound): number[] {
  return Object.keys(round?.matchupToTeamIdMap).map(n => +n);

}

export function getTeamsByMatchup(round: TournamentRound, matchId: number): number[] {
  return round?.matchupToTeamIdMap[matchId];

}

export function getUsersForTeam(round: TournamentRound, teamId: number): MatchupParticipant[] {
  return round?.teamsToUserMap[teamId];

}


export interface TournamentMatchup {
  team1Name: string;
  team2Name: string;
  team1Score: number;
  team2Score: number;

  
  // team1Deals: number;
  // team2Deals: number;
}