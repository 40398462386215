<div style="padding: 10px;" class="app-background-color">
	<div>
		<p style="text-align: center;">Edit Message</p>
		<mat-form-field style="width: 100%">
			<input #messageInput matInput [(ngModel)]="messageContents">
		</mat-form-field>

	</div>


	<ion-grid> 
		<ion-row>
			<ion-col size="6">
				<button style="width: 100%" mat-button (click)="cancel()">Cancel</button>
			</ion-col>

			<ion-col size="6">
				<button style="width: 100%" mat-button (click)="sendMessage()">Ok</button>
			</ion-col>
		</ion-row>
	</ion-grid>


</div>