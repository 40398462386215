import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TableFilterButton } from '../components/table-filter/table-filter.component';
import { DateFormatService } from './date-format.service';

@Injectable({
    providedIn: 'root'
})
export class TableFilterService {
    constructor(public dateFormatService: DateFormatService, public snackbar: MatSnackBar) { }

    private noFiltersApplied(filtersObjects: TableFilterButton[]): boolean {
        let returnVal = true;
        filtersObjects.forEach((filter: TableFilterButton) => {
            if (filter.selectedValue) {
                returnVal = false;
            }
        })
        return returnVal
    }

    //Object is type any because we are using this across multiple tables and data types
    public shouldDisplay(object: any, filtersObjects: TableFilterButton[], date?: string): boolean {
        if (this.noFiltersApplied(filtersObjects)) return true;

        let shouldDisplayObject = true;
        filtersObjects.forEach((filter: TableFilterButton) => {
            if (shouldDisplayObject) {

                if (filter.type === 'date' && date) {
                    if (filter.selectedValue === 'Today') {
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isToday(date))
                    } else if (filter.selectedValue?.includes('Tomorrow')) {
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isTomorrow(date))
                    }
                    else if (filter.selectedValue?.includes('This Week')) {
                        console.log(filter, date)
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isThisWeek(date))
                    }
                    else if (filter.selectedValue?.includes('This Month')) {
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isThisMonth(date))
                    }
                    else if (filter.selectedValue?.includes('This Year')) {
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isThisYear(date))
                    }
                    else if (filter.selectedValue?.includes('Past')) {
                        shouldDisplayObject = (!filter.selectedValue || this.dateFormatService.isPast(date))
                    }
                }
                else if (filter.type === 'boolean') {
                    if(filter.objectKey === "excludeClosedTickets") {
                        if(filter.selectedValue === "true") {
                            shouldDisplayObject = (object.ticketStatus !== "Closed")
                        }
                    } 

                    //the filter.subObjectKey is here so we can go one more layer deep into an object (i.e. report.freshReport.isFavorite)
                    else if (filter.subObjectKey) {
                        shouldDisplayObject = (!filter.selectedValue || filter.selectedValue.toString() === object[filter.objectKey][filter.subObjectKey].toString())
                    }
                    else {
                        shouldDisplayObject = (!filter.selectedValue || filter.selectedValue.toString() === object[filter.objectKey].toString())
                    }
                }
                else if (filter.type === 'emptyObjectKey') { //TODO
                    shouldDisplayObject = ( !filter.selectedValue || !object[filter.objectKey])
                }
                else {
                    if (filter.subObjectKey) {
                        shouldDisplayObject = (!filter.selectedValue || filter.selectedValue?.includes(object[filter.objectKey][filter.subObjectKey]));
                    }
                    else {
                        shouldDisplayObject = (!filter.selectedValue || filter.selectedValue?.includes(object[filter.objectKey]));
                    }

                }

            }
        })
        return shouldDisplayObject
    }


    getFilterCounter(filtersObjects: TableFilterButton[]) {
        let count = 0;
        filtersObjects.forEach(filter => {
            if (filter.selectedValue) { count += 1; }
        })
        return count;
    }


}
