import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, MatSnackBar } from "@angular/material";
import { Storage } from '@ionic/storage';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { adamAlert, adamLog } from "../shared/generic-functions";
import { REST_BASE_URL } from "../shared/models/constants";
import { LeadChanges } from "../shared/services/messaging.service";
import { SnackbarService } from "../shared/services/snackbar.service";
import { UserDetailsService } from "../shared/services/user-details.service";
import { AdminAssigment } from "./lead-manager-admin/lead-manager-admin.component";
import { LeadModalComponent } from "./lead-modal/lead-modal.component";
import { Appointment, DEFUALT_LEAD_OPTIONS_CONFIG, LeadModel, LeadOptionsConfigInterface, Note, Task } from "./leads.model";

@Injectable({
    providedIn: "root",
})
export class LeadsService {

    public leadOptionsConfig: LeadOptionsConfigInterface = DEFUALT_LEAD_OPTIONS_CONFIG;

    constructor(
        private http: HttpClient,
        private localStorage: Storage,
        public userDetailsService: UserDetailsService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
        public snackbarService: SnackbarService,
    ) {
        this.initLeads();
    }

    private async initLeads() {
        // await this.localStorage.create();
        // await this.localStorage.defineDriver(CordovaSQLiteDriver);
        this.loadLeadConfigFromCache();
        try {
            this.leadOptionsConfig = await this.getLeadConfiguration();
        } catch (e) {

        }

        this.saveLeadConfigToCache();
        
    }

    public async createNewLead(lead: LeadModel): Promise<any> {
        adamLog("Create Lead");
        let sessionKey: string = await this.userDetailsService.getSessionKey();
        if (!sessionKey) {
            sessionKey = "TESTKEY";
        }
        let url: string = REST_BASE_URL + '/leads/' + sessionKey;
        this.successSnackbar("This endpoint was removed from the backend because it's not being used.");

        // let body = JSON.stringify(lead);
        // const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

        // const res = await this.http.post<any>(url, body, { headers: headers }).toPromise();
        // if (res.success) {
        //     this.successSnackbar("Lead successfully created");
        // } 
        // return res;
    }


     userCanChangeAssignedRep(lead: LeadModel): boolean {
        const canAssignForThisLead: boolean = this.leadOptionsConfig?.userCanAssignRepsForTheirSets && lead?.setterId === +this.userDetailsService.getUserIdNumber();
        return canAssignForThisLead || this.leadOptionsConfig?.userIsTeamLeadManager || this.leadOptionsConfig?.userHasLeadAdminRights;
    }

    public async saveNewLead(lead: LeadModel): Promise<{message: string, success: boolean, lead: LeadModel}> {
        adamLog("Save Lead");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/updateLead/' + sessionKey;

        let body = JSON.stringify(lead);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

        const res = await this.http.post<{message?: string, success?: boolean, output: LeadModel}>(url, body, { headers: headers }).toPromise();
        if (res.success) {
            this.successSnackbar("Lead saved");
        }
        else {
            this.successSnackbar(res.message? res.message : "Error")
        }
        
        return {
            message: res.message, 
            success: res.success, 
            lead: res.output,
        };
    }



    private async updateLeadList(lead: LeadModel, whichList: LeadListOption): Promise<any> {
        adamLog("Save Lead");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/updateLeadList/' + sessionKey;
        url += "?listOption=" + LeadListOption[whichList];
        let body = JSON.stringify(lead);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

        const res = await this.http.post<any>(url, body, { headers: headers }).toPromise();
        if (res.success) {
            this.successSnackbar("Lead saved");
        }
        else {
            this.successSnackbar(res.message? res.message : "Error")
        }
        return res;
    }

    public async safeUpdateLeadField(oldLead: LeadModel, field: string, value: any): Promise<any> {
        const newLead: LeadModel = {...oldLead };
        newLead[field] = value;
        return this.safeUpdateLead(oldLead, newLead);
    }

    public async deleteLead(leadId: number): Promise<boolean> {
        const deleteChange: FieldUpdateModel[] = [{
            field: "isDeleted",
            newValue: "true",
        }]
        try {
            const res = await this.safeUpdateLeadWorker(leadId, deleteChange);
            if (!res?.success) {
                this.successSnackbar(res?.message ? res.message : "Error")
            }
            return res.success;
        } catch(e) {
            this.successSnackbar("Failed to delete lead.")
            return false;
        }
    }

    public async safeUpdateLead(oldLead: LeadModel, newLead: LeadModel): Promise<boolean> {
        if (oldLead?.leadId !== newLead?.leadId) {
            adamAlert("Error: l")
            return;
        }
        const changes: FieldUpdateModel[] = leadsDiff(oldLead, newLead);

        const res = await this.safeUpdateLeadWorker(oldLead.leadId, changes);
        return res.success

    }

    public async safeUpdateSat(leadId: number, sat: boolean): Promise<boolean> {
        const change: FieldUpdateModel = {
            field: "isCompleted",
            newValue: sat
        }
        const res = await this.safeUpdateLeadWorker(leadId, [change]);
        return res.success;

    }
    
    private async safeUpdateLeadWorker(leadId: number, updates: FieldUpdateModel[]): Promise<any> {
        adamLog("Save Lead");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/safeUpdateLead/' + sessionKey;

        url += "?leadId=" + leadId;
        let body = JSON.stringify(updates);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });


        const res = await this.http.post<any>(url, body, {headers}).toPromise();
        if (res.success) {
            this.successSnackbar("Lead saved");
        }
        else {
            console.log(res)
            this.successSnackbar(res.message ? res.message : "Error")
        }
        return res;
    }

    public async saveNewNote(lead: LeadModel, newNote: Note) {
        lead.notes.push(newNote);
        this.updateLeadList(lead, LeadListOption.NOTES);
    }

    public async saveNewTask(lead: LeadModel, newTask: Task) {
        lead.tasks.push(newTask);
        this.updateLeadList(lead, LeadListOption.TASKS);
    }
    
    public async saveLeadTasks(lead: LeadModel) {
        this.updateLeadList(lead, LeadListOption.TASKS);
    }

    public async saveLeadAppointments(lead: LeadModel) {
        this.updateLeadList(lead, LeadListOption.APPOINTMENTS);
    }

    public async getLeads(): Promise<LeadModel[]> {
        adamLog("Get Leads");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/' + sessionKey;

        const res: { output: LeadModel[] } = await this.http.get<any>(url).toPromise();
        return res.output;
    }

    public async getLeadChanges(leadId: number): Promise<LeadChanges[]> {
        adamLog("Get Lead Changes");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/updates/' + sessionKey;
        url += "?leadId=" + leadId;

        const res: { output: LeadChanges[] } = await this.http.get<any>(url).toPromise();
        return res.output;
    }

    public async getLeadConfiguration(): Promise<LeadOptionsConfigInterface> {
        adamLog("Get Lead Config");
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/leadConfig/' + sessionKey;

        const res: { output: LeadOptionsConfigInterface } = await this.http.get<any>(url).toPromise();

        return res.output;
    }
    // teamLeadAdministrator
    // teamManager

    public async getTeamLeadAdmins(): Promise<AdminAssigment[]> {
        adamLog("Get Team Lead Administrators");
        return this.getTeamAdmins("teamLeadAdministrator");
    }

    public async getTeamManagers(): Promise<AdminAssigment[]> {
        adamLog("Get Team Lead Administrators");
        return this.getTeamAdmins("teamManager");
    }

    private async getTeamAdmins(adminType: string): Promise<AdminAssigment[]> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/adminAssignment/' + sessionKey;
        url += "?adminType=" + adminType;

        const res: { output: { [team: string]: number[] } } = await this.http.get<any>(url).toPromise();

        const output: AdminAssigment[] = [];
        for (let key of Object.keys(res.output)) {
            output.push({
                team: key,
                type: "leadAdministrator",
                userIds: res.output[key],
            })
        }
        return output;;
    }



    public async changeMultipleFields(leadIds: number[], newValue: any, field: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();

        let url: string = REST_BASE_URL + '/leads/bulkUpdateField/' + sessionKey;


        let body: any = new FormData();
        body.append('leadIds', leadIds);
        body.append('newValue', '' + newValue);
        body.append('field', '' + field);

        const res = await this.http.post<any>(url, body).toPromise();
        if (res.success) {
            this.successSnackbar("Changes successful");
        }
        return res;
    }


    public async updateLeadAdmin(userIds: number[], team: string): Promise<any> {
        return this.updateAdmins(userIds, team, "teamLeadAdministrator")
    }

    public async updateTeamManagers(userIds: number[], team: string): Promise<any> {
        return this.updateAdmins(userIds, team, "teamManager")
    }

    private async updateAdmins(userIds: number[], team: string, adminType: string): Promise<any> {
        const sessionKey: string = await this.userDetailsService.getSessionKey();
        let url: string = REST_BASE_URL + '/adminAssignment/' + sessionKey;

        let body: any = new FormData();
        body.append('userIds', userIds);
        body.append('team', team);
        body.append('adminType', adminType);

        const res = await this.http.post<any>(url, body).toPromise();
        if (res.success) {
            this.successSnackbar("Changes successful");
        }
        return res;
    }


    private successSnackbar(message: string) {
        this.snackbarService.displaySnackBarMessage(message)
    }

    public getLeadSummaryFields(): string[] {
        return [
            "setterEmail",
            "sourceMarket",
            "appointmentDate",
            "leadStatus",
            "projectStatus",
            "assignedRep",
        ]
    }

    public async openLeadUsingModal(lead: LeadModel, mode: string = "view"): Promise<LeadModel> {
        let dialogRef: MatDialogRef<LeadModalComponent> = this.dialog.open(LeadModalComponent, {
            data: {
              lead: {...lead},
              mode: mode
            },
            minWidth: "100vw",
            maxHeight: "100vh"
      
        })

        const result: {lead: LeadModel} = await dialogRef.afterClosed().toPromise();

        return result?.lead;
    }

    public async createNewLeadUsingModal(): Promise<{message: string, success: boolean, lead: LeadModel}> {
        let dialogRef: MatDialogRef<LeadModalComponent> = this.dialog.open(LeadModalComponent, {
            data: {
              mode: "new"
            },
            minWidth: "100vw",
            maxHeight: "100vh"
        })

        const res = await dialogRef.afterClosed().toPromise();
        if (!res) return null;
        const output: {message: string, success: boolean, lead: LeadModel} = await this.saveNewLead(res?.lead);
        // output.lead = res?.lead;
        return output;
    }


    // ************** CACHING CONFIG *****************
    async loadLeadConfigFromCache() {
        // this should not overwrite it if it came from the server

        const res: LeadOptionsConfigInterface = await this.localStorage.get("LEAD_CONFIG");
        // it should overwrite it if it's just the defaults
        if (
            this.leadOptionsConfig?.leadStatus?.length === 0
            && this.leadOptionsConfig?.sourceMarket?.length === 0
            && res?.leadStatus?.length > 0
            && res?.sourceMarket?.length > 0

        ) {
            this.leadOptionsConfig = res;
        }
    }

    saveLeadConfigToCache() {
        setTimeout(() => {
            this.localStorage.set("LEAD_CONFIG", this.leadOptionsConfig);
        }, 1000);
    }


    //****************HELPER METHODS */

    getCustomerName(lead: LeadModel) {
        if (!lead) {return ""}
        let firstName = lead.customerFirstName ?  lead.customerFirstName : ""
        let lastName = lead.customerLastName ?  lead.customerLastName : ""
    
        return firstName + " " + lastName
      }


}

export function leadsDiff(a: LeadModel, b: LeadModel): FieldUpdateModel[] {
    const output: FieldUpdateModel[] = [];
    for (let key of Object.keys(a)) {
        if (a[key] !== b[key] 
            && key != 'assignments'
            && key != 'tasks'
            && key != 'notes'
            ) {
            output.push({
                field: key,
                newValue: b[key],
            })
        }
    }
    return output;
}


export function appiontmentSortComparator(a: Appointment, b: Appointment): number {
    if (a.startDateTime === b.startDateTime) return 0;
    return a.startDateTime < b.startDateTime ? -1 : 1;
}


export function tasksSortComparator(a: Task, b: Task): number {
    if (a.dueDate === b.dueDate) return 0;
    return a.dueDate < b.dueDate ? -1 : 1;
}

export function leadsSortComparator(a: LeadModel, b: LeadModel): number {
    if (a.appointments[a.appointments.length - 1]?.startDateTime === b.appointments[b.appointments.length - 1]?.startDateTime) return 0;
    return a.appointments[a.appointments.length - 1]?.startDateTime > b.appointments[b.appointments.length - 1]?.startDateTime ? -1 : 1;
}


export function appointmentsIn(leads: LeadModel[]): Appointment[] {
    const output: Appointment[] = [];
    for (let lead of leads) {
        output.push(...lead.appointments)
    }
    output.sort((a, b) => appiontmentSortComparator(a, b));
    return output;
}


export function tasksIn(leads: LeadModel[]): Task[] {
    const output: Task[] = [];
    for (let lead of leads) {
        output.push(...lead.tasks)
    }
    output.sort((a, b) => tasksSortComparator(a, b));
    return output;
}



export interface FieldUpdateModel {
    field: string;
    newValue: any;
}

export enum LeadListOption {
    NOTES, APPOINTMENTS, TASKS,
}
