import { ApplicantData } from "src/app/onboarding/onboarding-wizard/onboarding-store.service";

export class Employee {
  userDto: BackendUser;
  attributeMap: any;
  accessLevel: number;
  annualSalary: string;
  commuteMiles: string;
  commuteMilesScore: string;
  department: string;
  employeeId: string;
  employeeMigration: string;
  employmentStatus: string;
  engagement: string;
  engagementScore: string;
  flightRisk: string;
  flightRiskScore: string;
  jobTitle: string;
  level: string;
  managerEmployeeId: string;
  managerUserId: string;
  startDate: string;
  startDateString: string;
  terminationDate: string;
  fullName: string;
  userId: number;
  applicant?: ApplicantData;
  applicantStatus?: string;
  imageUrl?: string;
}

export class BackendUser {
  birthDate: string;
  companyId: string;
  companyMap: string;
  emailAddress: string;
  firstName?: string;
  preferredFirstName: string;
  gender: string;
  id: string;
  imageUrl: string;
  imageThumbnailUrl: string;
  imageUrlOrDefault: string;
  isActive: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  username: string;
  fullName: string;
}



export interface Template {
  title: string;
  description: string;
}


export class CampaignOverview {
  campaignTitle: string;
  eligibilityDescription: string;
  budget: number;
  cost: number;
  // costToDate: number;
  // projectedCost: number;
  // effectiveness: number;
  // dueDate: Date;
  type: string; // As in money, general,
  typeIcon: string;
  startDate: string;
  endDate: string;
  reward: string;
  condition: string;
  numberPendingTasks: number;
  employees: string[];
  groupIdentifiers: string[];
}
