import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { throws } from 'assert';
import { ReportDataPoint } from 'src/app/shared/services/reports.service';
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component';

import { OnboardingRestService } from '../onboarding-rest.service';
import { Invitation } from '../onboarding-wizard/onboarding-store.service';

@Component({
  selector: 'app-invitation-table',
  templateUrl: './invitation-table.component.html',
  styleUrls: ['./invitation-table.component.scss'],
})
export class InvitationTableComponent implements OnInit {
  @Output() onReinvite = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();


  data: any[];
  @Input() set dataInput(newData: any[]) {
    this.data = newData;    
    console.log(this.data)
  }

  constructor(public onboardingRestService: OnboardingRestService, public dialog: MatDialog) { }

  ngOnInit() {
  }



  getXHeader(): string {
    return "Name"
  }

  getYHeader(): string {
    return "Status"
  }

  getXFromCell(row): string {
    console.log("test")
    return row.x?row.x: "hi";
  }

  getYFromCell(row): string {
    return row.y?row.y: "we";
  }

  select(row): void {
    // console.log("ROW CLICKED")
  }

  getSelectedHeaders(): string[] {
    return ["Name", "Status"];
  }

  getDisplayColumns(): string[] {
    return ["Name", "Status"];
  }

  getValueForRowCol(row, val: number): string {
    if (val === 1) return row.x;
    if (val === 2) return row.y;
    return "SHDJK";
  }

  openDetails(invite: Invitation) {
    let ref= this.dialog.open(InvitationModalComponent, {
      data: {
        invite : invite
      }
    })

    ref.afterClosed().subscribe(res => {
      if (res === "resend") {
        this.reinvite(invite);
      }
      if (res === "edit") {
        this.onEdit.emit(invite);
      }
    })
  }

  async reinvite(invite): Promise<void> {
    this.onReinvite.emit(invite);
  }
}