<div class="app-background-color">
  <ion-row >

    <ion-col size='12'>
      <mat-form-field appearance="fill" style="width: 100%; height: 100px;">
        <mat-label>New Note</mat-label>
        <textarea matInput style="min-height: 150px; width: 100%;" type="text" [(ngModel)]="newNoteContents">
          </textarea>
      </mat-form-field>
    </ion-col>
  
    <ion-col size='12'>
      <button mat-button class="primary-color"  style="width: 100%;" (click)='submit()'>
        Submit Note
      </button>
    </ion-col>
  
  
  </ion-row>
</div>