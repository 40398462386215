import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { IonRouterOutlet } from '@ionic/angular';
import { AbstractLoadsReportDataComponent } from '../abstract-classes/abstract-saves-report';
import { AbstractStoresReportInfo } from '../abstract-classes/abstract-stores-report-info';
import { BusySpinnerComponent } from '../components/busy-spinner/busy-spinner.component';
import { HeaderService } from '../components/my-header/header.service';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { DashboardData, DateReportData, TrendData } from '../models/dashboard.model';
import { FreshReport } from '../models/fresh-report.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { ChartGenerationService } from './chart-generation.service';
import { FreshReportService } from './fresh-report.service';
import { ReportDataPoint, ReportsService } from './reports.service';
import { SnackbarService } from './snackbar.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService implements AbstractStoresReportInfo {

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public headerService: HeaderService,
    public freshReportService: FreshReportService,
    public snackbarService: SnackbarService,
    private chartGenerationService: ChartGenerationService) { }

  enzyBaseUrl: string = REST_BASE_URL;
  public selectedReport: DashboardData;
  private snapshotData: any[];


  private average(arr: number[]): number {
    return arr.reduce((p, c, i) => p + c) / arr.length
  }


  public storeReportMenuAndData(report: DashboardData, reportMenu: DataReportMenu, reportData: ReportDataPoint[], component: AbstractLoadsReportDataComponent): void {

    this.prepInstallDataForLineChart(reportData, report, component);
    this.prepInstallDataForColumnChart(reportData, report, component);
  }


  private prepInstallDataForColumnChart(installs: ReportDataPoint[], report: DashboardData, source: AbstractLoadsReportDataComponent = null) {
    let snapshotMax = Math.max.apply(Math, installs.map(function(o) { return +o.y; }))

    this.snapshotData = installs.map(o => {
      return {
      title: o.x,
      amount: o.y,
      value: 100 * +o.y/snapshotMax
      }
    })

    report.columnChartData = this.snapshotData;
    if (source) {
      source.afterDataLoads(report);
    }
  }

  mapNewReportViewTypeName(reportOrder: number): void {
    const newReport: DashboardData = this.savedReports[reportOrder];
    if (newReport) {
      if (newReport.reportMenu.viewType === "lineChart"){
        newReport.reportMenu.viewType = "Line Chart";
      }
      else if (newReport.reportMenu.viewType === "pieChart"){
        newReport.reportMenu.viewType = "Pie Chart";
      }
      else if (newReport.reportMenu.viewType === "columnChart"){
        newReport.reportMenu.viewType = "Column Chart";
      }
      else if (newReport.reportMenu.viewType === "table"){
        newReport.reportMenu.viewType = "Table";
      }
      else if (newReport.reportMenu.viewType === "leaderboard"){
        newReport.reportMenu.viewType = "Leaderboard";
      }
    }
  }

  private prepInstallDataForLineChart(installs: ReportDataPoint[], report: DashboardData, source: AbstractLoadsReportDataComponent = null) {

    let data: any[][] = installs.map(s => [new Date(Date.parse(s.x)), +s.y])

    let newData: any[][] = [];

    data.forEach((value, index) => {
      if (index <= data.length - 7) {
        newData.push([value[0], this.average(
          [
            data[index][1],
            data[index + 1][1],
            data[index + 2][1],
            data[index + 3][1],
            data[index + 4][1],
            data[index + 5][1],
            data[index + 6][1]
          ]
        )])
      }
    })

    newData.unshift([Date, 'Happiness Rating'])


    const output = Object.assign([], this.chartGenerationService.generateLineChartFromData(newData, '#eb4034'));
    const useDayOfMonth = true;
    const options = (useDayOfMonth) ? { year: "2-digit", month: "2-digit", day: "2-digit" } : { year: "2-digit", month: "2-digit" }
    const outputCorrect: DateReportData[] = [];
    output.dataTable.map(res => {
      if (res[1] !== "Happiness Rating") {
        const value: DateReportData = {
          "date": res[0].toLocaleDateString("en-US", options),
          "dataPointValue": res[1]
        }

        outputCorrect.push(value);
      }

    })

    if (report.trendData === undefined){
      report.trendData = new TrendData();
    }

    report.trendData.trendChartData_amChart = outputCorrect;
    report.trendData.trendChartData_googleChart = Object.assign([], this.chartGenerationService.generateLineChartFromData(newData, '#eb4034'))

    report.trendData.dataMax = Math.max.apply(Math, data.map(function(o) { return +o[1]; }))
    report.trendData.dataMin = Math.min.apply(Math, data.map(function(o) { return +o[1]; }))


    report.trendData.dataTotal = data.reduce((n, val) => {
      return +n + +(val[1]);
    }, 0);
    report.trendData.dataAvg = report.trendData.dataTotal / data.length;

  }


  async showAdvancedAnalytics(report: DashboardData) {
    // this.routerOutlet.swipeGesture = true;

    this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    const reportId: number = report.freshReport.id;

    if (report.reportCellList?.list?.length > 0) {
      this.selectedReport = report;
      this.dialog.closeAll();
      this.router.navigateByUrl('/analytics/0', { state: { selectedReport: report } });
      return;
    }

    const newCellList: ReportCellList = await this.freshReportService.getReportCellListByReportId(reportId);
    if (!report.reportMenu) {
      this.dialog.closeAll();
      this.snackbarService.displaySnackBarMessage("There is an error with this report", false)
      return;
    }

    report.reportMenu = newCellList.menu;
    report.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(report.reportMenu?.viewType)

    report.reportCellList = newCellList;
    if (newCellList) {
      this.headerService.asOfTime = newCellList.dataTime;
    }

    this.selectedReport = report;
    this.dialog.closeAll();
    this.router.navigateByUrl('/analytics/0', { state: { selectedReport: report } });
  }

  async showAdvancedAnalyticsByReportId(reportId: string, messageUsersMode: boolean = false, reportTime: string = null) {

    let report: DashboardData = {
      freshReport: new FreshReport(),
      reportMenu: new DataReportMenu(),
      reportCellList: new ReportCellList(),
      hasBeenSaved: false,
      viewFilter: 'Table',
      reportOrder: 0
    };
    
    const newCellList: ReportCellList = await this.freshReportService.getReportCellListByReportId(reportId, reportTime);
    report.reportMenu = newCellList.menu;
    report.reportMenu.viewType = this.freshReportService.convertBackendChartTypeToFrontendString(report?.reportMenu?.viewType)

    report.reportCellList = newCellList;
    if (newCellList) {
      this.headerService.asOfTime = newCellList.dataTime;
    }

    this.selectedReport = report;
    this.dialog.closeAll();
    this.router.navigateByUrl('/analytics/'+reportId, { state: { selectedReport: report, messageUsersMode: messageUsersMode } });
  }



  public savedReports: DashboardData[] = [];

}
