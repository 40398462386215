import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";

import { DeliveredNotifications, PushNotificationSchema, PushNotificationActionPerformed, PushNotificationDeliveredList, PushNotifications, PushNotificationToken } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  Capacitor
} from "@capacitor/core";
import { APP_VERSION, REST_BASE_URL } from "../models/constants";
import { DeviceDetailsService } from "./device-details.service";
import {  MessagingService } from "./messaging.service";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { MenuController, Platform } from "@ionic/angular";
import { LoginService } from "./login.service";
import { App } from '@capacitor/app';
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { UserDetailsService } from "./user-details.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { MessagingSocketService, ReactionCommand } from "./messaging-socket.service";
import { adamLog } from "../generic-functions";



@Injectable({
  providedIn: "root",
})
export class FcmService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    public router: Router,
    public userDetailsService: UserDetailsService,
    private http: HttpClient,
    private platform: Platform,
    public angularFireMessaging: AngularFireMessaging,
    public deviceDetailsService: DeviceDetailsService,
    public messagingService: MessagingService,
    public loginService: LoginService,
    public menu: MenuController,
    public messagingSocketService: MessagingSocketService,
    // public messagingStoreService: MessagingStoreService,
    public dialog: MatDialog
  ) { }

  initPush(sessionKey: string) {
    // console.log("INIT PUSH")

    if (Capacitor.platform !== "web") {
      this.registerPush(sessionKey);
    } else {
      console.log("REQUEST WEB PERMISSION")
      this.requestWebPermission(sessionKey);
    }
  }

  private requestWebPermission(sessionKey: string) {
    this.currentMessage.subscribe(res => {
    })
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.saveTokenToBackend(sessionKey, token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  startMessageListener() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      // console.log("new message received. ", payload);
      this.currentMessage.next(payload);
      this.handleNotification((payload as PushNotificationSchema));
    });
  }

  async triggerSaveToken() {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    if (sessionKey) {
      await this.registerPush(sessionKey);
    }
  }

  private async registerPush(sessionKey: string) {
    try {
      // let check = await PushNotifications.checkPermissions();
      // if (check.receive === 'granted') {return}

      let result = await PushNotifications.requestPermissions();

      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();

      } else {
        // No permission for push granted
        // alert (JSON.stringify(result));
        // return; 
        // alert(
        //   "WARNING! By not allowing notifications, you will not be able to use the app's messaging feature."
        // );
      }
    } catch(err) {
      // console.log("ERROR", JSON.stringify(err))
    }
    
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;

    await PushNotifications.removeAllListeners();
    PushNotifications.addListener(
      "registration",
      (token) => {
        // console.log("My token: " + token.value);
        console.log("over")

        this.saveTokenToBackend(sessionKey, token.value);

        //save token with backend
      }
    );

    PushNotifications.addListener("registrationError", (error: any) => {
      console.error("Error: " + JSON.stringify(error));
    });
    
    this.addPushNotificationListeners();
  }

  public startPushListeners(): void {
    this.startMessageListener();
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;
    PushNotifications.removeAllListeners();
    this.addPushNotificationListeners();
  }

  public addPushNotificationListeners(): void {
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        // console.log("Push received: " + JSON.stringify(notification));
        this.handleNotification(notification);
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notificationAction: PushNotificationActionPerformed) => {
        this.handleNotificationAction(notificationAction);
      }
    );
  }

  public async saveTokenToBackend(sessionKey: string, token: string) {
    const info: DeviceInfo = await this.deviceDetailsService.getDeviceInfo();
    const id: DeviceId = await Device.getId();

    adamLog("Saving device info");

    let body = new FormData();
    body.append('token', token);
    body.append('deviceInfo', JSON.stringify(info));
    body.append('deviceId', id.uuid);
    body.append('appVersion', APP_VERSION);

    // if (this.platform.is("ipad" || "iphone" || "ios" || "android" || "tablet" || "cordova" || "capacitor")) {
    //   const version: any = await this.appVersion.getVersionCode();
    //   body.append('appVersion', version.toString());
    // }
    
    let url: string = REST_BASE_URL + "/firebaseToken/" + sessionKey;
    
    this.http.post<any>(url, body).subscribe((result) => {
    });
  }


  public handleNotification(notification: PushNotificationSchema): void {
    if (this.messagingService.notificationIsMessage(notification)) {
      // this.messagingStoreService.loadAllConversations();
      // TODO: MOVE THIS TO SOCKET SERVICE OR SOMETHING
      this.messagingService.pushMessages$.next(notification);
    } 
    else if (this.messagingService.notificationIsReaction(notification)) {
      const reactionCommand: ReactionCommand = this.reactionCommandFromNotification(notification);
      if (reactionCommand.commandType === "ADD_REACTION") {
        this.messagingSocketService.handleAddReaction(reactionCommand);
      } else if(reactionCommand.commandType === "ADD_REACTION") {
        this.messagingSocketService.handleRemoveReaction(reactionCommand);
      }
    }
  }

  reactionCommandFromNotification(notification: PushNotificationSchema): ReactionCommand {
    const commandType: string = notification.data['notification_type'];
    const userId: number = +notification.data['userId'];
    const reaction: string = notification.data['reaction'];
    const messageId: number = +notification.data['messageId'];
    
    return {
      userId,
      reaction,
      messageId,
      commandType
    }
  }


  private async handleNotificationAction(notificationAction: PushNotificationActionPerformed): Promise<void> {    
    const data = notificationAction.notification.data;
    this.loginService.appOpenedFromNotification = true;
    this.loginService.notificationConvoId = +data?.convo_id;

    if (this.messagingService.notificationIsMessage(notificationAction.notification)) {

      this.messagingService.pushNotificationActions$.next(notificationAction);
      const newConvoId: number = +notificationAction?.notification?.data?.convo_id

      this.handleNavigationFromClick(newConvoId);

      return;
    }
    else if (this.messagingService.notificationIsReaction(notificationAction.notification)) {

      const newConvoId: number = +notificationAction?.notification?.data?.convo_id

      this.handleNavigationFromClick(newConvoId);
      // const newConvoId: number = this.messagingService.getConversationIdByMessageId(messageId);

      // if (newConvoId && newConvoId !== undefined) {
      //   this.handleNavigationFromClick(newConvoId);
      // }

    }

  }

  shouldNavigate: boolean = false;
  private async handleNavigationFromClick(newConvoId: number) {
    this.shouldNavigate = true;

    // alert("handleNavigationFromClick")

    this.platform.ready().then(async () => {

      if (this.router.url === "/login") {
        while (this.loginService.userHasLoggedIn === false) {
          await this.delay(300);
        }
      }
      if (this.router.url === "/messages") {
        this.router.navigateByUrl("/messages/" + newConvoId);
      } else {
        this.router.navigateByUrl("/messages", { state: { fromNotificationConvoId: newConvoId } });
      }

    });


  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  // private hideSplashScreen() {
  //   if (!(this.platform.is("mobileweb")||this.platform.is("desktop"))) {
  //     SplashScreen.hide();
  //   }
  // }

  public async getOutstandingNotifications(): Promise<PushNotificationSchema[]> {
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return [];

    const userId: string = await this.userDetailsService.getUserId();
    if (!userId) return [];

    const notificationList: DeliveredNotifications = await PushNotifications.getDeliveredNotifications();

    //THIS SHOULD NOT BE EMPTY!
    console.log(JSON.stringify(notificationList.notifications))
    

    return notificationList.notifications;
  }

  public removeAllDeliveredNotifications() {
    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;
    PushNotifications.removeAllDeliveredNotifications();
    // this.getOutstandingNotifications().then(res => console.log(JSON.stringify(res)))
  }
}
