import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { camelCaseToSentenceCase } from 'src/app/shared/generic-functions';
import { LeadChanges } from 'src/app/shared/services/messaging.service';


@Component({
  selector: 'enzy-generic-map-modal',
  templateUrl: './generic-map-modal.component.html',
  styleUrls: ['./generic-map-modal.component.scss'],
})
export class GenericMapModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GenericMapModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {map: {[key: string]: string}, objectType: string},
    )  { }

  ngOnInit() {}

  getTitle(): string {
    return camelCaseToSentenceCase(this.data?.objectType);
  }

  getKeys() {
    return Object.keys(this.data?.map)
  }

  getValue(key: string): string {
    return this.data?.map[key];
  }

  camelCaseToSentenceCase(key: string): string {
    return camelCaseToSentenceCase(key);
  }

}
