import { options } from '@amcharts/amcharts4/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { REST_BASE_URL } from '../models/constants';
import { DataReportMenu } from '../models/dashboard-report-list.model';
import { DashboardData } from '../models/dashboard.model';
import { FreshReportResponse } from '../models/fresh-report.model';
import { ReportCellList } from '../models/report-cell-list.model';
import { DashboardService } from './dashboard.service';
import { ReportStoreService } from './report-store.service';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class FreshReportService {

  constructor(private http: HttpClient, 
    public userDetailsService: UserDetailsService) { }


  async getFreshReports(): Promise<FreshReportResponse> {
    const sessionKey: string = await this.userDetailsService.getSessionKey()
    let url: string = REST_BASE_URL + '/dataReportSaved/' + sessionKey;
    return this.http.get<FreshReportResponse>(url).toPromise();
  }

  public async getFreshReportMenu(reportId: string): Promise<DataReportMenu> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/dataReportMenu/' + sessionKey;
    url += '?reportId=' + reportId;
    return this.http.get<DataReportMenu>(url).toPromise();
  }

  public async getReportCellListByReportId(reportId: any, reportDate: string = null): Promise<ReportCellList> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let dataReportListFullUrl = REST_BASE_URL + '/dataReportList/' + sessionKey;
    
    dataReportListFullUrl += "/" + reportId;
    if (reportDate !== null) {
      dataReportListFullUrl += "?reportDate=" + reportDate;
    }
    
    // const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    // console.log(dataReportListFullUrl)
    return this.http.get<ReportCellList>(dataReportListFullUrl).toPromise();
  }

  public async getEmployeeReports(userId: string): Promise<any> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let dataReportListFullUrl = REST_BASE_URL + '/employeeReports/' + sessionKey;

    dataReportListFullUrl += "?userId=" + userId;
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    return this.http.get<any>(dataReportListFullUrl).toPromise();
  }


  ///rest/dataReportSave
  saveFreshReportMenu(report: DashboardData, sessionKey: string): Observable<DataReportMenu> {
    let url: string = REST_BASE_URL + '/dataReportSave/';
    url += sessionKey;
    url += '?reportName=' + report.freshReport.reportName;

    report.reportMenu.viewType = this.convertChartTypeStringToBackendString(report.reportMenu.viewType);

    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
    let body = JSON.stringify(report.reportMenu);
    return this.http.post<DataReportMenu>(url, body, {headers: headers});
  }

  public convertChartTypeStringToBackendString(chartTypeString: string): string{
    if (chartTypeString === "Line Chart") {         return "lineChart"; }
    else if (chartTypeString === "Pie Chart") {     return "pieChart"; }
    else if (chartTypeString === "Column Chart") {  return "columnChart"; }
    else if (chartTypeString === "Leaderboard") {   return "leaderboard"; }
    else if (chartTypeString === "Table") {         return "table"; }
    return 'table';
  }

  public convertBackendChartTypeToFrontendString(backendChartType: string): string{
    if (backendChartType === 'table') {             return "Table"; }
    else if (backendChartType === 'lineChart') {    return "Line Chart"; }
    else if (backendChartType === 'pieChart') {     return "Pie Chart"; }
    else if (backendChartType === 'columnChart') {  return "Column Chart"; }
    else if (backendChartType === 'leaderboard') {  return "Leaderboard"; }
    return "Table";
  }

  deleteFreshReportMenu(report: DashboardData, sessionKey: string): Observable<DataReportMenu> {

    let url: string = REST_BASE_URL + '/dataReportDelete/';
    url += sessionKey;

    let body = report.freshReport.id;
    return this.http.post<DataReportMenu>(url, body);
  }

  updateFreshReportName(report: DashboardData, sessionKey: string): Observable<DataReportMenu> {
    let url: string = REST_BASE_URL + '/dataReportEdit/';
    url += sessionKey;
    url += '?reportName=' + report.freshReport.reportName;

    let body = report.freshReport.id;

    return this.http.post<DataReportMenu>(url, body);
  }

  async getDataListReportCsv(reportId: string, email: boolean = true, download: boolean = true): Promise<Observable<any>> {
    const sessionKey: string = await this.userDetailsService.getSessionKey();
    let url: string = REST_BASE_URL + '/dataListCsv/' + sessionKey;
    url += "/" + reportId

    let params: any = {};

    params['download'] = download;
    params['email'] = email;
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // return this.http.get(this.url,{ headers, responseType: 'text'});

    return this.http.get(url, { responseType: "text", params: params } );
  }
}
