import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDatepickerInputEvent, MatDialog, MatDialogRef, MatSnackBar, PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonRouterOutlet, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { MessageSelectRecipientComponent } from '../messaging/message-select-recipient/message-select-recipient.component';
import { HeaderService } from '../shared/components/my-header/header.service';
import { adamLog } from '../shared/generic-functions';
import { Employee } from '../shared/models/employees.model';
import { Filter, LeaderboardCell, LeadersPage, SummaryResult } from '../shared/models/leaders.model';
import { BusySpinnerService } from '../shared/services/busy-spinner.service';
import { DatesService } from '../shared/services/dates.service';
import { EmployeeStoreService } from '../shared/services/employee-store.service';
import { LeadersStoreService } from '../shared/services/leaders-store.service';
import { LeaderboardFilterOutput } from '../shared/services/leaders.service';
import { ProfileService } from '../shared/services/profile.service';
import { ReportsService } from '../shared/services/reports.service';
import { UserDetailsService } from '../shared/services/user-details.service';
import { ListOfProjectsComponent } from './list-of-projects/list-of-projects.component';

@Component({
  selector: 'app-leaders',
  templateUrl: './leaders.component.html',
  styleUrls: ['./leaders.component.scss'],
})
export class LeadersComponent implements OnInit {

  @ViewChild('searchInput') searchElement: ElementRef;
  searchValue: string = "";

  viewType: LeaderboardViewType = "list";
  searchOn: boolean = false;

  // TODO: could we make id a better variable name? I don't know what this is
  id: string;
  inputtedLeaderboardCell: LeaderboardCell; //used on drill downs
  forOption: string;


  options = {
    types: [],
    componentRestrictions: { country: ['USA'] }
  }

  companyId: number;

  formattedAddress = "";
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    this.formattedAddress = address.formatted_address;
  }


  constructor(public busySpinnerService: BusySpinnerService, 
    private datesService: DatesService, 
    private employeeStoreService: EmployeeStoreService, 
    private navController: NavController, 
    private leadersStoreService: LeadersStoreService, 
    private _route: ActivatedRoute, 
    private bottomSheet: MatBottomSheet, 
    private profileService: ProfileService,
    private routerOutlet: IonRouterOutlet, 
    private router: Router, 
    private dialog: MatDialog, 
    ) {


    if (history.state.leaderboardCell) {
      this.inputtedLeaderboardCell = history.state.leaderboardCell;
    }

    if (history.state.forOption) {
      this.forOption = history.state.forOption;
    }

    if (history.state.viewType) {
      this.viewType = history.state.viewType;
    }

    if (history.state.selectedIndex) {
      this.setSummaryResult(history.state.selectedIndex)
    }

    
  }

  shouldShowCustomDates(): boolean {
    return this.leadersStoreService.getCustomDatesIsSelected(+this.id);
  }

  ionViewDidEnter() {
    if (this.id === '0') {
      this.routerOutlet.swipeGesture = false;
    } else {
      this.routerOutlet.swipeGesture = true;
    }
  }

  async ngOnInit() {    
    this.id = this._route.snapshot.paramMap.get('id');

    if (this.id !== '0' && !this.routerOutlet.canGoBack()) {
      this.router.navigate(['/home/0'])
    }

    this.companyId = await (await this.profileService.loadSelectedWorkspaceFromCache()).id

  }

  getCurrentZeroValuesString(showZeros: boolean = this.currentlyShowingZeroValues()): string {
    return showZeros ? "Show All" : "Hide Zeros"
  }

  currentlyShowingZeroValues(): boolean {
    return this.getCurrentPage().showZeros;
  }

  changeZeroType(showZeros: boolean) {
    this.getCurrentPage().showZeros = showZeros;
    this.leadersStoreService.fetchLatestSummaryResults(+this.id);
  }

  getCellsToDisplay(): LeaderboardCell[] {
    return this.getLeaderboardCellList()?.filter(c => this.filterCellBySearch(c));
  }

  filterCellBySearch(cell: LeaderboardCell): boolean {
    if (!this.searchOn) return true;
    if (this.searchValue?.length === 0) return true;
    return (cell.title).toLowerCase().includes(this.searchValue.toLowerCase());
  }

  async doRefresh() {
    await this.leadersStoreService.refresh();
  }


  setSearchOn() {
    this.searchOn = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  clearSearch() {
    this.searchValue = "";
    this.searchOn = false;
    this.searchSend();

    this.searchSend();
  }

  searchSend() {
    // this.currentOptions = this.getLeaderboardCellList().filter(c => (c.title).toLowerCase().includes(this.searchValue.toLowerCase()));
  }


  changeViewType(newType: LeaderboardViewType) {
    // TODO: Open modal
    if (!newType) return;
    this.viewType = newType;
  }

  selectedIndex: number = 0;
  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.selectedIndex = clickedIndex;
  }

  setSummaryResult(index: number) {
    this.selectedIndex = index;
  }





  goBack(): void {
    this.leadersStoreService.removeFromStack();
    if (this.routerOutlet.canGoBack()) {
      this.navController.pop();
    } else {
      this.router.navigate(["/home/0"])
    }
  }


  getFilters(): Filter[] {
    if (this.id === "0") {
      return this.leadersStoreService.basePage?.filters
    } else {
      let tempId = +this.id
      return this.leadersStoreService.leadersStack.leadersPages[tempId]?.filters
    }
  }

  getCurrentPage(): LeadersPage {
    if (this.id === "0") {
      return this.leadersStoreService.basePage
    } else {
      let tempId = +this.id
      return this.leadersStoreService.leadersStack.leadersPages[tempId];
    }
  }

  getCurrentLeaderboardFilter(): LeaderboardFilterOutput {
    return this.getCurrentPage()?.leaderboardFilterOutput;
  }

  getSummaryResults(): SummaryResult[] {
    return this.getCurrentPage()?.summaryResult
  }

  getSummaryValueFormatted(summaryResult: SummaryResult) {
    return summaryResult.summaryValueFormatted ? summaryResult.summaryValueFormatted : "0"
  }

  getLeaderboardCellList(): LeaderboardCell[] {
    let summaryResult = this.getSummaryResults();
    if (!summaryResult) { return [] }
    let res = summaryResult[this.selectedIndex];
    return res?.leaderboardCellList;

  }

  getShouldShowImage(): boolean {
    let summaryResult = this.getSummaryResults();
    if (!summaryResult) { return true }
    let res = summaryResult[this.selectedIndex];
    if (res.showImage === null || res.showImage === undefined) return true;
    return res.showImage;
  }


  addStartDate(event: MatDatepickerInputEvent<Date>) {
    this.getCurrentLeaderboardFilter().startDate = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.leadersStoreService.fetchLatestSummaryResults(+this.id);

  }

  addEndDate(event: MatDatepickerInputEvent<Date>) {
    this.getCurrentLeaderboardFilter().endDate = this.datesService.serverFriendlyDate(event.value.toDateString());
    // TODO: use updated filter
    this.leadersStoreService.fetchLatestSummaryResults(+this.id);

  }


  getForOption(): string {
    let filters = this.getFilters();
    let returnVal = "";
    filters.forEach(item => {
      if (item?.title === "forOptions") { //TODO: I'm not sure if we want this hardcoded?
        returnVal = item.map[item.currentSelectedKey];
      }
    })

    return returnVal;
  }

  //There might be a case where we have a leaderboard and there are no underlying users to message
  shouldShowMessageUsers(): boolean {
    const result: SummaryResult = this.getSummaryResults()[this.selectedIndex];
    if (!result) return false;
    return result.leaderboardCellList.map(cell => cell.employeeId).filter(employeeId => employeeId !== null).length > 0;
  }

  messageUsers() {

    this.busySpinnerService.start();

    let summaryResult = this.getSummaryResults()[this.selectedIndex];
    if (!summaryResult) { return }

    let employeesToMessage: Employee[] = summaryResult.leaderboardCellList
      .filter(cell => cell.employeeId !== null)
      .map(cell => this.employeeStoreService.getUser(cell.employeeId));

    let ref = this.openModalToMessageUsers(employeesToMessage);

    ref.afterClosed().subscribe(res => {
      if (!res) { return }
      if (!res.isBCC) {
        this.router.navigateByUrl("/messages/" + res.conversationId);
      } else if (res.isBCC) {
        this.router.navigateByUrl('/messages/-1', { state: { isBCC: true, selectedUsers_toSend: res.selectedUsers_toSend } });
      }
    })

    this.busySpinnerService.stop();
  }

  openModalToMessageUsers(employeesToMessage: Employee[]): MatDialogRef<MessageSelectRecipientComponent, any> {
    return this.dialog.open(MessageSelectRecipientComponent, {
      maxWidth: '100vw !important',
      width: '100%',
      height: '100%',
      data: {
        employeesToMessage: employeesToMessage,
        isBCC: true
      },
      panelClass: 'transparent',
    })
  }

  async openProjectsModal() {
    this.busySpinnerService.start()
    const filter: LeaderboardFilterOutput = this.getCurrentLeaderboardFilter();
    const summaryResults = this.getSummaryResults();
    if (!summaryResults) return;
    const dataFilterAttributeIndex = summaryResults[this.selectedIndex].dateFilterAttributeIndex;

    const res: any = await this.leadersStoreService.leadersService.getProjects(filter, dataFilterAttributeIndex);
    const list: any[] = res?.list;

    // Display Projects
    let dialogRef = this.bottomSheet.open(ListOfProjectsComponent, {

      data: { data: list }
    });

    dialogRef.afterOpened().subscribe(res => {
      this.busySpinnerService.stop()

    })
  }


  formatDate(date: string) {
    const formattedDate = new DatePipe('en-US').transform(date, 'MM/dd/yy')
    return formattedDate
  }

  @ViewChild(IonContent, {read: IonContent}) ionContentElement: IonContent;
  @ViewChild('toolbarArea') toolbarAreaElement: ElementRef;
  @ViewChild('summaryArea') summaryAreaElement: ElementRef;
  @ViewChild('footerElement') footerElementElement: ElementRef;

  heightOf(element: ElementRef): number {
    return element?.nativeElement?.offsetHeight;
  }


  calculateListHeight(): string {
    // const contentHeight: number = this.contentHeight || 1000;
    const toolbarHeight: number = this.heightOf(this.toolbarAreaElement) || 100;
    const summaryAreaHeight: number = this.heightOf(this.summaryAreaElement) || 100;
    const topAreaHeight: number = toolbarHeight + summaryAreaHeight;
    
    const footerAreaHeight: number = this.heightOf(this.footerElementElement) || 100;

    const listAreaHeight: number = topAreaHeight + footerAreaHeight;
    return "calc(100vh - "  + listAreaHeight + "px)";
  }

  shouldShowViewProjects() {
    return this.companyId !== 11; //TODO: Cafe rio shouldn't see projects... there's a better way to do this
  }

}

export type LeaderboardViewType =  "list" | "grid" | "bar_list";