import { AfterViewInit, Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar, MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap, RoutesRecognized } from '@angular/router';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { IonContent, IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AdvancedAnalyticsModalComponent } from 'src/app/reports/advanced-analytics/advanced-analytics-modal/advanced-analytics-modal.component';
import { GenericProgressModalComponent } from 'src/app/shared/components/generic-progress-modal/generic-progress-modal.component';
import { GiphyComponent } from 'src/app/shared/components/giphy/giphy.component';
import { HeaderService } from 'src/app/shared/components/my-header/header.service';
import { Employee } from 'src/app/shared/models/employees.model';
import { CustomFileService } from 'src/app/shared/services/custom-file-service.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { MessagingSocketService, ReactionCommand, EditMessageCommand, DeleteMessageCommand, ImageReceivedCommand } from 'src/app/shared/services/messaging-socket.service';
import { MessagingStoreService } from 'src/app/shared/services/messaging-store.service';
import { ConversationModel, MessageModel, MessageTypeEnum, MessagingService, ParticipantModel, Reaction, ReportLine } from 'src/app/shared/services/messaging.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { MessageActionsDrawerComponent } from '../message-actions-drawer/message-actions-drawer.component';
import { v4 as uuidv4 } from 'uuid';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { DomController } from '@ionic/angular';
import { BccGuardRailModalComponent } from 'src/app/shared/components/bcc-guard-rail-modal/bcc-guard-rail-modal.component';
import { getBlobFromFile } from 'src/app/shared/generic-functions';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';


export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('1000ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('1000ms', style({ opacity: 0 }))]
  )
]);

const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0 }),
      stagger(100, [
        animate('1.5s', style({ opacity: 1 }))
      ])
    ], { optional: true })
  ])
])

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  animations: [listAnimation, fadeAnimation]

})
export class ConversationComponent implements OnInit, OnDestroy, AfterViewInit {
  refresherVar: string = "test";
  sessionKey: string = "";
  showMessagingToolbar: boolean = false;
  selectedGif: string = "";

  showMention: boolean = false;

  conversation: ConversationModel;
  messages: MessageModel[] = [];

  replyMessage: MessageModel;

  userId: string = "";

  private subscriptions = new Subscription();


  pushMessagesSubscription: Subscription;
  pushAddReactionSubscription: Subscription;
  pushRemoveReactionSubscription: Subscription;
  pushEditMessageSubscription: Subscription;
  pushDeleteMessageSubscription: Subscription;
  selectedFile = null;

  messageBeingEdited: MessageModel;

  url;

  val = "https://github.com/angular-material-extensions/link-preview";

  screenHeight: number;
  screenWidth: number;
  screenType: string = "mobile";


  mentionOptions: ParticipantModel[];

  numberOfMentions: number = 0;

  tagMode: boolean = false;

  originReportLines: ReportLine[];

  @ViewChild("content") private content: IonContent;


  constructor(
    private snackbarService: SnackbarService,
    public zone: NgZone,
    private _bottomSheet: MatBottomSheet,
    public platform: Platform,
    private sanitizer: DomSanitizer,
    public nav: HeaderService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    public messagingService: MessagingService,
    public messagingStoreService: MessagingStoreService,
    public userDetailsService: UserDetailsService,
    public messagingSocketService: MessagingSocketService,
    public customFileService: CustomFileService,
    public loginService: LoginService,
    public headerService: HeaderService,
    public routerOutlet: IonRouterOutlet,
    private navCtrl: NavController,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {

    if (history.state.reportLines) {
      this.originReportLines = history.state.reportLines;
    }

    setTimeout(() => {
      this.clearUnreadMessagesChip();
    }, 1000)

    this.startHandlers();

    if (this.platform.is("mobileweb") || this.platform.is("desktop")) return;
    Keyboard.addListener("keyboardDidShow", (info: KeyboardInfo) => {
      setTimeout(() => {
        this.scrollToBottom();
      }, 30)
    });

  }


  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const conversationId: number = +params.get('conversationId');

      this.initComponent(conversationId);
    });
  }

  async initializeConversationById(conversationId: number): Promise<void> {
    let convo: ConversationModel = this.messagingStoreService.getStoredConversationBasedOnId(conversationId);
    if (!convo) {
      await this.messagingStoreService.loadCachedConversations();
    }
    convo = this.messagingStoreService.getStoredConversationBasedOnId(conversationId);
    this.conversation = convo;
  }

  @ViewChildren('ngForMessages') ngForMessages: QueryList<any>;

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  smoothEndScroll(): void {
    const element = document.querySelector("")

    if (!this.messages[this.messages.length - 1]) { return }
    let lastMessageId = this.messages[this.messages.length - 1].messageId
    const last = document.getElementById('message-' + lastMessageId);


    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "end" });
  }

  keyaction() {
    this.userHasStartedTyping = true;
    this.autogrow();
  }

  ngOnDestroy() {
    // if (this.pushMessagesSubscription) {
    // this.pushMessagesSubscription.unsubscribe();
    // this.pushAddReactionSubscription.unsubscribe();
    // this.pushRemoveReactionSubscription.unsubscribe();
    // this.pushEditMessageSubscription.unsubscribe();
    // }

    this.subscriptions.unsubscribe();
  }

  @ViewChild('chat_input') chat_input: ElementRef

  textArea: any;

  ngAfterViewInit() {
    this.textArea = document.getElementById("textarea")
  }

  autogrow() {
    if (this.textArea === null) {
      console.log("no text area found")
      return
    }
    this.textArea.style.overflow = 'hidden';
    this.textArea.style.height = '0px';
    this.textArea.style.height = this.textArea.scrollHeight + 'px';
    if (this.textArea.scrollHeight === 0) {
      this.textArea.rows = "1";
    }
  }

  resetTextarea() {
    let textArea = document.getElementById("textarea");
    if (textArea === null) {
      console.log("no text area found")
      return
    }
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    // textArea.style.height = textArea.scrollHeight + '0px';
  }


  newUnreadMessage: boolean = false;

  shouldShowDownArrow() {
    const tempMessages: MessageModel[] = [...this.messages];

    if (tempMessages.length === 0 || !tempMessages[tempMessages.length - 1]) { return }
    let lastMessageId = tempMessages[tempMessages.length - 1].messageId
    const last = document.getElementById('message-' + lastMessageId);
    if (this.isInViewport(last)) { this.newUnreadMessage = false; }

    return !this.isInViewport(last) && this.newUnreadMessage;
  }

  doRefresh() {
    this.refresherVar = "" + Math.random();

    setTimeout(() => {
    }, 1000);
  }

  mentionPeople: string[] = []




  appIsActive: boolean = true;

  async initComponent(conversationId: number) {
    if (conversationId !== -1) {
      await this.initializeConversationById(conversationId);
    }
    else {
      this.isBcc = true;
    }
    await this.initMessages();

  }

  mentionConfig: any = {};


  async setupMentionConfig() {
    let userAccessLevel = await this.userDetailsService.getAccessLevel();
    if (this.isBcc && userAccessLevel === 10) {
      this.mentionConfig = {
        mentions: [
          {
            items: this.mentionPeople,
            triggerChar: '@'
            , maxItems: 10, labelKey: 'name', dropUp: 'true'
          },
          {
            items: ["FIRST"],
            triggerChar: '$',
            maxItems: 10, labelKey: 'name', dropUp: 'true'

          }
        ]
      }
    } else {
      this.mentionConfig = {
        mentions: [
          {
            items: this.mentionPeople,
            triggerChar: '@'
            , maxItems: 10, labelKey: 'name', dropUp: 'true'
          }
        ]
      }
    }

  }


  private async initMessages() {
    await this.setupMentionConfig();

    if (!this.messagingStoreService.getStoredConversations()) this.isLoading = true;
    if (!this.conversation) return;
    this.messages = await this.messagingStoreService.getStoredMessagesForConversation(this.conversation?.conversationId);
    

    this.sessionKey = await this.userDetailsService.getSessionKey();
    this.userId = await this.userDetailsService.getUserId();
    this.viewLoadedOneSecondAgo = true;

    let arr = []
    this.conversation?.participants.forEach(item => {
      arr.push(item.preferredFirstName + " " + item.lastName)
    })

    this.mentionPeople = arr;

    setTimeout(() => {
      this.viewLoadedOneSecondAgo = false;
    }, 1000)

    // We want to redraw the component if one of the following events occurs:
    // - New Message comes in
    // - Socket was disconnected then reconnected 
    this.messagingStoreService.triggerRedraw$?.subscribe(async (value) => {

      this.viewLoadedOneSecondAgo = true;

      setTimeout(() => {
        this.viewLoadedOneSecondAgo = false;
      }, 1000)

      this.zone.run(() => {
      })
      if (value !== "lazyLoad") {
        let convoIdForIncomingMessage = value.convoId;

        if (convoIdForIncomingMessage === this.conversation?.conversationId) {
          this.newUnreadMessage = true;
        }
      }
    });
  }



  async checkIfThereAreNewMessages() {
    let tempMessages = await this.messagingStoreService.getStoredMessagesForConversation(this.conversation?.conversationId);
    return tempMessages[tempMessages.length - 1].messageId !== this.messages[this.messages.length - 1].messageId;
  }


  bccUsersToSend: Employee[];
  isBcc: boolean = false;



  scrollingIsLockedDuringIntialization: boolean = true;

  ngAfterViewChecked(): void {
    if (this.scrollingIsLockedDuringIntialization) {
      this.scrollToBottom();
    }
  }


  async ionViewWillEnter() {


    this.initMessages();
    // setTimeout(() => {
    //   this.scrollToBottom(); //This was put in for after a poll is created 
    // }, 300)

    setTimeout(() => {
      this.scrollingIsLockedDuringIntialization = false;
    }, 700)

    this.autogrow();
    this.routerOutlet.swipeGesture = true;
    this.messagingStoreService.startNewMessageHandler();

    setTimeout(() => {
      this.clearUnreadMessagesChip();
    }, 500)



    if (history.state.reportLines) {
      this.originReportLines = history.state.reportLines;
    }

    if (history.state.isBCC) {
      this.isBcc = true;
      this.bccUsersToSend = history.state.selectedUsers_toSend;
    }
    else {
      this.isBcc = false;
    }
  }

  setBccMode(isBcc = true, usersToSend: Employee[]) {

    if (isBcc) {
      this.isBcc = true;
      this.bccUsersToSend = usersToSend ? usersToSend : history.state.selectedUsers_toSend;
    }
    else {
      this.isBcc = false;
    }
  }

  getConversationTitle(): string {
    if (this.isBcc) {
      return this.bccUsersToSend?.map((user) => user.userDto.preferredFirstName).join(", ");
    }

    const selectedConversation: ConversationModel = this.conversation;
    if (selectedConversation === undefined) {
      return;
    }
    if (selectedConversation.title) {
      return selectedConversation.title;
    } else {
      return this.generateTitleFromParticipants(selectedConversation);
    }
  }


  private generateTitleFromParticipants(convo: ConversationModel): string {
    if (convo.participants.length === 2) {
      return convo.participants.filter(usr => usr.id != this.userId).map(user => this.getFullName(user)).join(', ');
    } else {
      return convo.participants.filter(usr => usr.id != this.userId).map(user => this.getpreferredFirstNameLastInitial(user)).join(', ');
    }
  }

  getFullName(user: ParticipantModel): string {
    const first: string = user.preferredFirstName ? user.preferredFirstName : '';
    const last: string = user.lastName ? user.lastName : '';
    return first + " " + last;
  }

  getpreferredFirstNameLastInitial(user: ParticipantModel): string {
    const lastName: string = user?.lastName;
    const lastInitial: string = lastName?.length > 0 ? lastName[0] : "";
    return user.preferredFirstName ? user.preferredFirstName : '' + " " + lastInitial;
  }

  displayReconnectingMessage() {
    return this.messagingSocketService.displayReconnectingBar();
  }

  openRenameConversationDialog(): void {
    if (this.userCanRenameConversation()) {
      const dialogRef = this.dialog.open(AdvancedAnalyticsModalComponent, {
        width: "95%",
        data: {
          mode: "editName",
          reportName: this.conversation.title,
        },
      });

      dialogRef.afterClosed().subscribe((newTitle: string) => {
        if (newTitle === undefined || newTitle.length === 0) {
          alert("Unable to rename this conversation");
          return;
        } else {
        }
        if (newTitle !== undefined) {
          this.renameConversation(newTitle);
        }
      });
    }
  }

  private userCanRenameConversation(): boolean {
    return true;
  }

  renameConversation(newTitle: string): void {
    this.messagingService
      .renameConversation(
        this.sessionKey,
        this.conversation?.conversationId,
        newTitle
      )
      .then((result) => {
        if (result.message.toLowerCase().includes("success")) {
          this.conversation.title = newTitle;
          this.messagingService.triggerConvoReload$.next("Rename Convo");
        }
      });
  }

  reply(replyMessage: MessageModel) {
    this.replyMessage = replyMessage;
  }


  scrollSmooth() {
    this.content.scrollToBottom(100);
  }

  scrollToCurrentSpot() {
    return 100;
  }

  scrollToBottom() {
    if (!this.scrollMe) return;
    this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
  }

  @ViewChild('scrollMe', { static: false }) scrollMe;

  shouldScrollDown = true;
  shouldScrollDownCheck() {
    return this.shouldScrollDown;
  }


  isLoading: boolean = false;
  allMessagesLoaded: boolean = false;

  viewLoadedOneSecondAgo = true;

  async triggerLoadNewConversations(event) {
  }

  public scroll = false;
  private timeout: any;

  //This method is used to prevent long press from being called while user is scrolling
  setIsScrollingVariable() {
    this.scroll = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.scroll = false;
    }, 100);
  }

  scrollTop: any;
  scrollLeft: any;
  disableScroll() {
    // Get the current page scroll position
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,

      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(this.scrollLeft, this.scrollTop);
      };
  }

  enableScroll() {
    window.onscroll = function () { };
  }



  currentlyLazyLoading: boolean = false;

  async triggerLoadNewMessages(event) {

    this.setIsScrollingVariable();

    if (this.viewLoadedOneSecondAgo) return;
    if (this.isLoading || this.allMessagesLoaded) return;

    const start = document.getElementById('start-'+this.conversation.conversationId);


    if (this.isInViewport(start)) {
      this.isLoading = true;

      setTimeout(async () => {


        if (this.scroll || !this.isInViewport(start)) {
          this.isLoading = false;
          return
        }
        this.currentlyLazyLoading = true;
        let oldTopOfMessages = this.messages[0].messageId;



        const numberOfNewMessages: number = await this.messagingStoreService.appendLazyLoadedData(this.conversation?.conversationId);
        // this.messages = await this.messagingStoreService.getStoredMessagesForConversation(this.conversation?.conversationId);


        if (numberOfNewMessages === 0) {
          this.allMessagesLoaded = true;
          this.isLoading = false;
          return;
        }

        setTimeout(() => {
          this.isLoading = false;
          this.currentlyLazyLoading = false;

        }, 1000)


        setTimeout(() => {

          const itemToScrollTo = document.getElementById('message-' + oldTopOfMessages);

          if (itemToScrollTo) {
            itemToScrollTo.scrollIntoView({ behavior: "auto", block: "start" });
          }

        }, 100);

      }, 1000)

    }
  }

  // haveScrolledToBottomOnClick: boolean = false;
  // haveClickedIntoConversation: boolean = false;

  // lastHasRendered() {

  //   if (this.haveClickedIntoConversation) {

  //     const end = document.getElementById('end');
  //     if (this.haveScrolledToBottomOnClick) { return }

  //     this.scrollToBottom();

  //     setTimeout(() => {
  //       if (this.isInViewport(end)) {
  //         this.haveScrolledToBottomOnClick = true;
  //       }
  //       else {
  //         this.scrollToBottom();
  //       }
  //     }, 400);
  //   }
  // }




  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  public trackData(_: number, item: any): any {
    return item.value;
  }

  isInViewport(element: Element): boolean {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    if (
      rect.x === 0 &&
      rect.y === 0 &&
      rect.bottom === 0 &&
      rect.height === 0 &&
      rect.left === 0 &&
      rect.right === 0 &&
      rect.height === 0 &&
      rect.width === 0) {
      return false;
    }

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  settingNewMessages: boolean = false;
  userHasStartedTyping: boolean = false;



  startHandlers() {
    this.startNotificationActionHandler();
    this.startNewMessageHandler();
    this.startAddReactionHandler();
    this.startRemoveReactionHandler();
    this.startEditMessageHandler()
    this.startDeleteMessageHandler();
    this.startImageReceivedByServerHandler();

    this.messagingStoreService.messageMapUpdated$?.subscribe(async () => {
      let selectedConvo = this.conversation

      if (selectedConvo) {
        this.messages = await this.messagingStoreService.getStoredMessagesForConversation(selectedConvo.conversationId);
      }
      else {
        // this.router.navigate(["/messages"])
      }
    })

    if (!this.messagingStoreService.messageMapUpdated$) {
      // this.router.navigate(["/messages"])
    }
  }

  clearUnreadMessagesChip() {
    let convo = this.messagingStoreService.getStoredConversationBasedOnId(this.conversation?.conversationId)
    if (convo?.unreadMessageCount > 0) {
      this.messagingStoreService.markSelectedConvoAsReadLocally(this.conversation);
      this.messagingService.markConvoRead(this.conversation?.conversationId).then((result) => {

        if (result.message.includes("success")) {
          this.messagingStoreService.markSelectedConvoAsReadLocally(this.conversation);
        }
      });
    }
  }



  format(item: any) {
    return `<span class="chip">${item['name'].split(' ')[0]}</span>`;
  }

  back() {
    // console.log(this.routerOutlet.canGoBack())

    if (this.routerOutlet.canGoBack() === true) {
      this.navCtrl.navigateRoot('/messages', { animated: true, animationDirection: 'back' });
    } else {
      console.log("weirdBackButtonState");
      try {
        this.router.navigate(["/messages"])
      } catch (e) {
        console.log(e)
      }

    }

  }


  openCalendarEvent() {
    this.router.navigate(['/new-calendar'])
  }


  openPoll() {
    this.router.navigateByUrl('/new-poll', { state: { conversationId: this.conversation?.conversationId } });
  }



  addEmoji(emoji: string) {
    this.messagingStoreService.editorMsg += emoji;
  }

  markConversationAsRead() {
    this.messagingStoreService.markSelectedConvoAsReadLocally(this.conversation);
    this.messagingService.markConvoRead(this.conversation?.conversationId).then((response) => {
    });
  }

  startNewMessageHandler(): void {
    this.messagingSocketService.socketMessages$.subscribe((newMessage: MessageModel) => {
      // devAlert("new message")

      if (this.messageIsInConvo(newMessage)) {
        this.clearUnreadMessagesChip();
      }

      if (!this.messageIsInConvo(newMessage) || !this.appIsActive) { return }

      this.newUnreadMessage = true;

    });
  }

  // TODO: refactor Move notification action handler to messaging store service? Solve this
  startNotificationActionHandler() {
    this.messagingService.pushNotificationActions$.subscribe((action) => {
      const newConvoId: number = +action?.notification?.data?.convo_id;
      // Use the new convoId to navigate
      if (!newConvoId) return;


    });
  }

  getSortedMessages(): MessageModel[] {
    return this.messages.sort((a: MessageModel, b: MessageModel) => {
      if (a.sendDate > b.sendDate) { return 1 }
      if (a.sendDate < b.sendDate) { return -1 }
      if (a.sendDate === b.sendDate) { return this.compareMessagesById(a, b) }
    });
  }

  compareMessagesById(a: MessageModel, b: MessageModel): number {
    if (a.messageId > b.messageId) { return 1 }
    if (a.messageId < b.messageId) { return -1 }
    if (a.messageId === b.messageId) { return 0 }
  }

  // private async doStuffThatWasInFCM(newConvoId: any) {

  //   await this.platform.ready()
  //   //wait for user to login
  //   while(this.loginService.userHasLoggedIn === false) {
  //     await this.delay(300);
  //   }
  //   SplashScreen.hide();
  //   this.router.navigateByUrl("/messages" + newConvoId);


  // }

  messageIsInConvo(message: MessageModel): boolean {
    return "" + this.conversation?.conversationId === message.convoId;
  }

  startAddReactionHandler(): void {
    this.pushAddReactionSubscription = this.messagingSocketService.socketReactionsAdded$.subscribe((reactionCommand: ReactionCommand) => {
      this.addReactionToMessage(reactionCommand);
    })

    this.subscriptions.add(this.pushAddReactionSubscription);
  }

  private addReactionToMessage(reaction: ReactionCommand) {
    const messageToUpdate: MessageModel = this.messages
      .find((message) => message.messageId === "" + reaction.messageId);

    const newReaction: Reaction = {
      reaction: reaction.reaction, userId: reaction.userId + ""
    }

    if (messageToUpdate && !messageToUpdate.reactions.find(r => r.reaction === newReaction.reaction && r.userId === newReaction.userId)) {
      messageToUpdate.reactions.push(newReaction);

    }
  }

  startRemoveReactionHandler(): void {
    this.pushRemoveReactionSubscription = this.messagingSocketService.socketReactionsRemoved$.subscribe(
      (reactionNotification) => {
        this.handleRemoveReaction(reactionNotification);
      }
    );

    this.subscriptions.add(this.pushRemoveReactionSubscription);

  }

  startEditMessageHandler(): void {
    this.pushEditMessageSubscription = this.messagingSocketService.socketEditMessage$.subscribe((editMessageCommand: EditMessageCommand) => {
      this.handleMessageEdited(editMessageCommand)
    })

    this.subscriptions.add(this.pushEditMessageSubscription);

  }

  startDeleteMessageHandler(): void {
    this.pushDeleteMessageSubscription = this.messagingSocketService.socketDeleteMessage$.subscribe((deleteCommand: DeleteMessageCommand) => {
      this.handleDeleteMessage(deleteCommand.messageId + "")
    })

    this.subscriptions.add(this.pushDeleteMessageSubscription);

  }

  startImageReceivedByServerHandler(): void {
    this.messagingSocketService.socketImageReceived$.subscribe((imageReceivedCommand: ImageReceivedCommand) => {
      this.handleImageReceivedByServer(imageReceivedCommand.frontendId)
    })

    // this.subscriptions.add(this.socketImageReceived);

  }

  private handleImageReceivedByServer(frontendId: string) {
    const msg = this.messages.find(msg => msg.frontendId === frontendId);
    if (msg) {
      msg.isPending = false;
    }
    this.stopSendingMessageTimer();

  }

  private handleDeleteMessage(messageId: string) {
    let indicesToRemove: number[] = []
    this.messages.forEach((msg, index) => {
      if (msg.messageId === messageId) indicesToRemove.push(index);
    })
    for (let index of indicesToRemove) {
      this.messages.splice(index, 1);
    }
  }

  private handleMessageEdited(editCommand: EditMessageCommand) {
    const commandMessageId: string = "" + editCommand.messageId;
    const messageToEdit: MessageModel = this.messages.find((message) => message.messageId === commandMessageId);
    if (messageToEdit?.fromUser === editCommand.userId + "") {
      messageToEdit.contents = editCommand.newContents;
    }
  }

  private handleRemoveReaction(reactionCommand: ReactionCommand) {
    const messageToUpdate: MessageModel = this.messages
      .find((message) => message.messageId === "" + reactionCommand.messageId)

    if (!messageToUpdate?.reactions) return;
    const filteredMessages = messageToUpdate?.reactions?.filter((reaction) =>
      reaction.reaction !== reactionCommand.reaction || reaction.userId !== "" + reactionCommand.userId);
    messageToUpdate.reactions = filteredMessages;
  }


  public async reloadMessages(): Promise<void> {
    await this.messagingStoreService.loadMessageUpdates(this.conversation?.conversationId);
    this.messages = await this.messagingStoreService.getStoredMessagesForConversation(this.conversation?.conversationId);
    this.scrollSmooth();

    this.zone.run(() => {
      console.log("redrawing component for reloading messages");
    });
  }

  check(event, keyCode) {
    var textarea = event.target;

  }


  clickEnter() {
    if (this.platform.is("desktop") || this.platform.is("ipad") || this.platform.is('mobileweb')) {
      this.sendMsg();
    }
  }

  async constructTempMessage(messageContents: string, messageType: MessageTypeEnum, imageUrl: string = ""): Promise<MessageModel> {

    let tempMessage: MessageModel = {
      messageId: "tempId",
      fromUser: await this.userDetailsService.getUserId(),
      convoId: this.conversation?.conversationId.toString(),
      contents: messageContents,
      sendDate: "now",
      fromUserName: await this.userDetailsService.getUserName(),
      fromUserImg: await this.userDetailsService.getUserImage(),
      reactions: [],
      messageImage: imageUrl,
      messageType: MessageTypeEnum[messageType],
      isPending: true,
      frontendId: uuidv4()
    }

    return tempMessage
  }

  //There's some code smell with this string mutation. But we structured replies like this a while ago :/
  private replaceOldReplyWithNewEditedReply(contentsToSend: string) {
    let originalMessage = this.messageBeingEdited.contents.split(/(π)/);
    originalMessage[6] = contentsToSend;

    let newReply = "";
    originalMessage.forEach(val => {
      newReply += val;
    })
    return newReply;
  }

  private cleanUpCarriageReturn(contents: string): string {
    let output = contents;
    if (contents.includes('\r')) {
      output = contents.replace(/[\r]+/g, '');
    }
    return output;
  }

  private clearEditorMsg() {
    this.messagingStoreService.editorMsg = "";
    this.messagingStoreService.cacheTextBoxValue(this.conversation?.conversationId, "");
  }


  timerForSendingMessage: number = 0;
  interval: any;
  startSendingMessageTimer() {

    if (this.interval) { clearInterval(this.interval); this.timerForSendingMessage = 1000; }

    this.currentlySendingMessage = true;

    this.interval = setInterval(() => {
      this.timerForSendingMessage += 10;
    }, 10)
  }

  stopSendingMessageTimer() {
    this.sendingMessageBarValue = 100;


    setTimeout(() => {
      this.currentlySendingMessage = false;

      clearInterval(this.interval);
      this.timerForSendingMessage = 0;
    }, 300);

  }


  showBccProgressModal(bccId: string, numberOfUsers: number): MatDialogRef<GenericProgressModalComponent> {

    let val = this.dialog.open(GenericProgressModalComponent, {

      data: {
        progressSource: "bcc",
        bccId: bccId,
        startValue: 0,
        maxValue: numberOfUsers
      }
    });
    val.disableClose = true;
    return val;
  }

  showChatTextBox(): boolean {
    const selectedConversation: ConversationModel = this.conversation;
    // const isEnzyAssistant: boolean = selectedConversation?.ownerId === 4760;
    // if (isEnzyAssistant) { return false }

    const isAdminSendOnly: boolean = selectedConversation?.isAdminSendOnly;

    if (isAdminSendOnly) {
      return this.userIsChatAdmin();
    }
    return true;
  }

  userIsChatAdmin(): boolean {
    const selectedConversation: ConversationModel = this.conversation;
    const participant: ParticipantModel = selectedConversation.participants.find(p => p.id + "" === this.userId + "");
    return participant?.isAdmin;
  }



  async sendMsg() {

    if (this.messagingStoreService.editorMsg.toLocaleLowerCase().includes("$first") && !this.isBcc) {

      let ref = this.dialog.open(BccGuardRailModalComponent, {
        panelClass: 'transparent',
        disableClose: false
      })

      let res = await ref.afterClosed().toPromise();

      if (!res) { return }

    }


    this.startSendingMessageTimer();

    const previousContents: string = this.messageBeingEdited?.contents;
    let contentsToSend: string = this.cleanUpCarriageReturn(this.messagingStoreService.editorMsg);
    this.clearEditorMsg();
    this.resetTextarea();



    if (this.messagingStoreService.inEditMessageMode) {
      if (!this.messageBeingEdited) { return }

      if (this.messageBeingEdited.messageType === "REPLY") {
        contentsToSend = this.replaceOldReplyWithNewEditedReply(contentsToSend)
      }

      this.messageBeingEdited.contents = contentsToSend;

      this.clearEditorMsg();
      this.messagingService.editMessage(this.sessionKey, this.messageBeingEdited.messageId, contentsToSend).subscribe(result => {
        this.messagingStoreService.inEditMessageMode = false;
        this.messageBeingEdited = undefined;
        this.autogrow();

        this.stopSendingMessageTimer();
      }, (error) => {
        this.snackbarService.displaySnackBarMessage("Cannot edit messages when offline", false);
        this.messageBeingEdited.contents = previousContents;
      });

    }
    else if (this.isBcc) {

      const gifUrl: string = this.selectedGif?.length > 0 ? this.selectedGif : null;
      this.selectedGif = '';

      const bccId: string = uuidv4();
      let count = 0;

      let dialogRef: MatDialogRef<GenericProgressModalComponent> = this.showBccProgressModal(bccId, this.bccUsersToSend.length);
      dialogRef.componentInstance.setCurrentValue(count);

      const tempSubscription: Subscription = this.messagingSocketService.socketMessages$.subscribe((msg) => {
        if (msg.bccId) {
          count += 1;
          dialogRef.componentInstance.setCurrentValue(count);
          // console.log(msg);
        }
      });

      this.messagingService.sendBccMessage(this.bccUsersToSend, contentsToSend, bccId, this.selectedFile, gifUrl, this.originReportLines).then((result) => {
        dialogRef.close();
        tempSubscription.unsubscribe();
        this.isBcc = false;
        this.clear();
        this.messagingService.triggerConvoReload$.next("trigger");
        this.stopSendingMessageTimer();
        this.router.navigate(["/messages"]);

        // TODO: update enzy assistant conversation
        if (this.originReportLines?.length > 0) {
          const enzyAssistantConvoId: number = this.messagingStoreService.getEnzyAssistantConversationId();
          this.messagingStoreService.markManyMessageReportLinesAsCompleteLocally(enzyAssistantConvoId, this.originReportLines);
        }

      }).catch(err => {
        dialogRef.close();
        tempSubscription.unsubscribe();

      });
    } else {
      // Send Image
      if (this.checkIfPhotoExists()) {
        this.sendImageOrVideoMessageToBackend(this.selectedFile, "IMAGE", contentsToSend, null, false, this.originReportLines);
        return;
      } else if (this.checkIfVideoExists()) {
        this.sendImageOrVideoMessageToBackend(this.selectedFile, "VIDEO", contentsToSend, null, false, this.originReportLines);
        return;
      }


      //Sending a gif
      if (this.selectedGif.length !== 0) {

        const gifUrl: string = this.selectedGif;
        const messageContents: string = contentsToSend;

        let tempMessage = await this.constructTempMessage(messageContents, MessageTypeEnum.GIF, gifUrl);
        this.messages.push(tempMessage);

        this.selectedGif = "";

        setTimeout(() => {
          this.scrollToBottom()
        }, 300);


        this.sendMessageToBackend(messageContents, tempMessage.frontendId, MessageTypeEnum.GIF, gifUrl, this.originReportLines);
        return;
      }


      //Normal, basic message
      if (contentsToSend.length !== 0 && !this.replyMessage) {
        const messageContents = contentsToSend;

        let tempMessage = await this.constructTempMessage(messageContents, MessageTypeEnum.TEXT);
        this.messages.push(tempMessage);

        this.sendMessageToBackend(messageContents, tempMessage.frontendId, MessageTypeEnum.TEXT, "", this.originReportLines);

        setTimeout(() => {
          this.scrollToBottom()
        }, 300);


      }

      //REPLY
      if (this.replyMessage && contentsToSend.length !== 0) {
        let replyContent = this.replyMessage.contents;

        if (this.replyMessage.messageImage) {
          replyContent = this.replyMessage.messageImage;
        }
        const messageContents =
          "RπPLY" +
          this.replyMessage.fromUserName +
          "π" +
          replyContent +
          "π" +
          contentsToSend;

        let tempMessage = await this.constructTempMessage(messageContents, MessageTypeEnum.REPLY);
        this.messages.push(tempMessage);
        this.replyMessage = undefined;


        setTimeout(() => {
          this.scrollToBottom()
        }, 300);

        this.sendReplyToBackend(messageContents, tempMessage.frontendId);

      }


    }
  }

  resendMessage(message: MessageModel) {
    if (this.currentlySendingMessage) {
      return;
    }
    this.startSendingMessageTimer();
    if (message.selectedFile) {
      this.sendImageOrVideoMessageToBackend(message.selectedFile, message.messageType, message.contents, message.frontendId, true);
      // this.messagingStoreService.removeMessageFromTempByFrontendId(message.frontendId);
    }
    else {
      this.messagingStoreService.editorMsg = this.cleanUpCarriageReturn(message.contents);
      let enumObj: MessageTypeEnum;
      if (message.messageType === "TEXT") { enumObj = MessageTypeEnum.TEXT }
      else if (message.messageType === "REPLY") { enumObj = MessageTypeEnum.REPLY }
      else if (message.messageType === "GIF") { enumObj = MessageTypeEnum.GIF }
      else if (message.messageType === "URL") { enumObj = MessageTypeEnum.URL }

      this.sendMessageToBackend(message.contents, message.frontendId, enumObj);
    }

  }

  // checkForSpinningMessages() {
  //   setTimeout(() => {

  //     this.messages.forEach(msg => {
  //       if (msg.isPending) {
  //         console.log("spinning message :/")
  //         this.switchToSelectedConversation();
  //         setTimeout(() => {
  //           this.scrollToBottom();
  //         }, 500);
  //       }
  //     })
  //   }, 20000);
  // }

  getDateStringForIndex(index: number) {
    const message: MessageModel = this.getSortedMessages()[index];
    if (!message.sendDate) return new Date().toLocaleDateString();
    let date = new Date(message.sendDate?.replace(/-/g, '/'));
    if (date.toString() === "Invalid Date") return new Date().toLocaleDateString();
    return date;
  }

  shouldShowUserIcon(index: number): boolean {
    if (index === 0) { return true }

    if (this.previousMessageWasSent5MinutesAgo(index)) { return true }

    const sortedMessages: MessageModel[] = this.getSortedMessages();
    let currentSenderId = sortedMessages[index].fromUserName;
    let previousSenderId = sortedMessages[index - 1].fromUserName;


    return currentSenderId !== previousSenderId
  }

  previousMessageWasSent5MinutesAgo(index: number): boolean {
    // if (!this.platform.is("ios")) {return}
    if (index === 0) { return false }
    const sortedMessages: MessageModel[] = this.getSortedMessages();
    let currentDateUTC = new Date(sortedMessages[index].sendDate?.replace(/-/g, '/'));
    let previousSendDateUTC = new Date(sortedMessages[index - 1].sendDate?.replace(/-/g, '/'));

    const timezoneOffset: number = currentDateUTC.getTimezoneOffset();
    const timezoneOffsetInMilliseconds: number = timezoneOffset * 60000;

    let currentDate = new Date(currentDateUTC.valueOf() - timezoneOffsetInMilliseconds);
    let previousSendDate = new Date(previousSendDateUTC.valueOf() - timezoneOffsetInMilliseconds);

    if (currentDate.toString() === "Invalid Date" || previousSendDate.toString() === "Invalid Date") return false;

    const minuteDifference = Math.abs(currentDate.getMinutes() - previousSendDate.getMinutes());
    // console.log(minuteDifference + " : message : " + sortedMessages[index].contents)
    const differentDay: boolean = currentDate.getDate() !== previousSendDate.getDate();
    if (differentDay) { return true }


    const moreThan5Minutes: boolean = minuteDifference > 5;
    return moreThan5Minutes;
  }



  previousMessageHasSameSender(index: number): boolean {
    if (index === 0) { return true }

    if (this.previousMessageHasDifferentSendDate(index)) { return true }

    const sortedMessages: MessageModel[] = this.getSortedMessages();
    let currentSenderId = sortedMessages[index].fromUserName;
    let previousSenderId = sortedMessages[index - 1].fromUserName;


    return currentSenderId !== previousSenderId
  }

  previousMessageHasDifferentSendDate(index: number): boolean {
    // if (!this.platform.is("ios")) {return}
    if (index === 0) { return false }
    const sortedMessages: MessageModel[] = this.getSortedMessages();
    let currentDateUTC = new Date(sortedMessages[index].sendDate?.replace(/-/g, '/'));
    let previousSendDateUTC = new Date(sortedMessages[index - 1].sendDate?.replace(/-/g, '/'));

    const timezoneOffset: number = currentDateUTC.getTimezoneOffset();
    const timezoneOffsetInMilliseconds: number = timezoneOffset * 60000;

    let currentDate = new Date(currentDateUTC.valueOf() - timezoneOffsetInMilliseconds);
    let previousSendDate = new Date(previousSendDateUTC.valueOf() - timezoneOffsetInMilliseconds);

    if (currentDate.toString() === "Invalid Date" || previousSendDate.toString() === "Invalid Date") return false;

    const differentYear: boolean = currentDate.getFullYear() !== previousSendDate.getFullYear();
    const differentMonth: boolean = currentDate.getMonth() !== previousSendDate.getMonth();
    const differentDay: boolean = currentDate.getDate() !== previousSendDate.getDate();

    return differentYear || differentMonth || differentDay;
  }

  private sendMessageToBackend(messageContents: string, frontendId: string, messageType: MessageTypeEnum, gifUrl: string = "", reportLines: ReportLine[] = []): void {
    this.clearEditorMsg();

    // this.checkForSpinningMessages();

    const reportLine = reportLines.length > 0 ? reportLines[0] : null;

    this.messagingService.sendMessage(this.sessionKey, this.conversation?.conversationId, messageContents, messageType, gifUrl, frontendId, reportLine).then((result) => {
      this.clear();
      this.messagingStoreService.removeMessageFromTempByFrontendId(result?.messageDto?.frontendId, this.conversation?.conversationId + "");
      this.stopSendingMessageTimer();

      const index: number = this.messages.findIndex(m => m.frontendId === frontendId);
      if (index >= 0 && result?.messageDto) {
        this.messages[index] = result.messageDto;
      }

      // TODO: update enzy assistant conversation
      const enzyAssistantConvoId: number = this.messagingStoreService.getEnzyAssistantConversationId();
      this.messagingStoreService.markManyMessageReportLinesAsCompleteLocally(enzyAssistantConvoId, [reportLine]);
    })
      .catch((error) => {
        // alert("Message failed to send. Check your connection");

        this.messages.forEach((msg, index) => {
          if (msg.contents === messageContents && msg.messageId === "tempId") {
            msg.isPending = false;
            msg.messageId = "Failed"

            this.messagingStoreService.addTempMessage(msg);

            this.messages.splice(index, 1);

            this.stopSendingMessageTimer();

          }
        })

      });
  }


  async editMessage(message: MessageModel) {
    if (message.messageType === "REPLY") {
      this.messagingStoreService.editorMsg = message.contents.split("π")[3]
    } else {
      this.messagingStoreService.editorMsg = message.contents;
    }
    this.messagingStoreService.inEditMessageMode = true;
    this.messageBeingEdited = message;
    let textArea = document.getElementById("textarea")
    await textArea.focus();

    setTimeout(() => { this.autogrow(); }, 100)

  }


  private sendReplyToBackend(reply: string, frontendId: string) {
    // this.checkForSpinningMessages();
    this.clearEditorMsg();


    this.messagingService.sendMessage(this.sessionKey, this.conversation?.conversationId, reply, MessageTypeEnum.REPLY, null, frontendId).then((result) => {
      this.clear();
      this.stopSendingMessageTimer();

      this.messagingStoreService.removeMessageFromTempByFrontendId(result?.messageDto?.frontendId, this.conversation?.conversationId + "");

    })
      .catch((error) => {
        // alert("Message failed to send. Check your connection");
        this.stopSendingMessageTimer();

        this.messages.forEach((msg, index) => {
          if (msg.contents === reply && msg.messageId === "tempId") {
            msg.isPending = false;
            msg.messageId = "Failed"

            this.messagingStoreService.addTempMessage(msg);

            this.messages.splice(index, 1);
          }
        })

      });
  }

  private async sendImageOrVideoMessageToBackend(selectedImage: any, type: string, contents: string, frontendId: string = null, isResend: boolean = false, reportLines: ReportLine[] = []): Promise<void> {


    let tempMessage: MessageModel = {
      messageId: "tempId",
      fromUser: await this.userDetailsService.getUserId(),
      convoId: this.conversation?.conversationId.toString(),
      contents: contents,
      sendDate: "now",
      fromUserName: await this.userDetailsService.getUserName(),
      fromUserImg: await this.userDetailsService.getUserImage(),
      reactions: [],
      messageImage: "../../assets/placeholder-image.png",
      messageType: type,
      isPending: true,
      selectedFile: selectedImage ? selectedImage : this.selectedFile,
      frontendId: frontendId ? frontendId : uuidv4(),
      imageHeight: this.imageHeight,
      imageWidth: this.imageWidth
    }
    if (!isResend) {
      this.messages.push(tempMessage);

      setTimeout(() => {
        this.scrollToBottom()
      }, 300);

      this.clear();
    }



    this.clearEditorMsg();

    const reportLine = reportLines.length > 0 ? reportLines[0] : null;

    this.messagingService.sendImageMessage(this.sessionKey, "" + this.conversation?.conversationId, selectedImage, contents, tempMessage.frontendId, this.imageWidth, this.imageHeight, reportLine).then((result) => {
      this.dialog.closeAll();
      this.selectedFile = null;
      // this.clear();
      this.stopSendingMessageTimer();

      this.messagingStoreService.removeMessageFromTempByFrontendId(result?.messageDto?.frontendId, this.conversation?.conversationId + "");
      // TODO: update enzy assistant conversation
      const enzyAssistantConvoId: number = this.messagingStoreService.getEnzyAssistantConversationId();
      this.messagingStoreService.markManyMessageReportLinesAsCompleteLocally(enzyAssistantConvoId, [reportLine]);

    }).catch((reason: any) => {
      // alert("Message failed to send image. Check your connection");
      this.dialog.closeAll();


      // this.customFileService.saveFileLocally(this.selectedFile, tempMessage.frontendId);
      //  tempMessage.messageImage = null;
      this.stopSendingMessageTimer();


      this.messages.forEach((msg, index) => {
        if (msg.messageImage === "../../assets/placeholder-image.png" && msg.messageId === "tempId") {
          msg.isPending = false;
          msg.messageId = "Failed"

          this.messagingStoreService.addTempMessage(msg);
          this.messages.splice(index, 1);
        }
      })

    });
  }

  openDrawer() {
    // 
    let ref = this._bottomSheet.open(MessageActionsDrawerComponent, { data: { mode: "conversation", conversation: this.conversation } });
    ref.afterDismissed().subscribe((res) => {
      if (res === "leftChat") {
        this.navCtrl.pop();
      }
    });
  }

  identifyMessage(index: number, item: MessageModel) {
    return item.contents;
  }

  replaceTempMessageOrAppendToEnd(): void {
    // TODO: IMPLEMENT THIS GUY SO WHEN A MESSAGE COMES IN IT WIPES THE TEMP IMAGE
  }

  private checkIfPhotoExists(): boolean {
    return this.selectedFile !== null && this.selectedFile.type.includes("image");
  }


  private checkIfVideoExists(): boolean {
    return this.selectedFile !== null && this.selectedFile.type.includes("video");
  }

  selectMention(participant: ParticipantModel) {
    this.showMention = false;
  }





  private humanFriendlyDate(date: Date): string {
    const unixTimestamp: number = date.valueOf();

    const now: number = Date.now();

    const diffNumber = now - unixTimestamp;

    let days = Math.floor(diffNumber / (60 * 60 * 24 * 1000));
    let hours = Math.floor(diffNumber / (60 * 60 * 1000));
    let minutes = Math.floor(diffNumber / (60 * 1000));

    if (days > 1) {
      return date.toLocaleDateString();
    } else if (days === 1) {
      return "yesterday";
    } else if (hours > 0) {
      return date.toLocaleTimeString();
    } else if (minutes > 1) {
      return minutes + " minutes ago";
    } else if (minutes === 1) {
      return minutes + " minute ago";
    } else {
      return "just now";
    }
  }

  openGif() {
    this._bottomSheet
      .open(GiphyComponent)
      .afterDismissed()
      .subscribe((res) => {
        if (res) {
          this.selectedGif = res;
        }
      });
  }

  clear() {
    this.selectedGif = "";
    this.selectedFile = null;
    this.replyMessage = undefined;

    // this.imageHeight = null;
    // this.imageWidth = null;
  }

  public imagePath;
  imgURL: any;


  imageHeight: number = null;
  imageWidth: number = null;

  // Take new photo
  onFileSelected(event: any) {

    const Img = new Image();

    // const filesToUpload = (event.target.files);
    const URL = window.URL || window.webkitURL;
    Img.src = URL.createObjectURL(event.target.files[0]);

    getBlobFromFile(event.target.files[0]).then((res) => {
      let objectURL = URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });

    this.zone.run(() => {
      console.log("redrawing component ");
    });

    this.selectedFile = event.target.files[0];

  }

  @ViewChild('selectedImage') selectedImage: ElementRef;

  onLoad() {
    this.imageHeight = (this.selectedImage.nativeElement as HTMLImageElement).height;
    this.imageWidth = (this.selectedImage.nativeElement as HTMLImageElement).width;

  }



  sendingMessageBarValue = 0;
  currentlySendingMessage: boolean = false;

  shouldShowSendingMessageBar() {
    if (!this.currentlySendingMessage || this.timerForSendingMessage < 1000) { return false }

    if (this.timerForSendingMessage > 1000 && this.timerForSendingMessage <= 2500) {
      this.sendingMessageBarValue = 10;
    } else if (this.timerForSendingMessage > 2500 && this.timerForSendingMessage <= 4000) {
      this.sendingMessageBarValue = 50;
    }
    else if (this.timerForSendingMessage > 4000) {
      this.sendingMessageBarValue = 95;
    }
    return true;
  }



  isConnected(): boolean {
    return this.messagingSocketService.socketIsConnected();
  }

  async reconnect(): Promise<void> {
    this.messagingSocketService.reconnectIfDisconnected();
    await this.messagingStoreService.loadMessageUpdates(this.conversation?.conversationId);
    this.messages = await this.messagingStoreService.getStoredMessagesForConversation(this.conversation?.conversationId);
  }

  getGroupImage(): string {
    if (this.conversation && this.conversation.imageUrl) {
      return this.conversation.imageUrl;
    }
    else {
      return "";
    }
  }


  onSubmit() {
    const payload = new FormData();
    payload.append("imageFile", this.selectedFile, this.selectedFile.name);
  }

  isIOS() {
    return this.platform.is("ios");
  }

  private appendNewMessageFromNotification(newMessage: MessageModel): void {
    if (!this.messageIsAlreadyInList(newMessage)) {
      this.messages.push(newMessage);

      this.zone.run(() => {
        console.log("redrawing component ");
      });

      this.scrollSmooth();
    }
  }

  private messageIsAlreadyInList(message: MessageModel): boolean {
    const messageIndex: number = this.messages.findIndex(
      (m) => m.messageId === message.messageId
    );
    return messageIndex >= 0;
  }

  setActiveReportLines(reportLines: ReportLine[]) {
    this.originReportLines = reportLines;
  }
  // takeAndSaveVideo() {
  //   this.messagingService.takeAndSaveVideo();
  // }

}

