
  <ion-toolbar style=" width: 100%; height: 60px; position: sticky;top: 0; padding: 3px; z-index: 999; ">

    <div style="height: 10px;"></div>

    <div style=" max-width: 1200px; display: block; margin: auto;">

      <div class="scrolling-wrapper-flexbox"
        style="overflow-y: hidden; height: 50px; padding-right: 5px; padding-left: 5px;">

        <div style="width: 100%">
          <button class="filter-button" mat-button (click)="setSearchOn()" style="width: 100%;">
            <i class="material-icons opaque-text" style="float: left; margin-top: 5px;">
              search
            </i>
            <span *ngIf="!searchOn" style="float: left;" class="zeroLineHeight opaque-text">Search
            </span>

            <mat-form-field *ngIf="searchOn" floatLabel='never' appearance="none"
              style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
              <input #searchInput style=" font-size: 16px;" matInput [(ngModel)]="searchValue"
                (keyup)="applyFilter($event)">
            </mat-form-field>
          </button>

        </div>
        <div style="padding: 1px">
        </div>
        <div>
          <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
            style="margin-left: -50px; z-index: 999;">
            <i class="material-icons" style="font-size: 14px;">
              clear
            </i>
          </button>
        </div>
      </div>
    </div>
  </ion-toolbar>

<div class="app-background-color" style=" overflow: auto; min-height: 40vh;">

  <table class="app-background-color" mat-table [dataSource]="dataSource" matSort >

    <ng-container *ngFor='let col of this.getColumnsToShow(); let i = index'   style="margin-right:50px">
      <ng-container [matColumnDef]="col" style="min-width: 150px;">
        <th mat-header-cell *matHeaderCellDef style="min-width: 150px;"> {{col}} </th>
        <td mat-cell *matCellDef="let row" style="font-weight:100; min-width: 150px;"> {{row[col]}} </td>
      </ng-container>

    </ng-container>

    <ng-container style="min-width: 150px;">
      <tr mat-header-row *matHeaderRowDef="this.getColumnsToShow()" style="min-width: 150px;"></tr>
      <tr mat-row *matRowDef="let row; columns: this.getColumnsToShow();" style="min-width: 150px;"></tr>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data found
      </td>
    </tr>
  </table>

</div>