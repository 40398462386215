<ion-footer class="app-footer footer-height footer-grid" style="padding:0;margin: 0;" >
  <div style="max-width: 600px; display: block; margin: auto;">

    <div class="container footer-height">
      <div *ngFor="let footerTab of this.getFooterTabs()" class="square" style="padding: 0; margin: 0;">

        <button mat-button
          [ngClass]="{'selectedClass': (this.tabIsSelected(footerTab)), 'nonSelectedClass': (!this.tabIsSelected(footerTab))}"
          style="width: 100%; height: 50px; border-radius: 0;" (click)="processTabAction(footerTab)">
          <mat-icon style=" z-index: 9999;" [matBadge]="getTabChips(footerTab)">
            <img *ngIf="footerTab.iconImageUrl" style="height: 25px; width: 25px;" [src]="footerTab.iconImageUrl" [ngClass]="{'filter-white': isLightTheme(), 'filter-black': !isLightTheme() }" />
            <mat-icon *ngIf="footerTab.icon">{{footerTab.icon}}</mat-icon>
          </mat-icon>
        </button>
      </div>

      <div *ngIf="shouldShowMoreButton()" class="footer-height square">
        <button [ngClass]="{'selectedClass': (this.moreTabIsSelected()), 'nonSelectedClass': (!this.moreTabIsSelected())}"

        mat-button style="width: 100%; height: 50px; border-radius: 0;" [matMenuTriggerFor]="menu">

          <mat-icon style=" z-index: 9999;" [matBadge]="getTabChipsForMoreDrawer()">
            <img style="height: 30px; width: 30px;" src="../../../../assets/moreIcon.svg"
              [ngClass]="{'filter-white': isLightTheme(), 'filter-black': !isLightTheme() }" />
          </mat-icon>
        </button>
      </div>


      <div *ngIf="!shouldShowMoreButton()" class="footer-height square">
        <button mat-button style="width: 100%; height: 50px; border-radius: 0;" (click)="logOut()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </div>


</ion-footer>
<div class="safe-area-bottom app-footer"></div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>

    <div *ngFor="let tab of this.getMoreFooterTabs()">
      <button mat-menu-item (click)="processTabAction(tab)">
        <mat-icon [matBadge]="getTabChips(tab)">{{tab.icon}}</mat-icon>
        <span>{{tab.title}}</span>
      </button>
    </div>

    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Log Out</span>
    </button>
  </ng-template>
</mat-menu>