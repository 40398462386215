import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { UserDetailsService } from './user-details.service';
import { ReportsService } from './reports.service';
import { Filter, LeadersPage, LeadersStack, SummaryResult } from '../models/leaders.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LeaderboardFilterOutput, LeaderboardResult, LeadersService } from './leaders.service';
import { MatDialog } from '@angular/material';
import { BusySpinnerComponent } from '../components/busy-spinner/busy-spinner.component';
import { DatesService } from './dates.service';
import { BusySpinnerService } from './busy-spinner.service';


@Injectable({
  providedIn: 'root'
})
export class LeadersStoreService {

  public leadersStack: LeadersStack;
  public basePage: LeadersPage;
  public basePageHasBeenInitialized: Subject<boolean>;

  // public leaderboardFilterDescription: LeaderboardFilterOutput;

  constructor(public localStorage: Storage,
    public busySpinnerService: BusySpinnerService,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    public datesService: DatesService,
    public leadersService: LeadersService,
    public reportsService: ReportsService) {
    this.basePageHasBeenInitialized = new Subject();

    this.initService();
    this.initializeBasePage();
    this.initStack();


  }

  /********************************************INIT METHODS**************************************************************************************************/
  async initService() {
    await this.localStorage.create();
    await this.localStorage.defineDriver(CordovaSQLiteDriver);
  }


  async initializeBasePage() {
    this.basePage = {
      showZeros: false,
      summaryResult: [],
      filters: [],
      leaderboardFilterOutput: null,
      selectedObjectIndex: null
    }
    await this.initializeFilters();
    this.initializeSummaryResults();
  }

  async initializeFilters() {
    await this.loadCachedFilters();
    await this.fetchLatestFilters(0);
  }


  async initializeSummaryResults() {
    await this.loadCachedSummaryResults();
    this.fetchLatestSummaryResults(0);
  }


  initStack() {
    this.leadersStack = {
      leadersPages: []
    };

    this.leadersStack.leadersPages.push(this.basePage);
  }


  /********************************************FETCH METHODS**************************************************************************************************/
  async fetchLatestFilters(stackPosition: number, drillDownString: string = null) {
    if (stackPosition === 0) {
      await this.getBaseFilters();
    } else {
      await this.getDrillDownFilters(stackPosition, drillDownString);
    }
  }

  async getBaseFilters() {
    const result: LeaderboardFilterOutput = await this.leadersService.getFilterOutput();

    this.basePage.leaderboardFilterOutput = result;
    this.basePage.filters = this.parseFiltersFrom(result);
    this.cacheFiltersList();
  }

  async getDrillDownFilters(stackPosition: number, drillDownString: string) {

    let preDrillDownFilters: LeaderboardFilterOutput = null;
    if (stackPosition > 0) {
      preDrillDownFilters = this.leadersStack.leadersPages[stackPosition - 1].leaderboardFilterOutput;
    }
    // const preDrillDownFilters: LeaderboardFilterOutput = this.leadersStack.leadersPages[stackPosition].leaderboardFilterOutput;

    const result: LeaderboardFilterOutput = await this.leadersService.getDrillDownFilterOutput(preDrillDownFilters, drillDownString);

    this.leadersStack.leadersPages[stackPosition].leaderboardFilterOutput = result;
    this.leadersStack.leadersPages[stackPosition].filters = this.parseFiltersFrom(result);

  }

  private parseFiltersFrom(filterOutput: LeaderboardFilterOutput): Filter[] {
    return [filterOutput.timePeriodFilter, filterOutput.groupByFilter, filterOutput.showFilter];
  }

  public getBarMax(stackPosition: number = 0): number {
    const page: LeadersPage = this.getPageByStackPosition(stackPosition);
    const numbers: number[] = page?.summaryResult[0].leaderboardCellList?.map(line => {
      const value = line?.value;
      if (!value) return;
      return +(value.replace("%", ""));

      });
    return Math.max(...numbers);
  }

  
  getPageByStackPosition(stackPosition: number): LeadersPage {
    return stackPosition === 0 ? this.basePage : this.leadersStack.leadersPages[stackPosition];
  }

  getCustomDatesIsSelected(stackPosition: number): boolean {
    const pageToUpdate: LeadersPage = this.getPageByStackPosition(stackPosition);
    const timeFilter: Filter = pageToUpdate?.filters?.find(f => f?.title?.toLowerCase()?.includes("time"));
    return timeFilter?.currentSelectedKey?.toLowerCase()?.includes("custom date");
  }


  async fetchLatestSummaryResults(stackPosition: number, stratFilter: string = null) {
    let ref = this.dialog.open(BusySpinnerComponent, {
      panelClass: 'transparent',
      disableClose: false
    })

    if (stackPosition === 0) {
      const result: LeaderboardResult = await this.leadersService.getSummaryUpdatedResults(this.basePage.leaderboardFilterOutput, this.basePage.showZeros, 10000);
      if (result) {
        this.basePage.summaryResult = result.summaryResults;
        this.basePage.leaderboardFilterOutput = result.filterOutput;
        this.basePage.filters = this.parseFiltersFrom(result.filterOutput);
        this.cacheSummaryResultsList();
      }
        
    } else {
      const page: LeadersPage = this.leadersStack.leadersPages[stackPosition];
      stratFilter = stratFilter ? stratFilter : page.selectedValue;
      const result: LeaderboardResult = await this.leadersService.getSummaryUpdatedResults(page.leaderboardFilterOutput, page.showZeros, 10000);
      this.leadersStack.leadersPages[stackPosition].summaryResult = result.summaryResults;
      this.leadersStack.leadersPages[stackPosition].leaderboardFilterOutput = result.filterOutput;
      this.leadersStack.leadersPages[stackPosition].filters = this.parseFiltersFrom(result.filterOutput);
    }
    this.basePageHasBeenInitialized.next(true);

    ref.close();
  }



  /********************************************PUT INTO CACHE METHODS**************************************************************************************************/
  private async cacheFiltersList(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("FILTERS_LIST", JSON.stringify(this.basePage.filters));
      // console.log("CACHED FILTERS")
    }, 1000);
  }

  private async cacheSummaryResultsList(): Promise<void> {
    setTimeout(() => {
      this.localStorage.set("SUMMARY_RESULTS_LIST", JSON.stringify(this.basePage.summaryResult));
      // console.log("CACHED SUMMARY LIST")
    }, 1000);
  }




  /********************************************LOAD FROM CACHE METHODS**************************************************************************************************/
  private async loadCachedFilters(): Promise<Filter[]> {

    const res = await this.localStorage.get("FILTERS_LIST");
    if (!res) return;
    const cachedListOfFilters: Filter[] = JSON.parse(res);

    if (cachedListOfFilters?.length > 0) {
      this.basePage.filters = cachedListOfFilters;
    }
    // console.log("FINISHED LOADING FILTERS FROM LOCAL STORAGE");
  }



  private async loadCachedSummaryResults(): Promise<SummaryResult[]> {
    const res = await this.localStorage.get("SUMMARY_RESULTS_LIST");
    if (!res) return;
    const cachedListOfSummaryResults: SummaryResult[] = JSON.parse(res);
    
    if (cachedListOfSummaryResults?.length > 0) {
      this.basePage.summaryResult = cachedListOfSummaryResults;
    }
    this.basePageHasBeenInitialized.next(true);
    
    // console.log("FINISHED LOADING SUMMARY RESULTS FROM LOCAL STORAGE");
  }
  
  

  /********************************************SETTERS**************************************************************************************************/
  public setFilter(newFilter: Filter, stackPosition: number) {
    const pageToUpdate: LeadersPage = this.getPageByStackPosition(stackPosition);

    pageToUpdate.filters.forEach(f => {
      if (f?.title === newFilter?.title) {
        f = newFilter;
      }
    })
    
    if (newFilter?.title?.toLowerCase().includes("time")) {
      // todo: if it's being set to custom date then make sure the values are initialized to defaults
      if (newFilter?.currentSelectedKey?.toLowerCase()?.includes("custom date")) {
        this.setupCustomDates(pageToUpdate.leaderboardFilterOutput);
      } else {
        // if it's being set to something other than custom date set the start and end date to null
        pageToUpdate.leaderboardFilterOutput.startDate = null;
        pageToUpdate.leaderboardFilterOutput.endDate = null;
      }
    }
      
    this.fetchLatestSummaryResults(stackPosition);
  }

  setupCustomDates(filterOutput: LeaderboardFilterOutput) {
    var today = new Date();

    var oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
    filterOutput.startDate = this.datesService.serverFriendlyDate(oneWeekAgo.toDateString());
    filterOutput.endDate = this.datesService.serverFriendlyDate(today.toDateString());
  }


  /********************************************STACK METHODS**************************************************************************************************/
  public pushOntoStack(objectIndex: number, selectedValue: string) {
    let previousLeadersPage: LeadersPage = this.leadersStack.leadersPages[this.leadersStack.leadersPages.length - 1];

    let newLeadersPage: LeadersPage = {
      showZeros: previousLeadersPage?.showZeros ? previousLeadersPage.showZeros : false,
      summaryResult: [],
      filters: [],
      leaderboardFilterOutput: null,
      selectedObjectIndex: objectIndex,
      selectedValue: selectedValue
      
    }   
    this.leadersStack.leadersPages.push(newLeadersPage);
    // console.log("Size of stack: " + this.leadersStack.leadersPages.length);
  }


  public removeFromStack() {
    this.leadersStack.leadersPages.pop();
    // console.log("Size of stack: " + this.leadersStack.leadersPages.length);
  }


  /********************************************DRILL DOWN METHODS**************************************************************************************************/
  public async processDrillDown(stackPosition: number, objectIndex: number, stratFilter: string = null) {
    this.pushOntoStack(objectIndex, stratFilter);
    await this.getDrillDownFilters(stackPosition, stratFilter);
    await this.fetchLatestSummaryResults(stackPosition, stratFilter);
  }



   /********************************************REFRESH**************************************************************************************************/
   public async refresh() {
    await this.fetchLatestFilters(0);
    await this.fetchLatestSummaryResults(0);
  }

}
