<div class="no-context-menu app-background-color" style="height: 35vh;">
 <ion-grid>
   <ion-row>
    <button mat-button (click)="edit()"> Edit </button>
   </ion-row>

   <ion-row>
    <button mat-button (click)="remove()"> Remove </button>
   </ion-row>

   <ion-row>
    <button mat-button (click)="share()"> Share </button>
   </ion-row>
 </ion-grid>
</div>
