<shared-my-header></shared-my-header>
<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-toolbar class="transparent-bg"
        style=" max-width: 1200px; display: block; margin: auto;  height: 60px; z-index: 999; ">
        <div>

          <div class="scrolling-wrapper-flexbox" style="overflow-y: hidden; height: 40px;">
            <div style="width: 100%">
              <button class="filter-button" mat-button style="width: 100%;" (click)="setSearchOn()">
                <i class="material-icons opaque-text" style="float: left; margin-top: 7px; ">
                  search
                </i>

                <mat-form-field floatLabel='never' appearance="none"
                  style="width:100%; height: 45px; font-size: 14px; margin-top: -20px; padding: 5px;">
                  <input [(ngModel)]="searchValue" class="app-text-color" #searchInput placeholder="Search"
                    style=" font-size: 16px;" matInput (keyup)="applyFilterApplicants($event)">
                </mat-form-field>
              </button>

            </div>
            <div *ngIf="searchOn" style="padding: 5px"></div>
            <div>
              <button *ngIf="searchOn" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()"
                style="margin-left: -50px; z-index: 999;">
                <i class="material-icons" style="font-size: 14px;">
                  clear
                </i>
              </button>
            </div>

          </div>
        </div>
      </ion-toolbar>
    </ion-row>
    <ion-row justify-content-end>
      <ion-col size-xs="12" size-sm="12" size-md="3" size-lg="3" align-self-stretch>
        <p style="margin-top: 0">Users</p>
        <ion-grid style="padding: 0px;" class="section-div">
          <ion-row *ngFor="let status of this.adminDirectoryService.applicantStatuses">
            <button style="width: 100%; padding: 0 10px 0 10px; font-weight: 100; border-radius: 0;"
              [ngClass]="{'primary-color': (this.applicantsMode === status) }"
              (click)="changeApplicantsMode(status)" mat-button> <span style='float: left;'>{{status}}</span>
              <span style="float: right;">
                ({{getStatusCount(status) | number}})
              </span>
            </button>
          </ion-row>
        </ion-grid>
        <div style="height: 20px;"></div>
        <p>Filters</p>

        <ion-grid style="padding: 0px;" class="section-div">
          <span *ngFor="let key of getFilterKeys()">
            <ion-row>
              <button style="width: 100%; padding: 0 10px 0 10px; font-weight: 100; border-radius: 0; text-align: left;"
                [ngClass]="{'primary-color': (this.getFilterValue(key)) }" (click)="toggleFilter(key)" mat-button>
                {{getFilterKeyTitle(key)}}
              </button>
            </ion-row>
          </span>

        </ion-grid>

        <div style="height: 20px;"></div>
      </ion-col>

      <ion-col size-xs="12" size-sm="12" size-md="9" size-lg="9" align-self-stretch>

        <div style="max-width: 1200px; display: block; margin: auto;">

          <table mat-table [dataSource]="dataSource_all" matSort>

            <ng-container matColumnDef="imageUrl">
              <th mat-header-cell *matHeaderCellDef style="width: 60px;"> </th>
              <td mat-cell *matCellDef="let row" style="width: 60px;">
                <div style="margin-bottom: -10px;">
                  <app-user-icon [imageUrl]="getRowImage(row)" [initials]="getInitialsOnRow(row)">
                  </app-user-icon>

                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row" style="font-weight: 100;"> {{getNameOnRow(row)}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let row" style="font-weight: 100;"> {{getStatusOnRow(row)}} </td>
            </ng-container>

            <ng-container matColumnDef="dateSubmitted">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Onboarded </th>
              <td mat-cell *matCellDef="let row" style="font-weight: 100;"> {{getDateOnRow(row)}} </td>
            </ng-container>

            <ng-container matColumnDef="needsDocuments">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed Sections (5 possible)</th>
              <td mat-cell *matCellDef="let row" style="font-weight: 100;">
                <mat-icon matTooltip="Completed Documents" *ngIf="applicantHasDocuments(row)">perm_identity</mat-icon>
                <mat-icon matTooltip="Completed Rep Agreement" *ngIf="applicantHasRepAgreement(row)">assignment</mat-icon>
                <mat-icon matTooltip="Completed Bank Info" *ngIf="applicantHasBankInfo(row)">attach_money</mat-icon>
                <mat-icon matTooltip="Completed W9" *ngIf="applicantHasw9(row)">attach_file</mat-icon>
                <mat-icon matTooltip="Completed Background Check" *ngIf="applicantHasBackgroundCheck(row)">verified</mat-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsApplicants"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsApplicants;" (click)="selectRow(row)"></tr>

          </table>
          <mat-paginator [length]="this.applicantsStoreService.getApplicants().length" [pageSizeOptions]="[ 25]">
          </mat-paginator>

        </div>

      </ion-col>
    </ion-row>
  </ion-grid>




</ion-content>
<app-my-footer></app-my-footer>