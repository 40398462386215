import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Employee } from 'src/app/shared/models/employees.model';
import { ExpandImageModalComponent } from '../expand-image-modal/expand-image-modal.component';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent implements OnInit {

  constructor(private dialog: MatDialog,) { }

  ngOnInit() {
    if (this.isLarge) {
      this.type = "large";
    } else if(this.isExtraLarge) {
      this.type  = "xl";
    }
    else if (this.isGrid) {
      this.type = "grid"
    } else if (this.matChip) {
      this.type = "chip"
    }  else if (this.isGridSmall) {
      this.type = "gridSmall"
    }
    else if (this.isPinned) {
      this.type = "pinned"
    }
    else {
      this.type = "small"
    }

  }

  @Input()
  matChip: boolean = false;

  @Input()
  employee: Employee;

  @Input() 
  initials: string;

  @Input() 
  imageUrl?: string;

  @Input() 
  shouldExpandImage: boolean = false;


  type: string = "";


  @Input() 
  isLarge: boolean = false;

  @Input() 
  isExtraLarge: boolean = false;


  @Input() 
  isGrid: boolean = false;


  @Input() 
  isGridSmall: boolean = false;


  @Input() 
  isPinned: boolean = false;


  getImageUrl(): string {    
    if (this.employee && this.employee.userDto && this.employee.userDto.imageUrl) {
      if (this.employee.userDto.imageThumbnailUrl) {
        return this.employee.userDto.imageThumbnailUrl
      } else {
        return this.employee.userDto.imageUrl;
      }
    }
    else if(this.imageUrl) {
      return this.imageUrl;
    }
    return "";
  }

  rowHasImage(): boolean {
    if (this.employee && this.employee.userDto) {
      return !!this.employee.userDto.imageUrl || !!this.imageUrl
    }
    else if(this.imageUrl) {
      return true;
    }
    return false;
  }

  getInitials(): string {
    if ( this.employee && this.employee.userDto && this.employee.userDto.preferredFirstName !== null) {
      if (!this.employee.userDto.lastName) {return '-'}
      if (!this.employee.userDto.preferredFirstName) {return '-'}
      if (this.employee.userDto.lastName.length === 0 ) {return '-'}
      if (this.employee.userDto.preferredFirstName.length === 0 ) {return '-'}
      return (this.employee?.userDto?.preferredFirstName[0] + this.employee?.userDto?.lastName[0]).toUpperCase();
    }
    if (this.initials) {
      return this.initials;
    }
    return '';
    // const maxIntialsLength: number = (this.row.cellList[0].value.length > 2) ? 2 : this.row.cellList[0].value.length;
    // return this.row.cellList[0].value.slice(0, maxIntialsLength);
    
  }

  getHighQualityImage() {
    if (this.employee && this.employee.userDto && this.employee.userDto.imageUrl) {
      return this.employee.userDto.imageUrl;
    }
    else if(this.imageUrl) {
      return this.imageUrl;
    }
    return "";
  }


  show(){
    if (!this.shouldExpandImage) {return }
    if (this.rowHasImage()) {
      this.dialog.open(ExpandImageModalComponent, {
        maxWidth: '100vw !important',
        width: '300px',
        height: '300px',
        data: {imageUrl: this.getHighQualityImage()},
        panelClass: 'transparent',
      })
    }
    
  }
}
