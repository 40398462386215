import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  wasSubmitted: boolean = false;
  success: boolean = false;
  errorMessage: string = "";
  constructor(private loginService: LoginService, public router: Router) { }

  ngOnInit() {}

  reset() {
    this.loginService.resetPassword(this.email).then(res => {
      console.log(res)
      this.wasSubmitted = true;

      if (res.success) {
        this.success = true;
      } else {
        this.success = false;
        this.errorMessage = res.message;
      }
    })
  }

  goBack() {
    this.router.navigate(["/login"])
  }

}
