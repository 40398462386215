import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { ThemeService } from 'src/app/shared/services/theme-service.service';


export const DEFAULT_PIE_CHART_CONFIG: PieChartConfig = {
  height: null,
  showLegend: null
}
export class PieChartConfig {
  height: number;
  showLegend: boolean;
} 


@Component({
  selector: 'enzy-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  pieChart: am4charts.PieChart;
  legendContainer: any;
  compInitialzed: boolean = false;


  data: any;
  @Input() set dataInput(newData: any) {
    if (newData.length !== 0) {
      this.data = newData;
      this.setUpPieChart();
    }    
  }

  @Input() pieChartConfig: PieChartConfig = {...DEFAULT_PIE_CHART_CONFIG}


  constructor(public themeService: ThemeService, private zone: NgZone) { }

  ngOnInit() {
  }


  @ViewChild('pieChartLegendElement') pieChartLegendElement: ElementRef<HTMLElement>;
  @ViewChild('pieChartElement') pieChartElement: ElementRef<HTMLElement>;

  async setUpPieChart() {
    if (this.compInitialzed) {return}
    if (!this.pieChartElement) {return}

    am4core.options.autoDispose = true;
    am4core.options.animationsEnabled = false;
  

    this.pieChart = am4core.create(this.pieChartElement.nativeElement, am4charts.PieChart);
    this.pieChart.svgContainer.autoResize = true;

    this.legendContainer = am4core.create(this.pieChartLegendElement.nativeElement, am4core.Container);

    this.pieChart.data = this.data
    this.loadPieChart();

    this.compInitialzed = true;

  }

  private loadPieChart() {
    this.zone.runOutsideAngular(() => {
      
      this.legendContainer.width = am4core.percent(100);
      this.legendContainer.height = am4core.percent(100);

      // this.pieChart.data = this.selectedReport.trendData.trendChartData_amChart;

      let pieSeries = this.pieChart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "dataPointValue";
      pieSeries.dataFields.category = "date";
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeOpacity = 1;

      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;



      this.pieChart.innerRadius = am4core.percent(30);
      this.pieChart.fontSize = 10;

      this.pieChart.padding(0, 0, 0, 0);


      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      this.pieChart.hiddenState.properties.radius = am4core.percent(0);
 
      if (this.pieChartConfig.showLegend) {
        /* Create legend */
        this.pieChart.legend = new am4charts.Legend();
        this.pieChart.legend.parent = this.legendContainer;
        this.pieChart.legend.fontSize = 12;
        this.pieChart.legend.labels.template.text = "{date}: {dataPointValue}";
      }
    });

  }


  getHeight() {
    return this.pieChartConfig.height
  }
 
}
